import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HiveSortParams } from '@pure/hive';
import { FilterParams, OpportunityQuote, QuoteStatus } from '@pure1/data';
import { ExportOptionIds } from '../../../export/export-button/export-button.component';
import { RangeFilters } from '../quote-management-range-filters/quote-management-range-filters.component';
import { QuoteAction } from './filter-quote-actions.pipe';

const PAGE_SIZES = [25, 50, 100];

@Component({
    selector: 'quote-management-table',
    templateUrl: './quote-management-table.component.html',
})
export class QuoteManagementTableComponent {
    @ViewChild('quoteExportModal', { static: true }) readonly quoteExportModal: TemplateRef<any>;

    @Input() readonly data: OpportunityQuote[];
    @Input() readonly itemsPerPage: number;
    @Input() readonly offset: number;
    @Input() readonly totalItemCount: number;
    @Input() readonly totalUnfiltered: number;
    @Input() readonly filter: FilterParams<OpportunityQuote>;
    @Input() readonly selectedRangeFilters: RangeFilters;
    @Input() readonly isLoading: boolean;

    @Output() readonly pageChange = new EventEmitter<number>();
    @Output() readonly itemsPerPageChange = new EventEmitter<number>();
    @Output() readonly quoteClick = new EventEmitter<OpportunityQuote>();
    @Output() readonly quoteDownload = new EventEmitter<OpportunityQuote[]>();
    @Output() readonly quoteRequestChanges = new EventEmitter<OpportunityQuote>();
    @Output() readonly quoteAccept = new EventEmitter<OpportunityQuote>();
    @Output() readonly quotePOUpload = new EventEmitter<OpportunityQuote>();
    @Output() readonly selectTimeRangeFilters = new EventEmitter<RangeFilters>();
    @Output() readonly sortChange = new EventEmitter<HiveSortParams>();

    readonly DATE_WIDTH = '120px';
    readonly QUOTE_NUMBER_WIDTH = '160px';
    readonly SERIAL_NUMBER_WIDTH = '140px';
    readonly CONTRACT_TERM_WIDTH = '86px';
    readonly TOTAL_AMOUNT_WIDTH = '120px';
    readonly PREPARED_BY_WIDTH = '140px';
    readonly QuoteStatus = QuoteStatus;
    readonly pageSizes = PAGE_SIZES;
    readonly ACCEPT_ACTION: QuoteAction = {
        label: 'Accept Quote',
        acceptedStates: [QuoteStatus.OPEN, QuoteStatus.ACTION_REQUIRED],
        action: this.onQuoteAction.bind(this, this.quoteAccept),
    };
    readonly DOWNLOAD_ACTION: QuoteAction = {
        label: 'Download (PDF)',
        acceptedStates: Object.values(QuoteStatus),
        action: this.onQuoteAction.bind(this, this.quoteClick),
    };
    readonly REQUEST_CHANGE_ACTION: QuoteAction = {
        label: 'Request Change',
        acceptedStates: [
            QuoteStatus.OPEN,
            QuoteStatus.ACTION_REQUIRED,
            QuoteStatus.CHANGE_REQUESTED,
            QuoteStatus.EXPIRED,
        ],
        action: this.onQuoteAction.bind(this, this.quoteRequestChanges),
    };
    readonly PO_UPLOAD_ACTION: QuoteAction = {
        label: 'Upload Purchase Order',
        acceptedStates: [QuoteStatus.ACCEPTED],
        action: this.onQuoteAction.bind(this, this.quotePOUpload),
    };
    readonly quoteActions: QuoteAction[] = [
        this.ACCEPT_ACTION,
        this.DOWNLOAD_ACTION,
        this.REQUEST_CHANGE_ACTION,
        this.PO_UPLOAD_ACTION,
    ];
    readonly listViewExportOptions: IExportButtonOption[] = [
        { id: ExportOptionIds.filtered, text: 'Export filtered quotes', count: null },
        { id: ExportOptionIds.all, text: 'Export all quotes', count: null },
    ];
    readonly STATUS_TOOLTIP_TEXT = new Map<QuoteStatus, string>([
        [
            QuoteStatus.ACTION_REQUIRED,
            'Quote is ready for review. Please click on "Accept" to accept or "Request Change" for modifications required.',
        ],
        [
            QuoteStatus.CHANGE_REQUESTED,
            'A modification request has been sent for this quote. Please note that it may take 2 to 5 business days for processing.',
        ],
        [QuoteStatus.ACCEPTED, 'Quote is accepted and ready to be shared with the customer.'],
    ]);

    quoteFilter: string;
    selectedExportOption: IExportButtonOption;
    selectedQuotes: OpportunityQuote[] = [];
    hasWritePermission = false;

    constructor(private ngbModal: NgbModal) {}

    onPageChange(page: number): void {
        this.pageChange.emit(page);
    }

    onItemsPerPageChange(itemsPerPage: number): void {
        this.itemsPerPageChange.emit(itemsPerPage);
    }

    onSelectionChange(entities: OpportunityQuote[]): void {
        this.selectedQuotes = entities;
    }

    onSortChanged(newSort: HiveSortParams): void {
        this.sortChange.emit(newSort);
    }

    onClickExport(option: IExportButtonOption): void {
        this.selectedExportOption = option;
        this.ngbModal.open(this.quoteExportModal);
    }

    updateExportOptionCounts(optionsMap: Map<number, IExportButtonOption>): void {
        const filteredOpt = optionsMap.get(ExportOptionIds.filtered);
        this.updateExportOption(filteredOpt, this.totalItemCount);
        const filters = Object.assign({}, this.filter);
        this.quoteFilter = Object.values(filters).join(' and ');

        if (this.totalUnfiltered !== null) {
            const allOpt = optionsMap.get(ExportOptionIds.all);
            this.updateExportOption(allOpt, this.totalUnfiltered);
        }
    }

    onBulkDownloadClick(): void {
        if (this.selectedQuotes.length > 0) {
            this.quoteDownload.emit(this.selectedQuotes);
        }
    }

    onQuoteAction(emitter: EventEmitter<OpportunityQuote>, quote: OpportunityQuote, event?: MouseEvent): void {
        event?.stopPropagation();
        emitter.emit(quote);
    }

    onSelectTimeRangeFilters(timeRangeFilters: RangeFilters): void {
        this.selectTimeRangeFilters.emit(timeRangeFilters);
    }

    getDownloadTooltipText(disabled: boolean): string {
        return disabled ? 'Select quote to download' : 'Download selected quotes';
    }

    getStatusTooltipText(status: QuoteStatus): string {
        return this.STATUS_TOOLTIP_TEXT.get(status) || '';
    }

    private updateExportOption(option: IExportButtonOption, count: number): void {
        option.disabled = count === 0;
        // Don't want to show 0, just disable it
        option.count = count ? count : null;
    }
}
