import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ListParams } from '../interfaces/list-params';
import { DataPage } from '../interfaces/data-page';
import { ArrayData } from '../models/assessment-array';
import { ApplianceType } from '../models/sustainability-array';
import { GenericService } from './generic.service';

export const defaultParams = {
    pageStart: 0,
    pageSize: 1000,
};

@Injectable({
    providedIn: 'root',
})
export class DataProtectionV2Service extends GenericService<ArrayData> {
    private basePath = '/rest/v1/dpa';

    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArrayData,
            defaultParams: defaultParams,
            endpoint: '/rest/v1/dpa',
        });
    }

    list(params?: ListParams<ArrayData>): Observable<DataPage<ArrayData>> {
        return super.list({ ...params, sort: undefined, extra: 'version=2' });
    }

    setArrayExclusion(applianceId: string, excluded: boolean): Observable<void> {
        const url = `${this.basePath}/arrays/${applianceId}/excluded`;

        const requestPayload = {
            state: excluded,
        };

        return this.http.put<void>(url, requestPayload);
    }

    closeDpaAlert(
        alertId: string,
        applianceId: string,
        applianceType: ApplianceType,
        alertCode: number,
    ): Observable<string> {
        const url = `${this.basePath}/close-alert/${applianceId}`;

        const requestPayload = {
            alertId: alertId,
            applianceType: applianceType,
            alertCode: alertCode,
        };

        return this.http.put(url, requestPayload, { responseType: 'text' });
    }
}
