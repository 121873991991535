import { AdditionalRole, AdditionalRoleAssignment, RoleType } from '@pure1/data';
import { UserRoleItem } from '../user-roles-select/user-role-item';
import { isLegacyRole } from './is-legacy-role';
import { isPendingAssignmentStatus } from './is-pending-assignment-status';

/**
 * Specific function to generate an array of `UserRoleItem` based on the provided roles and user roles.
 * @param roles - An array of `RoleType` or `AdditionalRole` representing the available roles.
 * @param userRoles - An array of `AdditionalRoleAssignment` representing the user's assigned roles.
 * @param readonlyLegacyRole - sets the legacy role to readonly
 * @returns An array of `UserRoleItem` representing the generated user role items.
 */
export function userRoleItemsFactory(
    roles: (RoleType | AdditionalRole)[],
    userRoles: AdditionalRoleAssignment[],
    readonlyLegacyRole: boolean,
): UserRoleItem[] {
    return roles.map(item => {
        if (isLegacyRole(item)) {
            return UserRoleItem.fromLegacy(item, { disabled: readonlyLegacyRole });
        } else {
            const assignment = userRoles.find(({ id }) => id === item.id);

            return UserRoleItem.fromAdditional(item, {
                disabled: isPendingAssignmentStatus(assignment?.status),
            });
        }
    });
}
