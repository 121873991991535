/* eslint-disable rxjs/finnish */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthorizationService } from '@pure/authz-authorizer';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    AuthzAuthorizerService,
    AuthzDomain,
    CachedCurrentUserService,
    CurrentUser,
    DraasAuthzPermission,
    FeatureFlagDxpService,
} from '@pure1/data';
import { combineLatest, forkJoin, merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FeatureNames } from '../model/model';

export type TabTitle =
    | 'DASHBOARD'
    | 'FLEET'
    | 'MARKETPLACE'
    | 'ANALYTICS'
    | 'PROTECTION'
    | 'DRaaS'
    | 'MESSAGES'
    | 'SUPPORT'
    | 'ADMINISTRATION'
    | 'ASSETS';

export interface ITab {
    title: string;
    icon?: string;

    /** Href and path are mutually exclusive. Href is used for external links */
    href?: string;

    /** Href and path are mutually exclusive. Path is used for internal links */
    path?: string;
}

export interface IMainTab extends ITab {
    title: TabTitle;
    subTabs?: ITab[];
}

export interface IPermissions {
    dashboardAllowed?: boolean;
    upgradePrechecksAllowed?: boolean;
    purityOptimizationAllowed?: boolean;
    subscriptionAllowed?: boolean;
    appliancesAllowed?: boolean;
    vmTopologyAllowed?: boolean;
    pxClustersAllowed?: boolean;
    performanceAllowed?: boolean;
    capacityAllowed?: boolean;
    planningAllowed?: boolean;
    reportingAllowed?: boolean;
    snapshotsAllowed?: boolean;
    periodicAllowed?: boolean;
    continuousAllowed?: boolean;
    clusteringAllowed?: boolean;
    disasterRecoveryAllowed?: boolean;
    alertsAllowed?: boolean;
    auditLogAllowed?: boolean;
    sessionLogAllowed?: boolean;
    supportCasesAllowed?: boolean;
    edgeserviceAllowed?: boolean;
    apikeysAllowed?: boolean;
    ssoAllowed?: boolean;
    usersAllowed?: boolean;
    patchableArraysAllowed?: boolean;
    securityPatchingAllowed?: boolean;
    dataProtectionAllowed?: boolean;
    securityAssessmentAllowed?: boolean;
    sustainabilityAllowed?: boolean;
}

export interface IResponse {
    currentUser: CurrentUser;
    isIDPTabAvailable?: boolean;
    needSecurityPatchFb20240115?: boolean;
    securityPatchFb20240115?: FeatureFlagStatus;
    serviceCatalogFeatureFlag?: FeatureFlagStatus;
    dataProtectionV2FeatureFlag?: FeatureFlagStatus;
    securityAssessmentFeatureFlag?: FeatureFlagStatus;
    sustainabilityFeatureFlag?: FeatureFlagStatus;
    purityOptimizationFeatureFlag?: FeatureFlagStatus;
    dRaaSFeatureFlag?: FeatureFlagStatus;
    dRaaSPermissionList?: string[];
    assetManagementFeatureFlag?: FeatureFlagStatus;
    safemodeMultipartyAuthFeatureFlag?: FeatureFlagStatus;
    identityCenterFeatureFlag?: FeatureFlagStatus;
}

export type TabMap = Map<TabTitle, IMainTab>;

export const TAB_ORDER: Readonly<TabTitle[]> = Object.freeze(<TabTitle[]>[
    'DASHBOARD',
    'MARKETPLACE',
    'ASSETS',
    'FLEET',
    'ANALYTICS',
    'PROTECTION',
    'DRaaS',
    'MESSAGES',
    'SUPPORT',
    'ADMINISTRATION',
]);

@Injectable({ providedIn: 'root' })
export class TabsService {
    allTabsSet = false;

    readonly tabs$ = new ReplaySubject<TabMap>(1);

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private authorizationService: AuthorizationService,
        private http: HttpClient,
        private featureFlagDxpService: FeatureFlagDxpService,
        private authorizerService: AuthzAuthorizerService,
    ) {
        combineLatest([
            combineLatest({
                upgradePrechecksAllowed: this.authorizationService.isAllowed('PURE1:read:upgrade_prechecks'),
                purityOptimizationAllowed: this.authorizationService.isAllowed('PURE1:read:purity_optimization'),
                subscriptionAllowed: this.authorizationService.isAllowed('PURE1:read:subscription'),
                dashboardAllowed: this.authorizationService.isAllowed('PURE1:read:dashboard'),
                appliancesAllowed: this.authorizationService.isAllowed('PURE1:read:appliances'),
                vmTopologyAllowed: this.authorizationService.isAllowed('PURE1:read:analytic_vmtopology'),
                pxClustersAllowed: this.authorizationService.isAllowed('PURE1:read:px_cluster'),
                performanceAllowed: this.authorizationService.isAllowed('PURE1:read:analytic_performance'),
                capacityAllowed: this.authorizationService.isAllowed('PURE1:read:analytic_capacity'),
                planningAllowed: this.authorizationService.isAllowed('PURE1:read:analytic_planning'),
                reportingAllowed: this.authorizationService.isAllowed('PURE1:read:analytic_reporting'),
                snapshotsAllowed: this.authorizationService.isAllowed('PURE1:read:protection_snapshots'),
                periodicAllowed: this.authorizationService.isAllowed('PURE1:read:protection_periodic'),
                continuousAllowed: this.authorizationService.isAllowed('PURE1:read:protection_continuous'),
                clusteringAllowed: this.authorizationService.isAllowed('PURE1:read:protection_clustering'),
                disasterRecoveryAllowed: this.authorizationService.isAllowed('PURE1:read:disaster_recovery'),
                alertsAllowed: this.authorizationService.isAllowed('PURE1:read:alert'),
                auditLogAllowed: this.authorizationService.isAllowed('PURE1:read:messages_auditlog'),
                sessionLogAllowed: this.authorizationService.isAllowed('PURE1:read:messages_sessionlog'),
                supportCasesAllowed: this.authorizationService.isAllowed('PURE1:read:support_cases'),
                edgeserviceAllowed: this.authorizationService.isAllowed('PURE1:read:ems'),
                apikeysAllowed: this.authorizationService.isAllowed('PURE1:read:apikey'),
                ssoAllowed: this.authorizationService.isAllowed('PURE1:read:sso'),
                usersAllowed: this.authorizationService.isAllowed('PURE1:read:users'),
                patchableArraysAllowed: this.authorizationService.isAllowed('PURE1:read:patchable_arrays'),
                securityPatchingAllowed: this.authorizationService.isAllowed('PURE1:read:security_patching'),
                dataProtectionAllowed: this.authorizationService.isAllowed('PURE1:read:assessment_data_protection'),
                securityAssessmentAllowed: this.authorizationService.isAllowed('PURE1:read:assessment_security'),
                sustainabilityAllowed: this.authorizationService.isAllowed('PURE1:read:assessment_sustainability'),
            }),
            merge(
                /** The first forkJoin takes care of the base rendering of the sidebar, while
                 * the second one takes care of showing items that require certain authorizations
                 */
                forkJoin({
                    onlyUserRequests: of(true),
                    currentUser: this.cachedCurrentUserService.get().pipe(take(1)),
                }),
                combineLatest({
                    onlyUserRequests: of(false),
                    currentUser: this.cachedCurrentUserService.get().pipe(take(1)),
                    isIDPTabAvailable: this.isIDPTabAvailable(),
                    needSecurityPatchFb20240115: this.arraysSecurityPatchFb20240115(),
                    securityPatchFb20240115: this.featureFlagDxpService.getFeatureFlag(
                        FeatureNames.SECURITY_PATCH_FB_2024_01_15,
                    ),
                    serviceCatalogFeatureFlag: this.featureFlagDxpService.getFeatureFlag(FeatureNames.SERVICE_CATALOG),
                    dataProtectionV2FeatureFlag: this.featureFlagDxpService.getFeatureFlag(
                        FeatureNames.DATA_PROTECTION_V2,
                    ),
                    securityAssessmentFeatureFlag: this.featureFlagDxpService.getFeatureFlag(
                        FeatureNames.SECURITY_ASSESSMENT,
                    ),
                    sustainabilityFeatureFlag: this.featureFlagDxpService.getFeatureFlag(FeatureNames.SUSTAINABILITY),
                    purityOptimizationFeatureFlag: this.featureFlagDxpService.getFeatureFlag(
                        FeatureNames.PURITY_OPTIMIZATION,
                    ),
                    dRaaSFeatureFlag: this.featureFlagDxpService.getFeatureFlag(FeatureNames.DRAAS),
                    dRaaSPermissionList: this.authorizerService.getPermissionList(AuthzDomain.DRAAS),
                    assetManagementFeatureFlag: this.featureFlagDxpService.getFeatureFlag(
                        FeatureNames.ASSET_MANAGEMENT,
                    ),
                    identityCenterFeatureFlag: this.featureFlagDxpService.getFeatureFlag(FeatureNames.IDENTITY_CENTER),
                    safemodeMultipartyAuthFeatureFlag: this.featureFlagDxpService.getFeatureFlag(
                        FeatureNames.SAFE_MODE_MULTIPARTY_AUTH,
                    ),
                }),
            ),
        ]).subscribe(([permissions, response]) => {
            const tabs = this.toTabs(permissions, response);
            if (response.onlyUserRequests && !this.allTabsSet) {
                this.tabs$.next(tabs);
            } else {
                this.allTabsSet = true;
                this.tabs$.next(tabs);
            }
        });
    }

    toTabs(permissions: IPermissions, response: IResponse): TabMap {
        const tabs: TabMap = new Map<TabTitle, IMainTab>();
        const dashboard = this.getDashboardTabs(
            response.dataProtectionV2FeatureFlag,
            permissions.dataProtectionAllowed,
            response.securityAssessmentFeatureFlag,
            permissions.securityAssessmentAllowed,
            response.sustainabilityFeatureFlag,
            permissions.sustainabilityAllowed,
            response.purityOptimizationFeatureFlag,
            permissions.dashboardAllowed,
            permissions.purityOptimizationAllowed,
            permissions.upgradePrechecksAllowed,
        );
        if (dashboard) {
            tabs.set('DASHBOARD', dashboard);
        }
        const marketplace = this.getMarketplaceTabs(
            response.serviceCatalogFeatureFlag,
            permissions.subscriptionAllowed,
        );
        if (marketplace) {
            tabs.set('MARKETPLACE', marketplace);
        }
        const assetManagement = this.getAssetManagementTab(response.assetManagementFeatureFlag);
        if (assetManagement) {
            tabs.set('ASSETS', assetManagement);
        }
        const fleet = this.getFleetTabs(
            response.currentUser,
            permissions.appliancesAllowed,
            permissions.subscriptionAllowed,
            permissions.vmTopologyAllowed,
            permissions.pxClustersAllowed,
        );
        if (fleet) {
            tabs.set('FLEET', fleet);
        }
        const analytics = this.getAnalyticsTabs(
            permissions.performanceAllowed,
            permissions.appliancesAllowed,
            permissions.pxClustersAllowed,
            permissions.capacityAllowed,
            permissions.planningAllowed,
            permissions.reportingAllowed,
        );
        if (analytics) {
            tabs.set('ANALYTICS', analytics);
        }
        const protection = this.getProtectionTabs(
            permissions.snapshotsAllowed,
            permissions.periodicAllowed,
            permissions.continuousAllowed,
            permissions.clusteringAllowed,
        );
        if (protection) {
            tabs.set('PROTECTION', protection);
        }
        const disasterRecovery = this.getDisasterRecoveryTabs(
            permissions.disasterRecoveryAllowed,
            response.dRaaSFeatureFlag,
            response.dRaaSPermissionList,
        );
        if (disasterRecovery) {
            tabs.set('DRaaS', disasterRecovery);
        }
        const messages = this.getMessagesTab(
            permissions.alertsAllowed,
            permissions.auditLogAllowed,
            permissions.sessionLogAllowed,
            response.safemodeMultipartyAuthFeatureFlag,
        );
        if (messages) {
            tabs.set('MESSAGES', messages);
        }
        const support = this.getSupportTab(permissions.supportCasesAllowed);
        if (support) {
            tabs.set('SUPPORT', support);
        }
        const administration = this.getAdministrationTab(
            response.needSecurityPatchFb20240115,
            response.securityPatchFb20240115,
            response.isIDPTabAvailable,
            permissions.edgeserviceAllowed,
            permissions.apikeysAllowed,
            permissions.ssoAllowed,
            response.identityCenterFeatureFlag,
            permissions.usersAllowed,
            permissions.patchableArraysAllowed,
            permissions.securityPatchingAllowed,
        );
        if (administration) {
            tabs.set('ADMINISTRATION', administration);
        }

        return tabs;
    }

    getTabs(): Observable<TabMap> {
        return this.tabs$.asObservable();
    }

    getTabsOrder(): Readonly<TabTitle[]> {
        return TAB_ORDER;
    }

    refreshTabs(): void {
        this.authorizerService.refreshPermissionList(AuthzDomain.DRAAS);
    }

    private getDashboardTabs(
        dataProtectionV2FeatureFlag: FeatureFlagStatus,
        dataProtectionPermission: boolean,
        securityAssessmentFeatureFlag: FeatureFlagStatus,
        securityAssessmentPermission: boolean,
        sustainabilityFeatureFlag: FeatureFlagStatus,
        sustainabilityPermission: boolean,
        purityOptimizationFeatureFlag: FeatureFlagStatus,
        dashboardAllowed: boolean,
        purityOptimizationAllowed: boolean,
        upgradePrechecksAllowed: boolean,
    ): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'DASHBOARD',
            path: '/dashboard',
            icon: 'sidenav-dashboard.svg',
            subTabs: [],
        };
        if (dashboardAllowed) {
            mainTab.subTabs.push({
                title: 'Overview',
                path: '/dashboard/overview',
            });
        }
        if (
            (dataProtectionPermission && dataProtectionV2FeatureFlag?.enabled) ||
            (sustainabilityPermission && sustainabilityFeatureFlag?.enabled) ||
            (securityAssessmentPermission && securityAssessmentFeatureFlag?.enabled)
        ) {
            mainTab.subTabs.push({
                title: 'Assessment',
                path: '/dashboard/assessment',
            });
        }

        const purityOptimizationFlagEnabled = purityOptimizationFeatureFlag?.enabled === true;
        const purityOptimizationsPageIsVisible = purityOptimizationFlagEnabled && purityOptimizationAllowed;
        if (purityOptimizationsPageIsVisible || upgradePrechecksAllowed) {
            mainTab.subTabs.push({
                title: 'Software Lifecycle',
                path: '/dashboard/software-lifecycle',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getMarketplaceTabs(
        serviceCatalogFeatureFlag: FeatureFlagStatus,
        subscriptionAllowed: boolean,
    ): IMainTab | null {
        if (subscriptionAllowed && serviceCatalogFeatureFlag?.enabled) {
            const servicesMenuTabs: IMainTab = {
                title: 'MARKETPLACE',
                icon: 'sidenav-marketplace.svg',
                path: '/services/servicecatalog',
                subTabs: [
                    {
                        title: 'Catalog',
                        path: '/services/servicecatalog/main',
                    },
                    {
                        title: 'Orders',
                        path: '/services/servicecatalog/orders',
                    },
                ],
            };

            return servicesMenuTabs;
        }
        return null;
    }

    private getFleetTabs(
        currentUser: CurrentUser,
        appliancesAllowed: boolean,
        subscriptionAllowed: boolean,
        vmTopologyAllowed: boolean,
        pxClustersAllowed: boolean,
    ): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'FLEET',
            path: '/fleet',
            icon: 'sidenav-fleet.svg',
            subTabs: [],
        };

        if (appliancesAllowed) {
            mainTab.subTabs.push({
                title: 'Appliances',
                path: '/fleet/appliances',
            });
        }
        if (subscriptionAllowed) {
            mainTab.subTabs.push({
                title: 'Subscriptions',
                path: '/fleet/subscriptions',
            });
        }
        if (vmTopologyAllowed) {
            mainTab.subTabs.push({
                title: 'Virtual Machines',
                path: '/fleet/topology',
            });
        }
        if (pxClustersAllowed) {
            mainTab.subTabs.push({
                title: 'Containers',
                path: '/fleet/clusters',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getAnalyticsTabs(
        performanceAllowed: boolean,
        appliancesAllowed: boolean,
        pxClustersAllowed: boolean,
        capacityAllowed: boolean,
        planningAllowed: boolean,
        reportingAllowed: boolean,
    ): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'ANALYTICS',
            path: '/analysis',
            icon: 'sidenav-analytics.svg',
            subTabs: [],
        };
        if (performanceAllowed && appliancesAllowed) {
            mainTab.subTabs.push({
                title: 'Performance',
                path: '/analysis/performance',
            });
        } else if (pxClustersAllowed) {
            // if the user doesn't have access to appliances, but does have portworx metrics, take them to the px volumes page
            mainTab.subTabs.push({
                title: 'Performance',
                path: '/analysis/performance/pxvolumes',
            });
        }

        if (capacityAllowed) {
            mainTab.subTabs.push({
                title: 'Capacity',
                path: '/analysis/capacity',
            });
        }
        if (planningAllowed) {
            mainTab.subTabs.push({
                title: 'Planning',
                path: '/analysis/planning',
            });
        }
        if (reportingAllowed) {
            mainTab.subTabs.push({
                title: 'Reporting',
                path: '/analysis/reporting',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getProtectionTabs(
        snapshotsAllowed: boolean,
        periodicAllowed: boolean,
        continuousAllowed: boolean,
        clusteringAllowed: boolean,
    ): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'PROTECTION',
            path: '/protection',
            icon: 'sidenav-protection.svg',
            subTabs: [],
        };

        if (snapshotsAllowed) {
            mainTab.subTabs.push({
                title: 'Snapshots',
                path: '/protection/snapshots',
            });
        }
        if (periodicAllowed) {
            mainTab.subTabs.push({
                title: 'Periodic',
                path: '/protection/periodic',
            });
        }
        if (continuousAllowed) {
            mainTab.subTabs.push({
                title: 'Continuous',
                path: '/protection/continuous',
            });
        }
        if (clusteringAllowed) {
            mainTab.subTabs.push({
                title: 'ActiveCluster',
                path: '/protection/activecluster',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getDisasterRecoveryTabs(
        disasterRecoveryAllowed: boolean,
        dRaaSFeatureFlag?: FeatureFlagStatus,
        dRaaSPermissionList?: string[],
    ): IMainTab | null {
        if (disasterRecoveryAllowed && dRaaSFeatureFlag?.enabled) {
            const mainTab: IMainTab = {
                title: 'DRaaS',
                path: '/draas',
                icon: 'sidenav-disaster-recovery.svg',
                subTabs: [],
            };

            if (dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_OVERVIEW_READ)) {
                mainTab.subTabs.push({
                    title: 'Overview',
                    path: '/draas/overview',
                });
            }

            if (
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_MONITOR_RECOVERY_READ) ||
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_MONITOR_REPORTS_READ) ||
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_MONITOR_PROTECTION_READ)
            ) {
                mainTab.subTabs.push({
                    title: 'Monitor',
                    path: '/draas/monitor',
                });
            }

            if (
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_SETUP_PLANS_READ) ||
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_SETUP_GROUPS_READ) ||
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_SETUP_POLICIES_READ) ||
                dRaaSPermissionList?.includes(DraasAuthzPermission.UIPAGE_SETUP_SYSTEM_READ)
            ) {
                mainTab.subTabs.push({
                    title: 'Setup',
                    path: '/draas/setup',
                });
            }

            return mainTab;
        }

        return null;
    }

    private getMessagesTab(
        alertsAllowed: boolean,
        auditLogAllowed: boolean,
        sessionLogAllowed: boolean,
        safemodeMultipartyAuthFeatureFlag?: FeatureFlagStatus,
    ): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'MESSAGES',
            path: '/messages',
            icon: 'sidenav-messages.svg',
            subTabs: [],
        };

        if (alertsAllowed) {
            mainTab.subTabs.push({
                title: 'Alerts',
                path: '/messages/alerts',
            });
        }
        if (auditLogAllowed) {
            mainTab.subTabs.push({
                title: 'Audit Log',
                path: '/messages/auditlog',
            });
        }
        if (sessionLogAllowed) {
            mainTab.subTabs.push({
                title: 'Session Log',
                path: '/messages/sessions',
            });
        }
        if (safemodeMultipartyAuthFeatureFlag?.enabled) {
            mainTab.subTabs.push({
                title: 'Requests',
                path: '/messages/requests',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getSupportTab(supportCasesAllowed: boolean): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'SUPPORT',
            path: '/support',
            icon: 'sidenav-support.svg',
            subTabs: [],
        };

        if (supportCasesAllowed) {
            mainTab.subTabs.push({
                title: 'Cases',
                path: '/support',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getAdministrationTab(
        needSecurityPatchFb20240115: boolean,
        securityPatchFb20240115: FeatureFlagStatus,
        idpAvailable: boolean,
        emsAllowed: boolean,
        apikeysAllowed: boolean,
        ssoAllowed: boolean,
        identityCenterFeatureFlag: FeatureFlagStatus,
        usersAllowed: boolean,
        patchableArraysAllowed: boolean,
        securityPatchingAllowed: boolean,
    ): IMainTab | null {
        const mainTab: IMainTab = {
            title: 'ADMINISTRATION',
            path: '/administration',
            icon: 'sidenav-admin.svg',
            subTabs: [],
        };

        if (emsAllowed) {
            mainTab.subTabs.push({
                title: 'Edge Service',
                path: '/administration/edgeservice',
            });
        }

        if (apikeysAllowed) {
            mainTab.subTabs.push({
                title: 'API Registration',
                path: '/administration/apiappkeys',
            });
        }

        if (ssoAllowed && idpAvailable) {
            mainTab.subTabs.push({
                title: 'SSO/AD',
                path: '/administration/customidp/configuration',
            });
        }

        if (usersAllowed) {
            mainTab.subTabs.push({
                title: 'Users',
                path: '/administration/users',
            });

            if (identityCenterFeatureFlag?.enabled) {
                mainTab.subTabs.push({
                    title: 'Views',
                    path: '/administration/views',
                });
            }
        }

        const userRoleCanSecurityPatch = securityPatchingAllowed;

        const orgCanSecurityPatchFb20240115 = securityPatchFb20240115?.enabled === true;
        if (userRoleCanSecurityPatch && orgCanSecurityPatchFb20240115 && needSecurityPatchFb20240115) {
            mainTab.subTabs.push({
                title: 'Patch-2024-01-15',
                path: '/administration/security-patch-fb-2024-01-15',
            });
        }

        return mainTab.subTabs.length > 0 ? mainTab : null;
    }

    private getAssetManagementTab(assetManagementFeatureFlag: FeatureFlagStatus): IMainTab | null {
        if (assetManagementFeatureFlag?.enabled) {
            const mainTab: IMainTab = {
                title: 'ASSETS',
                path: '/assets',
                icon: 'sidenav-asset-management.svg',
                subTabs: [],
            };
            return mainTab;
        }
        return null;
    }

    private arraysSecurityPatchFb20240115(): Observable<boolean> {
        // check if there are any patchable arrays
        return this.http.get('/rest/v1/security-patch-fb-2024-01-15').pipe(
            take(1),
            map((alohaData: any[]) => alohaData && alohaData.length > 0),
            catchError(() => of(false)),
        );
    }

    private isIDPTabAvailable(): Observable<boolean> {
        // Query idp to see if user has access to SSO page
        return this.http.head('/api/1.0/sso-config').pipe(
            take(1),
            map(() => true),
            catchError(() => of(false)),
        );
    }
}
