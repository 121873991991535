<div class="close-button" (click)="closeSidebar()">
    <pureui-svg svg="x-action.svg" height="13"></pureui-svg>
</div>
<div class="pane-selector">
    <ul class="nav navbar-nav" pureTabGroup [selectedTab]="selectedTab" [vertical]="true">
        <li
            *ngFor="let tab of tabs"
            (click)="onTabClick(tab)"
            [ngbTooltip]="tab.tooltipText"
            [pureTabName]="tab.name"
            class="selection-icon"
            container="body"
            placement="left"
        >
            <pureui-svg [svg]="tab.icon" height="20"></pureui-svg>
            <hive-badge
                *ngIf="tab.notificationText"
                class="side-panel-badge"
                emphasis="info"
                [label]="tab.notificationText"
            ></hive-badge>
            <div
                class="side-panel-attention-grabber-bubble"
                *ngIf="tab.notificationText && tab.attentionBubbleText && showAttentionBubbleText"
            >
                <pureui-svg svg="megaphone-notification.svg" height="24"></pureui-svg>
                {{ tab.attentionBubbleText }}
            </div>
        </li>
    </ul>
</div>
<div class="pane-content">
    <ng-template *ngTemplateOutlet="selectedTab?.templateRef"></ng-template>
</div>
