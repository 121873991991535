import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'ssu-banner',
    templateUrl: './ssu-banner.component.html',
    styleUrls: ['./ssu-banner.component.scss'], // TODO: Do not use styleUrls
})
export class SsuBannerComponent {
    readonly purityUpgradesState = '/dashboard/software-lifecycle/purity-upgrades';

    @Output() readonly onNavigateAway: EventEmitter<void> = new EventEmitter<void>();
}
