import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';

import { View } from '@pure1/data';
import { DeferredAssignable } from '../user-role-management.interface';
import { NgbActiveModal, NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';

export type ViewSelection = View | DeferredAssignable | null;

@Component({
    selector: 'view-dropdown',
    templateUrl: 'view-dropdown.component.html',
})
export class ViewDropdownComponent implements OnChanges {
    @Input() readonly views: View[];
    @Input() readonly initialView: View;
    @Input() readonly deferredAssignable: DeferredAssignable;
    @Input() readonly addViewDisabled: boolean;
    @Input() readonly dropdownDisabled: boolean;
    @Input() readonly dropdownDisabledMessage: string;

    @Output() readonly viewChanged: EventEmitter<ViewSelection> = new EventEmitter();
    @Output() readonly addViewSelected: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('dropDown') readonly dropDown: NgbDropdown;

    currentSelection: ViewSelection = null;
    viewToDelete: View;

    constructor(public modalService: NgbModal) {}

    ngOnChanges(): void {
        if (this.deferredAssignable) {
            this.currentSelection = this.deferredAssignable;
            this.viewChanged.emit(this.currentSelection);
        } else {
            this.currentSelection = this.initialView || null;
        }
    }

    currentViewName(): string {
        return this.currentSelection ? this.currentSelection.name : 'Unassigned (All Arrays)';
    }

    selectViewOption(option: ViewSelection): void {
        this.currentSelection = option;
        this.viewChanged.emit(this.currentSelection);
        this.dropDown.close();
    }

    addView(): void {
        this.addViewSelected.emit();
    }

    deleteView(view: View, modal: TemplateRef<NgbActiveModal>): void {
        this.viewToDelete = view;
        this.modalService.open(modal, { backdrop: 'static' });
    }

    isSelected(option: ViewSelection): boolean {
        return (
            (this.currentSelection && option && this.currentSelection.name === option.name) ||
            option === this.currentSelection
        );
    }
}
