import { Component, Input, OnInit } from '@angular/core';
import { getFilters } from '../../utils/filter/conversion';
import { Pill } from '../../gfb/pill';
import { IStateFilter } from '../../redux/pure-redux.service';

@Component({
    selector: 'view-selector',
    templateUrl: './view-selector.component.html',
})
export class ViewSelectorComponent implements OnInit {
    @Input() readonly selector: string;

    pills: Pill[] = [];
    private fullKeyToPill: Map<string, Pill> = new Map();

    ngOnInit(): void {
        if (this.selector) {
            const filters = getFilters(this.selector);
            this.fullKeyToPill.clear();
            filters.forEach(filter => this.toPill(filter));
        }
    }

    private toPill(filter: IStateFilter): void {
        const newPill = new Pill([filter]);
        if (this.fullKeyToPill.has(newPill.fullKey())) {
            const prevPill = this.fullKeyToPill.get(newPill.fullKey());
            prevPill.merge(newPill);
        } else {
            this.pills.push(newPill);
            this.fullKeyToPill.set(newPill.fullKey(), newPill);
        }
    }
}
