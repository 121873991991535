<div class="container">
    <pureui-svg svg="ssu-banner-icon.svg" height="58" width="59"></pureui-svg>
    <div class="text">
        <h4>Expedite your FlashArray upgrade with Self-Service Upgrades.</h4>
        <span
            >Available now for Pure1 Edge Service enabled appliances.
            <a
                href="https://support.purestorage.com/bundle/m_pure1_manage_dashboard/page/Pure1/Pure1_Administration/Pure1_Troubleshooting/topics/reference/r_self-service_purityfa_upgrades_faq.html"
                target="_blank"
                >Learn more</a
            >
            | <a [routerLink]="purityUpgradesState" (click)="onNavigateAway.emit()">Try it now</a>.</span
        >
    </div>
</div>
