<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<section class="asset-table-pagination">
    <pureui-pagination
        [totalItemCount]="total"
        [offset]="offset"
        [itemsPerPage]="pageSize"
        (pageChange)="onPageChange($event)"
    >
    </pureui-pagination>
</section>
<subscription-page-table
    [data]="data"
    [columns]="columns"
    [multiSelect]="isPartner ? null : multiSelectOptions"
    [incidentMap]="incidentMap"
    [selections]="selectedAssets"
    [rowEndTemplate]="isPartner ? null : tableActions"
    [sort]="sort"
    [defaultSort]="defaultSort"
    [loading]="loading"
    [hasStickyCol]="true"
    (selectionsChange)="onSelectionsChange($event)"
    (sortChange)="toggleSort($event)"
    noDataMsg="No assets to display"
>
</subscription-page-table>

<ng-template #tableActions let-asset>
    <div class="asset-management-table-actions">
        <div
            class="disconnected-cloud"
            *ngIf="!asset.isVisibleArray"
            ngbTooltip="Not phoned home indicates Pure Storage products that have not yet sent telemetry to Pure1."
            container="body"
            placement="left"
        >
            <pureui-svg svg="disconnected-cloud.svg" height="18"></pureui-svg>
        </div>
        <div
            class="tags-icon table-action"
            *ngIf="isPartner && asset.isVisibleArray && asset.cloudTags?.length > 0"
            (mouseenter)="openPopover(tagsPopover, asset)"
            triggers="manual"
            [autoClose]="false"
            (mouseleave)="tagsPopover.close()"
            popoverClass="asset-tags-menu-popover"
            [ngbPopover]="tagsPopoverTemplate"
            container="body"
            placement="left"
            #tagsPopover="ngbPopover"
        >
            <pureui-svg svg="tags.svg" height="18"></pureui-svg>
        </div>
        <div
            class="actions-button table-action"
            *ngIf="asset.isVisibleArray"
            (click)="openPopover(actionsPopover, asset); $event.stopPropagation()"
            popoverClass="asset-tags-action-popover"
            [ngbPopover]="actionsPopoverTemplate"
            container="body"
            placement="left"
            #actionsPopover="ngbPopover"
            triggers="manual"
        >
            <pureui-svg svg="menu-slim.svg" height="14"></pureui-svg>
        </div>
    </div>
</ng-template>

<ng-template #tagsPopoverTemplate let-asset="asset">
    <ng-container *ngFor="let tag of asset.cloudTags; trackBy: 'key' | trackByProperty">
        <pure-tag [tag]="tag" [editable]="false"></pure-tag>
    </ng-container>
</ng-template>

<ng-template #actionsPopoverTemplate let-asset="asset">
    <div
        class="asset-action"
        (click)="
            assetManagementService.openTagsModal(asset);
            ampli.assetManagementTagging({ 'tagging behavior': 'single array tagging' })
        "
    >
        Add/Manage Tags
    </div>
</ng-template>
