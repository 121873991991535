import moment from 'moment';

export class RealTimeMetrics {
    data: { [key: string]: number };
    timestamp: moment.Moment;
    is_out_of_date: boolean;

    constructor(json: any) {
        this.timestamp = moment(json.time_stamp);
        this.data = json.data;
        this.is_out_of_date = json.is_out_of_date;
    }
}
