import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorizationService, Permission } from '@pure/authz-authorizer';
import { EMPTY, Observable, catchError, concat, first, map, of, pipe, switchMap, take } from 'rxjs';

import { PlatformClientService } from '@pure/pure1-ui-platform-angular';
import { ErrorCode } from '../../error/error-view/error-code';
import { AuthenticationService } from '../../services/authentication.service';

const transitionTo = (url: string) =>
    pipe(
        take(1),
        map((authorized: boolean) => (authorized ? url : undefined)),
    );

/**
 * Create Angular Router CanActivateFn that will check that the user has the required Permission.
 * @param permission Required resource. If null, then only requires the user to be logged in.
 */
export const hasPermissionGuard =
    (permission: Permission) =>
    (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
        const authenticationService = inject(AuthenticationService);
        const authzService = inject(AuthorizationService);
        const platformService = inject(PlatformClientService);
        const router = inject(Router);
        //const tabsService = inject(TabsService); // TODO: https://jira.purestorage.com/browse/CLOUD-123326

        return authenticationService.isLoggedIn().pipe(
            switchMap(isLoggedIn => {
                if (!isLoggedIn) {
                    platformService.login();

                    return EMPTY;
                } else if (permission === null) {
                    return of(true);
                } else {
                    return authzService.isAllowed(permission);
                }
            }),
            switchMap(isAllowed => {
                if (isAllowed) {
                    return of(true);
                }

                return concat(
                    // It's a prioritized list. Order matters!
                    authzService.isAllowed('PURE1:read:dashboard').pipe(transitionTo('/dashboard/overview')),
                    authzService.isAllowed('PURE1:read:appliances').pipe(transitionTo('/fleet/appliances')),
                    authzService.isAllowed('PURE1:read:analytic_vmtopology').pipe(transitionTo('/fleet/topology')),
                    authzService.isAllowed('PURE1:read:px_cluster').pipe(transitionTo('/fleet/clusters/list')),
                ).pipe(
                    first((url: string) => url != null),
                    map((url: string) => router.createUrlTree([url])),
                    catchError((_error: any) => {
                        console.error('hasPermissionGuard: No default state found for user due to lack of permissions');

                        return platformService.getIntrospect().pipe(
                            // catch error in case getIntrospect throws for some reason
                            catchError(() => of(null)),
                            map(introspect => Boolean(introspect?.act)),
                            map(isImpersonating =>
                                router.createUrlTree(['/errors'], {
                                    queryParams: {
                                        code: isImpersonating ? ErrorCode.CANNOT_IMPERSONATE_USER : ErrorCode.FORBIDDEN,
                                    },
                                }),
                            ),
                        );
                    }),
                );
            }),
            take(1),
        );
    };
