import moment from 'moment-timezone';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Schedule } from '../../types';
import { Angulartics2 } from 'angulartics2';
import {
    getAngularticsEventName,
    getArrayUniqueName,
} from 'core/src/software-lifecycle/purity-upgrades/purity-upgrades.utils';
import { PartialDeep } from 'lodash';
import { CduAppliance } from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.interface';
import { Router } from '@angular/router';

@Component({
    selector: 'upgrade-scheduled-confirmation',
    templateUrl: 'upgrade-scheduled-confirmation.component.html',
})
export class UpgradeScheduledConfirmationComponent implements OnChanges {
    @Input() readonly caseId: string;
    @Input() readonly schedule: Schedule;
    @Input() readonly createCaseDoneFlag: boolean;
    @Input() readonly createCaseErrorFlag: boolean;
    @Input() readonly email: string;
    @Input() readonly hasDuplicateApplianceNames: boolean;

    @Output() readonly onDismiss: EventEmitter<any> = new EventEmitter();

    // flag indicating whether the scheduled upgrade case has timeslots selected for arrays
    // (used to determine the type of confirmation message shown to the user)
    hasSelectedTimeslots = false; // this default will produce the more generic message

    constructor(
        private router: Router,
        private angulartics2: Angulartics2,
    ) {}

    ngOnChanges({ schedule }: SimpleChanges): void {
        if (schedule) {
            const firstArray = schedule.currentValue.schedule[0];
            this.hasSelectedTimeslots = !!firstArray.timeslot;
        }
    }

    printPrettyTime(time: moment.Moment): string {
        if (time) {
            return time.format('ddd, MMM D, HH:mm z');
        }
        return '';
    }

    upgradeOnLinkClick(caseId: string): void {
        this.angulartics2.eventTrack.next({
            action: getAngularticsEventName('Upgrade Scheduler - Navigate to Cases'),
            properties: { eventType: 'click' },
        });
        this.router.navigate(['/support/cases'], { queryParams: { caseId: caseId } });
    }

    getArrayName = (array: PartialDeep<CduAppliance>): string =>
        getArrayUniqueName(array, this.hasDuplicateApplianceNames);
}
