import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedResult } from '../../../software-lifecycle/purity-upgrades/purity-upgrades.interface';

export enum MpaRequestType {
    SAFE_MODE_CHANGE = 'SAFE_MODE_CHANGE',
    SAFE_MODE_ROLE_ASSIGNMENT = 'SAFE_MODE_ROLE_ASSIGNMENT',
    SAFE_MODE_CHANGE_APPROVAL_POLICY = 'SAFE_MODE_CHANGE_APPROVAL_POLICY',
}

export enum MpaRequestStatus {
    SCHEDULED = 'SCHEDULED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    AUTHORIZED = 'AUTHORIZED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
}

export interface MpaStepUpRequestDetails {
    requestId: number;
}

export interface MpaRequest {
    id: number;
    useCaseType: MpaRequestType;
    externalId?: string;
    status: MpaRequestStatus;
    summary: string;
    details: { [key: string]: any };
    approvalPeriodEndTime: moment.Moment;
    createTime: moment.Moment;
    lastUpdateTime: moment.Moment;
    stepUpRequestDetails: MpaStepUpRequestDetails;
    userCanApprove: boolean;
    userApproved: boolean;
}

const multipartyPath = '/multiparty/mpa/requests';

@Injectable({
    providedIn: 'root',
})
export class MpaRequestsService {
    constructor(private http: HttpClient) {}

    listMpaRequests(activeOnly?: boolean): Observable<PagedResult<MpaRequest>> {
        let url = multipartyPath;
        activeOnly ? (url += '?activeOnly=true') : null;
        return this.http.get<PagedResult<MpaRequest>>(url).pipe(
            map(response => ({
                ...response,
                content: response.content.map(this.formatMpaRequest),
            })),
        );
    }

    approveMpaRequest(requestId: number, accessToken: string): Observable<MpaRequest> {
        return this.http
            .post<MpaRequest>(
                `${multipartyPath}/${requestId}/approve`,
                {},
                { headers: { 'Additional-Authorization': `Bearer ${accessToken}` } },
            )
            .pipe(map(this.formatMpaRequest));
    }

    rejectMpaRequest(requestId: number, accessToken: string): Observable<MpaRequest> {
        return this.http
            .post<MpaRequest>(
                `${multipartyPath}/${requestId}/reject`,
                {},
                { headers: { 'Additional-Authorization': `Bearer ${accessToken}` } },
            )
            .pipe(map(this.formatMpaRequest));
    }

    private formatMpaRequest = (multipartyRequest: MpaRequest): MpaRequest => {
        return {
            ...multipartyRequest,
            approvalPeriodEndTime: multipartyRequest.approvalPeriodEndTime
                ? moment(multipartyRequest.approvalPeriodEndTime)
                : null,
            createTime: multipartyRequest.createTime ? moment(multipartyRequest.createTime) : null,
            lastUpdateTime: multipartyRequest.lastUpdateTime ? moment(multipartyRequest.lastUpdateTime) : null,
        };
    };
}
