import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from '@pure/authz-authorizer';
import { HIVE_THEMING, Themes } from '@pure/hive';
import { FilterParams, GFBEntity, OpportunityQuote, OpportunityQuoteService } from '@pure1/data';
import moment from 'moment';
import { Action } from 'redux';
import { BehaviorSubject, take } from 'rxjs';
import { WINDOW } from '../../app/injection-tokens';
import { FileDownloaderService } from '../../export/services/file-downloader.service';
import { PagedDataComponent2 } from '../../gui/paged-data2.component';
import {
    CREATED_DATE,
    DEFAULT_NAMESPACE,
    EXPIRATION_DATE,
    LOCAL_BAR_QUOTE_MANAGEMENT,
    LOCAL_BAR_QUOTE_MANAGEMENT_HIDDEN,
    NAME_KEY,
    OPPORTUNITY_QUOTES_ENTITY,
    TIME_END_KEY,
    TIME_START_KEY,
    addFilter,
    removeFiltersByKey,
} from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { UrlService } from '../../redux/url.service';
import { createFilter } from '../../redux/utils';
import { RangeFilters } from './quote-management-range-filters/quote-management-range-filters.component';

@Component({
    selector: 'quote-management-view',
    templateUrl: './quote-management-view.component.html',
    providers: [
        {
            provide: HIVE_THEMING,
            useValue: new BehaviorSubject<Themes>(Themes.UINext),
        },
        FileDownloaderService,
    ],
})
export class QuoteManagementViewComponent extends PagedDataComponent2<OpportunityQuote> {
    pageSize = 25;
    quoteToReview: OpportunityQuote;
    total = 0;
    totalUnfiltered = 0;
    defaultApiSortKey = NAME_KEY;
    selectedRangeFilters: RangeFilters = {
        startRange: null,
        endRange: null,
    };
    hasWritePermission = false;

    readonly DEFAULT_START_TIME = moment.utc().subtract(1, 'year');
    readonly DEFAULT_END_TIME = moment.utc().add(3, 'year');
    readonly DEFAULT_KEY = NAME_KEY;
    readonly DEFAULT_ENTITY = OPPORTUNITY_QUOTES_ENTITY;
    readonly GFB_ENTITIES: GFBEntity[] = [OPPORTUNITY_QUOTES_ENTITY];

    private readonly hiddenBarId = LOCAL_BAR_QUOTE_MANAGEMENT_HIDDEN;
    private readonly quoteCreationDateStartKey = `${CREATED_DATE}.${TIME_START_KEY}`;
    private readonly quoteCreationDateEndKey = `${CREATED_DATE}.${TIME_END_KEY}`;
    private readonly quoteExpirationDateStartKey = `${EXPIRATION_DATE}.${TIME_START_KEY}`;
    private readonly quoteExpirationDateEndKey = `${EXPIRATION_DATE}.${TIME_END_KEY}`;
    private readonly DEFAULT_DATE_FILTER: FilterParams<OpportunityQuote> = {
        created_date: `${CREATED_DATE}>=${this.DEFAULT_START_TIME.valueOf()} and ${CREATED_DATE}<=${this.DEFAULT_END_TIME.valueOf()}`,
    };

    @ViewChild('quoteManagementModal') readonly quoteManagementModal: TemplateRef<any>;
    @ViewChild('quoteRequestChangesModal') readonly quoteRequestChangesModal: TemplateRef<any>;
    @ViewChild('quoteAcceptModal') readonly quoteAcceptModal: TemplateRef<any>;
    @ViewChild('quotePOUploadModal') readonly quotePOUploadModal: TemplateRef<any>;

    constructor(
        @Inject(WINDOW) private window: Window,
        private ngbModal: NgbModal,
        private fileDownloaderService: FileDownloaderService,
        private authzService: AuthorizationService,
        protected opportunityQuoteService: OpportunityQuoteService,
        protected ngRedux: NgRedux<IState>,
        protected url: UrlService,
    ) {
        super(
            opportunityQuoteService,
            ngRedux,
            url,
            new QuoteManagementUrlReaderWriter(),
            LOCAL_BAR_QUOTE_MANAGEMENT,
            OPPORTUNITY_QUOTES_ENTITY,
            DEFAULT_NAMESPACE,
        );

        this.authzService.isAllowed('PURE1:write:subscriptions_renew').subscribe(hasWritePermission => {
            this.hasWritePermission = hasWritePermission;
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.opportunityQuoteService
            .list({
                filter: this.DEFAULT_DATE_FILTER,
            })
            .pipe(take(1))
            .subscribe(({ response, total }) => {
                this.totalUnfiltered = total;
            });
    }

    onTimeFilterChanges(timeRangeOption: RangeFilters): void {
        this.selectedRangeFilters = timeRangeOption;
        const actions: Action[] = [
            removeFiltersByKey(this.hiddenBarId, this.quoteCreationDateStartKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.quoteCreationDateEndKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.quoteExpirationDateStartKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.quoteExpirationDateEndKey, DEFAULT_NAMESPACE),
        ];
        if (timeRangeOption.startRange) {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.quoteCreationDateStartKey,
                        timeRangeOption.startRange?.start.valueOf().toString(),
                    ),
                ),
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.quoteCreationDateEndKey,
                        timeRangeOption.startRange?.end.valueOf().toString(),
                    ),
                ),
            );
        }
        if (timeRangeOption.endRange) {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.quoteExpirationDateStartKey,
                        timeRangeOption.endRange?.start.valueOf().toString(),
                    ),
                ),
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.quoteExpirationDateEndKey,
                        timeRangeOption.endRange?.end.valueOf().toString(),
                    ),
                ),
            );
        }
        this.ngRedux.dispatch(actions);
    }

    onBeforeUpdateParams(): void {
        this.handleRedux();
    }

    onBeforeFetchData(): void {
        if (this.listCallParameters) {
            this.listCallParameters.filter = this.appendTimeFilters(this.listCallParameters.filter);
        }
    }

    onAfterFetchFailed(_: Error): void {
        this.loading = false;
    }

    private appendTimeFilters(existingFilter: FilterParams<OpportunityQuote>): FilterParams<OpportunityQuote> {
        //Default time limitation is for the past 1 year
        const newFilters: FilterParams<OpportunityQuote> = {
            ...existingFilter,
            ...this.DEFAULT_DATE_FILTER,
        };
        //If there the time range selection is selected
        if (this.selectedRangeFilters.startRange) {
            newFilters[CREATED_DATE] =
                `${CREATED_DATE}>${this.selectedRangeFilters.startRange.start.valueOf() - 1} and ${CREATED_DATE}<${this.selectedRangeFilters.startRange.end.valueOf() + 1}`;
        }
        if (this.selectedRangeFilters.endRange) {
            newFilters[EXPIRATION_DATE] =
                `${EXPIRATION_DATE}>${this.selectedRangeFilters.endRange.start.valueOf() - 1} and ${EXPIRATION_DATE}<${this.selectedRangeFilters.endRange.end.valueOf() + 1}`;
        }
        return newFilters;
    }

    private handleRedux(): void {
        const state = this.ngRedux.getState();
        // Need hidden since the regular barId is managed by PagedData. Would prefer to not introduce a new slice
        const filters = state.filters[this.hiddenBarId];

        if (filters) {
            const startDateStartFilter = filters.find(filter => filter.key === this.quoteCreationDateStartKey);
            const endDateStartFilter = filters.find(filter => filter.key === this.quoteExpirationDateStartKey);
            if (startDateStartFilter) {
                this.selectedRangeFilters.startRange = {
                    start: moment.utc(Number(startDateStartFilter.value)),
                    end: moment.utc(Number(filters.find(filter => filter.key === this.quoteCreationDateEndKey).value)),
                };
            }
            if (endDateStartFilter) {
                this.selectedRangeFilters.endRange = {
                    start: moment.utc(Number(endDateStartFilter.value)),
                    end: moment.utc(
                        Number(filters.find(filter => filter.key === this.quoteExpirationDateEndKey).value),
                    ),
                };
            }
        }
    }

    onPageSizeChange(pageSize: number): void {
        this.urlReaderWriter.itemsPerPage = pageSize;
        this.pageSize = pageSize;
        this.fetchData();
    }

    onQuoteClick(opportunityQuote: OpportunityQuote): void {
        this.loading = true;
        this.opportunityQuoteService.getDownloadLink([opportunityQuote]).subscribe({
            next: links => {
                this.loading = false;
                if (links?.length > 0) {
                    this.window.open(links[0].download_link, '_blank');
                }
            },
            error: _ => {
                this.loading = false;
            },
        });
    }

    onQuoteDownload(opportunityQuotes: OpportunityQuote[]): void {
        this.loading = true;
        const quoteNameMap = new Map(
            opportunityQuotes.map(quote => {
                return [quote.id, quote.name];
            }),
        );

        this.opportunityQuoteService.getDownloadLinksAsBlobs(opportunityQuotes).subscribe({
            next: blobs => {
                this.loading = false;
                blobs.forEach(quoteBlob => {
                    const fileName = quoteNameMap.get(quoteBlob.id);
                    this.fileDownloaderService.downloadBlob(quoteBlob.blob, fileName);
                });
            },
            error: _ => {
                this.loading = false;
            },
        });
    }

    onQuoteRequestChanges(opportunityQuote: OpportunityQuote): void {
        this.quoteToReview = opportunityQuote;
        this.ngbModal.open(this.quoteRequestChangesModal);
    }

    onQuoteAccept(opportunityQuote: OpportunityQuote): void {
        this.quoteToReview = opportunityQuote;
        this.ngbModal.open(this.quoteAcceptModal, { size: 'sm' });
    }

    onQuotePOUpload(opportunityQuote: OpportunityQuote): void {
        this.quoteToReview = opportunityQuote;
        this.ngbModal.open(this.quotePOUploadModal);
    }
}

class QuoteManagementUrlReaderWriter extends UrlReaderWriter {
    path = /^\/assets\/quote-management/;
    localBarId = LOCAL_BAR_QUOTE_MANAGEMENT;

    constructor() {
        super();
    }
}
