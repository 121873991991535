import { Component, TemplateRef, ViewChild } from '@angular/core';
import { take, takeUntil, first, catchError } from 'rxjs/operators';
import { cloneDeep, memoize } from 'lodash';
import { Subscription, Subject, of } from 'rxjs';
import {
    AssigneeType,
    CachedCurrentUserService,
    CurrentUser,
    ExternalUsersService,
    GroupsService,
    RoleAssignment,
    RoleAssignmentService,
    SortParams,
    ViewsService,
    UsersService,
    AdditionalUserRolesService,
} from '@pure1/data';

import { DrawerState, MainPageState, UserRoleStateService } from '../services/user-role-state.service';
import {
    IColumnOption,
    ISearchParameters,
    ITableConfigOptions,
} from '../../ui/components/performance-table/performance-table.component';
import { LOCAL_BAR_USERS } from '../../redux/actions';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { consolidateFilterValues, IMultiValueFilter } from '../../gui/paged-data2.component';
import { UrlService } from '../../redux/url.service';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { SpinnerService } from 'core/src/services/spinner.service';
import { ampli } from 'core/src/ampli';

type AssignmentFilter = { [key: string]: string[] };

const PATH = /^\/administration\/users/;
const ORGANIZATION_KEY = 'organization';
const ORGANIZATION_VALUE_THIS_ORG = 'this org';
const VIEW_KEY = 'view_name';
const NAME_KEY = 'name';
const ROLE_KEY = 'role';

const DEFAULT_FILTER: AssignmentFilter = {};
const DEFAULT_SORT: SortParams = {
    key: 'name',
    order: 'desc',
};
const WILDCARD_FIELDS = [VIEW_KEY, NAME_KEY, ROLE_KEY];

@Component({
    selector: 'user-groups-view',
    templateUrl: './user-groups-view.component.html',
    providers: [UserRoleStateService],
})
export class UserGroupsViewComponent {
    currentUser: CurrentUser;

    @ViewChild('roleLink', { static: true }) roleLink: TemplateRef<any>;
    @ViewChild('editViewLink', { static: true }) editViewLink: TemplateRef<any>;
    @ViewChild('roleHeader', { static: true }) roleHeader: TemplateRef<any>;

    readonly ADD_EXTERNAL_USER: DrawerState = 'add-external-user';
    readonly ADD_GROUP: DrawerState = 'add-group';
    readonly ADD_USER: DrawerState = 'add-user';
    readonly ADD_SSO_USER: DrawerState = 'add-sso-user';
    readonly VIEW_LIST: DrawerState = 'view-list';

    columnOptions: IColumnOption<RoleAssignment>[] = [
        {
            title: 'Name',
            key: NAME_KEY,
            weight: 3,
            isHidden: false,
            hasSearch: false,
            getSortKey: () => NAME_KEY,
            getText: assignment => assignment.name,
        },
        {
            title: 'Role',
            key: ROLE_KEY,
            weight: 3,
            isHidden: false,
            hasSearch: false,
            getSortKey: () => ROLE_KEY,
            getText: assignment => this.roleLink,
        },
        {
            title: 'View',
            key: VIEW_KEY,
            weight: 3,
            isHidden: false,
            hasSearch: false,
            getSortKey: () => VIEW_KEY,
            getText: assignment => this.editViewLink,
        },
    ];

    // sort
    sort: SortParams | null = null;
    defaultSort = DEFAULT_SORT;
    barId = LOCAL_BAR_USERS;
    // search
    search: AssignmentFilter = cloneDeep(DEFAULT_FILTER);

    sortColumn = NAME_KEY;
    sortAscending = true;
    assignments: RoleAssignment[] = [];
    filteredAssignments: RoleAssignment[] = null;
    selectedAssignments: RoleAssignment[] = [];
    filteredAssignmentsCache: RoleAssignment[] = [];
    subscription: Subscription;

    // Batch opertaions are disabled if NG roles are available since they break existing UX
    ngRolesAvailable = false;
    multiSelectEnabled: boolean;

    tableOptions: ITableConfigOptions<RoleAssignment> = {
        customRowClickOptions: {
            onRowClick: (event: MouseEvent | TouchEvent, item: RoleAssignment) => {
                this.urStateService.editAssignment(item);
                event.stopPropagation();
            },
            resetRecent$: () => this.resetIndividualEdit$,
        },
    };

    private mainPageState: MainPageState;
    private unsubscribeFromUrlService: Function;

    private readonly resetIndividualEdit$ = new Subject<void>();
    private readonly destroy$ = new Subject<void>();

    private searchFnMap = {
        [ORGANIZATION_KEY]: (searchStrings: string[], assignment: RoleAssignment) =>
            searchStrings.some(str =>
                str === ORGANIZATION_VALUE_THIS_ORG
                    ? assignment.assigneeType !== AssigneeType.EXTERNAL
                    : assignment.assigneeType === AssigneeType.EXTERNAL,
            ),
        [VIEW_KEY]: (searchStrings: string[], assignment: RoleAssignment) =>
            searchStrings.some(str => {
                const text = this.getAssignableText(assignment).toLocaleLowerCase();
                return str.toLocaleLowerCase().includes(text);
            }),
        [ROLE_KEY]: (searchStrings: string[], assignment: RoleAssignment) => {
            for (const searchStr of searchStrings) {
                if (searchStr.toLocaleLowerCase() === assignment[ROLE_KEY].toLocaleLowerCase()) {
                    return true;
                }

                if (assignment.additionalRoles.some(role => String(role.id) === searchStr)) {
                    return true;
                }
            }

            return false;
        },
    };

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private ngRedux: NgRedux<IState>,
        private roleAssignmentService: RoleAssignmentService,
        private viewsService: ViewsService,
        private usersService: UsersService,
        private externalUsersService: ExternalUsersService,
        private groupsService: GroupsService,
        public urStateService: UserRoleStateService,
        private url: UrlService,
        private spinnerService: SpinnerService,
        private additionalUserRolesService: AdditionalUserRolesService,
    ) {}

    ngOnInit(): void {
        // after declaration we set the 'Load' title template
        this.columnOptions[1].title = this.roleHeader;
        this.cachedCurrentUserService
            .get()
            .pipe(this.spinnerService.spin('getUserGroupsList'), take(1))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
                this.columnOptions[2].isHidden = false;
                if (this.currentUser.adUser) {
                    this.urStateService.currentMainPageState = 'groups';
                } else {
                    this.urStateService.currentMainPageState = 'users';
                }
                this.mainPageState = this.urStateService.currentMainPageState;
            });

        this.urStateService
            .getDrawerNotifier()
            .pipe(takeUntil(this.destroy$))
            .subscribe(selection => {
                this.onSelectionChange(selection);
            });

        this.additionalUserRolesService
            .list()
            .pipe(catchError(() => of([])))
            .subscribe(roles => {
                this.ngRolesAvailable = roles.length > 0;
                this.multiSelectEnabled = !this.ngRolesAvailable;
            });

        this.unsubscribeFromUrlService = this.url.register(new RoleAssignmentListReaderWriter(), true);
        this.ngRedux
            .select(['filters', this.barId])
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.handleRedux());
        this.refreshData();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.resetIndividualEdit$.complete();
        if (this.unsubscribeFromUrlService) {
            this.unsubscribeFromUrlService();
        }
    }

    isUsers(): boolean {
        return this.mainPageState === 'users';
    }

    getAssignableText(assignment: RoleAssignment): string {
        return assignment.viewName || 'Unassigned (All Arrays)';
    }

    editView(assignment: RoleAssignment, event: MouseEvent | TouchEvent): void {
        this.urStateService.editView({ id: assignment.viewId, name: assignment.viewName });
        event.stopPropagation();
    }

    onSelectionChange(selection: RoleAssignment[]): void {
        ampli.umBatchEditSelectionChanged({
            category: 'Action',
            itemsSelected: selection.length,
        });

        this.selectedAssignments = selection;

        if (!this.ngRolesAvailable) {
            this.urStateService.setSelectedAssignments(selection);
        }
    }

    handleRedux(): void {
        const filters = this.ngRedux.getState().filters[this.barId] || [];
        this.search = this.updateSearch(consolidateFilterValues(filters, WILDCARD_FIELDS));
        this.processAssignments();
    }

    onTableSearchChange(search: ISearchParameters): void {
        this.search[search.key] = search.value.split(',');
        this.processAssignments();
    }

    onSortChange(sort: string): void {
        if (this.sortColumn === sort) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortColumn = sort;
        }

        this.sort = { key: this.sortColumn, order: this.sortAscending ? 'asc' : 'desc' };
        this.processAssignments();
    }

    onActionButtonClick(drawerState: DrawerState): void {
        this.urStateService.openDrawer(drawerState);
        this.resetIndividualEdit$.next();
    }

    onDrawerClose(): void {
        this.urStateService.closeDrawer();
        this.resetIndividualEdit$.next();
    }

    getAssignmentSortPredicate(assignment: RoleAssignment): string {
        if (this.sortColumn === NAME_KEY) {
            return assignment.name;
        } else if (this.sortColumn === ROLE_KEY) {
            return this.getRolesText(assignment);
        } else {
            // sort Column is 'assignment'
            return this.getAssignableText(assignment);
        }
    }

    quickAdd(): void {
        if (this.mainPageState) {
            this.urStateService.openDrawer(this.isUsers() ? this.ADD_USER : this.ADD_GROUP);
        }
    }

    protected processAssignments(): void {
        const getCachedAssignmentSortValue = memoize(this.getAssignmentSortPredicate.bind(this));
        this.filteredAssignments = this.assignments
            .filter((assignment: RoleAssignment) => {
                return Object.keys(this.search).every((key: string) => {
                    const searchStrings = this.search[key];
                    const searchFn = this.searchFnMap[key];

                    return searchFn
                        ? searchFn(searchStrings, assignment)
                        : searchStrings.some(searchString =>
                              String(assignment[key]).toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
                          );
                });
            })
            .sort((assignmentA, assignmentB) => {
                const aName = getCachedAssignmentSortValue(assignmentA);
                const bName = getCachedAssignmentSortValue(assignmentB);
                return this.sortAscending ? aName.localeCompare(bName) : bName.localeCompare(aName);
            });
    }

    protected refreshData(): void {
        this.unsubscribe();
        this.subscription = this.urStateService
            .listWithCache(this.roleAssignmentService, [
                this.viewsService,
                this.usersService,
                this.externalUsersService,
                this.groupsService,
            ])
            .pipe(this.spinnerService.spin('getUserGroupsList'))
            .subscribe(
                data => {
                    this.assignments = data.response;
                    this.processAssignments();
                },
                error => {},
            );
    }

    protected unsubscribe(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    private getRolesText(assignment: RoleAssignment): string {
        return [assignment.role, ...assignment.additionalRoles.map(r => r.name)].join('');
    }

    private updateSearch(filters: IMultiValueFilter[]): AssignmentFilter {
        const filterMap: AssignmentFilter = {};
        filters.forEach((filter: IMultiValueFilter) => {
            if (filter.namespace === null) {
                // ignore tags from table header filters
                filterMap[filter.key] = filter.values.map(value => value.toLocaleLowerCase());
            }
        });
        return filterMap;
    }
}

class RoleAssignmentListReaderWriter extends UrlReaderWriter {
    path = PATH;
    localBarId = LOCAL_BAR_USERS;
}
