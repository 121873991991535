import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FeatureFlagDxpService, ResourceStatus, UnifiedArray } from '@pure1/data';
import { orderBy } from 'lodash';
import { take } from 'rxjs/operators';
import { MessagesModalService } from '../../messages/alerts/messages-modal/messages-modal.service';
import { FeatureNames } from '../../model/FeatureNames';

import { severity } from '../../utils/severity';

@Component({
    selector: 'array-card-back',
    templateUrl: 'array-card-back.component.html',
})
export class ArrayCardBackComponent implements OnChanges, OnInit {
    @Input() readonly array: UnifiedArray;
    @Input() readonly alerts: IAlert[];
    /**
     * [CLOUD-112131] Provides a way to unload the internal elements of the component while leaving the component itself in-place.
     * This is a hack to let the view be lazy-loaded while still retaining the outer container so that it retains
     * the same spacing/layout whether or not the content is shown.
     * This is purely an optimization. If you don't know what it is for or care about it, you can safely leave it unset.
     */
    @Input() readonly removeInternalsFromDom: boolean = false;
    @Output() readonly onFlipCard = new EventEmitter<{ event: Event }>();

    readonly ResourceStatus = ResourceStatus;

    hwEOLEnabled = false;
    sortedAlerts: IAlert[];

    constructor(
        private messagesModal: MessagesModalService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.hwEOLEnabled = feature?.enabled === true;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.alerts) {
            this.updateSortedAlerts();
        }
    }

    flipCard(event: Event): void {
        this.onFlipCard.emit({ event: event });
    }

    alertCount(): number {
        return (this.alerts || []).length + (this.hasEolComponents() ? 1 : 0);
    }

    clickAlert(alert: IAlert): void {
        this.messagesModal.open(alert, alert.getKBLink());
    }

    hasEolComponents(): boolean {
        return this.hwEOLEnabled && this.array.has_end_of_life_hardware;
    }

    private updateSortedAlerts(): void {
        this.sortedAlerts = orderBy(this.alerts || [], [
            alert => severity(alert.currentSeverity).value,
            alert => -alert.updated,
        ]);
    }
}
