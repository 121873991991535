import { Angulartics2Module } from 'angulartics2';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { GfbModule } from '../gfb/gfb.module';
import { StepUpModule } from '../step-up/step-up.module';
import { UIModule } from '../ui/ui.module';

import { DeleteViewModalComponent } from './delete-view-modal/delete-view-modal.component';
import { EditViewComponent } from './edit-view/edit-view.component';
import { UserGroupsViewComponent } from './user-groups-view/user-groups-view.component';
import { UserRoleDrawerPaneComponent } from './user-role-drawer-pane/user-role-drawer-pane.component';
import { ViewDropdownComponent } from './view-dropdown/view-dropdown.component';
import { ViewListComponent } from './view-list/view-list.component';
import { ViewPillComponent } from './view-pill/view-pill.component';
import { ViewSelectorComponent } from './view-selector/view-selector.component';
import { CreateEditUserComponent } from './create-edit-user/create-edit-user.component';
import { UserFormComponent } from './user-form/user-form.component';
import { ExternalUserFormComponent } from './external-user-form/external-user-form.component';
import { CreateEditGroupComponent } from './create-edit-group/create-edit-group.component';
import { GroupFormComponent } from './group-form/group-form.component';
import { BatchEditAssignmentsComponent } from './batch-edit-assignments/batch-edit-assignments.component';
import { DeleteAssignmentsModalComponent } from './delete-assignments-modal/delete-assignments-modal.component';
import { NpsSurveyModule } from '../nps-survey/nps-survey.module';
import { UserRolesSelectComponent } from './user-roles-select/user-roles-select.component';
import { SSOUserFormComponent } from './sso-user-form/sso-user-form.component';
import { CreateEditSSOUserComponent } from './create-edit-sso-user/create-edit-sso-user.component';
import { UserManagementSharedModule } from '../user-management-shared/user-management-shared.module';
import { UserRoleManagementRoutingModule } from './user-role-management-routing.module';
import { AuthorizerModule } from '@pure/authz-authorizer';

@NgModule({
    imports: [
        Angulartics2Module,
        AuthorizerModule,
        CommonModule,
        FormsModule,
        GfbModule,
        NgbModule,
        NgSelectModule,
        NpsSurveyModule,
        ReactiveFormsModule,
        StepUpModule,
        UIModule,
        UserRoleManagementRoutingModule,
        StepUpModule,
        UserManagementSharedModule,
    ],
    declarations: [
        BatchEditAssignmentsComponent,
        CreateEditGroupComponent,
        CreateEditUserComponent,
        DeleteAssignmentsModalComponent,
        DeleteViewModalComponent,
        EditViewComponent,
        ExternalUserFormComponent,
        GroupFormComponent,
        UserGroupsViewComponent,
        UserFormComponent,
        UserRoleDrawerPaneComponent,
        UserRolesSelectComponent,
        ViewDropdownComponent,
        ViewListComponent,
        ViewPillComponent,
        ViewSelectorComponent,
        SSOUserFormComponent,
        CreateEditSSOUserComponent,
    ],
    exports: [EditViewComponent, ViewSelectorComponent, UserRoleDrawerPaneComponent, DeleteViewModalComponent],
})
export class UserRoleManagementModule {}
