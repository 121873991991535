import {
    GLOBAL_FILTER,
    DEFAULT_NAMESPACE,
    addFilter,
    addPagination,
    addSort,
    addTime,
    addSelection,
    addGroupSelection,
    addMetricSeries,
    addAggregationMethod,
    addCluster,
    addMetricType,
    addTableMetricsTimeRange,
    removeAllFilters,
    removeAllSelection,
    removeGroupSelection,
    removeAllTimes,
    removeAllMetricSeries,
    removeAllSorts,
    removeAggregationMethod,
    removePagination,
    removeCluster,
    removeMetricType,
    removeTableMetricsTimeRange,
    removeCapacityFullMethod,
    addCapacityFullMethod,
    removeFleetType,
    addFleetType,
    addTab,
    removeTab,
    addPanelSelection,
    removePanelSelection,
    removeEdgeServiceAgentCardsCollapseState,
    addEdgeServiceAgentCardsCollapseState,
    addItemType,
    removeItemType,
    setPerformanceAlert,
    removePerformanceAlert,
} from './actions';
import { createFilter, getFiltersUrlParams } from './utils';
import { EdgeServiceAgentCardsCollapseState, IState, IStateFilter, IStatePanelTabSlice } from './pure-redux.service';
import { Action } from 'redux';

export interface IUrlParams {
    [key: string]: any;
}

export abstract class UrlReaderWriter {
    // path to match to execute this UrlReaderWriter
    abstract path: RegExp;
    abstract localBarId: string;
    itemsPerPage = 1;

    write(state: IState, params: IUrlParams): IUrlParams {
        const filters = state.filters[this.localBarId] || [];
        const globalFilters = state.filters[GLOBAL_FILTER] || [];
        const page = (state.paginations[this.localBarId] || 0) / this.itemsPerPage;
        const times = state.times[this.localBarId] || 0;
        const sort = state.sorts[this.localBarId];
        const selection = state.selection[this.localBarId];
        const groupSelection = (state.groupSelection || [])[this.localBarId];
        const metricSeries = state.metricSeries[this.localBarId];
        const aggregationMethod = state.aggregationMethod[this.localBarId];
        const tableMetricsTimeRange = state.tableMetricsTimeRange[this.localBarId];
        const capacityFullMethod = state.capacityFullMethod[this.localBarId];
        const cluster = state.cluster[this.localBarId];
        const metricType = state.metricType[this.localBarId];
        const fleetType = state.fleetType[this.localBarId];
        const itemType = state.itemType[this.localBarId];
        const tab = state.tab[this.localBarId];
        const edgeServiceAgentCardsCollapseState = state.edgeServiceAgentCardsCollapseState[this.localBarId];
        const panel = state.panel;
        const performanceAlert = state.performanceAlertId[this.localBarId];

        return {
            ...params,
            ...{
                filter: getFiltersUrlParams(filters),
                global_filter: getFiltersUrlParams(globalFilters),
                page: page !== 0 ? String(page + 1) : undefined,
                sort: sort ? sort.join(',') : undefined,
                timeRange: times !== 0 ? JSON.stringify(times) : undefined,
                selection: selection ? selection.join(',') : undefined,
                group_selection: groupSelection,
                metric_series: metricSeries ? metricSeries.join(',') : undefined,
                aggregation_method: aggregationMethod,
                table_metrics_time_range: tableMetricsTimeRange,
                capacity_full_method: capacityFullMethod,
                cluster: cluster,
                metric_type: metricType,
                fleet_type: fleetType,
                item_type: itemType,
                edge_service_agent_cards_collapse_state: edgeServiceAgentCardsCollapseState,
                tab,
                panel: panel?.selection ? JSON.stringify(panel) : undefined,
                performance_alert: performanceAlert,
            },
        };
    }

    read(params: IUrlParams, initialRead = false, newPath?: string, oldPath?: string): Action[] {
        // for initial read and if there is no filter in the url,
        // we don't want to remove any existing filters, so default url will get filters from redux.
        const actions: Action[] = [];
        this.clearFilters(actions, this.localBarId, params, initialRead, newPath, oldPath);
        this.addFilters(actions, this.localBarId, JSON.parse(params.filter || '[]'));
        this.addFilters(actions, GLOBAL_FILTER, JSON.parse(params.global_filter || '[]'));
        this.addPagination(actions, Number(params.page || '0'));
        this.addSort(actions, params.sort || '');
        this.addTime(actions, JSON.parse(params.timeRange || '[]'));
        this.addSelection(actions, params.selection || '');
        this.addGroupSelection(actions, params.group_selection || '');
        this.addMetricSeries(actions, params.metric_series || '');
        this.addAggregationMethod(actions, params.aggregation_method);
        this.addTableMetricsTimeRange(actions, params.table_metrics_time_range);
        this.addCapacityFullMethod(actions, params.capacity_full_method);
        this.addCluster(actions, params.cluster);
        this.addMetricType(actions, params.metric_type || '');
        this.addFleetType(actions, params.fleet_type || '');
        this.addItemType(actions, params.item_type || '');
        this.addTab(actions, params.tab || '');
        this.addEdgeServiceAgentCardsCollapseState(actions, params.edge_service_agent_cards_collapse_state || '');
        this.addPanelSelection(actions, params.panel ? JSON.parse(params.panel) : '');
        this.addPerformanceAlert(actions, params.performance_alert || '');

        return actions;
    }

    protected hasValues(params: IUrlParams): boolean {
        return (
            (params.filter || []).length !== 0 ||
            (params.global_filter || []).length !== 0 ||
            params.page ||
            (params.sort || []).length !== 0 ||
            (params.timeRange || []).length !== 0 ||
            (params.selection || []).length !== 0 ||
            (params.metric_series || []).length !== 0 ||
            params.groupSelection ||
            params.aggregation_method ||
            params.table_metrics_time_range ||
            params.capacity_full_method ||
            params.cluster ||
            params.metric_type ||
            params.fleet_type ||
            params.item_type ||
            params.tab ||
            params.edge_service_agent_cards_collapse_state ||
            params.performance_alert
        );
    }

    protected clearFilters(
        actions: Action[],
        barId: string,
        params: IUrlParams,
        initialRead: boolean,
        newPath: string,
        oldPath: string,
    ): Action[] {
        actions.push(
            ...(initialRead && !this.hasValues(params)
                ? []
                : [
                      removeAllFilters(this.localBarId),
                      removeAllFilters(GLOBAL_FILTER),
                      removePagination(this.localBarId),
                      removeAllSorts(this.localBarId),
                      removeAllTimes(this.localBarId),
                      removeAllSelection(this.localBarId),
                      removeGroupSelection(this.localBarId),
                      removeAllMetricSeries(this.localBarId),
                      removeAggregationMethod(this.localBarId),
                      removeTableMetricsTimeRange(this.localBarId),
                      removeCapacityFullMethod(this.localBarId),
                      removeCluster(this.localBarId),
                      removeMetricType(this.localBarId),
                      removeFleetType(this.localBarId),
                      removeItemType(this.localBarId),
                      removeTab(this.localBarId),
                      removeEdgeServiceAgentCardsCollapseState(this.localBarId),
                      removePanelSelection(),
                      removePerformanceAlert(this.localBarId),
                  ]),
        );

        return actions;
    }

    protected addFilters(actions: Action[], barId: string, json: any[]): Action[] {
        if (json && json.length > 0) {
            json.forEach((item: IStateFilter) => {
                actions.push(
                    addFilter(
                        barId,
                        createFilter(
                            item.entity,
                            item.namespace === undefined ? DEFAULT_NAMESPACE : item.namespace,
                            item.key,
                            item.value,
                        ),
                    ),
                );
            });
        }
        return actions;
    }

    protected addPagination(actions: Action[], page: number): Action[] {
        if (page > 0) {
            actions.push(addPagination(this.localBarId, (Number(page) - 1) * this.itemsPerPage));
        }
        return actions;
    }

    protected addSort(actions: Action[], sorts: string): Action[] {
        if (sorts) {
            sorts.split(',').forEach(sort => actions.push(addSort(this.localBarId, sort)));
        }
        return actions;
    }

    protected addTime(actions: Action[], json: any[]): Action[] {
        if (json && json.length > 0) {
            json.forEach((item: any) => {
                actions.push(addTime(this.localBarId, item.key, item.value));
            });
        }
        return actions;
    }

    protected addSelection(actions: Action[], selection: string): Action[] {
        if (selection) {
            actions.push(addSelection(this.localBarId, selection.split(',')));
        }
        return actions;
    }

    protected addGroupSelection(actions: Action[], groupSelection: string): Action[] {
        if (groupSelection) {
            actions.push(addGroupSelection(this.localBarId, groupSelection));
        }
        return actions;
    }

    protected addMetricSeries(actions: Action[], metricSeries: string): Action[] {
        if (metricSeries) {
            actions.push(addMetricSeries(this.localBarId, metricSeries.split(',')));
        }
        return actions;
    }

    protected addAggregationMethod(actions: Action[], aggregationMethod: string): Action[] {
        if (aggregationMethod) {
            actions.push(addAggregationMethod(this.localBarId, aggregationMethod));
        }
        return actions;
    }

    protected addTableMetricsTimeRange(actions: Action[], timeRange: string): Action[] {
        if (timeRange) {
            actions.push(addTableMetricsTimeRange(this.localBarId, timeRange));
        }
        return actions;
    }

    protected addCapacityFullMethod(actions: Action[], capacityFullMethod: string): Action[] {
        if (capacityFullMethod) {
            actions.push(addCapacityFullMethod(this.localBarId, capacityFullMethod));
        }
        return actions;
    }

    protected addCluster(actions: Action[], cluster: string): Action[] {
        if (cluster) {
            actions.push(addCluster(this.localBarId, cluster));
        }
        return actions;
    }

    protected addMetricType(actions: Action[], metricType: string): Action[] {
        if (metricType) {
            actions.push(addMetricType(this.localBarId, metricType));
        }
        return actions;
    }

    protected addFleetType(actions: Action[], fleetType: string): Action[] {
        if (fleetType) {
            actions.push(addFleetType(this.localBarId, fleetType));
        }
        return actions;
    }

    protected addItemType(actions: Action[], itemType: string): Action[] {
        if (itemType) {
            actions.push(addItemType(this.localBarId, itemType));
        }
        return actions;
    }

    protected addTab(actions: Action[], tab: string): Action[] {
        if (tab) {
            actions.push(addTab(this.localBarId, tab));
        }
        return actions;
    }

    protected addEdgeServiceAgentCardsCollapseState(
        actions: Action[],
        status: EdgeServiceAgentCardsCollapseState,
    ): Action[] {
        if (status) {
            actions.push(addEdgeServiceAgentCardsCollapseState(this.localBarId, status));
        }
        return actions;
    }

    protected addPanelSelection(actions: Action[], panel: IStatePanelTabSlice): Action[] {
        if (panel?.selection) {
            actions.push(addPanelSelection(panel.selection));
        }
        return actions;
    }

    protected addPerformanceAlert(actions: Action[], alert: string): Action[] {
        if (alert) {
            actions.push(setPerformanceAlert(this.localBarId, alert));
        }
        return actions;
    }
}
