<pureui-spinner class="p-loading activity-panel-spinner" [pureuiShow]="loading"></pureui-spinner>
<div class="panel-header">
    <div class="title-text">Activity Tracker</div>
</div>
<div
    *ngIf="!actionableSalesFlows || actionableSalesFlows.length < 1; else showSalesFlows"
    class="no-salesflows-message"
>
    You currently do not have any actionable orders.
</div>
<ng-template #showSalesFlows>
    <div *ngFor="let salesFlow of actionableSalesFlows; trackBy: 'id' | trackByProperty" class="quotes-list-container">
        <div class="info-row">
            <div class="row-main-item">
                <div class="item-title">Reference ID</div>
                <div class="item-value reference-id-value" (click)="openInOrdersPage(salesFlow.orderRequestId)">
                    {{ salesFlow.orderRequestId | defaultString }}
                </div>
            </div>
            <div class="row-secondary-item">
                <div class="item-title">Status</div>
                <div class="item-value">{{ salesFlow.statusMessage }}</div>
            </div>
            <div class="row-secondary-item">
                <div class="item-title">Last Updated</div>
                <div class="item-value">{{ $any(salesFlow).lastUpdated | date: 'yyyy-MM-dd' : 'UTC' }}</div>
            </div>
        </div>
        <div class="info-row">
            <div class="row-main-item">
                <div class="item-title">Customer Org Name</div>
                <div class="item-value customer-org-value">{{ salesFlow.orgName }}</div>
            </div>
            <div class="row-main-item">
                <div class="item-title">Product</div>
                <div
                    class="item-value products-names"
                    [ngbTooltip]="getProductNames(salesFlow)"
                    placement="top"
                    container="body"
                    >{{ getProductNames(salesFlow) }}</div
                >
            </div>
        </div>
        <div class="quotes-header-row">
            <div class="row-main-item">Quote</div>
            <div class="row-secondary-item">Total Amount</div>
            <div class="row-secondary-item">Expiration Date</div>
        </div>
        <purchase-order-quote
            *ngFor="let sfdcQuote of salesFlow.quotes; trackBy: 'id' | trackByProperty"
            [salesFlow]="salesFlow"
            [quote]="sfdcQuote"
            [salesFlowHasAnAcceptedQuote]="salesFlowWithAcceptedQuote.has(salesFlow)"
        >
        </purchase-order-quote>
    </div>
</ng-template>
