import { ApiAppKeyService, RoleType } from '@pure1/data';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'create-api-key-modal',
    templateUrl: 'create-api-key-modal.component.html',
})
export class CreateApiKeyModalComponent {
    @Input() readonly modal: NgbActiveModal;

    max_role: string;
    name: string;
    public_key: string;
    errorMessage: string;

    roles: { name: string }[] = [{ name: RoleType.PURE1_VIEWER }, { name: RoleType.PURE1_ADMIN }];

    loading = false;

    constructor(private apiAppKeyService: ApiAppKeyService) {
        // Set the default max_role
        this.max_role = this.roles[0].name;
    }

    createAppKey(): void {
        this.loading = true;
        this.apiAppKeyService
            .create({ max_role: this.max_role, name: this.name, public_key: this.public_key })
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.modal.close();
                },
                error: (error: HttpErrorResponse) => {
                    this.loading = false;
                    if (error.status === 400 || error.status === 403 || error.status === 409) {
                        this.errorMessage = error.error.message;
                    } else {
                        this.errorMessage = 'An unexpected error occurred, please contact support';
                    }
                },
            });
    }
}
