import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InvoiceWithReportSize, IRestResponse } from '@pure1/data';
import { InvoiceReportType } from '../../export/services/export-request-builder.service';

const ENDPOINT = '/rest/v1/partner/invoice_with_report_size_estimation';

@Injectable({ providedIn: 'root' })
export class InvoiceWithReportSizeService {
    constructor(private http: HttpClient) {}

    listInvoiceWithReportSize(
        filterString: string,
        invoiceReportType: InvoiceReportType,
    ): Observable<InvoiceWithReportSize> {
        const url = ENDPOINT + `?filter=${filterString}&report_type=${invoiceReportType.valueOf()}`;
        return this.http.get<IRestResponse<InvoiceWithReportSize>>(url, { observe: 'response' }).pipe(
            map(response => {
                // There is always only one item in the response
                return new InvoiceWithReportSize(response.body.items[0]);
            }),
        );
    }
}
