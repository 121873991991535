import { Injectable } from '@angular/core';
import { CaseCategory, CaseSeverity, CaseSubCategory, ProductLine, SupportCase } from '../../support/support.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditSupportCaseComponent } from 'core/src/support/create-edit-support-case/create-edit-support-case.component';

const REQUEST_STEP_UP_ENROLLMENT: Partial<SupportCase> = {
    productLine: ProductLine.Others,
    subject: 'Pure1 Edge Service Step-Up Authentication Request',
    description: 'Required to configure Step-Up authentication',
    category: CaseCategory.ADMINISTRATIVE,
    subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
    severity: CaseSeverity.INFORMATIONAL,
};

const REQUEST_STEP_UP_UNLOCK: Partial<SupportCase> = {
    productLine: ProductLine.Others,
    subject: 'Request to Unlock Account',
    description: 'Locked out during Step-Up authentication. Please unlock account.',
    category: CaseCategory.ADMINISTRATIVE,
    subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
    severity: CaseSeverity.INFORMATIONAL,
};

const NEED_HELP_WITH_ACTIVATION: Partial<SupportCase> = {
    productLine: ProductLine.Others,
    subject: 'Need Help To Enable Two Factor Authentication',
    description: 'Cannot enable two factor authentication.',
    category: CaseCategory.ADMINISTRATIVE,
    subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
    severity: CaseSeverity.INFORMATIONAL,
};

const NEED_HELP_WITH_CHALLENGE: Partial<SupportCase> = {
    productLine: ProductLine.Others,
    subject: 'Need Help With Two Factor Authentication',
    description: 'Cannot verify passcode for two factor authentication.',
    category: CaseCategory.ADMINISTRATIVE,
    subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
    severity: CaseSeverity.INFORMATIONAL,
};

const LOST_PHONE: Partial<SupportCase> = {
    productLine: ProductLine.Others,
    subject: 'Lost Access To Phone For Two Factor Authentication',
    description: 'Lost access to phone. Please unlock account.',
    category: CaseCategory.ADMINISTRATIVE,
    subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
    severity: CaseSeverity.INFORMATIONAL,
};

@Injectable()
export class StepUpSupportService {
    constructor(private ngbModal: NgbModal) {}

    openStepUpRequestEnrollmentCase(): void {
        const modalRef = this.ngbModal.open(CreateEditSupportCaseComponent);
        modalRef.componentInstance.activeModal = modalRef;
        modalRef.componentInstance.caseTemplate = REQUEST_STEP_UP_ENROLLMENT;
    }

    openStepUpUnlockRequestCase(): void {
        const modalRef = this.ngbModal.open(CreateEditSupportCaseComponent);
        modalRef.componentInstance.activeModal = modalRef;
        modalRef.componentInstance.caseTemplate = REQUEST_STEP_UP_UNLOCK;
    }

    openStepUpActivationHelpCase(): void {
        const modalRef = this.ngbModal.open(CreateEditSupportCaseComponent);
        modalRef.componentInstance.activeModal = modalRef;
        modalRef.componentInstance.caseTemplate = NEED_HELP_WITH_ACTIVATION;
    }

    openStepUpChallengeHelpCase(): void {
        const modalRef = this.ngbModal.open(CreateEditSupportCaseComponent);
        modalRef.componentInstance.activeModal = modalRef;
        modalRef.componentInstance.caseTemplate = NEED_HELP_WITH_CHALLENGE;
    }

    openStepUpLostPhoneCase(): void {
        const modalRef = this.ngbModal.open(CreateEditSupportCaseComponent);
        modalRef.componentInstance.activeModal = modalRef;
        modalRef.componentInstance.caseTemplate = LOST_PHONE;
    }
}
