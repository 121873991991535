import { WizardComponent } from '@achimha/angular-archwizard';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import { CachedCurrentUserService, ContactsService, FeatureFlagDxpService } from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import {
    getUpgradePathRequiredInfoFromAppliance,
    SAFEMODE_AUTO_ON_BOUNDARY_VERSION,
    showSafeModeOptOut,
    updateActiveClusterPairs,
} from 'core/src/software-lifecycle/purity-upgrades/purity-upgrades.utils';
import moment from 'moment-timezone';
import { forkJoin, from, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { FeatureNames } from '../../../model/FeatureNames';
import { isFArrayProduct, isFBladeProduct, PureArray } from '../../../model/PureArray';
import { ArraysManager } from '../../../services/arrays-manager.service';
import {
    CduAppliance,
    ContractStatus,
    PDUWorkflowCreateRequest,
    PolicyVersionsModel,
    Release,
    WorkflowCreateRequestType,
} from '../../../software-lifecycle/purity-upgrades/purity-upgrades.interface';
import { DriverService } from '../../../software-lifecycle/purity-upgrades/services/driver.service';
import { ReleasesService } from '../../../software-lifecycle/purity-upgrades/services/releases.service';
import { forString } from '../../../utils/comparator';
import { SupportContactService } from '../../services/support-contact.service';
import { CaseComposite, CreatedUpgradeCaseResult, FreeTimeUpdates, ProductLine } from '../../support.interface';
import { splitUpSlots } from '../../support.utils';
import { SupportUpgradeService } from '../services/support-upgrade.service';
import { UpgradeCaseDraftService } from '../services/upgrade-case-draft.service';
import { Schedule, ScheduledArray, VersionedArray } from '../types';
import { ArraySelectorComponent } from './array-selector/array-selector.component';

function getDayAsMoment(day: NgbDateStruct, timezone: string): moment.Moment {
    return moment.tz(
        {
            year: day.year,
            month: day.month - 1,
            day: day.day,
        },
        timezone,
    );
}

const TIMESLOT_INTERVAL = moment.duration(60, 'minutes');

@Component({
    selector: 'upgrade-scheduler',
    templateUrl: 'upgrade-scheduler.component.html',
})
export class UpgradeSchedulerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() readonly activeModal: NgbActiveModal;
    @Input() searchArrayName: string;
    @Input() preselectedApplianceIds: string[] = [];
    @Input() preselectedCommonVersion: string;
    @ViewChild(WizardComponent) wizard: WizardComponent;
    @ViewChild('arraySelector') arraySelector: ArraySelectorComponent;

    @Output() onCaseCreated = new EventEmitter<CreatedUpgradeCaseResult>();

    readonly GENERIC_UPGRADE_THRESHOLD = 25;
    readonly MAX_NUMBER_OF_HOPS = 3;

    currentStepIndex = 0;
    allArrays: PureArray[] = []; // list of all arrays available for upgrade
    arraysToUpgrade: VersionedArray[] = [];
    schedule: Schedule; // the selected upgrade schedule
    description: string;

    loading = false;
    submitting = false;
    submitted = false;
    shouldLoadTimeslots = false;
    createCaseDoneFlag = false;
    createCaseErrorFlag = false;
    timeslotsChecking = false;
    timeslotsConflictFlag = false;
    hasSNOWContact = false;
    isSNOWInitialized = false;
    replicationTargets: { [arrayId: string]: CduAppliance };
    releases: Release[];
    releasesMap: { [key: string]: Release } = {};
    safeModeAutoOnFlags: { [key: string]: boolean } = {};
    showSafeModeAutoOnColumn = false;
    hasDuplicateApplianceNames = false;

    caseId: string;
    userId = '';
    userEmail = '';

    // angulartics variables
    ANALYTICS_PREFIX = 'Support - Bulk array upgrade - ';
    startTime: number;

    freeTimeUpdates: FreeTimeUpdates;

    hasSubmitPermission = false;
    enableFlashBladeSupport = false;
    ignoreExpiredContract = false;

    appliances: CduAppliance[];
    versionPolicies: { [key: string]: PolicyVersionsModel } = {};

    constructor(
        private angulartics: Angulartics2,
        private arraysManager: ArraysManager,
        private cachedCurrentUserService: CachedCurrentUserService,
        private contactsService: ContactsService,
        private supportContactService: SupportContactService,
        private upgradeService: SupportUpgradeService,
        private draftService: UpgradeCaseDraftService,
        private driverService: DriverService,
        private releaseService: ReleasesService,
        protected calendar: NgbCalendar,
        private cdr: ChangeDetectorRef,
        private authzServiceResolver: AuthorizationServiceResolver,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.startTime = moment().valueOf();

        const arraysRequest$ = from(this.getArrays()).pipe(take(1));
        const usersRequests$ = forkJoin([
            this.cachedCurrentUserService.get().pipe(take(1)),
            // the observable returned by list doesn't complete, so we have to
            // call take(1)
            this.contactsService.list().pipe(take(1)),
        ]);
        const releasesRequest$ = this.releaseService.getAllReleases();

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.SSU_FLASH_BLADE_SUPPPORT)
            .subscribe((enableFlashBladeSupport: FeatureFlagStatus) => {
                this.enableFlashBladeSupport = enableFlashBladeSupport?.enabled === true;
            });

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.SSU_IGNORE_EXPIRED_CONTRACT)
            .subscribe((ignoreExpiredContract: FeatureFlagStatus) => {
                this.ignoreExpiredContract = ignoreExpiredContract?.enabled === true;
            });

        forkJoin([usersRequests$, arraysRequest$, releasesRequest$]).subscribe({
            next: ([[curUser, contacts], pureArrays, releases]) => {
                this.userEmail = curUser.email;

                // Look up the current user's sfdc contact by email
                const contact = contacts.response.find(c => curUser.email.toLowerCase() === c.email.toLowerCase());
                if (contact?.id) {
                    this.userId = contact.id;
                } else {
                    throw new Error('Cannot get user ID for current user.');
                }

                this.supportContactService.getContactById(curUser.id).subscribe({
                    next: () => {
                        this.hasSNOWContact = true;
                        this.isSNOWInitialized = true;
                    },
                    error: () => {
                        this.hasSNOWContact = false;
                        this.isSNOWInitialized = true;
                    },
                });

                const arrayIds = pureArrays.map(x => x.arrayId);

                this.driverService
                    .getAppliances(arrayIds, false)
                    .pipe(
                        catchError(() => of({ content: [] as CduAppliance[] })),
                        tap(appliances$ => {
                            this.appliances = appliances$.content;

                            this.appliances = this.appliances.map(appliance => {
                                return {
                                    ...appliance,
                                    contractStatus: this.ignoreExpiredContract
                                        ? ContractStatus.ACTIVE
                                        : appliance.contractStatus,
                                };
                            });

                            const uniqueApplianceNames = new Set<string>(
                                this.appliances.map(appliance => appliance.hostname),
                            );
                            this.hasDuplicateApplianceNames = uniqueApplianceNames.size !== this.appliances.length;

                            if (this.preselectedApplianceIds.length > 0) {
                                this.appliances = this.appliances.filter(appliance =>
                                    this.preselectedApplianceIds.includes(appliance.applianceId),
                                );
                            }

                            this.releases = releases;
                            this.releasesMap = releases.reduce(
                                (acc, current) => ({
                                    ...acc,
                                    [current.versionFull]: current,
                                }),
                                {},
                            );

                            this.replicationTargets = updateActiveClusterPairs(this.appliances);

                            this.loading = false;
                        }),
                        switchMap(() =>
                            this.releaseService.getPolicies(
                                this.appliances.map(getUpgradePathRequiredInfoFromAppliance),
                            ),
                        ),
                    )
                    .subscribe(policies$ => {
                        this.versionPolicies = policies$;
                    });
            },
            error: console.error,
        });

        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Dialog opened',
            properties: {
                category: 'Action',
            },
        });
    }

    ngAfterViewInit(): void {
        if (this.searchArrayName) {
            this.arraySelector.nameFilter = this.searchArrayName;
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.angulartics.userTimings.next({
            timingCategory: 'Action',
            timingVar: this.ANALYTICS_PREFIX + 'Time spent in dialog',
            timingValue: moment().valueOf() - this.startTime,
        });

        if (!this.submitted) {
            this.angulartics.eventTrack.next({
                action: this.ANALYTICS_PREFIX + 'Cancelled',
                properties: {
                    category: 'Action',
                },
            });
        }
    }

    showStep(stepIndex: number): void {
        if (stepIndex === 1) {
            this.shouldLoadTimeslots = true;
        }
        if (stepIndex !== this.currentStepIndex) {
            this.currentStepIndex = stepIndex;
            this.wizard.goToStep(stepIndex);
        }
    }

    cancel(): void {
        this.draftService.cancelDraft();
        this.activeModal.dismiss();
    }

    dismiss(): void {
        this.activeModal.dismiss();
    }

    setArrays(arrays: VersionedArray[]): void {
        this.arraysToUpgrade = arrays;
        this.updateSafeModeInformation();
        this.showStep(1);
    }

    updateSafeModeInformation(): void {
        this.showSafeModeAutoOnColumn = false;
        this.safeModeAutoOnFlags = {};
        this.arraysToUpgrade.forEach(selectedArr => {
            if (
                showSafeModeOptOut(
                    this.releasesMap[SAFEMODE_AUTO_ON_BOUNDARY_VERSION],
                    this.releasesMap[selectedArr.array.currentVersion],
                    this.releasesMap[selectedArr.targetVersion],
                )
            ) {
                this.safeModeAutoOnFlags[selectedArr.array.applianceId] = true;
                this.showSafeModeAutoOnColumn = true;
            }
            if (
                selectedArr.secondaryArray &&
                showSafeModeOptOut(
                    this.releasesMap[SAFEMODE_AUTO_ON_BOUNDARY_VERSION],
                    this.releasesMap[selectedArr.secondaryArray.currentVersion],
                    this.releasesMap[selectedArr.targetVersion],
                )
            ) {
                this.safeModeAutoOnFlags[selectedArr.secondaryArray.applianceId] = true;
                this.showSafeModeAutoOnColumn = true;
            }
        });
    }

    setSchedule(schedule: Schedule): void {
        this.schedule = schedule;
        this.timeslotsConflictFlag = false;
        this.authzServiceResolver
            .getDefaultService()
            .pipe(
                switchMap(service =>
                    schedule.schedule.length === 1
                        ? service.hasPermission('PURE1:write:support_upgrade')
                        : service.hasPermission('PURE1:write:support_bulk_schedule'),
                ),
                take(1),
            )
            .subscribe({
                next: authorized => (this.hasSubmitPermission = authorized),
                // allow to submit in case of error, since it is still validated on the backend
                error: error => {
                    console.error(error);
                    this.hasSubmitPermission = true;
                },
            });

        this.showStep(2);
    }

    doSchedule(description: string): void {
        this.description = description;
        this.checkSelectedTimeslotsAndCreateCase();
    }

    toggleSafeModeAutoOn(arrayId: string): void {
        this.safeModeAutoOnFlags[arrayId] = !this.safeModeAutoOnFlags[arrayId];
        if (this.replicationTargets[arrayId]) {
            this.safeModeAutoOnFlags[this.replicationTargets[arrayId].applianceId] = this.safeModeAutoOnFlags[arrayId];
        }
    }

    addSafeModeAutoOnInformation(arrays: ScheduledArray[]): string {
        let newDescription = this.description;
        const safeModeAutoOnOptOutArrays = arrays.filter(selectedArr => {
            return (
                this.safeModeAutoOnFlags.hasOwnProperty(selectedArr.array.applianceId) &&
                !this.safeModeAutoOnFlags[selectedArr.array.applianceId]
            );
        });
        if (safeModeAutoOnOptOutArrays.length > 0) {
            newDescription += '\n\nCustomer is opting out of SafeMode Auto-On for the appliances: ';
            safeModeAutoOnOptOutArrays.forEach(selectedArr => {
                newDescription += selectedArr.array.hostname + ', ';
            });
            newDescription = newDescription.slice(0, -2) + '.';
        }
        return newDescription;
    }

    private checkSelectedTimeslotsAndCreateCase(): void {
        this.timeslotsChecking = true;
        // fetch only timeslots in range: earliest start time - last start time + buffer
        let earliestSchedule: ScheduledArray;
        let latestSchedule: ScheduledArray;
        for (const currentSchedule of this.schedule.schedule) {
            const currentStartTime = currentSchedule.timeslot?.startTime;
            if (!currentStartTime) {
                continue;
            }
            if (!earliestSchedule || currentStartTime?.isBefore(earliestSchedule.timeslot?.startTime)) {
                earliestSchedule = currentSchedule;
            }
            if (!latestSchedule || currentStartTime?.isAfter(latestSchedule.timeslot?.startTime)) {
                latestSchedule = currentSchedule;
            }
        }
        const startTime = earliestSchedule?.timeslot.startTime || moment();
        // add more buffer to the endTime than just duration due to the way the timeslots are coupled together in the backend
        const endTime = latestSchedule?.timeslot?.startTime.clone().add(1, 'days') || moment().add(1, 'days');
        const flashArraySlots$ = this.arraysToUpgrade.some(a => a.productLine === ProductLine.FlashArray)
            ? this.upgradeService.getFreeTimes(startTime, endTime, ProductLine.FlashArray)
            : of([]);
        const flashBladeSlots$ = this.arraysToUpgrade.some(a => a.productLine === ProductLine.FlashBlade)
            ? this.upgradeService.getFreeTimes(startTime, endTime, ProductLine.FlashBlade)
            : of([]);
        forkJoin({ flashArraySlots$, flashBladeSlots$ }).subscribe(
            ({ flashArraySlots$, flashBladeSlots$ }) => {
                const splitFlashArrayNewFreeTimes = splitUpSlots(
                    flashArraySlots$,
                    moment.duration(1, 'hours'),
                    TIMESLOT_INTERVAL,
                );
                const splitFlashBladeNewFreeTimes = splitUpSlots(
                    flashBladeSlots$,
                    moment.duration(1, 'hours'),
                    TIMESLOT_INTERVAL,
                );

                const scheduledArraysWithConflicts: ScheduledArray[] = [];
                // go through all arrays and check if their schedule fits into new timeslots
                this.schedule.schedule.forEach(scheduledArray => {
                    // array that has less than 4 number of hops and has timeslot (others cannot create conflicts because support will contact the customer anyway)
                    if (scheduledArray.timeslot) {
                        // find the slot where the upgrade shoudl start
                        const splitNewFreeTimes =
                            scheduledArray.productLine === ProductLine.FlashArray
                                ? splitFlashArrayNewFreeTimes
                                : splitFlashBladeNewFreeTimes;
                        const startSlotIndex = splitNewFreeTimes.findIndex(ts =>
                            ts.startTime.isSame(scheduledArray.timeslot.startTime),
                        );
                        if (startSlotIndex > -1) {
                            // check slots for this array (dont adjust capacity yet, adjust it later only if it really fits)
                            let count = 0;
                            for (let j = startSlotIndex; j < startSlotIndex + scheduledArray.numberOfHops; j++) {
                                if (
                                    splitNewFreeTimes[j].startTime.isSame(
                                        scheduledArray.timeslot.startTime.clone().add(count, 'hours'),
                                    ) &&
                                    splitNewFreeTimes[j].capacity > 0
                                ) {
                                    count++;
                                } else {
                                    // not enough capacity or missing timeslot, add array to arrays with conflicts
                                    scheduledArraysWithConflicts.push(scheduledArray);
                                    break;
                                }
                            }
                            // so the array should fit into this timeslot, adjust the capacity
                            if (count === scheduledArray.numberOfHops) {
                                for (let j = startSlotIndex; j < startSlotIndex + scheduledArray.numberOfHops; j++) {
                                    splitNewFreeTimes[j].capacity--;
                                }
                            }
                        } else {
                            // if start slot was not found, push current array into array with conflicts
                            scheduledArraysWithConflicts.push(scheduledArray);
                        }
                    }
                    // if this is called again, some arrays might have their timeslots already erased so we check for those (arrays without timeslots and less then 4 hops)
                    if (scheduledArray.numberOfHops <= this.MAX_NUMBER_OF_HOPS && !scheduledArray.timeslot) {
                        scheduledArraysWithConflicts.push(scheduledArray);
                    }
                });

                if (scheduledArraysWithConflicts.length > 0) {
                    this.timeslotsConflictFlag = true;
                    this.timeslotsChecking = false;
                    scheduledArraysWithConflicts.forEach(scheduledArray => {
                        scheduledArray.timeslot = null;
                    });
                    this.freeTimeUpdates = {
                        freeTimesForFlashArray: flashArraySlots$,
                        freeTimesForFlashBlade: flashBladeSlots$,
                        conflictingArrays: scheduledArraysWithConflicts,
                    };
                } else {
                    this.timeslotsConflictFlag = false;
                    this.timeslotsChecking = false;
                    this.createCase();
                }
            },
            error => {
                console.error(error); // for datadog reporting reasons
                this.timeslotsChecking = false;
            },
        );
    }

    reformatScheduledArrays(scheduledArrays: ScheduledArray[]): ScheduledArray[] {
        const newScheduledArrays: ScheduledArray[] = [];
        scheduledArrays.forEach(scheduledArray => {
            if (scheduledArray.secondaryArray) {
                const duration = moment.duration(scheduledArray.timeslot.duration.asMilliseconds() / 2, 'milliseconds');
                newScheduledArrays.push({
                    array: scheduledArray.array,
                    numberOfHops: scheduledArray.numberOfHops,
                    targetVersion: scheduledArray.targetVersion,
                    timeslot: {
                        startTime: scheduledArray.timeslot.startTime,
                        duration: duration,
                        capacity: scheduledArray.timeslot.capacity,
                    },
                    productLine: scheduledArray.productLine,
                    upgradeDurationSeconds: scheduledArray.upgradeDurationSeconds,
                });
                newScheduledArrays.push({
                    array: scheduledArray.secondaryArray,
                    numberOfHops: scheduledArray.numberOfHops,
                    targetVersion: scheduledArray.targetVersion,
                    timeslot: {
                        startTime: moment(scheduledArray.timeslot.startTime).add(duration.asMilliseconds(), 'ms'),
                        duration: duration,
                        capacity: scheduledArray.timeslot.capacity,
                    },
                    productLine: scheduledArray.productLine,
                    upgradeDurationSeconds: scheduledArray.upgradeDurationSeconds,
                });
            } else {
                // We do this because for FB the duration of the upgrade in Service Now is calculated
                // based on the number of hops.
                scheduledArray.numberOfHops =
                    scheduledArray.productLine === ProductLine.FlashArray
                        ? scheduledArray.numberOfHops
                        : 3 * scheduledArray.numberOfHops;
                newScheduledArrays.push(scheduledArray);
            }
        });
        return newScheduledArrays;
    }

    private createCase(): void {
        if (!this.timeslotsConflictFlag) {
            this.submitting = true;
            this.angulartics.eventTrack.next({
                action: this.ANALYTICS_PREFIX + 'Case submitted',
                properties: {
                    category: 'Action',
                },
            });

            const formattedSchedule = {
                schedule: this.reformatScheduledArrays(this.schedule.schedule),
                timezone: this.schedule.timezone,
            };

            const description = this.addSafeModeAutoOnInformation(formattedSchedule.schedule);

            // In case there is only one array scheduled, we create just one case as before the introduction of upgrade scheduler (no parent case is created)
            if (formattedSchedule.schedule.length === 1) {
                const scheduledArray = formattedSchedule.schedule[0];
                this.submitting = false;
                this.submitted = true;
                this.upgradeService
                    .createUpgradeCase(
                        scheduledArray.array.applianceId,
                        this.userId,
                        this.userEmail,
                        scheduledArray.timeslot ? scheduledArray.timeslot.startTime.valueOf() : null,
                        scheduledArray.timeslot
                            ? scheduledArray.timeslot.startTime.clone().add(scheduledArray.timeslot.duration).valueOf()
                            : null,
                        scheduledArray.numberOfHops,
                        scheduledArray.targetVersion,
                        description,
                        this.schedule.timezone,
                        scheduledArray.productLine,
                    )
                    .subscribe(
                        newCase => this.onCaseCreationSuccessCallback(newCase),
                        error => this.onCaseCreationErrorCallback(error),
                    );
            } else {
                // otherwise we construct an upgrade schedule and use bulk scheduling with parent case
                this.submitting = false;
                this.submitted = true;
                this.createCaseDoneFlag = true;
                this.createCaseErrorFlag = false;
                this.upgradeService.createBulkUpgradeSchedule(formattedSchedule, this.userId, description).subscribe(
                    newCase => this.onCaseCreationSuccessCallback(newCase),
                    error => this.onCaseCreationErrorCallback(error),
                );
            }
        }
    }

    // get all arrays available
    private getArrays(): Promise<PureArray[]> {
        return this.arraysManager.getPureArrays('').then(results => {
            this.allArrays = results
                .filter(
                    result =>
                        isFArrayProduct(result.product) ||
                        (isFBladeProduct(result.product) && this.enableFlashBladeSupport),
                )
                .sort(forString((result: PureArray) => result.name).asc);
            return this.allArrays;
        });
    }

    private onCaseCreationSuccessCallback(newCase: Partial<CaseComposite>): void {
        this.caseId = newCase.case.caseNumber;
        this.createCaseDoneFlag = true;
        this.createCaseErrorFlag = false;

        const workflowStates$ = this.schedule.schedule.map(schedule => {
            const requestObject: PDUWorkflowCreateRequest = {
                arrayId: schedule.array.applianceId,
                startVersion: schedule.array.currentVersion,
                supportCaseId: newCase.case.id,
                type: WorkflowCreateRequestType.PDU,
                targetVersion: schedule.targetVersion,
                targetTime: schedule.timeslot ? schedule.timeslot.startTime : null,
                safeModeAutoOn: this.safeModeAutoOnFlags[schedule.array.applianceId],
            };
            return this.driverService.createWorkflow(requestObject);
        });

        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Case created',
            properties: {
                category: 'Action',
                label: `${this.schedule.schedule.length} child cases`,
            },
        });

        forkJoin(workflowStates$).subscribe(
            workflowStates => {
                this.caseId = newCase.case.caseNumber;
                this.submitted = true;
                this.submitting = false;
                this.draftService.cancelDraft();
                this.onCaseCreated.emit({
                    workflowStates,
                    caseId: newCase.case.caseNumber,
                    internalCaseId: newCase.case.id,
                });
                this.angulartics.eventTrack.next({
                    action: this.ANALYTICS_PREFIX + 'Workflow States',
                    properties: {
                        category: 'Action',
                        label: `${this.schedule.schedule.length} total states`,
                    },
                });
            },
            error => {
                this.submitting = false;
                console.error('PDU Workflow Error', error);
            },
        );
    }

    private onCaseCreationErrorCallback(error: any): void {
        console.error(error); // for datadog reporting reasons
        this.submitting = false;
        this.submitted = true;
        this.createCaseDoneFlag = true;
        this.createCaseErrorFlag = true;
        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Case create failed',
            properties: {
                category: 'Action',
                label: `${this.schedule.schedule.length} child cases`,
            },
        });
    }
}
