// Generated from PureXPath.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by PureXPathParser.
function PureXPathListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

PureXPathListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
PureXPathListener.prototype.constructor = PureXPathListener;

// Enter a parse tree produced by PureXPathParser#main.
PureXPathListener.prototype.enterMain = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#main.
PureXPathListener.prototype.exitMain = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#locationPath.
PureXPathListener.prototype.enterLocationPath = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#locationPath.
PureXPathListener.prototype.exitLocationPath = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#relativeLocationPath.
PureXPathListener.prototype.enterRelativeLocationPath = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#relativeLocationPath.
PureXPathListener.prototype.exitRelativeLocationPath = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#step.
PureXPathListener.prototype.enterStep = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#step.
PureXPathListener.prototype.exitStep = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#nodeTest.
PureXPathListener.prototype.enterNodeTest = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#nodeTest.
PureXPathListener.prototype.exitNodeTest = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#abbreviatedStep.
PureXPathListener.prototype.enterAbbreviatedStep = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#abbreviatedStep.
PureXPathListener.prototype.exitAbbreviatedStep = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#expr.
PureXPathListener.prototype.enterExpr = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#expr.
PureXPathListener.prototype.exitExpr = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#exprSingle.
PureXPathListener.prototype.enterExprSingle = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#exprSingle.
PureXPathListener.prototype.exitExprSingle = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#primaryExpr.
PureXPathListener.prototype.enterPrimaryExpr = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#primaryExpr.
PureXPathListener.prototype.exitPrimaryExpr = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#functionCall.
PureXPathListener.prototype.enterFunctionCall = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#functionCall.
PureXPathListener.prototype.exitFunctionCall = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#pathExprNoRoot.
PureXPathListener.prototype.enterPathExprNoRoot = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#pathExprNoRoot.
PureXPathListener.prototype.exitPathExprNoRoot = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#filterExpr.
PureXPathListener.prototype.enterFilterExpr = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#filterExpr.
PureXPathListener.prototype.exitFilterExpr = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#orExpr.
PureXPathListener.prototype.enterOrExpr = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#orExpr.
PureXPathListener.prototype.exitOrExpr = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#andExpr.
PureXPathListener.prototype.enterAndExpr = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#andExpr.
PureXPathListener.prototype.exitAndExpr = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#equalityExpr.
PureXPathListener.prototype.enterEqualityExpr = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#equalityExpr.
PureXPathListener.prototype.exitEqualityExpr = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#functionName.
PureXPathListener.prototype.enterFunctionName = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#functionName.
PureXPathListener.prototype.exitFunctionName = function(ctx) {
};


// Enter a parse tree produced by PureXPathParser#nameTest.
PureXPathListener.prototype.enterNameTest = function(ctx) {
};

// Exit a parse tree produced by PureXPathParser#nameTest.
PureXPathListener.prototype.exitNameTest = function(ctx) {
};



exports.PureXPathListener = PureXPathListener;