import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, ReplaySubject, Subject, switchMap, throwError } from 'rxjs';
import { catchError, map, shareReplay, take, takeUntil } from 'rxjs/operators';

import {
    ArrayEolComponent,
    ArrayEolComponentInfo,
    ArrayEolComponentInfoService,
    ArrayEolComponentType,
    CapacityConfig,
    DataPage,
    Incident,
    IncidentService,
    RecommendationCode,
} from '@pure1/data';
import { CreateOrUpdateResult, OpportunityService, OpportunityStatus, Pure1LeadSource } from './opportunity.service';
import { ampli } from '../ampli';
import { ToastService, ToastType } from './toast.service';
import { Router } from '@angular/router';
import { AuthorizationService } from '@pure/authz-authorizer';

export type EolOpportunityStatus = 'opened' | 'unopened' | 'unknown';
const EOL_RECOMMENDATION_TYPES = Object.freeze(
    new Set<RecommendationCode>([RecommendationCode.EolM2X, RecommendationCode.Eol6G]),
);

@Injectable({ providedIn: 'root' })
export class EolHardwareService implements OnDestroy {
    readonly opportunitiesUpdated$ = new ReplaySubject<Map<string, OpportunityStatus>>(1);
    readonly incidentsUpdated$ = new ReplaySubject<Map<string, Incident>>(1);

    private readonly hwMap = new Map<string, Observable<ArrayEolComponentInfo>>();
    protected readonly destroy$ = new Subject<void>();
    private statusMap: Map<string, OpportunityStatus>;
    private incidentMap: Map<string, Incident>;
    private isAllowedToReadRecommendation: boolean = false;

    constructor(
        private authorizationService: AuthorizationService,
        private opportunityService: OpportunityService,
        private arrayEolComponentInfoService: ArrayEolComponentInfoService,
        private incidentService: IncidentService,
        private router: Router,
        private toast: ToastService,
    ) {
        this.authorizationService
            .isAllowed('PURE1:read:proactive_recommendations')
            .pipe(take(1))
            .subscribe(isAllowed => {
                this.subscribeToOpportunityStatus();
                if (isAllowed) {
                    this.getIncidentInfo();
                }
            });

        this.authorizationService
            .isAllowed('PURE1:read:subscription')
            .pipe(takeUntil(this.destroy$))
            .subscribe(isAllowed => {
                this.isAllowedToReadRecommendation = isAllowed;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    getEolComponentInfo(arrayId: string): Observable<ArrayEolComponentInfo> {
        if (!this.hwMap.has(arrayId)) {
            const request$ = this.arrayEolComponentInfoService.list({ extra: `array_ids=${arrayId}` }).pipe(
                take(1),
                map((result: DataPage<ArrayEolComponentInfo>) => {
                    if (!result || result.total === 0) {
                        return null;
                    }
                    return result.response[0];
                }),
                catchError(err => {
                    console.error('failed to get hardware eol info');
                    this.hwMap.delete(arrayId);
                    return of(null);
                }),
                shareReplay(), // don't refetch on every subscribe
            );
            this.hwMap.set(arrayId, request$);
        }
        return this.hwMap.get(arrayId);
    }

    createEolOpportunity(
        arrayId: string,
        arrayName: string,
        leadSource: Pure1LeadSource,
        config?: CapacityConfig,
    ): Observable<CreateOrUpdateResult> {
        this.trackOpportunityAmpliEvent(leadSource);
        return (
            config
                ? of(config)
                : this.getEolComponentInfo(arrayId).pipe(map(info => info?.recommendationAction.capacityConfig))
        ).pipe(
            switchMap((config: CapacityConfig) => {
                if (config) {
                    return this.opportunityService.createEolRequest(arrayId, arrayName, leadSource, config);
                } else {
                    return throwError(() => new Error('failed to get EoL hardware info'));
                }
            }),
        );
    }

    isEolOpportunityOpened(arrayId: string): EolOpportunityStatus {
        if (!this.statusMap) {
            return 'unknown';
        } else if (this.statusMap?.has(arrayId)) {
            const oppStatus: OpportunityStatus = this.statusMap.get(arrayId);
            return oppStatus.opportunities.some(opp => !!opp.pure1_lead_source) ? 'opened' : 'unopened';
        } else {
            return 'unopened';
        }
    }

    hasPermissionToViewRecommendation(): boolean {
        return this.isAllowedToReadRecommendation;
    }

    viewRecommendation(arrayId: string): void {
        ampli.eolViewRecommendationClicked();
        const incident = this.incidentMap.get(arrayId);
        if (incident) {
            this.router.navigate(['/services/servicecatalog/recommendationsview'], {
                queryParams: { selection: `incident-${incident.id}` },
            });
        } else {
            console.warn(`no incident found for ${arrayId}`);
            this.toast.add(ToastType.error, 'Unable to fetch recommendation, please try again later.');
        }
    }

    isEolIncidentOpened(arrayId: string): boolean {
        return this.incidentMap?.has(arrayId);
    }

    getComponentLabels(components: ArrayEolComponent[]): string[] {
        const componentMap = new Map<string, ArrayEolComponent[]>();
        // group components by date and component info
        components.forEach(component => {
            const key = `${component.endOfSupportDate.valueOf()}-${component.protocol}-${component.flag}-${component.type}`;
            if (componentMap.has(key)) {
                componentMap.get(key).push(component);
            } else {
                componentMap.set(key, [component]);
            }
        });

        // name/count/date labels
        const labelMap: { date: number; label: string }[] = [];
        componentMap.forEach(components => {
            labelMap.push({
                date: components[0].endOfSupportDate.valueOf(),
                label: `${components[0].endOfSupportDate.utc().format('MMMM D, YYYY')} --- (${components.length}) ${this.getFullComponentName(components[0])}`,
            });
        });
        labelMap.sort((a, b) => a.date - b.date);
        return labelMap.map(dateLabel => dateLabel.label);
    }

    private getFullComponentName(component: ArrayEolComponent): string {
        return [component.flag, component.protocol, this.getFullComponentTypeName(component.type)]
            .filter(item => item != null)
            .join(' ');
    }

    private getFullComponentTypeName(type: ArrayEolComponentType): string {
        switch (type) {
            case 'sh':
                return 'Shelf';
            case 'ct':
                return 'Controller';
            case 'ch':
                return 'Chassis';
            default:
                console.warn(`unknown component type: '${type}'`);
                return type;
        }
    }

    private subscribeToOpportunityStatus(): void {
        this.opportunityService.opportunities$.pipe(takeUntil(this.destroy$)).subscribe(statusMap => {
            this.statusMap = statusMap;
            this.opportunitiesUpdated$.next(this.statusMap);
        });
    }

    private getIncidentInfo(): void {
        this.incidentService
            .list()
            .pipe(take(1))
            .subscribe((incidents: DataPage<Incident>) => {
                if (incidents?.total > 0) {
                    const incidentMap = new Map<string, Incident>();
                    incidents.response
                        .filter(incident => EOL_RECOMMENDATION_TYPES.has(incident.recommendationCode))
                        .forEach(incident => {
                            incidentMap.set(incident.applianceId, incident);
                        });
                    this.incidentMap = incidentMap;
                    this.incidentsUpdated$.next(this.incidentMap);
                }
            });
    }

    private trackOpportunityAmpliEvent(leadSource: Pure1LeadSource): void {
        switch (leadSource) {
            case 'Pure1 Eol Appliance Card':
                ampli.eolContactAccountTeamClickedApplianceCardView();
                return;
            case 'Pure1 Eol Appliance List':
                ampli.eolContactAccountTeamClickedApplianceListViewPopover();
                return;
            case 'Pure1 Eol Appliance Table':
                ampli.eolContactAccountTeamClickedApplianceListView();
                return;
            default:
                console.warn('unknown eol opportunity lead source: ', leadSource);
                return;
        }
    }
}
