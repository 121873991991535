import _ from 'lodash';
import moment from 'moment';
import { Angulartics2 } from 'angulartics2';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, take, takeUntil, tap, exhaustMap } from 'rxjs/operators';

import {
    LOCAL_BAR_ORDERS,
    SERVICE_NAME_KEY,
    ORDERS_ENTITY,
    addFilter,
    removeAllFilters,
    DEFAULT_NAMESPACE,
} from '../../../redux/actions';
import {
    ServiceCatalogQuote,
    SortParams,
    FilterParams,
    DataPage,
    ServiceCatalogQuoteServiceV4,
    SfdcQuote,
    QuoteType,
} from '@pure1/data';
import { OrdersUpdateService } from '../../../service-catalog/services/orders-update.service';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState } from '../../../redux/pure-redux.service';
import { createFilter } from '../../../redux/utils';
import { smartTimer } from '@pstg/smart-timer';

const DEFAULT_SORT: SortParams = {
    key: 'requested_at',
    order: 'desc',
};

// Product Type (service type) -> consolidated products by group name
interface ComputedData {
    serviceName: string;
    products: string;
}

@Component({
    selector: 'orders-activity-panel',
    templateUrl: 'orders-activity-panel.component.html',
})
export class OrdersActivityPanelComponent implements OnInit, OnDestroy {
    actionableSalesFlows: ServiceCatalogQuote[] = null;
    activeOrder: ServiceCatalogQuote = null;
    filters: FilterParams<ServiceCatalogQuote>;
    loading = false;

    // Used in the template
    readonly salesFlowWithAcceptedQuote = new Map<ServiceCatalogQuote, SfdcQuote>();
    readonly quoteUrls = new Map<string, string>();
    readonly LOCAL_BAR_ORDERS = LOCAL_BAR_ORDERS;
    readonly ORDERS_ENTITY = ORDERS_ENTITY;
    readonly SERVICE_NAME = SERVICE_NAME_KEY;

    private sortOption = DEFAULT_SORT;
    private analyticsPrefix = 'Service Catalog Orders - ';
    private unsubscribeFromRedux: Function;
    private unsubscribeFromUrlService: Function;

    private readonly computedProductsNames = new Map<ServiceCatalogQuote, ComputedData[]>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV4,
        private angulartics2: Angulartics2,
        private ngRedux: NgRedux<IState>,
        private ordersUpdateService: OrdersUpdateService,
    ) {}

    ngOnInit(): void {
        this.ordersUpdateService.refreshQuotes$
            .pipe(
                tap(() => (this.loading = true)), // Only show loading spinner for on-demand updates (don't show when doing a periodic background refresh)
                switchMap(() => {
                    return smartTimer(0, moment.duration(30, 'seconds').asMilliseconds()).pipe(
                        exhaustMap(() =>
                            this.serviceCatalogQuoteService
                                .list({
                                    filter: this.filters,
                                    sort: this.getSortOption(),
                                })
                                .pipe(
                                    take(1),
                                    catchError(err => {
                                        console.error('serviceCatalogQuoteService.list() failed', err);
                                        return of<DataPage<ServiceCatalogQuote>>(null);
                                    }),
                                ),
                        ),
                    );
                }),
                filter(result => result != null), // Ignore null responses due to an error
                tap(() => (this.loading = false)), // Note: Loading indicator will persist on error
                distinctUntilChanged((prev, curr) => {
                    // Compare response since originalResponse has asOf time, which will be different every time
                    return _.isEqual(prev?.response, curr?.response);
                }),
                takeUntil(this.destroy$),
            )
            .subscribe(
                q => {
                    this.loading = false;
                    this.actionableSalesFlows = q.response.filter(salesFlow => {
                        const salesFlowHasCorrectStage = salesFlow.statusMessage !== 'Quote Requested';
                        const salesFlowHasSfdcQuotes = salesFlow.quotes?.length > 0;

                        return salesFlowHasCorrectStage && salesFlowHasSfdcQuotes;
                    });
                    this.generateComputedData();
                    this.detectAcceptedQuotesForSalesFlows();
                },
                q => {
                    console.log('error', q);
                    this.loading = false;
                    this.actionableSalesFlows = null;
                },
            );
    }

    ngOnDestroy(): void {
        this.unsubscribeFromRedux?.();
        this.unsubscribeFromUrlService?.();
        this.destroy$.next();
        this.destroy$.complete();
    }

    selectOrder(order: ServiceCatalogQuote): void {
        this.activeOrder = order;
        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'Select Order',
            properties: {
                category: 'Action',
            },
        });
    }

    getProductNames(salesFlow: ServiceCatalogQuote): string {
        if (!salesFlow || !this.computedProductsNames.get(salesFlow)) {
            return 'N/A';
        }
        let returnString = '';
        const computedNamesForGivenSalesFlow = this.computedProductsNames.get(salesFlow);
        computedNamesForGivenSalesFlow.forEach(productInfo => {
            returnString += productInfo.products + ' ';
        });

        return returnString.trim();
    }

    openInOrdersPage(referenceId: string): void {
        if (!referenceId) {
            return;
        }

        const idFilter = createFilter(ORDERS_ENTITY, DEFAULT_NAMESPACE, 'order_request_id', referenceId);

        this.ngRedux.dispatch([removeAllFilters(LOCAL_BAR_ORDERS), addFilter(LOCAL_BAR_ORDERS, idFilter)]);
    }

    private detectAcceptedQuotesForSalesFlows(): void {
        this.salesFlowWithAcceptedQuote.clear();
        this.actionableSalesFlows.forEach(salesFlow => {
            salesFlow.quotes.forEach(quote => {
                // Note: we should only ever have one accepted quote at a time, so no check for existing
                if (quote.partnerAcceptance === 'Accepted') {
                    this.salesFlowWithAcceptedQuote.set(salesFlow, quote);
                }
            });
        });
    }

    private generateComputedData(): void {
        this.computedProductsNames.clear();

        // The dash is used in Subscription based quotes but not in CAPEX quotes
        //  When we need a third format we will move the stringToAppend logic to a helper function
        if (this.actionableSalesFlows?.length > 0) {
            const dash = this.actionableSalesFlows[0].quoteType !== QuoteType.CAPEX ? ' - ' : '';

            this.actionableSalesFlows.forEach(salesFlow => {
                const newComputedData: ComputedData[] = [];
                const productServiceGrouping = new Map<string, Map<string, string[]>>();
                const sortedProducts = (salesFlow.products || [])
                    .slice()
                    .sort((a, b) =>
                        a.fullProductName.toLocaleLowerCase().localeCompare(b.fullProductName.toLocaleLowerCase()),
                    );

                sortedProducts.forEach(product => {
                    const productsInService = productServiceGrouping.get(product.serviceName) || new Map();
                    const productsInGroup = productsInService.get(product.groupName) || [];
                    productsInGroup.push(product.productName);
                    productsInService.set(product.groupName, productsInGroup);
                    productServiceGrouping.set(product.serviceName, productsInService);
                });

                productServiceGrouping.forEach((productsInGroup, serviceName) => {
                    let groupString = '';
                    productsInGroup.forEach((productNames, groupName) => {
                        const stringToAppend = `${groupName}${dash}${productNames.join(', ')}`;
                        if (groupString.length > 0) {
                            groupString += ', ';
                        }
                        groupString += stringToAppend;
                    });
                    newComputedData.push({
                        serviceName: serviceName,
                        products: groupString,
                    });
                });
                this.computedProductsNames.set(salesFlow, newComputedData);
            });
        }
    }

    private getSortOption(): SortParams | SortParams[] {
        // When sorting on status, we'll want to also pass in a secondary sort (the stage name)
        // This way, we'll keep similar stages together when they all have the same progress status
        return this.sortOption?.key === 'current_stage_progress'
            ? [this.sortOption, { key: 'status_message', order: this.sortOption.order }]
            : this.sortOption;
    }
}
