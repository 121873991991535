import _ from 'lodash';
import moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { catchError, concatMap, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Collection, Resource, DataPage, View, QueryParams, RoleAssignment, AssigneeType } from '@pure1/data';

import { Assignment, DeferredAssignable } from '../../user-management/user-management.interface';
import { smartTimer } from '@pstg/smart-timer';

export type MainPageState = 'users' | 'groups';
export type DrawerState =
    | 'view-list'
    | 'add-user'
    | 'add-sso-user'
    | 'add-external-user'
    | 'add-group'
    | 'edit-view'
    | 'add-view'
    | 'edit-user'
    | 'edit-sso-user'
    | 'edit-external-user'
    | 'edit-group'
    | 'batch-edit-assignments';

const MAX_NESTED_STATES = 3; // likely won't need depth more than one or two

@Injectable()
export class UserRoleStateService implements OnDestroy {
    // state variables
    currentMainPageState: MainPageState;
    get currentDrawerState(): DrawerState {
        return this.currentDrawerStateStack[0];
    }
    set currentDrawerState(newState: DrawerState) {
        if (newState) {
            this.currentDrawerStateStack.unshift(newState);
            if (this.currentDrawerStateStack.length > MAX_NESTED_STATES) {
                this.currentDrawerStateStack.pop(); // we drop any states that exceed maximum state stack depth
            }
        }
    }

    viewToEdit: Resource;
    deferAssignable = false;
    deferredAssignable: DeferredAssignable;
    allowSimpleFilter = true;
    assignmentToEdit: RoleAssignment;
    editedAssignment: Assignment;
    assignments: RoleAssignment[] = [];
    assignmentService: Collection<Assignment>;
    drawerClosed = true;

    readonly updateSelectionFromDrawer$ = new Subject<RoleAssignment[]>();

    private currentDrawerStateStack: DrawerState[] = [];
    private readonly viewNameChanges$ = new BehaviorSubject<void>(null); // subject to trigger assignments cache invalidation
    // we use a behvaior subject for viewNameChanges$ to allow call onNext automatically for each new subscription
    private readonly destroy$ = new Subject<void>();
    private readonly serviceCache = new Map<Collection<any>, BehaviorSubject<DataPage<any>>>();

    private readonly invalidateServicesMap = new Map<Collection<any>, Set<Collection<any>>>();
    private readonly serviceSubscriptionMap = new Map<Collection<any>, Subscription>();

    private closeFn: () => void;

    private static applyPatch<T extends Resource>(updated: T[], dataPage: DataPage<T>): DataPage<T> {
        const cachedIds = dataPage.response.map(item => item.id);
        const updatedMap = new Map<string, T>(updated.map<[string, T]>(item => [item.id, item]));

        if (!Array.from(updatedMap.keys()).every(id => cachedIds.includes(id))) {
            // we expect every value to be in the cache, but if not, we'll pick it up on the next list refresh
            console.warn('cannot update value that is not cached');
        }
        dataPage.response = dataPage.response.map(item => (updatedMap.has(item.id) ? updatedMap.get(item.id) : item));

        return dataPage;
    }

    private static applyDelete<T extends Resource>(ids: string[] | QueryParams, dataPage: DataPage<T>): DataPage<T> {
        let keys: string[] = [];
        if (!(ids instanceof Array)) {
            keys = [ids.email];
        } else {
            keys = ids.slice();
        }

        const cachedIds = dataPage.response.map(item => item.id);
        if (!keys.every(id => cachedIds.includes(id))) {
            // we expect every value to be in the cache, but if not, we'll pick it up on the next list refresh
            console.warn('cannot delete value that is not cached');
        }
        dataPage.response = dataPage.response.filter(item => !keys.includes(item.id)); // remove all items with given ids
        dataPage.total = dataPage.total - keys.length; // subtract the number we've just removed from the total
        return dataPage;
    }

    private static applyCreate<T extends Resource>(item: T, dataPage: DataPage<T>): DataPage<T> {
        dataPage.response.push(item); // this works because we assume no sort is applied
        dataPage.total += 1; // increment the total by one, the newly created value
        return dataPage;
    }

    private subscribeToRefetch<T extends Resource>(
        service: Collection<T>,
        cachedListResponse$: BehaviorSubject<DataPage<T>>,
    ): void {
        if (this.serviceSubscriptionMap.has(service)) {
            this.serviceSubscriptionMap.get(service).unsubscribe();
        }

        const subscription = smartTimer(0, moment.duration(30, 'seconds').asMilliseconds())
            .pipe(
                concatMap(() =>
                    service.list().pipe(
                        take(1),
                        catchError(err => {
                            console.error('Failed to load data', err);
                            return of<DataPage<T>>(null);
                        }),
                    ),
                ),
                filter(dataPage => dataPage != null),
                takeUntil(this.destroy$),
            )
            .subscribe(cachedListResponse$);

        this.serviceSubscriptionMap.set(service, subscription);
    }

    ngOnDestroy(): void {
        this.serviceCache.forEach(subject => {
            subject.complete();
        });
        this.destroy$.next();
        this.destroy$.complete();
    }

    invalidateRelatedServices<T extends Resource>(service: Collection<T>): void {
        if (this.invalidateServicesMap.has(service)) {
            this.invalidateServicesMap.get(service).forEach(relatedService => {
                // invalidate cached list response
                const cachedListResponse$ = this.serviceCache.get(relatedService);
                cachedListResponse$.next(null);
                this.subscribeToRefetch(relatedService, cachedListResponse$);
            });
        }
    }

    listWithCache<T extends Resource>(
        service: Collection<T>,
        invalidateOnServiceUpdate: Collection<any>[] = [],
    ): Observable<DataPage<T>> {
        if (!this.serviceCache.has(service)) {
            const cachedListResponse$ = new BehaviorSubject<DataPage<T>>(null);
            this.serviceCache.set(service, cachedListResponse$);

            this.subscribeToRefetch(service, cachedListResponse$);
        }

        invalidateOnServiceUpdate.forEach(relatedService => {
            if (this.invalidateServicesMap.has(relatedService)) {
                this.invalidateServicesMap.get(relatedService).add(service);
            } else {
                this.invalidateServicesMap.set(relatedService, new Set([service]));
            }
        });

        return this.serviceCache.get(service).pipe(filter(value => !!value)); // suppress the initial null value
    }

    // QueryParams is expected to be shaped as:
    // {
    //     email: string
    // }
    updateWithCache<T extends Resource>(
        service: Collection<T>,
        properties: Partial<T>,
        ids: string[] | QueryParams,
    ): Observable<DataPage<T>> {
        const updateCache = (updated: DataPage<T>) => {
            // callback to update the cached version of the data as soon as the backend returns successfully
            if (
                updated &&
                updated.response.length > 0 &&
                updated.response[0] instanceof View &&
                properties.hasOwnProperty('name')
            ) {
                // if we're updating a view name, then invalidate all assignment caches
                this.viewNameChanges$.next();
            }
            this.invalidateRelatedServices(service);
            if (this.serviceCache.has(service)) {
                const cachedDataPage = this.serviceCache.get(service).getValue();
                if (cachedDataPage && updated && updated.response.length > 0) {
                    this.serviceCache
                        .get(service)
                        .next(UserRoleStateService.applyPatch(updated.response, cachedDataPage));
                }
            }
        };
        /* NOTE: if update is applied to multiple ids and the backend experiences partial failure (i.e., some items were
         * changed successfully while other items were not), then we need to invalidate the cache, unsubscribe, and resubscribe.
         * In this case, we only update a single item for each backend call, so there is no need for more advanced error
         * handling, but we will need to fix and test this if we want to generalize further.
         */
        return service.update(properties, ids).pipe(tap(updateCache));
    }

    createWithCache<T extends Resource>(service: Collection<T>, properties: Partial<T>): Observable<T> {
        const updateCache = (item: T) => {
            // callback to update the cached version of the data as soon as the backend returns successfully
            this.invalidateRelatedServices(service);
            if (this.serviceCache.has(service)) {
                const cachedDataPage = this.serviceCache.get(service).getValue();
                if (cachedDataPage) {
                    this.serviceCache.get(service).next(UserRoleStateService.applyCreate(item, cachedDataPage));
                }
            }
        };
        return service.create(properties).pipe(tap(updateCache));
    }

    // QueryParams is expected to be shaped as:
    // {
    //     email: string
    // }
    deleteWithCache<T extends Resource>(service: Collection<T>, ids: string[] | QueryParams): Observable<void> {
        const updateCache = () => {
            // callback to update the cached version of the data as soon as the backend returns successfully
            this.invalidateRelatedServices(service);
            if (this.serviceCache.has(service)) {
                const cachedDataPage = this.serviceCache.get(service).getValue();
                if (cachedDataPage) {
                    this.serviceCache.get(service).next(UserRoleStateService.applyDelete(ids, cachedDataPage));
                }
            }
        };
        if (ids instanceof Array) {
            return zip(...ids.map(id => service.delete(id))).pipe(
                tap(updateCache),
                map(() => {}),
            );
        } else {
            return service.delete(ids.email, ids).pipe(tap(updateCache));
        }
    }

    editView(view: Resource, deferViewCreation = false, allowSimpleFilter = true): void {
        this.viewToEdit = view;
        this.deferAssignable = deferViewCreation;
        this.allowSimpleFilter = allowSimpleFilter;
        this.currentDrawerState = 'edit-view';
        this.drawerClosed = false;
    }

    createView(): void {
        this.editView(null, false, false);
    }

    previousDrawerState(): void {
        // return to most recent state
        if (this.currentDrawerStateStack.length > 1) {
            this.currentDrawerStateStack.shift();
        } else {
            this.closeDrawer();
        }
    }

    editAssignment(assignment: RoleAssignment): void {
        switch (assignment.assigneeType) {
            case AssigneeType.USER:
                this.currentDrawerState = 'edit-user';
                break;
            case AssigneeType.EXTERNAL:
                this.currentDrawerState = 'edit-external-user';
                break;
            case AssigneeType.GROUP:
                this.currentDrawerState = 'edit-group';
                break;
            case AssigneeType.SSO_USER:
                this.currentDrawerState = 'edit-sso-user';
                break;
            default:
                console.warn('Unexpected assignment type', assignment.assigneeType);
                return;
        }

        this.assignmentToEdit = assignment;
        this.drawerClosed = false;
    }

    setSelectedAssignments(assignments: RoleAssignment[]): void {
        this.assignments = _.cloneDeep(assignments);
        if (this.assignments.length > 0) {
            this.currentDrawerState = 'batch-edit-assignments';
            this.drawerClosed = false;
        } else {
            this.drawerClosed = true;
        }
    }

    openDrawer(drawerState: DrawerState): void {
        this.currentDrawerState = drawerState;
        this.drawerClosed = false;
    }

    closeDrawer(): void {
        this.currentDrawerState = null;
        this.currentDrawerStateStack = [];
        this.drawerClosed = true;
    }

    getDrawerNotifier(): Subject<RoleAssignment[]> {
        return this.updateSelectionFromDrawer$;
    }

    updateSelectionFromDrawer(selection: RoleAssignment[]): void {
        this.updateSelectionFromDrawer$.next(selection);
    }
}
