<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>

<section class="api-main-content">
    <div class="register-api-content">
        <button
            class="btn btn-secondary register-api-button"
            (click)="openModal(createApiKeyModal)"
            [disabled]="('PURE1:write:apikey' | isNotAllowed) || apiAppKeys.length >= API_KEY_LIMIT"
        >
            <pureui-svg class="add-icon" svg="create_add.svg" height="12"></pureui-svg>
            Register Application
        </button>
        <!-- Error message -->
        <div class="text-danger limit-error-message" *ngIf="apiAppKeys.length >= API_KEY_LIMIT">
            You have reached your limit of {{ API_KEY_LIMIT }} Applications
        </div>
    </div>

    <pureui-horizontal-resize id="api-app-keys-resize">
        <div class="table-fixed-header-container api-app-keys-container">
            <table
                class="table table-bordered table-fixed-header table-hover api-app-keys-table"
                [pureshadowscroll]="{ scrollElem: 'tbody', shadowElem: 'thead' }"
            >
                <thead class="thead-default">
                    <tr>
                        <th class="weight-5">Application Name</th>
                        <th class="weight-5">Application ID</th>
                        <th class="weight-3">Pure1 Role</th>
                        <th class="weight-5">Public Key</th>
                        <th class="weight-5">Created By</th>
                        <th class="weight-4">Created</th>
                        <th class="weight-4">Last Used</th>
                        <th class="weight-1 last-column"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let apiAppKey of apiAppKeys; trackBy: 'id' | trackByProperty">
                        <td class="weight-5">{{ apiAppKey.name }}</td>
                        <td class="weight-5">{{ apiAppKey.id }}</td>
                        <td class="weight-3">{{ $any(apiAppKey.max_role) | roleType }}</td>
                        <td class="weight-5">{{ apiAppKey.public_key }}</td>
                        <td class="weight-5" [ngbTooltip]="apiAppKey.created_by" placement="top" container="body">
                            <div class="truncate-cell-content">{{ apiAppKey.created_by }}</div>
                        </td>
                        <td class="weight-4">{{ $any(apiAppKey.created_at) | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                        <td class="weight-4">{{ $any(apiAppKey.last_used) | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                        <td
                            class="weight-1 last-column delete-api-key-button"
                            (click)="openModal(deleteApiKeyModal, apiAppKey)"
                        >
                            <pureui-svg class="pstg-action-icon" svg="trash_erase.svg" height="16"></pureui-svg>
                        </td>
                    </tr>
                    <tr class="no-results-row" *ngIf="apiAppKeys.length === 0 && !loading"> No applications found. </tr>
                </tbody>
            </table>
        </div>
    </pureui-horizontal-resize>
    <div class="api-keys-swagger-link"
        >To learn more about how to use the API,
        <a href="https://static.pure1.purestorage.com/api-docs/1.latest/index.html" target="_blank">click here</a>.</div
    >
</section>

<ng-template #createApiKeyModal let-modal>
    <create-api-key-modal [modal]="modal"> </create-api-key-modal>
</ng-template>

<ng-template #deleteApiKeyModal let-modal>
    <delete-api-key-modal [modal]="modal" [apiAppKey]="selectedApiAppKey"> </delete-api-key-modal>
</ng-template>
