<div *ngIf="arrayVersionErrors.length > 0" class="alert alert-danger" role="alert">
    <ul>
        <li *ngFor="let versionError of arrayVersionErrors; trackBy: trackByArrayId">
            <strong>{{ arraysMap.get(versionError.arrayId).hostname }}</strong
            >: {{ versionError.errorMessage }}
        </li>
    </ul>
</div>

<ng-template #sortHeader let-name="name" let-title="title">
    <th (click)="sortByColumn(name)" class="cell-{{ name }}">
        <span [ngClass]="getSortClass(name)">
            {{ title }}
        </span>
    </th>
</ng-template>

<div class="mb-1"
    >Select up to <strong>{{ arrayThreshold }} appliances</strong> to upgrade, and specify the upgrade version for each
    selection.</div
>

<table class="table array-selector-table">
    <thead class="thead-default" [class.loading]="loading">
        <tr class="multiline">
            <th class="cell-checkbox"></th>
            <th class="ac-connector-cell"></th>
            <ng-container *ngTemplateOutlet="sortHeader; context: { name: 'hostname', title: 'Name' }"></ng-container>
            <ng-container *ngTemplateOutlet="sortHeader; context: { name: 'policy', title: 'Policy' }"></ng-container>
            <ng-container *ngTemplateOutlet="sortHeader; context: { name: 'model', title: 'Model' }"></ng-container>
            <ng-container
                *ngTemplateOutlet="sortHeader; context: { name: 'version', title: 'Current Version' }"
            ></ng-container>
            <th class="cell-target-version">Upgrade Version</th>
        </tr>
        <tr class="multiline">
            <th></th>
            <th></th>
            <th>
                <input class="form-control search-name" [(ngModel)]="nameFilter" (input)="updateFilter()" />
            </th>
            <th> </th>
            <th>
                <input class="form-control search-model" [(ngModel)]="modelFilter" (input)="updateFilter()" />
            </th>
            <th>
                <input
                    class="form-control search-version"
                    [(ngModel)]="currentVersionFilter"
                    (input)="updateFilter()"
                />
            </th>
            <th> </th>
        </tr>
    </thead>
    <tbody *ngIf="!loading && hasSNOWContact">
        <tr
            *ngFor="let array of filteredSortedArrays"
            [class.ssu-available]="hasSsuAvailable(array.currentVersion)"
            [class]="getRowClass(array.applianceId)"
        >
            <td
                [attr.rowspan]="
                    isReplicationAppliancePrimary(array.applianceId) &&
                    isReplicatedApplianceNextToOther(array.applianceId)
                        ? 2
                        : null
                "
                *ngIf="
                    !(
                        isReplicationApplianceSecondary(array.applianceId) &&
                        isReplicatedApplianceNextToOther(array.applianceId)
                    )
                "
                class="table-cell-height-limit cell-checkbox"
            >
                <div class="status-column-cell">
                    <div>
                        <span [ngbTooltip]="disabledArrays[array.applianceId]" container="body" placement="right">
                            <input
                                type="checkbox"
                                [id]="'array-selector-' + array.applianceId"
                                [checked]="selectedArraysMap[array.applianceId]"
                                (change)="toggleArraySelection(array.applianceId)"
                                [disabled]="disabledArrays[array.applianceId]"
                            />
                        </span>
                    </div>
                </div>
            </td>
            <td
                class="ac-icon-cell"
                *ngIf="!isReplicationApplianceSecondary(array.applianceId)"
                [attr.rowspan]="isReplicationAppliancePrimary(array.applianceId) ? 2 : null"
            >
                <div class="replication-icon-wrapper" *ngIf="isReplicationAppliancePrimary(array.applianceId)">
                    <pureui-svg svg="pod.svg" height="10" class="replication-icon"></pureui-svg>
                </div>
            </td>
            <td class="ac-connector-cell">
                <div class="connector" *ngIf="isApplianceReplicated(array.applianceId)"></div>
            </td>
            <td class="array-name-cell cell-hostname">
                <label [htmlFor]="'array-selector-' + array.applianceId">{{ getArrayName(array) }}</label>
            </td>
            <td class="cell-policy">
                <span>
                    {{ isApplianceFlashArray(array) ? getPolicyName(array.policy) : '-' }}
                </span>
            </td>
            <td class="cell-model">{{ array.hardwareModel }}</td>
            <td class="cell-version"
                >{{ array.currentVersion }}
                <pureui-svg
                    *ngIf="hasSsuAvailable(array.currentVersion)"
                    height="12"
                    svg="info-circle.svg"
                    ngbTooltip="Check out the Self-Service Upgrade option."
                ></pureui-svg
            ></td>
            <td
                class="cell-target-version"
                [attr.rowspan]="
                    isReplicationAppliancePrimary(array.applianceId) &&
                    isReplicatedApplianceNextToOther(array.applianceId)
                        ? 2
                        : null
                "
                *ngIf="
                    !(
                        isReplicationApplianceSecondary(array.applianceId) &&
                        isReplicatedApplianceNextToOther(array.applianceId)
                    )
                "
            >
                <ng-container *ngIf="arrayLoadingCounter[array.applianceId] === 0; else versionsLoading">
                    <ng-container
                        *ngIf="
                            commonVersionsDropdownItems[array.applianceId] &&
                                commonVersionsDropdownItems[array.applianceId]?.length !== 0;
                            else noVersions
                        "
                    >
                        <pure-expandable-dropdown
                            [items]="commonVersionsDropdownItems[array.applianceId] || []"
                            [selectedValue]="selectedVersions[array.applianceId]"
                            (selectionChange)="onVersionSelectedForArray(array.applianceId, $event)"
                            emptyText="Select"
                            [shouldBoldRecommended]="isApplianceFlashArray(array)"
                            showItemsText="Show out of the lifecycle policy"
                            hideItemsText="Hide out of the lifecycle policy"
                            noDisplayedItemsText="No recommended versions"
                            [displayedItemsText]="isApplianceFlashArray(array) ? 'Policy recommended' : ''"
                            [isDisabled]="commonVersionsDropdownItems[array.applianceId]?.length === 0"
                            ngbTooltip="No upgrade versions available for this appliance."
                            [disableTooltip]="commonVersionsDropdownItems[array.applianceId]?.length !== 0"
                        >
                        </pure-expandable-dropdown>
                    </ng-container>
                    <ng-template #noVersions>
                        <div class="no-versions-text"> (no versions available) </div>
                    </ng-template>
                </ng-container>
                <ng-template #versionsLoading>
                    <div class="form-control-loading">
                        <pureui-spinner [pureuiShow]="true"></pureui-spinner>
                    </div>
                </ng-template>
            </td>
        </tr>
    </tbody>
</table>
<div *ngIf="loading || !isSNOWInitialized" class="col-xs-12 loader">
    <pureui-spinner [pureuiShow]="true"></pureui-spinner>
</div>

<div *ngIf="!loading && !hasSNOWContact && isSNOWInitialized" class="col-xs-12 loader">
    <div
        >You have read-only access. First time access to the Pure1 Manage Support Cases page requires a verification
        step. This process requires a minimum of 45 minutes to resolve. You can retry accessing the page again later. If
        this is not your first time, work with your Administrator to validate your access.</div
    >
</div>

<div class="btn-toolbar clearfix">
    <pure-entity-selection-dropdown
        entityName="Selections"
        [isSelectable]="false"
        [entities]="selectedArrays"
        (entitiesChange)="updateSelectedArrays($event)"
    >
    </pure-entity-selection-dropdown>
    <button type="button" class="btn btn-primary float-xs-right margin" (click)="onNext()" [disabled]="disableForward">
        Schedule &gt;
    </button>
    <button type="button" class="btn btn-info float-xs-right margin" (click)="onCancel.emit()"> Cancel </button>
</div>
