import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Collection } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';
import { CustomIdpConfig } from '../models/custom-idp-config';

const IDP_ENDPOINT = '/api/1.0/sso-config';

const IDP_CONFIG_UPDATE_CHECK_ENDPOINT = IDP_ENDPOINT + '/requestupdate';

/*
 * To replace CustomIdpConfigService when views ships
 */
@Injectable({ providedIn: 'root' })
export class CustomIdpConfigServiceV2 implements Collection<CustomIdpConfig> {
    constructor(private http: HttpClient) {}

    list(): Observable<DataPage<CustomIdpConfig>> {
        return this.http.get(IDP_ENDPOINT).pipe(map(response => this.makeDataPage(response)));
    }

    create(properties: Partial<CustomIdpConfig>): Observable<CustomIdpConfig> {
        return this.http.post(IDP_ENDPOINT, properties).pipe(map(response => new CustomIdpConfig(response)));
    }

    update(_properties: Partial<CustomIdpConfig>): Observable<DataPage<CustomIdpConfig>> {
        throw new Error('Not Supported');
    }

    delete(id: string): Observable<void> {
        return this.http.delete(`${IDP_ENDPOINT}/${id}`).pipe(map(() => void 0)); // Discard response
    }

    confirm(id: string): Observable<CustomIdpConfig> {
        return this.http.post(`${IDP_ENDPOINT}/confirm/${id}`, {}).pipe(map(response => new CustomIdpConfig(response)));
    }

    requiresUpdate(): Observable<void> {
        return this.http.get(IDP_CONFIG_UPDATE_CHECK_ENDPOINT, { responseType: 'text' }).pipe(map(() => void 0)); // Discard response
    }

    private makeDataPage(wrapper: any): DataPage<CustomIdpConfig> {
        const customIdpConfigs = wrapper.map(json => new CustomIdpConfig(json));
        return {
            total: customIdpConfigs.length,
            response: customIdpConfigs,
        };
    }
}
