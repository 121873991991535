import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { hasPermissionGuard } from '../app/guards/permission-guard';

import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';

const routes: Routes = [
    {
        // Define all the actual feature modules as children so we can wrap everything under PageWrapperComponent
        path: '',
        component: PageWrapperComponent,
        canActivate: [
            // PageWrapperComponent will cause errors if the user is not logged in. So just in case someone forgets to define
            // an permission on their route, ensure that users not logged in are at least redirected to the login page.
            hasPermissionGuard(null),
        ],
        children: [
            {
                path: 'administration',
                loadChildren: () =>
                    import('../administration/administration.module').then(mod => mod.AdministrationModule),
            },
            {
                path: 'assets',
                loadChildren: () =>
                    import('../asset-management/asset-management.module').then(mod => mod.AssetManagementModule),
            },
            {
                path: 'fleet',
                loadChildren: () => import('../fleet/fleet.module').then(mod => mod.FleetModule),
            },
            {
                path: 'messages',
                loadChildren: () => import('../messages/messages.module').then(mod => mod.MessagesModule),
            },
            {
                path: 'analysis',
                loadChildren: () => import('../analytics/analytics.module').then(mod => mod.AnalyticsModule),
            },
            {
                path: 'protection',
                loadChildren: () => import('../protection/protection.module').then(mod => mod.ProtectionModule),
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard/dashboard.module').then(mod => mod.DashboardModule),
            },
            {
                path: 'draas',
                loadChildren: () =>
                    import('../disaster-recovery/disaster-recovery.module').then(mod => mod.DisasterRecoveryModule),
            },
            {
                path: 'services',
                loadChildren: () =>
                    import('../service-catalog/service-catalog.module').then(mod => mod.ServiceCatalogModule),
            },
            {
                path: 'support',
                loadChildren: () => import('../support/support.module').then(mod => mod.SupportModule),
            },
            {
                path: 'pure4sight',
                loadChildren: () => import('../pure4sight/pure4sight.module').then(mod => mod.Pure4SightModule),
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('../notification-center/notification-center.module').then(
                        mod => mod.NotificationCenterModule,
                    ),
            },
        ],
    },
    {
        // Invalid route handling
        // This MUST be here in PageRouting, and MUST be at the end of the list.
        // This redirects to /fleet, and if the user doesn't have access to that page, the hasPermissionGuard will redirect them again to an appropriate page.
        path: '**',
        pathMatch: 'full',
        redirectTo: 'fleet',
    },
];

/**
 * A custom TitleStrategy that just prevents changing the page title at all.
 * We may want to have the page title change in the future, but we need to fully migrate to Angular router first
 * before we can do that through the router, otherwise pages that are not yet migrated won't show the right title
 * (it'll just show whatever the title was previously, and won't match the current page).
 * See: CLOUD-121155
 */
@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
    constructor(private readonly title: Title) {
        super();
    }

    buildTitle(_snapshot: RouterStateSnapshot): string | undefined {
        return undefined;
    }

    getResolvedTitleForRoute(_snapshot: ActivatedRouteSnapshot): any {
        return null;
    }

    override updateTitle(_snapshot: RouterStateSnapshot): void {}
}

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [{ provide: TitleStrategy, useClass: CustomTitleStrategy }],
})
export class PageRoutingModule {}
