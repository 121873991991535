import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment-timezone';

import { Schedule, VersionedArray } from '../../types';

@Component({
    selector: 'upgrade-confirmation-generic',
    templateUrl: 'upgrade-confirmation-generic.component.html',
})
export class UpgradeConfirmationGenericComponent implements OnInit {
    @Input() readonly schedule: Schedule;
    @Output() readonly onBack: EventEmitter<any> = new EventEmitter();
    @Output() readonly onCancel: EventEmitter<any> = new EventEmitter();
    @Output() readonly onForward: EventEmitter<any> = new EventEmitter();

    confirmForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.confirmForm = this.fb.group({
            comment: '',
            bestPracticesCheck: [false, Validators.requiredTrue],
        });
    }

    continue(): void {
        const { bestPracticesCheck, comment } = this.confirmForm.value;
        if (bestPracticesCheck) {
            this.onForward.emit(comment);
        }
    }

    getTimezonePrettyName(timezone: string): string {
        if (!timezone) {
            return '';
        }
        const raw = timezone.split('/');
        const city = raw[raw.length - 1].replace('_', ' ');
        return city + ' (UTC' + moment.tz(timezone).format('Z') + ')';
    }

    getDateRangeFromMoments(from: moment.Moment, to: moment.Moment): string {
        let output = '';
        if (from) {
            output += from.format('MMM D, YYYY');
        }
        output += ' - ';
        if (to) {
            output += to.format('MMM D, YYYY');
        }
        return output;
    }
}
