import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { smartTimer } from '@pstg/smart-timer';
import { DefaultResourceProvider, Resource } from '@pure/authz-authorizer';
import moment from 'moment';
import { merge, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, exhaustMap, map, startWith, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PlatformClientService } from '@pure/pure1-ui-platform-angular';
import { Oauth2Introspect } from 'core/src/services/authentication.service';
import { WINDOW } from '../../app/injection-tokens';
import { ErrorCode } from '../../error/error-view/error-code';
import { DataPage } from '../interfaces/data-page';
import { CurrentUser } from '../models/current-user';
import { CurrentUserService } from './current-user.service';

@Injectable({ providedIn: 'root' })
export class CachedCurrentUserService implements OnDestroy, DefaultResourceProvider {
    private currentUser$ = new ReplaySubject<CurrentUser>(1);
    private readonly destroy$ = new Subject<void>();
    private readonly refresh$ = new Subject<void>();
    private readonly forceRefresh$ = new Subject<void>();

    private initialized = false;

    constructor(
        private currentUserService: CurrentUserService,
        private httpClient: HttpClient,
        private router: Router,
        private platformClientService: PlatformClientService,
        @Inject(WINDOW) private window: Window,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get(): Observable<CurrentUser> {
        this.tryToInitialize();
        this.refresh$.next();
        return this.currentUser$.asObservable();
    }

    forceRefresh(): void {
        this.tryToInitialize();
        this.forceRefresh$.next();
    }

    private tryToInitialize(): void {
        if (!this.initialized) {
            this.initialized = true;
            this.subscribeOnRefresh();
        }
    }

    private subscribeOnRefresh(): void {
        let isFirstFetch = true;

        const periodicRefresh$ = smartTimer(
            0,
            moment.duration(5, 'minutes').asMilliseconds(),
            moment.duration(5, 'minutes').asMilliseconds(),
        );

        const throttledRefresh$ = merge(this.refresh$.pipe(startWith(void 0)), periodicRefresh$).pipe(
            throttleTime(moment.duration(30, 'seconds').asMilliseconds()),
        );

        this.forceRefresh$
            .pipe(
                startWith(void 0),
                switchMap(() => throttledRefresh$),
                exhaustMap(() => this.currentUserRequest(isFirstFetch)),
                takeUntil(this.destroy$),
                tap(() => (isFirstFetch = false)),
            )
            .subscribe({
                next: this.handleNext.bind(this),
                error: this.handleError.bind(this),
            });
    }

    private currentUserRequest(isFirstFetch: boolean): Observable<DataPage<CurrentUser>> {
        return this.currentUserService.list().pipe(
            take(1),
            catchError(err => {
                // If we fail when fetching the user for the first time, that is catastrophic since the site doesn't support a null CurrentUser.
                // For subsequent failures, we can continue to just use the old CurrentUser.
                if (isFirstFetch) {
                    return throwError(() => err);
                } else {
                    return of({ response: [], total: 0 });
                }
            }),
        );
    }

    private handleNext(res: DataPage<CurrentUser> | { response: any[]; total: number }): void {
        if (res?.response?.[0]) {
            // Only update the cached value if we have a valid value. On first page load, this will cause the site
            // to "hang" (and user to likely refresh). On subsequent updates, it'll just use the previous value.
            // Almost none of our code handles an invalid user object, nor should it need to.
            this.currentUser$.next(res.response[0]);
        } else {
            console.warn('Failed to update cachedCurrentUser - will continue to use previous value');
        }
    }

    private handleError(err: unknown): void {
        // This error branch should only happen if we fail the first call to fetch CurrentUser
        if (err instanceof HttpErrorResponse && err.status === 404) {
            // When there is a 404, it is most likely because the user is impersonating an invalid user, eg a user with a deactivated SFDC account.
            // See: https://jira.purestorage.com/browse/CLOUD-85924
            // In this case, we want to let them know what's happening, and stop the impersonation.
            // Call /introspect to look in the access token who's the user currently logged in.
            this.httpClient.get<Oauth2Introspect>('/auth/introspect', {}).subscribe(claims => {
                if (claims.act?.email?.endsWith('@purestorage.com')) {
                    this.window.alert(
                        'You are impersonating a user who may not be allowed to log in to Pure1.\n' +
                            'Please reach out to #ask-cx-dtt for assistance addressing any issues in SFDC impacting this user.\n\n' +
                            'You will be redirected to your own account.',
                    );
                    // Initiate a login with auth-portal to get normal user token back
                    this.platformClientService.login();
                } else {
                    // In very rare case, if a customer has been de-activated while being logged in.
                    this.platformClientService.logout();
                }
            });
        } else {
            // Something went wrong during login, show error message.
            this.router.navigate(['/errors'], { queryParams: { code: ErrorCode.IDP_INTERNAL_ERROR } });
        }
    }

    getDefaultResource(): Observable<Resource> {
        return this.currentUser$.pipe(
            map(
                cu =>
                    ({
                        type: 'EXPANDED_ORGANIZATION_PHONEBOOK_ID',
                        id: cu.organization_id,
                    }) as Resource,
            ),
        );
    }
}
