<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="modal.close()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title delete-configuration-title">Remove Accounts</h3>
</div>
<section class="confirmation-section">
    <span class="confirmation-text">The following accounts will be removed:</span>
    <div class="to-be-deleted-list">
        <ng-container
            *ngFor="
                let assignment of assignments?.slice(0, MAX_ASSIGNMENTS_TO_SHOW) || [];
                trackBy: 'id' | trackByProperty
            "
        >
            <div class="assignment-names">{{ assignment.name }}</div>
        </ng-container>
        <span class="other-deletion-text" *ngIf="assignments?.length > MAX_ASSIGNMENTS_TO_SHOW">
            And {{ assignments.length - MAX_ASSIGNMENTS_TO_SHOW }} other account{{
                assignments.length - MAX_ASSIGNMENTS_TO_SHOW === 1 ? '' : 's'
            }}
        </span>
    </div>
    <span class="confirmation-text">Are you sure you want to remove these accounts?</span>
</section>
<section class="actions-row">
    <button
        class="btn btn-info cancel-delete-button"
        (click)="modal.close()"
        [disabled]="loading"
        angulartics2On="click"
        angularticsAction="UM - Reject delete user"
        angularticsCategory="Action"
    >
        No
    </button>
    <button
        class="btn btn-danger delete-assignments-button"
        pure-read-only
        (click)="deleteAssignments()"
        [disabled]="loading"
        angulartics2On="click"
        angularticsAction="UM - Confirm delete user"
        angularticsCategory="Action"
    >
        <pureui-spinner [pureuiShow]="isProcessing"></pureui-spinner>
        Yes, remove these accounts
    </button>
</section>
