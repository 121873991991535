import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment-timezone';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
    NgbCalendar,
    NgbDate,
    NgbDateStruct,
    NgbDatepicker,
    NgbDatepickerNavigateEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import { CachedCurrentUserService } from '@pure1/data';

import { Timeslot, VersionedArray } from '../../types';
import { SupportUpgradeService } from '../../services/support-upgrade.service';
import { AbstractUpgradePlannerComponent } from '../abstract-upgrade-planner/abstract-upgrade-planner.component';
import { FreeTimeUpdates, ProductLine } from '../../../support.interface';
import { CduAppliance, Release } from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.interface';
import { splitUpSlots } from '../../../support.utils';
import { getArrayUniqueName } from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.utils';
import { PartialDeep } from 'lodash';

function getDayAsMoment(day: NgbDateStruct, timezone: string): moment.Moment {
    return moment.tz(
        {
            year: day.year,
            month: day.month - 1,
            day: day.day,
        },
        timezone,
    );
}

// Interval between timeslot starttimes
const TIMESLOT_INTERVAL = moment.duration(60, 'minutes');

type MarkDisabledFunction = (date: NgbDateStruct, currentMonth: { year: number; month: number }) => boolean;

@Component({
    selector: 'upgrade-planner',
    templateUrl: 'upgrade-planner.component.html',
})
export class UpgradePlannerComponent extends AbstractUpgradePlannerComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('dp') datepicker: NgbDatepicker;

    @Input() readonly arrays: VersionedArray[];
    @Input() readonly maxHops: number;
    @Input() readonly freeTimeUpdates: FreeTimeUpdates;
    @Input() readonly releasesMap: { [key: string]: Release } = {};
    @Input() readonly replicationTargets: { [arrayId: string]: CduAppliance };
    @Input() readonly hasDuplicateApplianceNames: boolean;

    selectedDate: NgbDateStruct | null = null;
    selectedArray: VersionedArray;
    selectedTimeslots: { [id: string]: Timeslot } = {};
    timeslots: Timeslot[];
    filteredTimeslots: Timeslot[];
    timeslotsLoading = true;
    scrollToTimeslot: Timeslot = null;
    arraySelection$ = new ReplaySubject<void>(1);

    /**
     * Passed into NgbDatePicker; returns whether or not the specified date
     * should be disabled.
     */
    markDayDisabledFn$: Observable<MarkDisabledFunction>;
    markDayDisabledFnSubscription: Subscription;

    constructor(
        protected fb: UntypedFormBuilder,
        protected cachedCurrentUserService: CachedCurrentUserService,
        protected calendar: NgbCalendar,
        private upgradeService: SupportUpgradeService,
    ) {
        super(fb, cachedCurrentUserService, calendar);
    }

    /**
     * Extends existing initialization with timeslots logic
     */
    ngOnInit(): void {
        super.ngOnInit();

        this.selectedTimeslots = {};
        this.upgradeForm.registerControl('timeslot', this.fb.control(null, Validators.required));

        this.upgradeForm.controls.timeslot.valueChanges.subscribe(this.onTimeslotSelected);
        this.upgradeForm.controls.timezone.valueChanges.subscribe(() => {
            this.selectedTimeslots = {};
            this.upgradeForm.controls.timeslot.setValue(null);
            this.onDatepickerNavigate(null);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.arrays) {
            if (!this.selectedArray || !changes.arrays.currentValue.some(arr => arr === this.selectedArray)) {
                if (changes.arrays.currentValue.length > 0) {
                    this.setSelectedArray(changes.arrays.currentValue[0]);
                }
            }
        }

        if (changes.freeTimeUpdates?.currentValue) {
            const newFreeTimes =
                this.selectedArray.productLine === ProductLine.FlashArray
                    ? changes.freeTimeUpdates.currentValue.freeTimesForFlashArray
                    : changes.freeTimeUpdates.currentValue.freeTimesForFlashBlade;
            const arraysWithConflicts = changes.freeTimeUpdates.currentValue.conflictingArrays;

            if (this.markDayDisabledFnSubscription && !this.markDayDisabledFnSubscription.closed) {
                this.markDayDisabledFnSubscription.unsubscribe();
            }

            const tzNow = this.upgradeForm.controls.timezone.value;

            this.markDayDisabledFn$ = this.upgradeForm.controls.timezone.valueChanges.pipe(
                startWith(tzNow),
                map(timezone => ({
                    timeslots: splitUpSlots(newFreeTimes, moment.duration(1, 'hours'), TIMESLOT_INTERVAL),
                    timezone: timezone as string,
                })),
                tap(({ timeslots }) => {
                    this.timeslots = timeslots;
                    const newSelectedTimeslots = {};
                    Object.keys(this.selectedTimeslots).forEach(arrayId => {
                        if (
                            !arraysWithConflicts.some(arrWithConflict => arrWithConflict.array.applianceId === arrayId)
                        ) {
                            newSelectedTimeslots[arrayId] = this.selectedTimeslots[arrayId];
                        }
                    });
                    this.selectedTimeslots = newSelectedTimeslots;
                }),
                // Merge map to allow recalculation of timeslots on selected array change, but to keep selected timeslots
                mergeMap(params => this.arraySelection$.pipe(map(() => params))),
                // Take only relevant slots
                map(({ timeslots, timezone }) => {
                    return this.filterTimeslots(timeslots, timezone);
                }),
                tap(({ timeslots }) => {
                    this.filteredTimeslots = timeslots;
                }),
                map(({ timeslots, timezone }) => this.makeMarkDayDisabledFn(timeslots, timezone)),
            );

            this.setSelectedArray(arraysWithConflicts[0]); // it is guaranteed to have some items

            this.markDayDisabledFnSubscription = this.markDayDisabledFn$.subscribe(makeMarkDayDisabled => {
                this.timeslotsLoading = false;
                this.makeMarkDayDisabled = makeMarkDayDisabled;
            });
        }
    }

    ngOnDestroy(): void {
        if (this.markDayDisabledFnSubscription && !this.markDayDisabledFnSubscription.closed) {
            this.markDayDisabledFnSubscription.unsubscribe();
        }
    }

    filterTimeslots(timeslots: Timeslot[], timezone: string): { timeslots: Timeslot[]; timezone: string } {
        const replicationTarget = this.replicationTargets[this.selectedArray?.array.applianceId];
        const replicationTimeslot = this.selectedTimeslots[replicationTarget?.applianceId];

        const filteredTimeslots = timeslots.filter(timeslot =>
            this.hasTimeslotNumOfAvailableSuccessors(timeslot, timeslots),
        );

        if (!replicationTimeslot) {
            return {
                timeslots: filteredTimeslots,
                timezone,
            };
        }

        const replicationTargetVersioned = this.arrays.find(x => x.array.applianceId === replicationTarget.applianceId);
        const hops = replicationTargetVersioned.numberOfHops;

        const spread = moment.duration(this.getDurationFactor(), 'h');
        const start = replicationTimeslot.startTime.clone().subtract(spread);
        const end = replicationTimeslot.startTime.clone().add(spread);
        if (hops === 1) {
            // the user can select any time for the other array except for the time that has is already selected.
            return {
                timeslots: filteredTimeslots.filter(timeslot => !timeslot.startTime.isBetween(start, end)),
                timezone,
            };
        } else {
            //  the upgrade must be completed continously within the same day
            return {
                timeslots: filteredTimeslots.filter(
                    timeslot => timeslot.startTime.isSame(start) || timeslot.startTime.isSame(end),
                ),
                timezone,
            };
        }
    }

    onTimeslotSelected = (newValue: Timeslot): void => {
        if (this.selectedArray) {
            if (!newValue) {
                this.selectedTimeslots[this.selectedArray.array.applianceId] = newValue;
                return;
            }

            this.selectedTimeslots[this.selectedArray.array.applianceId] = {
                startTime: newValue.startTime,
                duration: moment.duration(this.getDurationFactor(), 'hours'),
                capacity: 1,
            };
        }
    };

    getDurationFactor(): number {
        const durationInHours = Math.ceil(this.selectedArray.upgradeDurationSeconds / 3600);
        if (!durationInHours) {
            return 1;
        }
        if (this.replicationTargets[this.selectedArray.array.applianceId]) {
            return 2 * durationInHours;
        }
        return durationInHours;
    }

    hasSsuAvailable(version: string): boolean {
        return this.releasesMap[version]?.emsSupported;
    }

    isArrayInDay(array: VersionedArray, date: NgbDate): boolean {
        const arrayTimeslot = this.selectedTimeslots[array.array.applianceId];
        return arrayTimeslot?.startTime.isSame(this.ngbDateToMoment(date), 'day');
    }

    numberOfArraysInDate(date: NgbDate): number {
        return this.arrays.reduce((count, arr) => (this.isArrayInDay(arr, date) ? count + 1 : count), 0);
    }

    setSelectedArray(array: VersionedArray): void {
        this.selectedArray = array;
        this.onDatepickerNavigate(null);
        const timeslot = this.selectedTimeslots[array.array.applianceId];
        if (timeslot) {
            this.selectedDate = new NgbDate(
                timeslot.startTime.year(),
                timeslot.startTime.month() + 1,
                timeslot.startTime.date(),
            );
            this.scrollToTimeslot = timeslot;
        } else {
            this.scrollToTimeslot = null;
        }
        if (this.upgradeForm) {
            this.upgradeForm.controls.timeslot.setValue(this.selectedTimeslots[array.array.applianceId], {
                emitEvent: false,
            });
        }
        this.arraySelection$.next();
    }

    onDateSelection(date: NgbDateStruct): void {
        this.selectedDate = date;
    }

    continue(): void {
        this.onForward.emit({
            timezone: this.upgradeForm.controls.timezone.value,
            schedule: this.arrays.map(arr => ({
                ...arr,
                timeslot: this.selectedTimeslots[arr.array.applianceId],
            })),
        });
    }

    // Creates a markDayDisabled function for use in the ngbDatePicker, so that
    // it can react to timezone changes
    makeMarkDayDisabledFn(timeslots: Timeslot[], timezone: string): MarkDisabledFunction {
        return (currentDay: NgbDateStruct) => {
            const curDay = getDayAsMoment(currentDay, timezone);
            const nextDay = curDay.clone().add(1, 'day');
            return !timeslots.find(cur => cur.startTime.isSameOrAfter(curDay) && cur.startTime.isBefore(nextDay));
        };
    }

    // this function will be overwritten
    makeMarkDayDisabled: MarkDisabledFunction = (date, currentMonth) => {
        return true;
    };

    checkTimeslotsStartEqual(a: Timeslot, b: Timeslot): boolean {
        return a.startTime.isSame(b.startTime);
    }

    canContinue(): boolean {
        return !this.arrays.some(arr => {
            if (arr.numberOfHops > this.maxHops) {
                // Arrays with this many hops will be scheduled in coordination with support
                return false;
            }
            return !this.selectedTimeslots[arr.array.applianceId];
        });
    }

    hasTimeslotNumOfAvailableSuccessors(timeslot: Timeslot, timeslots: Timeslot[]): boolean {
        const numOfHops = this.getDurationFactor();
        if (numOfHops <= 1) {
            return true;
        }
        const numOfIterations = numOfHops - 1;
        let timeslotsChecked = 0;
        for (let i = 0; i < numOfIterations; i++) {
            const nextTimeslotStart = timeslot.startTime.clone().add(moment.duration(i + 1, 'hours'));
            const nextTimeslot = timeslots.find(t => t.startTime.isSame(nextTimeslotStart));
            if (nextTimeslot) {
                if (this.timeSlotHasRemainingCapacity(nextTimeslot)) {
                    timeslotsChecked++;
                }
            }
        }
        return timeslotsChecked === numOfIterations;
    }

    timeSlotHasRemainingCapacity(timeslot: Timeslot): boolean {
        const currentUsage = Object.values(this.selectedTimeslots)
            .filter(t => t)
            .filter(t => t.startTime.isSame(timeslot.startTime)).length;
        return timeslot.capacity > currentUsage;
    }

    getSelectedArrayIndex(): number {
        if (!this.selectedArray) {
            return 0;
        }
        return this.arrays.findIndex(arr => arr.array.applianceId === this.selectedArray.array.applianceId);
    }

    trackByIndex(index: number): number {
        return index;
    }

    trackByArrayId(_: number, item: VersionedArray): string {
        return item.array.applianceId;
    }

    onBackWrapper(): void {
        this.selectedTimeslots = {};
        this.onBack.emit();
    }

    getArrayName = (array: PartialDeep<CduAppliance>): string =>
        getArrayUniqueName(array, this.hasDuplicateApplianceNames);

    onDatepickerNavigate(navigation: NgbDatepickerNavigateEvent | null): void {
        // If the user navigates to a new month, we need to update the timeslots
        this.timeslotsLoading = true;
        const tzNow = this.upgradeForm?.controls.timezone.value;

        // Get the start and end times for the month, if today is after the first day of the month, use today as the start time
        const today = getDayAsMoment(this.calendar.getToday(), tzNow);
        const selectedMonth = navigation?.next || this.datepicker?.state?.firstDate || this.calendar.getToday();
        const firstDayOfTheMonth = getDayAsMoment(
            { year: selectedMonth.year, month: selectedMonth.month, day: 1 },
            tzNow,
        );
        const startTime = today.isAfter(firstDayOfTheMonth) ? today : firstDayOfTheMonth;
        const endTime = firstDayOfTheMonth.clone().add(1, 'months').add(1, 'days');

        this.upgradeService
            .getFreeTimes(startTime, endTime, this.selectedArray.productLine)
            .pipe(
                map(freeTimes => ({
                    timeslots: splitUpSlots(freeTimes, moment.duration(1, 'hours'), TIMESLOT_INTERVAL),
                    timezone: tzNow as string,
                })),
                tap(({ timeslots }) => {
                    this.timeslots = timeslots;
                }),
                // Merge map to allow recalculation of timeslots on selected array change, but to keep selected timeslots
                mergeMap(params => this.arraySelection$.pipe(map(() => params))),
                // Take only relevant slots
                map(({ timeslots, timezone }) => {
                    return this.filterTimeslots(timeslots, timezone);
                }),
                tap(({ timeslots }) => {
                    this.filteredTimeslots = timeslots;
                }),
                map(({ timeslots, timezone }) => {
                    return this.makeMarkDayDisabledFn(timeslots, timezone);
                }),
            )
            .subscribe(makeMarkDayDisabled => {
                this.timeslotsLoading = false;
                this.makeMarkDayDisabled = makeMarkDayDisabled;
            });
    }
}
