import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DisasterRecoveryProtectionGroup } from '../models/disaster-recovery-protection-group';
import { FilterParams } from '../interfaces/list-params';
import { map, Observable, Subject, tap } from 'rxjs';
import {
    DraasApiAggregatedProtectionGroup,
    DraasApiChangeOrderOfVms,
    DraasApiCreateProtectionGroupV2,
    DraasApiToggleGroupProtection,
    DraasApiUpdateProtectionGroup,
} from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryPlan } from '../models/disaster-recovery-plan';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryProtectionGroupsService extends DisasterRecoveryBaseService<
    DisasterRecoveryProtectionGroup,
    DraasApiAggregatedProtectionGroup
> {
    protected resourceClass = DisasterRecoveryProtectionGroup;
    protected pathParams = ['clusterId', 'planId', 'groupId'];

    readonly groupCreated$: Subject<DisasterRecoveryProtectionGroup> = new Subject();
    readonly groupUpdated$: Subject<DisasterRecoveryProtectionGroup> = new Subject();
    readonly groupRemoved$: Subject<string> = new Subject();

    constructor(protected http: HttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryPlan>): string {
        if (filter.planId) {
            return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/recovery-plans/${filter.planId}/protection-groups`;
        } else {
            return filter.groupId
                ? `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/protection-groups/${filter.groupId}`
                : `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/protection-groups`;
        }
    }

    create(clusterId: string, body: DraasApiCreateProtectionGroupV2): Observable<DisasterRecoveryProtectionGroup> {
        return this.doCreate(this.getEndpoint({ clusterId }), body, this.groupCreated$);
    }

    update(
        clusterId: string,
        groupId: string,
        body: any | DraasApiUpdateProtectionGroup,
    ): Observable<DisasterRecoveryProtectionGroup> {
        return this.doUpdate(this.getEndpoint({ clusterId, groupId }), body, this.groupUpdated$);
    }

    delete(clusterId: string, groupId: string): Observable<void> {
        return this.doDelete(this.getEndpoint({ clusterId, groupId }), groupId, this.groupRemoved$);
    }

    changeOrderOfVms(
        clusterId: string,
        groupId: string,
        newOrderOfVms: string[],
        groupVersion: number,
    ): Observable<DisasterRecoveryProtectionGroup> {
        const url = `${this.getEndpoint({ clusterId, groupId })}/vm-order`;
        const body: DraasApiChangeOrderOfVms = {
            new_order_of_vms: newOrderOfVms,
            version: groupVersion,
        };
        return this.http.patch<DraasApiAggregatedProtectionGroup>(url, body).pipe(
            map(response => new DisasterRecoveryProtectionGroup(response)),
            tap(plan => this.groupUpdated$.next(plan)),
        );
    }

    runProtection(clusterId: string, groupId: string): Observable<void> {
        const url = `${this.getEndpoint({ clusterId, groupId })}/run-protection`;
        return this.http.post<void>(url, null);
    }

    toggleProtection(
        clusterId: string,
        groupId: string,
        enable: boolean,
        groupVersion: number,
    ): Observable<DisasterRecoveryProtectionGroup> {
        const url = `${this.getEndpoint({ clusterId, groupId })}/protection`;
        const body: DraasApiToggleGroupProtection = {
            protection_enabled: enable,
            version: groupVersion,
        };
        return this.http
            .patch<DraasApiAggregatedProtectionGroup>(url, body)
            .pipe(map(response => new DisasterRecoveryProtectionGroup(response)));
    }
}
