<form [formGroup]="confirmForm">
    <div class="row">
        <div class="col-xs-12 mb-1">
            Please review your upgrade request for {{ schedule?.schedule.length }} selected appliances
        </div>
    </div>
    <div class="row">
        <div class="col-xs-7">
            <div class="header left-header mb-1">
                <div class="inline-column column-1">Appliances</div>
                <div class="inline-column column-2">Timezone</div>
                <div class="inline-column column-3">Preferred Date Range</div>
            </div>
            <div class="selected-appliances-wrapper">
                <table class="selected-appliances-table">
                    <tbody>
                        <tr *ngFor="let scheduledArray of schedule?.schedule; let i = index">
                            <td class="column-1">
                                <span>{{ scheduledArray.array.hostname }}</span
                                ><br />
                                <span class="version"
                                    >{{ scheduledArray.array.currentVersion }} >
                                    {{ scheduledArray.targetVersion }}</span
                                >
                            </td>
                            <td *ngIf="i === 0" class="rowspan column-2">{{
                                getTimezonePrettyName(schedule?.timezone)
                            }}</td>
                            <td *ngIf="i === 0" class="rowspan column-3">{{
                                getDateRangeFromMoments(schedule?.fromDate, schedule?.toDate)
                            }}</td>
                            <td *ngIf="i === 1" [attr.rowspan]="schedule?.schedule.length - 1" class="column-2"
                                >&nbsp;</td
                            >
                            <td *ngIf="i === 1" [attr.rowspan]="schedule?.schedule.length - 1" class="column-3"
                                >&nbsp;</td
                            >
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-xs-5">
            <div class="row">
                <div class="col-xs-12 mb-1 header">Comments</div>
            </div>
            <div class="row">
                <div class="col-xs-12 mb-1">
                    <textarea class="form-control comment-text-area" type="text" formControlName="comment"> </textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 mb-3">
                    <label class="form-check-label" for="best-practices">
                        <input
                            id="best-practices"
                            class="form-check-input"
                            type="checkbox"
                            formControlName="bestPracticesCheck"
                        />
                        I have read the
                        <a
                            href="https://support.purestorage.com/FlashArray/PurityFA/PurityFA_Upgrades/03_Upgrades:_Support_Process/Purity_Upgrade:_Pre-Upgrade_Host-Side_Issues_and_Caveats"
                            target="_blank"
                        >
                            upgrade best practices
                        </a>
                        <span class="info-icon"
                            ><pure-info-icon
                                tooltipContent="Please review the checklist in our best practice guide to learn of your todo’s before the upgrade starts"
                            ></pure-info-icon
                        ></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="row">
    <div class="col-xs-12">
        <div class="btn-toolbar clearfix">
            <button
                type="button"
                id="continue-button"
                class="btn btn-primary float-xs-right margin"
                (click)="continue()"
                [disabled]="!confirmForm.valid"
            >
                Request Upgrade
            </button>
            <button type="button" class="btn btn-primary float-xs-right margin btn-custom" (click)="onBack.emit()">
                &lt; Back
            </button>
            <button type="button" class="btn btn-info float-xs-right margin btn-custom" (click)="onCancel.emit()">
                Cancel
            </button>
        </div>
    </div>
</div>
