import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
    ExternalUser,
    ExternalUsersService,
    ViewsService,
    ViewReference,
    RoleAssignmentService,
    ResetPasswordService,
    RoleType,
} from '@pure1/data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';

import { BaseAssignmentFormComponent } from '../base-assignment-form/base-assignment-form.component';
import { UserRoleStateService } from '../services/user-role-state.service';
import { ToastService } from '../../services/toast.service';
import { Assignment } from '../user-role-management.interface';

@Component({
    selector: 'external-user-form',
    templateUrl: './external-user-form.component.html',
    host: {
        class: 'base-assignment-form',
    },
})
export class ExternalUserFormComponent extends BaseAssignmentFormComponent implements OnChanges {
    newExternalUser: ExternalUser;

    constructor(
        externalUsersService: ExternalUsersService,
        viewsService: ViewsService,
        roleAssignmentService: RoleAssignmentService,
        resetPasswordService: ResetPasswordService,
        modalService: NgbModal,
        urStateService: UserRoleStateService,
        angulartics2: Angulartics2,
        toast: ToastService,
        mpaRequestsBadgeService: MpaRequestsBadgeService,
        private fb: UntypedFormBuilder,
    ) {
        super(
            viewsService,
            externalUsersService,
            roleAssignmentService,
            urStateService,
            angulartics2,
            resetPasswordService,
            modalService,
            toast,
            mpaRequestsBadgeService,
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateAssignmentObject(this.assignment);

        this.assignmentForm = this.fb.group({
            view: [{ value: this.newExternalUser.view, disabled: true }], // enable view selection once views have been loaded
            email: [
                { value: this.newExternalUser.email, disabled: this.isEdit },
                Validators.compose([Validators.required, Validators.email]),
            ],
        });

        // restore any changes that were in place if we left to add a view
        super.ngOnChanges(changes);

        this.getViews();
    }

    updateAssignmentObject(assignment: Assignment): void {
        if (this.isEdit) {
            this.newExternalUser = assignment as ExternalUser;
        } else {
            this.newExternalUser = new ExternalUser({
                role: RoleType.PURE1_VIEWER,
            });
        }
    }

    makeAssignment(json: any): ExternalUser {
        return new ExternalUser(json);
    }

    copyAssignmentFields(newAssignment: ExternalUser): void {
        newAssignment.name = this.assignmentForm.value.email;
        newAssignment.role = this.RoleType.PURE1_VIEWER;
    }

    updateView(viewReference: ViewReference): void {
        this.newExternalUser.view = viewReference;
    }

    isValidForm(): boolean {
        return this.assignmentForm.valid;
    }
}
