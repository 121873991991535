import moment from 'moment';
import { Resource } from '../interfaces/resource';

export class ApiAppKey implements Resource {
    id: string;
    name: string;
    created_at: moment.Moment;
    created_by: string;
    last_used: moment.Moment;
    max_role: string;
    public_key: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.created_at = json.created_at_ms !== null ? moment(json.created_at_ms) : null;
        this.created_by = json.created_by;
        const lastUsedAsNumber = Number(json.last_used_ms);
        this.last_used = !isNaN(lastUsedAsNumber) && lastUsedAsNumber > 0 ? moment(lastUsedAsNumber) : null;
        this.max_role = json.max_role;
        this.public_key = json.public_key;
    }
}
