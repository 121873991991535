<form [formGroup]="upgradeForm">
    <div class="row" *ngIf="arrays && arrays.length > 0">
        <div class="col-xs-3">
            <div class="row selected-appliances">
                <div class="col-xs-12">
                    <p>Selected appliances ({{ arrays.length }})</p>
                    <ul class="selected-appliances-list">
                        <li *ngFor="let versionedArray of arrays">
                            <span>{{ versionedArray.array.hostname }}</span
                            ><br />
                            <span class="version"
                                >{{ versionedArray.array.currentVersion }} &gt; {{ versionedArray.targetVersion }}</span
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xs-9 date-range-selection px-2">
            <div class="row">
                <p class="col-xs-12"
                    >Select a preferred date range for the upgrade of the {{ arrays.length }} appliances</p
                >
            </div>
            <div class="row">
                <div class="col-xs-5 calendar-wrapper">
                    <select
                        class="btn btn-default-focus btn-sm"
                        formControlName="timezone"
                        angulartics2On="change"
                        analyticsCategory="Action"
                        angularticsAction="Support - Bulk array upgrade - Timezone changed"
                    >
                        <option [value]="timezone" *ngFor="let timezone of tzList; trackBy: trackByIndex">
                            {{ getTimezonePrettyName(timezone) }}
                        </option>
                    </select>
                    <ngb-datepicker
                        navigation="arrows"
                        (dateSelect)="onDateSelection($event)"
                        [dayTemplate]="t"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                    ></ngb-datepicker>
                    <ng-template
                        #t
                        let-date
                        let-currentMonth="currentMonth"
                        let-disabled="disabled"
                        let-focused="focused"
                    >
                        <span
                            class="custom-day"
                            [class.focused]="focused"
                            [class.range]="isRange(date)"
                            [class.only]="isOnly(date)"
                            [class.start]="isStart(date)"
                            [class.end]="isEnd(date)"
                            [class.outside]="date.month !== currentMonth"
                            [class.disabled]="disabled"
                            (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null"
                        >
                            {{ date.day }}
                        </span>
                    </ng-template>
                </div>
                <div class="col-xs-7">
                    <div class="alert alert-info" role="alert">
                        Upgrade request for <strong>7 or more</strong> arrays requires additional planning. Select a
                        preferred date range (up to 14 days) for the upgrade. We will review your request and follow up
                        with you.
                    </div>
                    <p
                        >Preferred Start Date:
                        <ng-container
                            *ngIf="fromDate; then prettyDate; else warning"
                            [ngTemplateOutlet]="$any(fromDate)"
                        ></ng-container
                    ></p>
                    <p
                        >Preferred End Date:
                        <ng-container
                            *ngIf="toDate; then prettyDate; else warning"
                            [ngTemplateOutlet]="$any(toDate)"
                        ></ng-container
                    ></p>
                    <ng-template #prettyDate let-date
                        ><strong>{{ printPrettyDate(date) }}</strong></ng-template
                    >
                    <ng-template #warning><em>Select date from calendar</em></ng-template>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="btn-toolbar clearfix">
    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        (click)="continue()"
        [disabled]="!fromDate || !toDate"
    >
        Confirm &gt;
    </button>
    <button type="button" class="btn btn-primary float-xs-right margin" (click)="onBack.emit()"> &lt; Back </button>
    <button type="button" class="btn btn-info float-xs-right margin" (click)="onCancel.emit()"> Cancel </button>
</div>
