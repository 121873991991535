// Generated from PureXPath.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002!\u00c1\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004",
    "\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t",
    "\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017",
    "\t\u0017\u0004\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a",
    "\u0004\u001b\t\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e",
    "\t\u001e\u0004\u001f\t\u001f\u0004 \t \u0004!\t!\u0004\"\t\"\u0004#",
    "\t#\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0005\u0006W\n\u0006\u0005",
    "\u0006Y\n\u0006\u0003\u0006\u0003\u0006\u0005\u0006]\n\u0006\u0003\u0007",
    "\u0006\u0007`\n\u0007\r\u0007\u000e\u0007a\u0003\b\u0003\b\u0003\t\u0003",
    "\t\u0003\t\u0003\n\u0003\n\u0003\u000b\u0003\u000b\u0003\f\u0003\f\u0003",
    "\r\u0003\r\u0003\u000e\u0003\u000e\u0003\u000f\u0003\u000f\u0003\u0010",
    "\u0003\u0010\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003\u0012",
    "\u0003\u0013\u0003\u0013\u0003\u0014\u0003\u0014\u0003\u0015\u0003\u0015",
    "\u0003\u0016\u0003\u0016\u0003\u0017\u0003\u0017\u0003\u0018\u0003\u0018",
    "\u0003\u0018\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u001a\u0003\u001a",
    "\u0003\u001b\u0003\u001b\u0003\u001b\u0003\u001c\u0003\u001c\u0003\u001d",
    "\u0003\u001d\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0005\u001e",
    "\u0099\n\u001e\u0003\u001f\u0003\u001f\u0007\u001f\u009d\n\u001f\f\u001f",
    "\u000e\u001f\u00a0\u000b\u001f\u0003\u001f\u0003\u001f\u0003\u001f\u0003",
    "\u001f\u0007\u001f\u00a6\n\u001f\f\u001f\u000e\u001f\u00a9\u000b\u001f",
    "\u0003\u001f\u0005\u001f\u00ac\n\u001f\u0003 \u0006 \u00af\n \r \u000e",
    " \u00b0\u0003 \u0003 \u0003!\u0003!\u0007!\u00b7\n!\f!\u000e!\u00ba",
    "\u000b!\u0003\"\u0003\"\u0003#\u0003#\u0005#\u00c0\n#\u0003\u00a7\u0002",
    "$\u0003\u0003\u0005\u0004\u0007\u0005\t\u0006\u000b\u0007\r\u0002\u000f",
    "\b\u0011\t\u0013\n\u0015\u000b\u0017\f\u0019\r\u001b\u000e\u001d\u000f",
    "\u001f\u0010!\u0011#\u0012%\u0013\'\u0014)\u0015+\u0016-\u0017/\u0018",
    "1\u00193\u001a5\u001b7\u001c9\u001d;\u001e=\u001f? A!C\u0002E\u0002",
    "\u0003\u0002\u0006\u0003\u0002$$\u0005\u0002\u000b\f\u000f\u000f\"\"",
    "\u0010\u0002C\\aac|\u00c2\u00d8\u00da\u00f8\u00fa\u0301\u0372\u037f",
    "\u0381\u2001\u200e\u200f\u2072\u2191\u2c02\u2ff1\u3003\ud801\uf902\ufdd1",
    "\ufdf2\uffff\u0007\u0002//2;\u00b9\u00b9\u0302\u0371\u2041\u2042\u0002",
    "\u00c9\u0002\u0003\u0003\u0002\u0002\u0002\u0002\u0005\u0003\u0002\u0002",
    "\u0002\u0002\u0007\u0003\u0002\u0002\u0002\u0002\t\u0003\u0002\u0002",
    "\u0002\u0002\u000b\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002",
    "\u0002\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002",
    "\u0002\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002",
    "\u0002\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002\u0002",
    "\u0002\u0002\u001d\u0003\u0002\u0002\u0002\u0002\u001f\u0003\u0002\u0002",
    "\u0002\u0002!\u0003\u0002\u0002\u0002\u0002#\u0003\u0002\u0002\u0002",
    "\u0002%\u0003\u0002\u0002\u0002\u0002\'\u0003\u0002\u0002\u0002\u0002",
    ")\u0003\u0002\u0002\u0002\u0002+\u0003\u0002\u0002\u0002\u0002-\u0003",
    "\u0002\u0002\u0002\u0002/\u0003\u0002\u0002\u0002\u00021\u0003\u0002",
    "\u0002\u0002\u00023\u0003\u0002\u0002\u0002\u00025\u0003\u0002\u0002",
    "\u0002\u00027\u0003\u0002\u0002\u0002\u00029\u0003\u0002\u0002\u0002",
    "\u0002;\u0003\u0002\u0002\u0002\u0002=\u0003\u0002\u0002\u0002\u0002",
    "?\u0003\u0002\u0002\u0002\u0002A\u0003\u0002\u0002\u0002\u0003G\u0003",
    "\u0002\u0002\u0002\u0005J\u0003\u0002\u0002\u0002\u0007N\u0003\u0002",
    "\u0002\u0002\tP\u0003\u0002\u0002\u0002\u000b\\\u0003\u0002\u0002\u0002",
    "\r_\u0003\u0002\u0002\u0002\u000fc\u0003\u0002\u0002\u0002\u0011e\u0003",
    "\u0002\u0002\u0002\u0013h\u0003\u0002\u0002\u0002\u0015j\u0003\u0002",
    "\u0002\u0002\u0017l\u0003\u0002\u0002\u0002\u0019n\u0003\u0002\u0002",
    "\u0002\u001bp\u0003\u0002\u0002\u0002\u001dr\u0003\u0002\u0002\u0002",
    "\u001ft\u0003\u0002\u0002\u0002!v\u0003\u0002\u0002\u0002#x\u0003\u0002",
    "\u0002\u0002%{\u0003\u0002\u0002\u0002\'}\u0003\u0002\u0002\u0002)\u007f",
    "\u0003\u0002\u0002\u0002+\u0081\u0003\u0002\u0002\u0002-\u0083\u0003",
    "\u0002\u0002\u0002/\u0085\u0003\u0002\u0002\u00021\u0088\u0003\u0002",
    "\u0002\u00023\u008b\u0003\u0002\u0002\u00025\u008d\u0003\u0002\u0002",
    "\u00027\u0090\u0003\u0002\u0002\u00029\u0092\u0003\u0002\u0002\u0002",
    ";\u0098\u0003\u0002\u0002\u0002=\u00ab\u0003\u0002\u0002\u0002?\u00ae",
    "\u0003\u0002\u0002\u0002A\u00b4\u0003\u0002\u0002\u0002C\u00bb\u0003",
    "\u0002\u0002\u0002E\u00bf\u0003\u0002\u0002\u0002GH\u0007q\u0002\u0002",
    "HI\u0007t\u0002\u0002I\u0004\u0003\u0002\u0002\u0002JK\u0007c\u0002",
    "\u0002KL\u0007p\u0002\u0002LM\u0007f\u0002\u0002M\u0006\u0003\u0002",
    "\u0002\u0002NO\u0007?\u0002\u0002O\b\u0003\u0002\u0002\u0002PQ\u0007",
    "#\u0002\u0002QR\u0007?\u0002\u0002R\n\u0003\u0002\u0002\u0002SX\u0005",
    "\r\u0007\u0002TV\u00070\u0002\u0002UW\u0005\r\u0007\u0002VU\u0003\u0002",
    "\u0002\u0002VW\u0003\u0002\u0002\u0002WY\u0003\u0002\u0002\u0002XT\u0003",
    "\u0002\u0002\u0002XY\u0003\u0002\u0002\u0002Y]\u0003\u0002\u0002\u0002",
    "Z[\u00070\u0002\u0002[]\u0005\r\u0007\u0002\\S\u0003\u0002\u0002\u0002",
    "\\Z\u0003\u0002\u0002\u0002]\f\u0003\u0002\u0002\u0002^`\u00042;\u0002",
    "_^\u0003\u0002\u0002\u0002`a\u0003\u0002\u0002\u0002a_\u0003\u0002\u0002",
    "\u0002ab\u0003\u0002\u0002\u0002b\u000e\u0003\u0002\u0002\u0002cd\u0007",
    "1\u0002\u0002d\u0010\u0003\u0002\u0002\u0002ef\u00071\u0002\u0002fg",
    "\u00071\u0002\u0002g\u0012\u0003\u0002\u0002\u0002hi\u0007*\u0002\u0002",
    "i\u0014\u0003\u0002\u0002\u0002jk\u0007+\u0002\u0002k\u0016\u0003\u0002",
    "\u0002\u0002lm\u0007]\u0002\u0002m\u0018\u0003\u0002\u0002\u0002no\u0007",
    "_\u0002\u0002o\u001a\u0003\u0002\u0002\u0002pq\u0007/\u0002\u0002q\u001c",
    "\u0003\u0002\u0002\u0002rs\u0007-\u0002\u0002s\u001e\u0003\u0002\u0002",
    "\u0002tu\u00070\u0002\u0002u \u0003\u0002\u0002\u0002vw\u0007,\u0002",
    "\u0002w\"\u0003\u0002\u0002\u0002xy\u00070\u0002\u0002yz\u00070\u0002",
    "\u0002z$\u0003\u0002\u0002\u0002{|\u0007B\u0002\u0002|&\u0003\u0002",
    "\u0002\u0002}~\u0007.\u0002\u0002~(\u0003\u0002\u0002\u0002\u007f\u0080",
    "\u0007~\u0002\u0002\u0080*\u0003\u0002\u0002\u0002\u0081\u0082\u0007",
    ">\u0002\u0002\u0082,\u0003\u0002\u0002\u0002\u0083\u0084\u0007@\u0002",
    "\u0002\u0084.\u0003\u0002\u0002\u0002\u0085\u0086\u0007>\u0002\u0002",
    "\u0086\u0087\u0007?\u0002\u0002\u00870\u0003\u0002\u0002\u0002\u0088",
    "\u0089\u0007@\u0002\u0002\u0089\u008a\u0007?\u0002\u0002\u008a2\u0003",
    "\u0002\u0002\u0002\u008b\u008c\u0007<\u0002\u0002\u008c4\u0003\u0002",
    "\u0002\u0002\u008d\u008e\u0007<\u0002\u0002\u008e\u008f\u0007<\u0002",
    "\u0002\u008f6\u0003\u0002\u0002\u0002\u0090\u0091\u0007)\u0002\u0002",
    "\u00918\u0003\u0002\u0002\u0002\u0092\u0093\u0007$\u0002\u0002\u0093",
    ":\u0003\u0002\u0002\u0002\u0094\u0095\u0007^\u0002\u0002\u0095\u0099",
    "\u0007^\u0002\u0002\u0096\u0097\u0007^\u0002\u0002\u0097\u0099\u0007",
    ")\u0002\u0002\u0098\u0094\u0003\u0002\u0002\u0002\u0098\u0096\u0003",
    "\u0002\u0002\u0002\u0099<\u0003\u0002\u0002\u0002\u009a\u009e\u0007",
    "$\u0002\u0002\u009b\u009d\n\u0002\u0002\u0002\u009c\u009b\u0003\u0002",
    "\u0002\u0002\u009d\u00a0\u0003\u0002\u0002\u0002\u009e\u009c\u0003\u0002",
    "\u0002\u0002\u009e\u009f\u0003\u0002\u0002\u0002\u009f\u00a1\u0003\u0002",
    "\u0002\u0002\u00a0\u009e\u0003\u0002\u0002\u0002\u00a1\u00ac\u0007$",
    "\u0002\u0002\u00a2\u00a7\u0007)\u0002\u0002\u00a3\u00a6\u0005;\u001e",
    "\u0002\u00a4\u00a6\u000b\u0002\u0002\u0002\u00a5\u00a3\u0003\u0002\u0002",
    "\u0002\u00a5\u00a4\u0003\u0002\u0002\u0002\u00a6\u00a9\u0003\u0002\u0002",
    "\u0002\u00a7\u00a8\u0003\u0002\u0002\u0002\u00a7\u00a5\u0003\u0002\u0002",
    "\u0002\u00a8\u00aa\u0003\u0002\u0002\u0002\u00a9\u00a7\u0003\u0002\u0002",
    "\u0002\u00aa\u00ac\u0007)\u0002\u0002\u00ab\u009a\u0003\u0002\u0002",
    "\u0002\u00ab\u00a2\u0003\u0002\u0002\u0002\u00ac>\u0003\u0002\u0002",
    "\u0002\u00ad\u00af\t\u0003\u0002\u0002\u00ae\u00ad\u0003\u0002\u0002",
    "\u0002\u00af\u00b0\u0003\u0002\u0002\u0002\u00b0\u00ae\u0003\u0002\u0002",
    "\u0002\u00b0\u00b1\u0003\u0002\u0002\u0002\u00b1\u00b2\u0003\u0002\u0002",
    "\u0002\u00b2\u00b3\b \u0002\u0002\u00b3@\u0003\u0002\u0002\u0002\u00b4",
    "\u00b8\u0005C\"\u0002\u00b5\u00b7\u0005E#\u0002\u00b6\u00b5\u0003\u0002",
    "\u0002\u0002\u00b7\u00ba\u0003\u0002\u0002\u0002\u00b8\u00b6\u0003\u0002",
    "\u0002\u0002\u00b8\u00b9\u0003\u0002\u0002\u0002\u00b9B\u0003\u0002",
    "\u0002\u0002\u00ba\u00b8\u0003\u0002\u0002\u0002\u00bb\u00bc\t\u0004",
    "\u0002\u0002\u00bcD\u0003\u0002\u0002\u0002\u00bd\u00c0\u0005C\"\u0002",
    "\u00be\u00c0\t\u0005\u0002\u0002\u00bf\u00bd\u0003\u0002\u0002\u0002",
    "\u00bf\u00be\u0003\u0002\u0002\u0002\u00c0F\u0003\u0002\u0002\u0002",
    "\u000f\u0002VX\\a\u0098\u009e\u00a5\u00a7\u00ab\u00b0\u00b8\u00bf\u0003",
    "\b\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function PureXPathLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

PureXPathLexer.prototype = Object.create(antlr4.Lexer.prototype);
PureXPathLexer.prototype.constructor = PureXPathLexer;

Object.defineProperty(PureXPathLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

PureXPathLexer.EOF = antlr4.Token.EOF;
PureXPathLexer.T__0 = 1;
PureXPathLexer.T__1 = 2;
PureXPathLexer.T__2 = 3;
PureXPathLexer.T__3 = 4;
PureXPathLexer.Number = 5;
PureXPathLexer.PATHSEP = 6;
PureXPathLexer.ABRPATH = 7;
PureXPathLexer.LPAR = 8;
PureXPathLexer.RPAR = 9;
PureXPathLexer.LBRAC = 10;
PureXPathLexer.RBRAC = 11;
PureXPathLexer.MINUS = 12;
PureXPathLexer.PLUS = 13;
PureXPathLexer.DOT = 14;
PureXPathLexer.MUL = 15;
PureXPathLexer.DOTDOT = 16;
PureXPathLexer.AT = 17;
PureXPathLexer.COMMA = 18;
PureXPathLexer.PIPE = 19;
PureXPathLexer.LT = 20;
PureXPathLexer.GT = 21;
PureXPathLexer.LE = 22;
PureXPathLexer.GE = 23;
PureXPathLexer.COLON = 24;
PureXPathLexer.CC = 25;
PureXPathLexer.APOS = 26;
PureXPathLexer.QUOT = 27;
PureXPathLexer.ESC_APOS = 28;
PureXPathLexer.Literal = 29;
PureXPathLexer.Whitespace = 30;
PureXPathLexer.NCName = 31;

PureXPathLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

PureXPathLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

PureXPathLexer.prototype.literalNames = [ null, "'or'", "'and'", "'='", 
                                          "'!='", null, "'/'", "'//'", "'('", 
                                          "')'", "'['", "']'", "'-'", "'+'", 
                                          "'.'", "'*'", "'..'", "'@'", "','", 
                                          "'|'", "'<'", "'>'", "'<='", "'>='", 
                                          "':'", "'::'", "'''", "'\"'" ];

PureXPathLexer.prototype.symbolicNames = [ null, null, null, null, null, 
                                           "Number", "PATHSEP", "ABRPATH", 
                                           "LPAR", "RPAR", "LBRAC", "RBRAC", 
                                           "MINUS", "PLUS", "DOT", "MUL", 
                                           "DOTDOT", "AT", "COMMA", "PIPE", 
                                           "LT", "GT", "LE", "GE", "COLON", 
                                           "CC", "APOS", "QUOT", "ESC_APOS", 
                                           "Literal", "Whitespace", "NCName" ];

PureXPathLexer.prototype.ruleNames = [ "T__0", "T__1", "T__2", "T__3", "Number", 
                                       "Digits", "PATHSEP", "ABRPATH", "LPAR", 
                                       "RPAR", "LBRAC", "RBRAC", "MINUS", 
                                       "PLUS", "DOT", "MUL", "DOTDOT", "AT", 
                                       "COMMA", "PIPE", "LT", "GT", "LE", 
                                       "GE", "COLON", "CC", "APOS", "QUOT", 
                                       "ESC_APOS", "Literal", "Whitespace", 
                                       "NCName", "NCNameStartChar", "NCNameChar" ];

PureXPathLexer.prototype.grammarFileName = "PureXPath.g4";



exports.PureXPathLexer = PureXPathLexer;

