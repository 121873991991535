<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Deregister Application </h3>
</div>

<div class="modal-body">
    Are you sure you want to deregister <span class="app-name">{{ apiAppKey.name }}</span
    >? This action cannot be undone.
</div>

<div class="modal-footer btn-toolbar">
    <button type="button" class="btn btn-info cancel-button" (click)="modal.dismiss()"> Cancel </button>

    <button
        type="button"
        class="btn btn-primary delete-api-key-button"
        (click)="deleteApiKey()"
        [disabled]="('PURE1:write:apikey' | isNotAllowed) || loading"
    >
        <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
        Delete
    </button>
</div>
