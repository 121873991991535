import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PartialDeep } from 'lodash';
import moment from 'moment-timezone';
import { CduAppliance } from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.interface';
import { getArrayUniqueName } from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.utils';
import { Schedule, ScheduledArray } from '../../types';
import { ProductLine } from '../../../support.interface';

@Component({
    selector: 'upgrade-confirmation',
    templateUrl: 'upgrade-confirmation.component.html',
})
export class UpgradeConfirmationComponent implements OnInit {
    @Input() readonly maxHops: number;
    @Input() readonly schedule: Schedule;
    @Input() readonly timeslotsChecking: boolean;
    @Input() readonly timeslotsConflictFlag: boolean;
    @Input() readonly safeModeAutoOnFlags: { [key: string]: boolean };
    @Input() readonly showSafeModeAutoOnColumn: boolean;
    @Input() readonly hasSubmitPermission: boolean;
    @Input() readonly hasDuplicateApplianceNames: boolean;

    @Output() readonly onBack: EventEmitter<any> = new EventEmitter();
    @Output() readonly onCancel: EventEmitter<any> = new EventEmitter();
    @Output() readonly onForward: EventEmitter<any> = new EventEmitter();
    @Output() readonly toggleSafeModeAutoOn: EventEmitter<any> = new EventEmitter();

    confirmForm: UntypedFormGroup;
    userHasClickedOnLink = false;
    clickOnLinkTooltip =
        'Before you can confirm your request, it is critical to ensure that you have read through our Best Practices and Caveats.';
    confirmSafeModeOptOut = false;

    get submitDisabled(): boolean {
        return (
            !this.userHasClickedOnLink ||
            !this.confirmForm.valid ||
            !this.hasSubmitPermission ||
            this.timeslotsChecking ||
            (this.showSafeModeAutoOnWarning() && !this.confirmSafeModeOptOut)
        );
    }

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.confirmForm = this.fb.group({
            comment: '',
            bestPracticesCheck: [{ value: false, disabled: true }, Validators.requiredTrue],
        });
    }

    enableCheckbox(): void {
        this.confirmForm.get('bestPracticesCheck').enable();
        this.userHasClickedOnLink = true;
    }

    continue(): void {
        const { bestPracticesCheck, comment } = this.confirmForm.value;
        if (bestPracticesCheck) {
            this.onForward.emit(comment);
        }
    }

    getTimezonePrettyName(timezone: string): string {
        if (!timezone) {
            return '';
        }
        const raw = timezone.split('/');
        const city = raw[raw.length - 1].replace('_', ' ');
        return city + ' (UTC' + moment.tz(timezone).format('Z') + ')';
    }

    getDateFromMoment(start: moment.Moment): string {
        if (start) {
            return start.format('MM-DD-YYYY');
        } else {
            return '';
        }
    }

    getTimeAndDurationFromMoments(start: moment.Moment, duration: moment.Duration): string {
        let output = '';
        if (start) {
            output += start.format('HH:mm');
        }
        if (duration) {
            const hrs = duration.as('hours');
            const hrsuffix = hrs === 1 ? 'hr' : 'hrs';
            output += ' (' + hrs + ' ' + hrsuffix + ')';
        }
        return output;
    }

    trackByArrayId(index: number, item: ScheduledArray): string {
        return item.array.applianceId;
    }

    showSafeModeAutoOnWarning(): boolean {
        if (!this.showSafeModeAutoOnColumn || !this.schedule?.schedule) {
            return false;
        }
        return this.schedule.schedule.some(a => this.safeModeAutoOnFlags[a.array.applianceId] === false);
    }

    getArrayName = (array: PartialDeep<CduAppliance>): string =>
        getArrayUniqueName(array, this.hasDuplicateApplianceNames);

    itContainsFAAppliances(): boolean {
        return this.schedule?.schedule.some(a => a.productLine === ProductLine.FlashArray);
    }

    itContainsFBAppliances(): boolean {
        return this.schedule?.schedule.some(a => a.productLine === ProductLine.FlashBlade);
    }
}
