import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepUpError, StepUpErrorType, StepUpFactor, StepUpVerifyResponse } from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { CachedStepUpService } from '../../data/services/cached-step-up.service';
import { StepUpSupportService } from '../services/step-up-support.service';

@Component({
    selector: 'totp-activation',
    templateUrl: 'totp-activation.component.html',
})
export class TotpActivationComponent implements OnInit {
    @Input() readonly verifyResponse: StepUpVerifyResponse;

    @Output() readonly activateSuccess = new EventEmitter<void>();
    @Output() readonly activateCancel = new EventEmitter<void>();

    totpFactor: StepUpFactor;
    enrollErrorMessage: string;
    activateErrorMessage: string;
    passcodeInputValue: string;

    constructor(
        private angulartics: Angulartics2,
        private cachedStepUpService: CachedStepUpService,
        private stepUpSupport: StepUpSupportService,
    ) {}

    ngOnInit(): void {
        this.cachedStepUpService.stepUpEnrollFactorV2('totp', null, this.verifyResponse.accessToken).subscribe({
            next: totpFactor => {
                this.totpFactor = totpFactor;
            },
            error: (error: StepUpError) => {
                console.warn(`error enrolling factor: ${error.errorDescription}`);
                this.enrollErrorMessage = error.errorDescription;
            },
        });
    }

    submitDisabled(): boolean {
        return this.passcodeInputValue == null; // invalid value, see TotpPasscodeInputComponent
    }

    activate(): void {
        this.cachedStepUpService.stepUpActivateV2(this.passcodeInputValue, this.totpFactor.id).subscribe({
            next: _ => {
                this.angulartics.eventTrack.next({
                    action: 'Step Up - Activation Modal - passcode verified successfully',
                    properties: { category: 'Action' },
                });
                this.activateSuccess.emit();
            },
            error: (error: StepUpError) => this.handleStepUpError(error),
        });
    }

    goToSupport(): void {
        this.stepUpSupport.openStepUpActivationHelpCase();
        this.activateCancel.emit();
    }

    close(): void {
        this.activateCancel.emit();
    }

    inputValueChanged(value: string): void {
        this.passcodeInputValue = value;
    }

    private handleStepUpError(error: StepUpError) {
        this.activateErrorMessage = error.errorDescription;

        if (error.error === 'locked_out') {
            this.angulartics.eventTrack.next({
                action: 'Step Up - Activation Modal - passcode lockout',
                properties: { category: 'Action' },
            });
        }
        this.trackPassCodeError(error.error);
    }

    private trackPassCodeError(stepUpError: StepUpErrorType): void {
        this.angulartics.eventTrack.next({
            action: 'Step Up - Activation Modal - challenge error',
            properties: { category: 'Action', label: stepUpError },
        });
    }
}
