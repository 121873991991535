import {
    Component,
    Input,
    EventEmitter,
    Output,
    Renderer2,
    ElementRef,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Inject,
    HostListener,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { WINDOW } from '../../../app/injection-tokens';

@Component({
    selector: 'pure-expandable-dropdown',
    templateUrl: 'expandable-dropdown.component.html',
})
export class ExpandableDropdownComponent implements OnDestroy, OnChanges {
    @Input() items: ExpandableDropdownItem[];
    @Input() selectedValue: string = null;
    @Input() emptyText = '';
    @Input() label = '';
    @Input() showItemsText = '';
    @Input() hideItemsText = '';
    @Input() displayedItemsText = '';
    @Input() noDisplayedItemsText = '';
    @Input() isDisabled = false;
    @Input() insideModal = false;
    @Input() shouldBoldRecommended = false;
    @Input() testId: string = null;

    @Output() readonly selectionChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('selectionList') selectionList: ElementRef;
    // hide dropdown on click away
    @HostListener('document:click')
    private documentClickHandler(): void {
        this.isSelectionsExpanded = false;
    }

    isSelectionsExpanded = false;
    hiddenItems: ExpandableDropdownItem[] = [];
    visibleItems: ExpandableDropdownItem[] = [];
    currentDisplayText: string;
    currentIcon?: string;
    currentIconTooltip?: string;
    hasSelectedValue = false;

    hiddenItemsVisible = false;

    private endSelectionExpandFn = null;

    constructor(
        @Inject(WINDOW) private window: Window,
        private el: ElementRef,
        private cdRef: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {}

    ngOnDestroy(): void {
        if (this.endSelectionExpandFn) {
            this.endSelectionExpandFn();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.hiddenItems = this.items.filter(i => i.hidden);
            this.visibleItems = this.items.filter(i => !i.hidden);
        }

        if (changes.selectedValue || changes.items) {
            const currentItem = this.items.find(i => i.value === this.selectedValue);
            this.currentDisplayText = currentItem ? currentItem.displayText : this.emptyText;
            this.currentIcon = currentItem?.icon;
            this.currentIconTooltip = currentItem?.iconTooltip;
            this.hasSelectedValue = this.items.map(i => i.value).includes(this.selectedValue);
        }
    }

    clickItem(item: ExpandableDropdownItem): void {
        this.selectionChange.emit(item.value);
        this.hiddenItemsVisible = false;
        this.isSelectionsExpanded = false;
    }

    toggleVisibleItems(event: Event): void {
        this.hiddenItemsVisible = !this.hiddenItemsVisible;
        this.isSelectionsExpanded = true;
        event.stopPropagation();
    }

    getCurrentItemDisplayText(): string {
        const currentItem = this.items.find(i => i.value === this.selectedValue);
        return currentItem ? currentItem.displayText : this.emptyText;
    }

    toggleSelections(event: Event): void {
        if (this.items.length === 0) {
            return;
        }

        this.isSelectionsExpanded = !this.isSelectionsExpanded;
        if (this.isSelectionsExpanded) {
            this.endSelectionExpandFn = this.renderer.listen('window', 'click', (event: MouseEvent) => {
                if (!this.el.nativeElement.contains(event.target)) {
                    this.isSelectionsExpanded = false;
                    this.endSelectionExpandFn();
                    this.endSelectionExpandFn = null;
                }
            });
        } else {
            if (this.endSelectionExpandFn) {
                this.endSelectionExpandFn();
                this.endSelectionExpandFn = null;
            }
        }

        if (this.insideModal && this.isSelectionsExpanded) {
            this.cdRef.detectChanges();
            const modalContent = $('.modal-content').first();

            if (modalContent) {
                const modalContentRect = modalContent.offset();
                if (modalContentRect) {
                    const buttonRect = this.el.nativeElement.getBoundingClientRect();
                    const modalContentTop = modalContentRect.top + this.window.scrollY;
                    const modalContentLeft = modalContentRect.left + this.window.scrollX;

                    const ul = this.selectionList.nativeElement;
                    ul.style.position = 'fixed';
                    ul.style.top = buttonRect.top - modalContentTop + 26 + 'px';
                    ul.style.left = buttonRect.left - modalContentLeft + 'px';
                }
            }
        }
        event.stopPropagation();
    }
}

export type ExpandableDropdownItem = {
    value: string;
    displayText: string;
    hidden: boolean;
    icon?: string;
    iconTooltip?: string;
};
