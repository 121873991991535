import { User } from '../models/user';

export class CurrentUser extends User {
    readOnly: boolean;
    caOnly: boolean;
    adUser: boolean;
    isAlternateLogin: boolean;
    oktaConnection: boolean;
    organization_id: number | null;
    organizationName: string;
    isPure: boolean;
    isPartner: boolean;
    impersonating: boolean;
    accountName: string;
    accountId: string;
    originalContactId: string;
    originalUserEmail: string;

    constructor(json: any) {
        super(json);
        this.readOnly = json.readOnly;
        this.caOnly = json.caOnly;
        this.adUser = json.adUser;
        this.oktaConnection = json.oktaConnection;
        this.isAlternateLogin = json.connection && json.connection === 'email';
        this.organization_id = json.organization_id;
        this.organizationName = json.organizationName;
        this.isPure = json.isPure;
        this.isPartner = json.isPartner;
        this.impersonating = json.impersonating;
        this.accountName = json.accountName;
        this.accountId = json.accountId;
        this.originalContactId = json.originalContactId;
        this.originalUserEmail = json.originalUserEmail;
    }
}
