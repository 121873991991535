import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Injectable({ providedIn: 'root' })
export class Pure4sightOnboardingService {
    modalVisible$ = new BehaviorSubject<boolean>(false);

    private DO_NOT_SHOW_PURE4SIGHT_ONBOARDING_MODAL = 'do-not-show-pure4sight-onboarding-modal';

    constructor(private storageService: StorageService) {}

    ackOnboardingModal(): void {
        this.hideOnboardingModal();
        this.storageService.set(this.DO_NOT_SHOW_PURE4SIGHT_ONBOARDING_MODAL, true);
    }

    shouldShowOnboardingModal(): boolean {
        return !this.storageService.get(this.DO_NOT_SHOW_PURE4SIGHT_ONBOARDING_MODAL);
    }

    showOnboardingModal(): void {
        this.modalVisible$.next(true);
    }

    hideOnboardingModal(): void {
        this.modalVisible$.next(false);
    }
}
