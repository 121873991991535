import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ArrayWithAgents } from '../models/array-with-agents';
import { EdgeServiceAgentVersionWithPermissions } from '../models/edge-service-agent-version-with-permissions';
import { GenericService } from './generic.service';

export interface AgentDeployParam {
    arrayId: string;
    agentName: string;
    agentVersion: string;
}

export interface AgentDeployRequest {
    twoFactorAuthToken: string;
    agentDeployParams: AgentDeployParam[];
}

export interface AgentUpdateRequest {
    twoFactorAuthToken: string;
    arrayId: string;
}

export interface AgentUninstallRequest {
    twoFactorAuthToken: string;
    agentName: string;
    arrayId: string;
}

export interface AgentPermissionRequest {
    agentNames: string[];
    arrayIds: string[];
}

@Injectable({ providedIn: 'root' })
export class EdgeServiceInstallAgentService extends GenericService<ArrayWithAgents> {
    private static readonly baseEndPoint = '/rest/v1/ems/agents/agent-installation';

    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArrayWithAgents,
            endpoint: EdgeServiceInstallAgentService.baseEndPoint,
            defaultParams: {},
        }); // create, update, delete are assumed to be non-supported
    }

    /**
     * get permission list for an agent of some specific versions
     */
    getAgentVersionPermissions(
        agentPermissionRequest: AgentPermissionRequest,
    ): Observable<EdgeServiceAgentVersionWithPermissions[]> {
        return this.http.post<EdgeServiceAgentVersionWithPermissions[]>(
            EdgeServiceInstallAgentService.baseEndPoint + '/agent-permissions',
            agentPermissionRequest,
        );
    }

    /**
     * install agent for a list of array
     */
    installAgent(requestParam: AgentDeployRequest): Observable<void> {
        return this.http.post<void>(EdgeServiceInstallAgentService.baseEndPoint + '/install', requestParam);
    }

    /**
     * To update all eligible agents of an array
     */
    updateAgent(requestParam: AgentUpdateRequest | string[]): Observable<void> {
        return this.http.post<void>(EdgeServiceInstallAgentService.baseEndPoint + '/update', requestParam);
    }

    /**
     * To uninstall an agent from an array
     */
    uninstallAgent(requestParam: AgentUninstallRequest): Observable<void> {
        return this.http.post<void>(EdgeServiceInstallAgentService.baseEndPoint + '/uninstall', requestParam);
    }
}
