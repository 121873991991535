import _ from 'lodash';
import { InputStream, CommonTokenStream } from 'antlr4/index';
import { ParseTreeWalker } from 'antlr4/tree';
import { PureXPathLexer, PureXPathParser } from '../../../../resources/filters';

import { ViewFilterListener, MultiValueFilter } from './ViewFilterListener';
import { GLOBAL_ENTITY, DEFAULT_NAMESPACE, ARRAY_NAME_KEY } from '../../redux/actions';
import { createFilter } from '../../redux/utils';
import { IStateFilter } from '../../redux/pure-redux.service';

export function getSimpleFilterString(selector: string): string {
    const simpleFilter = getSimpleFilter(selector);
    if (simpleFilter.operator !== 'tags' && simpleFilter.key === ARRAY_NAME_KEY) {
        simpleFilter.key = 'Appliance';
    } else {
        simpleFilter.key = _.startCase(simpleFilter.key); // capitalize every word
    }
    return `${simpleFilter.key}: ${simpleFilter.values.join(', ')}`;
}

export function getSimpleFilter(selector: string): MultiValueFilter {
    const filters = getMultiValueFilters(selector);
    if (filters.length !== 1) {
        throw new Error('selector is not a Simple Filter');
    }
    return filters[0];
}

/**
 * Converts a view's selector string to an array of IStateFilter
 * @param selector
 * @param useAstroMonkeySyntax : where to use astromonkey syntax for filters
 */
export function getFilters(selector: string, useAstroMonkeySyntax = true): IStateFilter[] {
    const stateFilters: IStateFilter[] = getMultiValueFilters(selector, useAstroMonkeySyntax)
        .flatMap(multiValueFilter =>
            multiValueFilter.values.map(value => ({
                operator: multiValueFilter.operator,
                key: multiValueFilter.key,
                value,
            })),
        )
        .filter(filter => filter.operator !== 'not equals')
        .map(filter =>
            createFilter(
                GLOBAL_ENTITY,
                // We have lost the tag namespace by the time we write the tags() operator
                // We assume that all user-defined tags have the 'pure1' namespace
                filter.operator === 'tags' ? 'pure1' : DEFAULT_NAMESPACE,
                filter.key,
                filter.value,
            ),
        );
    return stateFilters;
}

function getMultiValueFilters(selector: string, useAstroMonkeySyntax = true): MultiValueFilter[] {
    if (!selector) {
        // empty string (as well as null or undefined) should correspond to no filter
        return [];
    }
    const inputStream = new InputStream(selector);
    const lexer = new PureXPathLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new PureXPathParser(tokenStream);
    const tree = parser.main();
    const listener = new ViewFilterListener();
    const walker = new ParseTreeWalker();
    let filters: MultiValueFilter[];
    try {
        walker.walk(listener, tree);
        filters = listener.get();
    } catch (e) {
        console.error(e.message);
        filters = [];
    }

    if (useAstroMonkeySyntax) {
        return filters.map(astroMonkeyToSpogMultiValueFilter);
    }
    return filters;
}

function astroMonkeyToSpogMultiValueFilter(filter: MultiValueFilter): MultiValueFilter {
    if (filter.operator === 'tags') {
        return filter;
    } else {
        return { ...filter, key: astroMonkeyToSpogKey(filter.key) };
    }
}
/**
 * convert astro monkey keys into valid SPoG keys
 * @param key The filter key, currently the only difference between AstroMonkey and SPoG syntax
 */
function astroMonkeyToSpogKey(key: string): string {
    if (key === 'name') {
        return ARRAY_NAME_KEY;
    } else {
        return key;
    }
}
