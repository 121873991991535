import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiKeysViewComponent } from './api-keys-view/api-keys-view.component';
import { hasPermissionGuard } from '../app/guards/permission-guard';

const routes: Routes = [
    {
        path: '',
        component: ApiKeysViewComponent,
        title: 'API Registration',
        canActivate: [hasPermissionGuard('PURE1:read:apikey')],
        data: {
            pageHelpUrl:
                'https://support.purestorage.com/Pure1/Pure1_Manage/Pure1_Manage_-_REST_API/Pure1_Manage_-_REST_API__Reference',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ApiKeysRoutingModule {}
