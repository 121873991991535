<div class="modal-body">
    <ng-container *ngIf="createCaseDoneFlag; else confirmModalLoading">
        <ng-container *ngIf="!createCaseErrorFlag; else confirmModalError">
            <div class="confirmation-icon">
                <pureui-svg svg="scheduled-upgrade.svg" height="50"></pureui-svg>
            </div>
            <ng-container *ngIf="!hasSelectedTimeslots; else upgradeScheduled">
                <h4>An upgrade request has been sent.</h4>
                <br />
                <h5>
                    As this is a more complex upgrade than usual, Pure Support will get back to you via
                    <b class="email">{{ email }}</b> to schedule a time.
                </h5>
            </ng-container>
            <ng-template #upgradeScheduled>
                <h4>Your assisted upgrade appointments have been successfully scheduled.</h4>
                <ul class="appliances-list">
                    <li *ngFor="let array of schedule.schedule" class="scheduled-appliances">
                        <div *ngIf="!array.secondaryArray" class="appliance-name">
                            {{ getArrayName(array.array) }}
                        </div>
                        <div *ngIf="array.secondaryArray" class="appliance-name">
                            <span class="replication-icon-wrapper">
                                <pureui-svg svg="pod.svg" height="10" class="replication-icon"></pureui-svg>
                            </span>
                            <div class="clustered-appliance-name primary">
                                <span class="connector"></span>
                                {{ getArrayName(array.array) }}
                            </div>
                            <div class="secondary">
                                <span class="connector"></span>
                                {{ getArrayName(array.secondaryArray) }}
                            </div>
                        </div>
                        <div class="created-case-link" (click)="upgradeOnLinkClick(caseId)">
                            Case ID#: {{ caseId }}
                        </div>
                    </li>
                </ul>
                <br />
            </ng-template>
            <br />
        </ng-container>
        <button class="btn btn-primary" (click)="onDismiss.emit()">Done</button>
    </ng-container>
    <ng-template #confirmModalLoading>
        <br />
        <pureui-spinner class="p-loading" [pureuiShow]="true"></pureui-spinner>
        <br />
    </ng-template>
    <ng-template #confirmModalError>
        <div class="error-content">
            <h4>Oops... Something went wrong...</h4>
            <h4>Please contact administrator or try again later.</h4>
        </div>
    </ng-template>
</div>
