import { Component, Input } from '@angular/core';

@Component({
    selector: 'pure-numbered-steps',
    templateUrl: './pure-numbered-steps.component.html',
})
export class PureNumberedStepsComponent {
    @Input() readonly steps: string[];
    @Input() readonly currentStep: string;

    stepTrackBy(index: number, step: string): string {
        return step;
    }
}
