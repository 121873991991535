import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import {
    CustomTimeRange,
    isCustomTimeRange,
    TimeRangeListOption,
    TimeRangeSelectChoice,
} from '../../../ui/components/calendar-time-range-select/calendar-time-range-select.component';
import { ImmutableTimeRange } from '../../../model/ImmutableTimeRange';
import {
    DisasterRecoveryClusterConfigurationService,
    DisasterRecoveryJobExecution,
    DisasterRecoveryJobExecutionsService,
    DisasterRecoveryVmJobExecution,
} from '@pure1/data';
import {
    addPagination,
    addTime,
    removeAllTimes,
    removePagination,
    TIME_CUSTOM_DURATION,
    TIME_END_KEY,
    TIME_START_KEY,
} from '../../../redux/actions';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState, IStateTime } from '../../../redux/pure-redux.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DisasterRecoveryBaseComponent } from '../../disaster-recovery-base.component';

export enum JobExecutionType {
    Protection = 'Protection',
    Recovery = 'Recovery',
    Failover = 'Failover',
}

const PAGE_SIZE = 25;

@Component({
    selector: 'job-execution-monitor',
    templateUrl: './job-execution-monitor.component.html',
})
export class JobExecutionMonitorComponent extends DisasterRecoveryBaseComponent implements OnInit, OnDestroy {
    @Input() readonly type: JobExecutionType;

    runningJobExecutions: DisasterRecoveryJobExecution[] = [];

    loadingFinishedJobExecutions = true;
    finishedJobExecutions: DisasterRecoveryJobExecution[] = [];

    timeRangeOptions: TimeRangeListOption[] = [
        { text: 'Last Hour', duration: moment.duration(1, 'hour') },
        { text: 'Last 7 Days', duration: moment.duration(7, 'days') },
        { text: 'Last 2 Months', duration: moment.duration(2, 'months') },
        { text: 'Last 3 Months', duration: moment.duration(3, 'months') },
    ];
    private readonly defaultTimeRangeOptionIdx = 1;

    extremeTimeRange: ImmutableTimeRange = new ImmutableTimeRange(moment().subtract(1, 'year'), moment());
    selectedTimeRangeOption: TimeRangeSelectChoice;

    selectedVmJobExecution: DisasterRecoveryVmJobExecution = null;
    isExecutionStepsPanelOpen = false;

    finishedJobExecutionsOffset = 0;
    finishedJobExecutionsPageSize = PAGE_SIZE;
    finishedJobExecutionsTotal: number;

    private barId: string;
    private unsubscribeFromRunning$ = new Subject<void>();
    private unsubscribeFromFinished$ = new Subject<void>();
    private unsubscribeFromRedux: Function;

    constructor(
        clusterConfigurationService: DisasterRecoveryClusterConfigurationService,
        ngRedux: NgRedux<IState>,
        private jobExecutionsService: DisasterRecoveryJobExecutionsService,
    ) {
        super(clusterConfigurationService, ngRedux);
    }

    ngOnInit(): void {
        this.barId = 'job-execution-monitor-' + this.type;
        this.initSelectedTimeRange();

        this.clusterLoaded$.subscribe(() => {
            // API will return 400 if you try to fetch running job executions without bootstrapped cluster
            if (this.clusterId && this.clusterAwsProviderId) {
                this.loadRunningJobExecutions();
                this.loadFinishedJobExecutions();
            }
        });
        this.unsubscribeFromRedux = this.ngRedux.subscribe(() => {
            this.finishedJobExecutionsOffset = this.ngRedux.getState().paginations[this.barId] || 0;
        });

        super.ngOnInit();
    }

    ngOnDestroy(): void {
        this.unsubscribeFromRunning$.next();
        this.unsubscribeFromFinished$.next();
        if (this.unsubscribeFromRedux) {
            this.unsubscribeFromRedux();
        }
    }

    private loadRunningJobExecutions(): void {
        this.unsubscribeFromRunning$.next();
        this.jobExecutionsService
            .getRunningJobExecutions(this.clusterId, this.type)
            .pipe(takeUntil(this.unsubscribeFromRunning$))
            .subscribe(data => {
                this.runningJobExecutions = data;
            });
    }

    private loadFinishedJobExecutions(): void {
        this.unsubscribeFromFinished$.next();

        let start: moment.Moment;
        let end: moment.Moment = null;
        if (isCustomTimeRange(this.selectedTimeRangeOption)) {
            start = this.selectedTimeRangeOption.start;
            end = this.selectedTimeRangeOption.end;
        } else {
            start = moment().subtract(this.selectedTimeRangeOption.duration);
        }

        this.loadingFinishedJobExecutions = true;
        const pageNumber = this.finishedJobExecutionsOffset / this.finishedJobExecutionsPageSize;
        this.jobExecutionsService
            .getFinishedJobExecutions(
                this.clusterId,
                this.type,
                this.finishedJobExecutionsPageSize,
                pageNumber,
                start,
                end,
            )
            .pipe(takeUntil(this.unsubscribeFromFinished$))
            .subscribe(data => {
                this.finishedJobExecutionsTotal = data.total;
                this.finishedJobExecutions = data.response;
                this.loadingFinishedJobExecutions = false;
            });
    }

    private initSelectedTimeRange(): void {
        const state: IStateTime[] = this.ngRedux.getState().times[this.barId];
        if (state?.[0].key === TIME_CUSTOM_DURATION) {
            this.selectedTimeRangeOption = this.timeRangeOptions.find(
                timeRangeOption => timeRangeOption.duration.toISOString() === state[0].value,
            );
        }
        if (state?.[0].key === TIME_START_KEY && state?.[1].key === TIME_END_KEY) {
            // datepicker uses a local timezone
            this.selectedTimeRangeOption = {
                start: moment(state[0].value).local(),
                end: moment(state[1].value).local(),
            } as CustomTimeRange;
        }
        if (!this.selectedTimeRangeOption) {
            this.selectedTimeRangeOption = this.timeRangeOptions[this.defaultTimeRangeOptionIdx];
        }
    }

    selectVmJobExecution(vmJobExecution: DisasterRecoveryVmJobExecution): void {
        this.selectedVmJobExecution = vmJobExecution;
        this.isExecutionStepsPanelOpen = true;
    }

    closeExecutionStepsPanel(): void {
        this.isExecutionStepsPanelOpen = false;
    }

    selectTimeRange(timeRangeOption: TimeRangeSelectChoice): void {
        this.selectedTimeRangeOption = timeRangeOption;
        if (isCustomTimeRange(timeRangeOption)) {
            this.ngRedux.dispatch([
                removePagination(this.barId),
                removeAllTimes(this.barId),
                addTime(this.barId, TIME_START_KEY, timeRangeOption.start.toISOString()),
                addTime(this.barId, TIME_END_KEY, timeRangeOption.end.toISOString()),
            ]);
        } else {
            this.ngRedux.dispatch([
                removePagination(this.barId),
                removeAllTimes(this.barId),
                addTime(this.barId, TIME_CUSTOM_DURATION, timeRangeOption.duration.toISOString()),
            ]);
        }
        this.loadFinishedJobExecutions();
    }

    onPageChange(offset: number): void {
        this.ngRedux.dispatch([removePagination(this.barId), addPagination(this.barId, offset)]);
        this.loadFinishedJobExecutions();
    }
}
