import moment from 'moment';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { takeUntil, map, switchMap, tap, exhaustMap, take } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

import { ApiAppKey, ApiAppKeyService } from '@pure1/data';
import { smartTimer } from '@pstg/smart-timer';

@Component({
    selector: 'api-keys-view',
    templateUrl: './api-keys-view.component.html',
})
export class ApiKeysViewComponent implements OnInit, OnDestroy {
    readonly API_KEY_LIMIT = 100;

    loading = false;
    selectedApiAppKey: ApiAppKey;
    apiAppKeys: ApiAppKey[] = [];

    private readonly update$ = new BehaviorSubject<void>(null);
    private readonly destroy$ = new Subject<void>();

    constructor(
        private apiAppKeyService: ApiAppKeyService,
        public modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.update$
            .pipe(
                tap(() => (this.loading = true)),
                switchMap(() =>
                    smartTimer(0, moment.duration(30, 'seconds').asMilliseconds()).pipe(
                        exhaustMap(() => this.apiAppKeyService.list().pipe(take(1))),
                        map(result => result.response),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(
                keys => {
                    this.loading = false;
                    this.apiAppKeys = keys.slice();
                    this.sortApiAppKeys();
                },
                err => {
                    this.loading = false;
                },
            );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openModal(modal: TemplateRef<NgbActiveModal>, selectedApiAppKey?: ApiAppKey): void {
        const properties = selectedApiAppKey ? {} : { size: 'lg' };
        this.selectedApiAppKey = selectedApiAppKey;

        this.modalService.open(modal, properties).result.then(
            () => {
                this.update$.next();
            },
            () => {},
        );
    }

    private sortApiAppKeys(): void {
        // Currently, we only sort by name. There is no existing sorting on the table
        this.apiAppKeys.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            return aName.localeCompare(bName);
        });
    }
}
