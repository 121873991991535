import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

const SMA_ROLE = 'Cannot assign the Safe Mode Approver role. The assignee has to enable mobile authentication first.';
const LEGACY_ROLE = 'At least one required role needs to be selected.';
const REQUIRED = 'At least one role needs to be selected';

@Pipe({
    name: 'roleErrors',
})
export class RoleErrorsPipe implements PipeTransform {
    transform(value: ValidationErrors): string | null {
        if (!value) {
            return null;
        }

        if (value.required) {
            return REQUIRED;
        }

        if (value.legacyRole) {
            return LEGACY_ROLE;
        }

        if (value.smaRole) {
            return SMA_ROLE;
        }

        if (value.legacyRole) {
            return LEGACY_ROLE;
        }

        return null;
    }
}
