import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DataPage,
    IRestResponse,
    Mitigation,
    MitigationDetail,
    MitigationSeverity,
    MitigationStatus,
    listMitigationsOptions,
} from '@pure1/data';
import { Observable, forkJoin } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { ListParams } from '../interfaces/list-params';
import { GenericService } from './generic.service';

const defaultParams = {
    pageStart: 0,
    pageSize: 1000, // BE has no support for pagination yet
};

@Injectable({ providedIn: 'root' })
export class PurityOptimizationV2Service extends GenericService<Mitigation> {
    private static cacheTTL = 300000; // 300 seconds
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Mitigation,
            defaultParams: defaultParams,
            endpoint: '/rest/cfwd/api/v1/mitigations',
        });
    }

    list(
        params?: ListParams<Mitigation>,
        opts: listMitigationsOptions = { showCompleted: true, criticalOnly: false },
    ): Observable<DataPage<Mitigation>> {
        return super.list(params).pipe(
            concatMap(page => {
                let relevantMitigations = page.response;
                relevantMitigations.forEach(item => {
                    if (item.optimizationStatus === MitigationStatus.EXPIRED) {
                        item.optimizationStatus = MitigationStatus.AVAILABLE;
                    }
                });
                if (!opts.showCompleted) {
                    relevantMitigations = relevantMitigations.filter(
                        mitigation => mitigation.optimizationStatus !== MitigationStatus.COMPLETED,
                    );
                }
                if (opts.criticalOnly) {
                    relevantMitigations = relevantMitigations.filter(
                        mitigation => mitigation.severity === MitigationSeverity.CRITICAL,
                    );
                }
                const uniqueMitigationNames = [...new Set(relevantMitigations.map(item => item.optimizationName))];
                const detailRequests = uniqueMitigationNames.map(mitigationName =>
                    this.getMitigationDetail(mitigationName),
                );
                return forkJoin(detailRequests).pipe(
                    map(details => {
                        relevantMitigations.forEach(item => {
                            const relevantDetails = details.find(
                                detail => detail?.softwareName === item.optimizationName,
                            );
                            if (!relevantDetails) {
                                return;
                            }
                            item.optimizationDescription = relevantDetails.description;
                            item.type = relevantDetails.type;
                            item.optimizationKbLink = `https://support.purestorage.com/?cid=Alert_${relevantDetails.alertCode}`;
                        });
                        return page;
                    }),
                );
            }),
        );
    }

    getMitigation(applianceId: string): Observable<Mitigation> {
        const params = new HttpParams().set('applianceIds', applianceId);
        return this.http
            .get<IRestResponse<Mitigation>>('/rest/cfwd/api/v1/mitigations', {
                params,
            })
            .pipe(
                map(response => {
                    return response.items[0];
                }),
            );
    }

    /**
     * Opts in several arrays in a single API call
     * @param arrays The arrays to be opted in
     * @returns The list of resources opted in
     * Note: the BE should insert the information about the user (customer) in the call to the upstream service
     */
    optInMultiple(arrays: Mitigation[]): Observable<Mitigation[]> {
        const groupedOptIns = this.groupOptInsByOptimization(arrays);
        return this.http
            .post<IRestResponse<Mitigation[]>>(
                '/rest/cfwd/api/v1/mitigations/opt-in',
                { optIns: groupedOptIns },
                {
                    params: {},
                    observe: 'response',
                },
            )
            .pipe(
                map(response => {
                    if (response.body?.items) {
                        return response.body.items.map(item => new Mitigation(item));
                    } else {
                        return [];
                    }
                }),
            );
    }

    /**
     * Opts in several arrays in a single API call, using Step Up Authentication
     * @param arrays The arrays to be opted in
     * @param stepUpAuthToken The Step Up Authentication token
     * @returns The list of resources opted in
     * Note: the BE should insert the information about the user (customer) in the call to the upstream service
     */
    optInMultipleWithStepUpAuth(arrays: Mitigation[], stepUpAuthToken: string): Observable<Mitigation[]> {
        const optIns = this.groupOptInsByOptimization(arrays);
        return this.http
            .post<IRestResponse<Mitigation[]>>(
                '/rest/cfwd/api/v1/mitigations/ems',
                {
                    optIns,
                    stepUpAuthToken,
                },
                {
                    params: {},
                    observe: 'response',
                },
            )
            .pipe(
                map(response => {
                    if (response.body?.items) {
                        return response.body.items.map(item => new Mitigation(item));
                    } else {
                        return [];
                    }
                }),
            );
    }

    /**
     * Groups arrays in a Map according to the optimization they have been opted in to.
     * @returns a Map number->string[] where each entry represents an optimization ID (key) and
     * the IDs of the arrays that have been opted in for that optimization (value).
     */
    private groupOptInsByOptimization(optimizedArrays: Mitigation[]): { [key: number]: string[] } {
        const result: { [key: number]: string[] } = {};
        optimizedArrays.forEach(oarr => {
            const arrayId = oarr.arrayId;
            const optimizationId = oarr.optimizationName;
            const existingArrayIds = result[optimizationId];
            if (existingArrayIds) {
                result[optimizationId] = [...existingArrayIds, arrayId];
            } else {
                result[optimizationId] = [arrayId];
            }
        });
        return result;
    }

    @Cacheable({
        maxAge: PurityOptimizationV2Service.cacheTTL,
        maxCacheCount: 10,
    })
    getMitigationDetail(mitigationName: string): Observable<MitigationDetail> {
        return this.http.get<MitigationDetail>('/rest/cfwd/api/v1/mitigations/detail', {
            params: { mitigationName },
        });
    }
}
