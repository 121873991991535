import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterParams } from '@pure1/data';
import { map, Observable, Subject } from 'rxjs';
import {
    DraasApiCreateServiceLevelPolicyV3,
    DraasApiReplicationStrategyProviderTemplate,
    DraasApiServiceLevelPolicyV3,
    DraasApiUpdateServiceLevelPolicyV3,
} from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryPolicyV2 } from '../models/disaster-recovery-policy-v2';
import { DisasterRecoveryReplicationStrategySiteTemplate } from '../models/disaster-recovery-replication-strategy-site';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryPoliciesV2Service extends DisasterRecoveryBaseService<
    DisasterRecoveryPolicyV2,
    DraasApiServiceLevelPolicyV3
> {
    protected resourceClass = DisasterRecoveryPolicyV2;
    protected pathParams = ['clusterId', 'policyId'];

    readonly policyCreated$: Subject<DisasterRecoveryPolicyV2> = new Subject();
    readonly policyUpdated$: Subject<DisasterRecoveryPolicyV2> = new Subject();
    readonly policyRemoved$: Subject<string> = new Subject();

    constructor(protected http: HttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryPolicyV2>): string {
        return filter.policyId
            ? `${DraasApiConfig.getUrlPrefix()}/api/3.0/clusters/${filter.clusterId}/service-level-policies/${filter.policyId}`
            : `${DraasApiConfig.getUrlPrefix()}/api/3.0/clusters/${filter.clusterId}/service-level-policies`;
    }

    create(clusterId: string, body: DraasApiCreateServiceLevelPolicyV3): Observable<DisasterRecoveryPolicyV2> {
        return this.doCreate(this.getEndpoint({ clusterId }), body, this.policyCreated$);
    }

    update(
        clusterId: string,
        policyId: string,
        body: DraasApiUpdateServiceLevelPolicyV3,
    ): Observable<DisasterRecoveryPolicyV2> {
        return this.doUpdate(this.getEndpoint({ clusterId, policyId }), body, this.policyUpdated$);
    }

    delete(clusterId: string, policyId: string): Observable<void> {
        return this.doDelete(this.getEndpoint({ clusterId, policyId }), policyId, this.policyRemoved$);
    }

    getReplicationStrategies(clusterId: string): Observable<DisasterRecoveryReplicationStrategySiteTemplate[]> {
        const endpoint = `${this.getEndpoint({ clusterId })}/replication-strategies`;
        return this.http
            .get<DraasApiReplicationStrategyProviderTemplate[]>(endpoint)
            .pipe(map(response => response.map(json => new DisasterRecoveryReplicationStrategySiteTemplate(json))));
    }
}
