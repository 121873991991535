<ng-container *ngIf="reservedAmount != null && reservedAmount !== 0; else notAvailable">
    <capacity-progress-bar
        [ngClass]="isSimulation ? 'is-simulation' : ''"
        [percent]="(currentAmount * 100) / reservedAmount"
        label="{{ ((currentAmount * 100) / reservedAmount | number: '1.1-1' | defaultString) + '%' }} | {{
            currentAmount | formatSubscriptionData: licenseType : 0 : useFormatTiBPipe : fixUnit | defaultString
        }}{{ fixUnit ? ' ' + fixUnit : '' }}"
    >
    </capacity-progress-bar>
</ng-container>
<ng-template #notAvailable>
    <capacity-progress-bar
        [notAvailable]="true"
        label="{{
            currentAmount | formatSubscriptionData: licenseType : 0 : useFormatTiBPipe : fixUnit | defaultString
        }}{{ fixUnit ? ' ' + fixUnit : '' }}"
    >
    </capacity-progress-bar>
</ng-template>
<ng-container *ngIf="reservedAmount != null && reservedAmount !== 0">
    <span id="usage-total"
        >{{
            ' of ' +
                (reservedAmount | formatSubscriptionData: licenseType : 0 : useFormatTiBPipe : fixUnit | defaultString)
        }}
        {{ fixUnit }}</span
    >
</ng-container>
