<pureui-spinner class="p-loading" [pureuiShow]="supportLoader.isLoading$ | async"></pureui-spinner>

<div
    class="row secondary-nav-and-content-wrapper secondary-nav-collapsible"
    [ngClass]="{ 'secondary-nav-collapsed': secondaryNavCollapsed }"
>
    <support-nav
        id="support-sidebar"
        class="secondary-left-nav"
        *ngIf="!caOnly"
        [cases]="caseManager.forNav()"
        [closedCases]="caseManager.closedCases()"
        [selectedCaseId]="caseId"
        (newCaseCreated)="newCaseCreated($event)"
        (toggleSecondaryNavCollapsed)="toggleSecondaryNavCollapsed()"
    ></support-nav>

    <div class="col-xs container-fluid" id="support-main-content">
        <banners class="support-banners"></banners>
        <div id="support-main" class="row" [ngClass]="{ 'single-case': caseId }">
            <router-outlet></router-outlet>
            <div id="main-feed" *ngIf="!caseId">
                <div *ngIf="showNoCaseSelectedMessage()" class="no-case-selected-message">
                    To view specific case details, please select a case from the sidebar on the left.
                </div>
            </div>
        </div>
    </div>
</div>
