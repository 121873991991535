import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { ViewsService, View } from '@pure1/data';
import { UserRoleStateService } from '../services/user-role-state.service';

@Component({
    selector: 'view-list',
    templateUrl: './view-list.component.html',
})
export class ViewListComponent implements OnInit, OnDestroy {
    @ViewChild('viewNameErrorTooltip', { static: true }) viewNameErrorTooltip: NgbTooltip;

    views: View[] = [];
    subscription: Subscription;
    sortAscending = true;
    loading = true;

    constructor(
        private viewsService: ViewsService,
        public modalService: NgbModal,
        private urStateService: UserRoleStateService,
    ) {}

    ngOnInit(): void {
        this.refreshData();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    toggleSort(): void {
        this.sortAscending = !this.sortAscending;
        this.views.reverse();
    }

    openOptionsPopover(view: View, optionsPopover: NgbPopover): void {
        optionsPopover.open({ view: view, popover: optionsPopover });
    }

    openDeleteModal(popover: NgbPopover, modal: TemplateRef<NgbActiveModal>): void {
        this.modalService.open(modal, { backdrop: 'static' });
        popover.close();
    }

    editView(view: View): void {
        this.urStateService.editView({
            id: view.id,
            name: view.name,
        });
    }

    addNewView(): void {
        this.urStateService.createView();
    }

    private refreshData(): void {
        this.unsubscribe();
        this.loading = true;
        this.subscription = this.urStateService.listWithCache(this.viewsService).subscribe(data => {
            this.loading = false;
            this.views = data.response.sort((a, b) => {
                const aName = a.name.toLocaleLowerCase();
                const bName = b.name.toLocaleLowerCase();
                return this.sortAscending ? aName.localeCompare(bName) : bName.localeCompare(aName); // assumes all views have a well-defined name
            });
        });
    }

    private unsubscribe(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}
