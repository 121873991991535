<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="modal-title">
        <ng-container *ngIf="!featureFlagEnabled; else newTitle"> Edit Designated Partner </ng-container>
        <ng-template #newTitle> {{ partnerToUpdate?.contactName ? 'Edit' : 'Add' }} Partner </ng-template>
    </div>
</div>

<div class="modal-body">
    <ng-container *ngIf="!featureFlagEnabled; else newPartnerFF">
        <div class="form-check">
            <div class="form-check-label" (click)="clickPartnerOption('existingPartner')">
                <input
                    type="radio"
                    class="form-check-input"
                    value="existingPartner"
                    [(ngModel)]="selectedPartnerOption"
                />
                Choose Existing Partner
            </div>
            <div class="form-check-label" (click)="clickPartnerOption('newPartner')">
                <input type="radio" class="form-check-input" value="newPartner" [(ngModel)]="selectedPartnerOption" />
                Add New Partner
            </div>
        </div>
        <ng-container *ngIf="selectedPartnerOption === 'existingPartner'; else newPartner">
            <div class="partner-info-dropdown">
                <ng-container *ngIf="!isRenewalOrder(); else notRenewalOrder">
                    <div class="partner-org">
                        <ng-select
                            [clearable]="false"
                            [placeholder]="selectedOrg ? '' : 'Organization'"
                            [searchable]="false"
                            [items]="partnerOrganizationOptions"
                            [ngModel]="selectedOrg"
                            (change)="partnerOrgSelectionChanged($event)"
                        >
                        </ng-select>
                    </div>
                </ng-container>
                <ng-template #notRenewalOrder>
                    <div class="current-partner-org">{{ partnerLatestOrg | defaultString }}</div>
                </ng-template>
                <div class="partner-info">
                    <ng-select
                        class="partner-info-selection"
                        [disabled]="!selectedOrg && !isRenewalOrder()"
                        [clearable]="false"
                        [placeholder]="selectedPartner ? '' : 'Partner'"
                        [searchable]="false"
                        [items]="generatePartnerNameList()"
                        [(ngModel)]="selectedPartner"
                    >
                    </ng-select>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #newPartner>
        <div class="new-partner-info">
            <form id="partnerForm" class="partner-inputs" [formGroup]="confirmForm">
                <div class="partner-input">
                    Organization
                    <ng-container *ngIf="!isRenewalOrder(); else notRenewalInput">
                        <input type="text" class="form-control" formControlName="orgInput" autofocus="autofocus" />
                    </ng-container>
                    <ng-template #notRenewalInput>
                        <div class="form-control">{{ partnerLatestOrg | defaultString }}</div>
                    </ng-template>
                </div>
                <div class="partner-input">
                    First Name
                    <input type="text" class="form-control" formControlName="firstNameInput" autofocus="autofocus" />
                </div>
                <div class="partner-input">
                    Last Name
                    <input type="text" class="form-control" formControlName="lastNameInput" autofocus="autofocus" />
                </div>
                <div class="partner-input">
                    Phone (optional)
                    <input
                        type="text"
                        class="form-control"
                        formControlName="phoneInput"
                        numbersOnly
                        autofocus="autofocus"
                    />
                </div>
                <div class="partner-input">
                    Email
                    <input type="text" class="form-control" formControlName="emailInput" autofocus="autofocus" />
                </div>
            </form>
            <textarea
                class="comment-body form-control"
                [(ngModel)]="additionalCommentInput"
                rows="6"
                maxlength="3000"
                placeholder="Additional Information (optional)"
            ></textarea>
        </div>
    </ng-template>
    <ng-template #newPartnerFF>
        <div class="new-partner-info">
            <form id="partnerForm" [formGroup]="partnerForm">
                <div class="top-form-section">
                    <div class="partner-inputs">
                        <div class="partner-input">
                            <label>Organization</label>
                            <input type="text" class="form-control" formControlName="orgInput" />
                        </div>
                        <div class="partner-input">
                            <label>Name</label>
                            <input type="text" class="form-control" formControlName="nameInput" ngbAutoFocus />
                        </div>
                        <div class="partner-input">
                            <label>Phone (optional)</label>
                            <input type="text" class="form-control" formControlName="phoneInput" numbersOnly />
                        </div>
                        <div class="partner-input">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="emailInput" />
                        </div>
                    </div>
                    <div class="partner-image-section">
                        <img class="partner-form-image" src="/images/partner-form-image.gif" />
                    </div>
                </div>
                <div class="partner-input">
                    <label>Additional Info (optional)</label>
                    <textarea
                        class="comment-body form-control"
                        formControlName="additionalCommentInput"
                        rows="6"
                        maxlength="3000"
                    >
                    </textarea>
                </div>
            </form>
        </div>
    </ng-template>
</div>

<div class="modal-footer">
    <button type="button" id="cancelButton" class="btn btn-info" (click)="activeModal.dismiss()"> Back </button>
    <button
        type="button"
        id="submit-button"
        class="btn btn-primary"
        [disabled]="('PURE1:write:subscriptions_renew' | isNotAllowed) || !isSaveButtonValidated()"
        (click)="modifyPartner()"
    >
        <ng-container *ngIf="!isLoading">Save</ng-container>
        <ng-container *ngIf="isLoading">Saving...</ng-container>
    </button>
</div>
