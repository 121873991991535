import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import moment from 'moment';
import {
    CduAppliance,
    CheckStatus,
    DownloadUpgradeWorkflowInstance,
    InstallCheck,
    InstallCheckList,
    InstallCheckOverride,
    InstallUpgradeWorkflowInstance,
    InstallUpgradeWorkflowInstanceStatus,
    isDownloadUpgradeWorkflowInstance,
    isInstallUpgradeWorkflowInstance,
    isPDUWorkflowCreateRequest,
    PagedResult,
    StepUpAuthRequirements,
    UpgradeWorkflowInstance,
    WorkflowCreateRequest,
    WorkflowEvent,
    WorkflowInstanceLog,
    WorkflowState,
    CduApplianceResponse,
    WorkflowEventType,
} from '../purity-upgrades.interface';
import { StepUpModalService } from '../../../step-up/services/step-up-modal.service';
import { splitToChunks } from '../purity-upgrades.utils';
import { FilterParams, ListParams, StepUpVerifyResponse, quoteAndEscape } from '@pure1/data';
import { AuthenticationService } from '../../../services/authentication.service';
import { UpgradePrechecksErrorService } from './error.service';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState } from '../../../redux/pure-redux.service';
import { addStepUpToken, removeExpiredStepUpTokens, removeAllStepUpTokens } from '../../../redux/actions';
import { IMultiValueFilter } from 'core/src/gui/paged-data2.component';

export const AUTHORIZATION_STEP_UP_HEADER_NAME = 'Authorization-Step-Up';
export const INVALIDATE_CACHE_HEADER_NAME = 'Invalidate-Cache';

@Injectable({ providedIn: 'root' })
export class DriverService implements OnDestroy {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 9000; // 29 seconds
    private endpoint = '/rest/cfwd/api/v1';
    private destroy$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        private stepUpModalService: StepUpModalService,
        private authenticationService: AuthenticationService,
        private errorService: UpgradePrechecksErrorService,
        private ngRedux: NgRedux<IState>,
    ) {
        this.authenticationService
            .isLoggedIn()
            .pipe(takeUntil(this.destroy$))
            .subscribe(isLoggedIn => {
                if (!isLoggedIn) {
                    this.ngRedux.dispatch(removeAllStepUpTokens());
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    getAvailableWorkflowNames(arrayIds: string[]): Observable<{ [key: string]: any[] }> {
        let params = new HttpParams();
        params = params.set('filter', 'arrayId=(' + arrayIds.map(id => `'${id}'`).join(', ') + ')');
        return this.http.get<{ [key: string]: any[] }>(this.endpoint + '/workflow-states/workflow-names/available', {
            params,
        });
    }

    @Cacheable({
        maxAge: DriverService.cacheTTL,
        cacheBusterObserver: DriverService.cacheBuster$,
    })
    getWorkflowState(workflowInstanceId: number): Observable<WorkflowState> {
        return this.http.get<WorkflowState>(this.endpoint + '/workflow-states/' + workflowInstanceId).pipe(
            catchError(this.errorService.errorInterceptor),
            map(ws => ({
                ...ws,
                instance: this.formatWorkflowInstance(ws.instance),
            })),
        );
    }

    @Cacheable({
        maxAge: DriverService.cacheTTL,
        cacheBusterObserver: DriverService.cacheBuster$,
    })
    getRunningWorkflows(applianceIds: string[]): Observable<{ [key: string]: UpgradeWorkflowInstance[] }> {
        const chunks = splitToChunks(applianceIds, 100);

        return forkJoin([...chunks.map(chunk => this.getRunningWorkflowsChunk(chunk))]).pipe(
            map(responses =>
                responses.reduce(
                    (acc, chunk) => ({
                        ...acc,
                        ...chunk,
                    }),
                    {},
                ),
            ),
        );
    }

    @Cacheable({
        maxAge: DriverService.cacheTTL,
        cacheBusterObserver: DriverService.cacheBuster$,
    })
    getRunningCDUWorkflows(applianceIds: string[]): Observable<{ [key: string]: UpgradeWorkflowInstance[] }> {
        const chunks = splitToChunks(applianceIds, 100);

        return forkJoin([...chunks.map(chunk => this.getRunningCDUWorkflowsChunk(chunk))]).pipe(
            map(responses =>
                responses.reduce(
                    (acc, chunk) => ({
                        ...acc,
                        ...chunk,
                    }),
                    {},
                ),
            ),
        );
    }

    createWorkflow(workflowCreateRequest: WorkflowCreateRequest): Observable<WorkflowState> {
        const requestBody = {
            ...workflowCreateRequest,
            ...(isPDUWorkflowCreateRequest(workflowCreateRequest)
                ? {
                      targetTime: workflowCreateRequest.targetTime?.toISOString() || null,
                  }
                : {}),
        };

        return this.http.post<WorkflowState>(this.endpoint + '/workflow-states', requestBody).pipe(
            catchError(this.errorService.errorInterceptor),
            map(ws => ({
                ...ws,
                instance: this.formatWorkflowInstance(ws.instance),
            })),
        );
    }

    createWorkflowEvent(workflowInstanceId: number, requestObject: WorkflowEvent): Observable<WorkflowState> {
        return this.http
            .post<WorkflowState>(this.endpoint + '/workflow-states/' + workflowInstanceId + '/event', requestObject)
            .pipe(
                catchError(
                    requestObject.type === WorkflowEventType.AbortWorkflow ? null : this.errorService.errorInterceptor,
                ),
                map(ws => ({
                    ...ws,
                    instance: this.formatWorkflowInstance(ws.instance),
                })),
            );
    }

    createWorkflowEventWithPreflight(
        arrayId: string,
        workflowInstanceId: number,
        requestObject: WorkflowEvent,
    ): Observable<WorkflowState> {
        let headers = new HttpHeaders();

        this.ngRedux.dispatch(removeExpiredStepUpTokens(moment()));
        const tokenState = this.ngRedux.getState().stepUpTokens[arrayId];

        if (tokenState) {
            headers = headers.append(AUTHORIZATION_STEP_UP_HEADER_NAME, tokenState.accessToken);
        }

        return this.http
            .post<StepUpAuthRequirements>(
                this.endpoint + '/workflow-states/' + workflowInstanceId + '/event/preflight',
                { type: requestObject.type },
                { headers },
            )
            .pipe(
                mergeMap(requirements => {
                    if (requirements) {
                        return this.stepUpModalService
                            .stepUp(
                                requirements.aud,
                                requirements.authorizationDetails,
                                requirements.singleUse,
                                requirements.ttl,
                            )
                            .pipe(
                                catchError(this.errorService.errorInterceptor),
                                mergeMap((stepUpResponse: StepUpVerifyResponse) => {
                                    if (stepUpResponse === null) {
                                        throw new Error('cancelled');
                                    }

                                    if (!requirements.singleUse) {
                                        const expire = moment().add(requirements.ttl, 'seconds');
                                        this.ngRedux.dispatch(
                                            addStepUpToken(arrayId, stepUpResponse.accessToken, expire),
                                        );
                                    }

                                    return this.createWorkflowEventWithStepUp(
                                        workflowInstanceId,
                                        requestObject,
                                        stepUpResponse.accessToken,
                                    );
                                }),
                            );
                    } else if (headers.has(AUTHORIZATION_STEP_UP_HEADER_NAME)) {
                        return this.createWorkflowEventWithStepUp(
                            workflowInstanceId,
                            requestObject,
                            tokenState.accessToken,
                        );
                    } else {
                        return this.createWorkflowEventWithStepUp(workflowInstanceId, requestObject);
                    }
                }),
            );
    }

    setWorkflowSupportCaseId(workflowInstanceId: number, supportCaseId: string): Observable<UpgradeWorkflowInstance> {
        return this.http
            .post<UpgradeWorkflowInstance>('/workflow-instances/' + workflowInstanceId + '/support-case', {
                supportCaseId,
            })
            .pipe(catchError(this.errorService.errorInterceptor), map(this.formatWorkflowInstance));
    }

    private createWorkflowEventWithStepUp(
        workflowInstanceId: number,
        requestObject: WorkflowEvent,
        stepUpHeader: string = null,
    ): Observable<WorkflowState> {
        let headers = new HttpHeaders();

        if (stepUpHeader) {
            headers = headers.append(AUTHORIZATION_STEP_UP_HEADER_NAME, stepUpHeader);
        }

        return this.http
            .post<WorkflowState>(this.endpoint + '/workflow-states/' + workflowInstanceId + '/event', requestObject, {
                headers,
            })
            .pipe(
                catchError(this.errorService.errorInterceptor),
                map(ws => ({
                    ...ws,
                    instance: this.formatWorkflowInstance(ws.instance),
                })),
            );
    }

    getAppliances(
        applianceIds: string[],
        invalidateCache: boolean,
        params?: ListParams<CduAppliance>,
    ): Observable<CduApplianceResponse> {
        let headers = new HttpHeaders();
        const sort = Array.isArray(params?.sort) ? params.sort[0] : params?.sort;
        const filter = params?.filter;
        let formattedParams = new HttpParams().set('limit', applianceIds.length.toString());
        if (sort) {
            if (sort.key === 'version') {
                // CLOUD-108385
                sort.key = 'currentVersion';
            }
            formattedParams = formattedParams.set('sort', `${sort.key},${sort.order}`);
        }
        if (filter) {
            formattedParams = formattedParams.set('filter', Object.values(filter).join(' and '));
        }

        if (invalidateCache) {
            headers = headers.append(INVALIDATE_CACHE_HEADER_NAME, 'true');
        }

        return this.http
            .post<CduApplianceResponse>(this.endpoint + '/appliance', applianceIds, {
                headers,
                params: formattedParams,
            })
            .pipe(
                catchError(this.errorService.errorInterceptor),
                map(result => ({
                    ...result,
                    content: result.content.map(this.formatCduAppliance),
                })),
            );
    }

    private getRunningWorkflowsChunk(applianceIds: string[]): Observable<{ [key: string]: UpgradeWorkflowInstance[] }> {
        const params = new HttpParams()
            .set('id', applianceIds.join(','))
            .set('workflowName', 'CDU,PDU')
            .set('limit', 1);
        return this.http
            .get<{ [key: string]: UpgradeWorkflowInstance[] }>(this.endpoint + '/workflow-instances/arrays', { params })
            .pipe(
                catchError(this.errorService.errorInterceptor),
                map(result =>
                    Object.keys(result).reduce(
                        (acc, arrayId) => ({
                            ...acc,
                            [arrayId]: result[arrayId]
                                .map(this.formatWorkflowInstance)
                                .sort(this.sortWorkflowInstanceByCreateTimeDesc),
                        }),
                        {},
                    ),
                ),
                mergeMap(upgradeWorkflows =>
                    this.getRunningPatchWorkflowsChunk(applianceIds).pipe(
                        map(patchWorkflows =>
                            applianceIds.reduce(
                                (acc, id) => ({
                                    ...acc,
                                    [id]: [...upgradeWorkflows[id], ...patchWorkflows[id]].sort(
                                        this.sortWorkflowInstanceByCreateTimeDesc,
                                    ),
                                }),
                                {},
                            ),
                        ),
                    ),
                ),
            );
    }

    private getRunningCDUWorkflowsChunk(
        applianceIds: string[],
    ): Observable<{ [key: string]: UpgradeWorkflowInstance[] }> {
        const params = new HttpParams().set('id', applianceIds.join(',')).set('workflowName', 'CDU').set('limit', 1);
        return this.http
            .get<{ [key: string]: UpgradeWorkflowInstance[] }>(this.endpoint + '/workflow-instances/arrays', { params })
            .pipe(
                catchError(this.errorService.errorInterceptor),
                map(result =>
                    Object.keys(result).reduce(
                        (acc, arrayId) => ({
                            ...acc,
                            [arrayId]: result[arrayId]
                                .map(this.formatWorkflowInstance)
                                .sort(this.sortWorkflowInstanceByCreateTimeDesc),
                        }),
                        {},
                    ),
                ),
            );
    }

    private getRunningPatchWorkflowsChunk(
        applianceIds: string[],
    ): Observable<{ [key: string]: UpgradeWorkflowInstance[] }> {
        const params = new HttpParams().set('id', applianceIds.join(',')).set('workflowName', 'PATCH').set('limit', 1);
        return this.http
            .get<{ [key: string]: UpgradeWorkflowInstance[] }>(this.endpoint + '/workflow-instances/arrays', { params })
            .pipe(
                catchError(this.errorService.errorInterceptor),
                map(result =>
                    Object.keys(result).reduce(
                        (acc, arrayId) => ({
                            ...acc,
                            [arrayId]: result[arrayId]
                                .map(this.formatWorkflowInstance)
                                .sort(this.sortWorkflowInstanceByCreateTimeDesc),
                        }),
                        {},
                    ),
                ),
            );
    }

    private sortWorkflowInstanceByCreateTimeDesc = (a: UpgradeWorkflowInstance, b: UpgradeWorkflowInstance): number => {
        if (a.createTime && b.createTime) {
            return b.createTime.valueOf() - a.createTime.valueOf();
        }

        // TODO: Delete once create time being null is fixed on backend
        return b.id - a.id;
    };

    private formatWorkflowInstance = (
        wi: UpgradeWorkflowInstance | InstallUpgradeWorkflowInstance | DownloadUpgradeWorkflowInstance,
    ): UpgradeWorkflowInstance => {
        return {
            ...wi,
            children: (wi.children || [])
                .map(this.formatWorkflowInstance)
                .sort(this.sortWorkflowInstanceByCreateTimeDesc),
            createTime: wi.createTime ? moment(wi.createTime) : null,
            endTime: wi.endTime ? moment(wi.endTime) : null,
            lastUpdateTime: wi.lastUpdateTime ? moment(wi.lastUpdateTime) : null,
            startTime: wi.startTime ? moment(wi.startTime) : null,
            targetTime: wi.targetTime ? moment(wi.targetTime) : null,
            ...(isDownloadUpgradeWorkflowInstance(wi)
                ? {
                      downloadRegistrationTime: wi.downloadRegistrationTime
                          ? moment(wi.downloadRegistrationTime)
                          : null,
                  }
                : {}),
            logs: (wi.logs || []).map(this.formatWorkflowInstanceLog),
            ...(isInstallUpgradeWorkflowInstance(wi)
                ? {
                      lastEventTime: wi.lastEventTime ? moment(wi.lastEventTime) : null,
                      statuses: (wi.statuses || [])
                          .map(this.formatWorkflowInstallUpgradeInstanceStatus)
                          .sort((a, b) => b.lastUpdateTime.diff(a.lastUpdateTime)),
                      checkLists: (wi.checkLists || []).map(this.formatInstallCheckList),
                      checkOverrides: (wi.checkOverrides || []).map(this.formatInstallCheckOverride),
                  }
                : {}),
        };
    };

    private formatWorkflowInstallUpgradeInstanceStatus = (
        log: InstallUpgradeWorkflowInstanceStatus,
    ): InstallUpgradeWorkflowInstanceStatus => {
        return {
            ...log,
            createTime: log.createTime ? moment(log.createTime) : null,
            lastUpdateTime: log.lastUpdateTime ? moment(log.lastUpdateTime) : null,
        };
    };

    private formatWorkflowInstanceLog = (log: WorkflowInstanceLog): WorkflowInstanceLog => {
        return {
            ...log,
            createTime: log.createTime ? moment(log.createTime) : null,
            lastUpdateTime: log.lastUpdateTime ? moment(log.lastUpdateTime) : null,
        };
    };

    private formatInstallCheckList = (checkList: InstallCheckList): InstallCheckList => {
        return {
            ...checkList,
            checks: checkList.checks.map(this.formatInstallCheck).sort((x, y) => {
                // Sort first FAILED, then OVERRIDEN, then PASSED
                if (x.status === CheckStatus.FAILED && y.status === x.status) {
                    return 0;
                }

                if (x.status === CheckStatus.FAILED) {
                    return -1;
                }

                if (y.status === CheckStatus.FAILED) {
                    return 1;
                }

                if (x.status === CheckStatus.OVERRIDDEN && y.status === x.status) {
                    return 0;
                }

                if (x.status === CheckStatus.OVERRIDDEN) {
                    return -1;
                }

                if (y.status === CheckStatus.OVERRIDDEN) {
                    return 1;
                }

                return 0;
            }),
            createTime: checkList.createTime ? moment(checkList.createTime) : null,
            lastUpdateTime: checkList.lastUpdateTime ? moment(checkList.lastUpdateTime) : null,
        };
    };

    private formatInstallCheck = (check: InstallCheck): InstallCheck => {
        return {
            ...check,
            status: check.status.toUpperCase() as CheckStatus,
            createTime: check.createTime ? moment(check.createTime) : null,
            lastUpdateTime: check.lastUpdateTime ? moment(check.lastUpdateTime) : null,
        };
    };

    private formatInstallCheckOverride = (override: InstallCheckOverride): InstallCheckOverride => {
        return {
            ...override,
            createTime: override.createTime ? moment(override.createTime) : null,
            lastUpdateTime: override.lastUpdateTime ? moment(override.lastUpdateTime) : null,
        };
    };

    private formatCduAppliance = (appliance: CduAppliance): CduAppliance => {
        return {
            ...appliance,
            statusTime: appliance.statusTime ? moment(appliance.statusTime) : null,
        };
    };

    prepFilterParams<T>(filters: IMultiValueFilter[]): FilterParams<T> {
        const filterParams: FilterParams<T> = {};
        filters.forEach(filter => {
            const values = filter.values;
            if (values.length === 0) {
                return;
            }
            const valuesArr = values.map(value => `${quoteAndEscape(String(value))}`);
            const valuesStr = valuesArr.join(',');
            if (filter.operator === 'contains') {
                filterParams[filter.key] = `contains(${filter.key},${valuesStr})`;
            } else {
                filterParams[filter.key] = `${filter.key}=${valuesStr}`;
            }
        });
        return filterParams;
    }
}
