<div class="loading-spinner">
    <pureui-spinner class="p-loading" [pureuiShow]="isLoading"></pureui-spinner>
</div>
<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
        <svg class="pstg-action-icon" [hive-svg]="'x-action.svg'" [height]="12"></svg>
    </button>
    <h3 class="modal-title"> You've accepted {{ opportunityQuote?.name | defaultString }} </h3>
</div>

<main class="modal-body">
    <p>This quote is accepted. Next upload a purchase order to complete the service.</p>
    <div class="purchase-order-info">
        <label for="upload-file-input" class="upload-file btn btn-primary">
            <input id="upload-file-input" type="file" accept=".pdf" multiple (change)="onFileChange($event)" />
            Select PO Files
        </label>
    </div>
</main>

<footer class="modal-footer">
    <hive-button emphasis="secondary" label="Cancel" (click)="modal.dismiss()"></hive-button>
    <hive-button
        emphasis="primary"
        label="Upload"
        (click)="onUploadClick()"
        [disabled]="!newFiles?.length"
    ></hive-button>
</footer>
