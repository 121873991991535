import { Observable, switchMap } from 'rxjs';
import { tap, shareReplay, first } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { NgRedux } from './ng-redux.service';
import { WINDOW } from '../app/injection-tokens';
import { REDUX_LOCAL_STORAGE_KEY, ReduxMiddleWares } from './middleware';
import { appReducer } from './reducers';
import { reduxBatch } from '@manaflair/redux-batch';
import {
    DisasterRecoveryJobExecutionStatus,
    DisasterRecoveryPlan,
    DisasterRecoveryTag,
    DisasterRecoveryTagCategory,
} from '@pure1/data';
import { AuthenticationService } from '../services/authentication.service';
import moment from 'moment';
import { DisasterRecoveryReportType } from '../disaster-recovery/monitor/reports/reports/reports.component';

export interface IStateFilterExport {
    entity: string;
    key: string;
    namespace: string;
    value: string;
}

export interface IStateFilter extends IStateFilterExport {
    id: string;
    displayKey?: string;
    displayValue?: string;
}

export interface IStateTime {
    key: string;
    value: string;
}

export interface IStateFiltersSlice {
    [barId: string]: IStateFilter[];
}

export interface IStateSortsSlice {
    [barId: string]: string[];
}

export interface IStatePaginationSlice {
    [barId: string]: number;
}

export interface IStateTimeSlice {
    [barId: string]: IStateTime[];
}

export interface IStateMetricSeriesSlice {
    [barId: string]: string[];
}

export interface IStateAggregationMethodSlice {
    [barId: string]: string;
}

export interface IStateTableMetricsTimeRangeSlice {
    [barId: string]: string;
}

export interface IStateCapacityFullMethodSlice {
    [barId: string]: string;
}

export interface IStateClusterSlice {
    [barId: string]: string;
}

export interface IStateMetricTypeSlice {
    [barId: string]: string;
}

export interface IStateSelectionSlice {
    [barId: string]: string[];
}

export interface IStateGroupSelectionSlice {
    [barId: string]: string;
}

export interface IStateResizerSlice {
    [resizerId: string]: number;
}

export interface IStateFleetType {
    [barId: string]: string;
}

export interface IStateItemType {
    [barId: string]: string;
}

export interface IStateTabSlice {
    [barId: string]: string;
}

export interface IStateRegionSlice {
    [barId: string]: string;
}

export interface IStateDisasterRecoveryToggledItemsSlice {
    [groupId: string]: string[];
}

export interface IStateDisasterRecoverySelectedGroupsViewSlice {
    [barId: string]: 'list-view' | 'card-view';
}

export interface IStateDisasterRecoverySelectedInventoryViewSlice {
    [barId: string]: 'vms-view' | 'tags-view';
}

export interface IStateDisasterRecoverySelectedPolicySlice {
    [barId: string]: string | undefined;
}

export interface IStateDisasterRecoverySelectedPlanSlice {
    [barId: string]: DisasterRecoveryPlan | undefined;
}

export interface IStateDisasterRecoverySelectedReportTypeSlice {
    reportType?: DisasterRecoveryReportType;
}

export interface IStateDisasterRecoverySelectedReportSlice {
    reportId?: string;
}

export interface IStateDisasterRecoveryFiltersSlice {
    [barId: string]: {
        vm?: IStateDisasterRecoveryVmFilterSlice;
        vmJobExecution?: IStateDisasterRecoveryVmJobExecutionFilterSlice;
    };
}

export interface IStateDisasterRecoveryVmFilterSlice {
    name?: string;
    datacenter?: string;
    host?: string;
    search?: string;
    tagCategory?: DisasterRecoveryTagCategory;
    tag?: DisasterRecoveryTag[];
}

export interface IStateDisasterRecoveryVmJobExecutionFilterSlice {
    group?: string;
    status?: DisasterRecoveryJobExecutionStatus;
}

export interface IStateDisasterRecoveryClusterSlice {
    clusterId?: string;
}

export interface IStatePerformanceAlertId {
    [barId: string]: string;
}

export type EdgeServiceAgentCardsCollapseState = 'collapsed' | 'expanded';

export interface IStateEdgeServiceAgentCardsCollapseState {
    [barId: string]: EdgeServiceAgentCardsCollapseState;
}

export interface IStateEntityFocusSlice {
    [barId: string]: [{ key: 'entityId'; value: string }];
}

export enum PanelTabName {
    ORDERS_ACTIVITY_PANEL_TAB_NAME = 'orders_activity_panel',
    EVERGREEN_ACTIVITY_PANEL_TAB_NAME = 'evergreen_activity_panel',
    SUSTAINABILITY_INSIGHTS = 'sustainability_insights',
    SUSTAINABILITY_DATACENTERS = 'sustainability_datacenters',
    DATA_PROTECTION_V2_TAB_NAME = 'data_protection_v2_panel',
    SECURITY_ASSESSMENT_TAB_NAME = 'security_assessment_panel',
}

// Right now, we have no concept of a "local" panel, so we don't need to keep track of barIds.
// May change if we have page specific panel tabs
export interface IStatePanelTabSlice {
    selection: PanelTabName;
}

export interface IStateStepUpTokenSlice {
    [arrayId: string]: {
        accessToken: string;
        expiresAt: moment.Moment | string; // string in ISO 8601 format (Moment -> String export)
    }; // this is returned from localStorage
}

export interface IState {
    filters?: IStateFiltersSlice;
    sorts?: IStateSortsSlice;
    paginations?: IStatePaginationSlice;
    times?: IStateTimeSlice;
    metricSeries?: IStateMetricSeriesSlice;
    aggregationMethod?: IStateAggregationMethodSlice;
    tableMetricsTimeRange?: IStateTableMetricsTimeRangeSlice;
    capacityFullMethod?: IStateCapacityFullMethodSlice;
    selection?: IStateSelectionSlice;
    groupSelection?: IStateGroupSelectionSlice;
    cluster?: IStateClusterSlice;
    metricType?: IStateMetricTypeSlice;
    resizerSize?: IStateResizerSlice;
    fleetType?: IStateFleetType;
    itemType?: IStateItemType;
    tab?: IStateTabSlice;
    edgeServiceAgentCardsCollapseState?: IStateEdgeServiceAgentCardsCollapseState;
    panel?: IStatePanelTabSlice;
    region?: IStateRegionSlice;
    stepUpTokens?: IStateStepUpTokenSlice;
    disasterRecoveryToggledItems?: IStateDisasterRecoveryToggledItemsSlice;
    disasterRecoverySelectedGroupsView?: IStateDisasterRecoverySelectedGroupsViewSlice;
    disasterRecoverySelectedInventoryView?: IStateDisasterRecoverySelectedInventoryViewSlice;
    disasterRecoverySelectedPolicy?: IStateDisasterRecoverySelectedPolicySlice;
    disasterRecoverySelectedPlan?: IStateDisasterRecoverySelectedPlanSlice;
    disasterRecoverySelectedReportType?: IStateDisasterRecoverySelectedReportTypeSlice;
    disasterRecoverySelectedReport?: IStateDisasterRecoverySelectedReportSlice;
    disasterRecoveryCluster?: IStateDisasterRecoveryClusterSlice;
    disasterRecoveryFilters?: IStateDisasterRecoveryFiltersSlice;
    performanceAlertId?: IStatePerformanceAlertId;
}

@Injectable({ providedIn: 'root' })
export class PureRedux {
    private init$: Observable<void>;

    constructor(
        private ngRedux: NgRedux<IState>,
        private middlewares: ReduxMiddleWares,
        @Inject(WINDOW) private window: Window,
        private authenticationService: AuthenticationService,
    ) {}

    /**
     * Returns an observable that completes when redux has been configured.
     * Redux gets configured only once, and so this will normally return an observable that will emit immediately.
     */
    init(): Observable<void> {
        if (!this.init$) {
            this.init$ = this.authenticationService.basekeyGenerated$.pipe(
                first(),
                switchMap(_ => this.authenticationService.isLoggedIn()),
                tap(isLoggedIn => {
                    // Only configure redux if logged in
                    if (isLoggedIn) {
                        this.configureRedux();
                    }
                }),
                shareReplay<void>(1),
            );
        }
        return this.init$;
    }

    private configureRedux(): void {
        let initialState: IState = {};
        if (this.window.localStorage) {
            const value = this.window.localStorage[`${this.window.pure1.basekey}:${REDUX_LOCAL_STORAGE_KEY}`];
            if (value) {
                try {
                    initialState = JSON.parse(value);
                } catch (e) {
                    console.error('Error occurred parsing redux state from local storage', e);
                }
            }
        }
        this.ngRedux.configureStore(
            appReducer,
            initialState,
            [this.middlewares.consoleLogger, this.middlewares.localStorage],
            [<any>reduxBatch],
        );
    }
}
