import { Directive, Input, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizationService, Permission } from '@pure/authz-authorizer';

@Directive({
    selector: 'a[validateHref]',
})
export class ValidateHrefDirective implements OnInit, OnDestroy {
    @Input('validateHref') readonly resource: Permission;

    // You might be wondering why this directive just applies a CSS style and doesn't modify href directly.
    // We like to use [uiSref] in a lot of places, which generates the href on click!
    // And, it doesn't trigger change detection at all, so we can't capture it with ngOnChanges.
    // Thus, while the first href removal may succeed, any subsequent clicks will just reattach the link.
    // So, we just add a CSS style disabling pointer events (like click and hover). If the user disables these,
    // we also validate and reject upon page transition in the router. So, this is effectively a cosmetic directive.
    @HostBinding('class.invalidate-click') invalidateClick: boolean;

    private readonly destroy$ = new Subject<void>();

    constructor(private authorizationService: AuthorizationService) {}

    ngOnInit(): void {
        this.authorizationService
            .isAllowed(this.resource)
            .pipe(takeUntil(this.destroy$))
            .subscribe(allowed => {
                this.invalidateClick = !allowed;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
