import moment from 'moment-timezone';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '../../../../app/injection-tokens';
import { Timeslot, VersionedArray } from '../../types';
import { CduAppliance } from 'core/src/software-lifecycle/purity-upgrades/purity-upgrades.interface';

@Component({
    selector: 'upgrade-timeslot-picker',
    templateUrl: './upgrade-timeslot-picker.component.html',
})
export class UpgradeTimeslotPickerComponent implements OnChanges, OnInit {
    @Input() day: NgbDateStruct; // TODO: Make "readonly". Inputs should not be assigned to.
    @Input() readonly control: UntypedFormControl;
    @Input() readonly timezone: string;
    @Input() readonly scrollToTimeslot: Timeslot;
    /** These need to be sorted by startTime */
    @Input() readonly sortedTimeslots: Timeslot[];
    @Input() readonly selectedTimeSlots: { [id: string]: Timeslot };
    @Input() readonly arrays: VersionedArray[];
    @Input() readonly selectedArray: VersionedArray;
    @Input() readonly replicationTargets: { [applianceId: string]: CduAppliance };
    @Output() readonly dayChange = new EventEmitter<NgbDateStruct>();

    timeslots$: Observable<Timeslot[]>;

    private refreshTimeslots$: Subject<moment.Moment>;

    constructor(@Inject(WINDOW) private window: Window) {}

    ngOnInit(): void {
        this.refreshTimeslots$ = new BehaviorSubject<moment.Moment>(this.getDayAsMoment(this.timezone));

        this.timeslots$ = this.refreshTimeslots$.pipe(
            switchMap(day => {
                // Send "null" first to indicate that this is loading
                return concat(of(null), this.getTimeslotsForDay(day, this.timezone));
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.refreshTimeslots$) {
            if (changes.day || changes.timezone || changes.sortedTimeslots) {
                this.refreshTimeslots$.next(this.getDayAsMoment(this.timezone));
            }
        }

        if (changes.scrollToTimeslot?.currentValue) {
            const element = this.window.document.getElementById(
                changes.scrollToTimeslot.currentValue.startTime.valueOf(),
            );

            if (element) {
                element.parentElement.scrollIntoView();
            }
        }
    }

    printDate(): string {
        return this.getDayAsMoment(this.timezone).format('LL');
    }

    addDays(days: number): void {
        const next = this.getDayAsMoment(this.timezone).add(days, 'days');
        this.day = {
            year: next.year(),
            month: next.month() + 1,
            day: next.date(),
        };
        this.dayChange.emit(this.day);
    }

    trackByIndex(index: number): number {
        return index;
    }

    printPrettyTime(time: moment.Moment): string {
        // Reason of using moment instead of DatePipe is because
        // DatePipe only accept number offset for the timezone, it cannot auto-adjust for daylight saving time
        return time.tz(this.timezone).format('HH:mm');
    }

    getDurationFactor(): number {
        const durationInHours = Math.ceil(this.selectedArray.upgradeDurationSeconds / 3600);
        if (!durationInHours) {
            return 1;
        }
        if (this.replicationTargets[this.selectedArray.array.applianceId]) {
            return 2 * durationInHours;
        }
        return durationInHours;
    }

    getSelectedArrayIndex(): number {
        if (!this.selectedArray) {
            return 0;
        }
        return this.arrays.findIndex(arr => arr.array.applianceId === this.selectedArray.array.applianceId);
    }

    timeSlotHasRemainingCapacity(timeslot: Timeslot): boolean {
        if (this.arrays.length > 0) {
            const arrayCountForTimeslot = this.arrays.reduce(
                (accumulator, current) => {
                    if (current.array.applianceId === this.selectedArray.array.applianceId) {
                        return accumulator;
                    } else {
                        return this.isArrayInTimeSlot(current, timeslot) ? accumulator + 1 : accumulator;
                    }
                },

                0,
            );
            return arrayCountForTimeslot < timeslot.capacity;
        }

        // this is for single array upgrade creation
        return true;
    }

    isArrayInTimeSlot(array: VersionedArray, timeslot: Timeslot): boolean {
        const arrayTimeSlot = this.selectedTimeSlots[array.array.applianceId];
        return arrayTimeSlot && this.checkTimeSlotsOverlap(arrayTimeSlot, timeslot);
    }

    isCurrentArrayInTimeslot(timeslot: Timeslot): boolean {
        //to support old single upgrade case scheduler (which should be removed) - we dont pass VersionedArray there but something else that has applianceId
        const arrayID = this.selectedArray.array
            ? this.selectedArray.array.applianceId
            : this.selectedArray['applianceId'];
        const arrayTimeSlot = this.selectedTimeSlots[arrayID];
        if (arrayTimeSlot) {
            return arrayTimeSlot.startTime.isSame(timeslot.startTime);
        }
        return false;
    }

    checkTimeSlotsOverlap(arraySlot: Timeslot, randomSlot: Timeslot): boolean {
        // Get Start and End values for array timeslot
        const arraySlotStart = arraySlot.startTime.valueOf();
        const arraySlotEnd = arraySlot.startTime.valueOf() + arraySlot.duration.asMilliseconds();

        // Get Start and End values for random timeslot
        const randomSlotStart = randomSlot.startTime.valueOf();
        const randomSlotEnd = randomSlot.startTime.valueOf() + randomSlot.duration.asMilliseconds();

        // Multiple conditions can occur so the timeslots can overlap
        // #1 | array-slot and random-slot start at the same time
        const one = arraySlotStart === randomSlotStart;
        // #2 | array-slot and random-slot end at the same time
        const two = arraySlotEnd === randomSlotEnd;
        // #3 | array-slot starts before random-slot starts and array-slot ends after random-slot ends (3 or more hour array slot and 1 hour or more random slot)
        const three = arraySlotStart < randomSlotStart && arraySlotEnd > randomSlotEnd;

        // Final result if slots are overlapping is if one of the previous conditions is true
        return one || two || three;
    }

    getEndTime(startTime: moment.Moment): moment.Moment {
        const duration = this.getDurationFactor();
        return moment(startTime).add(duration, 'hours');
    }

    private getDayAsMoment(timezone: string): moment.Moment {
        return moment.tz(
            {
                year: this.day.year,
                month: this.day.month - 1,
                day: this.day.day,
            },
            timezone,
        );
    }

    private getTimeslotsForDay(day: moment.Moment, timezone: string): Observable<Timeslot[]> {
        const startTime = moment.tz(day, this.timezone);
        const endTime = startTime.clone().add(1, 'days');

        if (!this.sortedTimeslots) {
            return of([]);
        }
        return Observable.create(obs => {
            const todayTimeslots: Timeslot[] = [];
            for (const timeslot of this.sortedTimeslots) {
                if (timeslot.startTime.isSameOrAfter(startTime) && timeslot.startTime.isBefore(endTime)) {
                    todayTimeslots.push(timeslot);
                }
            }
            obs.next(todayTimeslots);
        });
    }
}
