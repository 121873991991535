<div class="modal-header">
    <button
        type="button"
        class="close modal-x-button pull-right"
        (click)="modal.dismiss()"
        angulartics2On="click"
        angularticsAction="UM - Delete view - cancel"
        angularticsCategory="Action"
    >
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title delete-configuration-title"
        >Delete: <span class="view-name">{{ view.name }}</span></h3
    >
</div>

<div class="modal-body">
    <div class="view-name"> {{ view.name }} </div>
    <view-selector class="view-filter" [selector]="view.selector"></view-selector>
    <div *ngIf="assignments !== undefined">
        <div *ngIf="assignments.length > 0; else noAssignments" class="delete-message">
            This view is assigned to {{ assignments.length }} {{ isUsers() ? 'user(s)' : 'group(s)' }}, are you sure you
            want to delete?
            <div
                class="toggle-table-link"
                (click)="toggleTableVisibility()"
                angulartics2On="click"
                angularticsAction="UM - Delete view - toggle assignment table visibility"
                angularticsCategory="Action"
            >
                View {{ isUsers() ? 'Users' : 'Groups' }}
                <pureui-svg
                    class="toggle-icon"
                    svg="chevron-up.svg"
                    height="10"
                    [ngClass]="{ visible: tableVisible }"
                ></pureui-svg>
            </div>
            <div [hidden]="!tableVisible" class="table-fixed-header-container">
                <table
                    class="assignees-table table table-bordered table-fixed-header"
                    [pureshadowscroll]="{ scrollElem: 'tbody', shadowElem: 'thead' }"
                >
                    <thead class="thead-default">
                        <tr>
                            <th class="weight-4">
                                <div class="column-group-title"> Name </div>
                            </th>
                            <th class="weight-4">
                                <div class="column-group-title"> Role </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="assignee-row"
                            *ngFor="let assignee of assignments || []; trackBy: 'id' | trackByProperty"
                        >
                            <td class="weight-4">
                                <div class="vertical">
                                    {{ assignee.name }}
                                </div>
                            </td>
                            <td class="weight-4">
                                <div class="vertical">
                                    {{ assignee.role | roleType }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ng-template #noAssignments>
            <div class="delete-message">Are you sure you want to delete?</div>
        </ng-template>
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-info"
        style="margin-right: 15px"
        [disabled]="loading"
        (click)="modal.close()"
        angulartics2On="click"
        angularticsAction="UM - Delete view - cancel"
        angularticsCategory="Action"
    >
        Cancel
    </button>
    <button
        class="btn btn-danger delete-view-button"
        [disabled]="('PURE1:write:view' | isNotAllowed) || loading"
        (click)="deleteView()"
        angulartics2On="click"
        [angularticsAction]="'UM - Delete view - ' + (assignments?.length > 0 ? 'with assignees' : 'no assignees')"
        angularticsCategory="Action"
        [angularticsLabel]="assignments?.length + ''"
    >
        <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
        Delete <span *ngIf="assignments?.length > 0"> & Remove {{ isUsers ? 'Users' : 'Groups' }}</span>
    </button>
</div>
