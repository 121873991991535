import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const INTERNAL_ENDPOINT = '/rest/v1/internal';

@Injectable({ providedIn: 'root' })
export class PureInternalNetworkService {
    constructor(private http: HttpClient) {}

    trackImpersonateUser(): Observable<{}> {
        return this.http.patch<{}>(`${INTERNAL_ENDPOINT}/impersonate`, {});
    }

    switchImpersonateUser(email: string): Observable<{}> {
        return this.http.put<{}>(`${INTERNAL_ENDPOINT}/impersonate/${email}`, {});
    }

    impersonateHistory(): Observable<string[]> {
        return this.http.get<string[]>(`${INTERNAL_ENDPOINT}/impersonate/history`);
    }
}
