import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArrayContractStatus, FeatureFlagDxpService, UnifiedArray } from '@pure1/data';
import { take } from 'rxjs/operators';
import { FArray, FBlade, FeatureNames } from '../../model/model';

@Component({
    selector: 'array-card-header',
    templateUrl: 'array-card-header.component.html',
})
export class ArrayCardHeaderComponent implements OnInit {
    @Input() readonly array: UnifiedArray & FArray & FBlade;
    @Input() readonly alerts: IAlert[];
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Output() readonly onFlipCard = new EventEmitter<{ event: Event }>();

    readonly ArrayContractStatusExpired = ArrayContractStatus.EXPIRED;
    hwEOLEnabled = false;

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.hwEOLEnabled = feature?.enabled === true;
            });
    }

    flipCard(event: Event): void {
        if (
            this.showWarningCardForOutOfSupportAppliance &&
            this.array.contract_status === this.ArrayContractStatusExpired
        ) {
            return;
        }
        this.onFlipCard.emit({ event: event });
    }

    hasEolComponents(): boolean {
        return this.hwEOLEnabled && this.array.has_end_of_life_hardware;
    }

    hasLongHostname(): boolean {
        // Array name in card view with 15px font-size starts to truncate on the 24th character
        return this.array.hostname.length > 23;
    }
}
