import _ from 'lodash';
import moment from 'moment';
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    convertToAmpliQuoteRequestConfig,
    License,
    LicenseType,
    Partner,
    PartnerRequest,
    QuoteType,
    ServiceCatalogGroup,
    ServiceCatalogQuote,
    ServiceCatalogQuoteServiceV3,
    Subscription,
} from '@pure1/data';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';
import { ampli } from '../../ampli';

import { waitUntilDefined } from '../../utils/dom';
import { emailRegex } from '../../utils/strings';
import { FormatTiBPipe } from '../../ui/pipes/format-tib.pipe';
import { isServiceTierSupported } from '../subscription-utils/subscription-utils';
import { ServiceCatalogOfferingsService } from '../../service-catalog/services/service-catalog-offerings.service';
import { OrdersUpdateService } from '../../service-catalog/services/orders-update.service';
import { QuoteManagementService } from '../services/quote-management.service';
import { PartnerInfoService } from '../../ui/components/partner-info/partner-info.service';

type QuoteProductParam = {
    full_product_name: string;
    group_name: string;
    product_name: string;
    product_sku: string;
    quoting_option_name: string;
    service_name: string;
};
type RenewalTermOption = {
    termInMonths: number;
    displayText: string;
};

const ALLOWED_RENEWAL_TERMS_IN_MONTHS = [12, 24, 36];

const DEFAULT_MIN_RESERVE_IN_TIB = 50;
const MIN_RESERVE_IN_TIB_IF_EXISTING_SUBSCRIPTION = 1;

@Component({
    selector: 'manage-subscription-modal',
    templateUrl: './manage-subscription-modal.component.html',
})
export class ManageSubscriptionModalComponent implements OnInit {
    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly subscription: Subscription;
    @Input() readonly licenses: License[];
    @Input() readonly isEditOrder: boolean = false; //Default value is false, except from Order Page
    @Input() readonly quoteBeingEdited: ServiceCatalogQuote = null; // if this is set then modal assumes it's editing an existing quote

    @ViewChild('modalBody') readonly modalBodyElement: ElementRef;
    @ViewChild('requestConfirmedModal') readonly requestConfirmedModal: TemplateRef<any>;

    readonly maxCommentLength = 3000;

    existingLicenses: License[] = [];
    increaseReserved: number[] = [];
    totalCurrentReserved: number;
    newLicenses: License[] = [];
    agreedOnTermsAndContracts = false;
    licenseType: LicenseType;
    reservedUnit: string;
    additionalInstructionsText = '';
    additionalEmails = '';
    isLoading = false;
    hasError = false;
    validationTooltip = '';
    renewalTermOptions: RenewalTermOption[] = [];
    selectedRenewalOption: number;
    isQuoteRenewal = false;
    partnersInfos: Partner[];
    partnerRequest: PartnerRequest;

    private readonly analyticsPrefix = 'Expansion Modal - ';
    private offeringsInGroups: ServiceCatalogGroup[];

    constructor(
        private el: ElementRef<HTMLElement>,
        private angulartics2: Angulartics2,
        private partnerInfoService: PartnerInfoService,
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV3,
        private serviceCatalogOfferingsService: ServiceCatalogOfferingsService,
        private ordersUpdateService: OrdersUpdateService,
        private quoteManagementService: QuoteManagementService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.isQuoteRenewal = this.quoteBeingEdited?.quoteType === QuoteType.RENEWAL;
        this.existingLicenses = this.licenses.map(x => Object.assign({}, x));
        this.existingLicenses.forEach(existingLicense => (existingLicense.startDate = null)); // this will be filled later if it's an edit
        this.increaseReserved = new Array(this.existingLicenses.length);
        this.reservedUnit = this.licenses[0].licenseType !== LicenseType.VMA ? 'TiB' : 'VM';
        this.licenseType = this.licenses[0].licenseType;
        this.totalCurrentReserved = this.existingLicenses.map(x => x.reservedAmount).reduce((a, b) => a + b, 0);
        this.isLoading = true;

        // prefetch offerings info for new products
        this.serviceCatalogOfferingsService.offeringsInGroups$.pipe(take(1)).subscribe(oInGroups => {
            this.offeringsInGroups = oInGroups;
        });

        this.partnerInfoService.partnersInfos$.pipe(take(1)).subscribe(data => {
            this.partnersInfos = data;
            if (this.partnersInfos?.length > 0) {
                this.partnerRequest = new PartnerRequest({
                    account_name: this.partnersInfos[0].accountName,
                    contact_name: this.partnersInfos[0].contactName,
                    contact_email: this.partnersInfos[0].contactEmail,
                    contact_phone_number: '',
                });
            }
            this.isLoading = false;
        });

        // populate with existing requirements
        if (this.quoteBeingEdited) {
            this.quoteBeingEdited.licenseUpdates?.forEach(licenseUpdate => {
                if (licenseUpdate.newSite) {
                    const newLicense = Object.assign({}, this.licenses[0]);
                    newLicense.id = null;
                    newLicense.name = licenseUpdate.siteName;
                    newLicense.licenseType = licenseUpdate.licenseType;
                    newLicense.reservedAmount =
                        this.reservedUnit === 'TiB'
                            ? FormatTiBPipe.fromTiB(licenseUpdate.additionalAmount)
                            : licenseUpdate.additionalAmount;
                    newLicense.licenseAssets = [];
                    newLicense.startDate = licenseUpdate.startDate;
                    newLicense.endDate = null;
                    this.newLicenses.push(newLicense);
                } else {
                    const indexInExistingLicense = this.existingLicenses.findIndex(existingLicense => {
                        return existingLicense.id === licenseUpdate.id;
                    });
                    if (indexInExistingLicense > -1) {
                        this.increaseReserved[indexInExistingLicense] =
                            this.reservedUnit === 'TiB'
                                ? FormatTiBPipe.fromTiB(licenseUpdate.additionalAmount)
                                : licenseUpdate.additionalAmount;
                        this.existingLicenses[indexInExistingLicense].startDate = licenseUpdate.startDate;
                    }
                }
            });
            this.additionalInstructionsText = this.quoteBeingEdited.comment || '';

            // generate renewal terms for subscription renewal and set default to first option
            if (this.isQuoteRenewal) {
                this.renewalTermOptions = ALLOWED_RENEWAL_TERMS_IN_MONTHS.map(termInMonths => {
                    // default to just adding months to 'now' just in case subscription endDate is for some really obscure reason not specified
                    // then check if subscription endDate exists and use that if available
                    let renewalEndDate = moment().add(termInMonths, 'month').utc().format('YYYY-MM-DD');
                    if (this.subscription?.endDate) {
                        const endDate = this.subscription.endDate.clone();
                        renewalEndDate = endDate.add(termInMonths, 'month').utc().format('YYYY-MM-DD');
                    }
                    return { termInMonths: termInMonths, displayText: renewalEndDate };
                });
                this.selectedRenewalOption =
                    this.quoteBeingEdited.termInMonth &&
                    ALLOWED_RENEWAL_TERMS_IN_MONTHS[0] <= this.quoteBeingEdited?.termInMonth &&
                    this.quoteBeingEdited?.termInMonth <=
                        ALLOWED_RENEWAL_TERMS_IN_MONTHS[ALLOWED_RENEWAL_TERMS_IN_MONTHS.length - 1]
                        ? this.quoteBeingEdited?.termInMonth
                        : this.renewalTermOptions[0].termInMonths;
            }
        }
    }

    totalIncreased(): number {
        return (
            this.increaseReserved.reduce((a, b) => a + b, 0) +
            this.newLicenses.map(x => x.reservedAmount).reduce((a, b) => a + b, 0)
        );
    }

    addLicense(): void {
        if (!this.newLicensesDataValid()) {
            return;
        }
        const newLicense = Object.assign({}, this.licenses[0]);
        newLicense.id = '';
        newLicense.name = '';
        if (isServiceTierSupported(this.subscription.programType)) {
            newLicense.licenseType = null; // no default license type
        }
        newLicense.reservedAmount = this.subscription
            ? FormatTiBPipe.fromTiB(MIN_RESERVE_IN_TIB_IF_EXISTING_SUBSCRIPTION)
            : FormatTiBPipe.fromTiB(DEFAULT_MIN_RESERVE_IN_TIB);
        newLicense.licenseAssets = [];
        newLicense.startDate =
            this.quoteBeingEdited?.quoteType === QuoteType.RENEWAL ? this.subscription.endDate : null;
        newLicense.endDate = null;
        this.newLicenses.push(newLicense);

        waitUntilDefined(
            () =>
                this.el.nativeElement.querySelector(
                    `new-license:nth-child(${this.licenses.length + this.newLicenses.length}`,
                ) as HTMLElement,
        ).then(() => {
            this.modalBodyElement.nativeElement.scrollTop = this.modalBodyElement.nativeElement.scrollHeight;
        });
        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + `Added a new license`,
            properties: { category: 'Action', label: `Add new license` },
        });
    }

    deleteNewLicense(index: number): void {
        if (this.newLicenses.length > index) {
            this.newLicenses.splice(index, 1);
        }
    }

    onLicenseChange(license: License, index: number): void {
        this.newLicenses[index] = license;
    }

    onPartnerRequestChanged(newPartnerInfo: PartnerRequest): void {
        this.partnerRequest = newPartnerInfo;
    }

    isValid(): boolean {
        let newValidationTooltip = '';

        if (!this.agreedOnTermsAndContracts) {
            newValidationTooltip += 'Please accept the End User Agreement. ';
        }
        if (!this.newLicensesDataValid() || !this.existingLicensesDataValid()) {
            newValidationTooltip += 'All fields are required unless otherwise stated. ';
        }
        if (
            this.totalIncreased() < 1 &&
            (!this.quoteBeingEdited || this.quoteBeingEdited.quoteType !== QuoteType.RENEWAL)
        ) {
            newValidationTooltip += 'Total space increase cannot be 0. ';
        }

        if (!this.emailStringValid()) {
            newValidationTooltip += 'Error or duplicates in additional emails entries. ';
        }

        this.validationTooltip = newValidationTooltip.trim();
        return !this.isLoading && newValidationTooltip === '';
    }

    requestAQuote(): void {
        this.isLoading = true;
        this.hasError = false;
        const params = this.quoteManagementService.generateParamsForQuote(
            this.increaseReserved,
            this.existingLicenses,
            this.newLicenses,
            this.subscription,
            this.offeringsInGroups,
            this.additionalInstructionsText,
            this.additionalEmails,
            this.quoteBeingEdited,
            this.reservedUnit,
        );
        params.partnerRequest = this.partnerRequest;
        //triggering Amplitude events to track quote requested actions
        ampli.quoteRequested({
            'quote requested from': 'subscription page',
            'requested configuration': convertToAmpliQuoteRequestConfig(params.programType, params.quoteType),
        });
        if (this.quoteBeingEdited) {
            // if quoteBeingEdited is set, it means the customers are editing a quote or modifying the renewal order, so use update/PATCH
            params.id = this.quoteBeingEdited.id;
            if (this.isQuoteRenewal) {
                params.termInMonth = this.selectedRenewalOption;
            }
            this.serviceCatalogQuoteService
                .update(params)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.ordersUpdateService.refreshQuotes$.next(null);
                        this.activeModal.close();
                        this.isLoading = false;
                        this.modalService.open(this.requestConfirmedModal, { windowClass: 'request-confirmed-modal' });

                        this.angulartics2.eventTrack.next({
                            action:
                                this.analyticsPrefix + 'Edit ' + this.quoteBeingEdited.quoteType === QuoteType.EXPANSION
                                    ? 'Expansion'
                                    : 'Renewal' + ' Submission Success',
                            properties: {
                                category: 'Action',
                                label: `submit ${this.subscription.programType}, new license count: ${this.newLicenses.length}`,
                                value: `${this.totalIncreased()}`,
                            },
                        });
                    },
                    error: error => {
                        this.isLoading = false;
                        this.hasError = true;
                        console.error('error submitting service catalog quote', error);

                        this.angulartics2.eventTrack.next({
                            action:
                                this.analyticsPrefix + 'Edit ' + this.quoteBeingEdited.quoteType === QuoteType.EXPANSION
                                    ? 'Expansion'
                                    : 'Renewal' + ' Submission Error',
                            properties: {
                                category: 'Action',
                                label: `submit ${this.subscription.programType}, new license count: ${this.newLicenses.length}`,
                                value: `${this.totalIncreased()}`,
                            },
                        });
                    },
                });
        } else {
            this.serviceCatalogQuoteService
                .create(params)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.ordersUpdateService.refreshQuotes$.next(null);
                        this.activeModal.close();
                        this.isLoading = false;
                        this.modalService.open(this.requestConfirmedModal, { windowClass: 'request-confirmed-modal' });

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + `Request Expansion Submission Success`,
                            properties: {
                                category: 'Action',
                                label: `submit ${this.subscription.programType}, new license count: ${this.newLicenses.length}`,
                                value: `${this.totalIncreased()}`,
                            },
                        });
                    },
                    error: error => {
                        this.isLoading = false;
                        this.hasError = true;
                        console.error('error submitting service catalog quote', error);

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + `Request Expansion Submission Error`,
                            properties: {
                                category: 'Action',
                                label: `submit ${this.subscription.programType}, new license count: ${this.newLicenses.length}`,
                                value: `${this.totalIncreased()}`,
                            },
                        });
                    },
                });
        }
    }

    getManagementModalTitle(): string {
        if (!this.quoteBeingEdited) {
            return 'Expansion Order';
        }

        if (this.quoteBeingEdited.quoteType === QuoteType.RENEWAL) {
            return 'Request Renewal for';
        }

        return 'Edit Expansion Order';
    }

    getManagementModalSubmitButtonLabel(): string {
        if (!this.quoteBeingEdited) {
            return 'Submit';
        }
        if (this.quoteBeingEdited.quoteType === QuoteType.RENEWAL) {
            return 'Submit';
        }

        return 'Override Existing Order';
    }

    newLicensesDataValid(): boolean {
        return (
            this.newLicenses.length === 0 ||
            (this.newLicenses.every(
                license =>
                    license.name &&
                    license.reservedAmount &&
                    !Number.isNaN(license.reservedAmount) &&
                    license.licenseType &&
                    license.startDate,
            ) &&
                _.uniq(this.newLicenses.map(license => license.name)).length === this.newLicenses.length)
        );
    }

    private existingLicensesDataValid(): boolean {
        let isValid = true;
        this.increaseReserved.map((increaseAmount, index) => {
            if (increaseAmount && (!this.quoteBeingEdited || this.quoteBeingEdited.quoteType !== QuoteType.RENEWAL)) {
                const startDate = this.existingLicenses[index]?.startDate;
                if (!startDate || startDate.valueOf() < this.subscription.startDate.valueOf()) {
                    isValid = false;
                }
            }
        });

        return isValid;
    }

    private hasUpdatedExistingLicenses(): boolean {
        const reserveChanged = this.increaseReserved.some(n => n !== null && n > 0);
        const otherQuotePropertiesChanged =
            this.quoteBeingEdited && this.additionalInstructionsText !== this.quoteBeingEdited?.comment;
        return reserveChanged || otherQuotePropertiesChanged;
    }

    private emailStringValid(): boolean {
        if (!this.additionalEmails) {
            return true;
        }
        const tokenizedEmails = this.additionalEmails.split(',').map(token => token.trim());
        const errorInEmailsString = tokenizedEmails.some(email => !emailRegex.test(email));
        const errorDuplicateEmails =
            tokenizedEmails.filter((item, index) => tokenizedEmails.indexOf(item) !== index).length > 0;

        return !errorInEmailsString && !errorDuplicateEmails;
    }

    private convertAmount(value: number): number {
        if (this.reservedUnit === 'TiB') {
            return FormatTiBPipe.toTiB(value);
        } else {
            return value;
        }
    }
}
