<div class="step-up-modal-header">
    <div class="title"> Link Your Pure1 Account </div>
</div>
<div class="step-up-modal-body">
    <div>Open your authenticator app and scan this QR code</div>
    <qrcode *ngIf="totpFactor; else enrollNotComplete" [qrdata]="totpFactor.profile.barcodeUri" [width]="128"></qrcode>
    <ng-template #enrollNotComplete>
        <div class="enroll-not-complete">
            <div class="totp-error-message" *ngIf="enrollErrorMessage; else loadingSpinner">{{
                enrollErrorMessage
            }}</div>
            <ng-template #loadingSpinner>
                <pureui-spinner class="p-loading" [pureuiShow]="!enrollErrorMessage"></pureui-spinner>
            </ng-template>
        </div>
    </ng-template>
    <div *ngIf="totpFactor">
        <div>Or type the code: </div>
        <div class="totp-secret">{{ totpFactor.profile.secret }}</div>
    </div>
    <div>Enter the code generated by your authenticator app.</div>
    <div class="passcode-area">
        <div class="totp-error-message" *ngIf="activateErrorMessage">{{ activateErrorMessage }}</div>
        <totp-passcode-input
            [hasError]="activateErrorMessage != null"
            (valueChanged)="inputValueChanged($event)"
        ></totp-passcode-input>
    </div>

    <button
        class="btn btn-primary submit-button"
        (click)="activate()"
        [disabled]="submitDisabled()"
        angulartics2On="click"
        angularticsAction="Step Up - Activation Modal - submit passcode"
        angularticsCategory="Action"
    >
        Link Account
    </button>

    <div class="text">
        Need help?
        <a
            (click)="goToSupport()"
            angulartics2On="click"
            angularticsAction="Step Up - Activation Modal - contact support"
            angularticsCategory="Action"
        >
            Contact Pure Technical Services
        </a>
    </div>
</div>
<div class="step-up-modal-footer">
    <a
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Step Up - Activation Modal - cancel"
        angularticsCategory="Action"
        >Cancel</a
    >
</div>
