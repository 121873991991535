import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';

import { UIModule } from '../ui/ui.module';

import { FeatureBannerComponent } from './feature-banner/feature-banner.component';
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';
import { NpsSurveyBannerComponent } from './nps-survey-banner/nps-survey-banner.component';
import { SafeModeBannerComponent } from './safemode-banner/safemode-banner.component';
import { WarningBannerComponent } from './warning-banner/warning-banner.component';
import { CduBannerComponent } from './cdu-banner/cdu-banner.component';
import { DpaBannerComponent } from './dpa-banner/dpa-banner.component';
import { XToXlUpgradeBannerComponent } from './x-to-xl-upgrade-banner/x-to-xl-upgrade-banner.component';
import { AutoOnSafeModeBannerComponent } from './auto-on-safe-mode-banner/auto-on-safe-mode-banner.component';
import { EolWarningCarouselBannerComponent } from './eol-warning-carousel-banner/eol-warning-carousel-banner.component';
import { DrrBannerComponent } from './drr-banner/drr-banner.component';
import { SdfcUpdateBannerComponent } from './sdfc-update-banner/sdfc-update-banner.component';
import { EnhancedSafemodeBannerComponent } from './enhanced-safemode-banner/enhanced-safemode-banner.component';
import { RouterModule } from '@angular/router';
import { SafemodeMobileBannerComponent } from './safemode-mobile-banner/safemode-mobile-banner.component';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { OpportunityCreationBanner } from './opportunity-creation-banner/opportunity-creation-banner.component';
import { OptimizeCapacityBannerComponent } from './optimize-capacity-banner/optimize-capacity-banner.component';
import { Pure1AppLinkPipe } from '@pure/pure1-ui-platform-angular';
import { PrefillGoogleFormDirective } from '../ui/directives/prefill-google-form.directive';

@NgModule({
    imports: [
        Angulartics2Module,
        AuthorizerModule,
        CommonModule,
        NgbModule,
        UIModule,
        RouterModule,
        Pure1AppLinkPipe,
        PrefillGoogleFormDirective,
    ],
    declarations: [
        BannerCarouselComponent,
        FeatureBannerComponent,
        NpsSurveyBannerComponent,
        SafeModeBannerComponent,
        WarningBannerComponent,
        CduBannerComponent,
        DpaBannerComponent,
        AutoOnSafeModeBannerComponent,
        XToXlUpgradeBannerComponent,
        EolWarningCarouselBannerComponent,
        DrrBannerComponent,
        SdfcUpdateBannerComponent,
        EnhancedSafemodeBannerComponent,
        SafemodeMobileBannerComponent,
        OpportunityCreationBanner,
        OptimizeCapacityBannerComponent,
    ],
    exports: [BannerCarouselComponent, SafemodeMobileBannerComponent],
})
export class SpotlightBannerModule {}
