import { Observable } from 'rxjs';
import { ViewReference } from '@pure1/data';

export interface Assignee {
    id: string;
    name: string;
    admin?: boolean;
}

type ViewAssignable = { view: ViewReference };
type SimpleFilterAssignable = { selector: string };

export type Assignable = ViewAssignable | SimpleFilterAssignable;

export type Assignment = Assignee & Assignable;

export function isViewAssignment(assignment: Assignment): assignment is Assignee & ViewAssignable {
    // ensure view is not null or undefined
    return !!(<ViewAssignable>assignment).view;
}

export function isSimpleFilterAssignment(assignment: Assignment): assignment is Assignee & SimpleFilterAssignable {
    return isSimpleFilterAssignable(assignment);
}

export function isSimpleFilterAssignable(assignable: Assignable): assignable is SimpleFilterAssignable {
    // ensure selector is not null or undefined
    return (
        (<SimpleFilterAssignable>assignable).selector !== null &&
        (<SimpleFilterAssignable>assignable).selector !== undefined
    );
}

export type DeferredAssignable = {
    isSimpleFilter: boolean;
    name: string; // name is used to display the assignable
    selector: string;
    assignable$: Observable<Assignable>; // the actual observable for creating the assignable
};
