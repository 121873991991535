<div class="modal-header">
    <button
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Close"
        angularticsCategory="Action"
        class="close modal-x-button pull-right"
        type="button"
    >
        <pureui-svg class="pstg-action-icon" height="12" svg="x-action.svg"></pureui-svg>
    </button>
    <div class="modal-title">Introducing Pure Identity and Access Management (IAM)</div>
</div>

<div class="modal-body">
    <div class="introducing-identity-center-dialog-content">
        <div class="introducing-identity-center-card">
            <img
                alt="Navigate to Pure IAM"
                class="introducing-identity-center-dialog-content-image"
                src="/images/introducing-identity-center-1.png"
            />
            <p class="hive-paragraph-semibold hive-paragraph-large introducing-identity-center-card-title">
                1. Navigate to Pure IAM
            </p>
            <p class="hive-paragraph-regular hive-paragraph-large">
                Use the Application Switcher in Pure1 Manage to access Pure IAM.
            </p>
        </div>
        <div class="introducing-identity-center-card">
            <img
                alt="Launch Pure IAM"
                class="introducing-identity-center-dialog-content-image"
                src="/images/introducing-identity-center-2.png"
            />
            <p class="hive-paragraph-semibold hive-paragraph-large introducing-identity-center-card-title">
                2. Launch Pure IAM
            </p>
            <p class="hive-paragraph-regular hive-paragraph-large">
                New Pure Identity and Access Management application is designed to secure and streamline user access
                within a company. It features user provisioning, role-based access control, multi-factor authentication,
                and single sign-on.
            </p>
        </div>
        <div class="introducing-identity-center-card">
            <img
                alt="Manage Identities and Access"
                class="introducing-identity-center-dialog-content-image"
                src="/images/introducing-identity-center-3.png"
            />
            <p class="hive-paragraph-semibold hive-paragraph-large introducing-identity-center-card-title">
                3. Manage Identities and Access
            </p>
            <ul class="regular large introducing-identity-center-identity-list">
                <li>Identity Providers (SSO)</li>
                <li>Users</li>
                <li>User Groups</li>
                <li>API Keys</li>
            </ul>
        </div>
    </div>
</div>

<div class="modal-footer">
    <hive-checkbox
        [(ngModel)]="doNotShowAgain"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Do not show again"
        angularticsCategory="Action"
        class="checkbox"
    >
        Don't show this message any more
    </hive-checkbox>
    <a
        [external]="true"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Learn More"
        angularticsCategory="Action"
        class="link"
        hive-link
        href="https://support.purestorage.com/bundle/m_pure1_manage_administration/page/Pure1/Pure1_Manage/topics/concept/c_Overview_IAM.html"
    >
        Learn More
    </a>
    <a
        (click)="close(true)"
        *ngIf="'identity-center' | pure1AppLink as link"
        [href]="link"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Launch"
        angularticsCategory="Action"
        target="_blank"
    >
        <hive-button icon="regular/arrow-up-right-from-square.svg" iconPosition="right" label="Launch Pure IAM" />
    </a>
</div>
