<div class="array-tags-view-container">
    <impersonating-banner permission="PURE1:view:tags"></impersonating-banner>
    <tag-change-summary
        *ngIf="lastChange.length > 0"
        [changes]="lastChange"
        (rollbacked)="onRollbacked($event)"
    ></tag-change-summary>
    <div class="table-fixed-header-container">
        <table
            class="table table-bordered not-first-col table-hover table-fixed-header"
            [pureshadowscroll]="{ scrollElem: 'tbody', shadowElem: 'thead' }"
        >
            <thead class="thead-default">
                <tr>
                    <th class="icon-column">
                        <input type="checkbox" #selectAllCheckbox (click)="selectAll()" />
                    </th>
                    <th
                        class="name-column manual-sort"
                        [ngClass]="sortAscending ? 'st-sort-ascent' : 'st-sort-descent'"
                        (click)="toggleSort()"
                        >Name&nbsp;</th
                    >
                    <th class="tag-column">
                        Tags
                        <div class="float-xs-right" style="display: flex">
                            <pure-entity-selection-dropdown
                                entityName="Selection"
                                [entities]="sortedSelectedArrays"
                                [isEditable]="true"
                                [isSelectable]="false"
                                (entitiesChange)="onSelectionChange($event)"
                            ></pure-entity-selection-dropdown>
                            <button
                                class="btn btn-secondary with-action-icon edit-btn"
                                blurOnClick
                                [disabled]="selectedArrays.size === 0"
                                (click)="editSelected()"
                            >
                                <pureui-svg svg="edit.svg" [height]="10"></pureui-svg>
                            </button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let array of sortedArrays; trackBy: 'arrayId' | trackByProperty"
                    (click)="changeArraySelection(array.arrayId, array.hostname)"
                    [ngClass]="{ 'table-active': selectedArrays.has(array.arrayId) }"
                >
                    <td class="icon-column">
                        <input type="checkbox" [checked]="selectedArrays.has(array.arrayId)" />
                    </td>
                    <td class="name-column array-name"
                        ><a [arrayHref]="array.arrayId">{{ array.hostname }}</a></td
                    >
                    <td class="tag-column tags-list">
                        <pure-tag
                            *ngFor="let tag of resourceIdToTagMap.get(array.arrayId); trackBy: trackByTagId"
                            [tag]="tag"
                            [ngClass]="$any(tag).changed ? 'highlight' : ''"
                            [editable]="editableTagOrganizationId === tag.tag_organization_id"
                        ></pure-tag>
                        <span class="no-tags" *ngIf="!pageLoading && !resourceIdToTagMap.has(array.arrayId)">–</span>
                        <pureui-svg
                            [hidden]="
                                selectedArrays.size > 1 ||
                                (selectedArrays.size === 1 && !selectedArrays.has(array.arrayId))
                            "
                            svg="edit.svg"
                            [height]="15"
                            class="pull-right manage-tags pstg-action-icon"
                            (click)="open($event, array)"
                        ></pureui-svg>
                    </td>
                </tr>
                <tr *ngIf="filteredArrays.length === 0 && !pageLoading">
                    <td colspan="3" class="no-arrays">No arrays are matching your filtering criteria.</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
