<div class="pill-container">
    <span class="pill-key">
        {{ displayKey }}
    </span>
    <span *ngIf="displayValueLength < 40; else truncateValues" class="pill-values">
        <span class="pill-value" *ngFor="let displayValue of displayValues">{{ displayValue }}</span>
    </span>
    <ng-template #truncateValues>
        <span class="pill-values">
            <span class="pill-value"> {{ displayValues[0] }}... ({{ displayValues.length }} items) </span>
        </span>
    </ng-template>
</div>
