<form id="user-form" class="form-body" [formGroup]="assignmentForm">
    <p *ngIf="warningMessage" class="alert-warning warning-msg">{{ warningMessage }}</p>

    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <section id="user-details" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Overview</h1>
            <div class="edit-profile hover-effect" *ngIf="isReadonly" (click)="beginEdit()">
                <pureui-svg class="edit-icon" svg="edit.svg" height="16"></pureui-svg>
            </div>
        </section>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="user-first-name">First name</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showFirstNameInput">{{ newUser.firstName }}</ng-container>
                <ng-template #showFirstNameInput>
                    <input
                        class="form-control"
                        id="user-first-name"
                        name="user-first-name"
                        type="text"
                        placeholder="First Name *"
                        formControlName="firstName"
                    />
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="user-last-name">Last name</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showLastNameInput">{{ newUser.lastName }}</ng-container>
                <ng-template #showLastNameInput>
                    <input
                        class="form-control"
                        id="user-last-name"
                        name="user-last-name"
                        type="text"
                        placeholder="Last Name *"
                        formControlName="lastName"
                    />
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="user-email">Email</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showEmailInput">{{ newUser.email }}</ng-container>
                <ng-template #showEmailInput>
                    <input
                        class="form-control"
                        id="user-email"
                        name="user-email"
                        type="email"
                        placeholder="Email Address *"
                        formControlName="email"
                    />
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-phone">Phone</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showPhoneInput">{{ newUser.phone | defaultString }}</ng-container>
                <ng-template #showPhoneInput>
                    <input-phone
                        class="phone-control"
                        id="user-phone"
                        [prefill]="newUser.phone"
                        (onPhoneChange)="onPhoneChange($event)"
                    >
                    </input-phone>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-timezone">Time zone</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showTimezoneSelect">{{ newUser.timezone }}</ng-container>
                <ng-template #showTimezoneSelect>
                    <select id="user-timezone" class="custom-select form-control" formControlName="timezone">
                        <option
                            *ngFor="let timezone of timezones; trackBy: 'value' | trackByProperty"
                            [value]="timezone.value"
                        >
                            ({{ timezone.timeOffset }}) {{ timezone.value }}</option
                        >
                    </select>
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-access-control" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Access Control</h1>
            <div class="edit-profile hover-effect" *ngIf="isReadonly" (click)="beginEdit()">
                <pureui-svg class="edit-icon" svg="edit.svg" height="16"></pureui-svg>
            </div>
        </section>
        <ng-container *ngIf="isCurrentUser && isReadonly; else regularControlLayout">
            <section class="current-user-orgs">
                <section class="primary-org">
                    <h4 class="primary-org-name"> {{ currentOrg?.name }} </h4>
                    <div class="row">
                        <div class="col-xs-3 form-label">
                            <label for="user-role">Roles</label>
                        </div>
                        <div class="col-xs-9 form-field user-role-list">
                            <show-all-roles
                                [role]="newUser.role"
                                [additionalRoles]="newUser.additionalRoles"
                            ></show-all-roles>
                        </div>
                    </div>
                </section>
                <div
                    class="row external-org-name"
                    *ngFor="let org of orgList; trackBy: 'organization_id' | trackByProperty"
                >
                    {{ org.name }}
                </div>
            </section>
        </ng-container>
        <ng-template #regularControlLayout>
            <div class="row">
                <div class="col-xs-3 form-label">
                    <label [ngClass]="{ required: !isReadonly }" for="user-role">Roles</label>
                </div>
                <div class="col-xs-9 form-field user-role-list">
                    <ng-container *ngIf="isReadonly; else showRoleSelect">
                        <show-all-roles
                            [role]="newUser.role"
                            [additionalRoles]="newUser.additionalRoles"
                        ></show-all-roles>
                    </ng-container>
                    <ng-template #showRoleSelect>
                        <user-roles-select
                            *ngIf="!isPure1Support; else supportLabel"
                            formControlName="roles"
                            [items]="availableRoles"
                            [loading]="loadingRoles"
                            [shouldDisplayFn]="shouldDisplayRoleFn"
                            [errorMessage]="rolesControl.errors | roleErrors"
                            id="user-role"
                        />
                        <ng-template #supportLabel>Pure1 Support</ng-template>
                    </ng-template>
                </div>
            </div>
            <div class="row" *ngIf="!isCurrentUser">
                <div class="col-xs-3 form-label">
                    <label for="user-view-assignment">View</label>
                </div>
                <div class="col-xs-9 form-field">
                    <ng-container *ngIf="isReadonly; else showViewDropdown">{{
                        newUser.view?.name || 'Unassigned (All Arrays)'
                    }}</ng-container>
                    <ng-template #showViewDropdown>
                        <view-dropdown
                            [views]="views"
                            [deferredAssignable]="deferredAssignable"
                            [addViewDisabled]="assignmentForm.controls.view.disabled"
                            [dropdownDisabled]="assignmentForm.controls.view.disabled"
                            [dropdownDisabledMessage]="dropdownDisabledMessage"
                            [initialView]="$any(newUser.view)"
                            (addViewSelected)="addView()"
                            (viewChanged)="selectView($event)"
                        >
                        </view-dropdown>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </section>
    <ng-container *ngIf="isCurrentUser">
        <section id="account-security" class="form-section">
            <section class="section-header">
                <h1 class="section-title">Account Security</h1>
            </section>
            <account-security-section [currentUser]="currentUser"> </account-security-section>
        </section>
    </ng-container>
    <section id="user-options" class="form-section" *ngIf="isEdit">
        <div class="profile-options row">
            <div class="btn-toolbar">
                <span
                    class="btn profile-option-button reset-password hover-effect"
                    (click)="resetPassword()"
                    angulartics2On="click"
                    angularticsAction="UM - Reset password"
                    angularticsCategory="Action"
                >
                    Reset Password
                </span>
                <span
                    class="btn profile-option-button delete-account hover-effect"
                    *ngIf="!isCurrentUser"
                    (click)="openModal(deleteUserModal)"
                    angulartics2On="click"
                    angularticsAction="UM - Delete user"
                    angularticsCategory="Action"
                >
                    Remove Account
                </span>
            </div>
        </div>
    </section>
</form>

<ng-template #deleteUserModal let-modal>
    <delete-assignments-modal [assignments]="[roleAssignment]" [modal]="modal"> </delete-assignments-modal>
</ng-template>

<ng-container *ngIf="!isReadonly">
    <div class="form-footer">
        <button
            class="btn btn-primary float-xs-right save-button"
            (click)="create()"
            [disabled]="('PURE1:write:users' | isNotAllowed) || !isValidForm() || loading"
            angulartics2On="click"
            angularticsAction="UM - Create user"
            angularticsCategory="Action"
        >
            Save
        </button>
        <button
            class="btn btn-info float-xs-right cancel-button"
            [disabled]="loading"
            (click)="cancel()"
            angulartics2On="click"
            angularticsAction="UM - Cancel create user"
            angularticsCategory="Action"
        >
            Cancel
        </button>
    </div>
</ng-container>
