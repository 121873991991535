import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from '@pure/authz-authorizer';
import { OpportunityQuote, OpportunityQuoteService } from '@pure1/data';

enum State {
    DEFAULT = 'DEFAULT',
    ACCEPT = 'ACCEPT',
    ERROR = 'ERROR',
}

@Component({
    selector: 'quote-accept-modal',
    templateUrl: './quote-accept-modal.component.html',
})
export class QuoteAcceptModalComponent {
    @Input() readonly opportunityQuote: OpportunityQuote;
    @Input() readonly modal: NgbActiveModal;
    @Input() readonly acceptAllowed: boolean;

    @Output() readonly acceptComplete = new EventEmitter<void>();

    State = State;
    state: State = State.DEFAULT;
    isLoading = false;

    constructor(private opportunityQuoteService: OpportunityQuoteService) {}

    onAccept(): void {
        if (this.isLoading || !this.acceptAllowed) {
            return;
        }

        this.isLoading = true;
        this.opportunityQuoteService.accept(this.opportunityQuote).subscribe({
            next: () => {
                this.acceptComplete.emit();
                this.state = State.ACCEPT;
                this.isLoading = false;
            },
            error: () => {
                this.state = State.ERROR;
                this.isLoading = false;
            },
        });
    }
}
