// TODO: Why does this file redeclare PureXPathListener / PureXPathParser? Not worth investigating now as this hasn't been changed in years.
/* eslint-disable @typescript-eslint/no-redeclare */

import { PureXPathListener, PureXPathParser } from '../../../../resources/filters';

enum ListOp {
    ANY = 'any',
    ALL = 'all',
    NONE = 'none',
}

type PureXPathListener = any;

type ParseTree = {
    getText(): string;
};

type ParserRuleContext = {
    getChild(i: number): ParseTree;
    getChildCount(): number;
    getPayload(): ParserRuleContext;
    getText(): string;
};

type RelativeLocationPathContext = ParserRuleContext & { step(): ParserRuleContext[] };
type AbbreviatedStepContext = ParserRuleContext;
type FunctionCallContext = ParserRuleContext & {
    exprSingle(): ParserRuleContext[];
    exprSingle(i: number): ParserRuleContext;
    functionName(): ParserRuleContext;
};
type OrExprContext = ParserRuleContext;
type AndExprContext = ParserRuleContext;
type EqualityExprContext = ParserRuleContext;
type NameTestContext = ParserRuleContext;
type NodeTestContext = ParserRuleContext & {
    LBRAC(): ParserRuleContext;
    RBRAC(): ParserRuleContext;
};
type PureXPathParser = ParserRuleContext;

export type MultiValueFilter = {
    // differs from IMultiValueFilter because there is no namespace
    operator: 'equals' | 'contains' | 'tags' | 'not equals';
    key: string;
    values: string[];
};

/**
 * This interface defines a complete listener for a parse tree produced by
 * `PureXPathParser`.
 */
export class ViewFilterListener extends PureXPathListener {
    relativePathDepth = 1;
    path: string[] = []; // use as a stack
    stack: MultiValueFilter[] = []; // use as a stack

    // These 2 variables used to determine when to generate the condition "field exists".
    //
    // pathLengthOnEnterEqualityExpr is significant because equalityExpr is the highest node in the
    // subtree where we can do expression construction with the field name. The only condition where
    // the path length might differ on enter and exit of the equalityExpr is if there exist some
    // NCNames that were not used up in other equalityExpr, relationalExpr, or functionCall in this
    // subtree. Which, means we encounter a dangling NCName that is meant to be used for field
    // existence check.
    //
    // The only exception to this at the moment is contains() function. The nature of the function
    // means there will be a dangling NCName on the lhs. Hence, we explicitly state that when
    // visiting contains() function, mayContainsExists is false.
    private pathLengthOnEnterEqualityExpr = 0;
    private mayContainExists = true;

    /**
     * Exit a parse tree produced by `PureXPathParser.relativeLocationPath`.
     * @param ctx the parse tree
     */
    exitRelativeLocationPath = (ctx: RelativeLocationPathContext): void => {
        const dotsInPath = ctx.step().filter(x => x.getText() === '.').length;
        const dotDotsInPath = ctx.step().filter(x => x.getText() === '..').length;
        this.relativePathDepth = ctx.step().length - dotsInPath - 2 * dotDotsInPath;
    };

    /**
     * Enter a parse tree produced by `PureXPathParser.nameTest`.
     * @param ctx the parse tree
     */
    enterNameTest = (ctx: NameTestContext): void => {
        const text = ctx.getText();
        // TODO: potential bug if any properties are named "any" or "all" in the future
        if (ListOp.ANY !== text && ListOp.ALL !== text) {
            this.path.push(text);
        }
    };

    /**
     * Enter a parse tree produced by `PureXPathParser.nodeTest`.
     * @param ctx the parse tree
     */
    enterNodeTest = (ctx: NodeTestContext): void => {
        if (ctx.LBRAC() || ctx.RBRAC()) {
            throw new Error('brackets are not supported in view filters');
        }
    };

    /**
     * Exit a parse tree produced by `PureXPathParser.abbreviatedStep`.
     * @param ctx the parse tree
     */
    exitAbbreviatedStep = (ctx: AbbreviatedStepContext): void => {
        if (ctx.getText() === '.') {
            return;
        } else if (ctx.getText() === '..') {
            this.path.pop();
        }
    };
    // END PATH PROCESSING

    /**
     * Exit a parse tree produced by `PureXPathParser.orExpr`.
     * @param ctx the parse tree
     */
    exitOrExpr = (ctx: OrExprContext): void => {
        // every expression is an OrExpression, but if it has more than on
        if (ctx.getChildCount() > 1) {
            throw new Error('cannot use OR operator for view');
        }
        // like AndExpr, this code isn't really needed yet other than for validation (we can't use OR, for instance)

        // const toOr: T[] = [];
        // while (toOr.length < ctx.andExpr().length && this.stack.length !== 0) {
        //     toOr.push(this.stack.pop());
        // }
        // if (toOr.length < 2) {
        //     throw new Error('Received improper condition for operator' + ctx.getText());
        // }
        // this.stack.push(toOr.reduce((c1, c2) => this.onOr(c1, c2)));
    };

    /**
     * Exit a parse tree produced by `PureXPathParser.andExpr`.
     * @param ctx the parse tree
     */
    exitAndExpr = (ctx: AndExprContext): void => {
        if (ctx.getChildCount() === 1) {
            return;
        }
        // We don't actually need to combine two expressions with the AND operator
        // but we should do some validation here to ensure there's no dangling AND

        // const toAnd: T[] = [];
        // while (toAnd.length < ctx.equalityExpr().length && this.stack.length !== 0) {
        //     toAnd.push(this.stack.pop());
        // }
        // if (toAnd.length < 2) {
        //     throw new Error('Received improper condition for operator' + ctx.getText());
        // }
        // this.stack.push(toAnd.reduce((c1, c2) => this.onAnd(c1, c2)));
    };

    /**
     * Enter a parse tree produced by `PureXPathParser.equalityExpr`.
     * @param ctx the parse tree
     */
    enterEqualityExpr = (ctx: EqualityExprContext): void => {
        this.pathLengthOnEnterEqualityExpr = this.path.length;
    };

    /**
     * Exit a parse tree produced by `PureXPathParser.equalityExpr`.
     * @param ctx the parse tree
     */
    exitEqualityExpr = (ctx: EqualityExprContext): void => {
        if (ctx.getChildCount() === 3) {
            const values: string[] = this.getLiterals(ctx.getChild(2));
            const opString = ctx.getChild(1).getText();
            if (opString === '=') {
                this.stack.push({
                    operator: 'equals',
                    key: ctx.getChild(0).getText(),
                    values,
                });
            } else if (opString === '!=') {
                this.stack.push({
                    operator: 'not equals',
                    key: ctx.getChild(0).getText(),
                    values,
                });
            } else {
                throw new Error('not an equality/inequality expression');
            }
        }
        if (this.path.length > 0) {
            this.path.pop();
        }
    };

    /**
     * Enter a parse tree produced by `PureXPathParser.functionCall`.
     * @param ctx the parse tree
     */
    enterFunctionCall = (ctx: FunctionCallContext): void => {
        const functionName = ctx.functionName().getText();
        switch (functionName) {
            case 'contains':
                this.mayContainExists = false;
            // no break (to simplify code)
            // eslint-disable-next-line no-fallthrough
            case 'tags':
                if (ctx.exprSingle().length !== 2) {
                    throw new Error('filter is in illegal format');
                }
                break;
            case 'not':
                if (ctx.exprSingle().length !== 1) {
                    throw new Error('filter is in illegal format');
                }
                break;
            default:
                throw new Error('unexpected function name' + functionName);
        }
    };

    /**
     * Exit a parse tree produced by `PureXPathParser.functionCall`.
     * @param ctx the parse tree
     */
    exitFunctionCall = (ctx: FunctionCallContext): void => {
        const functionName = ctx.functionName().getText();
        switch (functionName) {
            case 'contains':
                this.stack.push({
                    operator: 'contains',
                    key: ctx.getChild(2).getText(),
                    values: this.getLiterals(ctx.exprSingle(1).getPayload()),
                });
                if (this.path.length > 0) {
                    this.path.pop();
                }
                this.mayContainExists = true;
                break;
            case 'tags':
                this.stack.push({
                    operator: 'tags',
                    key: this.parseValue(ctx.getChild(2).getText()),
                    values: this.getLiterals(ctx.exprSingle(1).getPayload()),
                });
                if (this.path.length > 0) {
                    this.path.pop();
                }
                break;
            case 'not':
            default:
                throw new Error('illegal filter format');
        }
    };

    visitErrorNode(errorNode: ParseTree): void {
        throw new Error('illegal filter format encountered with text: ' + errorNode.getText());
    }

    get(): MultiValueFilter[] {
        // return all the filters we've collected on the stack
        return this.stack;
    }

    private getLiterals(tree): string[] {
        const filterValues = [];
        const count = tree.getChildCount();
        if (count === 0) {
            // if a leaf node
            const token: any = tree.getPayload() as any;
            if (
                token.type === PureXPathParser.Literal ||
                token.type === PureXPathParser.NCName ||
                token.type === PureXPathParser.Number
            ) {
                filterValues.push(this.parseValue(token.text));
            }
            return filterValues;
        }
        for (let i = 0; i < count; i++) {
            const childTree = tree.getChild(i);
            if (tree) {
                filterValues.push(...this.getLiterals(childTree));
            }
        }
        return filterValues;
    }

    private parseValue(value: string): string {
        // blank strings must be surrounded with quotes
        if (!value || value.length === 0) {
            return undefined;
        }
        value = value.trim().replace("\\'", "'").replace('\\\\', '\\');
        // TODO: check that numbers do not need quotes
        if (value.length > 1 && value.startsWith(`'`) && value.endsWith(`'`)) {
            value = value.substring(1, value.length - 1);
        }

        return value;
    }
}
