import moment from 'moment';
import { Resource } from '../interfaces/resource';

export type commitState = 'pending' | 'committed';

export class CustomIdpConfig implements Resource {
    // Connection meta
    id: string; // Pure1 resource id
    name: string; // Pure1 resource name
    domain: string; // customer domain
    domain_aliases: string[]; // additional customer domain aliases
    connection: string; // auth0 connection name

    // Customer IDP side configuration
    sp_callback_url: string; // Service provider (Auth0) sign in callback Url
    sp_entity_id: string; // Service provider (Auth0) SAML entity Id

    // Auth0 side configuration
    custom_idp_sign_in_url: string; // IDP (Okta) sign in url
    custom_idp_cert: string; // X.509 signing certificate used by IDP.
    admin_user_group: string;
    regular_user_group: string;
    sign_saml_request: boolean;

    // Audit Fields
    timestamp: moment.Moment;
    created_by: string;

    commit_state: commitState;

    constructor(json: any) {
        if (json != null) {
            this.id = json.id;
            this.domain = json.domain;
            this.domain_aliases = json.domain_aliases || [];
            this.connection = json.connection;

            this.sp_callback_url = json.sp_callback_url;
            this.sp_entity_id = json.sp_entity_id;

            this.custom_idp_sign_in_url = json.custom_idp_sign_in_url;
            this.custom_idp_cert = json.custom_idp_cert;
            this.admin_user_group = json.admin_user_group;
            this.regular_user_group = json.regular_user_group;
            this.sign_saml_request = json.sign_saml_request;

            this.commit_state = json.commit_state;
        }
    }
}
