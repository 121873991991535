<form id="external-user-form" class="form-body" [formGroup]="assignmentForm">
    <p *ngIf="warningMessage" class="alert-warning warning-msg">{{ warningMessage }}</p>

    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <section id="external-user-details" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Overview</h1>
        </section>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="user-email">Email</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showEmailInput">{{ newSsoUser.email }}</ng-container>
                <ng-template #showEmailInput>
                    <input
                        class="form-control"
                        id="user-email"
                        name="user-email"
                        type="email"
                        placeholder="Email Address *"
                        formControlName="email"
                    />
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-access-control" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Access Control</h1>
            <div class="edit-profile hover-effect" *ngIf="isReadonly" (click)="beginEdit()">
                <pureui-svg class="edit-icon" svg="edit.svg" height="16"></pureui-svg>
            </div>
        </section>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="external-user-role-assignment">Roles</label>
            </div>
            <div class="col-xs-9 form-field user-role-list">
                <ng-container *ngIf="isReadonly; else roleSelect">
                    <show-all-roles [additionalRoles]="newSsoUser.additionalRoles"></show-all-roles>
                </ng-container>
                <ng-template #roleSelect>
                    <user-roles-select
                        formControlName="additionalRoles"
                        [items]="availableRoles"
                        [loading]="loadingRoles"
                        [errorMessage]="assignmentForm.get('additionalRoles').errors | roleErrors"
                    />
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-options" class="form-section" *ngIf="isEdit">
        <div class="profile-options">
            <div class="btn-toolbar">
                <span
                    class="btn profile-option-button delete-account hover-effect"
                    (click)="removeAllRolesFromSSO()"
                    angulartics2On="click"
                    angularticsAction="UM - Delete SSO user"
                    angularticsCategory="Action"
                >
                    Remove Roles
                </span>
            </div>
        </div>
    </section>
</form>

<ng-container *ngIf="!isReadonly">
    <div class="form-footer">
        <button
            class="btn btn-primary float-xs-right save-button"
            (click)="create()"
            [disabled]="('PURE1:write:users' | isNotAllowed) || !isValidForm() || loading"
            angulartics2On="click"
            angularticsAction="UM - Create SSO user"
            angularticsCategory="Action"
        >
            Save
        </button>
        <button
            class="btn btn-info float-xs-right cancel-button"
            [disabled]="loading"
            (click)="cancel()"
            angulartics2On="click"
            angularticsAction="UM - Cancel create SSO user"
            angularticsCategory="Action"
        >
            Cancel
        </button>
    </div>
</ng-container>
