import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityQuote } from '../../../data';

@Component({
    selector: 'quote-poupload-modal',
    templateUrl: './quote-poupload-modal.component.html',
})
export class QuotePOUploadModalComponent {
    @Input() readonly modal: NgbActiveModal;
    @Input() readonly opportunityQuote: OpportunityQuote;

    isLoading = false;
    newFiles: FileList;

    onFileChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.newFiles = target.files;
    }

    onUploadClick(): void {
        //TODO implement sending to backend
        console.log('Upload clicked!');
        this.modal.close();
    }
}
