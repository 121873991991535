import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';

// Super basic comment box that only allows for minor modifications. Should only be used for optional comments
// Any further functionality required from a comment box will likely just require a separate implementation
// This is just to remove the boiler plate that occurs when we need a simple functioning comment box
@Component({
    selector: 'basic-comment-box',
    templateUrl: 'basic-comment-box.component.html',
})
export class BasicCommentBoxComponent implements OnInit {
    @Input() readonly descriptor: string;
    @Input() readonly characterLimit: number = 3000;
    @Input() readonly startingText: string = '';
    @Output() readonly textChange = new EventEmitter<string>();

    textBox: UntypedFormControl;

    constructor(private fb: UntypedFormBuilder) {}

    // Definitely don't foresee descriptor and character limit changing ever after initialization
    ngOnInit(): void {
        this.textBox = this.fb.control(this.startingText, [Validators.maxLength(this.characterLimit)]);

        this.textBox.valueChanges.subscribe(text => {
            this.textChange.emit(text);
        });
    }
}
