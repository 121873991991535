<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<side-panel [selectedTabName]="selectedTabName" (panelClose)="onPanelClose()" (panelOpen)="onPanelOpen($event)">
    <ng-template
        side-panel-tab
        [name]="PanelTabName.ORDERS_ACTIVITY_PANEL_TAB_NAME"
        icon="activity-tracker.svg"
        [notificationText]="ordersCount$ | async"
        attentionBubbleText="Order update available"
    >
        <orders-activity-panel></orders-activity-panel>
    </ng-template>
</side-panel>
