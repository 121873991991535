import moment from 'moment';
import { PodPerformanceAggregation } from './pod-performance-aggregation';
import { RealTimeMetrics } from './real-time-metrics';
import { Pod } from './pod';

export class PodAggregation extends Pod {
    readonly _as_of: moment.Moment;
    readonly stretched_pod: boolean;
    readonly aggregation: PodPerformanceAggregation;
    real_time_metrics: RealTimeMetrics;

    constructor(json: any) {
        super(json);
        this._as_of = moment(json._as_of);

        if (json.aggregation) {
            this.aggregation = new PodPerformanceAggregation(json.aggregation);
        }

        if (json.real_time_metrics) {
            this.real_time_metrics = new RealTimeMetrics(json.real_time_metrics);
        }
    }
}
