<div class="upgrade-scheduler-wrapper cdu-enabled">
    <aw-wizard *ngIf="!submitting && !submitted" #wizard navBarLayout="small" class="pure-common-wizard">
        <aw-wizard-step stepTitle="Select appliances" (stepEnter)="showStep(0)">
            <ssu-banner (onNavigateAway)="activeModal.dismiss()"></ssu-banner>
            <array-selector
                #arraySelector
                [arrayThreshold]="GENERIC_UPGRADE_THRESHOLD"
                [arrays]="allArrays"
                [appliances]="appliances"
                [preselectedApplianceIds]="preselectedApplianceIds"
                [preselectedCommonVersion]="preselectedCommonVersion"
                [loading]="loading"
                [hasSNOWContact]="hasSNOWContact"
                [isSNOWInitialized]="isSNOWInitialized"
                [replicationTargets]="replicationTargets"
                [releasesMap]="releasesMap"
                [versionPolicies]="versionPolicies"
                [hasDuplicateApplianceNames]="hasDuplicateApplianceNames"
                (onCancel)="cancel()"
                (onForward)="setArrays($event)"
            ></array-selector>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Schedule">
            <ssu-banner (onNavigateAway)="activeModal.dismiss()"></ssu-banner>
            <upgrade-planner
                *ngIf="arraysToUpgrade.length <= GENERIC_UPGRADE_THRESHOLD && shouldLoadTimeslots"
                [maxHops]="MAX_NUMBER_OF_HOPS"
                [arrays]="arraysToUpgrade"
                [freeTimeUpdates]="freeTimeUpdates"
                [releasesMap]="releasesMap"
                [replicationTargets]="replicationTargets"
                [hasDuplicateApplianceNames]="hasDuplicateApplianceNames"
                (onBack)="showStep(0)"
                (onCancel)="cancel()"
                (onForward)="setSchedule($event)"
            ></upgrade-planner>
            <upgrade-planner-generic
                *ngIf="arraysToUpgrade.length > GENERIC_UPGRADE_THRESHOLD"
                [arrays]="arraysToUpgrade"
                (onBack)="showStep(0)"
                (onCancel)="cancel()"
                (onForward)="setSchedule($event)"
            ></upgrade-planner-generic>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Confirm request">
            <ssu-banner (onNavigateAway)="activeModal.dismiss()"></ssu-banner>
            <upgrade-confirmation
                *ngIf="arraysToUpgrade.length <= GENERIC_UPGRADE_THRESHOLD"
                [maxHops]="MAX_NUMBER_OF_HOPS"
                [schedule]="schedule"
                [timeslotsChecking]="timeslotsChecking"
                [timeslotsConflictFlag]="timeslotsConflictFlag"
                [safeModeAutoOnFlags]="safeModeAutoOnFlags"
                [showSafeModeAutoOnColumn]="showSafeModeAutoOnColumn"
                [hasSubmitPermission]="hasSubmitPermission"
                [hasDuplicateApplianceNames]="hasDuplicateApplianceNames"
                (onBack)="showStep(1)"
                (onCancel)="cancel()"
                (onForward)="doSchedule($event)"
                (toggleSafeModeAutoOn)="toggleSafeModeAutoOn($event)"
            ></upgrade-confirmation>
            <upgrade-confirmation-generic
                *ngIf="arraysToUpgrade.length > GENERIC_UPGRADE_THRESHOLD"
                [schedule]="schedule"
                (onBack)="showStep(1)"
                (onCancel)="cancel()"
                (onForward)="doSchedule($event)"
            ></upgrade-confirmation-generic>
        </aw-wizard-step>
    </aw-wizard>
    <div *ngIf="submitting || submitted" class="scheduled-confirmation-wrapper">
        <div *ngIf="submitting" class="col-xs-12 waiting">
            <h4>Please wait while we process your request.</h4>
            <br />
            <div class="progress-wrapper">
                <ngb-progressbar
                    [value]="100"
                    [max]="100"
                    type="info"
                    [striped]="true"
                    [animated]="true"
                    height="24px"
                    class="bar"
                ></ngb-progressbar>
            </div>
        </div>
        <upgrade-scheduled-confirmation
            *ngIf="submitted"
            [caseId]="caseId"
            (onDismiss)="dismiss()"
            [createCaseDoneFlag]="createCaseDoneFlag"
            [createCaseErrorFlag]="createCaseErrorFlag"
            [schedule]="schedule"
            [email]="userEmail"
        ></upgrade-scheduled-confirmation>
    </div>
</div>
