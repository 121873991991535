import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { GenericService } from './generic.service';
import { SfdcQuote } from '../models/sfdc-quote';
import { DataPage } from '../interfaces/data-page';
import { IRestResponse } from '../interfaces/collection';

const QUOTE_ENDPOINT = '/rest/v5/quotes';

@Injectable({ providedIn: 'root' })
export class SfdcQuoteServiceV5 extends GenericService<SfdcQuote> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SfdcQuote,
            endpoint: QUOTE_ENDPOINT,
            list: false,
            create: false,
            update: true,
            delete: false,
        });
    }

    /**
     * @param properties Required fields: id, salesFlowId, partnerAcceptance
     */
    update(properties: Partial<SfdcQuote>): Observable<DataPage<SfdcQuote>> {
        const baseUrl = `${QUOTE_ENDPOINT}`;
        const requestBody = this.convertQuoteToRequest(properties);
        return this.http.patch<IRestResponse<SfdcQuote>>(QUOTE_ENDPOINT, requestBody, { observe: 'response' }).pipe(
            map(response => {
                const mappedResponse: IRestResponse<SfdcQuote> = response?.body;
                return this.makeDataPage(mappedResponse, response);
            }),
        );
    }

    private convertQuoteToRequest(sfdcQuote: Partial<SfdcQuote>): string {
        return JSON.stringify({
            id: sfdcQuote.salesFlowId,
            quotes: [
                {
                    id: sfdcQuote.id,
                    partner_acceptance: sfdcQuote.partnerAcceptance,
                    partner_comment: sfdcQuote.partnerComment,
                },
            ],
        });
    }
}
