import { Injectable } from '@angular/core';
import { KeepHtml, ToastService, ToastType } from '../../../services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpgradePrechecksErrorService {
    constructor(private toastService: ToastService) {}

    errorInterceptor = (error: HttpErrorResponse) => {
        if (error.status) {
            switch (error.status) {
                case 400:
                    this.showErrorToast('Invalid action or invalid or missing data.');
                    break;
                case 401:
                    this.showErrorToast('Session expired. Please log in again.');
                    break;
                case 403:
                    this.showErrorToast(
                        'You are not authorized to perform this action. Please email <a href="mailto:support@purestorage.com">support@purestorage.com</a> for assistance.',
                    );
                    break;
                case 404:
                    this.showErrorToast('Action is currently not available. Try again.');
                    break;
                default:
                    this.showErrorToast(
                        'An unexpected error has occurred. Please try again later. If the problem persists contact Pure Technical Services.',
                    );
            }
            return throwError(() => error);
        }

        return of(null);
    };

    private showErrorToast = (message: string): void => {
        this.toastService.add(ToastType.error, new KeepHtml(message));
    };
}
