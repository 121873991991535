import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { STEP_UP_ERROR_RESPONSES } from '@pure1/data';
import { StepUpSupportService } from '../../services/step-up-support.service';

@Component({
    selector: 'enrollment-required-modal',
    templateUrl: 'enrollment-required-modal.component.html',
})
export class EnrollmentRequiredModalComponent {
    readonly STEP_UP_SUPPORT_LINK = `https://support.purestorage.com/Pure1/Pure1_Manage/002_Getting_Support/Identity_Management_with_Step-Up_Authentication`;

    constructor(
        public activeModal: NgbActiveModal,
        private stepUpSupport: StepUpSupportService,
    ) {}

    close(): void {
        this.activeModal.dismiss(STEP_UP_ERROR_RESPONSES['enrollment_required']);
    }

    goToSupport(): void {
        this.stepUpSupport.openStepUpRequestEnrollmentCase();
        this.close();
    }
}
