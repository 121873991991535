<div class="ur-container">
    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <div class="drawer-title">Update Access Control</div>
    <pure-entity-selection-dropdown
        entityName="Selections"
        [isSelectable]="false"
        [entities]="assignments"
        [isEditable]="true"
        (entitiesChange)="onEntitiesChange($event)"
    >
    </pure-entity-selection-dropdown>

    <div class="ur-body">
        <div class="batch-edit-error-text" *ngIf="isCurrentUserSelected()">
            <span>Unselect yourself to update user assignments.</span>
        </div>
        <div class="batch-edit-error-text" *ngIf="isSsoAdminSelected()">
            <span>The Pure1 Admin group for this organization can't be updated.</span>
        </div>
        <div id="form" class="form" *ngIf="status === 'form'">
            <form id="batch-edit-form" class="form-body" [formGroup]="assignmentForm">
                <section id="user-access-control" class="form-section">
                    <section class="section-header">
                        <h1 class="section-title">Access Control</h1>
                    </section>
                    <div class="row">
                        <div class="col-xs-3 form-label">
                            <label class="required" for="user-role">Role</label>
                        </div>
                        <div class="col-xs-9">
                            <select
                                id="user-role"
                                class="custom-select form-control"
                                formControlName="role"
                                (change)="ampli.umBatchEditRoleSelected({ category: 'Action' })"
                            >
                                <option *ngFor="let type of roleTypes" [ngValue]="type">{{ type | roleType }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-3 form-label">
                            <label for="user-view-assignment">View</label>
                        </div>
                        <div class="col-xs-9">
                            <view-dropdown
                                [views]="views"
                                [deferredAssignable]="deferredAssignable"
                                [addViewDisabled]="assignmentForm.controls.view.disabled"
                                [dropdownDisabled]="assignmentForm.controls.view.disabled"
                                (addViewSelected)="addView()"
                                (viewChanged)="selectView($event)"
                            >
                            </view-dropdown>
                        </div>
                    </div>
                </section>
            </form>
        </div>

        <!-- TODO: Probably use the same style as delete view modal we're using as a warning for reset/delete -->
        <section class="profile-options">
            <div class="btn-toolbar">
                <span
                    class="btn profile-option-button reset-password hover-effect"
                    *ngIf="areAllAssignmentsUsers()"
                    (click)="resetPasswords()"
                    angulartics2On="click"
                    angularticsAction="UM - Batch Edit - Reset password"
                    angularticsCategory="Action"
                >
                    Reset Passwords
                </span>
                <span
                    class="btn profile-option-button delete-account hover-effect"
                    *ngIf="!isCurrentUserSelected() && !isSsoAdminSelected()"
                    (click)="openModal(deleteAssignmentsModal)"
                    angulartics2On="click"
                    angularticsAction="UM - Batch Edit - Delete users"
                    angularticsCategory="Action"
                >
                    Remove {{ assignments.length | pluralize: 'Account' : 'All Accounts' }}
                </span>
            </div>
        </section>

        <ng-template #deleteAssignmentsModal let-modal>
            <delete-assignments-modal [assignments]="assignments" [modal]="modal"></delete-assignments-modal>
        </ng-template>
    </div>

    <div class="ur-footer form-footer">
        <!-- TODO: Revisit Angulartics -->
        <button
            class="btn btn-primary float-xs-right save-button"
            (click)="update()"
            [disabled]="('PURE1:write:users' | isNotAllowed) || !isValidForm() || loading"
            angulartics2On="click"
            angularticsAction="UM - Batch Edit - Saved changes"
            angularticsCategory="Action"
        >
            Save
        </button>
        <button
            class="btn btn-info float-xs-right cancel-button"
            (click)="cancel()"
            [disabled]="loading"
            angulartics2On="click"
            angularticsAction="UM - Batch Edit - Cancel"
            angularticsCategory="Action"
        >
            Cancel
        </button>
    </div>
</div>
