import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OpportunityQuote, QuoteStatus } from '@pure1/data';
import { AssetManagementSummaryInfo } from '../../asset-management-summary-card/asset-management-summary-card.component';

@Component({
    selector: 'quote-management-summary-totals',
    templateUrl: './quote-management-summary-totals.component.html',
})
export class QuoteManagementSummaryTotalsComponent implements OnChanges {
    @Input() readonly opportunityQuotes: OpportunityQuote[];

    summaries: AssetManagementSummaryInfo[];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.opportunityQuotes) {
            const totalQuotes = this.opportunityQuotes?.length;
            const totalActionRequiredQuotes = this.opportunityQuotes?.filter(
                opportunityQuote =>
                    opportunityQuote.status === QuoteStatus.OPEN ||
                    opportunityQuote.status === QuoteStatus.ACTION_REQUIRED,
            ).length;
            const totalAcceptedQuotes = this.opportunityQuotes?.filter(
                opportunityQuote => opportunityQuote.status === QuoteStatus.ACCEPTED,
            ).length;

            this.summaries = [
                {
                    title: 'Total',
                    svg: 'file-grey.svg',
                    value: totalQuotes,
                },
                {
                    title: 'Action Required',
                    svg: 'file-blue.svg',
                    value: totalActionRequiredQuotes,
                },
                {
                    title: 'Accepted',
                    svg: 'file-checkmark-teal.svg',
                    value: totalAcceptedQuotes,
                },
            ];
        }
    }
}
