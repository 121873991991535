import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';

import { ApiAppKey, ApiAppKeyService } from '@pure1/data';
import { ToastService, ToastType } from '../../services/toast.service';

@Component({
    selector: 'delete-api-key-modal',
    templateUrl: './delete-api-key-modal.component.html',
})
export class DeleteApiKeyModalComponent {
    @Input() readonly apiAppKey: ApiAppKey;
    @Input() readonly modal: NgbActiveModal;

    loading = false;

    constructor(
        private apiAppKeyService: ApiAppKeyService,
        private toast: ToastService,
    ) {}

    deleteApiKey(): void {
        this.loading = true;
        this.apiAppKeyService.delete(this.apiAppKey.id).subscribe(
            () => {
                this.loading = false;
                this.modal.close();
            },
            err => {
                this.loading = false;
                this.toast.add(ToastType.error, 'An error occurred while trying to delete. Please try again');
            },
        );
    }
}
