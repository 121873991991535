import { Resource } from '../interfaces/resource';
import { Assignment } from '../models/assignment';
import { AdditionalRoleAssignment } from './additional-role';

/**
 * @deprecated role based authorization is going to be migrated to permission based.
 * Reach out to #ask-pure1-security-authz for more information.
 * {@link RoleType}
 */
export enum RoleType {
    DASHBOARD_VIEWER = 'Dashboard_Viewer',
    VM_VIEWER = 'VM_Viewer',
    PURE1_VIEWER = 'Pure1_Viewer',
    PURE1_ADMIN = 'Pure1_Admin',
    PURE1_SUPPORT = 'Pure1_Support',
    PORTWORX_ADMIN = 'Portworx_Admin',
}

export enum BusinessRoles {
    ACCOUNT_EXECUTIVE = 'ACCOUNT EXECUTIVE',
    ASP = 'ASP',
    CC_ON_ALL_CASES = 'CC_ON_ALL_CASES',
    CC_ON_ALL_PORTWORX_CASE = 'CC_ON_ALL_PORTWORX_CASE',
    CC_ON_ALL_SEV_1_CASE = 'CC_ON_ALL_SEV_1_CASE',
    MAIN_POINT_OF_CONTACT = 'MAIN POINT OF CONTACT',
    PRIMARY_DESIGNATED_FB_SUPPORT_ENGINEER = 'PRIMARY DESIGNATED FB SUPPORT ENGINEER',
    PURE1_ADMIN = 'PURE1_ADMIN',
    PURE1_EXTERNAL = 'PURE1_EXTERNAL',
    PURE1_INTERNAL = 'PURE1_INTERNAL',
    PURE1_USER = 'PURE1_USER',
    PURE1_VIEWER = 'PURE1_VIEWER',
    SECONDARY_DESIGNATED_FB_SUPPORT_ENGINEER = 'SECONDARY DESIGNATED FB SUPPORT ENGINEER',
    SERVICE_ACCOUNT_MANAGER = 'SERVICE ACCOUNT MANAGER',
    SERVICE_DELIVERY_MANAGER = 'SERVICE DELIVERY MANAGER',
    SYSTEMS_ENGINEER = 'SYSTEMS ENGINEER',
    TECHNICAL_ACCOUNT_MANAGER = 'TECHNICAL ACCOUNT MANAGER',
    TECHNICAL_CONTACT = 'TECHNICAL CONTACT',
}

/** Enum string OR numeric ID */
export type UserRoleID = RoleType | number;

export class User extends Assignment implements Resource {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    timezone: string;
    partner: boolean;
    name: string;
    /** @deprecated since its type ({@link RoleType}) is deprecated */
    role: RoleType;
    additionalRoles: AdditionalRoleAssignment[];

    constructor(user: any) {
        super(user);
        this.id = user.id;
        this.email = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phone = user.phone;
        this.timezone = user.timezone;
        this.partner = user.partner;
        this.name = user.name;
        this.role = user.role;
        this.additionalRoles = (user.additionalRoles || [])
            .map(role => new AdditionalRoleAssignment(role))
            .sort(AdditionalRoleAssignment.compareByNameAlphabetically);
    }
}
