import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { Observable, Subject, combineLatest, defer, of } from 'rxjs';
import { catchError, filter, finalize, map, startWith, takeUntil } from 'rxjs/operators';

import {
    ViewReference,
    RoleAssignmentService,
    ResetPasswordService,
    SSOUsersService,
    ViewsService,
    UserRoleID,
    CurrentUser,
    AdditionalRoleAssignment,
    AdditionalUserRolesService,
    AdditionalRole,
    StepUpService,
    KnownRoleDomains,
} from '@pure1/data';
import { SSOUser } from 'core/src/data/models/sso-user';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';

import { BaseAssignmentFormComponent } from '../base-assignment-form/base-assignment-form.component';
import { UserRoleStateService } from '../services/user-role-state.service';
import { ToastService, ToastType } from '../../services/toast.service';
import { Assignment } from '../user-role-management.interface';
import { UserRoleItem } from '../user-roles-select/user-role-item';
import { userRoleItemsFactory } from '../helpers/user-role-items-factory';
import { when } from '../../utils/form-validators';

@Component({
    selector: 'sso-user-form',
    templateUrl: './sso-user-form.component.html',
    host: {
        class: 'base-assignment-form',
    },
})
export class SSOUserFormComponent extends BaseAssignmentFormComponent<SSOUser> implements OnChanges, OnInit, OnDestroy {
    @Input() readonly currentUser: CurrentUser;
    @Input() readonly isCurrentUser: boolean;

    availableRoles: UserRoleItem[] = [];
    loadingRoles = false;
    newSsoUser: SSOUser = null;
    warningMessage: string;
    cachedUserStepUpStatus = new Map<string, boolean>();

    readonly roleMap = new Map<UserRoleID, UserRoleItem>();
    readonly reset$ = new Subject<void>();

    constructor(
        ssoUsersService: SSOUsersService,
        viewsService: ViewsService,
        roleAssignmentService: RoleAssignmentService,
        resetPasswordService: ResetPasswordService,
        modalService: NgbModal,
        urStateService: UserRoleStateService,
        angulartics2: Angulartics2,
        toast: ToastService,
        mpaRequestsBadgeService: MpaRequestsBadgeService,
        private fb: FormBuilder,
        private additionalUserRolesService: AdditionalUserRolesService,
        private readonly stepUpService: StepUpService,
    ) {
        super(
            viewsService,
            ssoUsersService,
            roleAssignmentService,
            urStateService,
            angulartics2,
            resetPasswordService,
            modalService,
            toast,
            mpaRequestsBadgeService,
        );

        this.assignmentForm = this.fb.group({
            email: new FormControl('', {
                validators: [Validators.required, Validators.email],
                // 'blur' is used to trigger the roles validation when the email field is populated (http call)
                updateOn: 'blur',
            }),
            additionalRoles: new FormControl([], {
                validators: when(() => !this.isEdit).applyValidator(Validators.required),
                asyncValidators: this.stepUpEnabledValidator(),
            }),
        });

        this.assignmentForm
            .get('email')
            .valueChanges.pipe(
                filter(() => this.assignmentForm.get('email').valid),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.assignmentForm.get('additionalRoles').updateValueAndValidity();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.reset$.next();
        this.updateAssignmentObject(this.assignment);
        const selectedRoles = this.newSsoUser.additionalRoles.map(role => role.id);

        this.assignmentForm.patchValue({
            email: this.newSsoUser.email,
            additionalRoles: selectedRoles,
        });

        if (this.isEdit) {
            this.assignmentForm.get('email').disable();
        }

        super.ngOnChanges(changes);
    }

    ngOnInit(): void {
        combineLatest([this.loadAdditionalRoles(), this.reset$.pipe(startWith(0))])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([additionalRoles]) => {
                this.generateAvailableRoles(additionalRoles);
            });
    }

    updateAssignmentObject(assignment: Assignment): void {
        if (this.isEdit) {
            this.newSsoUser = assignment as SSOUser;
            if (this.newSsoUser.additionalRoles?.length < 1) {
                this.urStateService.closeDrawer();
            }
        } else {
            this.newSsoUser = new SSOUser({});
        }

        this.warningMessage = this.getWarningMessageForRoles(this.assignment?.additionalRoles ?? []);
    }

    makeAssignment(json: any): SSOUser {
        return new SSOUser({
            ...json,
            additionalRoles: json.additionalRoles.map(id => {
                const { name, domain } = this.roleMap.get(id);

                return { id, name, domain } as AdditionalRoleAssignment;
            }),
        });
    }

    copyAssignmentFields(newAssignment: SSOUser): void {
        newAssignment.name = this.assignmentForm.value.email;
        newAssignment.email = this.assignmentForm.value.email;
        if (this.isEdit) {
            const oldUser = this.assignment as SSOUser;
            newAssignment.id = oldUser.id;
            newAssignment.name = oldUser.name;
            newAssignment.email = oldUser.email;
        }
    }

    updateView(viewReference: ViewReference): void {}

    isValidForm(): boolean {
        return this.assignmentForm.valid;
    }

    private loadAdditionalRoles(): Observable<AdditionalRole[]> {
        return defer(() => {
            this.loadingRoles = true;

            return this.additionalUserRolesService.list().pipe(
                finalize(() => {
                    this.loadingRoles = false;
                }),
            );
        });
    }

    private generateAvailableRoles(additionalRoles: AdditionalRole[]): void {
        const userAdditionalRoles = this.assignment?.additionalRoles ?? [];

        this.availableRoles = userRoleItemsFactory(additionalRoles, userAdditionalRoles, this.isCurrentUser);
        this.availableRoles.forEach(role => {
            this.roleMap.set(role.id, role);
        });
    }

    removeAllRolesFromSSO(): void {
        this.loading = true;
        const newAssignment = new SSOUser(this.newSsoUser);
        newAssignment.view = null;
        newAssignment.additionalRoles = [];
        this.urStateService
            .updateWithCache<Assignment>(this.assignmentService, newAssignment, { email: this.assignment.email })
            .pipe(map(result => result.response[0]))
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.toast.add(ToastType.success, 'Successfully removed all roles');
                    this.urStateService.closeDrawer();
                },
                error: err => this.handleError(err),
            });
    }

    isSafeModeRole(roleId: UserRoleID): boolean {
        return this.roleMap.get(roleId)?.domain === KnownRoleDomains.SAFE_MODE;
    }

    private stepUpEnabledValidator(): AsyncValidatorFn {
        let previousEmail: string;

        return (control: FormControl<UserRoleID[]>) => {
            const email = control.parent?.get('email');
            const smaRoleSelected = control.value.some(roleId => this.isSafeModeRole(roleId));
            const emailValid = email.valid || email.disabled;

            if (smaRoleSelected && emailValid) {
                let res$: Observable<boolean>;
                const emailUpdated = email.value !== previousEmail;

                if (this.cachedUserStepUpStatus.has(email.value)) {
                    res$ = of(this.cachedUserStepUpStatus.get(email.value));
                } else if (emailUpdated) {
                    previousEmail = email.value;
                    res$ = this.stepUpService.stepUpEnabled(email.value) as Observable<boolean>;
                } else {
                    return of(null);
                }

                return res$.pipe(
                    map((stepUpEnabled: boolean) => {
                        this.cachedUserStepUpStatus.set(email.value, stepUpEnabled);

                        return stepUpEnabled ? null : { smaRole: true };
                    }),
                    catchError(() => of(null)),
                );
            }

            return of(null);
        };
    }
}
