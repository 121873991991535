import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DraasApiRecoverySummary,
    DraasApiGroupProtectionSummary,
    DraasApiProtectionStatus,
    DraasApiProtectionStatistics,
    DraasApiSystemStatus,
    DraasApiClusterDatastoreUsageStatus,
} from '@pure/paas-api-gateway-client-ts';
import { map, Observable } from 'rxjs';
import { DisasterRecoveryProtectionStatistics } from '../models/disaster-recovery-protection-statistics';
import { DisasterRecoveryStorageUsageStatus } from '../models/disaster-recovery-storage-usage-status';
import { DisasterRecoverySystemStatus } from '../models/disaster-recovery-system-status';
import { DisasterRecoveryProtectionStatus } from '../models/disaster-recovery-protection-status';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryGroupProtectionSummary } from '../models/disaster-recovery-group-protection-summary';
import { DisasterRecoveryRecoverySummary } from '../models/disaster-recovery-recovery-summary';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryOverviewService {
    protected http: HttpClient;

    constructor(http: HttpClient) {
        this.http = http;
    }

    private getEndpoint(clusterId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/overview`;
    }

    getProtectionStatistics(clusterId: string): Observable<DisasterRecoveryProtectionStatistics> {
        const url = `${this.getEndpoint(clusterId)}/protection-statistics`;
        return this.http
            .get<DraasApiProtectionStatistics>(url)
            .pipe(map(response => new DisasterRecoveryProtectionStatistics(response)));
    }

    getSystemStatus(clusterId: string): Observable<DisasterRecoverySystemStatus> {
        const url = `${this.getEndpoint(clusterId)}/system-status`;
        return this.http
            .get<DraasApiSystemStatus>(url)
            .pipe(map(response => new DisasterRecoverySystemStatus(response)));
    }

    getStorageUsageStatus(clusterId: string): Observable<DisasterRecoveryStorageUsageStatus> {
        const url = `${this.getEndpoint(clusterId)}/datastore-usage`;
        return this.http
            .get<DraasApiClusterDatastoreUsageStatus>(url)
            .pipe(map(response => new DisasterRecoveryStorageUsageStatus(response)));
    }

    getProtectionStatus(clusterId: string): Observable<DisasterRecoveryProtectionStatus> {
        const url = `${this.getEndpoint(clusterId)}/protection-status`;
        return this.http
            .get<DraasApiProtectionStatus>(url)
            .pipe(map(response => new DisasterRecoveryProtectionStatus(response)));
    }

    getProtectionSummary(
        clusterId: string,
        timeRange?: moment.Duration,
    ): Observable<DisasterRecoveryGroupProtectionSummary[]> {
        let url = `${this.getEndpoint(clusterId)}/protection-summary`;
        if (timeRange) {
            url += `?date_time_from=${moment().subtract(timeRange).toISOString()}`;
        }
        return this.http
            .get<DraasApiGroupProtectionSummary[]>(url)
            .pipe(map(response => response.map(summary => new DisasterRecoveryGroupProtectionSummary(summary))));
    }

    getRecoverySummary(clusterId: string, asset: string): Observable<DisasterRecoveryRecoverySummary> {
        const url = `${this.getEndpoint(clusterId)}/recovery-summary/${asset}`;
        return this.http
            .get<DraasApiRecoverySummary>(url)
            .pipe(map(response => new DisasterRecoveryRecoverySummary(response)));
    }
}
