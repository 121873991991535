import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    FeatureFlagDxpService,
    STEP_UP_ERROR_RESPONSES,
    StepUpError,
    StepUpFactor,
    StepUpVerifyResponse,
} from '@pure1/data';
import { from, Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, combineLatestWith, map, switchMap, take } from 'rxjs/operators';
import { CachedStepUpService } from '../../data/services/cached-step-up.service';
import { FeatureNames } from '../../model/FeatureNames';
import { DisableStepUpWarningModalComponent } from '../modals/disable-step-up-warning-modal/disable-step-up-warning-modal.component';
import { EnrollmentRequiredModalComponent } from '../modals/enrollment-required-modal/enrollment-required-modal.component';
import { EnrollmentViaIdentityCenterRequiredModalComponent } from '../modals/enrollment-via-identity-center-required-modal/enrollment-via-identity-center-required-modal.component';
import { PreEnrollPromptModalComponent } from '../modals/pre-enroll-prompt-modal/pre-enroll-prompt-modal.component';
import { StepUpErrorMessageModalComponent } from '../modals/step-up-error-message-modal/step-up-error-message-modal.component';
import { StepUpRegistrationModalComponent } from '../modals/step-up-registration-modal/step-up-registration-modal.component';
import { VerifyChallengeModalComponent } from '../modals/verify-challenge-modal/verify-challenge-modal.component';

const IMPERSONATE_MODAL_ERROR = {
    title: 'Access Denied',
    message: 'You cannot perform this action while switched to another user.',
    closeLabel: 'OK',
};
const LOCKED_OUT_MODAL_ERROR = {
    title: 'You Are Temporarily Locked Out',
    message:
        'Your account has been temporarily locked. There have been too many invalid verification code attempts during login.<br/>Please try again in an hour or contact Pure Technical Services to unlock your account.',
    closeLabel: 'Okay, I will wait',
};

@Injectable({ providedIn: 'root' })
export class StepUpModalService {
    // Global reference to the active modal. In case we don't close the modal after success and re-use it for a subsequent stepUp call.
    modalRef: NgbModalRef;

    constructor(
        private readonly modalService: NgbModal,
        private readonly cachedStepUpService: CachedStepUpService,
        private readonly authzServiceResolver: AuthorizationServiceResolver,
        private readonly featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    stepUp(
        audience: string,
        authorizationDetails: any[],
        singleUse: boolean,
        ttl?: number,
    ): Observable<StepUpVerifyResponse> {
        return this.authzServiceResolver.getDefaultService().pipe(
            take(1),
            switchMap(service => service.hasPermission('PURE1:write:step_up')),
            take(1),
            switchMap(canWriteStepup => {
                if (!canWriteStepup) {
                    return this.showErrorModal(
                        IMPERSONATE_MODAL_ERROR,
                        STEP_UP_ERROR_RESPONSES.impersonate_not_allowed,
                    );
                }
                return this.cachedStepUpService.getFactorsV2().pipe(
                    take(1),
                    catchError((error: StepUpError) => {
                        if (error.error === 'locked_out') {
                            return this.showErrorModal(
                                LOCKED_OUT_MODAL_ERROR,
                                STEP_UP_ERROR_RESPONSES.locked_out,
                                true,
                            );
                        } else {
                            console.warn('unknown factor error encountered: ' + error);
                            return throwError(() => error);
                        }
                    }),
                    combineLatestWith(
                        this.featureFlagDxpService
                            .getFeatureFlag(FeatureNames.IDENTITY_CENTER)
                            .pipe(map((feature: FeatureFlagStatus) => feature?.enabled === true)),
                    ),
                    switchMap(([result, identityCenterFeatureEnabled]: [StepUpFactor[], boolean]) => {
                        if (
                            result.some(
                                factor =>
                                    (factor.factorType === 'sms' || factor.factorType === 'totp') &&
                                    factor.status === 'ACTIVE',
                            )
                        ) {
                            this.showVerifyChallengeModal(
                                result,
                                audience,
                                authorizationDetails,
                                singleUse,
                                ttl,
                                null,
                                true,
                            );
                        } else {
                            if (identityCenterFeatureEnabled) {
                                this.showEnrollmentViaIdentityCenterRequiredModal();
                            } else {
                                this.showEnrollmentRequiredModal();
                            }
                        }
                        return from<ObservableInput<StepUpVerifyResponse>>(this.modalRef.result);
                    }),
                );
            }),
        );
    }

    stepUpForRemoval(activeFactor: StepUpFactor): Observable<StepUpVerifyResponse> {
        this.showVerifyChallengeModal(
            [activeFactor],
            'auth-portal',
            [{ phone: activeFactor.profile.phoneNumber }],
            true,
            null,
            'You will lose access to Pure1 features that require step-up authentication.',
            true,
        );
        return from<ObservableInput<StepUpVerifyResponse>>(this.modalRef.result);
    }

    /**
     * @Deprecated Enrollment of SMS factor will no longer be supported. Use openRegistrationModal method instead for TOTP factor registration.
     */
    stepUpForEnrollment(activeFactor: StepUpFactor, phone: string): Observable<StepUpVerifyResponse> {
        this.showVerifyChallengeModal([activeFactor], 'auth-portal', [{ phone: phone }], true, null, null, false);
        return from<EventEmitter<StepUpVerifyResponse>>(this.modalRef.componentInstance.verifyOutput);
    }

    /**
     * @Deprecated Enrollment of SMS factor will no longer be supported. Use openRegistrationModal method instead for TOTP factor registration.
     */
    activate(pendingFactor: StepUpFactor, authToResendEnroll: string): Observable<StepUpVerifyResponse> {
        // this.modalRef should have been left open by previous enrollmentStepUp() call.
        this.modalRef.componentInstance.factors = [pendingFactor];
        this.modalRef.componentInstance.audience = 'auth-portal';
        this.modalRef.componentInstance.singleUse = true;
        this.modalRef.componentInstance.isActivate = true;
        this.modalRef.componentInstance.authToResendEnroll = authToResendEnroll;
        this.modalRef.componentInstance.closeModalOnSuccess = true;
        this.modalRef.componentInstance.init();
        return from<ObservableInput<StepUpVerifyResponse>>(this.modalRef.result);
    }

    openPreEnrollPromptModal(): Observable<StepUpFactor> {
        this.modalRef = this.modalService.open(PreEnrollPromptModalComponent, {
            windowClass: 'step-up-modal-window',
            backdrop: 'static',
        });
        return from(this.modalRef.result);
    }

    openRegistrationModal(activeFactor: StepUpFactor): Observable<StepUpFactor> {
        this.modalRef = this.modalService.open(StepUpRegistrationModalComponent, {
            windowClass: 'step-up-modal-window',
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
        });
        this.modalRef.componentInstance.activeFactor = activeFactor;
        return from(this.modalRef.result);
    }

    openDisableWarningModal(): Observable<void> {
        this.modalRef = this.modalService.open(DisableStepUpWarningModalComponent, {
            windowClass: 'step-up-modal-window',
            backdrop: 'static',
        });
        return from(this.modalRef.result);
    }

    private showErrorModal(
        errorBody: any,
        resultError: StepUpError,
        showSupportButton = false,
    ): Observable<StepUpVerifyResponse> {
        this.modalRef = this.modalService.open(StepUpErrorMessageModalComponent, {
            windowClass: 'step-up-modal-window',
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
        });
        this.modalRef.componentInstance.errorBody = errorBody;
        this.modalRef.componentInstance.error = resultError;
        this.modalRef.componentInstance.showSupportButton = showSupportButton;
        return from<ObservableInput<StepUpVerifyResponse>>(this.modalRef.result);
    }

    private showEnrollmentRequiredModal(): void {
        this.modalRef = this.modalService.open(EnrollmentRequiredModalComponent, {
            windowClass: 'step-up-modal-window',
            backdrop: 'static',
            keyboard: false,
        });
    }

    private showEnrollmentViaIdentityCenterRequiredModal(): void {
        this.modalRef = this.modalService.open(EnrollmentViaIdentityCenterRequiredModalComponent, {
            windowClass: 'step-up-modal-window',
            backdrop: 'static',
            keyboard: false,
        });
    }

    private showVerifyChallengeModal(
        factors: StepUpFactor[],
        audience: string,
        authorizationDetails: any[],
        singleUse: boolean,
        ttl: number,
        additionalWarning: string,
        closeModalOnSuccess: boolean,
    ): void {
        this.modalRef = this.modalService.open(VerifyChallengeModalComponent, {
            windowClass: 'step-up-modal-window',
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
        });
        this.modalRef.componentInstance.factors = factors;
        this.modalRef.componentInstance.audience = audience;
        this.modalRef.componentInstance.authorizationDetails = authorizationDetails;
        this.modalRef.componentInstance.singleUse = singleUse;
        this.modalRef.componentInstance.ttl = ttl;
        this.modalRef.componentInstance.additionalWarning = additionalWarning;
        this.modalRef.componentInstance.closeModalOnSuccess = closeModalOnSuccess;
        this.modalRef.componentInstance.init();
    }
}
