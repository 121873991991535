import { Component, OnInit } from '@angular/core';
import { AuthorizationService, Permission } from '@pure/authz-authorizer';
import { CachedCurrentUserService, CurrentUser, FeatureFlagDxpService } from '@pure1/data';
import { combineLatest, forkJoin, map, of, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { FeatureNames } from '../../model/FeatureNames';
import { ISubmenuBarItem } from '../../ui/components/submenu-bar/submenu-bar-base.component';

const ASSET_MANAGEMENT_TAB_NAME = 'Asset Management';
const APPLIANCE_GENEALOGY_TAB_NAME = 'Appliance Genealogy';
const SUBSCRIPTION_GENEALOGY_TAB_NAME = 'Subscription Genealogy';
const QUOTE_MANAGEMENT_TAB_NAME = 'Quote Management';
const INVOICE_MANAGEMENT_TAB_NAME = 'Invoice Management';

interface AssetSubmenuBarItem extends ISubmenuBarItem {
    authzPermission?: Permission;
}

@Component({
    selector: 'asset-tab',
    templateUrl: 'asset-tab.component.html',
})
export class AssetTabComponent implements OnInit {
    // Items that show up by default
    submenuItems: AssetSubmenuBarItem[] = [
        {
            title: ASSET_MANAGEMENT_TAB_NAME,
            url: '/assets/asset-management',
        },
    ];
    currentUser: CurrentUser;

    // Items that appear if the flag is enabled
    readonly conditionalTabs = new Map<FeatureNames, AssetSubmenuBarItem>([
        [
            FeatureNames.APPLIANCE_GENEALOGY,
            {
                title: APPLIANCE_GENEALOGY_TAB_NAME,
                url: '/assets/appliance-genealogy',
            },
        ],
        [
            FeatureNames.SUBSCRIPTION_GENEALOGY,
            {
                title: SUBSCRIPTION_GENEALOGY_TAB_NAME,
                url: '/assets/subscription-genealogy',
            },
        ],
        [
            FeatureNames.QUOTE_MANAGEMENT,
            {
                title: QUOTE_MANAGEMENT_TAB_NAME,
                url: '/assets/quote-management',
                authzPermission: 'PURE1:write:subscriptions_renew',
            },
        ],
        [
            FeatureNames.PARTNER_INVOICE,
            {
                title: INVOICE_MANAGEMENT_TAB_NAME,
                url: '/assets/invoice-management',
                authzPermission: 'PURE1:write:subscriptions_renew',
            },
        ],
    ]);

    constructor(
        private featureFlagDxpService: FeatureFlagDxpService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private authzService: AuthorizationService,
    ) {}

    ngOnInit(): void {
        const featurePolicies$ = forkJoin(
            Array.from(this.conditionalTabs.entries()).map(([featureName, tab]) => {
                const featurePolicy$ = this.featureFlagDxpService.getFeatureFlag(featureName);
                const authzPermission$ = tab.authzPermission
                    ? this.authzService.isAllowed(tab.authzPermission)
                    : of(true);
                return zip([featurePolicy$, authzPermission$]);
            }),
        ).pipe(
            map(permissions =>
                permissions
                    .filter(([feature, authzPermission]) => feature !== null && authzPermission) // Check policies against permissions
                    .map(([feature, _]) => feature),
            ),
        );

        const user$ = this.cachedCurrentUserService.get().pipe(take(1));

        combineLatest([user$, featurePolicies$]).subscribe(([currentUser, features]) => {
            this.currentUser = currentUser;

            features.forEach(feature => {
                if (feature?.enabled) {
                    if (
                        (feature.name === FeatureNames.PARTNER_INVOICE ||
                            feature.name === FeatureNames.QUOTE_MANAGEMENT) &&
                        !this.currentUser.isPure &&
                        !this.currentUser.isPartner
                    ) {
                        return;
                    }
                    this.submenuItems.push(this.conditionalTabs.get(feature.name as FeatureNames));
                }
            });
        });
    }
}
