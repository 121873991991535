import { Component, Input, OnInit } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { QuoteUrlService } from '../../../../service-catalog/services/quote-url.service';
import { SfdcQuote, ServiceCatalogQuote, SfdcQuoteServiceV5 } from '@pure1/data';
import { take } from 'rxjs/operators';
import { OrdersUpdateService } from '../../../../service-catalog/services/orders-update.service';

type QuoteAcceptanceState = 'Accepted' | 'Rejected' | 'Unaccepted';

const SUBMITTING_MSG = 'Submitting…';
const REQUEST_CHANGE_SUCCESS_MSG = 'Your request for change was successfully submitted.';
const ERROR_BACKEND_MSG = 'Something went wrong. Please try again or contact support if the problem persists.';

@Component({
    selector: 'purchase-order-quote',
    templateUrl: 'purchase-order-quote.component.html',
})
export class PurchaseOrderQuoteComponent implements OnInit {
    @Input() readonly salesFlow: ServiceCatalogQuote;
    @Input() readonly quote: SfdcQuote;
    @Input() readonly salesFlowHasAnAcceptedQuote: boolean;

    readonly analyticsPrefix = 'PO-Quote - ';

    downloadUrl: string;
    negotiationCommentForRejectedQuote: string;
    doingWork = false;
    expandedForNegotiation = false;
    expandedForAttachment = false;
    hoverState = false;
    hasPOfiles = false;
    requestChangeSubmissionStatusMessage: string = null;

    constructor(
        private angulartics2: Angulartics2,
        private ordersUpdateService: OrdersUpdateService,
        private quoteUrlService: QuoteUrlService,
        private sfdcQuoteService: SfdcQuoteServiceV5,
    ) {}

    ngOnInit(): void {
        this.negotiationCommentForRejectedQuote = this.quote.partnerComment;

        // expand attachment section if salesflow in progress and this is the accepted quote and they have no POs yet
        if (
            this.salesFlow.statusMessage === 'Quote in Progress' &&
            this.quote.partnerAcceptance === 'Accepted' &&
            !(this.salesFlow.purchaseOrders?.length > 0)
        ) {
            this.expandedForNegotiation = false;
            this.expandedForAttachment = true;
        }
    }

    generateUrlAndOpenNewWindow(salesFlowId: string, quoteId: string): void {
        this.quoteUrlService.openNewWindowOnDownloadUrlWith302Response(salesFlowId, quoteId);
        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'clicked to download a quote pdf',
            properties: {
                category: 'Action',
            },
        });
    }

    onTextChange(newText: string): void {
        this.negotiationCommentForRejectedQuote = newText;
    }

    getQuoteMainIcon(): string {
        if (this.quote.partnerAcceptance === 'Accepted') {
            return 'quote-accepted-checkmark-icon.svg';
        }

        if (this.quote.partnerAcceptance !== 'Accepted' && this.quote.partnerComment?.length > 0) {
            return 'quote-changes-requested-icon.svg';
        }

        // default icon
        return 'quote-default-pdf-icon.svg';
    }

    showQuoteRowActionButtons(): boolean {
        if (!this.salesFlow || !this.quote) {
            return false;
        }

        return this.hoverState || this.expandedForNegotiation || this.expandedForAttachment;
    }

    showEnabledButtons(): boolean {
        // show disabled buttons only if:
        //   - we have an accepted quote in the parent salesFlow's list of quotes AND
        //   - this is not the accepted quote AND
        //   - there are PO files.
        // Otherwise show enabled buttons
        return !(
            this.salesFlowHasAnAcceptedQuote &&
            this.quote.partnerAcceptance !== 'Accepted' &&
            this.salesFlow.purchaseOrders.length > 0
        );
    }

    quoteAcceptOrReject(quoteAcceptanceState: QuoteAcceptanceState): void {
        if (quoteAcceptanceState === 'Unaccepted' || quoteAcceptanceState === 'Accepted') {
            this.expandedForNegotiation = false;
            this.expandedForAttachment = false;
        }
        if (quoteAcceptanceState === 'Rejected') {
            this.requestChangeSubmissionStatusMessage = SUBMITTING_MSG;
        }
        const partialProperties = {
            salesFlowId: this.salesFlow.id,
            id: this.quote.id,
            partnerAcceptance: quoteAcceptanceState,
            partnerComment:
                quoteAcceptanceState === 'Rejected' && this.negotiationCommentForRejectedQuote
                    ? this.negotiationCommentForRejectedQuote
                    : '',
        };

        this.doingWork = true;
        this.sfdcQuoteService
            .update(partialProperties)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.ordersUpdateService.refreshQuotes$.next(null);
                    this.doingWork = false;
                    if (this.requestChangeSubmissionStatusMessage === SUBMITTING_MSG) {
                        this.requestChangeSubmissionStatusMessage = REQUEST_CHANGE_SUCCESS_MSG;
                    } else {
                        this.requestChangeSubmissionStatusMessage = null;
                    }
                    if (quoteAcceptanceState === 'Accepted') {
                        this.expandedForNegotiation = false;
                        this.expandedForAttachment = true;
                    }
                    this.angulartics2.eventTrack.next({
                        action:
                            this.analyticsPrefix + 'successfully changed quote acceptance to: ' + quoteAcceptanceState,
                        properties: {
                            category: 'Action',
                        },
                    });
                },
                error: err => {
                    this.doingWork = false;
                    if (this.requestChangeSubmissionStatusMessage === SUBMITTING_MSG) {
                        this.requestChangeSubmissionStatusMessage = ERROR_BACKEND_MSG;
                    }
                    console.error('Sfdc Quote: failed to submit change request', err);

                    this.angulartics2.eventTrack.next({
                        action: this.analyticsPrefix + 'failed to change quote acceptance to: ' + quoteAcceptanceState,
                        properties: {
                            category: 'Action',
                        },
                    });
                },
            });

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'changing quote acceptance to: ' + quoteAcceptanceState,
            properties: {
                category: 'Action',
            },
        });
    }

    toggleQuoteExpansionForNegotiation(): void {
        // always clear status msgs when collapsing/expanding the request change area
        this.requestChangeSubmissionStatusMessage = null;

        // collapse when there are PO files
        if (this.hasPOfiles) {
            this.expandedForNegotiation = false;
            return;
        }

        // collapse the attachment expansion if we are expanding the request change area
        if (!this.expandedForNegotiation && this.expandedForAttachment) {
            this.expandedForAttachment = false;
        }
        this.expandedForNegotiation = !this.expandedForNegotiation;
    }

    toggleQuoteExpansionForAttachment(): void {
        if (!this.expandedForAttachment && this.expandedForNegotiation) {
            this.expandedForNegotiation = false;
        }
        this.expandedForAttachment = !this.expandedForAttachment;
    }

    getRequestChangeExitButtonLabel(): string {
        if (this.expandedForNegotiation && this.negotiationCommentForRejectedQuote === this.quote.partnerComment) {
            return 'Close';
        }

        return 'Cancel';
    }

    isCommentSubmitDisabled(): boolean {
        // busy submitting
        if (this.doingWork) {
            return true;
        }

        // no comment change
        if (this.negotiationCommentForRejectedQuote === this.quote.partnerComment) {
            return true;
        }

        // can enable submit
        return false;
    }
}
