<ng-select
    (change)="onValueChange($event)"
    (blur)="onTouched()"
    [ngModel]="loading ? null : value"
    [loading]="loading"
    [hideSelected]="true"
    [disabled]="disabled"
    [multiple]="true"
    [searchable]="false"
    [groupBy]="groupByFn"
    [clearable]="false"
    [placeholder]="placeholder"
    [closeOnSelect]="false"
    class="user-roles-select"
>
    <ng-template ng-optgroup-tmp let-group="item">
        <div class="group-title"> {{ group.label }} <span class="group-title-line"></span> </div>
    </ng-template>

    <ng-template ngFor let-item [ngForOf]="items">
        <ng-option *ngIf="shouldDisplayFn(item, value)" [disabled]="item.disabled" [value]="item.id">{{
            item.name
        }}</ng-option>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" [class.ng-value-disabled]="getItemField(item, 'disabled')" *ngFor="let item of value">
            <span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label">{{ getItemField(item, 'name') }}</span>
        </div>
    </ng-template>
</ng-select>

<p *ngIf="controlError" class="error-message">{{ errorMessage }}</p>
