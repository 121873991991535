import { LicenseType } from './license';
import { ProgramType } from './subscription';
import { Resource } from '../interfaces/resource';
import { SfdcPurchaseOrder } from './sfdc-purchase-order';
import { SfdcQuote } from './sfdc-quote';
import moment from 'moment';
import { PartnerRequest } from './partner';

export interface Workload {
    name: string;
}

export enum QuoteType {
    POC = 'POC',
    REGULAR_QUOTE = 'REGULAR_QUOTE',
    FLEX = 'FLEX',
    EXPANSION = 'EXPANSION',
    CAPEX = 'CAPEX',
    RENEWAL = 'RENEWAL',
    SUPPORT_CONTRACT_RENEWAL = 'SUPPORT_CONTRACT_RENEWAL',
    UNKNOWN = 'UNKNOWN',
}

export interface QuoteStage {
    name: string;
    lastUpdated: moment.Moment;
    category: 'Editable' | 'In process' | 'Succeeded' | 'Failed';
}

export type ProductFamily = 'FlashArray' | 'FlashBlade' | 'Professional Service' | 'Cohesity' | 'OEM';
export type ProductType = 'Array' | 'Shelf' | 'Data Pack' | 'ARRAY-ISCSI' | 'ARRAY-FC';
export type OrderRequestType = 'Quote' | 'POC Trial' | 'Trial' | 'Free Trial' | 'Free License' | 'Unknown';
export type OrderCategory =
    | 'New Subscription'
    | 'Subscription Expansion'
    | 'Subscription Expand & Renew'
    | 'Subscription Renewal'
    | 'Support Contract Upgrade'
    | 'Support Contract Expansion'
    | 'Support Contract Renewal'
    | 'Professional Services'
    | 'New Hardware'
    | 'Hardware Expansion'
    | 'Unknown';

export class QuoteProduct {
    productSku: string;
    serviceName: string;
    groupName: string;
    productName: string;
    fullProductName: string;
    quotingOptionName: string;

    constructor(json: any) {
        this.productSku = json.product_sku;
        this.serviceName = json.service_name;
        this.groupName = json.group_name;
        this.productName = json.product_name;
        this.fullProductName = json.full_product_name;
        this.quotingOptionName = json.quoting_option_name;
    }
}

export class PartnerContact {
    id: string;
    name: string;
    phone: string;
    email: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.phone = json.phone;
        this.email = json.email;
    }
}

export class LicenseArrayCapacity {
    raw: number;
    usable: number;
    euc: number;
    model?: string;
}

export class LicenseUpdate {
    id: string;
    siteName: string;
    newSite: boolean;
    licenseType: LicenseType;
    additionalAmount: number;
    originalAmount: number;
    performance?: number;
    startDate: moment.Moment;
    licenseArrayCapacities?: LicenseArrayCapacity[]; // Used for Flex, can have multiple products per license
    productSku?: string;
    workload: Workload;
    isPre: boolean;
    subscriptionProductId?: string;
    productIds?: string[];

    constructor(json: any) {
        this.id = json.license_id;
        this.siteName = json.site_name;
        this.newSite = json.new_site;
        this.licenseType = json.license_type;
        this.additionalAmount = json.additional_amount;
        this.originalAmount = json.original_amount;
        this.performance = json.performance;
        this.startDate = json.start_date ? moment(json.start_date).utc() : null;
        this.licenseArrayCapacities = json.license_array_capacities;
        this.productSku = json.product_sku || null;
        this.workload = json.workload;
        this.isPre = json.is_pre;
        this.subscriptionProductId = json.subscription_product_id;
        this.productIds = json.product_ids || [];
    }
}

export class OrderLineItem {
    id: string;
    productCategory: string;
    productFamily: ProductFamily;
    productType: ProductType;
    productModel: string;
    productName: string;
    quantity: number;

    constructor(json: any) {
        this.id = json.id;
        this.productCategory = json.product_category;
        this.productFamily = json.product_family;
        this.productType = json.product_type;
        this.productModel = json.product_model;
        this.productName = json.product_name;
        this.quantity = json.quantity;
    }
}

export class ServiceCatalogOrder {
    id: string;
    pure1OrderStatus: string;
    subscriptionId: string;
    shipToAddress: string;
    trackingNumber: string;
    viewTracking: string;
    shippingMethod: string;
    stages: QuoteStage[];
    orderLines: OrderLineItem[];

    constructor(json: any) {
        this.id = json.id;
        this.pure1OrderStatus = json.pure1_order_status;
        this.subscriptionId = json.subscription_id;
        this.shipToAddress = json.ship_to_address;
        this.trackingNumber = json.tracking_number;
        this.viewTracking = json.view_tracking;
        this.shippingMethod = json.shipping_method;
        this.stages = (json.stages || []).map(stage => {
            return {
                name: stage.name,
                lastUpdated: stage.last_updated ? moment(stage.last_updated).utc() : null,
                category: stage.category,
            };
        });
        this.orderLines = (json.order_lines || []).map(line => new OrderLineItem(line));
    }
}
export type OrderArrayConfigDatapack = { single_column: boolean; current_TB: number; new_TB: number; type: string };
export type OrderArrayConfigShelf = { datapacks: OrderArrayConfigDatapack[]; type: string };
export type OrderArrayConfigChassis = { datapacks: OrderArrayConfigDatapack[] };

export class OrderArrayConfig {
    capacityConfiguration: {
        chassis: OrderArrayConfigChassis;
        shelves: OrderArrayConfigShelf[];
    };
    fqdn: string;
    id: string;
    increasedRawTB: number;
    increasedUsableTiB: number;
    model: string;
    name: string;
    newArray: boolean;
    newModel: string;

    constructor(json: any) {
        this.capacityConfiguration = json.capacity_configuration;
        this.fqdn = json.fqdn;
        this.id = json.id;
        this.increasedRawTB = json.increased_raw_TB;
        this.increasedUsableTiB = json.increased_usable_TiB;
        this.model = json.model;
        this.name = json.name;
        this.newArray = json.new_array;
        this.newModel = json.new_model;
    }
}

export class SupportContractArrayInfo {
    array_id: string;
    array_name: string;
    model: string;
    expiration_date: moment.Moment;
    supported_date: moment.Moment;
    array_utilization: number;
    evergreen_support_subscription: string;
    customer_requested: boolean;
    org_id: number;
    array_version: string;
    chassis_serial_number: string;

    constructor(json: any) {
        this.array_id = json.array_id;
        this.array_name = json.array_name;
        this.model = json.model;
        this.expiration_date = json.expiration_date ? moment.utc(json.expiration_date) : null;
        this.supported_date = json.supported_date ? moment.utc(json.supported_date) : null;
        this.array_utilization = json.array_utilization;
        this.evergreen_support_subscription = json.evergreen_support_subscription;
        this.customer_requested = json.customer_requested;
        this.org_id = json.org_id;
        this.array_version = json.array_version;
        this.chassis_serial_number = json.chassis_serial_number;
    }
}

type LicenseExpansionQuoteRequestJson = {
    quote_type: QuoteType.EXPANSION;
    service_name: ProgramType.EVERGREEN_ONE;
    program_type: ProgramType; // can this be stricter?
    request_type: 'Quote';
    order_category: 'Subscription Expansion';
    [others: string]: any; // this is a catch-all for any other fields that might be added, but unclear if required
};

type RenewalQuoteRequestJson = {
    quote_type: QuoteType.RENEWAL;
    request_type: 'Quote';
    subscription_id: string;
    order_category: 'New Subscription' | 'Subscription Renewal';
    [others: string]: any; // this is a catch-all for any other fields that might be added, but unclear if required
};

type QuoteRequestJson = {
    quote_type: QuoteType.REGULAR_QUOTE;
    request_type: 'Quote';
    program_type: ProgramType;
    license_type: LicenseType;
    [others: string]: any; // this is a catch-all for any other fields that might be added, but unclear if required
};

type TrialRequestJson = {
    quote_type: QuoteType.POC;
    order_category: 'New Subscription';
    [others: string]: any; // this is a catch-all for any other fields that might be added, but unclear if required
};

export class ServiceCatalogQuote implements Resource {
    id: string;
    arrayConfig?: OrderArrayConfig;
    name: string;
    quoteType: QuoteType;
    programType: ProgramType;
    licenseType: LicenseType;
    reservedAmount: number;
    reservedUnit: string;
    onDemand: boolean;
    termInMonth: number;
    comment: string;
    productSku: string;
    // only used for trial request
    productType: string;
    serviceName: string;
    groupName: string;
    productName: string;
    quotingOptionName: string;
    orgId: number;
    orgName: string;
    stages: QuoteStage[];
    completed: boolean;
    lastUpdated: moment.Moment;
    requestedAt: moment.Moment;
    requestedBy: string;
    requestedByName: string;
    statusMessage: string;
    currentStageProgress: number;
    maxStagesCount: number;
    orderRequestId: string;
    fullProductName: string;
    fullProductType: string;
    orders: ServiceCatalogOrder[];
    products: QuoteProduct[];
    requestType: OrderRequestType;
    licenseUpdates: LicenseUpdate[];
    subscriptionId: string;
    subscriptionName: string;
    orderCategory: OrderCategory;
    quoteCustomerEmails: string[];
    editable: boolean;
    supportContractArrayInfos: SupportContractArrayInfo[];
    pure1Acknowledged: boolean;
    purchaseOrders: SfdcPurchaseOrder[];
    quotes: SfdcQuote[];
    permissions: string[];
    self_serve_renewal: boolean;
    initialEvergreenSupportSubscription: string;
    evergreenSupportSubscription: string;
    partnerAe: PartnerContact;
    partnerOrgName: string;
    partnerRequest: PartnerRequest;
    sfdcStage: string;
    sfdcStageNumber: number;
    isFieldLed: boolean;
    orderType: string;
    opportunityClosed: boolean;
    pure1LeadSource: string;

    // Derived fields
    compoundedName: string;
    updatedFromOriginal: boolean;

    static fromJson(json: any): ServiceCatalogQuote {
        return new ServiceCatalogQuote(json);
    }

    static fromLicenseExpansionRequestJson(json: LicenseExpansionQuoteRequestJson): ServiceCatalogQuote {
        return new ServiceCatalogQuote(json);
    }

    static fromRenewalRequestJson(json: RenewalQuoteRequestJson): ServiceCatalogQuote {
        return new ServiceCatalogQuote(json);
    }

    static fromQuoteRequestJson(json: QuoteRequestJson): ServiceCatalogQuote {
        return new ServiceCatalogQuote(json);
    }

    static fromTrialRequestJson(json: TrialRequestJson): ServiceCatalogQuote {
        return new ServiceCatalogQuote(json);
    }

    constructor(json: any) {
        this.id = json.id;
        this.arrayConfig = json.array_details ? new OrderArrayConfig(json.array_details) : null;
        this.name = json.license_type;
        this.quoteType = json.quote_type;
        this.programType = json.program_type;
        this.licenseType = json.license_type;
        this.reservedAmount = json.reserved_amount;
        this.reservedUnit = json.reserved_unit;
        this.onDemand = json.on_demand;
        this.termInMonth = json.term_in_month;
        this.comment = json.comment;
        this.productSku = json.product_sku;
        this.productType = json.product_type;
        this.serviceName = json.service_name;
        this.groupName = json.group_name;
        this.productName = json.product_name;
        this.quotingOptionName = json.quoting_option_name;
        this.orgId = json.org_id;
        this.orgName = json.org_name;
        this.stages =
            json.stages?.map(stage => {
                return {
                    name: stage.name,
                    lastUpdated: stage.last_updated ? moment(stage.last_updated).utc() : null,
                    category: stage.category,
                };
            }) || [];
        this.completed = json.completed;
        this.lastUpdated = json.last_updated;
        this.requestedAt = json.requested_at;
        this.requestedBy = json.requested_by;
        this.requestedByName = json.requested_by_name;
        this.statusMessage = json.status_message;
        this.currentStageProgress = json.current_stage_progress ? json.current_stage_progress * 100 : 0.0;
        this.maxStagesCount = json.max_stages_count || (this.stages || []).length;
        this.orderRequestId = json.order_request_id;
        this.quoteCustomerEmails = json.quote_customer_emails || [];
        this.fullProductName = json.full_product_name;
        this.fullProductType = json.full_product_type;
        this.orders = json.orders?.map(order => new ServiceCatalogOrder(order)) || [];
        this.subscriptionId = json.subscription_id;
        this.subscriptionName = json.subscription_name;
        this.products = json.products
            ? json.products.filter(product => !!product).map(product => new QuoteProduct(product))
            : null;
        this.licenseUpdates = json.license_updates
            ? json.license_updates.map(update => new LicenseUpdate(update))
            : null;
        this.requestType = json.request_type;
        this.orderCategory = json.order_category;
        this.editable = json.editable;
        this.supportContractArrayInfos = json.support_contract_array_infos
            ? json.support_contract_array_infos.map(arrayInfo => new SupportContractArrayInfo(arrayInfo))
            : null;
        this.pure1Acknowledged = json.pure1_acknowledged;
        this.purchaseOrders = json.purchase_orders
            ? json.purchase_orders.map(po => new SfdcPurchaseOrder(po, this.id))
            : null;
        this.quotes = json.quotes ? json.quotes.map(quote => new SfdcQuote(quote, this.id)) : null;
        this.permissions = json.permissions;
        this.self_serve_renewal = json.self_serve_renewal;
        this.initialEvergreenSupportSubscription = json.initial_evergreen_support_subscription;
        this.evergreenSupportSubscription = json.evergreen_support_subscription;
        this.partnerAe = json.partner_ae;
        this.partnerOrgName = json.partner_org_name;
        this.partnerRequest = json.partner_request ? new PartnerRequest(json.partner_request) : null;
        this.sfdcStage = json.sfdc_stage;
        this.sfdcStageNumber = this.parseSfdcStage(json.sfdc_stage);
        this.isFieldLed = json.is_field_led;
        this.orderType = json.is_field_led ? 'Assisted' : 'Self-service';
        this.opportunityClosed = json.opportunity_closed;
        this.pure1LeadSource = json.pure1_lead_source;

        this.compoundedName = this.parseCompoundedName(json.products);
    }

    private parseCompoundedName(products: QuoteProduct[]): string {
        const capexProductName = products
            ? products
                  .filter(product => !!product)
                  .map(product => new QuoteProduct(product))[0]
                  ?.productName.substring(0, 3)
            : null;
        //Since for capex orders, they used  "FA/FB" send to backend instead of the real service name
        //In order to match orders compoundedName with offerings compoundedName, we have to parse it here
        if (this.isCapexQuote()) {
            return this.serviceName === 'FA'
                ? 'FlashArray' + this.serviceName + capexProductName
                : 'FlashBlade' + this.serviceName + capexProductName;
        }
        return this.serviceName + this.groupName + this.productName + (this.licenseType ?? '');
    }

    private parseSfdcStage(stage: string): number {
        // New stage names defined CLOUD-99538
        return stage == null ? null : stage.startsWith('Stage') ? Number(stage[6]) : Number(stage[0]);
    }

    isCapexQuote(): boolean {
        return this.serviceName === 'FA' || this.serviceName === 'FB';
    }
}
