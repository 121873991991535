import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const DO_NOT_SHOW_INTRODUCING_IDENTITY_CENTER_MODAL = 'do-not-show-introducing-identity-center-modal';

@Component({
    selector: 'introducing-identity-center',
    templateUrl: `./introducing-identity-center.component.html`,
})
export class IntroducingIdentityCenterComponent {
    protected doNotShowAgain = false;
    private readonly activeModal = inject(NgbActiveModal);

    protected close(doNotShowAgain?: boolean): void {
        this.activeModal.close(doNotShowAgain ?? this.doNotShowAgain);
    }
}
