<div
    class="eol-overlay-container"
    [ngClass]="{ expanded: hovering && opportunityStatus === 'unopened' }"
    *ngIf="!overlayDismissed"
>
    <pureui-svg
        class="close-overlay pstg-action-icon"
        svg="x-action.svg"
        height="9"
        (click)="dismissOverlay()"
    ></pureui-svg>
    <div class="overlay-message" [ngSwitch]="opportunityStatus">
        <div class="contact-account-team-container" *ngSwitchCase="'unopened'">
            <div class="message-text">
                A component on this appliance has an EOL date of
                {{ array.end_of_life_hardware.earliest_eol_date | date: 'MMMM d, YYYY' : 'UTC' }}
            </div>
            <div class="hover-button-container" [hidden]="!hovering">
                <button
                    type="button"
                    class="btn btn-primary"
                    *ngIf="!incidentOpened || !hasPermissionToViewRecommendation(); else viewRecommendation"
                    [disabled]="('PURE1:write:support_cases' | isNotAllowed) || loading || !hwEolInfo"
                    (click)="clickContactAccountTeam()"
                >
                    Contact Account Team
                </button>
                <ng-template #viewRecommendation>
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="loading || !hwEolInfo || !hasPermissionToViewRecommendation()"
                        (click)="clickViewRecommendation()"
                    >
                        View Recommendation
                    </button>
                </ng-template>
            </div>
        </div>
        <div *ngSwitchCase="'opened'">
            <div class="team-contacted-message message-text">
                We have received your message and our account team will get in touch with you.
            </div>
        </div>
        <div *ngSwitchCase="'unknown'">
            <pureui-spinner [pureuiShow]="true" class="p-loading"></pureui-spinner>
        </div>
    </div>
</div>
