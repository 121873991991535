<div
    class="dismiss-button"
    (click)="close()"
    angulartics2On="click"
    angularticsAction="Step Up - Enrollment Modal - close modal"
>
    &#x2715;
    <!-- "✕" -->
</div>
<div class="img-col">
    <pureui-svg svg="step-up-verification-composite.svg" height="152"></pureui-svg>
</div>
<div class="text-col">
    <div class="title">Authentication Required</div>
    <div class="text"
        >We've added an extra level of security against unauthorized logins and access to sensitive functionality.<br />
        <br />
        You must set up step-up authentication in your user profile. Pure1 will ask for a temporary one time passcode
        when authentication is required.<br />
        <br />
    </div>
    <a
        [href]="STEP_UP_SUPPORT_LINK"
        target="_blank"
        angulartics2On="click"
        angularticsAction="Step Up - Enrollment Modal - learn more"
        angularticsCategory="Action"
    >
        Learn More
    </a>
</div>
