<form [formGroup]="upgradeForm">
    <div class="row" *ngIf="arrays && arrays.length > 0">
        <div class="col-xs-3">
            <div class="row selected-appliances">
                <div class="col-xs-12">
                    <ul class="selected-appliances-list">
                        <li
                            *ngFor="let versionedArray of arrays; index as i; trackBy: trackByArrayId"
                            [class.selected-array]="versionedArray === selectedArray"
                            (click)="setSelectedArray(versionedArray)"
                        >
                            <div class="array-slot-name">
                                <div class="array-name">
                                    <span class="dot" [ngClass]="'dot-' + (i + 1)"></span>
                                    <span class="replication-icon-wrapper" *ngIf="versionedArray.secondaryArray">
                                        <pureui-svg svg="pod.svg" height="10" class="replication-icon"></pureui-svg>
                                    </span>
                                    <div *ngIf="versionedArray.secondaryArray">
                                        <div class="clustered-array-name primary">
                                            <span class="connector"></span>
                                            {{ getArrayName(versionedArray.array) }}
                                        </div>
                                        <div class="clustered-array-name secondary">
                                            <span class="connector"></span>
                                            {{ getArrayName(versionedArray.secondaryArray) }}
                                        </div>
                                    </div>
                                    <span class="clustered-array-name" *ngIf="!versionedArray.secondaryArray">{{
                                        getArrayName(versionedArray.array)
                                    }}</span>
                                </div>
                                <pureui-svg
                                    *ngIf="selectedTimeslots[versionedArray.array.applianceId]"
                                    class="pstg-action-icon tick-icon"
                                    svg="tick.svg"
                                    height="12"
                                ></pureui-svg>
                                <pureui-svg
                                    *ngIf="versionedArray.numberOfHops > maxHops"
                                    class="pstg-action-icon tick-icon warning"
                                    svg="tick.svg"
                                    height="12"
                                ></pureui-svg>
                            </div>
                            <span class="version"
                                >{{ versionedArray.array.currentVersion }} &gt; {{ versionedArray.targetVersion }}
                                <pureui-svg
                                    *ngIf="hasSsuAvailable(versionedArray.array.currentVersion)"
                                    height="10"
                                    svg="info-circle.svg"
                                    container="body"
                                    ngbTooltip="Check out the Self-Service Upgrade option."
                                ></pureui-svg>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="currentUserLoading || timeslotsLoading" class="col-xs-9 loader-column">
            <div class="row loader">
                <pureui-spinner [pureuiShow]="true"></pureui-spinner>
            </div>
        </div>
        <div [hidden]="timeslotsLoading || currentUserLoading" class="col-xs-9 px-2">
            <ng-container *ngIf="selectedArray.numberOfHops <= maxHops; else hopsWarning">
                <div class="row">
                    <div class="col-xs-5 calendar-wrapper">
                        <select
                            class="btn btn-default-focus btn-sm"
                            formControlName="timezone"
                            angulartics2On="change"
                            analyticsCategory="Action"
                            angularticsAction="Support - Bulk array upgrade - Timezone changed"
                        >
                            <option [value]="timezone" *ngFor="let timezone of tzList; trackBy: trackByIndex">
                                {{ getTimezonePrettyName(timezone) }}
                            </option>
                        </select>
                        <ngb-datepicker
                            #dp
                            navigation="arrows"
                            [(ngModel)]="selectedDate"
                            [ngModelOptions]="{ standalone: true }"
                            (dateSelect)="onDateSelection($event)"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [markDisabled]="makeMarkDayDisabled"
                            [dayTemplate]="d"
                            (navigate)="onDatepickerNavigate($event)"
                        ></ngb-datepicker>
                        <ng-template
                            #d
                            let-date
                            let-currentMonth="currentMonth"
                            let-disabled="disabled"
                            let-focused="focused"
                        >
                            <div
                                class="custom-day"
                                [class.focused]="focused"
                                [class.disabled]="disabled"
                                [class.selected]="date.equals(selectedDate)"
                                [class.outside]="date.month !== currentMonth"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null"
                            >
                                <div class="dot-wrapper">
                                    <div class="bar" *ngIf="numberOfArraysInDate(date) > 3; else dotsTpl"></div>
                                    <ng-template #dotsTpl>
                                        <ng-container *ngFor="let versionedArray of arrays; index as i">
                                            <span
                                                *ngIf="isArrayInDay(versionedArray, date)"
                                                class="dot"
                                                [ngClass]="'dot-' + (i + 1)"
                                            ></span>
                                        </ng-container>
                                    </ng-template>
                                </div>
                                {{ date.day }}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-xs-7" *ngIf="timeslots && selectedDate">
                        <upgrade-timeslot-picker
                            [(day)]="selectedDate"
                            [control]="$any(upgradeForm.controls.timeslot)"
                            [timezone]="upgradeForm.controls.timezone.value"
                            [scrollToTimeslot]="scrollToTimeslot"
                            [sortedTimeslots]="filteredTimeslots"
                            [selectedTimeSlots]="selectedTimeslots"
                            [selectedArray]="selectedArray"
                            [arrays]="arrays"
                            [replicationTargets]="replicationTargets"
                            (dayChange)="dp.navigateTo($event)"
                        >
                        </upgrade-timeslot-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 mt-1">
                        <div *ngIf="selectedArray" class="alert alert-info" role="alert">
                            If the requested times become unavailable before you have confirmed the request, you will be
                            notified and provided the ability to re-select a requested time for that appliance.
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #hopsWarning>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="alert alert-warning" role="alert">
                            Upgrade of this array to version {{ selectedArray.targetVersion }} will require additional
                            planning. Because of that you'll schedule upgrade of this array directly with our Support.
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</form>

<div class="btn-toolbar clearfix">
    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        (click)="continue()"
        [disabled]="!canContinue()"
    >
        Confirm &gt;
    </button>

    <button type="button" class="btn btn-primary float-xs-right margin" (click)="onBackWrapper()"> &lt; Back </button>
    <button type="button" class="btn btn-info float-xs-right margin" (click)="onCancel.emit()"> Cancel </button>
</div>
