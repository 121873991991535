import { Component, OnChanges, SimpleChanges } from '@angular/core';
import {
    GroupsService,
    Group,
    RoleAssignmentService,
    RoleType,
    ResetPasswordService,
    ViewsService,
    ViewReference,
    AdditionalRole,
    AdditionalUserRolesService,
    UserRoleID,
    AdditionalRoleAssignment,
} from '@pure1/data';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';

import { BaseAssignmentFormComponent } from '../base-assignment-form/base-assignment-form.component';
import { UserRoleStateService } from '../services/user-role-state.service';
import { ToastService } from '../../services/toast.service';
import { Assignment } from '../user-role-management.interface';
import { Observable, defer, finalize, takeUntil } from 'rxjs';
import { userRoleItemsFactory } from '../helpers/user-role-items-factory';
import { UserRoleItem } from '../user-roles-select/user-role-item';
import { isLegacyRole } from '../helpers/is-legacy-role';
import { shouldDisplayRole } from '../helpers/should-display-role';
import { hasLegacyRoleValidator } from '../helpers/has-legacy-role-validator';

@Component({
    selector: 'group-form',
    templateUrl: './group-form.component.html',
    host: {
        class: 'base-assignment-form',
    },
})
export class GroupFormComponent extends BaseAssignmentFormComponent<Group> implements OnChanges {
    newGroup: Group;
    /**
     * @deprecated role based authorization is going to be migrated to permission based.
     * Reach out to #ask-pure1-security-authz for more information.
     */
    isAdminGroup = false;
    loadingRoles = false;
    availableAdditionalRoles: UserRoleItem[] = [];

    readonly roleMap = new Map<UserRoleID, UserRoleItem>();

    readonly shouldDisplayRoleFn = (item: UserRoleItem, selectedRoles: UserRoleID[]): boolean => {
        return item.id !== RoleType.PURE1_ADMIN && shouldDisplayRole(item, selectedRoles);
    };

    constructor(
        groupsService: GroupsService,
        viewsService: ViewsService,
        roleAssignmentService: RoleAssignmentService,
        resetPasswordService: ResetPasswordService,
        modalService: NgbModal,
        urStateService: UserRoleStateService,
        angulartics2: Angulartics2,
        toast: ToastService,
        mpaRequestsBadgeService: MpaRequestsBadgeService,
        private readonly additionalUserRolesService: AdditionalUserRolesService,
        private fb: UntypedFormBuilder,
    ) {
        super(
            viewsService,
            groupsService,
            roleAssignmentService,
            urStateService,
            angulartics2,
            resetPasswordService,
            modalService,
            toast,
            mpaRequestsBadgeService,
        );
    }

    ngOnInit(): void {
        this.loadAdditionalRoles()
            .pipe(takeUntil(this.destroy$))
            .subscribe(additionalRoles => {
                this.generateAvailableRoles(additionalRoles);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isGroupForm = true;
        this.updateAssignmentObject(this.assignment);
        this.isAdminGroup = this.newGroup.role === RoleType.PURE1_ADMIN;

        this.assignmentForm = this.fb.group({
            roles: [
                this.collectRoleIds(this.newGroup.role, this.newGroup.additionalRoles),
                [Validators.required, hasLegacyRoleValidator],
            ],
            view: [{ value: this.newGroup.view, disabled: this.newGroup.role === RoleType.PURE1_ADMIN }],
            name: [{ value: this.newGroup.name, disabled: this.isEdit }, Validators.required],
        });

        // restore any changes that were in place if we left to add a view
        super.ngOnChanges(changes);

        this.getViews();
    }

    collectRoleIds(role: UserRoleID, additionalRoles: AdditionalRole[]): UserRoleID[] {
        const roleIds: UserRoleID[] = [...additionalRoles.map(role => role.id)];

        if (role) {
            roleIds.unshift(role);
        }

        return roleIds;
    }

    getLegacyRoles(): RoleType[] {
        return [
            RoleType.PURE1_ADMIN,
            RoleType.DASHBOARD_VIEWER,
            RoleType.VM_VIEWER,
            RoleType.PURE1_VIEWER,
            RoleType.PORTWORX_ADMIN,
        ];
    }

    copyAssignmentFields(newAssignment: Group): void {
        if (this.isEdit) {
            // populate the not-editable fields
            newAssignment.id = this.assignment.id;
            newAssignment.name = this.assignment.name;
        }
    }

    updateAssignmentObject(assignment: Assignment): void {
        if (this.isEdit) {
            this.newGroup = assignment as Group;
        } else {
            this.newGroup = new Group({
                role: RoleType.PURE1_VIEWER,
            });
        }
    }

    makeAssignment(json: any): Group {
        const role = this.isAdminGroup ? null : json.roles.find(roleId => isLegacyRole(roleId));
        const additionalRoles = json.roles
            .filter(roleId => !isLegacyRole(roleId))
            .map(id => {
                const { name, domain, assignableToIdentityGroup } = this.roleMap.get(id);

                return { id, name, domain, assignableToIdentityGroup } as AdditionalRoleAssignment;
            });
        return new Group({
            ...json,
            role,
            additionalRoles,
        });
    }

    updateView(viewReference: ViewReference): void {
        this.newGroup.view = viewReference;
    }

    private loadAdditionalRoles(): Observable<AdditionalRole[]> {
        return defer(() => {
            this.loadingRoles = true;

            return this.additionalUserRolesService.list().pipe(
                finalize(() => {
                    this.loadingRoles = false;
                }),
            );
        });
    }

    private generateAvailableRoles(additionalRoles: AdditionalRole[]): void {
        const mixedRoles = [...this.getLegacyRoles(), ...additionalRoles];
        const userAdditionalRoles = this.assignment?.additionalRoles ?? [];
        this.availableAdditionalRoles = userRoleItemsFactory(
            mixedRoles,
            userAdditionalRoles,
            this.newGroup.role === RoleType.PURE1_ADMIN,
        ).filter(role => role.assignableToIdentityGroup);
        this.availableAdditionalRoles.forEach(role => {
            this.roleMap.set(role.id, role);
        });
    }
}
