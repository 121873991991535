import { Component, Input, OnInit } from '@angular/core';
import { AuthorizationServiceResolver, Permission } from '@pure/authz-authorizer';
import { CurrentUser, CachedCurrentUserService } from '@pure1/data';
import { forkJoin } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'impersonating-banner',
    templateUrl: 'impersonating-banner.component.html',
})
export class ImpersonatingBannerComponent implements OnInit {
    @Input() readonly permission: Permission;
    showBanner = false;
    message: string;

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private authzServiceResolver: AuthorizationServiceResolver,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.cachedCurrentUserService.get().pipe(take(1)),
            this.authzServiceResolver.getDefaultService().pipe(
                switchMap(service => service.hasPermission(this.permission)),
                take(1),
            ),
        ]).subscribe(([currentUser, hasPermission]) => {
            this.showBanner = currentUser.impersonating && (currentUser.adUser || !hasPermission);
            this.message = currentUser.adUser
                ? `You're seeing this page because you are a Pure Storage employee. The user ${currentUser.email} logs
                        in to Pure1 using SSO and can see this page only if they are an admin.`
                : `You're seeing this page because you are a Pure Storage employee. The user ${currentUser.name} does
                    not have access to this page.`;
        });
    }
}
