import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import { FeatureFlagDxpService } from '@pure1/data';
import { map, Observable } from 'rxjs';
import { FeatureNames } from '../../model/FeatureNames';

export const isFeaturePolicyEnabled =
    (featureName: FeatureNames): CanActivateFn =>
    (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
        const featureFlagDxpService = inject(FeatureFlagDxpService);
        return featureFlagDxpService
            .getFeatureFlag(featureName)
            .pipe(map((featurePolicy: FeatureFlagStatus) => featurePolicy?.enabled === true));
    };
