<div class="ur-container" [pureshadowscroll]="{ scrollElem: '.view-list', shadowElem: '.header-button-container' }">
    <div
        class="drawer-title manual-sort"
        [ngClass]="sortAscending ? 'st-sort-ascent' : 'st-sort-descent'"
        (click)="toggleSort()"
    >
        <span class="views-title">Views</span>
    </div>
    <div class="header-button-container">
        <button
            class="btn btn-secondary add-view-button"
            (click)="addNewView()"
            angulartics2On="click"
            angularticsAction="UM - Add new view"
            angularticsCategory="Action"
        >
            <pureui-svg class="pstg-action-icon create-view-btn-icon" svg="create_add.svg" height="11"></pureui-svg>
            <span class="btn-text">Create New View</span>
        </button>
    </div>
    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <div *ngIf="views.length === 0 && !loading" class="no-views-message">
        There are no views for your organization.
    </div>
    <div class="ur-body view-list">
        <div *ngFor="let view of views; let i = index; trackBy: 'id' | trackByProperty" class="view-content">
            <div
                class="view-name"
                (click)="editView(view)"
                angulartics2On="click"
                angularticsAction="UM - Edit view from view list"
                angularticsCategory="Action"
            >
                <a>{{ view.name }}</a>
                <view-selector class="view-filter" [selector]="view.selector"></view-selector>
            </div>

            <div
                class="view-options-button"
                [ngbPopover]="viewOperationsPopover"
                placement="left"
                popoverClass="view-options-popover"
                triggers="manual"
                autoClose="outside"
                container="body"
                #optionsPopover="ngbPopover"
                (click)="openOptionsPopover(view, optionsPopover)"
            >
                <pureui-svg svg="menu.svg" [height]="15" class="pstg-action-icon"></pureui-svg>
            </div>
        </div>
    </div>
</div>

<!-- popover template -->
<ng-template let-view="view" let-popover="popover" #viewOperationsPopover>
    <div class="popover-action-menu">
        <div
            class="menu-item edit-view"
            (click)="editView(view)"
            angulartics2On="click"
            angularticsAction="UM - Edit view from view list"
            angularticsCategory="Action"
        >
            Edit
        </div>

        <ng-template #deleteViewModal let-modal>
            <delete-view-modal [view]="view" [modal]="modal"></delete-view-modal>
        </ng-template>

        <div
            class="menu-item delete-view"
            (click)="openDeleteModal(popover, deleteViewModal)"
            angulartics2On="click"
            angularticsAction="UM - Delete view modal opened"
            angularticsCategory="Action"
        >
            Delete
        </div>
    </div>
</ng-template>
