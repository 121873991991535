<div class="view-dropdown" ngbDropdown #dropDown="ngbDropdown" autoClose="outside" placement="bottom">
    <div class="dropdown-button-container" [ngbTooltip]="dropdownDisabledMessage" container="body" placement="left">
        <button class="dropdown-button btn btn-secondary with-caret" ngbDropdownToggle [disabled]="dropdownDisabled">
            <span class="selected-option-text">{{ currentViewName() }}</span>
        </button>
    </div>
    <div class="dropdown-menu" ngbDropdownMenu>
        <div class="view-list">
            <ng-template #deleteViewModal let-modal>
                <delete-view-modal [view]="viewToDelete" [modal]="modal"></delete-view-modal>
            </ng-template>
            <div
                class="view-option"
                (click)="selectViewOption(null)"
                [ngClass]="{ selected: currentSelection === null }"
            >
                <div class="view-content">
                    <span class="view-name">Unassigned <span class="all-arrays-subtext">(All Arrays)</span></span>
                </div>
            </div>
            <div
                class="view-option"
                *ngFor="let view of views; trackBy: 'id' | trackByProperty"
                (click)="selectViewOption(view)"
                [ngClass]="{ selected: isSelected(view) }"
            >
                <div class="view-content">
                    <span class="view-name"> {{ view.name }} </span>
                    <view-selector class="view-selector" [selector]="view.selector"></view-selector>
                </div>

                <button
                    class="trash-icon-button"
                    angulartics2On="click"
                    angularticsAction="UM - View Dropdown - Delete view modal opened"
                    angularticsCategory="Action"
                    (click)="deleteView(view, deleteViewModal)"
                >
                    <pureui-svg svg="trash_erase.svg" [height]="15" class="pstg-action-icon"></pureui-svg>
                </button>
            </div>
            <div
                class="view-option"
                *ngIf="deferredAssignable"
                (click)="selectViewOption(deferredAssignable)"
                [ngClass]="{ selected: isSelected(deferredAssignable) }"
            >
                <div class="view-content">
                    <span class="view-name"> {{ deferredAssignable.name }} </span>
                    <view-selector class="view-selector" [selector]="deferredAssignable.selector"></view-selector>
                </div>
            </div>
        </div>
        <div class="dropdown-footer">
            <button
                class="btn btn-secondary add-view-button"
                [disabled]="addViewDisabled"
                (click)="addView()"
                angulartics2On="click"
                angularticsAction="UM - View Dropdown - Create view from view dropdown"
                angularticsCategory="Action"
            >
                <pureui-svg class="pstg-action-icon create-view-btn-icon" svg="create_add.svg" height="11"></pureui-svg>
                <span class="btn-text">Create New View</span>
            </button>
        </div>
    </div>
</div>
