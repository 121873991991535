<div class="ur-container">
    <div class="drawer-title" *ngIf="isEdit; else newGroupTitle"> {{ group.name }}</div>
    <ng-template #newGroupTitle>
        <div class="drawer-title">Create New Group</div>
    </ng-template>

    <div id="form" class="ur-body form">
        <div class="profile">
            <group-form
                [assignment]="group"
                [roleAssignment]="groupAssignment"
                [isEdit]="isEdit"
                [deferredAssignable]="urStateService.deferredAssignable"
                (groupCreated)="onGroupCreated()"
            ></group-form>
        </div>
    </div>
</div>
