import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FeatureFlagDxpService, UnifiedArray } from '@pure1/data';
import { orderBy } from 'lodash';
import { take } from 'rxjs/operators';
import { MessagesModalService } from '../../../messages/alerts/messages-modal/messages-modal.service';
import { FeatureNames } from '../../../model/FeatureNames';
import { severity } from '../../../utils/severity';
import { Theme } from '../../styles/theme';

@Component({
    selector: 'array-alerts-popover',
    templateUrl: 'array-alerts-popover.component.html',
})
export class ArrayAlertsPopoverComponent implements OnChanges, OnInit {
    @Input() readonly array: UnifiedArray;
    @Input() readonly alerts: IAlert[];
    @Input() readonly showArrayLink: boolean = true;

    readonly Theme = Theme;
    sortedAlerts: IAlert[];
    hwEOLEnabled = false;

    constructor(
        private messagesModal: MessagesModalService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.hwEOLEnabled = feature?.enabled === true;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.alerts) {
            this.updateSortedAlerts();
        }
    }

    alertCount(): number {
        return (this.alerts || []).length + (this.hasEolComponents() ? 1 : 0);
    }

    clickAlert(alert: IAlert): void {
        this.messagesModal.open(alert, alert.getKBLink());
    }

    hasEolComponents(): boolean {
        return this.hwEOLEnabled && !!this.array?.has_end_of_life_hardware;
    }

    private updateSortedAlerts(): void {
        this.sortedAlerts = orderBy(this.alerts || [], [
            alert => severity(alert.currentSeverity).value,
            alert => -alert.updated,
        ]);
    }
}
