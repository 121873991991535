import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ButtonComponent, ContentDialogComponent, SvgDirective } from '@pure/hive';
import { Pure4sightOnboardingService } from '@pure1/data';
import { Router } from '@angular/router';

@Component({
    templateUrl: './pure4sight-onboarding-modal.component.html',
    selector: 'pure4sight-onboarding-modal',
    standalone: true,
    imports: [ContentDialogComponent, SvgDirective, ButtonComponent],
})
export class Pure4sightOnboardingModalComponent implements AfterViewInit {
    @ViewChild('pure4sightOnboardingModalDialog') pure4sightOnboardingModalDialog: ContentDialogComponent;

    constructor(
        private pure4sightOnboardingService: Pure4sightOnboardingService,
        private router: Router,
    ) {}

    ngAfterViewInit(): void {
        this.pure4sightOnboardingService.modalVisible$.subscribe(modalVisible => {
            if (modalVisible) {
                this.pure4sightOnboardingModalDialog?.show();
            } else {
                this.pure4sightOnboardingModalDialog?.hide();
            }
        });
    }

    protected dismissModalDialog(): void {
        this.pure4sightOnboardingService.hideOnboardingModal();
    }

    protected ackOnboardingModal(): void {
        this.pure4sightOnboardingService.ackOnboardingModal();
        this.router.navigate(['/pure4sight']);
    }
}
