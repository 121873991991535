import moment from 'moment';
import { DraasApiVmJobExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryJobExecution, DisasterRecoveryJobExecutionStatus } from './disaster-recovery-job-execution';
import {
    DisasterRecoveryJobStepProgressWithBytes,
    isLocalCopyJobStepProgress,
    isReplicationJobStepProgress,
} from './disaster-recovery-vm-job-execution-step';

export class DisasterRecoveryVmJobExecution {
    parentJobExecution: DisasterRecoveryJobExecution;
    id: string;
    clusterId: string;
    vmName: string;
    executedOn?: moment.Moment;
    status: DisasterRecoveryJobExecutionStatus;
    groupName: string;
    start?: moment.Moment;
    end?: moment.Moment;
    vmwareToolsVersion: string;
    deleted: boolean;
    localCopyProgress: DisasterRecoveryJobStepProgressWithBytes | undefined;
    replicationProgress: DisasterRecoveryJobStepProgressWithBytes | undefined;

    constructor(json: DraasApiVmJobExecutionInfo, parentJobExecution: DisasterRecoveryJobExecution) {
        this.parentJobExecution = parentJobExecution;
        this.id = json.execution_info.id;
        this.clusterId = parentJobExecution.clusterId;
        this.vmName = json.vm?.name;
        this.executedOn = json.execution_info.executed_on ? moment.utc(json.execution_info.executed_on) : null;
        // for a recovery plan find the group name in a map (or use a group id if it's not there), for a protection group use a name of the parent job
        this.groupName = parentJobExecution.groupNames
            ? parentJobExecution.groupNames.get(json.group_id) || json.group_id
            : (this.groupName = parentJobExecution.name);
        this.status =
            DisasterRecoveryJobExecutionStatus[json.execution_info.status] ||
            DisasterRecoveryJobExecutionStatus.UNKNOWN;
        this.start = json.execution_info.start ? moment.utc(json.execution_info.start) : null;
        this.end = json.execution_info.end ? moment.utc(json.execution_info.end) : null;
        this.vmwareToolsVersion = json.vm?.vmware_tools_version;
        this.deleted = json.vm?.deleted;
        this.localCopyProgress = json.execution_info.progress_details
            ?.filter(isLocalCopyJobStepProgress)
            ?.map(progress => new DisasterRecoveryJobStepProgressWithBytes(progress))
            .pop();
        this.replicationProgress = json.execution_info.progress_details
            ?.filter(isReplicationJobStepProgress)
            ?.map(progress => new DisasterRecoveryJobStepProgressWithBytes(progress))
            .pop();
    }
}
