<form id="group-form" class="form-body" [formGroup]="assignmentForm">
    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <section id="group-details" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Overview</h1>
            <div class="edit-profile hover-effect" *ngIf="isReadonly && !isAdminGroup" (click)="beginEdit()">
                <pureui-svg class="edit-icon" svg="edit.svg" height="16"></pureui-svg>
            </div>
        </section>

        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="group-name">Group name</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showGroupNameInput">{{ newGroup.name }}</ng-container>
                <ng-template #showGroupNameInput>
                    <input
                        class="form-control"
                        id="group-name"
                        name="group-name"
                        type="text"
                        placeholder="Group Name *"
                        formControlName="name"
                    />
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-access-control" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Access Control</h1>
            <div class="edit-profile hover-effect" *ngIf="isReadonly" (click)="beginEdit()">
                <pureui-svg class="edit-icon" svg="edit.svg" height="16"></pureui-svg>
            </div>
        </section>

        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="user-role-assignment">Role</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showRoleSelect">
                    <show-all-roles [role]="newGroup.role" [additionalRoles]="newGroup.additionalRoles" />
                </ng-container>
                <ng-template #showRoleSelect>
                    <user-roles-select
                        formControlName="roles"
                        [items]="availableAdditionalRoles"
                        [loading]="loadingRoles"
                        [shouldDisplayFn]="shouldDisplayRoleFn"
                        [errorMessage]="assignmentForm.get('roles').errors | roleErrors"
                        id="user-role-assignment"
                    />
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="group-view-assignment">View</label>
            </div>

            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly || isAdminGroup; else showViewDropdown">{{
                    newGroup.view?.name || 'Unassigned (All Arrays)'
                }}</ng-container>
                <ng-template #showViewDropdown>
                    <view-dropdown
                        [views]="views"
                        [deferredAssignable]="deferredAssignable"
                        [addViewDisabled]="assignmentForm.controls.view.disabled"
                        [dropdownDisabled]="assignmentForm.controls.view.disabled"
                        [initialView]="$any(newGroup.view)"
                        (addViewSelected)="addView()"
                        (viewChanged)="selectView($event)"
                    >
                    </view-dropdown>
                </ng-template>
            </div>
        </div>
    </section>
    <section id="group-options" class="form-section" *ngIf="isEdit && !isAdminGroup">
        <div class="profile-options">
            <div class="btn-toolbar">
                <span
                    class="btn profile-option-button delete-account hover-effect"
                    (click)="openModal(deleteGroupsModal)"
                    angulartics2On="click"
                    angularticsAction="UM - Delete group"
                    angularticsCategory="Action"
                >
                    Remove Group
                </span>
            </div>
        </div>
    </section>
</form>

<ng-template #deleteGroupsModal let-modal>
    <delete-assignments-modal [assignments]="[roleAssignment]" [modal]="modal"> </delete-assignments-modal>
</ng-template>

<ng-container *ngIf="!isReadonly">
    <div class="form-footer">
        <button
            class="btn btn-primary float-xs-right save-button"
            (click)="create()"
            [disabled]="('PURE1:write:users' | isNotAllowed) || !assignmentForm.valid || loading"
            angulartics2On="click"
            angularticsAction="UM - Create group"
            angularticsCategory="Action"
        >
            Save
        </button>
        <button
            class="btn btn-info float-xs-right cancel-button"
            (click)="cancel()"
            [disabled]="loading"
            angulartics2On="click"
            angularticsAction="UM - Cancel create group"
            angularticsCategory="Action"
        >
            Cancel
        </button>
    </div>
</ng-container>
