import { AdditionalRole, RoleType, UserRoleID } from '@pure1/data';
import { ROLE_TYPE_MAP } from 'core/src/ui/pipes/role-type.pipe';

export class UserRoleItem {
    readonly id: UserRoleID;
    readonly name: string;
    readonly domain: string;
    readonly disabled: boolean;
    readonly assignableToIdentityGroup: boolean;

    static fromLegacy(role: RoleType, props?: Partial<UserRoleItem>): UserRoleItem {
        const defaultProps: UserRoleItem = {
            id: role,
            name: ROLE_TYPE_MAP.get(role),
            disabled: false,
            domain: '',
            assignableToIdentityGroup: true,
        };

        return new UserRoleItem({ ...defaultProps, ...props });
    }

    static fromAdditional(role: AdditionalRole, props?: Partial<UserRoleItem>): UserRoleItem {
        const defaultProps: UserRoleItem = {
            id: role.id,
            name: role.name,
            disabled: false,
            domain: role.domain,
            assignableToIdentityGroup: role.assignableToIdentityGroup,
        };

        return new UserRoleItem({ ...defaultProps, ...props });
    }

    constructor(obj: UserRoleItem) {
        Object.assign(this, obj);
    }
}
