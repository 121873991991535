import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Collection } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';
import { ApiAppKey } from '../models/api-app-key';

@Injectable({ providedIn: 'root' })
export class ApiAppKeyService implements Collection<ApiAppKey> {
    constructor(private http: HttpClient) {}

    list(): Observable<DataPage<ApiAppKey>> {
        return this.http.get<unknown[]>('/rest/v1/apiappkeys').pipe(map(response => this.makeDataPage(response)));
    }

    create(properties: Partial<ApiAppKey>): Observable<ApiAppKey> {
        return this.http
            .post<unknown[]>('/rest/v1/apiappkeys', properties)
            .pipe(map(response => new ApiAppKey(response)));
    }

    update(_properties: Partial<ApiAppKey>): Observable<DataPage<ApiAppKey>> {
        throw new Error('Not Supported');
    }

    delete(id: string): Observable<void> {
        return this.http.delete('/rest/v1/apiappkeys/' + encodeURIComponent(id)).pipe(map(() => void 0)); // Discard return value
    }

    private makeDataPage(wrapper: unknown[]): DataPage<ApiAppKey> {
        const apiAppKeys = wrapper.map(jsonPod => new ApiAppKey(jsonPod));
        return {
            total: apiAppKeys.length,
            response: apiAppKeys,
        };
    }
}
