// Generated from PureXPath.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');
var PureXPathListener = require('./PureXPathListener').PureXPathListener;
var grammarFileName = "PureXPath.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003!\u008c\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004",
    "\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f\u0004",
    "\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010\t\u0010\u0004",
    "\u0011\t\u0011\u0004\u0012\t\u0012\u0003\u0002\u0003\u0002\u0003\u0003",
    "\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0007\u0004,\n\u0004",
    "\f\u0004\u000e\u0004/\u000b\u0004\u0003\u0005\u0003\u0005\u0005\u0005",
    "3\n\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006",
    "\u0005\u0006:\n\u0006\u0003\u0006\u0003\u0006\u0007\u0006>\n\u0006\f",
    "\u0006\u000e\u0006A\u000b\u0006\u0003\u0007\u0003\u0007\u0003\b\u0003",
    "\b\u0003\b\u0007\bH\n\b\f\b\u000e\bK\u000b\b\u0003\t\u0003\t\u0003\n",
    "\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0005\nV\n\n\u0003",
    "\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0007\u000b]",
    "\n\u000b\f\u000b\u000e\u000b`\u000b\u000b\u0005\u000bb\n\u000b\u0003",
    "\u000b\u0003\u000b\u0003\f\u0003\f\u0003\f\u0003\f\u0005\fj\n\f\u0005",
    "\fl\n\f\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0003\u000e\u0007\u000e",
    "s\n\u000e\f\u000e\u000e\u000ev\u000b\u000e\u0003\u000f\u0003\u000f\u0003",
    "\u000f\u0007\u000f{\n\u000f\f\u000f\u000e\u000f~\u000b\u000f\u0003\u0010",
    "\u0003\u0010\u0003\u0010\u0007\u0010\u0083\n\u0010\f\u0010\u000e\u0010",
    "\u0086\u000b\u0010\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003",
    "\u0012\u0002\u0002\u0013\u0002\u0004\u0006\b\n\f\u000e\u0010\u0012\u0014",
    "\u0016\u0018\u001a\u001c\u001e \"\u0002\u0005\u0004\u0002\u0010\u0010",
    "\u0012\u0012\u0004\u0002\u0005\u0006\u0016\u0019\u0004\u0002\u0011\u0011",
    "!!\u0002\u0089\u0002$\u0003\u0002\u0002\u0002\u0004&\u0003\u0002\u0002",
    "\u0002\u0006(\u0003\u0002\u0002\u0002\b2\u0003\u0002\u0002\u0002\n4",
    "\u0003\u0002\u0002\u0002\fB\u0003\u0002\u0002\u0002\u000eD\u0003\u0002",
    "\u0002\u0002\u0010L\u0003\u0002\u0002\u0002\u0012U\u0003\u0002\u0002",
    "\u0002\u0014W\u0003\u0002\u0002\u0002\u0016k\u0003\u0002\u0002\u0002",
    "\u0018m\u0003\u0002\u0002\u0002\u001ao\u0003\u0002\u0002\u0002\u001c",
    "w\u0003\u0002\u0002\u0002\u001e\u007f\u0003\u0002\u0002\u0002 \u0087",
    "\u0003\u0002\u0002\u0002\"\u0089\u0003\u0002\u0002\u0002$%\u0005\u000e",
    "\b\u0002%\u0003\u0003\u0002\u0002\u0002&\'\u0005\u0006\u0004\u0002\'",
    "\u0005\u0003\u0002\u0002\u0002(-\u0005\b\u0005\u0002)*\u0007\b\u0002",
    "\u0002*,\u0005\b\u0005\u0002+)\u0003\u0002\u0002\u0002,/\u0003\u0002",
    "\u0002\u0002-+\u0003\u0002\u0002\u0002-.\u0003\u0002\u0002\u0002.\u0007",
    "\u0003\u0002\u0002\u0002/-\u0003\u0002\u0002\u000203\u0005\n\u0006\u0002",
    "13\u0005\f\u0007\u000220\u0003\u0002\u0002\u000221\u0003\u0002\u0002",
    "\u00023\t\u0003\u0002\u0002\u000249\u0005\"\u0012\u000256\u0007\f\u0002",
    "\u000267\u0005\"\u0012\u000278\u0007\r\u0002\u00028:\u0003\u0002\u0002",
    "\u000295\u0003\u0002\u0002\u00029:\u0003\u0002\u0002\u0002:?\u0003\u0002",
    "\u0002\u0002;<\u0007\u0010\u0002\u0002<>\u0005\n\u0006\u0002=;\u0003",
    "\u0002\u0002\u0002>A\u0003\u0002\u0002\u0002?=\u0003\u0002\u0002\u0002",
    "?@\u0003\u0002\u0002\u0002@\u000b\u0003\u0002\u0002\u0002A?\u0003\u0002",
    "\u0002\u0002BC\t\u0002\u0002\u0002C\r\u0003\u0002\u0002\u0002DI\u0005",
    "\u0010\t\u0002EF\u0007\u0014\u0002\u0002FH\u0005\u0010\t\u0002GE\u0003",
    "\u0002\u0002\u0002HK\u0003\u0002\u0002\u0002IG\u0003\u0002\u0002\u0002",
    "IJ\u0003\u0002\u0002\u0002J\u000f\u0003\u0002\u0002\u0002KI\u0003\u0002",
    "\u0002\u0002LM\u0005\u001a\u000e\u0002M\u0011\u0003\u0002\u0002\u0002",
    "NO\u0007\n\u0002\u0002OP\u0005\u000e\b\u0002PQ\u0007\u000b\u0002\u0002",
    "QV\u0003\u0002\u0002\u0002RV\u0007\u001f\u0002\u0002SV\u0007\u0007\u0002",
    "\u0002TV\u0005\u0014\u000b\u0002UN\u0003\u0002\u0002\u0002UR\u0003\u0002",
    "\u0002\u0002US\u0003\u0002\u0002\u0002UT\u0003\u0002\u0002\u0002V\u0013",
    "\u0003\u0002\u0002\u0002WX\u0005 \u0011\u0002Xa\u0007\n\u0002\u0002",
    "Y^\u0005\u0010\t\u0002Z[\u0007\u0014\u0002\u0002[]\u0005\u0010\t\u0002",
    "\\Z\u0003\u0002\u0002\u0002]`\u0003\u0002\u0002\u0002^\\\u0003\u0002",
    "\u0002\u0002^_\u0003\u0002\u0002\u0002_b\u0003\u0002\u0002\u0002`^\u0003",
    "\u0002\u0002\u0002aY\u0003\u0002\u0002\u0002ab\u0003\u0002\u0002\u0002",
    "bc\u0003\u0002\u0002\u0002cd\u0007\u000b\u0002\u0002d\u0015\u0003\u0002",
    "\u0002\u0002el\u0005\u0004\u0003\u0002fi\u0005\u0018\r\u0002gh\u0007",
    "\b\u0002\u0002hj\u0005\u0006\u0004\u0002ig\u0003\u0002\u0002\u0002i",
    "j\u0003\u0002\u0002\u0002jl\u0003\u0002\u0002\u0002ke\u0003\u0002\u0002",
    "\u0002kf\u0003\u0002\u0002\u0002l\u0017\u0003\u0002\u0002\u0002mn\u0005",
    "\u0012\n\u0002n\u0019\u0003\u0002\u0002\u0002ot\u0005\u001c\u000f\u0002",
    "pq\u0007\u0003\u0002\u0002qs\u0005\u001c\u000f\u0002rp\u0003\u0002\u0002",
    "\u0002sv\u0003\u0002\u0002\u0002tr\u0003\u0002\u0002\u0002tu\u0003\u0002",
    "\u0002\u0002u\u001b\u0003\u0002\u0002\u0002vt\u0003\u0002\u0002\u0002",
    "w|\u0005\u001e\u0010\u0002xy\u0007\u0004\u0002\u0002y{\u0005\u001e\u0010",
    "\u0002zx\u0003\u0002\u0002\u0002{~\u0003\u0002\u0002\u0002|z\u0003\u0002",
    "\u0002\u0002|}\u0003\u0002\u0002\u0002}\u001d\u0003\u0002\u0002\u0002",
    "~|\u0003\u0002\u0002\u0002\u007f\u0084\u0005\u0016\f\u0002\u0080\u0081",
    "\t\u0003\u0002\u0002\u0081\u0083\u0005\u0016\f\u0002\u0082\u0080\u0003",
    "\u0002\u0002\u0002\u0083\u0086\u0003\u0002\u0002\u0002\u0084\u0082\u0003",
    "\u0002\u0002\u0002\u0084\u0085\u0003\u0002\u0002\u0002\u0085\u001f\u0003",
    "\u0002\u0002\u0002\u0086\u0084\u0003\u0002\u0002\u0002\u0087\u0088\u0007",
    "!\u0002\u0002\u0088!\u0003\u0002\u0002\u0002\u0089\u008a\t\u0004\u0002",
    "\u0002\u008a#\u0003\u0002\u0002\u0002\u000f-29?IU^aikt|\u0084"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'or'", "'and'", "'='", "'!='", null, "'/'", 
                     "'//'", "'('", "')'", "'['", "']'", "'-'", "'+'", "'.'", 
                     "'*'", "'..'", "'@'", "','", "'|'", "'<'", "'>'", "'<='", 
                     "'>='", "':'", "'::'", "'''", "'\"'" ];

var symbolicNames = [ null, null, null, null, null, "Number", "PATHSEP", 
                      "ABRPATH", "LPAR", "RPAR", "LBRAC", "RBRAC", "MINUS", 
                      "PLUS", "DOT", "MUL", "DOTDOT", "AT", "COMMA", "PIPE", 
                      "LT", "GT", "LE", "GE", "COLON", "CC", "APOS", "QUOT", 
                      "ESC_APOS", "Literal", "Whitespace", "NCName" ];

var ruleNames =  [ "main", "locationPath", "relativeLocationPath", "step", 
                   "nodeTest", "abbreviatedStep", "expr", "exprSingle", 
                   "primaryExpr", "functionCall", "pathExprNoRoot", "filterExpr", 
                   "orExpr", "andExpr", "equalityExpr", "functionName", 
                   "nameTest" ];

function PureXPathParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

PureXPathParser.prototype = Object.create(antlr4.Parser.prototype);
PureXPathParser.prototype.constructor = PureXPathParser;

Object.defineProperty(PureXPathParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

PureXPathParser.EOF = antlr4.Token.EOF;
PureXPathParser.T__0 = 1;
PureXPathParser.T__1 = 2;
PureXPathParser.T__2 = 3;
PureXPathParser.T__3 = 4;
PureXPathParser.Number = 5;
PureXPathParser.PATHSEP = 6;
PureXPathParser.ABRPATH = 7;
PureXPathParser.LPAR = 8;
PureXPathParser.RPAR = 9;
PureXPathParser.LBRAC = 10;
PureXPathParser.RBRAC = 11;
PureXPathParser.MINUS = 12;
PureXPathParser.PLUS = 13;
PureXPathParser.DOT = 14;
PureXPathParser.MUL = 15;
PureXPathParser.DOTDOT = 16;
PureXPathParser.AT = 17;
PureXPathParser.COMMA = 18;
PureXPathParser.PIPE = 19;
PureXPathParser.LT = 20;
PureXPathParser.GT = 21;
PureXPathParser.LE = 22;
PureXPathParser.GE = 23;
PureXPathParser.COLON = 24;
PureXPathParser.CC = 25;
PureXPathParser.APOS = 26;
PureXPathParser.QUOT = 27;
PureXPathParser.ESC_APOS = 28;
PureXPathParser.Literal = 29;
PureXPathParser.Whitespace = 30;
PureXPathParser.NCName = 31;

PureXPathParser.RULE_main = 0;
PureXPathParser.RULE_locationPath = 1;
PureXPathParser.RULE_relativeLocationPath = 2;
PureXPathParser.RULE_step = 3;
PureXPathParser.RULE_nodeTest = 4;
PureXPathParser.RULE_abbreviatedStep = 5;
PureXPathParser.RULE_expr = 6;
PureXPathParser.RULE_exprSingle = 7;
PureXPathParser.RULE_primaryExpr = 8;
PureXPathParser.RULE_functionCall = 9;
PureXPathParser.RULE_pathExprNoRoot = 10;
PureXPathParser.RULE_filterExpr = 11;
PureXPathParser.RULE_orExpr = 12;
PureXPathParser.RULE_andExpr = 13;
PureXPathParser.RULE_equalityExpr = 14;
PureXPathParser.RULE_functionName = 15;
PureXPathParser.RULE_nameTest = 16;


function MainContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_main;
    return this;
}

MainContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MainContext.prototype.constructor = MainContext;

MainContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

MainContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterMain(this);
	}
};

MainContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitMain(this);
	}
};




PureXPathParser.MainContext = MainContext;

PureXPathParser.prototype.main = function() {

    var localctx = new MainContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, PureXPathParser.RULE_main);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 34;
        this.expr();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function LocationPathContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_locationPath;
    return this;
}

LocationPathContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LocationPathContext.prototype.constructor = LocationPathContext;

LocationPathContext.prototype.relativeLocationPath = function() {
    return this.getTypedRuleContext(RelativeLocationPathContext,0);
};

LocationPathContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterLocationPath(this);
	}
};

LocationPathContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitLocationPath(this);
	}
};




PureXPathParser.LocationPathContext = LocationPathContext;

PureXPathParser.prototype.locationPath = function() {

    var localctx = new LocationPathContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, PureXPathParser.RULE_locationPath);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 36;
        this.relativeLocationPath();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RelativeLocationPathContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_relativeLocationPath;
    return this;
}

RelativeLocationPathContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RelativeLocationPathContext.prototype.constructor = RelativeLocationPathContext;

RelativeLocationPathContext.prototype.step = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StepContext);
    } else {
        return this.getTypedRuleContext(StepContext,i);
    }
};

RelativeLocationPathContext.prototype.PATHSEP = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.PATHSEP);
    } else {
        return this.getToken(PureXPathParser.PATHSEP, i);
    }
};


RelativeLocationPathContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterRelativeLocationPath(this);
	}
};

RelativeLocationPathContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitRelativeLocationPath(this);
	}
};




PureXPathParser.RelativeLocationPathContext = RelativeLocationPathContext;

PureXPathParser.prototype.relativeLocationPath = function() {

    var localctx = new RelativeLocationPathContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, PureXPathParser.RULE_relativeLocationPath);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 38;
        this.step();
        this.state = 43;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===PureXPathParser.PATHSEP) {
            this.state = 39;
            this.match(PureXPathParser.PATHSEP);
            this.state = 40;
            this.step();
            this.state = 45;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function StepContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_step;
    return this;
}

StepContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StepContext.prototype.constructor = StepContext;

StepContext.prototype.nodeTest = function() {
    return this.getTypedRuleContext(NodeTestContext,0);
};

StepContext.prototype.abbreviatedStep = function() {
    return this.getTypedRuleContext(AbbreviatedStepContext,0);
};

StepContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterStep(this);
	}
};

StepContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitStep(this);
	}
};




PureXPathParser.StepContext = StepContext;

PureXPathParser.prototype.step = function() {

    var localctx = new StepContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, PureXPathParser.RULE_step);
    try {
        this.state = 48;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case PureXPathParser.MUL:
        case PureXPathParser.NCName:
            this.enterOuterAlt(localctx, 1);
            this.state = 46;
            this.nodeTest();
            break;
        case PureXPathParser.DOT:
        case PureXPathParser.DOTDOT:
            this.enterOuterAlt(localctx, 2);
            this.state = 47;
            this.abbreviatedStep();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NodeTestContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_nodeTest;
    return this;
}

NodeTestContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NodeTestContext.prototype.constructor = NodeTestContext;

NodeTestContext.prototype.nameTest = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(NameTestContext);
    } else {
        return this.getTypedRuleContext(NameTestContext,i);
    }
};

NodeTestContext.prototype.LBRAC = function() {
    return this.getToken(PureXPathParser.LBRAC, 0);
};

NodeTestContext.prototype.RBRAC = function() {
    return this.getToken(PureXPathParser.RBRAC, 0);
};

NodeTestContext.prototype.DOT = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.DOT);
    } else {
        return this.getToken(PureXPathParser.DOT, i);
    }
};


NodeTestContext.prototype.nodeTest = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(NodeTestContext);
    } else {
        return this.getTypedRuleContext(NodeTestContext,i);
    }
};

NodeTestContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterNodeTest(this);
	}
};

NodeTestContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitNodeTest(this);
	}
};




PureXPathParser.NodeTestContext = NodeTestContext;

PureXPathParser.prototype.nodeTest = function() {

    var localctx = new NodeTestContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, PureXPathParser.RULE_nodeTest);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 50;
        this.nameTest();
        this.state = 55;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===PureXPathParser.LBRAC) {
            this.state = 51;
            this.match(PureXPathParser.LBRAC);
            this.state = 52;
            this.nameTest();
            this.state = 53;
            this.match(PureXPathParser.RBRAC);
        }

        this.state = 61;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,3,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 57;
                this.match(PureXPathParser.DOT);
                this.state = 58;
                this.nodeTest(); 
            }
            this.state = 63;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,3,this._ctx);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function AbbreviatedStepContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_abbreviatedStep;
    return this;
}

AbbreviatedStepContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AbbreviatedStepContext.prototype.constructor = AbbreviatedStepContext;

AbbreviatedStepContext.prototype.DOT = function() {
    return this.getToken(PureXPathParser.DOT, 0);
};

AbbreviatedStepContext.prototype.DOTDOT = function() {
    return this.getToken(PureXPathParser.DOTDOT, 0);
};

AbbreviatedStepContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterAbbreviatedStep(this);
	}
};

AbbreviatedStepContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitAbbreviatedStep(this);
	}
};




PureXPathParser.AbbreviatedStepContext = AbbreviatedStepContext;

PureXPathParser.prototype.abbreviatedStep = function() {

    var localctx = new AbbreviatedStepContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, PureXPathParser.RULE_abbreviatedStep);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 64;
        _la = this._input.LA(1);
        if(!(_la===PureXPathParser.DOT || _la===PureXPathParser.DOTDOT)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_expr;
    return this;
}

ExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprContext.prototype.constructor = ExprContext;

ExprContext.prototype.exprSingle = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprSingleContext);
    } else {
        return this.getTypedRuleContext(ExprSingleContext,i);
    }
};

ExprContext.prototype.COMMA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.COMMA);
    } else {
        return this.getToken(PureXPathParser.COMMA, i);
    }
};


ExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterExpr(this);
	}
};

ExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitExpr(this);
	}
};




PureXPathParser.ExprContext = ExprContext;

PureXPathParser.prototype.expr = function() {

    var localctx = new ExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, PureXPathParser.RULE_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 66;
        this.exprSingle();
        this.state = 71;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===PureXPathParser.COMMA) {
            this.state = 67;
            this.match(PureXPathParser.COMMA);
            this.state = 68;
            this.exprSingle();
            this.state = 73;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ExprSingleContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_exprSingle;
    return this;
}

ExprSingleContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprSingleContext.prototype.constructor = ExprSingleContext;

ExprSingleContext.prototype.orExpr = function() {
    return this.getTypedRuleContext(OrExprContext,0);
};

ExprSingleContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterExprSingle(this);
	}
};

ExprSingleContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitExprSingle(this);
	}
};




PureXPathParser.ExprSingleContext = ExprSingleContext;

PureXPathParser.prototype.exprSingle = function() {

    var localctx = new ExprSingleContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, PureXPathParser.RULE_exprSingle);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 74;
        this.orExpr();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function PrimaryExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_primaryExpr;
    return this;
}

PrimaryExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PrimaryExprContext.prototype.constructor = PrimaryExprContext;

PrimaryExprContext.prototype.LPAR = function() {
    return this.getToken(PureXPathParser.LPAR, 0);
};

PrimaryExprContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

PrimaryExprContext.prototype.RPAR = function() {
    return this.getToken(PureXPathParser.RPAR, 0);
};

PrimaryExprContext.prototype.Literal = function() {
    return this.getToken(PureXPathParser.Literal, 0);
};

PrimaryExprContext.prototype.Number = function() {
    return this.getToken(PureXPathParser.Number, 0);
};

PrimaryExprContext.prototype.functionCall = function() {
    return this.getTypedRuleContext(FunctionCallContext,0);
};

PrimaryExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterPrimaryExpr(this);
	}
};

PrimaryExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitPrimaryExpr(this);
	}
};




PureXPathParser.PrimaryExprContext = PrimaryExprContext;

PureXPathParser.prototype.primaryExpr = function() {

    var localctx = new PrimaryExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, PureXPathParser.RULE_primaryExpr);
    try {
        this.state = 83;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case PureXPathParser.LPAR:
            this.enterOuterAlt(localctx, 1);
            this.state = 76;
            this.match(PureXPathParser.LPAR);
            this.state = 77;
            this.expr();
            this.state = 78;
            this.match(PureXPathParser.RPAR);
            break;
        case PureXPathParser.Literal:
            this.enterOuterAlt(localctx, 2);
            this.state = 80;
            this.match(PureXPathParser.Literal);
            break;
        case PureXPathParser.Number:
            this.enterOuterAlt(localctx, 3);
            this.state = 81;
            this.match(PureXPathParser.Number);
            break;
        case PureXPathParser.NCName:
            this.enterOuterAlt(localctx, 4);
            this.state = 82;
            this.functionCall();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function FunctionCallContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_functionCall;
    return this;
}

FunctionCallContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FunctionCallContext.prototype.constructor = FunctionCallContext;

FunctionCallContext.prototype.functionName = function() {
    return this.getTypedRuleContext(FunctionNameContext,0);
};

FunctionCallContext.prototype.LPAR = function() {
    return this.getToken(PureXPathParser.LPAR, 0);
};

FunctionCallContext.prototype.RPAR = function() {
    return this.getToken(PureXPathParser.RPAR, 0);
};

FunctionCallContext.prototype.exprSingle = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprSingleContext);
    } else {
        return this.getTypedRuleContext(ExprSingleContext,i);
    }
};

FunctionCallContext.prototype.COMMA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.COMMA);
    } else {
        return this.getToken(PureXPathParser.COMMA, i);
    }
};


FunctionCallContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterFunctionCall(this);
	}
};

FunctionCallContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitFunctionCall(this);
	}
};




PureXPathParser.FunctionCallContext = FunctionCallContext;

PureXPathParser.prototype.functionCall = function() {

    var localctx = new FunctionCallContext(this, this._ctx, this.state);
    this.enterRule(localctx, 18, PureXPathParser.RULE_functionCall);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 85;
        this.functionName();
        this.state = 86;
        this.match(PureXPathParser.LPAR);
        this.state = 95;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << PureXPathParser.Number) | (1 << PureXPathParser.LPAR) | (1 << PureXPathParser.DOT) | (1 << PureXPathParser.MUL) | (1 << PureXPathParser.DOTDOT) | (1 << PureXPathParser.Literal) | (1 << PureXPathParser.NCName))) !== 0)) {
            this.state = 87;
            this.exprSingle();
            this.state = 92;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===PureXPathParser.COMMA) {
                this.state = 88;
                this.match(PureXPathParser.COMMA);
                this.state = 89;
                this.exprSingle();
                this.state = 94;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
        }

        this.state = 97;
        this.match(PureXPathParser.RPAR);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function PathExprNoRootContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_pathExprNoRoot;
    return this;
}

PathExprNoRootContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PathExprNoRootContext.prototype.constructor = PathExprNoRootContext;

PathExprNoRootContext.prototype.locationPath = function() {
    return this.getTypedRuleContext(LocationPathContext,0);
};

PathExprNoRootContext.prototype.filterExpr = function() {
    return this.getTypedRuleContext(FilterExprContext,0);
};

PathExprNoRootContext.prototype.PATHSEP = function() {
    return this.getToken(PureXPathParser.PATHSEP, 0);
};

PathExprNoRootContext.prototype.relativeLocationPath = function() {
    return this.getTypedRuleContext(RelativeLocationPathContext,0);
};

PathExprNoRootContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterPathExprNoRoot(this);
	}
};

PathExprNoRootContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitPathExprNoRoot(this);
	}
};




PureXPathParser.PathExprNoRootContext = PathExprNoRootContext;

PureXPathParser.prototype.pathExprNoRoot = function() {

    var localctx = new PathExprNoRootContext(this, this._ctx, this.state);
    this.enterRule(localctx, 20, PureXPathParser.RULE_pathExprNoRoot);
    var _la = 0; // Token type
    try {
        this.state = 105;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,9,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 99;
            this.locationPath();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 100;
            this.filterExpr();
            this.state = 103;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===PureXPathParser.PATHSEP) {
                this.state = 101;
                this.match(PureXPathParser.PATHSEP);
                this.state = 102;
                this.relativeLocationPath();
            }

            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function FilterExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_filterExpr;
    return this;
}

FilterExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FilterExprContext.prototype.constructor = FilterExprContext;

FilterExprContext.prototype.primaryExpr = function() {
    return this.getTypedRuleContext(PrimaryExprContext,0);
};

FilterExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterFilterExpr(this);
	}
};

FilterExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitFilterExpr(this);
	}
};




PureXPathParser.FilterExprContext = FilterExprContext;

PureXPathParser.prototype.filterExpr = function() {

    var localctx = new FilterExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 22, PureXPathParser.RULE_filterExpr);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 107;
        this.primaryExpr();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function OrExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_orExpr;
    return this;
}

OrExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
OrExprContext.prototype.constructor = OrExprContext;

OrExprContext.prototype.andExpr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(AndExprContext);
    } else {
        return this.getTypedRuleContext(AndExprContext,i);
    }
};

OrExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterOrExpr(this);
	}
};

OrExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitOrExpr(this);
	}
};




PureXPathParser.OrExprContext = OrExprContext;

PureXPathParser.prototype.orExpr = function() {

    var localctx = new OrExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 24, PureXPathParser.RULE_orExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 109;
        this.andExpr();
        this.state = 114;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===PureXPathParser.T__0) {
            this.state = 110;
            this.match(PureXPathParser.T__0);
            this.state = 111;
            this.andExpr();
            this.state = 116;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function AndExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_andExpr;
    return this;
}

AndExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AndExprContext.prototype.constructor = AndExprContext;

AndExprContext.prototype.equalityExpr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(EqualityExprContext);
    } else {
        return this.getTypedRuleContext(EqualityExprContext,i);
    }
};

AndExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterAndExpr(this);
	}
};

AndExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitAndExpr(this);
	}
};




PureXPathParser.AndExprContext = AndExprContext;

PureXPathParser.prototype.andExpr = function() {

    var localctx = new AndExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 26, PureXPathParser.RULE_andExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 117;
        this.equalityExpr();
        this.state = 122;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===PureXPathParser.T__1) {
            this.state = 118;
            this.match(PureXPathParser.T__1);
            this.state = 119;
            this.equalityExpr();
            this.state = 124;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function EqualityExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_equalityExpr;
    return this;
}

EqualityExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
EqualityExprContext.prototype.constructor = EqualityExprContext;

EqualityExprContext.prototype.pathExprNoRoot = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PathExprNoRootContext);
    } else {
        return this.getTypedRuleContext(PathExprNoRootContext,i);
    }
};

EqualityExprContext.prototype.LT = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.LT);
    } else {
        return this.getToken(PureXPathParser.LT, i);
    }
};


EqualityExprContext.prototype.GT = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.GT);
    } else {
        return this.getToken(PureXPathParser.GT, i);
    }
};


EqualityExprContext.prototype.LE = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.LE);
    } else {
        return this.getToken(PureXPathParser.LE, i);
    }
};


EqualityExprContext.prototype.GE = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(PureXPathParser.GE);
    } else {
        return this.getToken(PureXPathParser.GE, i);
    }
};


EqualityExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterEqualityExpr(this);
	}
};

EqualityExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitEqualityExpr(this);
	}
};




PureXPathParser.EqualityExprContext = EqualityExprContext;

PureXPathParser.prototype.equalityExpr = function() {

    var localctx = new EqualityExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 28, PureXPathParser.RULE_equalityExpr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 125;
        this.pathExprNoRoot();
        this.state = 130;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << PureXPathParser.T__2) | (1 << PureXPathParser.T__3) | (1 << PureXPathParser.LT) | (1 << PureXPathParser.GT) | (1 << PureXPathParser.LE) | (1 << PureXPathParser.GE))) !== 0)) {
            this.state = 126;
            _la = this._input.LA(1);
            if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << PureXPathParser.T__2) | (1 << PureXPathParser.T__3) | (1 << PureXPathParser.LT) | (1 << PureXPathParser.GT) | (1 << PureXPathParser.LE) | (1 << PureXPathParser.GE))) !== 0))) {
            this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 127;
            this.pathExprNoRoot();
            this.state = 132;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function FunctionNameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_functionName;
    return this;
}

FunctionNameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FunctionNameContext.prototype.constructor = FunctionNameContext;

FunctionNameContext.prototype.NCName = function() {
    return this.getToken(PureXPathParser.NCName, 0);
};

FunctionNameContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterFunctionName(this);
	}
};

FunctionNameContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitFunctionName(this);
	}
};




PureXPathParser.FunctionNameContext = FunctionNameContext;

PureXPathParser.prototype.functionName = function() {

    var localctx = new FunctionNameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 30, PureXPathParser.RULE_functionName);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 133;
        this.match(PureXPathParser.NCName);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NameTestContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = PureXPathParser.RULE_nameTest;
    return this;
}

NameTestContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NameTestContext.prototype.constructor = NameTestContext;

NameTestContext.prototype.MUL = function() {
    return this.getToken(PureXPathParser.MUL, 0);
};

NameTestContext.prototype.NCName = function() {
    return this.getToken(PureXPathParser.NCName, 0);
};

NameTestContext.prototype.enterRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.enterNameTest(this);
	}
};

NameTestContext.prototype.exitRule = function(listener) {
    if(listener instanceof PureXPathListener ) {
        listener.exitNameTest(this);
	}
};




PureXPathParser.NameTestContext = NameTestContext;

PureXPathParser.prototype.nameTest = function() {

    var localctx = new NameTestContext(this, this._ctx, this.state);
    this.enterRule(localctx, 32, PureXPathParser.RULE_nameTest);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 135;
        _la = this._input.LA(1);
        if(!(_la===PureXPathParser.MUL || _la===PureXPathParser.NCName)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


exports.PureXPathParser = PureXPathParser;
