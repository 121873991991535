import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    CachedCurrentUserService,
    CurrentUser,
    DataPage,
    DpaSafeMode,
    DpaSafeModeService,
    PGroupSafemode,
    Product,
    UnifiedArray,
    UnifiedArrayService,
} from '@pure1/data';
import { WizardComponent } from '@achimha/angular-archwizard';
import { Angulartics2 } from 'angulartics2';
import moment from 'moment-timezone';
import { forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ArraysManager } from '../../../services/arrays-manager.service';
import { SupportContactService } from '../../../support/services/support-contact.service';
import { ProductLine, User } from '../../../support/support.interface';
import { splitUpSlots } from '../../../support/support.utils';
import { forString } from '../../../utils/comparator';
import { DpaSafeModeUtils } from '../../../utils/dpa-safe-mode-utils';
import {
    ApplianceStorageType,
    SafemodeChangeRequest,
    SafemodeMultipartyAuthorizationService,
    SafeModeRequestDetails,
    SafeModeResourceId,
    SafeModeStatusEnablement,
} from '../../services/safemode-multiparty-authorization.service';
import { SafeModeSchedulerService } from '../../services/safemode-scheduler.service';
import { getDayAsMoment, isUpgradeRequired } from '../../util';
import { SafeModeArraySelectorMultipartyAuthComponent } from '../safemode-array-selector/safemode-array-selector-multiparty-auth.component';
import { SafeModePGroupsSelectorMultipartyAuthComponent } from '../safemode-pgroups-selector/safemode-pgroups-selector-multiparty-auth.component';
import { Schedule } from '../types';

const TIMESLOT_INTERVAL = moment.duration(60, 'minutes');

@Component({
    selector: 'safemode-scheduler-multiparty-auth',
    templateUrl: 'safemode-scheduler-multiparty-auth.component.html',
})
export class SafeModeSchedulerMultipartyAuthComponent implements OnDestroy {
    @ViewChild(WizardComponent) wizard: WizardComponent;

    ProductLine = ProductLine;
    allArrays: UnifiedArray[] = []; // list of all arrays available to schedule
    selectedArrays: UnifiedArray[] = [];
    selectedPGroups: PGroupSafemode[] = [];
    arraysFilteredByProduct: UnifiedArray[] = []; // available arrays after Product Line is selected
    initialArrays: string[] = [];
    eradicationDays = 1;
    safemodeEnablement = false;
    selectedProductLine: ProductLine;

    loading = false;
    submitting = false;
    submitted = false;
    createCaseDoneFlag = false;
    createCaseErrorFlag = false;
    timeslotsConflictFlag = false;
    encourageCustomerToCallSupport = false;
    statusEditMode = false;
    eradicationEditMode = false;
    currentStatus = '';
    currentEradication = '';

    introDisplayed = true;

    newRequest: SafemodeChangeRequest;
    isOrgCompliant: boolean;
    hasSNOWContact = true;
    fileStorageTypeSelected = true;
    objectStorageTypeSelected = false;

    eradicationOptions = [1, 2, 3, 4, 5, 6, 7, 10, 15, 20, 30];

    // angulartics variables
    ANALYTICS_PREFIX = 'Assessment - SafeMode self service - Scheduler - ';
    startTime: number;
    // when capacity of some array is over this then we want to encourage the customer to call support if he cannot find suitable appointment time
    CAPACITY_THRESHOLD = 90;

    productLine = ProductLine;

    constructor(
        private angulartics: Angulartics2,
        private cachedCurrentUserService: CachedCurrentUserService,
        private unifiedArrayService: UnifiedArrayService,
        private supportContactService: SupportContactService,
        private scheduleService: SafeModeSchedulerService,
        private safemodeMultipartyAuthorizationService: SafemodeMultipartyAuthorizationService,
        private activeModal: NgbActiveModal,
        private dpaSafeModeService: DpaSafeModeService,
        private ngbModal: NgbModal,
        private arraysManager: ArraysManager,
        protected calendar: NgbCalendar,
    ) {}

    initialize(): void {
        this.loading = true;
        this.startTime = moment().valueOf();
        const arraysRequest$ = this.getArrays();
        const hasSnowContact$ = this.hasSnowContact();
        const safeMode$ = this.dpaSafeModeService.list().pipe(
            take(1),
            catchError(error => {
                const errorMsg = error && (error.data?.message || error.statusText);
                console.error('Error updating safemode: ' + errorMsg);
                return of<DataPage<DpaSafeMode>>(null);
            }),
            map(value => value?.response),
        );

        const isOrgCompliant$ = this.safemodeMultipartyAuthorizationService.isOrgCompliant();

        forkJoin([hasSnowContact$, arraysRequest$, safeMode$, isOrgCompliant$]).subscribe({
            next: ([_, __, safeMode, isOrgCompliant]) => {
                this.isOrgCompliant = isOrgCompliant.hasEnoughApprovers;
                if (!this.isOrgCompliant) {
                    this.loading = false;
                    return;
                }
                this.allArrays = DpaSafeModeUtils.getArraysWithDpaSafeMode(this.allArrays, safeMode || []);
                this.selectedArrays = this.allArrays.filter(
                    array => this.initialArrays.includes(array.id) || this.initialArrays.includes(array.cloud_array_id),
                );

                // preselect the right product line (assuming all initial arrays are the same product)
                // use the initial array product line, otherwise use FA
                if (this.initialArrays.length) {
                    const preselectedArray = this.allArrays.find(
                        array => array.id === this.initialArrays[0] || array.cloud_array_id === this.initialArrays[0],
                    );
                    if (preselectedArray) {
                        this.selectedProductLine =
                            preselectedArray.product === Product.FA ? ProductLine.FlashArray : ProductLine.FlashBlade;
                    } else {
                        this.selectedProductLine = ProductLine.FlashBlade;
                    }
                } else {
                    this.selectedProductLine = ProductLine.FlashBlade;
                }

                // filter available arrays accordingly
                this.arraysFilteredByProduct = this.allArrays.filter(array => {
                    const arrayProductLine =
                        array.product === Product.FA ? ProductLine.FlashArray : ProductLine.FlashBlade;
                    return arrayProductLine === this.selectedProductLine;
                });

                this.updateCurrentStatus();
                this.updateCurrentEradication();

                this.loading = false;
            },
            error: console.error,
        });

        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Dialog opened',
            properties: {
                category: 'Action',
            },
        });
    }

    updateCurrentStatus(): void {
        let currentStatus = null;
        if (this.selectedArrays.length === 0 && this.selectedPGroups.length === 0) {
            currentStatus = '(no appliances selected)';
            return;
        }
        this.selectedPGroups.forEach(pgroup => {
            if (currentStatus && currentStatus !== pgroup.retentionLock) {
                currentStatus = '(multiple values)';
                return;
            }
            if (currentStatus === null) {
                currentStatus = pgroup.retentionLock;
            }
        });
        if (currentStatus === 'ratcheted') {
            currentStatus = 'Enabled';
        }
        if (currentStatus === 'unlocked') {
            currentStatus = 'Disabled';
        }
        this.selectedArrays.forEach(array => {
            if (currentStatus && currentStatus !== array.safeModeStatus) {
                currentStatus = '(multiple values)';
                return;
            }
            if (currentStatus === null) {
                currentStatus = array.safeModeStatus;
            }
        });

        this.currentStatus = currentStatus;
    }

    daysString(days: number): string {
        return days + (days === 1 ? ' day' : ' days');
    }

    updateCurrentEradication(): void {
        if (this.selectedArrays.length === 0) {
            this.currentEradication = '(no appliances selected)';
            return;
        }

        const eradicationDays = this.selectedArrays.map(array => {
            const t = array.safeMode.global?.eradicationTimer;
            if (t == null) {
                return '(not set)';
            } else {
                return this.daysString(t.days());
            }
        });
        const uniqueEradicationDays = [...new Set(eradicationDays)];

        if (uniqueEradicationDays.length === 1) {
            this.currentEradication = uniqueEradicationDays[0];
        } else {
            this.currentEradication = '(multiple values)';
        }
    }

    ngOnDestroy(): void {
        this.angulartics.userTimings.next({
            timingCategory: 'Action',
            timingVar: this.ANALYTICS_PREFIX + 'Time spent in dialog',
            timingValue: moment().valueOf() - this.startTime,
        });

        if (!this.submitted) {
            this.angulartics.eventTrack.next({
                action: this.ANALYTICS_PREFIX + 'Cancelled',
                properties: {
                    category: 'Action',
                },
            });
        }
    }

    showStep(stepIndex: number): void {
        if (this.wizard) {
            this.wizard.goToStep(stepIndex);
        }
    }

    cancel(): void {
        this.activeModal.dismiss();
    }

    setArrays(arrays: UnifiedArray[]): void {
        this.selectedArrays = arrays;
        this.updateCurrentStatus();
        this.updateCurrentEradication();
    }

    setPGroups(pGroups: PGroupSafemode[]): void {
        this.selectedPGroups = pGroups;
        this.updateCurrentStatus();
    }

    showSchedule(): void {
        this.showStep(1);
        const arrayIds = new Set<string>();
        this.selectedArrays.forEach(array => {
            arrayIds.add(array.cloud_array_id);
        });
        this.selectedPGroups.forEach(pgroup => {
            arrayIds.add(pgroup.arrays[0]?.flashArrayId);
        });
        from(this.arraysManager.getPureArrays('', { array_id: Array.from(arrayIds) })).subscribe(pureArrays => {
            this.encourageCustomerToCallSupport = pureArrays.some(
                array => Number(array.capacityData.normalizedPercFull) >= this.CAPACITY_THRESHOLD,
            );
        });
    }

    doSchedule(schedule: Schedule): void {
        this.submitting = true;
        // check the timeslots to prevent overbooking
        const startTime = getDayAsMoment(this.calendar.getToday(), schedule.timezone);
        const endTime = getDayAsMoment(this.calendar.getToday(), schedule.timezone).add(6, 'months');
        this.scheduleService.getFreeTimes(startTime, endTime, this.selectedProductLine).subscribe({
            next: newFreeTimes => {
                const splitedNewFreeTimes = splitUpSlots(newFreeTimes, moment.duration(1, 'hours'), TIMESLOT_INTERVAL);
                const startSlotIndex = splitedNewFreeTimes.findIndex(ts => ts.startTime.isSame(schedule.selectedTime));
                if (startSlotIndex < 0 || splitedNewFreeTimes[startSlotIndex].capacity < 1) {
                    this.timeslotsConflictFlag = true;
                    this.submitting = false;
                } else {
                    this.timeslotsConflictFlag = false;
                    this.createCase(schedule);
                }
            },
            error: error => {
                console.error(error); // for datadog reporting reasons
                this.submitting = false;
            },
        });
    }

    selectAppliancesModalShow(): void {
        const modalInstance = this.ngbModal.open(SafeModeArraySelectorMultipartyAuthComponent, {
            size: 'lg',
            backdrop: 'static',
        });
        const instance = modalInstance.componentInstance as SafeModeArraySelectorMultipartyAuthComponent;

        instance.initialSelection = this.selectedArrays;
        instance.arrays = this.arraysFilteredByProduct;
        instance.selectedPGroups = this.selectedPGroups;

        instance.onCancel.subscribe(() => modalInstance.close());
        instance.onForward.subscribe(event => {
            this.setArrays(event);
            modalInstance.close();
        });

        instance.initialize();
    }

    selectPGroupsModalShow(): void {
        const modalInstance = this.ngbModal.open(SafeModePGroupsSelectorMultipartyAuthComponent, {
            size: 'lg',
            backdrop: 'static',
        });
        const instance = modalInstance.componentInstance as SafeModePGroupsSelectorMultipartyAuthComponent;

        instance.initialSelection = this.selectedPGroups;
        instance.selectedArrays = this.selectedArrays;

        instance.onCancel.subscribe(() => modalInstance.close());
        instance.onForward.subscribe(event => {
            this.setPGroups(event);
            modalInstance.close();
        });

        instance.initialize();
    }

    removeAppliance(applianceId: string): void {
        this.selectedArrays = this.selectedArrays.filter(value => value.appliance_id !== applianceId);
        this.updateCurrentStatus();
        this.updateCurrentEradication();
    }

    removePgroup(pgroupId: string): void {
        this.selectedPGroups = this.selectedPGroups.filter(value => value.id !== pgroupId);
        this.updateCurrentStatus();
    }

    private createCase(schedule: Schedule): void {
        if (!this.timeslotsConflictFlag) {
            const details: SafeModeRequestDetails = {
                newSafeModeStatus: this.statusEditMode
                    ? this.safemodeEnablement
                        ? SafeModeStatusEnablement.ENABLED
                        : SafeModeStatusEnablement.DISABLED
                    : SafeModeStatusEnablement.UNCHANGED,
            };
            if (this.eradicationEditMode) {
                details.newExpirationInDays = this.eradicationDays;
            }
            const resources: SafeModeResourceId[] = [];
            const storageTypes = [];
            if (this.productLine.FlashBlade === this.selectedProductLine) {
                if (this.fileStorageTypeSelected) {
                    storageTypes.push(ApplianceStorageType.FILE_SYSTEMS);
                }
                if (this.objectStorageTypeSelected) {
                    storageTypes.push(ApplianceStorageType.OBJECT_STORAGE);
                }
            }
            this.selectedArrays.forEach(array => {
                resources.push({
                    applianceId: array.cloud_array_id,
                    storageTypes: storageTypes,
                });
            });
            this.selectedPGroups.forEach(pgroup => {
                resources.push({
                    applianceId: pgroup.arrays[0]?.flashArrayId,
                    pgroupId: pgroup.id,
                });
            });
            this.safemodeMultipartyAuthorizationService
                .createSafemodeRequest(resources, details, schedule.selectedTime.toISOString(), schedule.timezone)
                .subscribe({
                    next: request => this.onRequestCreated(request),
                    error: error => this.onRequestCreationErrorCallback(error),
                });
        }
    }

    private hasSnowContact(): Observable<CurrentUser | User> {
        return this.cachedCurrentUserService.get().pipe(
            take(1),
            switchMap(curUser => this.supportContactService.getContactById(curUser.id)),
            take(1),
            catchError(() => {
                this.hasSNOWContact = false;
                return of(null);
            }),
        );
    }

    private getArrays(): Observable<DataPage<UnifiedArray>> {
        return this.unifiedArrayService.list().pipe(
            take(1),
            tap((array: DataPage<UnifiedArray>) => {
                this.allArrays = array.response
                    ? array.response
                          .filter(array => !isUpgradeRequired(array))
                          .sort(forString((result: UnifiedArray) => result.name).asc)
                    : [];
            }),
        );
    }

    private onRequestCreated(request: SafemodeChangeRequest): void {
        this.newRequest = request;
        this.submitting = false;
        this.submitted = true;
        this.createCaseDoneFlag = true;
        this.createCaseErrorFlag = false;
        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Case created',
            properties: {
                category: 'Action',
                label: `${request.resources.length} child cases`,
            },
        });
    }

    private onRequestCreationErrorCallback(error: any): void {
        console.error(error); // for datadog reporting reasons
        this.submitting = false;
        this.submitted = true;
        this.createCaseDoneFlag = true;
        this.createCaseErrorFlag = true;
        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Case create failed',
            properties: {
                category: 'Action',
                label: `${error}`,
            },
        });
    }
}
