import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FeatureFlagDxpService, UnifiedArray } from '@pure1/data';
import { take } from 'rxjs/operators';
import { FeatureNames } from '../../../model/FeatureNames';
import { SEVERITY_DATA, SeverityData } from './alert-indicator.data';

@Component({
    selector: 'pure-alert-indicator',
    templateUrl: 'alert-indicator.html',
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertIndicatorComponent implements OnChanges {
    @Input() readonly array: UnifiedArray;
    @Input() readonly alerts: IAlert[] | IAlert;
    @Input() readonly fixedSeverity: AlertSeverityName;
    @Input() readonly isDisabled: boolean = false;
    @Input() readonly iconWidth: number;
    @Input() readonly iconHeight: number;

    severity: AlertSeverityName;
    severityData: SeverityData;
    hwEOLEnabled = false;

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnChanges(changesObj: SimpleChanges): void {
        if (changesObj.alerts || changesObj.fixedSeverity || changesObj.array) {
            this.featureFlagDxpService
                .getFeatureFlag(FeatureNames.HW_EOL)
                .pipe(take(1))
                .subscribe(feature => {
                    this.hwEOLEnabled = feature?.enabled === true;
                });
            this.setAlertSetSeverity();
        }
    }

    private setAlertSetSeverity(): void {
        if (this.hwEOLEnabled && this.array?.has_end_of_life_hardware) {
            this.severity = 'eol';
        } else if (this.fixedSeverity) {
            if (SEVERITY_DATA[this.fixedSeverity]) {
                this.severity = this.fixedSeverity;
            } else {
                console.warn('Invalid fixedSeverity given for alert: ', this.fixedSeverity, this.alerts);
                this.severity = 'none';
            }
        } else if (this.containsAlertSeverity('critical')) {
            this.severity = 'critical';
        } else if (this.containsAlertSeverity('warning')) {
            this.severity = 'warning';
        } else if (this.containsAlertSeverity('info')) {
            this.severity = 'info';
        } else {
            this.severity = 'none';
        }

        this.severityData = SEVERITY_DATA[this.severity];
    }

    private containsAlertSeverity(severity: string): boolean {
        let alerts: IAlert[];
        if (!this.alerts) {
            alerts = [];
        } else if (Array.isArray(this.alerts)) {
            alerts = this.alerts;
        } else {
            alerts = [this.alerts];
        }
        return alerts.some(alert => alert.currentSeverity.toLocaleLowerCase() === severity);
    }
}
