import moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
    CachedCurrentUserService,
    ServiceCatalogQuoteServiceV3,
    CurrentUser,
    DataPage,
    License,
    LicenseService,
    ServiceCatalogGroup,
    ServiceCatalogOffering,
    ServiceCatalogQuote,
    ServiceCatalogService,
} from '@pure1/data';
import { AuthorizationService, Permission } from '@pure/authz-authorizer';

export type OfferingActivesCount = { actives: number };
export type OfferingOrdersCount = {
    totalPendingOrders: number;
    totalOrders: number;
    quoteOrders: number;
    trialOrders: number;
    other: number;
};

@Injectable({ providedIn: 'root' })
export class ServiceCatalogOfferingsService implements OnDestroy {
    readonly writePermissionForAllowedUserActionOnOfferings: Permission = 'PURE1:write:subscriptions_renew'; // to be used across all service-catalog components

    readonly offeringsInGroups$: Subject<ServiceCatalogGroup[]> = new ReplaySubject(1);
    readonly offeringsByCompoundedName$: Subject<Map<string, ServiceCatalogOffering>> = new ReplaySubject(1);
    readonly activesCountByLicenseType$: Subject<Map<string, OfferingActivesCount>> = new ReplaySubject(1);
    readonly ordersCountByCompoundedName$: Subject<Map<string, OfferingOrdersCount>> = new ReplaySubject(1);

    private offeringsByCompoundedName: Map<string, ServiceCatalogOffering>;
    private activesCountByLicenseType: Map<string, OfferingActivesCount>;
    private ordersCountByCompoundedName: Map<string, OfferingOrdersCount>;
    private offeringsInGroups: ServiceCatalogGroup[];
    private rawQuotes: ServiceCatalogQuote[];
    private quotesByCompoundedName: Map<string, ServiceCatalogQuote[]>;
    private currentUser: CurrentUser;
    private readonly destroy$ = new Subject<void>();
    private canViewSubscriptionsRenew = false;
    private userAllowedToTakeActionOnOfferings: boolean = false;

    constructor(
        private authorizationService: AuthorizationService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private serviceCatalogService: ServiceCatalogService,
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV3,
        private licenseService: LicenseService,
    ) {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe((currentUser: CurrentUser) => {
                this.currentUser = currentUser;
            });
        this.authorizationService
            .isAllowed('PURE1:view:subscriptions_renew')
            .pipe(takeUntil(this.destroy$))
            .subscribe(canViewSubscriptionsRenew => {
                this.canViewSubscriptionsRenew = canViewSubscriptionsRenew;
            });
        this.authorizationService
            .isAllowed(this.writePermissionForAllowedUserActionOnOfferings)
            .pipe(takeUntil(this.destroy$))
            .subscribe(isAllowed => {
                this.userAllowedToTakeActionOnOfferings = isAllowed;
            });
        this.updateOfferings();
        this.updateActivesCount();
        this.updateOrdersCount();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    isUserAllowedToTakeActionOnOfferings(): boolean {
        return this.userAllowedToTakeActionOnOfferings;
    }

    getActionTooltipBasedOnUserPermission(): string {
        if (this.isUserAllowedToTakeActionOnOfferings()) {
            return null;
        } else if (this.canViewSubscriptionsRenew) {
            return `ATTENTION: You are allowed to click through because you are a PURE STORAGE employee. The user ${this.currentUser.email} may instead, depending on their permissions, see a disabled button and a message to contact their Pure1 Admin to take further action`;
        } else {
            return 'Only Pure1 Administrators have access to this feature.';
        }
    }

    isAllowedToOpenContactModals(): boolean {
        return this.isUserAllowedToTakeActionOnOfferings() || this.canViewSubscriptionsRenew;
    }

    updateOfferings(): Subject<ServiceCatalogGroup[]> {
        const returnSubject = new Subject<ServiceCatalogGroup[]>();
        this.serviceCatalogService
            .list()
            .pipe(take(1))
            .subscribe(
                (dataPage: DataPage<ServiceCatalogGroup>) => {
                    if (dataPage) {
                        this.offeringsInGroups = dataPage.response || [];
                        //build maps offerings for easier lookup
                        this.offeringsByCompoundedName = new Map<string, ServiceCatalogOffering>();
                        this.offeringsInGroups.forEach(offeringGroup => {
                            if (offeringGroup.offerings?.length > 0) {
                                offeringGroup.offerings.forEach(offering => {
                                    this.offeringsByCompoundedName.set(offering.compoundedName, offering);
                                });
                            }
                        });
                        this.offeringsInGroups$.next(this.offeringsInGroups);
                        this.offeringsByCompoundedName$.next(this.offeringsByCompoundedName);
                        returnSubject.next(this.offeringsInGroups);
                    } else {
                        console.warn('no data when getting service catalog');
                    }
                },
                error => {
                    console.error('error in getting offerings in offering service', error);
                    returnSubject.error(error);
                },
            );
        return returnSubject;
    }

    updateActivesCount(): Subject<Map<string, OfferingActivesCount>> {
        const returnSubject = new Subject<Map<string, OfferingActivesCount>>();
        this.licenseService
            .list()
            .pipe(take(1))
            .subscribe(
                (dataPage: DataPage<License>) => {
                    const licenses = dataPage.response || [];
                    const currentTime = moment();
                    this.activesCountByLicenseType = new Map<string, OfferingActivesCount>();
                    licenses.forEach(license => {
                        if (!this.activesCountByLicenseType.has(license.programTypeAndLicenseTypeString)) {
                            this.activesCountByLicenseType.set(license.programTypeAndLicenseTypeString, { actives: 0 });
                        }
                        if (currentTime.isBetween(license.startDate, license.endDate, undefined, '[]')) {
                            this.activesCountByLicenseType.get(license.programTypeAndLicenseTypeString).actives++;
                        }
                    });

                    this.activesCountByLicenseType$.next(this.activesCountByLicenseType);
                    returnSubject.next(this.activesCountByLicenseType);
                },
                error => {
                    console.warn('error in getting licenses in offering service', error);
                    returnSubject.error(error);
                },
            );
        return returnSubject;
    }

    updateOrdersCount(): Subject<Map<string, OfferingOrdersCount>> {
        const returnSubject = new Subject<Map<string, OfferingOrdersCount>>();
        this.serviceCatalogQuoteService
            .list()
            .pipe(take(1))
            .subscribe(
                (dataPage: DataPage<ServiceCatalogQuote>) => {
                    if (dataPage) {
                        this.rawQuotes = dataPage.response || [];
                        this.quotesByCompoundedName = new Map<string, ServiceCatalogQuote[]>();
                        this.ordersCountByCompoundedName = new Map<string, OfferingOrdersCount>();
                        this.rawQuotes.forEach(quote => {
                            if (!quote.completed && quote.compoundedName) {
                                // Count the trial and quote orders
                                if (!this.ordersCountByCompoundedName.has(quote.compoundedName)) {
                                    this.ordersCountByCompoundedName.set(quote.compoundedName, {
                                        totalPendingOrders: 0,
                                        totalOrders: 0,
                                        quoteOrders: 0,
                                        trialOrders: 0,
                                        other: 0,
                                    });
                                }
                                const orderCounts = this.ordersCountByCompoundedName.get(quote.compoundedName);
                                orderCounts.totalOrders++;
                                if (!quote.completed) {
                                    orderCounts.totalPendingOrders++;
                                }
                                if (quote.quoteType.toString() === 'REGULAR_QUOTE') {
                                    orderCounts.quoteOrders++;
                                } else if (quote.quoteType.toString() === 'POC') {
                                    orderCounts.trialOrders++;
                                } else {
                                    orderCounts.other++;
                                }

                                // Also save this quote in the list of quotes associated with this compoundName
                                if (!this.quotesByCompoundedName.has(quote.compoundedName)) {
                                    this.quotesByCompoundedName.set(quote.compoundedName, []);
                                }
                                this.quotesByCompoundedName.get(quote.compoundedName).push(quote);
                            }
                        });

                        this.ordersCountByCompoundedName$.next(this.ordersCountByCompoundedName);
                        returnSubject.next(this.ordersCountByCompoundedName);
                    } else {
                        console.error('no data when getting quotes for service catalog');
                    }
                },
                error => {
                    console.error('failed to get offering quotes in offering service', error);
                    returnSubject.error(error);
                },
            );
        return returnSubject;
    }
}
