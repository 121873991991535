import { FormatSizePipe } from '../../ui/pipes/format-size.pipe';
import { isNumber } from 'lodash';
import { DecimalPipe } from '@angular/common';
import { exhaustiveGuard } from '../../utils/exhaustiveGuard';

export const APPLIANCE_GENEALOGY_EVENTS = [
    'applianceInstall',
    'hardwareUpgrade',
    'softwareUpgrade',
    'decommission',
    'currentApplianceState',
    'applianceRenewal',
    'upcomingApplianceRenewal',
    'eol',
    'eolUpcoming',
    'dispatch',
    'dispatchUpcoming',
    'everModernUpgrade',
    'everModernUpgradeUpcoming',
] as const;
export type ApplianceGenealogyEventType = (typeof APPLIANCE_GENEALOGY_EVENTS)[number];

export const SUBSCRIPTION_GENEALOGY_EVENTS = [
    'start',
    'plannedRamp',
    'updateRequest',
    'renewal',
    'expiration',
    'termination',
    'upcomingRenewal',
    'currentSubscriptionState',
] as const;
export type SubscriptionGenealogyEventType = (typeof SUBSCRIPTION_GENEALOGY_EVENTS)[number];

export type GenealogyEventType = ApplianceGenealogyEventType | SubscriptionGenealogyEventType;

export function getApplianceGenealogyEventDisplayName(event: ApplianceGenealogyEventType): string {
    switch (event) {
        case 'applianceInstall':
            return 'Installation';
        case 'hardwareUpgrade':
            return 'Hardware';
        case 'softwareUpgrade':
            return 'Software';
        case 'applianceRenewal':
            return 'Contract Renewal';
        case 'decommission':
            return 'Decommission';
        case 'upcomingApplianceRenewal':
            return 'Upcoming Renewal';
        case 'currentApplianceState':
            return 'Current State';
        case 'eol':
            return 'End of Life Schedule';
        case 'eolUpcoming':
            return 'Upcoming End of Life Schedule';
        case 'dispatch':
            return 'Service Dispatch';
        case 'dispatchUpcoming':
            return 'Upcoming Service Dispatch';
        case 'everModernUpgrade':
        case 'everModernUpgradeUpcoming':
            return 'Ever Modern Upgrade';
        default:
            return exhaustiveGuard(event);
    }
}

export function getApplianceGenealogyEventColor(event: ApplianceGenealogyEventType): string {
    switch (event) {
        case 'applianceInstall':
            return '#C9C9C9';
        case 'hardwareUpgrade':
            return '#1B72CB';
        case 'softwareUpgrade':
            return '#00B89E';
        case 'applianceRenewal':
        case 'upcomingApplianceRenewal':
            return '#FEB804';
        case 'decommission':
            return '#CD146D';
        case 'currentApplianceState':
            return '#000000';
        case 'eol':
            return '#CD146D';
        case 'eolUpcoming':
            return '#CD146D';
        case 'dispatch':
        case 'dispatchUpcoming':
            return '#697889';
        case 'everModernUpgrade':
        case 'everModernUpgradeUpcoming':
            return '#FF6814';
        default:
            return exhaustiveGuard(event);
    }
}

export function getApplianceGenealogyEventLegendIconUrl(event: ApplianceGenealogyEventType): string {
    switch (event) {
        case 'applianceInstall':
            return 'diamond-gray.svg';
        case 'hardwareUpgrade':
            return 'circle-blue.svg';
        case 'softwareUpgrade':
            return 'square-green.svg';
        case 'applianceRenewal':
            return 'triangle-yellow.svg';
        case 'decommission':
            return 'x-red.svg';
        case 'eol':
            return 'eol-clock-icon-past.svg';
        case 'eolUpcoming':
            throw Error('Eol Upcoming is not a legend event');
        case 'dispatch':
            return 'dispatch-event-icon-past.svg';
        case 'dispatchUpcoming':
            throw Error('Dispatch Upcoming is not a legend event');
        case 'upcomingApplianceRenewal':
            throw Error('Upcoming Renewal is not a legend event');
        case 'currentApplianceState':
            throw Error('Current State is not a legend event');
        case 'everModernUpgrade':
            return 'ever-modern-event-icon-past.svg';
        case 'everModernUpgradeUpcoming':
            throw Error('Ever Modern Upgrade Upcoming is not a legend event');
        default:
            return exhaustiveGuard(event);
    }
}

export function getApplianceGenealogyEventIconUrl(event: ApplianceGenealogyEventType): string {
    switch (event) {
        case 'applianceInstall':
            return '/images/install_border.svg';
        case 'hardwareUpgrade':
            return '/images/hardware_border.svg';
        case 'softwareUpgrade':
            return '/images/software_border.svg';
        case 'applianceRenewal':
            return '/images/triangle-yellow.svg';
        case 'decommission':
            return '/images/decommission_border.svg';
        case 'upcomingApplianceRenewal':
            return '/images/triangle-outline-yellow.svg';
        case 'currentApplianceState':
            return '/images/dot-white.svg';
        case 'eol':
            return '/images/eol-clock-icon-past.svg';
        case 'eolUpcoming':
            return '/images/eol-clock-icon-upcoming.svg';
        case 'dispatch':
            return '/images/dispatch-event-icon-past.svg';
        case 'dispatchUpcoming':
            return '/images/dispatch-event-icon-upcoming.svg';
        case 'everModernUpgrade':
            return '/images/ever-modern-event-icon-past.svg';
        case 'everModernUpgradeUpcoming':
            return '/images/ever-modern-event-icon-upcoming.svg';
        default:
            return exhaustiveGuard(event);
    }
}

export function isApplianceGenealogyEvent(event: GenealogyEventType): event is ApplianceGenealogyEventType {
    return APPLIANCE_GENEALOGY_EVENTS.includes(event as ApplianceGenealogyEventType);
}

export function getSubscriptionGenealogyEventDisplayName(event: SubscriptionGenealogyEventType): string {
    switch (event) {
        case 'start':
            return 'Contract Start';
        case 'plannedRamp':
            return 'Planned Ramp';
        case 'updateRequest':
            return 'Update';
        case 'renewal':
            return 'Renewal';
        case 'expiration':
            return 'Expiration';
        case 'termination':
            return 'Termination';
        case 'upcomingRenewal':
            return 'Upcoming Renewal';
        case 'currentSubscriptionState':
            return 'Current State';
        default:
            return exhaustiveGuard(event);
    }
}

export function getSubscriptionGenealogyEventColor(event: SubscriptionGenealogyEventType): string {
    switch (event) {
        case 'start':
            return '#C9C9C9';
        case 'plannedRamp':
            return '#1B72CB';
        case 'updateRequest':
            return '#00B89E';
        case 'renewal':
        case 'upcomingRenewal':
            return '#FEB804';
        case 'expiration':
            return '#FC7164';
        case 'termination':
            return '#CD146D';
        case 'currentSubscriptionState':
            return '#000000';
        default:
            return exhaustiveGuard(event);
    }
}

export function getSubscriptionGenealogyEventLegendIconUrl(event: SubscriptionGenealogyEventType): string {
    switch (event) {
        case 'start':
            return 'diamond-gray.svg';
        case 'plannedRamp':
            return 'circle-purple.svg';
        case 'updateRequest':
            return 'square-teal.svg';
        case 'renewal':
            return 'triangle-yellow.svg';
        case 'expiration':
            return 'triangle-round-orange.svg';
        case 'termination':
            return 'x-red.svg';
        case 'upcomingRenewal':
            throw Error('Upcoming Renewal is not a legend event');
        case 'currentSubscriptionState':
            throw Error('Current State is not a legend event');
        default:
            return exhaustiveGuard(event);
    }
}

export function getSubscriptionGenealogyEventTimelineIconUrl(event: SubscriptionGenealogyEventType): string {
    switch (event) {
        case 'start':
            return 'diamond-gray-border.svg';
        case 'plannedRamp':
            return 'circle-purple-border.svg';
        case 'updateRequest':
            return 'square-teal-border.svg';
        case 'renewal':
            return 'triangle-yellow-border.svg';
        case 'expiration':
            return 'triangle-round-orange-border.svg';
        case 'termination':
            return 'x-red-border.svg';
        case 'upcomingRenewal':
            return 'triangle-outline-yellow.svg';
        case 'currentSubscriptionState':
            return 'dot-white.svg';
        default:
            return exhaustiveGuard(event);
    }
}

export function formatCapacity(data: number | string): string {
    const formatSizePipe = new FormatSizePipe();
    if (data == null) {
        return 'N/A ';
    }
    return isNumber(data) ? formatSizePipe.transform(data as number, 1, false, 'T') : data;
}

export function formatCapacityTB(bytes: number): string {
    const decimalPipe = new DecimalPipe('en-US');
    if (bytes == null) {
        return 'N/A ';
    }
    return decimalPipe.transform(bytes / 1e12, `1.0-0`);
}

export function isSubscriptionGenealogyEvent(event: GenealogyEventType): event is SubscriptionGenealogyEventType {
    return SUBSCRIPTION_GENEALOGY_EVENTS.includes(event as SubscriptionGenealogyEventType);
}
