export const KnownRoleDomains = {
    DRAAS: 'DRAAS',
    SAFE_MODE: 'SAFE_MODE',
} as const;

export class AdditionalRole {
    id: number;
    name: string;
    domain: string;
    assignableToIdentityGroup: boolean;

    static compareByNameAlphabetically(a: AdditionalRole, b: AdditionalRole): number {
        return a.name.localeCompare(b.name);
    }

    constructor(role: any) {
        this.id = role.id;
        this.name = role.name;
        this.domain = role.domain;
        this.assignableToIdentityGroup = role.assignableToIdentityGroup;
    }
}

export type AssignmentStatus = 'ASSIGNED' | 'ASSIGNMENT_PENDING' | 'REMOVAL_PENDING';

export class AdditionalRoleAssignment extends AdditionalRole {
    status?: AssignmentStatus;

    constructor(obj: any) {
        super(obj);

        if (obj.status !== undefined) {
            this.status = obj.status;
        }
    }
}
