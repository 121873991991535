import _ from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CachedCurrentUserService } from './cached-current-user.service';
import {
    AuthorizationServiceResolver,
    Permission,
    PermissionDomain,
    ResourceAuthorizationService,
} from '@pure/authz-authorizer';

/** @deprecated use {@link AuthorizationServiceResolver} instead */
@Injectable({ providedIn: 'root' })
export class AuthzAuthorizerService {
    constructor(
        private readonly cachedCurrentUserService: CachedCurrentUserService,
        private readonly authzServiceResolver: AuthorizationServiceResolver,
    ) {}

    getPermissionList(domain: string): Observable<string[]> {
        return this.authzSerivce().pipe(switchMap(service => service.getPermissionList(domain as PermissionDomain)));
    }

    refreshPermissionList(domain: string): void {
        this.authzSerivce()
            .pipe(
                switchMap(service =>
                    service.getPermissionList(domain as PermissionDomain, { fetchPolicy: 'network-first' }),
                ),
                take(1),
            )
            .subscribe();
    }

    hasPermission(permission: string): Observable<boolean> {
        return this.authzSerivce().pipe(switchMap(service => service.hasPermission(permission as Permission)));
    }

    private authzSerivce(): Observable<ResourceAuthorizationService> {
        return this.cachedCurrentUserService.get().pipe(
            take(1),
            map(({ organization_id }) =>
                this.authzServiceResolver.resolve({ id: organization_id, type: 'EXPANDED_ORGANIZATION_PHONEBOOK_ID' }),
            ),
        );
    }
}
