import { Component, Input, OnChanges } from '@angular/core';
import { FeatureFlagDxpService } from '@pure1/data';
import { ArrayGenealogy } from 'core/src/data/models/array-genealogy';
import moment from 'moment/moment';
import { take } from 'rxjs';
import { FeatureNames } from '../../../model/FeatureNames';
import { AssetManagementSummaryInfo } from '../../asset-management-summary-card/asset-management-summary-card.component';

const EOL_LOOK_FORWARD_MONTHS = 18;

export interface ApplianceGenealogyTotals {
    totalAppliancesInstalled: number;
    totalHardwareUpgrades: number;
    totalSoftwareUpgrades: number;
    totalEols: number;
    totalDispatchEvents: number;
}

@Component({
    selector: 'appliance-genealogy-totals',
    templateUrl: './appliance-genealogy-totals.component.html',
})
export class ApplianceGenealogyTotalsComponent implements OnChanges {
    @Input() readonly data: ArrayGenealogy[];

    summaries: AssetManagementSummaryInfo[] = [];
    isFollowupEnabled = false;
    flashBladeGenealogyEnabled = false;
    isEolEnabled = false;
    isDispatchEventEnabled = false;
    isEverModernUpgradeEnabled = false;
    readonly today = moment.utc();
    readonly eolLookForwardWindow = this.today.clone().add(EOL_LOOK_FORWARD_MONTHS, 'months');

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnInit(): void {
        this.calculateSummaries();
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_FOLLOWUP)
            .pipe(take(1))
            .subscribe(feature => {
                this.isFollowupEnabled = feature?.enabled === true;
                if (this.isFollowupEnabled) {
                    this.calculateSummaries();
                }
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.FLASH_BLADE_GENEALOGY)
            .pipe(take(1))
            .subscribe(feature => {
                this.flashBladeGenealogyEnabled = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.isEolEnabled = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_DISPATCH_EVENT)
            .pipe(take(1))
            .subscribe(feature => {
                this.isDispatchEventEnabled = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_EVER_MODERN_EVENT)
            .pipe(take(1))
            .subscribe(feature => {
                this.isEverModernUpgradeEnabled = feature?.enabled === true;
            });
    }

    ngOnChanges(): void {
        this.calculateSummaries();
    }

    calculateSummaries(): void {
        // Undefined will lead to showing dashes in the totals
        let totalAppliancesInstalled = undefined;
        let totalHardwareUpgrades = undefined;
        let totalSoftwareUpgrades = undefined;
        let totalUpcomingRenewals = undefined;
        let totalEols = undefined;
        let totalDispatchEvents = undefined;
        let totalEverModernUpgrades = undefined;

        if (this.data) {
            totalAppliancesInstalled = this.data.filter(array => {
                if (array.currentState?.model?.startsWith('FlashBlade//')) {
                    return this.flashBladeGenealogyEnabled && !!array.applianceInstallation;
                }
                if (array.currentState?.model?.startsWith('FlashBlade')) {
                    return false; // Skip classic aka legacy aka Norway FBs
                }
                return !!array.applianceInstallation;
            }).length;
            totalHardwareUpgrades = this.data.reduce((total, array) => array.hardwareUpgrades.length + total, 0);
            totalSoftwareUpgrades = this.data.reduce((total, array) => array.softwareUpgrades.length + total, 0);
            totalUpcomingRenewals = this.data.filter(array => array.upcomingRenewalEvent != null).length;
            totalEols = this.data.filter(array => this.hasEolComponentsInScope(array)).length;
            totalDispatchEvents = this.data.reduce((total, array) => array.dispatchEvents.length + total, 0);
            // assetAgeTargetDate is required as the upgrade date on the timeline
            totalEverModernUpgrades = this.data.filter(
                array => array.everModernUpgrade?.assetAgeTargetDate != null,
            ).length;
        }

        this.summaries = [
            {
                title: 'Appliances Installed',
                svg: 'server.svg',
                value: totalAppliancesInstalled,
            },
            {
                title: 'Hardware Upgrades',
                svg: 'arrow_down_to_square.svg',
                value: totalHardwareUpgrades,
            },
            {
                title: 'Software Upgrades',
                svg: 'arrow-up-cloud.svg',
                value: totalSoftwareUpgrades,
            },
            ...[].concat(
                this.isEolEnabled
                    ? [
                          {
                              title: 'End of Life',
                              svg: 'eol-clock-icon-upcoming.svg',
                              value: totalEols,
                          },
                      ]
                    : [],
                this.isDispatchEventEnabled
                    ? [
                          {
                              title: 'Service Dispatches',
                              svg: 'dispatch-event-icon-upcoming.svg',
                              value: totalDispatchEvents,
                          },
                      ]
                    : [],
                this.isEverModernUpgradeEnabled
                    ? [
                          {
                              title: 'Ever Modern Upgrades',
                              svg: 'ever-modern-event-icon-upcoming.svg',
                              value: totalEverModernUpgrades,
                          },
                      ]
                    : [],
                this.isFollowupEnabled
                    ? [
                          {
                              title: 'Upcoming Renewals',
                              svg: 'file-signature-yellow.svg',
                              value: totalUpcomingRenewals,
                          },
                      ]
                    : [],
            ),
        ];
    }

    // Returns true if any of the eol_components are in the past, or less than
    // or equal to EOL_LOOK_FORWARD_MONTHS months in the future.
    hasEolComponentsInScope(array: ArrayGenealogy): boolean {
        if (!array.eolHardware?.eolComponents) {
            return false;
        }
        for (const component of array.eolHardware.eolComponents) {
            // Skip components with null/undefined eolDates
            if (!component.eolDate) {
                continue;
            }
            if (component.eolDate.isSameOrBefore(this.eolLookForwardWindow)) {
                return true;
            }
        }
        return false;
    }
}
