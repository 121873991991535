import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ampli } from 'core/src/ampli';

import { Incident, SortParams, SubscriptionAsset, SubscriptionAssetService } from '@pure1/data';
import { Subject } from 'rxjs';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlService } from '../../redux/url.service';
import {
    MultiSelectOptions,
    TableColumn,
} from '../../subscription/subscription-page-table/subscription-page-table.component';
import { CustomTimeRange } from '../../ui/components/calendar-time-range-select/calendar-time-range-select.component';
import { AssetManagementService } from '../services/asset-management.service';

@Component({
    selector: 'asset-management-table',
    templateUrl: 'asset-management-table.component.html',
})
export class AssetManagementTableComponent implements OnChanges {
    @Input() readonly columns: TableColumn<SubscriptionAsset>[];
    @Input() readonly data: SubscriptionAsset[];
    @Input() readonly defaultSort: SortParams;
    @Input() readonly loading: boolean;
    @Input() readonly offset: number;
    @Input() readonly selections: SubscriptionAsset[];
    @Input() readonly selectedStartTimeRangeOption: CustomTimeRange;
    @Input() readonly selectedRenewalTimeRangeOption: CustomTimeRange;
    @Input() readonly incidentMap: Map<string, Incident>;
    @Input() readonly sort: SortParams | null;
    @Input() readonly total: number;
    @Input() readonly isPartner: boolean;

    @Output() readonly selectionsChange = new EventEmitter<SubscriptionAsset[]>();
    @Output() readonly sortChange = new EventEmitter<string>();
    @Output() readonly pageChange = new EventEmitter<number>();

    selectedAssets: SubscriptionAsset[] = [];
    pageSize = 25;

    multiSelectOptions: MultiSelectOptions<SubscriptionAsset> = {
        shouldDisableRow: row => {
            return !row.isVisibleArray;
        },
    };

    readonly destroy$ = new Subject<void>();
    readonly ampli = ampli;

    constructor(
        protected ngRedux: NgRedux<IState>,
        protected subscriptionAssetService: SubscriptionAssetService,
        url: UrlService,
        public assetManagementService: AssetManagementService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedStartTimeRangeOption || changes.selectedRenewalTimeRangeOption) {
            this.assetManagementService.updateAssets();
        }

        if (changes.selections && this.selections) {
            this.selectedAssets = this.selections.slice();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelectionsChange(newSelections: SubscriptionAsset[]): void {
        this.selectedAssets = newSelections;
        this.selectionsChange.emit(newSelections);
    }

    onPageChange(page: number): void {
        this.pageChange.emit(page);
    }

    openPopover(popover: NgbPopover, asset: SubscriptionAsset): void {
        popover.open({
            asset: asset,
        });
    }

    toggleSort(sort: string): void {
        this.sortChange.emit(sort);
    }
}
