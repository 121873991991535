<div class="loading-spinner">
    <pureui-spinner class="p-loading" [pureuiShow]="isLoading"></pureui-spinner>
</div>
<ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="State.DEFAULT">
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <h3 class="modal-title"> Accept {{ opportunityQuote?.name | defaultString }} </h3>
        </div>

        <main class="modal-body">
            <p> By accepting, you agree that this version of the quote will be presented to the customer. </p>
        </main>

        <footer class="modal-footer">
            <hive-button emphasis="secondary" label="Cancel" (click)="modal.dismiss()"></hive-button>
            <hive-button [disabled]="isLoading || !acceptAllowed" label="Accept" (click)="onAccept()"></hive-button>
        </footer>
    </ng-container>

    <ng-container *ngSwitchCase="State.ACCEPT">
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <h3 class="modal-title"> Accepted </h3>
        </div>

        <main class="modal-body">
            <p>
                You've accepted the quote <b>{{ opportunityQuote?.name | defaultString }}</b
                >.
            </p>
        </main>

        <footer class="modal-footer">
            <hive-button emphasis="secondary" label="Close" (click)="modal.dismiss()"></hive-button>
        </footer>
    </ng-container>

    <ng-container *ngSwitchCase="State.ERROR">
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <pureui-svg class="pstg-action-icon" svg="warning-icon.svg" height="20"> </pureui-svg>
            <h3 class="modal-title">Oops! Something went wrong.</h3>
        </div>
        <main class="modal-body">
            <div>Please try again. If the issue persists, contact Pure1 Technical Services.</div>
        </main>
        <footer class="modal-footer">
            <hive-button emphasis="secondary" label="Close" (click)="modal.dismiss()"></hive-button>
        </footer>
    </ng-container>
</ng-container>
