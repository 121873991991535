import moment from 'moment';
import { TimeRange } from '../../ui/components/time-range-selector/time-range-selector.component';
import { FilterParams, LicenseType, ProgramType, Subscription } from '@pure1/data';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LICENSE_COUNTRY_KEY, LICENSE_NAME_KEY, LICENSE_TYPE_KEY, PROGRAM_TYPE_KEY } from '../../redux/actions';

const MAX_DATA_RETENTION_QUARTERS = 12;

export class TimeRangeChoiceBeyondLicenseActivityPeriodError extends Error {
    constructor() {
        super('timeRangeChoice is beyond license activity period');
    }
}

export class LicenseTypeDetail {
    licenseType: LicenseType;
    label: string;
    group: string;
}

export const SUBSCRIPTION_WILDCARD_FIELDS = [LICENSE_COUNTRY_KEY, LICENSE_TYPE_KEY, LICENSE_NAME_KEY];

export enum SubscriptionView {
    LIST = 'list',
    DASHBOARD = 'dashboard',
}

const BLOCK_GROUP = '//Block';
const UBF_GROUP = '//Unified Block and File';
const UFFO_GROUP = '//UFFO (Unified Fast File and Object)';

const ULTRA = 'Ultra';
const PREMIUM = 'Premium';
const PERFORMANCE = 'Performance';
const ULTRA_PRE = 'Ultra (PRE)';
const PREMIUM_PRE = 'Premium (PRE)';
const PERFORMANCE_PRE = 'Performance (PRE)';
const CAPACITY = 'Capacity';

const LICENSE_TYPE_DETAIL = new Map<LicenseType, LicenseTypeDetail>([
    [
        LicenseType.BLOCK_ULTRA,
        {
            licenseType: LicenseType.BLOCK_ULTRA,
            label: ULTRA,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.BLOCK_ULTRA_PRE,
        {
            licenseType: LicenseType.BLOCK_ULTRA_PRE,
            label: ULTRA_PRE,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.BLOCK_PREMIUM,
        {
            licenseType: LicenseType.BLOCK_PREMIUM,
            label: PREMIUM,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.BLOCK_PREMIUM_PRE,
        {
            licenseType: LicenseType.BLOCK_PREMIUM_PRE,
            label: PREMIUM_PRE,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.BLOCK_PERFORMANCE,
        {
            licenseType: LicenseType.BLOCK_PERFORMANCE,
            label: PERFORMANCE,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.BLOCK_PERFORMANCE_PRE,
        {
            licenseType: LicenseType.BLOCK_PERFORMANCE_PRE,
            label: PERFORMANCE_PRE,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.BLOCK_CAPACITY,
        {
            licenseType: LicenseType.BLOCK_CAPACITY,
            label: CAPACITY,
            group: BLOCK_GROUP,
        },
    ],
    [
        LicenseType.UBF_ULTRA,
        {
            licenseType: LicenseType.UBF_ULTRA,
            label: ULTRA,
            group: UBF_GROUP,
        },
    ],
    [
        LicenseType.UBF_PREMIUM,
        {
            licenseType: LicenseType.UBF_PREMIUM,
            label: PREMIUM,
            group: UBF_GROUP,
        },
    ],
    [
        LicenseType.UBF_PERFORMANCE,
        {
            licenseType: LicenseType.UBF_PERFORMANCE,
            label: PERFORMANCE,
            group: UBF_GROUP,
        },
    ],
    [
        LicenseType.UBF_CAPACITY,
        {
            licenseType: LicenseType.UBF_CAPACITY,
            label: CAPACITY,
            group: UBF_GROUP,
        },
    ],
    [
        LicenseType.UFFO_ULTRA,
        {
            licenseType: LicenseType.UFFO_ULTRA,
            label: ULTRA,
            group: UFFO_GROUP,
        },
    ],
    [
        LicenseType.UFFO_PREMIUM,
        {
            licenseType: LicenseType.UFFO_PREMIUM,
            label: PREMIUM,
            group: UFFO_GROUP,
        },
    ],
]);

const LEGACY_LICENSE_TYPE_MAPPING = new Map<LicenseType, LicenseType>([
    [LicenseType.CBS, LicenseType.BLOCK_PERFORMANCE],
    [LicenseType.FA_SERVICE, LicenseType.BLOCK_PERFORMANCE],
    [LicenseType.FB_SERVICE, LicenseType.UFFO_PREMIUM],
    [LicenseType.FA_C_SERVICE, LicenseType.BLOCK_CAPACITY],
]);

export function getTimeRangeChoices(startDate: moment.Moment): TimeRange[] {
    const choices: TimeRange[] = [];
    let startTime = moment.utc().startOf('quarter');
    let endTime = moment.utc();
    // Build quarters starting from the current one
    while (endTime.isAfter(startDate) && choices.length < MAX_DATA_RETENTION_QUARTERS) {
        choices.push({
            start: startTime.clone(),
            end: endTime.clone(),
        });
        startTime = startTime.subtract(1, 'day').startOf('quarter');
        endTime = startTime.clone().endOf('quarter').startOf('day');
    }

    return choices.reverse(); // Puts the current time range as the last element
}

// Not customer configurable. We do this just as a nice QoL change
// Sort by terminated/expired or not -> sort by Poc or not -> sort by org -> sort by name within the org
export function subscriptionSort(a: Subscription, b: Subscription): number {
    // sort by terminated/expired or not
    if ((a.isTerminated() || a.isExpired()) && !b.isTerminated() && !b.isExpired()) {
        return 1;
    } else if ((b.isTerminated() || b.isExpired()) && !a.isTerminated() && !a.isExpired()) {
        return -1;
    }

    //sort by poc or not
    if (a.isPoc && !b.isPoc) {
        return -1;
    } else if (!a.isPoc && b.isPoc) {
        return 1;
    }

    //sort by org, orgId can be null
    if (a.orgId === null && b.orgId !== null) {
        return -1;
    } else if (a.orgId !== null && b.orgId === null) {
        return 1;
    } else if (a.orgId !== b.orgId) {
        return a.orgId - b.orgId;
    }

    //sort by name
    return a.name.localeCompare(b.name);
}

export function isServiceTierSupported(programType: ProgramType): boolean {
    return (
        programType === ProgramType.PURE_AS_A_SERVICE ||
        programType === ProgramType.FLASH_STACK_AS_A_SERVICE ||
        programType === ProgramType.EVERGREEN_ONE
    );
}

export function migrateToServiceTier(licenseType: LicenseType): LicenseType {
    if (LEGACY_LICENSE_TYPE_MAPPING.has(licenseType)) {
        return LEGACY_LICENSE_TYPE_MAPPING.get(licenseType);
    } else {
        return licenseType;
    }
}

export function getLicenseTypeDetail(licenseType: LicenseType): LicenseTypeDetail {
    return LICENSE_TYPE_DETAIL.get(licenseType);
}

export function convertToDate(dateStruct: NgbDateStruct): number {
    if (!dateStruct) {
        return null;
    }
    return moment(dateStruct.year + '-' + dateStruct.month + '-' + dateStruct.day, 'YYYY-M-D')
        .utc()
        .valueOf();
}

export function convertToNgbDateStruct(dateToConvert: moment.Moment): NgbDateStruct {
    if (!dateToConvert) {
        return null;
    }

    return {
        year: dateToConvert.year(),
        month: dateToConvert.month() + 1, //months are 0 indexed
        day: dateToConvert.date(), //but day of the month is not. Weird, eh?
    };
}

export function getTimerangeForQueringMetrics(
    timeRangeChoice: TimeRange,
    licenseStartDate: moment.Moment,
    licenseEndDate: moment.Moment,
): TimeRange {
    if (timeRangeChoice.end.isBefore(licenseStartDate) || timeRangeChoice.start.isAfter(licenseEndDate)) {
        throw new TimeRangeChoiceBeyondLicenseActivityPeriodError();
    }

    const start = moment.utc(Math.max(timeRangeChoice.start.valueOf(), licenseStartDate.valueOf()));

    // It's possible for current time - 1 to be before start at the start of a quarter.
    // This check is to make sure we don't run into that weirdness
    let mostRecentDatapoint = moment.utc().subtract(1, 'day');
    if (mostRecentDatapoint.isBefore(start)) {
        mostRecentDatapoint = start.clone();
    }

    const end = moment.utc(
        Math.min(
            timeRangeChoice.end.valueOf(),
            mostRecentDatapoint.valueOf(),
            licenseEndDate ? licenseEndDate.valueOf() : Infinity,
        ),
    );

    return { start, end };
}

export function appendProgramTypeFilters(
    existing: FilterParams<Subscription>,
    programTypes: ProgramType[],
): FilterParams<Subscription> {
    if (!programTypes?.length) {
        return existing;
    }

    const valuesStr = (programTypes || []).map(type => `'${type}'`).join(',');
    return Object.assign(
        {},
        {
            ...existing,
            [PROGRAM_TYPE_KEY]: `contains(${PROGRAM_TYPE_KEY},(${valuesStr}))`,
        },
    );
}
