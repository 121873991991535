import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { SpinnerService } from 'core/src/services/spinner.service';

@Pipe({
    name: 'asyncSpinner',
    pure: false,
})
export class AsyncSpinnerPipe implements PipeTransform {
    private asyncPipe: AsyncPipe;

    constructor(
        private cdr: ChangeDetectorRef,
        private readonly spinnerService: SpinnerService,
    ) {
        this.asyncPipe = new AsyncPipe(this.cdr);
    }

    transform(value: any): any {
        return Boolean(this.asyncPipe.transform(this.spinnerService.getSpinnerUpdates(value)));
    }

    ngOnDestroy() {
        this.asyncPipe.ngOnDestroy();
    }
}
