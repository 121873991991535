import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DisasterRecoveryOvaUrl, FilterParams } from '@pure1/data';
import {
    DraasApiFailbackOva,
    DraasApiFailbackOvasWithPresignedLinks,
    DraasApiOvaUrl,
    DraasApiPollPresignedLinks,
    DraasApiRequestCreationOfPresignedLinks,
} from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryFailbackOva } from '../models/disaster-recovery-failback-ova';
import { forkJoin, map, mergeMap, Observable, repeatWhen, skipWhile, switchMap } from 'rxjs';
import {
    DisasterRecoveryOvaPartWithPresignedLink,
    DisasterRecoveryOvasWithPresignedLinks,
} from '../models/disaster-recovery-ovas-with-presigned-links';
import { delay, take, tap } from 'rxjs/operators';
import { DisasterRecoveryEncryptionService } from './disaster-recovery-encryption.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryFailbackOvasService extends DisasterRecoveryBaseService<
    DisasterRecoveryFailbackOva,
    DraasApiFailbackOva
> {
    protected resourceClass = DisasterRecoveryFailbackOva;
    protected pathParams = ['clusterId'];
    private readonly urlsPlaceholder = '#URLS {id}#';

    constructor(
        protected http: HttpClient,
        private encryptionService: DisasterRecoveryEncryptionService,
    ) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryFailbackOva>): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/failback-ovas`;
    }

    private getDownloadLinkEndpoint(clusterId: string, ovaId: string): string {
        return `${this.getEndpoint({ clusterId })}/link/${ovaId}`;
    }

    private getV2LinksEndpoint(clusterId: string): string {
        return `${this.getEndpoint({ clusterId })}/links`;
    }

    requestDownloadLink(clusterId: string, ovaId: string): Observable<void> {
        return this.http.post<void>(this.getDownloadLinkEndpoint(clusterId, ovaId), null);
    }

    pollDownloadLink(clusterId: string, ovaId: string, retryCount: number): Observable<DisasterRecoveryOvaUrl> {
        return this.http
            .get<DraasApiOvaUrl>(this.getDownloadLinkEndpoint(clusterId, ovaId), { observe: 'response' })
            .pipe(
                repeatWhen(delay(1000)),
                skipWhile((response, index) => response.status === 202 && index < retryCount),
                take(1),
                map(response => {
                    if (response.status === 200) {
                        return new DisasterRecoveryOvaUrl(response.body);
                    } else {
                        throw new Error(`Failed to fetch OVA download link after ${retryCount} retries`);
                    }
                }),
            );
    }

    requestPresignedLinksV2(clusterId: string, ovaIds: string[]): Observable<CryptoKey> {
        const generateKeyPair$ = this.encryptionService.generateRSAKeyPair();
        const doRequest$ = generateKeyPair$.pipe(
            switchMap(keyPair => this.encryptionService.exportRSAPublicKey(keyPair.publicKey)),
            switchMap(publicKey => {
                const body: DraasApiRequestCreationOfPresignedLinks = {
                    ova_ids: ovaIds,
                    public_key: publicKey,
                };
                return this.http.post<void>(`${this.getV2LinksEndpoint(clusterId)}/request`, body);
            }),
        );
        return forkJoin([generateKeyPair$, doRequest$]).pipe(map(([keyPair]) => keyPair.privateKey));
    }

    pollPresignedLinksV2(
        clusterId: string,
        ovaIds: string[],
        rsaPrivateKey: CryptoKey,
        retryCount: number,
    ): Observable<DisasterRecoveryOvasWithPresignedLinks> {
        const body: DraasApiPollPresignedLinks = { ova_ids: ovaIds };
        return this.http
            .post<DraasApiFailbackOvasWithPresignedLinks>(`${this.getV2LinksEndpoint(clusterId)}/poll`, body, {
                observe: 'response',
            })
            .pipe(
                repeatWhen(delay(1000)),
                skipWhile((response, index) => response.status === 202 && index < retryCount),
                take(1),
                map(response => {
                    if (response.status === 200) {
                        return new DisasterRecoveryOvasWithPresignedLinks(response.body);
                    } else {
                        throw new Error(`Failed to fetch OVA download link after ${retryCount} retries`);
                    }
                }),
                switchMap(ovas => this.decryptAllLinks(ovas, rsaPrivateKey)),
                tap(ovas => this.addLinksToDownloadScripts(ovas)),
            );
    }

    private decryptAllLinks(
        ovas: DisasterRecoveryOvasWithPresignedLinks,
        rsaPrivateKey: CryptoKey,
    ): Observable<DisasterRecoveryOvasWithPresignedLinks> {
        const allParts = ovas.ovas.flatMap(ova => ova.parts);
        return this.encryptionService.decryptAESKeyWithRSA(ovas.secretKey, rsaPrivateKey).pipe(
            mergeMap(aesKey => forkJoin(allParts.map(part => this.decryptPartLink(part, aesKey)))),
            // the links are decrypted in place, so we can just return the original object
            map(() => ovas),
        );
    }

    private decryptPartLink(
        part: DisasterRecoveryOvaPartWithPresignedLink,
        aesKey: CryptoKey,
    ): Observable<DisasterRecoveryOvaPartWithPresignedLink> {
        return this.encryptionService.decryptWithAES(part.presignedLink, aesKey).pipe(
            map(decryptedLink => {
                part.presignedLink = decryptedLink;
                return part;
            }),
        );
    }

    private addLinksToDownloadScripts(ovas: DisasterRecoveryOvasWithPresignedLinks): void {
        ovas.ovas.forEach(ova => {
            const placeholder = this.urlsPlaceholder.replace('{id}', ova.id);
            ovas.bashDownloadScript = ovas.bashDownloadScript.replace(
                placeholder,
                ova.parts.map(part => `"${part.presignedLink}"`).join('\n    '),
            );
            ovas.powershellDownloadScript = ovas.powershellDownloadScript.replace(
                placeholder,
                ova.parts.map(part => `"${part.presignedLink}"`).join(',\n    '),
            );
        });
    }
}
