import { Resource } from '../interfaces/resource';

class EmailTypeFrequencyRequest {
    email_type: string;
    email_frequency: EmailFrequency;
}

export enum EmailFrequency {
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
    NONE = 'None',
}

export class EmailTypeFrequency {
    emailType: string;
    userFriendlyEmailType: string;
    emailFrequency: EmailFrequency;

    constructor(json: any) {
        this.emailType = json.email_type;
        this.userFriendlyEmailType = json.user_friendly_email_type;
        this.emailFrequency = json.email_frequency;
    }

    toRequestBody(): EmailTypeFrequencyRequest {
        return {
            email_type: this.emailType,
            email_frequency: this.emailFrequency,
        };
    }
}

export class EmailTypeGrouping {
    displayName: string;
    emailTypeFrequencies: EmailTypeFrequency[];

    constructor(json: any) {
        this.displayName = json.display_name;
        this.emailTypeFrequencies = json.email_type_frequencies
            ? json.email_type_frequencies.map(frequency => new EmailTypeFrequency(frequency))
            : [];
    }

    toRequestBody(): EmailTypeFrequencyRequest[] {
        return this.emailTypeFrequencies.map(emailType => emailType.toRequestBody());
    }
}

export class EmailSettings implements Resource {
    id: string;
    name: string;
    emailTypeGroupings: EmailTypeGrouping[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.emailTypeGroupings = json.email_type_groupings
            ? json.email_type_groupings.map(frequency => new EmailTypeGrouping(frequency))
            : [];
    }

    toRequestBody(): EmailTypeFrequencyRequest[] {
        return this.emailTypeGroupings.flatMap(emailType => emailType.toRequestBody());
    }
}
