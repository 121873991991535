import { Resource } from '../interfaces/resource';
import { AdditionalRoleAssignment } from './additional-role';
import { Assignment } from './assignment';

export class SSOUser extends Assignment implements Resource {
    id: string;
    name: string;
    email: string;
    additionalRoles: AdditionalRoleAssignment[];

    constructor(user: any) {
        super(user);
        // This model is used in different contexts even though it is not supposed to.
        // This is a hotfix to pick either of existing properties for the time being.
        // TODO: Should be fixed as part of CLOUD-106105
        this.id = user.id || user.name;
        this.email = user.email || user.name;
        this.name = user.name || user.email;
        this.additionalRoles = (user.additionalRoles || []).map(role => new AdditionalRoleAssignment(role));
    }
}
