import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
    Injector,
    OnInit,
} from '@angular/core';
import { UserRoleID } from '@pure1/data';
import { UserRoleItem } from './user-role-item';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'user-roles-select',
    templateUrl: './user-roles-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserRolesSelectComponent),
            multi: true,
        },
    ],
})
export class UserRolesSelectComponent implements OnInit, ControlValueAccessor {
    @Input()
    get value(): UserRoleID[] {
        return this._value;
    }
    set value(newValue: UserRoleID[]) {
        const hasAssigned = this.assignValue(newValue);

        if (hasAssigned) {
            this.onChange(newValue);
        }
    }
    private _value: UserRoleID[] = [];

    @Input()
    get items(): UserRoleItem[] {
        return this._items;
    }
    set items(newItems: UserRoleItem[]) {
        this._items = newItems;
        for (const item of this._items) {
            this._itemsMap.set(item.id, item);
        }
    }

    private _items: UserRoleItem[] = [];
    private _itemsMap: Map<UserRoleID, UserRoleItem> = new Map();

    @Input() disabled = false;
    @Input() loading = false;
    @Input() shouldDisplayFn = (item: UserRoleItem, selectedRoles: UserRoleID[]): boolean => true;
    @Input() errorMessage?: string;

    @Output() valueChange = new EventEmitter<UserRoleID[]>();

    get placeholder(): string {
        if (this.loading) {
            return '';
        } else if (this.items.length === 0) {
            return 'No roles available';
        } else if (this.value.length === 0) {
            return 'Select roles';
        }

        return '';
    }

    get groupByFn(): () => string | null {
        return this.value.length > 0 ? () => 'Supported Role Combination' : null;
    }

    ngControl: NgControl;

    get controlError(): string | null {
        if (this.ngControl) {
            return this.ngControl.touched ? this.errorMessage : null;
        }

        return this.errorMessage;
    }

    constructor(private injector: Injector) {}

    ngOnInit(): void {
        this.ngControl = this.injector.get(NgControl);
    }

    getItemField(id: UserRoleID, fieldName: string): string {
        if (this._itemsMap.has(id)) {
            return this._itemsMap.get(id)[fieldName];
        }
        return id.toString();
    }

    onTouched = (): void => {};
    onChange = (value: UserRoleID[]): void => {};

    onValueChange(value: UserRoleID[]): void {
        this.onTouched();
        this.assignValue(value);
        this.propagateChange();
    }

    writeValue(val: UserRoleID[]): void {
        this.value = val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private assignValue(newValue: UserRoleID[]): boolean {
        if (newValue !== this._value) {
            this._value = newValue;

            return true;
        }

        return false;
    }

    private propagateChange(): void {
        this.valueChange.emit(this.value);
        this.onChange(this.value);
    }
}
