<div class="ur-container" *ngIf="{ loading: 'createEditUser' | asyncSpinner } as state">
    <div class="drawer-title" *ngIf="isEdit; else newUserTitle"> {{ user.email }} </div>
    <ng-template #newUserTitle>
        <div class="drawer-title"> Create New {{ isExternalUser() ? 'External' : '' }} User </div>
    </ng-template>
    <div id="form" class="ur-body form" *ngIf="status === 'form'">
        <pureui-spinner class="p-loading" [pureuiShow]="state.loading"></pureui-spinner>
        <div class="profile" *ngIf="!state.loading" [ngSwitch]="userType">
            <external-user-form
                *ngSwitchCase="'external'"
                [assignment]="user"
                [roleAssignment]="userAssignment"
                [isEdit]="isEdit"
                [deferredAssignable]="urStateService.deferredAssignable"
            />
            <user-form
                *ngSwitchCase="'regular'"
                [assignment]="$any(user)"
                [roleAssignment]="userAssignment"
                [isEdit]="isEdit"
                [deferredAssignable]="urStateService.deferredAssignable"
                [isCurrentUser]="isCurrentUser(user)"
                [currentUser]="currentUser"
            />
        </div>
    </div>

    <div id="successCreate" class="result-message success" *ngIf="status === 'successCreate'">
        <pureui-svg class="pstg-secondary-icon result-icon" svg="ok.svg" height="74"></pureui-svg><br />
        <div *ngIf="isExternalUser(); else newUser">
            <h2>Account was successfully added</h2>
            <p class="result-success-secondary-text"
                >An email will be sent to <em>{{ newExternalUser.email }}</em> with instructions <br />on how to login
                and set up their password.</p
            >
        </div>
        <ng-template #newUser>
            <h2>Account was successfully created.</h2>
        </ng-template>
    </div>
</div>
