import { Observable, of, ReplaySubject, Subject, Subscription, throwError, zip } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import {
    Collection,
    CurrentUser,
    ExternalUser,
    FilterParams,
    Group,
    ListParams,
    Product,
    Resource,
    SortParams,
    UnifiedArray,
    UnifiedArrayService,
    User,
    ViewsService,
} from '@pure1/data';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { getFilters, getSimpleFilterString } from '../../utils/filter/conversion';
import { addFilter, ARRAY_NAME_KEY, NAME_KEY, removeAllFilters } from '../../redux/actions';
import { UserRoleStateService } from '../services/user-role-state.service';
import { consolidateFilterValues, prepForDataLayer } from '../../gui/paged-data2.component';
import { Assignable, Assignment } from '../../user-management/user-management.interface';
import { VIEW_NAME_REG_EX } from '../../utils/strings';
import { ToastService, ToastType } from '../../services/toast.service';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';

// view name validation error messages
const INVALID_CHARACTERS = 'Please use combinations of letters, numbers, dashes, or underscores';
const DUPLICATE_NAME = 'This view name already exists, please try another name.';
const EMPTY_NAME = 'Enter a name for this view.';
const MAX_VIEW_NAME_LENGTH = 63;
const MAX_LENGTH_EXCEEDED = "View name can't be more than 63 characters.";

export function updateViewNameTooltipMessage(
    viewName: string,
    viewNameTooltip: NgbTooltip,
    viewNameControl: UntypedFormControl,
    isValidSimpleFilter: () => boolean = () => false,
): void {
    viewNameTooltip.ngbTooltip = null;
    if (viewName && viewNameControl.errors) {
        if (viewNameControl.errors.pattern) {
            viewNameTooltip.ngbTooltip = INVALID_CHARACTERS;
        } else if (viewNameControl.errors.unique) {
            viewNameTooltip.ngbTooltip = DUPLICATE_NAME;
        }
    } else if (!viewName && !isValidSimpleFilter()) {
        // ask the user to provide a name if there isn't one and it is not a valid simple filter
        viewNameTooltip.ngbTooltip = EMPTY_NAME;
    } else if (viewName && viewName.length === MAX_VIEW_NAME_LENGTH) {
        viewNameTooltip.ngbTooltip = MAX_LENGTH_EXCEEDED;
    }

    // show the tooltip if there is a message
    if (viewNameTooltip.ngbTooltip) {
        if (!viewNameTooltip.isOpen()) {
            viewNameTooltip.open();
        }
    } else {
        viewNameTooltip.close();
    }
}

export const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again.';

const ORG_ID = 'org_id';
const VIEW_NAME_VALIDATORS = [
    Validators.pattern(VIEW_NAME_REG_EX),
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(MAX_VIEW_NAME_LENGTH),
];

type editViewsState = 'create' | 'update' | 'assign' | 'standalone_create';

@Component({
    selector: 'edit-view',
    templateUrl: './edit-view.component.html',
})
export class EditViewComponent implements OnChanges, OnDestroy {
    @Input() readonly selectedAssignees: Assignment[] = [];
    @Input() readonly viewToEdit: Resource;
    @Input() readonly assignmentService: Collection<Assignment>;
    @Input() readonly currentUser: CurrentUser;
    @Input() readonly deferAssignable: boolean = false;
    @Input() readonly allowSimpleFilter: boolean = true;

    @ViewChild('viewNameErrorTooltip', { static: true }) viewNameErrorTooltip: NgbTooltip;

    @Output() readonly viewUpdated = new EventEmitter<void>();
    @Output() readonly viewUpdateFailed = new EventEmitter<void>();

    loading = true;
    barId = 'viewEditor';
    currentView: Resource;
    flashArrayCount = 0;
    flashBladeCount = 0;
    appliances: UnifiedArray[] = [];
    initialLoad: boolean;
    readonly viewRefCache: ReplaySubject<Resource[]> = new ReplaySubject(1);
    readonly viewName: UntypedFormControl = new UntypedFormControl(
        '',
        VIEW_NAME_VALIDATORS, // sync validator
        this.uniqueNameValidation.bind(this), //async validator
    );

    private readonly destroy$ = new Subject<void>();
    private arraysSubscription: Subscription;

    constructor(
        public urStateService: UserRoleStateService,
        private angulartics2: Angulartics2,
        private toast: ToastService,
        private viewsService: ViewsService,
        private ngRedux: NgRedux<IState>,
        private unifiedArraysService: UnifiedArrayService,
    ) {}

    get state(): editViewsState {
        if (this.currentView) {
            if (this.selectedAssignees && this.selectedAssignees.length > 0) {
                return 'assign';
            } else {
                return 'update';
            }
        } else {
            if (this.allowSimpleFilter) {
                return 'create';
            } else {
                return 'standalone_create';
            }
        }
    }

    ngOnChanges(): void {
        this.loading = false;
        this.ngRedux.dispatch(removeAllFilters(this.barId)); // clean-up the GFB initially;
        if (this.viewToEdit) {
            this.currentView = this.viewToEdit;
            this.viewName.setValue(this.currentView.name);
            this.selectView(this.currentView, true);
        }

        this.ngRedux
            .select(['filters', this.barId])
            .pipe(takeUntil(this.destroy$))
            .subscribe(filters => {
                if (this.initialLoad || this.state === 'update') {
                    this.initialLoad = false;
                } else {
                    this.currentView = null;
                }
                // this is so that we can remove/add the tooltip as pills are added and simple filter validity changes
                this.updateErrorTooltipText(this.viewName.value);
                this.fetchData();
            });

        this.urStateService
            .listWithCache(this.viewsService)
            .pipe(
                takeUntil(this.destroy$),
                map(dataPage => dataPage.response.map(view => ({ name: view.name, id: view.id }))),
            )
            .subscribe(viewRefs => this.viewRefCache.next(viewRefs));

        this.fetchData();

        this.viewName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(viewNameValue => {
            this.updateErrorTooltipText(viewNameValue);
        });
    }

    ngOnDestroy(): void {
        if (this.urStateService) {
            this.urStateService.assignments = []; // remove the assignees, so that they don't stick around til next time
            this.urStateService.viewToEdit = null; // remove the input, so that it's not defined when we re-enter this state
        }
        this.ngRedux.dispatch(removeAllFilters(this.barId)); // clean-up the GFB
        this.destroy$.next();
        this.unsubscribe();
    }

    updateErrorTooltipText(viewNameValue: string): void {
        updateViewNameTooltipMessage(
            viewNameValue,
            this.viewNameErrorTooltip,
            this.viewName,
            this.isValidSimpleFilter.bind(this),
        );
    }

    selectView(viewReference: Resource, updateGfb = false): void {
        if (this.state !== 'update') {
            // change mode, clear GFB, populate with parsed filter, etc.
            this.currentView = viewReference;
        }

        // convert the selector string to pills
        if (updateGfb) {
            this.initialLoad = true;
            this.urStateService
                .listWithCache(this.viewsService)
                .pipe(take(1))
                .subscribe(dataPage => {
                    const view = dataPage.response.filter(view => view.id === viewReference.id)[0];
                    this.ngRedux.dispatch([
                        removeAllFilters(this.barId),
                        ...getFilters(view.selector).map(filter => addFilter(this.barId, filter)),
                    ]);
                });
        }

        if (!this.viewToEdit) {
            this.angulartics2.eventTrack.next({
                action: 'UM - View selected in GFB',
                properties: { category: 'Action' },
            });
        }
    }

    applyChanges(): void {
        const selector = this.getCurrentSelector();

        // start the spinner, assuming we have 1 or more calls to the backend
        this.loading = true;

        /** Every operation can be broken down into two steps, an assignable step and an assignment step
         * The assignable part can consist of:
         * - Create View
         * - Update View
         * - Create Simple Filter (basically a no-op)
         * Failures at this stage can be handled on the Edit Views page, usually (the exception is for deferred view creation from Edit Users form)
         *
         * The assignment step can consist of:
         * - Create User
         * - Update User
         * - no-op (for update view operation)
         *
         * The below logic chooses an observable for the view part, and an observable for the assignment part,
         * and then routes them up and subscribes.
         */

        // STEP 1:  The assignable step
        let assignable$: Observable<Assignable>; // this observable can outlive this component. We should confirm everywhere we use 'this'
        switch (this.state) {
            case 'create':
            case 'standalone_create': {
                const name = this.viewName.value;
                if (this.isValidSimpleFilter()) {
                    assignable$ = of({ selector, view: null });
                } else {
                    assignable$ = this.urStateService.createWithCache(this.viewsService, { name, selector }).pipe(
                        map(view => ({ view: { id: view.id, name: view.name }, selector: null })),
                        tap(
                            () => {},
                            error => {
                                this.loading = false;
                                this.toast.add(ToastType.error, GENERIC_ERROR_MESSAGE);
                                console.error(error);
                            },
                        ),
                    );
                }
                break;
            }
            case 'assign':
                // we should deep copy currentView, just in case we need it after this is garbage collected
                assignable$ = of({ view: Object.assign({}, this.currentView), selector: null });
                break;
            case 'update':
                // we don't need to update any assignees when the state is 'update'
                assignable$ = this.urStateService
                    .updateWithCache(
                        this.viewsService,
                        {
                            // we have to put the quotes here or the backend throws a 400
                            name: this.viewName.value,
                            selector: selector,
                            id: this.currentView.id,
                        },
                        [this.currentView.id],
                    )
                    .pipe(
                        map(dataPage => {
                            if (dataPage.response.length !== 1) {
                                /* TODO: throw an error */
                            }
                            const view = dataPage.response[0];
                            return { view: { id: view.id, name: view.name }, selector: null };
                        }),
                    );
                break;
            default:
                assignable$ = throwError('unexpected state');
        }

        // STEP 2: the assignment step
        if (this.deferAssignable) {
            // TODO: allow selecting an existing view here
            let name: string;
            if (this.isValidSimpleFilter()) {
                name = getSimpleFilterString(selector);
            } else {
                name = this.viewName.value;
            }
            this.urStateService.deferredAssignable = {
                selector,
                name,
                isSimpleFilter: this.isValidSimpleFilter(),
                assignable$,
            };
            this.urStateService.previousDrawerState();
            return;
        }
        let assignment$: Observable<Assignment[]>;
        switch (this.state) {
            case 'assign':
            case 'create':
                assignment$ = assignable$.pipe(
                    switchMap(
                        (assignable: Assignable) =>
                            // backend doesn't support batching of view assignments
                            // so we break down each assignee into a different request
                            zip(
                                ...this.selectedAssignees.map(assignee =>
                                    this.urStateService.updateWithCache(this.assignmentService, assignable, [
                                        assignee.id,
                                    ]),
                                ),
                            ).pipe(
                                map(dataPages =>
                                    dataPages // collect every assignment from each dataPage
                                        .flatMap(dataPage => dataPage.response),
                                ),
                            ), // and return as a single array
                    ),
                );
                break;
            case 'update':
            case 'standalone_create':
                assignment$ = assignable$.pipe(map(() => [])); // no assignments were updated in 'update' state, so return empty array
                break;
            default:
                assignment$ = throwError('unexpected state');
        }

        assignment$.subscribe(
            () => {
                this.loading = false;
                this.trackViewCreateUpdateEvent();
                this.urStateService.previousDrawerState();
                this.viewUpdated.emit();
            },
            error => {
                this.loading = false;
                console.error('EditViewComponent applyChanges() error', error);
                this.toast.add(
                    ToastType.error,
                    'Something went wrong. Please confirm user assignments and try again if necessary.',
                );
                this.urStateService.previousDrawerState();
                this.viewUpdateFailed.emit();
            },
        );
    }

    fetchData(): void {
        this.unsubscribe();
        this.loading = true;

        this.arraysSubscription = this.unifiedArraysService
            .list(this.getListParams())
            .pipe(take(1))
            .subscribe(dataPage => {
                this.loading = false;
                this.appliances = dataPage.response;
                const productCounts = this.appliances
                    .map(array => array.product)
                    .reduce(
                        (counts: { [product: string]: number }, product) => {
                            counts[product]++;
                            return counts;
                        },
                        {
                            [Product.FA]: 0,
                            [Product.FB]: 0,
                        },
                    );
                this.flashArrayCount = productCounts[Product.FA];
                this.flashBladeCount = productCounts[Product.FB];
            });
    }

    isValidSimpleFilter(): boolean {
        // make sure that ngRedux has been initialized, otherwise skip validation for now
        if (this.ngRedux) {
            const filters = consolidateFilterValues(this.ngRedux.getState().filters[this.barId] || [], []);
            return this.allowSimpleFilter && this.viewName.value.length === 0 && filters.length === 1;
        } else {
            return true;
        }
    }

    getAssigneeText(): string {
        if (this.selectedAssignees.length > 0) {
            const assignee = this.selectedAssignees[0];
            if (assignee instanceof User) {
                return 'users';
            } else if (assignee instanceof ExternalUser) {
                return 'external users';
            } else if (assignee instanceof Group) {
                return 'groups';
            }
        }
        return '';
    }

    getPrimaryActionBtnEnableStatus(): boolean {
        switch (this.state) {
            case 'assign':
                return true;
            case 'standalone_create':
            case 'update':
                return this.viewName.valid;
            case 'create':
                return this.viewName.valid || this.isValidSimpleFilter();
            default:
                return false;
        }
    }

    getActionButtonText(): string {
        if (this.deferAssignable) {
            return 'Confirm';
        }
        switch (this.state) {
            case 'assign':
                return 'Apply';
            case 'update':
                return 'Update';
            case 'standalone_create':
                return 'Save';
            case 'create':
            default:
                return 'Save & Apply';
        }
    }

    private trackViewCreateUpdateEvent(): void {
        if (!this.deferAssignable) {
            const action =
                this.state === 'update'
                    ? `UM - View updated`
                    : `UM - ${this.isValidSimpleFilter() ? 'Simple filter' : 'View'} created from ${this.state} state`;
            this.angulartics2.eventTrack.next({
                action,
                properties: { category: 'Action' },
            });
        }
    }

    private unsubscribe(): void {
        if (this.arraysSubscription && !this.arraysSubscription.closed) {
            this.arraysSubscription.unsubscribe();
        }
    }

    private getCurrentSelector(): string {
        const multiValueFilters = consolidateFilterValues(this.ngRedux.getState().filters[this.barId] || [], []).map(
            multiValueFilter => {
                // re-map spog properties (from GFB) into AstroMonkey properties
                if (multiValueFilter.namespace === null && multiValueFilter.key === ARRAY_NAME_KEY) {
                    return { ...multiValueFilter, key: NAME_KEY };
                } else {
                    return multiValueFilter;
                }
            },
        );
        const filterParams = prepForDataLayer(multiValueFilters);
        return Object.values(filterParams).join(' and ');
    }

    private getListParams(): ListParams<UnifiedArray> {
        const multiValueFilters = consolidateFilterValues(this.ngRedux.getState().filters[this.barId] || [], []);

        if (this.currentUser?.organization_id) {
            multiValueFilters.push({
                namespace: null,
                entity: 'arrays',
                key: ORG_ID,
                values: [String(this.currentUser.organization_id)],
                operator: 'equals',
            });
        }
        const filter: FilterParams<UnifiedArray> = prepForDataLayer(multiValueFilters);
        const sort: SortParams = {
            key: 'hostname',
            order: 'asc',
        };

        return { filter, sort };
    }

    private uniqueNameValidation(control: AbstractControl): Observable<{ [key: string]: any } | null> {
        if (!this.viewsService) {
            return of(null);
        }
        return this.viewRefCache.pipe(
            take(1),
            map(existingViews => {
                // if editing a view, remove
                if (this.state === 'update') {
                    existingViews = existingViews.filter(ref => ref.id !== this.currentView.id);
                }

                const currentViewNameLc = control.value.toLocaleLowerCase();
                existingViews = existingViews.filter(ref => ref.name.toLocaleLowerCase() === currentViewNameLc);

                if (existingViews.length > 0) {
                    return { unique: { value: control.value } };
                } else {
                    return null;
                }
            }),
        );
    }
}
