import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { Params } from '@angular/router';

import { FeatureFlagDxpService, ProgramType, SafeModeStatusEnum, SubscriptionAsset } from '@pure1/data';
import { FeatureNames } from 'core/src/model/FeatureNames';
import moment from 'moment';
import { take } from 'rxjs';
import { LICENSE_ENTITY, NAME_KEY, SUBSCRIPTION_ENTITY } from '../../redux/actions';
import {
    SelectableColumn,
    SelectableColumnGroup,
} from '../../subscription/subscription/subscription-column-selector/subscription-column-selector.component';
import { getEverModernUpgradeRecommendedAction } from '../appliance-genealogy-view/appliance-genealogy-chart/appliance-genealogy-chart.component';

type AssetTableEntity = 'subscription' | 'license' | 'sustainability' | 'assetSustainability';

const CLASS_NAME_EXPIRED = 'expired';
const KNOWLEDGEBASE_SAFEMODE_HELP_LINK =
    'https://support.purestorage.com/bundle/m_flasharray_security/page/FlashArray/FlashArray_Security/FlashArray_Security_Guides_and_Manuals/topics/task/t_safemode_snapshots_and_ransomware.html';

export const UNKNOWN_COMPONENT_DEFAULT_DISPLAY = 'Hardware Component';

enum CapacityType {
    EUC = 'euc',
    TOTAL = 'total',
}

/*
 * We create a new file to handle this because there are 5 billion columns
 * It also needs to be a component so we can use templating
 */
@Component({
    selector: 'asset-management-table-columns',
    templateUrl: 'asset-management-table-columns.component.html',
})
export class AssetManagementTableColumnsComponent implements OnChanges {
    @Input() readonly isPartner: boolean;
    @Input() readonly isSafeModeFeatureFlagEnabled: boolean;

    @Output() readonly columnsInit = new EventEmitter<SelectableColumnGroup<SubscriptionAsset>>();

    // Column headers
    @ViewChild('initialSubscriptionHeader', { static: true }) readonly initialSubscriptionHeader: TemplateRef<any>;
    @ViewChild('currentSubscriptionHeader', { static: true }) readonly currentSubscriptionHeader: TemplateRef<any>;
    @ViewChild('initialSubscriptionStartDateHeader', { static: true })
    readonly initialSubscriptionStartDateHeader: TemplateRef<any>;
    @ViewChild('subscriptionLastUpdatedHeader', { static: true })
    readonly subscriptionLastUpdatedHeader: TemplateRef<any>;
    @ViewChild('subscriptionTermHeader', { static: true }) readonly subscriptionTermHeader: TemplateRef<any>;
    @ViewChild('licenseActivationDateHeader', { static: true }) readonly licenseActivationDateHeader: TemplateRef<any>;
    @ViewChild('licenseUsageHeader', { static: true }) readonly licenseUsageHeader: TemplateRef<any>;
    @ViewChild('licenseUtilizationHeader', { static: true }) readonly licenseUtilizationHeader: TemplateRef<any>;
    @ViewChild('licenseAverageOnDemandHeader', { static: true })
    readonly licenseAverageOnDemandHeader: TemplateRef<any>;
    @ViewChild('licensePreRatioHeader', { static: true }) readonly licensePreRatioHeader: TemplateRef<any>;
    @ViewChild('licenseTotalOnDemandHeader', { static: true }) readonly licenseTotalOnDemandHeader: TemplateRef<any>;
    @ViewChild('licenseEnergyUsageHeader', { static: true }) readonly licenseEnergyUsageHeader: TemplateRef<any>;
    @ViewChild('effectiveUsageCapacityHeader', { static: true })
    readonly effectiveUsageCapacityHeader: TemplateRef<any>;
    @ViewChild('totalUsedCapacityHeader', { static: true }) readonly totalUsedCapacityHeader: TemplateRef<any>;
    @ViewChild('applianceEnergyUsageHeader', { static: true }) readonly applianceEnergyUsageHeader: TemplateRef<any>;
    @ViewChild('emuUpgradeEligibilityDateHeader', { static: true })
    readonly emuUpgradeEligibilityDateHeader: TemplateRef<any>;
    @ViewChild('fvrCommitmentHeader', { static: true }) readonly fvrCommitmentHeader: TemplateRef<any>;
    @ViewChild('emuActionNeededHeader', { static: true }) readonly emuActionNeededHeader: TemplateRef<any>;

    // Subscription value templates
    @ViewChild('currentSubscription', { static: true }) readonly currentSubscription: TemplateRef<any>;
    @ViewChild('startDate', { static: true }) readonly startDate: TemplateRef<any>;
    @ViewChild('renewalDate', { static: true }) readonly renewalDate: TemplateRef<any>;
    @ViewChild('subscriptionLastUpdated', { static: true }) readonly subscriptionLastUpdated: TemplateRef<any>;
    @ViewChild('subscriptionDaysToRenew', { static: true }) readonly subscriptionDaysToRenew: TemplateRef<any>;
    @ViewChild('subscriptionType', { static: true }) readonly subscriptionType: TemplateRef<any>;

    // License value templates
    @ViewChild('licenseActivationDate', { static: true }) readonly licenseActivationDate: TemplateRef<any>;
    @ViewChild('licenseEndDate', { static: true }) readonly licenseEndDate: TemplateRef<any>;
    @ViewChild('licenseLastUpdated', { static: true }) readonly licenseLastUpdated: TemplateRef<any>;
    @ViewChild('licenseReservedAmount', { static: true }) readonly licenseReservedAmount: TemplateRef<any>;
    @ViewChild('licenseUsage', { static: true }) readonly licenseUsage: TemplateRef<any>;
    @ViewChild('licenseUtilization', { static: true }) readonly licenseUtilization: TemplateRef<any>;
    @ViewChild('licenseAverageOnDemand', { static: true }) readonly licenseAverageOnDemand: TemplateRef<any>;
    @ViewChild('licensePreRatio', { static: true }) readonly licensePreRatio: TemplateRef<any>;
    @ViewChild('licenseTotalOnDemand', { static: true }) readonly licenseTotalOnDemand: TemplateRef<any>;
    @ViewChild('licensePowerUsage', { static: true }) readonly licensePowerUsage: TemplateRef<any>;

    // Appliance value templates
    @ViewChild('applianceActivationDate', { static: true }) readonly applianceActivationDate: TemplateRef<any>;
    @ViewChild('applianceEolDate', { static: true }) readonly applianceEolDate: TemplateRef<any>;
    @ViewChild('applianceTotalUsable', { static: true }) readonly applianceTotalUsable: TemplateRef<any>;
    @ViewChild('effectiveUsageCapacity', { static: true }) readonly effectiveUsageCapacity: TemplateRef<any>;
    @ViewChild('safeModeStatusIcon', { static: true }) readonly safeModeStatusIcon: TemplateRef<any>;
    @ViewChild('totalUsedCapacity', { static: true }) readonly totalUsedCapacity: TemplateRef<any>;
    @ViewChild('applianceUtilization', { static: true }) readonly applianceUtilization: TemplateRef<any>;
    @ViewChild('applianceDataReduction', { static: true }) readonly applianceDataReduction: TemplateRef<any>;
    @ViewChild('applianceEnergyUsage', { static: true }) readonly applianceEnergyUsage: TemplateRef<any>;
    @ViewChild('applianceTags', { static: true }) readonly applianceTags: TemplateRef<any>;

    // Quote value templates
    @ViewChild('quoteId', { static: true }) readonly quoteId: TemplateRef<any>;
    @ViewChild('quoteStatus', { static: true }) readonly quoteStatus: TemplateRef<any>;
    @ViewChild('quoteExpirationDate', { static: true }) readonly quoteExpirationDate: TemplateRef<any>;

    readonly ProgramType = ProgramType;
    readonly CapacityType = CapacityType;
    readonly SafeModeStatusEnum = SafeModeStatusEnum;
    readonly TODAY = moment.utc();
    readonly entityToFilterFn = new Map<AssetTableEntity, (asset: SubscriptionAsset) => string>([
        ['subscription', this.getSubscriptionFilter],
        ['license', this.getLicenseFilter],
        ['sustainability', this.getSustainabilityFilter],
        ['assetSustainability', this.getSustainabilityFilterWithAssetName],
    ]);

    readonly KNOWLEDGEBASE_SAFEMODE_HELP_LINK = KNOWLEDGEBASE_SAFEMODE_HELP_LINK;

    private isEverModernEnabled = false;

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_EVER_MODERN_EVENT)
            .pipe(take(1))
            .subscribe(feature => {
                this.isEverModernEnabled = feature?.enabled === true;
                // emit the columns again after getting the flag
                this.columnsInit.emit(this.getColumns());
            });
    }

    ngOnChanges(): void {
        this.columnsInit.emit(this.getColumns());
    }

    getUiParams(asset: SubscriptionAsset, entities: AssetTableEntity[]): Params {
        const filterString = entities
            .map(entity => this.entityToFilterFn.get(entity)(asset))
            .reduce((prev, curr) => (prev += curr));
        return { filter: filterString };
    }

    getDaysToRenewClass(asset: SubscriptionAsset): string {
        if (asset.subscription.endDate?.diff(moment.utc(), 'days') <= 0) {
            return CLASS_NAME_EXPIRED;
        } else {
            return null;
        }
    }

    getCapacityData(asset: SubscriptionAsset, programType: ProgramType, capacityType: CapacityType): number {
        const isEverForeverOrFoundation =
            programType === ProgramType.EVERGREEN_FOREVER || programType === ProgramType.EVERGREEN_FOUNDATION;
        const shouldShowUsage =
            (isEverForeverOrFoundation && capacityType === CapacityType.TOTAL) ||
            (!isEverForeverOrFoundation && capacityType === CapacityType.EUC);

        //If it is evergreen forever& foundation asset, we will show on the Total Used Capacity column.
        //Otherwise show on the EUC column
        if (shouldShowUsage) {
            return asset.currentUsage;
        }
        return null;
    }

    shouldWarnAboutEol(asset: SubscriptionAsset): boolean {
        // Show earliest EoL date in red if it is within 18 months. 18 * 30 = 540 days
        return asset.eolHardware?.earliestEolDate?.diff(moment.utc(), 'days') <= 540;
    }

    getEolTooltipHtml(asset: SubscriptionAsset): string {
        let tableRows = '';
        for (const eolComponent of asset.eolHardware.eolComponents) {
            tableRows += `
                    <tr>
                        <td class="text-left">${eolComponent.label || UNKNOWN_COMPONENT_DEFAULT_DISPLAY}</td>
                        <td class="text-right">${eolComponent.eolDate.format('yyyy-MM-DD')}</td>
                    </tr>`;
        }
        return `<div class="header">
                    <span class="text-left">${asset.name}</span>
                    <span class="text-right">${asset.subscription?.name}</span>
                </div>
                <hr>
                <div class="sub-header">
                    <span class="text-left">End of Life Schedule</span>
                </div>
                <table>
                    ${tableRows}
                </table>`;
    }

    private getSubscriptionFilter(asset: SubscriptionAsset): string {
        const subscriptionFilter = {
            entity: SUBSCRIPTION_ENTITY,
            key: NAME_KEY,
            value: asset.subscription.name,
        };
        return JSON.stringify([subscriptionFilter]);
    }

    private getLicenseFilter(asset: SubscriptionAsset): string {
        const licenseFilter = {
            entity: LICENSE_ENTITY,
            key: NAME_KEY,
            value: asset.license.name,
        };
        return JSON.stringify([licenseFilter]);
    }

    private getSustainabilityFilter(asset: SubscriptionAsset): string {
        const subscriptionFilter = {
            entity: 'sustainability',
            key: 'subscription',
            value: asset.subscription.name,
        };
        const licenseFilter = {
            entity: 'sustainability',
            key: 'license',
            value: asset.license.name,
        };

        return JSON.stringify([subscriptionFilter, licenseFilter]);
    }

    private getSustainabilityFilterWithAssetName(asset: SubscriptionAsset): string {
        return JSON.stringify([
            {
                entity: 'arrays',
                key: 'array_name',
                value: asset.name,
            },
        ]);
    }

    private getColumns(): SelectableColumnGroup<SubscriptionAsset> {
        const SUBSCRIPTION_COLUMNS: SelectableColumn<SubscriptionAsset>[] = [
            {
                name: 'Current Subscription',
                column: {
                    titleTemplate: this.currentSubscriptionHeader,
                    valueTemplate: this.currentSubscription,
                    getSortKey: () => 'subscription.name',
                    weight: 5,
                    sticky: true,
                },
                selected: true,
                readonly: true,
            },
            {
                name: 'Initial Subscription',
                column: {
                    titleTemplate: this.initialSubscriptionHeader,
                    getValue: (asset: SubscriptionAsset): string => asset.subscription.initialName,
                    getSortKey: () => 'subscription.initial_subscription_name',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Organization Name',
                column: {
                    title: 'Organization Name',
                    getValue: (asset: SubscriptionAsset): string => asset.subscription.orgName,
                    getSortKey: () => 'subscription.org_name',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Partner Name',
                column: {
                    title: 'Partner Name',
                    getValue: (asset: SubscriptionAsset): string => asset.subscription.partnerAccountName,
                    getSortKey: () => 'subscription.partner_account_name',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Initial Subscription Start Date',
                column: {
                    titleTemplate: this.initialSubscriptionStartDateHeader,
                    valueTemplate: this.startDate,
                    getSortKey: () => 'subscription.start_date',
                    weight: 7,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Renewal Date',
                column: {
                    title: 'Renewal Date',
                    valueTemplate: this.renewalDate,
                    getSortKey: () => 'subscription.end_date',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Last Updated',
                column: {
                    titleTemplate: this.subscriptionLastUpdatedHeader,
                    valueTemplate: this.subscriptionLastUpdated,
                    getSortKey: () => 'subscription.last_updated',
                    weight: 5,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Days to Renew',
                column: {
                    title: 'Days to Renew',
                    valueTemplate: this.subscriptionDaysToRenew,
                    getSortKey: () => 'subscription.end_date',
                    getClass: asset => this.getDaysToRenewClass(asset),
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Subscription Type',
                column: {
                    title: 'Subscription Type',
                    valueTemplate: this.subscriptionType,
                    getSortKey: () => 'subscription.program_type',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Term (Months)',
                column: {
                    titleTemplate: this.subscriptionTermHeader,
                    getValue: (asset: SubscriptionAsset): string => String(asset.subscription.term),
                    getSortKey: () => 'subscription.subscription_term',
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Subscription Status',
                column: {
                    title: 'Subscription Status',
                    getValue: (asset: SubscriptionAsset): string => asset.subscription.status,
                    getSortKey: () => 'subscription.status',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
        ];

        const LICENSE_COLUMNS: SelectableColumn<SubscriptionAsset>[] = [
            {
                name: 'License Name',
                column: {
                    title: 'License Name',
                    getValue: (asset: SubscriptionAsset): string => asset.license.name,
                    getSortKey: () => 'license.name',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'City (License)',
                column: {
                    title: 'City (License)',
                    getValue: (asset: SubscriptionAsset): string => asset.license.siteAddress.city,
                    getSortKey: () => 'license.site_address.city',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Country (License)',
                column: {
                    title: 'Country (License)',
                    getValue: (asset: SubscriptionAsset): string => asset.license.siteAddress.country,
                    getSortKey: () => 'license.site_address.country',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Activation Date (License)',
                column: {
                    titleTemplate: this.licenseActivationDateHeader,
                    valueTemplate: this.licenseActivationDate,
                    getSortKey: () => 'license.activation_date',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'License End Date',
                column: {
                    title: 'License End Date',
                    valueTemplate: this.licenseEndDate,
                    getSortKey: () => 'license.end_date',
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Last Updated (License)',
                column: {
                    title: 'Last Updated (License)',
                    valueTemplate: this.licenseLastUpdated,
                    getSortKey: () => 'license.last_updated',
                    weight: 5,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'License Type',
                column: {
                    title: 'License Type',
                    getValue: (asset: SubscriptionAsset): string => asset.license.licenseType,
                    getSortKey: () => 'license.license_type',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Reserve Capacity',
                column: {
                    title: 'Reserve Capacity',
                    valueTemplate: this.licenseReservedAmount,
                    getSortKey: () => 'license.reserved_amount',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'License Usage',
                column: {
                    titleTemplate: this.licenseUsageHeader,
                    valueTemplate: this.licenseUsage,
                    getSortKey: () => 'license.usage.current',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'License Utilization',
                column: {
                    titleTemplate: this.licenseUtilizationHeader,
                    valueTemplate: this.licenseUtilization,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'QTD Average On-Demand Usage',
                column: {
                    titleTemplate: this.licenseAverageOnDemandHeader,
                    valueTemplate: this.licenseAverageOnDemand,
                    getSortKey: () => 'license.usage.average_on_demand',
                    weight: 7,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'License PRE Ratio',
                column: {
                    titleTemplate: this.licensePreRatioHeader,
                    valueTemplate: this.licensePreRatio,
                    getSortKey: () => 'license.pre_ratio',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'QTD Total On-Demand Usage',
                column: {
                    titleTemplate: this.licenseTotalOnDemandHeader,
                    valueTemplate: this.licenseTotalOnDemand,
                    getSortKey: () => 'license.usage.total_on_demand',
                    weight: 7,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Energy Usage',
                column: {
                    titleTemplate: this.licenseEnergyUsageHeader,
                    valueTemplate: this.licensePowerUsage,
                    getSortKey: () => 'license.power_usage',
                },
                selected: false,
                readonly: false,
            },
        ];

        const APPLIANCE_COLUMNS: SelectableColumn<SubscriptionAsset>[] = [
            {
                name: 'Appliance Name',
                column: {
                    title: 'Appliance Name',
                    getValue: (asset: SubscriptionAsset): string => asset.name,
                    getSortKey: () => 'name',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Install Location',
                column: {
                    title: 'Install Location (City)',
                    getValue: (asset: SubscriptionAsset): string => asset.installAddress.city,
                    getSortKey: () => 'install_address.city',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Activation Date (Appliance)',
                column: {
                    title: 'Activation Date (Appliance)',
                    valueTemplate: this.applianceActivationDate,
                    getSortKey: () => 'most_recent_activation_start_date',
                    weight: 6,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'EOL Date',
                column: {
                    title: 'EOL Date',
                    valueTemplate: this.applianceEolDate,
                    getSortKey: () => 'eol_timestamp',
                    weight: 6,
                },
                selected: false,
                readonly: false,
            },
            ...[].concat(
                this.isEverModernEnabled
                    ? [
                          {
                              name: 'Ever Modern Upgrades',
                              column: {
                                  title: 'Ever Modern Upgrades',
                                  weight: 21,
                                  subColumns: [
                                      {
                                          name: 'Upgrade Eligibility Date',
                                          column: {
                                              titleTemplate: this.emuUpgradeEligibilityDateHeader,
                                              getValue: (asset: SubscriptionAsset): string =>
                                                  asset.everModernUpgrade?.assetAgeTargetDate?.format('YYYY-MM-DD'),
                                              getSortKey: () => 'ever_modern_upgrade.asset_age_target_date',
                                              weight: 5,
                                          },
                                      },
                                      {
                                          name: 'FVR Commitment Date',
                                          column: {
                                              titleTemplate: this.fvrCommitmentHeader,
                                              getValue: (asset: SubscriptionAsset): string =>
                                                  asset.everModernUpgrade?.nextEvergreenDate?.format('YYYY-MM-DD'),
                                              getSortKey: () => 'ever_modern_upgrade.next_evergreen_date',
                                              weight: 5,
                                          },
                                      },
                                      {
                                          name: 'Action Needed',
                                          column: {
                                              titleTemplate: this.emuActionNeededHeader,
                                              getValue: (asset: SubscriptionAsset): string =>
                                                  getEverModernUpgradeRecommendedAction(asset.everModernUpgrade),
                                              weight: 10,
                                          },
                                      },
                                  ],
                              },
                              selected: false,
                              readonly: false,
                          },
                      ]
                    : [],
            ),
            {
                name: 'Purity Version',
                column: {
                    title: 'Purity Version',
                    getValue: (asset: SubscriptionAsset): string => asset.purityVersion,
                    getSortKey: () => 'purity_version',
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Model',
                column: {
                    title: 'Model',
                    getValue: (asset: SubscriptionAsset): string => asset.model,
                    getSortKey: () => 'model',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Chassis SN',
                column: {
                    title: 'Chassis SN',
                    getValue: (asset: SubscriptionAsset): string => asset.chassisSerial,
                    getSortKey: () => 'chassis_serial',
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Effective Used Capacity (EUC)',
                column: {
                    titleTemplate: this.effectiveUsageCapacityHeader,
                    valueTemplate: this.effectiveUsageCapacity,
                    getSortKey: () => 'current_usage',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            ...[].concat(
                this.isSafeModeFeatureFlagEnabled
                    ? [
                          {
                              name: 'SafeMode Status',
                              column: {
                                  title: 'SafeMode Status',
                                  valueTemplate: this.safeModeStatusIcon,
                              },
                              selected: true,
                              readonly: false,
                          },
                      ]
                    : [],
            ),
            {
                name: 'Total Used Capacity',
                column: {
                    titleTemplate: this.totalUsedCapacityHeader,
                    valueTemplate: this.totalUsedCapacity,
                    getSortKey: () => 'current_usage',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Appliance Utilization',
                column: {
                    title: 'Appliance Utilization',
                    valueTemplate: this.applianceUtilization,
                    getSortKey: () => 'percent_full',
                    weight: 5,
                },
                selected: true,
                readonly: false,
            },
            {
                name: 'Appliance Total Usable',
                column: {
                    title: 'Appliance Total Usable',
                    valueTemplate: this.applianceTotalUsable,
                    getSortKey: () => 'capacity',
                    weight: 5,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Appliance DRR',
                column: {
                    title: 'Appliance DRR',
                    valueTemplate: this.applianceDataReduction,
                    getSortKey: () => 'data_reduction',
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Appliance Energy Usage',
                column: {
                    titleTemplate: this.applianceEnergyUsageHeader,
                    valueTemplate: this.applianceEnergyUsage,
                    getSortKey: () => 'last_7_days_power',
                    weight: 7,
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Tags',
                column: {
                    title: 'Tags',
                    valueTemplate: this.applianceTags,
                    weight: 16,
                },
                selected: false,
                readonly: false,
            },
        ];

        const QUOTE_COLUMNS: SelectableColumn<SubscriptionAsset>[] = [
            {
                name: 'Quote ID',
                column: {
                    title: 'Quote ID',
                    valueTemplate: this.quoteId,
                    getSortKey: () => 'quote.id',
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Quote Status',
                column: {
                    title: 'Quote Status',
                    valueTemplate: this.quoteStatus,
                    getSortKey: () => 'quote.status',
                },
                selected: false,
                readonly: false,
            },
            {
                name: 'Quote Expiration Date',
                column: {
                    title: 'Quote Expiration Date',
                    valueTemplate: this.quoteExpirationDate,
                    getSortKey: () => 'quote.expiration_date',
                },
                selected: false,
                readonly: false,
            },
        ];

        SUBSCRIPTION_COLUMNS.concat(...LICENSE_COLUMNS, ...APPLIANCE_COLUMNS, ...QUOTE_COLUMNS).forEach(column => {
            if (!column.column.weight) {
                column.column.weight = 4;
            }
        });

        const defaultColumns = {
            subscription: SUBSCRIPTION_COLUMNS,
            license: LICENSE_COLUMNS,
            appliance: APPLIANCE_COLUMNS,
        };

        if (this.isPartner) {
            return {
                ...defaultColumns,
                quote: QUOTE_COLUMNS,
            };
        }

        return defaultColumns;
    }
}
