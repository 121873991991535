import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { FeatureFlagDxpService, FilterParams, IArrayAddress, IGeolocation, UnifiedArray } from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { GOOGLE_MAPS, MARKER_CLUSTERER } from '../../../app/injection-tokens';
import { FeatureNames } from '../../../model/FeatureNames';
import { GLOBAL_FILTER, LOCAL_BAR_ARRAYS, LOCAL_BAR_MAP_VIEW, TAGS_KEY } from '../../../redux/actions';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState, IStateFilter } from '../../../redux/pure-redux.service';
import { UrlReaderWriter } from '../../../redux/url-reader-writer';
import { UrlService } from '../../../redux/url.service';
import { ArraysManagerUnifiedArrayService } from '../../../services/arrays-manager-unified-array.service';
import { GoogleMapsService } from '../../../services/google-maps.service';
import { MapModalService } from '../map-modal/map-modal.service';
import { MAP_STYLE } from './google-maps-styling';

interface Location {
    markers: google.maps.Marker[];
    arrayLists: PlottedArray[][];
}

interface Point {
    x: number;
    y: number;
}

// Used for Object.create result. Address is technically a getter function
export interface PlottedArray extends UnifiedArray {
    readonly address: IArrayAddress;
}

type GoogleMaps = typeof google.maps;

// Arbitrary padding to make the markers not too close to the drawer after opening
const PADDING = 50;
// Arbitrary zoom level. Allows user to get close without zooming in WAY too far.
// Also allows users to get a full world view without zooming out WAY too far.
// Useful when they're filtering out a specific array and don't want to see just the building
const MAX_ZOOM_LEVEL = 19;
const MIN_ZOOM_LEVEL = 3;
// Past 4 digits, it doesn't matter much if the coordinates are different (about <10 meters)
const COORDINATE_SIG_FIG = 4;

// Markers and Clustering-related constants
// Many of these are CSS related because Google Maps doesn't let us put a class in the marker/cluster. Instead,
// we need to put them directly in the object and Maps will do the styling for us
const DEFAULT_MARKER_ICON_PATH = '/images/map-marker-default.svg';
const SELECTED_MARKER_ICON_PATH = '/images/map-marker-selected.svg';
const MARKER_HEIGHT = 52;
const MARKER_WIDTH = 40;
const MARKER_FONT_SIZE = 15;
const MARKER_FONT_WEIGHT = '400';
const MARKER_LABEL_OFFSET = 20;
const MARKER_LABEL_OFFSET_FROM_CENTER = 6;
const DEFAULT_STYLE_INDEX = 1;
const SELECTED_STYLE_INDEX = 2;
const CLUSTER_STYLE: ClusterIconStyle[] = [
    {
        anchorIcon: [MARKER_HEIGHT, MARKER_WIDTH / 2], // Which part of the 40x52 marker corresponds to the location
        anchorText: [-MARKER_LABEL_OFFSET_FROM_CENTER, 0], // Where to put the label text
        fontWeight: MARKER_FONT_WEIGHT,
        height: MARKER_HEIGHT,
        textSize: MARKER_FONT_SIZE,
        url: DEFAULT_MARKER_ICON_PATH,
        width: MARKER_WIDTH,
    },
    {
        anchorIcon: [MARKER_HEIGHT, MARKER_WIDTH / 2],
        anchorText: [-MARKER_LABEL_OFFSET_FROM_CENTER, 0],
        fontWeight: MARKER_FONT_WEIGHT,
        height: MARKER_HEIGHT,
        textSize: MARKER_FONT_SIZE,
        url: SELECTED_MARKER_ICON_PATH,
        width: MARKER_WIDTH,
    },
];
const MAP_DISABLED_MESSAGE =
    'This feature is blocked due to Google maps restrictions in certain territories, please contact your administrator for details.';

@Component({
    selector: 'arrays-map-view',
    templateUrl: 'arrays-map-view.component.html',
})
export class ArraysMapViewComponent implements AfterViewInit, OnDestroy {
    @ViewChild('mapElement', { static: true }) mapElement: ElementRef;
    @ViewChild('topSection', { static: true }) topSectionElement: ElementRef;
    @ViewChild('drawer', { read: ElementRef, static: true }) drawerElement: ElementRef;

    searchElement: HTMLInputElement;
    arrays: PlottedArray[] = [];
    barId = LOCAL_BAR_ARRAYS;
    clusterer: MarkerClusterer;
    drawerOpened = false;
    markers: google.maps.Marker[] = [];
    map: google.maps.Map;
    search: google.maps.places.Autocomplete;
    googleMaps: GoogleMaps;
    mapLoadingStatus: 'loading' | 'loaded' | 'err' = 'loading';

    selectedLocation: Location = {
        markers: [],
        arrayLists: [],
    };

    private defaultMarkerIcon: google.maps.Icon;
    private selectedMarkerIcon: google.maps.Icon;

    // Redux
    private unsubscribeFromRedux: Function;
    private unsubscribeFromUrlService: Function;

    // Other listeners/subscriptions
    private addressSubscription: Subscription;
    private mapListeners: google.maps.MapsEventListener[] = [];

    // Analytics
    private analyticsPrefix = 'Map View - ';
    private analyticsStep = 'Workflow Step';

    constructor(
        private angulartics2: Angulartics2,
        private featureFlagDxpService: FeatureFlagDxpService,
        private googleMapsService: GoogleMapsService,
        private mapModalService: MapModalService,
        private renderer: Renderer2,
        private unifiedArrayService: ArraysManagerUnifiedArrayService,
        private url: UrlService,
        @Inject(DOCUMENT) private doc: Document,
        @Inject(GOOGLE_MAPS) private googleMapsPromise: Promise<GoogleMaps>,
        @Inject(MARKER_CLUSTERER) private markerClusterer: typeof MarkerClusterer, // This is the markerclusterer.js file we're injecting
        private ngRedux: NgRedux<IState>,
    ) {}

    ngAfterViewInit(): void {
        //TODO: figure out how to pass this in as input
        this.searchElement = this.doc.body.querySelector('#map-search') as HTMLInputElement;

        this.featureFlagDxpService.getFeatureFlag(FeatureNames.ARRAY_ADDRESS).subscribe(feature => {
            if (feature && feature?.enabled === false) {
                // Don't load the page and show modal
                this.mapModalService.open(MAP_DISABLED_MESSAGE);
            } else {
                this.googleMapsPromise
                    .then(map => {
                        this.mapLoadingStatus = 'loaded';
                        this.googleMaps = map;
                        this.initializeMap();
                        this.initializeSearch();
                        this.clearSelectedLocation();
                        this.unsubscribeFromUrlService = this.url.register(new ContactsUrlReaderWriter(), true);
                        this.unsubscribeFromRedux = this.ngRedux.subscribe(() => this.handleRedux());
                        this.handleRedux();
                    })
                    .catch(() => (this.mapLoadingStatus = 'err'));
            }
        });
    }

    ngOnDestroy(): void {
        if (!this.googleMaps) {
            return;
        }

        if (this.addressSubscription && !this.addressSubscription.closed) {
            this.addressSubscription.unsubscribe();
        }

        // Clean up Markers
        this.googleMaps.event.clearInstanceListeners(this.clusterer);
        this.removeMarkers();

        // Clean up all Autocomplete
        this.googleMaps.event.clearInstanceListeners(this.search);
        this.map.controls[this.googleMaps.ControlPosition.TOP_RIGHT].pop();
        const autocompleteDropdowns = Array.from(this.doc.body.querySelectorAll('.pac-container'));
        autocompleteDropdowns.forEach(dropdown => {
            dropdown.remove();
        });

        // Clean up map
        const mapDiv = this.map.getDiv();
        (this.mapElement.nativeElement as HTMLElement).removeChild(mapDiv);
        this.mapListeners.forEach(listener => {
            this.googleMaps.event.removeListener(listener);
        });
        this.googleMapsService.releaseMap(this.map);

        if (this.unsubscribeFromRedux) {
            this.unsubscribeFromRedux();
        }
        if (this.unsubscribeFromUrlService) {
            this.unsubscribeFromUrlService();
        }
    }

    closeDrawer(): void {
        this.resetSelectedMarker();
        this.drawerOpened = false;
    }

    onLocationChange(changedArrays: PlottedArray[]): void {
        const newAddress = changedArrays[0].install_address_requested;
        if (!newAddress.geolocation) {
            return;
        }

        this.populateMap(this.arrays, newAddress.geolocation);
    }

    private initializeSearch(): void {
        this.search = new this.googleMaps.places.Autocomplete(this.searchElement, {
            types: ['(regions)'],
        });
        this.map.controls[this.googleMaps.ControlPosition.TOP_RIGHT].push(this.topSectionElement.nativeElement);
        this.search.addListener('place_changed', () => {
            const geometry = this.search.getPlace().geometry;
            if (geometry) {
                // We only know the API call (session) was completed if a geometry value was returned.
                // this.search.getPlace() will return a value if the user hits the enter key,
                // but only selecting from the dropdown will be considered a completion of the autocomplete session
                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + 'Google API calls',
                    properties: {
                        category: 'Action',
                        label: 'place searched',
                    },
                });

                if (geometry.viewport) {
                    this.map.fitBounds(geometry.viewport);
                } else {
                    this.map.panTo(geometry.location);
                }

                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + 'Region select',
                    properties: {
                        category: 'Action',
                        label: 'Selected a region using the search bar dropdown',
                    },
                });
            } else {
                this.searchElement.placeholder = 'Enter a location';
            }
        });
        const mapListener = this.googleMaps.event.addListener(this.map, 'bounds_changed', () => {
            this.search.setBounds(this.map.getBounds());
        });

        this.mapListeners.push(mapListener);
    }

    private initializeMap(): void {
        const mapOptions: google.maps.MapOptions = {
            clickableIcons: false,
            fullscreenControl: false,
            mapTypeControl: false,
            maxZoom: MAX_ZOOM_LEVEL,
            minZoom: MIN_ZOOM_LEVEL,
            streetViewControl: false,
            styles: MAP_STYLE,
        };

        this.defaultMarkerIcon = {
            labelOrigin: new this.googleMaps.Point(MARKER_LABEL_OFFSET, MARKER_LABEL_OFFSET),
            scaledSize: new this.googleMaps.Size(MARKER_WIDTH, MARKER_HEIGHT),
            url: DEFAULT_MARKER_ICON_PATH,
        };
        this.selectedMarkerIcon = {
            labelOrigin: new this.googleMaps.Point(MARKER_LABEL_OFFSET, MARKER_LABEL_OFFSET),
            scaledSize: new this.googleMaps.Size(MARKER_WIDTH, MARKER_HEIGHT),
            url: SELECTED_MARKER_ICON_PATH,
        };

        this.map = this.googleMapsService.getMap(mapOptions);
        this.map.getDiv().id = 'map';
        (this.mapElement.nativeElement as HTMLElement).appendChild(this.map.getDiv());
        const options: MarkerClustererOptions = {
            calculator: (markers: google.maps.Marker[], count: number): ClusterIconInfo => {
                let arrayCount = 0;
                let styleIndex = DEFAULT_STYLE_INDEX;
                markers.forEach(marker => {
                    arrayCount += parseInt(marker.getLabel() as any as string, 10);
                });
                if (markers.every(marker => this.selectedLocation.markers.includes(marker))) {
                    styleIndex = SELECTED_STYLE_INDEX;
                }
                return {
                    text: String(arrayCount),
                    index: styleIndex, // Which index+1 of styles array to use (defined below). It's index+1 b/c of their implementation
                    title: '',
                };
            },
            gridSize: MARKER_HEIGHT, // Pixel size of grid in which to calculate a cluster. Use the larger between height and width
            styles: CLUSTER_STYLE,
            zoomOnClick: false,
        };
        this.clusterer = new this.markerClusterer(this.map, [], options);
    }

    private centerMap(markers: google.maps.Marker[]): void {
        let bounds = new this.googleMaps.LatLngBounds();
        if (markers.length === 0) {
            // Coordinates perfectly handcrafted with the finest techniques to fit most of the world
            bounds = new this.googleMaps.LatLngBounds(
                new google.maps.LatLng(75, -155),
                new google.maps.LatLng(-60, 180),
            );
        }
        markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });
        this.map.fitBounds(bounds);
    }

    private updateMarkers(geolocations: Map<string, PlottedArray[]>, defaultSelection?: IGeolocation): void {
        this.removeMarkers();
        Array.from(geolocations.values()).forEach(arrayList => {
            const geolocation = arrayList[0].address.geolocation;
            const marker = new this.googleMaps.Marker({
                position: {
                    lat: geolocation.latitude,
                    lng: geolocation.longitude,
                },
                icon: this.defaultMarkerIcon,
                label: String(arrayList.length),
            });
            this.markers.push(marker);
            marker.addListener('click', () => {
                this.clearSelectedLocation();
                this.selectedLocation = {
                    markers: [marker],
                    arrayLists: [arrayList],
                };
                marker.setIcon(this.selectedMarkerIcon);
                this.shiftMapIfDrawerOverlaps(marker.getPosition());
                this.drawerOpened = true;

                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + 'Marker clicked',
                    properties: {
                        category: 'Action',
                        label: '1',
                    },
                });
                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + this.analyticsStep,
                    properties: {
                        category: 'Action',
                        label: 'Marker clicked',
                    },
                });
            });

            if (defaultSelection && this.geolocationToKey(defaultSelection) === this.geolocationToKey(geolocation)) {
                this.selectedLocation = {
                    markers: [marker],
                    arrayLists: [arrayList],
                };
                marker.setIcon(this.selectedMarkerIcon);
            }
        });

        // Add click listener for clusters
        this.clusterer.addListener('clusterclick', (cluster: Cluster) => {
            this.clearSelectedLocation();
            const markers = cluster.getMarkers();
            this.selectedLocation = {
                markers: markers,
                arrayLists: markers.map(marker => geolocations.get(this.geolocationToKey(marker.getPosition()))),
            };
            markers.forEach(marker => {
                marker.setIcon(this.selectedMarkerIcon);
            });
            cluster.updateIcon_();
            this.shiftMapIfDrawerOverlaps(cluster.getCenter());
            this.drawerOpened = true;

            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Marker clicked',
                properties: {
                    category: 'Action',
                    label: `${markers.length}`,
                },
            });
            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + this.analyticsStep,
                properties: {
                    category: 'Action',
                    label: 'Marker clicked',
                },
            });
        });
    }

    private shiftMapIfDrawerOverlaps(position: google.maps.LatLng): void {
        const pixelOffsetFromMap = this.positionToPixelLocation(position);
        const pixelOffsetFromView =
            pixelOffsetFromMap.x + (this.mapElement.nativeElement as HTMLDivElement).getBoundingClientRect().left;
        const drawerWidth = (this.drawerElement.nativeElement as HTMLDivElement).getBoundingClientRect().width;
        if (pixelOffsetFromView < drawerWidth + PADDING) {
            this.map.panBy(pixelOffsetFromView - (drawerWidth + PADDING), 0);
        }
    }

    private resetSelectedMarker(): void {
        const markers = this.selectedLocation.markers;
        this.selectedLocation.markers = [];
        if (markers) {
            markers.forEach(marker => {
                marker.setIcon(this.defaultMarkerIcon);
            });
        }
        this.clusterer.getClusters().forEach(cluster => cluster.updateIcon_());
    }

    private removeMarkers(): void {
        this.clearSelectedLocation();
        if (this.clusterer) {
            this.clusterer.clearMarkers();
            this.googleMaps.event.clearInstanceListeners(this.clusterer);
        }
        this.markers.forEach(marker => {
            marker.setMap(null);
            this.googleMaps.event.clearInstanceListeners(marker);
        });
        this.selectedLocation.markers = [];
        this.markers = [];
    }

    private geolocationToKey(geolocation: IGeolocation | google.maps.LatLng): string {
        let lat, lng: number;
        const geo = geolocation as IGeolocation;
        if (geo.latitude) {
            lat = geo.latitude;
            lng = geo.longitude;
        } else {
            const latlng = geolocation as google.maps.LatLng;
            lat = latlng.lat();
            lng = latlng.lng();
        }
        return `(${lat.toFixed(COORDINATE_SIG_FIG)},${lng.toFixed(COORDINATE_SIG_FIG)})`;
    }

    private clearSelectedLocation(): void {
        this.resetSelectedMarker();
        this.selectedLocation = {
            markers: [],
            arrayLists: [],
        };
    }

    // Pixel location based on distance from SW bound
    private positionToPixelLocation(position: google.maps.LatLng): Point {
        const scale = 2 ** this.map.getZoom();
        // LatLng is on a cylindrical surface while Point is on a flat plane
        const swPoint = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        const markerPoint = this.map.getProjection().fromLatLngToPoint(position);
        return {
            x: (markerPoint.x - swPoint.x) * scale,
            y: (markerPoint.y - swPoint.y) * scale,
        };
    }

    private handleRedux(): void {
        if (this.addressSubscription) {
            this.addressSubscription.unsubscribe();
        }
        const listParams = {
            filter: this.getFilterParams(),
        };
        this.addressSubscription = this.unifiedArrayService
            .list(listParams)
            .pipe(
                map(result => {
                    return result.response.map(array => {
                        return Object.create(array, {
                            address: {
                                get(): IArrayAddress {
                                    if (this.install_address_requested && this.install_address_requested.geolocation) {
                                        return this.install_address_requested;
                                    }
                                    return this.install_address;
                                },
                            },
                        }) as PlottedArray;
                    });
                }),
                filter(arrays => {
                    if (arrays.length === this.arrays.length) {
                        // Refresh map if both sets of arrays are empty
                        // This makes the map load even if the filters return no arrays initially
                        if (this.arrays.length === 0) {
                            this.centerMap([]);
                        }
                        const arrayMapping = new Map<string, PlottedArray>();
                        this.arrays.forEach(array => {
                            arrayMapping.set(array.id, array);
                        });

                        return !arrays.every(newArray => {
                            const currentArray = arrayMapping.get(newArray.id);
                            if (!_.isEqual(newArray.address, currentArray.address)) {
                                // If the geolocations and street addresses are still equal, it's likely that the backend has
                                // extra fields that don't affect how the map is drawn, so we can just save the data instead.
                                if (
                                    this.geolocationToKey(newArray.address.geolocation) ===
                                        this.geolocationToKey(currentArray.address.geolocation) &&
                                    newArray.address.street_address === currentArray.address.street_address
                                ) {
                                    this.arrays[this.arrays.indexOf(currentArray)] = newArray;
                                } else {
                                    return false;
                                }
                            }
                            return true;
                        });
                    }
                    return true;
                }),
            )
            .subscribe(result => {
                this.arrays = result;
                this.clearSelectedLocation();
                this.closeDrawer();
                this.populateMap(result);
            });
    }

    private populateMap(newArrays: PlottedArray[], center?: IGeolocation): void {
        // Place arrays into buckets based on location
        const locationMap = new Map<string, PlottedArray[]>();
        newArrays.forEach(array => {
            if (
                array.address &&
                array.address.geolocation &&
                array.address.geolocation.latitude &&
                array.address.geolocation.longitude
            ) {
                const key = this.geolocationToKey(array.address.geolocation);
                const addresses = locationMap.get(key) || [];
                addresses.push(array);
                locationMap.set(key, addresses);
            } else {
                console.warn('No address found for array_id: ' + array.id);
            }
        });

        // Generate markers based on the locations, selecting the marker at the location passed in
        this.updateMarkers(locationMap, center);

        // We do this to manually trigger a screen resize. It seems like google maps has some sort of size caching
        // that makes it so they don't resize the map if the width appears to be the same (even on a manual resize trigger)
        // Pairs with the 'idle' event handler
        this.renderer.setStyle(this.map.getDiv(), 'width', `${this.map.getDiv().getBoundingClientRect().width - 1}px`);
        this.renderer.setStyle(
            this.map.getDiv(),
            'height',
            `${this.map.getDiv().getBoundingClientRect().height - 1}px`,
        );
        this.renderer.setStyle(this.map.getDiv(), 'visibility', 'hidden');

        // Center map on either provided center or the newly generated markers
        if (center) {
            const newBounds = new this.googleMaps.LatLngBounds();
            newBounds.extend({
                lat: center.latitude,
                lng: center.longitude,
            });
            this.map.fitBounds(newBounds);
        } else {
            this.centerMap(this.markers);
        }

        // Place markers onto the map using the clusterer. Save listener for cleanup
        const mapListener = this.googleMaps.event.addListenerOnce(this.map, 'idle', () => {
            // We do this to manually trigger a screen resize. It seems like google maps has some sort of size caching
            // that makes it so they don't resize the map if the width appears to be the same (even on a manual resize trigger)
            // Pairs with initializeMap()
            this.renderer.removeStyle(this.map.getDiv(), 'width');
            this.renderer.removeStyle(this.map.getDiv(), 'height');
            this.renderer.removeStyle(this.map.getDiv(), 'visibility');

            this.clusterer.addMarkers(this.markers);
        });
        this.mapListeners.push(mapListener);
    }

    private getFilterParams(): FilterParams<UnifiedArray> {
        const localFilters = this.ngRedux.getState().filters[this.barId] || [];
        const globalFilters = this.ngRedux.getState().filters[GLOBAL_FILTER] || [];
        const activeFilters = globalFilters.concat(localFilters);

        return this.filtersToIGlobalFilters(activeFilters) as FilterParams<UnifiedArray>;
        // Below is used when we eventually port over to using generic service
        // Right now, prepForDataLayer formats the filters to be ready for the url
        // But ArraysManager expects the filters to not be formatted, so we end up prepping for the url twice
        // and result in a request having: contains(contains(array_name))

        //const filters = consolidateFilterValues(activeFilters, DEFAULT_WILDCARD_FIELDS);
        //return prepForDataLayer(filters);
    }

    private filtersToIGlobalFilters(filters: IStateFilter[]): IGlobalFilters {
        const globalFilter: IGlobalFilters = {};
        const tagsMap = new Map<string, string[]>();
        filters.forEach(filter => {
            if (filter.namespace) {
                const tagValues = tagsMap.get(filter.key) || [];
                tagValues.push(filter.value);
                tagsMap.set(filter.key, tagValues);
            } else {
                globalFilter[filter.key] = globalFilter[filter.key] || [];
                globalFilter[filter.key].push(filter.value);
            }
        });
        globalFilter[TAGS_KEY] = tagsMap;
        return globalFilter;
    }
}

class ContactsUrlReaderWriter extends UrlReaderWriter {
    // path to match to execute this UrlReaderWriter
    path = /^\/overview\/mapview/;
    localBarId = LOCAL_BAR_MAP_VIEW;

    constructor() {
        super();
    }
}
