import cryptoJS from 'crypto-js';
import { Component, Output, EventEmitter, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Angulartics2 } from 'angulartics2';
import { take, switchMap, catchError } from 'rxjs/operators';
import { QuoteUrlService } from '../../../../service-catalog/services/quote-url.service';
import { SfdcPurchaseOrder, SfdcQuote, ServiceCatalogQuote, SfdcQuoteServiceV5 } from '@pure1/data';
import { throwError } from 'rxjs';

const FILE_UPLOADED_STATUS = 'Uploaded';

@Component({
    selector: 'purchase-order-upload-form',
    templateUrl: 'purchase-order-upload-form.component.html',
})
export class PurchaseOrderUploadFormComponent implements AfterViewInit {
    @Input() readonly quoteAccepted: boolean = false;
    @Input() readonly salesFlow: ServiceCatalogQuote;
    @Input() readonly quote: SfdcQuote;

    @Output() readonly cancelForm = new EventEmitter<void>();

    totalAmount: number;
    comment: string;
    newFiles: File[] = [];
    newFilesUploadDone: boolean[] = [];
    newFilesUploadStatus: string[] = [];
    uploadingComment = false;
    errorInUploadingComment = false;
    mainMessageToUser = '';
    numberOfDuplicateFiles = 0;

    readonly MAX_FILE_SIZE_IN_BYTES = 500 * 1024 * 1024; // 500MiB
    readonly ERROR_PREFIX = 'Error: ';
    readonly FILE_TOO_LARGE = 'file too large';
    readonly analyticsPrefix = 'PO-upload - ';

    private formData: FormData = new FormData(); // FormData will always track new files only

    constructor(
        private angulartics2: Angulartics2,
        private cdr: ChangeDetectorRef,
        private http: HttpClient,
        private quoteUrlService: QuoteUrlService,
        private sfdcQuoteService: SfdcQuoteServiceV5,
    ) {}

    ngAfterViewInit(): void {
        this.selectMainMessageToUser();
        this.cdr.detectChanges();
    }

    onTextChange(newText: string): void {
        this.comment = newText;
    }

    onFileChange(event: Event): void {
        // Cast to any because the Event type doesn't seem to be able to pick this value up
        const files: FileList = (event.target as HTMLInputElement).files;
        // Filelist can be 0 if the user cancelled out of the prompt
        if (files?.length > 0) {
            // Note: FileList is not iterable
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (this.formData.get(file.name) != null) {
                    this.numberOfDuplicateFiles++;
                } else {
                    this.formData.append(file.name, file, file.name);
                    // FormData cannot be iterated on, so we'll keep it in a separate list
                    this.newFiles.push(file);
                }
            }

            this.newFilesUploadStatus = new Array<string>(this.newFiles.length);
            this.newFiles.forEach((file, index) => {
                if (file.size >= this.MAX_FILE_SIZE_IN_BYTES) {
                    this.newFilesUploadStatus[index] = this.ERROR_PREFIX + this.FILE_TOO_LARGE;
                }
            });
        }
    }

    isSubmitDisabled(): boolean {
        this.selectMainMessageToUser();
        return !!this.mainMessageToUser;
    }

    deleteAttachment(file: File, index: number): void {
        this.newFiles.splice(index, 1);
        this.formData.delete(file.name);

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'clicked to remove a local file from upload list',
            properties: {
                category: 'Action',
            },
        });
    }

    cancelClicked(): void {
        // do some housekeeping resets
        this.resetInputs();
        this.cancelForm.emit();
    }

    openNewWindowForPOFileDownload(poFile: SfdcPurchaseOrder): void {
        this.quoteUrlService.openNewWindowOnDownloadUrlWith302Response(this.salesFlow.id, poFile.pure1Id, 'PO');
        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'clicked to download an existing PO file',
            properties: {
                category: 'Action',
            },
        });
    }

    resetInputs(): void {
        this.mainMessageToUser = '';
        this.numberOfDuplicateFiles = 0;
        this.errorInUploadingComment = false;
        this.newFilesUploadDone = [];
        this.newFiles = [];
        this.comment = null;
        this.formData = new FormData();
    }

    submitAttachment(): void {
        if (!this.newFiles || this.newFiles.length < 1) {
            return;
        }

        this.newFilesUploadDone = new Array<boolean>(this.newFiles.length);
        this.numberOfDuplicateFiles = 0;

        // Upload Files
        this.newFiles.forEach((file: File, index: number) => {
            if (file.size >= this.MAX_FILE_SIZE_IN_BYTES) {
                return;
            }

            const reader = new FileReader();

            reader.onloadend = this.onLoadEnd(reader, file, index);
            reader.readAsArrayBuffer(file);
        });

        // Upload optional comment
        if (this.comment) {
            const partialProperties = {
                salesFlowId: this.salesFlow.id,
                id: this.quote.id,
                partnerComment: this.comment,
            };

            this.uploadingComment = true;
            this.sfdcQuoteService
                .update(partialProperties)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.uploadingComment = false;

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + 'success uploading comment with PO',
                            properties: {
                                category: 'Action',
                            },
                        });
                    },
                    error: () => {
                        this.uploadingComment = false;
                        this.errorInUploadingComment = true;

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + 'failure uploading comment with PO',
                            properties: {
                                category: 'Action',
                            },
                        });
                    },
                });
        }

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'clicked to upload files' + this.comment ? ' with comments' : '',
            properties: {
                category: 'Action',
            },
        });
    }

    selectMainMessageToUser(): string {
        if (this.newFiles.length < 1) {
            this.mainMessageToUser = 'Please select PO files to upload by clicking "Select PO Files"';
        } else if (this.totalAmount !== this.quote.netPrice) {
            this.mainMessageToUser =
                'The value entered for the total quote amount must match the quote (numbers only).';
            if (this.numberOfDuplicateFiles > 0) {
                this.mainMessageToUser += ' Some files with duplicate names were not added.';
            }
        } else if (this.newFilesUploadDone.length > 0) {
            if (this.newFilesUploadDone.some(uploaded => uploaded !== true)) {
                this.mainMessageToUser = 'Uploading in progress…';
            } else {
                if (
                    this.newFilesUploadStatus.some(
                        uStatus => uStatus !== FILE_UPLOADED_STATUS && !uStatus.endsWith(this.FILE_TOO_LARGE),
                    )
                ) {
                    this.mainMessageToUser = 'Some uploads failed. Please close and try again';
                } else {
                    this.mainMessageToUser = 'All valid files uploaded. You may safely close.';
                    if (this.errorInUploadingComment) {
                        this.mainMessageToUser +=
                            ' Your comment was not sent properly. Please email your Pure contact directly.';
                    }
                }
            }
        } else {
            this.mainMessageToUser = '';
        }
        return this.mainMessageToUser;
    }

    onLoadEnd(reader: FileReader, file: File, index: number): (this: FileReader, ev: ProgressEvent<FileReader>) => any {
        let fileAsArrayBuffer = null;
        this.newFilesUploadDone[index] = false;
        this.newFilesUploadStatus[index] = 'Uploading...';

        return () => {
            fileAsArrayBuffer = reader.result;
            const formattedFile = cryptoJS.lib.WordArray.create(fileAsArrayBuffer);
            const computedMd5Str = cryptoJS.MD5(formattedFile).toString(cryptoJS.enc.Base64);

            // get the upload URL
            this.quoteUrlService
                .getUploadUrl(this.salesFlow.id, file.size, computedMd5Str)
                .pipe(
                    catchError(error => {
                        this.newFilesUploadDone[index] = true;
                        this.newFilesUploadStatus[index] = 'Server Error';

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + 'failed to obtain PO file upload URL',
                            properties: {
                                category: 'Action',
                            },
                        });
                        // Just forward the error
                        return throwError(error);
                    }),
                    switchMap(uploadURLforFile => {
                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + 'successfully obtained PO file upload URL',
                            properties: {
                                category: 'Action',
                            },
                        });

                        const headers = new HttpHeaders({
                            'Content-Type': 'application/pdf',
                            'Content-MD5': computedMd5Str,
                        });
                        const options = { headers: headers };

                        // upload file
                        return this.http.put<void>(uploadURLforFile, fileAsArrayBuffer, options);
                    }),
                    take(1),
                )
                .subscribe(
                    () => {
                        this.newFilesUploadDone[index] = true;
                        this.newFilesUploadStatus[index] = FILE_UPLOADED_STATUS;

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + 'successfully uploaded PO file',
                            properties: {
                                category: 'Action',
                            },
                        });
                    },
                    error => {
                        this.newFilesUploadDone[index] = true;
                        this.newFilesUploadStatus[index] = 'Failed to Upload';
                        console.error('upload error', error);

                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + 'failed to upload PO file',
                            properties: {
                                category: 'Action',
                            },
                        });
                    },
                );
        };
    }
}
