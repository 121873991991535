import { Component, Input, OnChanges } from '@angular/core';
import { CurrentUser, RoleAssignment, SSOUser } from '@pure1/data';

import { UserRoleStateService } from '../services/user-role-state.service';

@Component({
    selector: 'create-edit-sso-user',
    templateUrl: './create-edit-sso-user.component.html',
    host: {
        class: 'base-create-edit',
    },
})
export class CreateEditSSOUserComponent implements OnChanges {
    @Input() readonly assignment: RoleAssignment;
    @Input() readonly currentUser: CurrentUser;

    user?: SSOUser;
    isEdit = false;
    isProcessing = false;

    constructor(public urStateService: UserRoleStateService) {}

    ngOnChanges(): void {
        this.isEdit = !!this.assignment;

        if (this.isEdit) {
            this.user = new SSOUser(this.assignment);
        }
    }

    onGroupCreated(): void {
        this.returnToList();
    }

    returnToList(): void {
        this.urStateService.deferAssignable = false;
        this.urStateService.deferredAssignable = null;
        this.urStateService.previousDrawerState();
    }

    onCancelled(): void {
        this.returnToList();
    }
}
