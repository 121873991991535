import { ToastService, ToastType } from './../../services/toast.service';
import _ from 'lodash';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { map, switchMap, take } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';

import { Assignment } from '../user-role-management.interface';
import {
    AssigneeType,
    Collection,
    CurrentUser,
    ExternalUsersService,
    GroupsService,
    QueryParams,
    RoleAssignment,
    RoleAssignmentService,
    RoleType,
    UsersService,
    ViewsService,
    ViewReference,
    ResetPasswordService,
    SSOUsersService,
} from '@pure1/data';
import { UserRoleStateService } from '../services/user-role-state.service';
import { BaseAssignmentFormComponent } from '../base-assignment-form/base-assignment-form.component';

type BatchEditStatus = 'form' | 'error' | 'success' | 'confirmDelete';

@Component({
    selector: 'batch-edit-assignments',
    templateUrl: './batch-edit-assignments.component.html',
    host: {
        class: 'base-assignment-form',
    },
})
export class BatchEditAssignmentsComponent extends BaseAssignmentFormComponent implements OnChanges {
    @Input() readonly assignments: RoleAssignment[];
    @Input() readonly currentUser: CurrentUser;

    status: BatchEditStatus = 'form';

    constructor(
        viewsService: ViewsService,
        private externalUsersService: ExternalUsersService,
        private groupsService: GroupsService,
        resetPasswordService: ResetPasswordService,
        roleAssignmentService: RoleAssignmentService,
        private usersService: UsersService,
        private ssoUsersService: SSOUsersService,
        modalService: NgbModal,
        angulartics2: Angulartics2,
        urStateService: UserRoleStateService,
        protected toast: ToastService,
        private fb: UntypedFormBuilder,
    ) {
        // No need to supply assignmentService since we don't need create()
        super(
            viewsService,
            null,
            roleAssignmentService,
            urStateService,
            angulartics2,
            resetPasswordService,
            modalService,
            null,
            null,
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.roleTypes = this.getLegacyRoles();

        const previousRole = this.assignmentForm?.get('role').value as RoleType;
        // First, try to find the previousRole. If it's still available, leave it selected
        const defaultRole =
            this.roleTypes.find(roleType => roleType === previousRole) ??
            // Otherwise, if we only have one assignment selected, we'll just select that assignment's role
            (this.assignments.length === 1
                ? this.roleTypes.find(roleType => roleType === this.assignments[0].role)
                : // Default to the first in the list if we really can't find anything else
                  this.roleTypes[0]);

        this.assignmentForm = this.fb.group({
            role: [defaultRole, Validators.required],
            view: [],
        });

        this.getViews();
    }

    update(): void {
        if (this.isValidForm()) {
            const assignable$ = this.getAssignable();
            const assignee = this.makeAssignment(_.omit(this.assignmentForm.value, 'view'));

            const assignment$: Observable<Assignment> = assignable$.pipe(
                switchMap(assignable => {
                    const updateList = this.assignments.map(assignment => {
                        const newAssignment = Object.assign(assignee, assignable);
                        // TODO: Capture and show errors in the UI
                        return this.urStateService
                            .updateWithCache<Assignment>(
                                this.getAssignmentService(assignment),
                                newAssignment,
                                this.getAssignmentParams(assignment),
                            )
                            .pipe(
                                map(result => result.response[0]),
                                take(1),
                            );
                    });
                    return merge(...updateList);
                }),
            );

            this.loading = true;
            assignment$.subscribe(
                assignment => {},
                error => {
                    this.loading = false;
                    const errorMsg =
                        error.data?.errors?.[0].message ||
                        `There was an error with the update request. Not all assignments may have updated properly. Please try again.`;
                    this.toast.add(ToastType.error, errorMsg);
                },
                () => {
                    this.loading = false;
                    this.toast.add(ToastType.success, 'Successfully updated users');
                },
            );

            if (this.deferredAssignable && this.assignmentForm.controls.view.value === this.deferredAssignable) {
                // TODO: Revisit angulartics
                this.angulartics2.eventTrack.next({
                    action: 'UM - Batch Edit - Creating view and then assigning it from assignment form',
                    properties: { category: 'Action' },
                });
            }
        }
    }

    resetPasswords(): void {
        // Probably doesn't need to reset their own password since they found a way to log in?
        if (this.isCurrentUserSelected()) {
            return;
        }

        const resetList = this.assignments.map(assignment => {
            return this.resetPasswordService.delete(null, { email: assignment.id }).pipe(take(1));
        });
        this.loading = true;
        merge(...resetList).subscribe(
            () => {},
            err => {
                this.loading = false;
                this.toast.add(ToastType.error, 'An error occurred, please try again.');
            },
            () => {
                this.loading = false;
                this.toast.add(
                    ToastType.success,
                    `An email was sent to each account with instructions on how to reset their respective passwords.`,
                );
            },
        );
    }

    isSsoUser(): boolean {
        return this.currentUser.adUser;
    }

    isValidForm(): boolean {
        return !this.isCurrentUserSelected() && !this.isSsoAdminSelected() && this.assignmentForm.valid;
    }

    isCurrentUserSelected(): boolean {
        return this.assignments.some(assignment => assignment.id === this.currentUser.email);
    }

    isSsoAdminSelected(): boolean {
        return this.isSsoUser() && this.assignments.some(assignment => assignment.role === RoleType.PURE1_ADMIN);
    }

    areAllAssignmentsUsers(): boolean {
        return this.assignments.every(assignment => assignment.assigneeType === AssigneeType.USER);
    }

    getAssignmentService(assignment: RoleAssignment): Collection<Assignment> {
        switch (assignment.assigneeType) {
            case AssigneeType.USER:
                return this.usersService;
            case AssigneeType.EXTERNAL:
                return this.externalUsersService;
            case AssigneeType.GROUP:
                return this.groupsService;
            case AssigneeType.SSO_USER:
                return this.ssoUsersService;
            default:
                console.warn('Unexpected assignee type', assignment.assigneeType);
        }

        return null;
    }

    getAssignmentParams(assignment: RoleAssignment): string[] | QueryParams {
        switch (assignment.assigneeType) {
            case AssigneeType.USER:
            case AssigneeType.SSO_USER:
                return { email: assignment.id };
            case AssigneeType.EXTERNAL:
                return { ids: assignment.id };
            case AssigneeType.GROUP:
                return [assignment.name];
            default:
                console.warn('Unexpected assignee type', assignment.assigneeType);
        }

        return null;
    }

    openModal(modal: TemplateRef<NgbActiveModal>): void {
        this.modalService.open(modal);
    }

    onEntitiesChange(newAssignments: RoleAssignment[]): void {
        this.urStateService.updateSelectionFromDrawer(newAssignments.slice());
    }

    /*
     * Overridden methods
     */

    getLegacyRoles(): RoleType[] {
        if (this.assignments?.some(assignment => assignment.assigneeType === AssigneeType.EXTERNAL)) {
            return [RoleType.PURE1_VIEWER];
        }

        if (this.urStateService.currentMainPageState === 'groups') {
            return [RoleType.DASHBOARD_VIEWER, RoleType.VM_VIEWER, RoleType.PURE1_VIEWER];
        }

        return [
            RoleType.DASHBOARD_VIEWER,
            RoleType.VM_VIEWER,
            RoleType.PURE1_VIEWER,
            RoleType.PURE1_ADMIN,
            RoleType.PORTWORX_ADMIN,
        ];
    }

    updateAssignmentObject(assignment: Assignment): void {}

    // None of the fields are immutable
    copyAssignmentFields(newAssignment: Assignment): void {}

    makeAssignment(json: any): Assignment {
        // TODO: Ideally we can just use the Assignment in the data layer, but it looks like there was a lot
        // of special stuff to make views work. Need to figure out a better way to type this
        return {
            role: json.role,
            view: json.view,
        } as any as Assignment;
    }

    // Assignment selection can have multiple views, so we can't just show a single one
    updateView(viewReference: ViewReference): void {}

    cancel(): void {
        this.urStateService.updateSelectionFromDrawer([]);
        this.urStateService.closeDrawer();
    }
}
