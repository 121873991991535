import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'pre-enroll-prompt-modal',
    templateUrl: 'pre-enroll-prompt-modal.component.html',
})
export class PreEnrollPromptModalComponent {
    constructor(private activeModal: NgbActiveModal) {}

    cancel(): void {
        this.activeModal.dismiss({ error: 'cancelled', errorDescription: 'cancelled' });
    }

    continue(): void {
        this.activeModal.close();
    }
}
