import moment from 'moment';
import { concat, forkJoin, Observable, throwError } from 'rxjs';
import { Component, Input } from '@angular/core';
import {
    AssigneeType,
    Collection,
    ExternalUser,
    ExternalUsersService,
    Group,
    GroupsService,
    RoleAssignment,
    SSOUser,
    User,
    UsersService,
} from '@pure1/data';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserRoleStateService } from '../services/user-role-state.service';
import { delay, retryWhen, take } from 'rxjs/operators';
import { ToastService, ToastType } from '../../services/toast.service';

const RETRY_DELAY = moment.duration(0.5, 'second');
const RETRY_COUNT = 5;

@Component({
    selector: 'delete-assignments-modal',
    templateUrl: './delete-assignments-modal.component.html',
})
export class DeleteAssignmentsModalComponent {
    @Input() readonly assignments: RoleAssignment[];
    @Input() readonly modal: NgbActiveModal;

    readonly MAX_ASSIGNMENTS_TO_SHOW = 5;

    isProcessing: boolean;
    loading: boolean;

    constructor(
        public urStateService: UserRoleStateService,
        private toast: ToastService,
        private externalUsersService: ExternalUsersService,
        private groupsService: GroupsService,
        private usersService: UsersService,
    ) {}

    deleteAssignments(): void {
        const externalUsers = this.assignments.filter(assignment => assignment.assigneeType === AssigneeType.EXTERNAL);
        const regularUsers = this.assignments.filter(assignment => assignment.assigneeType === AssigneeType.USER);
        const groups = this.assignments.filter(assignment => assignment.assigneeType === AssigneeType.GROUP);

        const deleteObservables$: Observable<void>[] = [];

        if (externalUsers.length > 0) {
            deleteObservables$.push(...this.generateDeleteObservables(externalUsers, this.externalUsersService));
        }
        if (regularUsers.length > 0) {
            deleteObservables$.push(...this.generateDeleteObservables(regularUsers, this.usersService));
        }
        if (groups.length > 0) {
            deleteObservables$.push(...this.generateDeleteObservables(groups, this.groupsService));
        }

        this.isProcessing = true;
        this.loading = true;
        forkJoin(deleteObservables$)
            .pipe(take(1))
            .subscribe({
                error: () => {
                    this.toast.add(ToastType.error, `Failed to delete. Please contact Support`);
                    this.onSubscriptionCompletion();
                },
                next: () => {
                    let deleteMessage = groups.length > 0 ? 'group' : 'user';
                    deleteMessage += deleteObservables$.length > 1 ? 's' : '';
                    this.toast.add(ToastType.success, `Successfully deleted ${deleteMessage}`);
                    this.urStateService.closeDrawer();
                },
                complete: () => {
                    this.loading = false;
                    this.onSubscriptionCompletion();
                },
            });
    }

    private generateDeleteObservables(
        usersToDelete: RoleAssignment[],
        service: Collection<User | ExternalUser | Group | SSOUser>,
    ): Observable<void>[] {
        return usersToDelete.map(user => {
            return this.urStateService.deleteWithCache(service, { email: this.extractAssignmentId(user) }).pipe(
                retryWhen(errors =>
                    concat(
                        errors.pipe(delay(RETRY_DELAY.asMilliseconds()), take(RETRY_COUNT)),
                        // take(RETRY_COUNT) will complete the observable and won't propagate an error
                        // This way, we retry and propagate the error if it fails enough times
                        throwError(`Failed to complete after ${RETRY_COUNT} retries`),
                    ),
                ),
                take(1),
            );
        });
    }

    private extractAssignmentId(assignment: RoleAssignment): string {
        switch (assignment.assigneeType) {
            case AssigneeType.SSO_USER:
                return assignment.name;
            default:
                return assignment.id;
        }
    }

    private onSubscriptionCompletion(): void {
        this.isProcessing = false;
        this.urStateService.updateSelectionFromDrawer([]);
        this.modal.close();
    }
}
