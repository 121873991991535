<ng-container *ngIf="activatedTotpFactor; else totpNotActivated">
    <div class="success-confirmation">
        <div class="step-up-modal-header">
            <pureui-svg class="header-icon" svg="edge-service-check.svg" height="60"></pureui-svg>
        </div>
        <div class="step-up-modal-body">
            <div class="step-up-challenge-title">
                <div class="title"> Great! You are all set! </div>
                <div class="subtitle">
                    Your Pure1 Account is successfully linked. The two factor authentication via the authenticator app
                    is activated.
                </div>
            </div>
            <div class="step-up-challenge-inputs">
                <button class="btn btn-primary" (click)="close()"> Complete </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #totpNotActivated>
    <totp-activation
        *ngIf="stepUpVerifyResponse; else challengeVerify"
        [verifyResponse]="stepUpVerifyResponse"
        (activateSuccess)="activateSuccess()"
        (activateCancel)="cancel()"
    >
    </totp-activation>
    <ng-template #challengeVerify>
        <step-up-challenge
            class="step-up-modal"
            [factor]="activeFactor"
            [audience]="'auth-portal'"
            [authorizationDetails]="[{ enroll: 'totp' }]"
            [singleUse]="true"
            [isActivate]="false"
            (verifySuccess)="verifySuccess($event)"
            (verifyFailure)="verifyFailure($event)"
            (verifyCancel)="cancel()"
        >
        </step-up-challenge>
    </ng-template>
</ng-template>
