<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" [height]="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Register Application </h3>
</div>

<div class="modal-body">
    <!-- Application name -->
    <div class="form-group row">
        <label class="col-xs-2 col-form-label text-xs-right" for="api-key-app-name"> Name </label>
        <div class="col-xs-10">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    id="api-key-app-name"
                    [(ngModel)]="name"
                    autofocus="autofocus"
                    placeholder="&lt;app-name&gt;"
                    maxlength="15"
                />
            </div>
        </div>
    </div>

    <!-- Public Key -->
    <div class="form-group row">
        <label class="col-xs-2 col-form-label text-xs-right" for="api-key-public-key"> Public Key </label>
        <div class="col-xs-10">
            <div class="input-group">
                <textarea
                    rows="9"
                    cols="70"
                    type="text"
                    class="form-control"
                    id="api-key-public-key"
                    [(ngModel)]="public_key"
                    placeholder="PEM encoded 2048-bit RSA key starting with -----BEGIN PUBLIC KEY-----"
                    maxlength="450"
                ></textarea>
            </div>
        </div>
    </div>

    <!-- Pure1 Role -->
    <div class="form-group row">
        <label class="col-xs-2 col-form-label text-xs-right" for="api-key-role"> Pure1 Role </label>
        <div class="col-xs-10">
            <div class="input-group">
                <select class="form-control" id="api-key-role" [(ngModel)]="max_role">
                    <option *ngFor="let role of roles; trackBy: 'name' | trackByProperty" [ngValue]="role.name">{{
                        $any(role.name) | roleType
                    }}</option>
                </select>
            </div>
        </div>
    </div>

    <!-- Error message -->
    <div class="form-group row" *ngIf="errorMessage">
        <div class="col-xs-2"> </div>
        <div class="col-xs-10">
            <div class="text-danger" id="error-message-div">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</div>

<div class="modal-footer btn-toolbar">
    <button type="button" class="btn btn-info" (click)="modal.dismiss()"> Cancel </button>

    <button
        type="button"
        id="upload"
        class="btn btn-primary"
        pure-read-only
        (click)="createAppKey()"
        [disabled]="loading"
    >
        <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
        Upload
    </button>
</div>
