import _ from 'lodash';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { Input, OnChanges, SimpleChanges, TemplateRef, Directive, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { switchMap, take, map, shareReplay } from 'rxjs/operators';

import { stringOrEmpty } from 'core/src/utils/helpers';
import {
    Collection,
    View,
    ViewReference,
    RoleType,
    User,
    RoleAssignment,
    ExternalUser,
    AssignmentStatus,
    AdditionalRoleAssignment,
    KnownRoleDomains,
    SSOUser,
} from '@pure1/data';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';

import {
    DeferredAssignable,
    Assignable,
    Assignment,
    isViewAssignment,
    isSimpleFilterAssignment,
    isSimpleFilterAssignable,
} from '../user-role-management.interface';
import { ToastService, ToastType } from './../../services/toast.service';
import { getSimpleFilterString } from '../../utils/filter/conversion';
import { UserRoleStateService } from '../services/user-role-state.service';
import { isPendingAssignmentStatus } from '../helpers/is-pending-assignment-status';
import { ampli } from 'core/src/ampli';

const SAFE_MODE_ROLE_STATUS_MESSAGE_MAP = new Map<AssignmentStatus, string>([
    ['ASSIGNMENT_PENDING', 'The SafeMode Approver role is pending approval.'],
    ['REMOVAL_PENDING', 'The SafeMode Approver role is pending removal.'],
]);

@Directive()
export abstract class BaseAssignmentFormComponent<T extends Assignment = Assignment> implements OnChanges, OnDestroy {
    readonly RoleType = RoleType;

    @Input() readonly isEdit: boolean;
    @Input() readonly deferredAssignable: DeferredAssignable;
    @Input() readonly assignment: T | null;
    @Input() readonly roleAssignment: RoleAssignment;

    views: View[];
    roleTypes: RoleType[];
    assignmentForm: UntypedFormGroup;
    currentSimpleFilter: Assignable;
    loading = false;
    isReadonly = true;
    isGroupForm = false;

    readonly ampli = ampli;

    protected readonly destroy$ = new Subject<void>();

    constructor(
        protected viewsService: Collection<View>,
        protected assignmentService: Collection<Assignment>,
        protected roleAssignmentService: Collection<RoleAssignment>,
        protected urStateService: UserRoleStateService,
        protected angulartics2: Angulartics2,
        protected resetPasswordService: Collection<User>,
        protected modalService: NgbModal,
        protected toast: ToastService,
        private mpaRequestsBadgeService: MpaRequestsBadgeService,
    ) {
        this.roleTypes = this.getLegacyRoles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // restore any changes that were in place if we left to add a view
        if (this.urStateService.editedAssignment) {
            // we need to set any undefined properties to null
            // because for some reason, formGroup.setValue() doesn't like undefined properties
            Object.entries(this.urStateService.editedAssignment)
                .filter(([key, value]) => value === undefined)
                .forEach(([key, value]) => {
                    this.urStateService.editedAssignment[key] = null;
                });

            this.assignmentForm.setValue(this.urStateService.editedAssignment);
            this.urStateService.editedAssignment = null;
            this.isReadonly = false;
        } else {
            this.isReadonly = this.isEdit;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getAssignable(): Observable<Assignable> {
        if (this.deferredAssignable && this.assignmentForm.controls.view.value === this.deferredAssignable) {
            return this.deferredAssignable.assignable$;
        } else if (
            this.assignmentForm.controls.view &&
            this.assignmentForm.controls.view.value === this.currentSimpleFilter
        ) {
            return of(this.currentSimpleFilter);
        } else if (this.assignmentForm.controls.view) {
            return of({ view: this.assignmentForm.controls.view.value });
        } else {
            return of({ view: null });
        }
    }

    getSimpleFilterString(assignable: Assignable): string {
        if (isSimpleFilterAssignable(assignable)) {
            return getSimpleFilterString(assignable.selector);
        }
        return '';
    }

    create(): Observable<Assignment> {
        if (this.assignmentForm.valid) {
            const assignable$ = this.getAssignable();
            const assignee = this.makeAssignment(_.omit(this.assignmentForm.value, 'view'));
            // handle fields unique to each Assignment type
            this.copyAssignmentFields(assignee);

            const assignment$ = assignable$.pipe(
                switchMap(assignable => {
                    const newAssignment = Object.assign(assignee, assignable);
                    if (this.isEdit) {
                        if (this.assignment instanceof User) {
                            return this.urStateService
                                .updateWithCache<Assignment>(this.assignmentService, newAssignment, {
                                    email: this.assignment.email,
                                })
                                .pipe(map(result => result.response[0]));
                        } else if (this.assignment instanceof ExternalUser) {
                            return this.urStateService
                                .updateWithCache<Assignment>(this.assignmentService, newAssignment, {
                                    ids: this.assignment.email,
                                })
                                .pipe(map(result => result.response[0]));
                        } else if (this.assignment instanceof SSOUser) {
                            return this.urStateService
                                .updateWithCache<Assignment>(this.assignmentService, newAssignment, {})
                                .pipe(map(result => result.response[0]));
                        }
                        return this.urStateService
                            .updateWithCache<Assignment>(this.assignmentService, newAssignment, [this.assignment.name])
                            .pipe(map(result => result.response[0]));
                    } else {
                        return this.urStateService.createWithCache<Assignment>(this.assignmentService, newAssignment);
                    }
                }),
                shareReplay(1),
            );
            this.loading = true;
            assignment$.subscribe(
                assignment => {
                    this.loading = false;
                    this.isReadonly = true;
                    const entity = this.isGroupForm ? 'group' : 'user';
                    const successText =
                        'Successfully ' + (this.isEdit ? `updated ${entity} information` : `created ${entity}`);
                    this.toast.add(ToastType.success, successText);
                    if (!this.isEdit) {
                        this.urStateService.closeDrawer();
                    }
                    this.updateAssignmentObject(assignment);
                    this.mpaRequestsBadgeService.loadFreshMpaRequests();
                },
                error => this.handleError(error),
            );

            if (this.deferredAssignable && this.assignmentForm.controls.view.value === this.deferredAssignable) {
                this.angulartics2.eventTrack.next({
                    action: 'Creating view and then assigning it from assignment form',
                    properties: { category: 'Action' },
                });
            }
            // Child component might need to do smth when request is done, so lets return observable.
            return assignment$;
        }
        return EMPTY;
    }

    addView(): void {
        this.urStateService.editedAssignment = this.assignmentForm.getRawValue();
        this.urStateService.editView(null, true);
    }

    selectView(view: View | DeferredAssignable | null): void {
        if (view === this.deferredAssignable || view === this.currentSimpleFilter) {
            this.assignmentForm.controls.view.setValue(view);
        } else if (view) {
            this.assignmentForm.controls.view.setValue(new ViewReference(view));
        } else {
            this.assignmentForm.controls.view.setValue(null);
        }

        ampli.umBatchEditViewSelected({ category: 'Action' });
    }

    resetPassword(): void {
        this.loading = true;
        // External Users and Groups cannot reset password, so "assignment" here must refer to a User.
        const email = (<User>this.assignment).email;
        if (this.isEdit) {
            this.resetPasswordService.delete(null, { email: email }).subscribe(
                () => {
                    this.loading = false;
                    this.toast.add(
                        ToastType.success,
                        `An email was sent to ${email} with instructions on how to reset the password.`,
                    );
                },
                () => {
                    this.loading = false;
                    this.toast.add(ToastType.error, 'An error occurred, please try again.');
                },
            );
        }
    }

    cancel(): void {
        if (this.isEdit) {
            this.isReadonly = true;
        } else {
            this.urStateService.closeDrawer();
        }
    }

    beginEdit(): void {
        this.isReadonly = false;
    }

    openModal(modal: TemplateRef<NgbActiveModal>): void {
        this.modalService.open(modal);
    }

    getLegacyRoles(): RoleType[] {
        return Object.values(RoleType);
    }

    getWarningMessageForRoles(roles: AdditionalRoleAssignment[]): string {
        const safeModeRoleInPendingState = roles.find(
            ({ domain, status }) => domain === KnownRoleDomains.SAFE_MODE && isPendingAssignmentStatus(status),
        );

        return safeModeRoleInPendingState
            ? SAFE_MODE_ROLE_STATUS_MESSAGE_MAP.get(safeModeRoleInPendingState.status)
            : '';
    }

    abstract updateAssignmentObject(assignment: Assignment): void;

    abstract updateView(viewReference: ViewReference): void;

    abstract makeAssignment(json: any): Assignment; // call the corresponding constructor

    abstract copyAssignmentFields(assignment: Assignment): void; // handle unique fields

    protected handleError(error: any): void {
        this.loading = false;
        const errorMsg =
            stringOrEmpty(error.data?.errors?.[0].message) ||
            stringOrEmpty(error.error?.message) ||
            stringOrEmpty(error.error) ||
            `There was an error with the ${this.isEdit ? 'update' : 'create'} request. Please try again.`;
        this.toast.add(ToastType.error, errorMsg);
    }

    protected getViews(): void {
        this.loading = true;
        this.urStateService
            .listWithCache(this.viewsService)
            .pipe(take(1))
            .subscribe(
                data => {
                    this.loading = false;
                    this.views = data.response.filter(view => view.name).sort((a, b) => a.name.localeCompare(b.name));

                    if (this.deferredAssignable) {
                        this.assignmentForm.controls.view.setValue(this.deferredAssignable || null, { onlySelf: true });
                    } else if (this.assignment && isViewAssignment(this.assignment)) {
                        const assignmentViewId = this.assignment.view.id;
                        const viewReference: ViewReference = new ViewReference(
                            this.views.find(view => view.id === assignmentViewId),
                        );
                        this.assignmentForm.controls.view.setValue(viewReference, { onlySelf: true });
                        this.updateView(viewReference);
                    } else if (this.assignment && isSimpleFilterAssignment(this.assignment)) {
                        this.currentSimpleFilter = { selector: this.assignment.selector };
                        this.assignmentForm.controls.view.setValue(this.currentSimpleFilter, { onlySelf: true });
                    } else {
                        this.assignmentForm.controls.view.setValue(null, { onlySelf: true });
                    }
                    this.assignmentForm.controls.view.enable();
                },
                () => {
                    this.loading = false;
                },
            );
    }
}
