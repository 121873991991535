<div *ngIf="!urStateService.drawerClosed" class="drawer-container">
    <ng-container [ngSwitch]="urStateService.currentDrawerState">
        <view-list *ngSwitchCase="'view-list'"></view-list>

        <edit-view
            *ngSwitchCase="'edit-view'"
            [currentUser]="currentUser"
            [viewToEdit]="urStateService.viewToEdit"
            [selectedAssignees]="$any(urStateService.assignments)"
            [assignmentService]="urStateService.assignmentService"
            [deferAssignable]="urStateService.deferAssignable"
            [allowSimpleFilter]="urStateService.allowSimpleFilter"
        ></edit-view>

        <create-edit-user *ngSwitchCase="'add-user'" [currentUser]="currentUser" [isEdit]="false"></create-edit-user>

        <create-edit-user
            *ngSwitchCase="'edit-user'"
            [currentUser]="currentUser"
            [isEdit]="true"
            [userAssignment]="urStateService.assignmentToEdit"
        ></create-edit-user>

        <create-edit-sso-user *ngSwitchCase="'add-sso-user'" [currentUser]="currentUser"></create-edit-sso-user>

        <create-edit-sso-user
            *ngSwitchCase="'edit-sso-user'"
            [currentUser]="currentUser"
            [assignment]="urStateService.assignmentToEdit"
        ></create-edit-sso-user>

        <create-edit-user
            *ngSwitchCase="'add-external-user'"
            userType="external"
            [currentUser]="currentUser"
            [isEdit]="false"
        ></create-edit-user>

        <create-edit-user
            *ngSwitchCase="'edit-external-user'"
            userType="external"
            [currentUser]="currentUser"
            [isEdit]="true"
            [userAssignment]="urStateService.assignmentToEdit"
        ></create-edit-user>

        <create-edit-group *ngSwitchCase="'add-group'"></create-edit-group>

        <create-edit-group
            *ngSwitchCase="'edit-group'"
            [groupAssignment]="urStateService.assignmentToEdit"
        ></create-edit-group>

        <batch-edit-assignments
            *ngSwitchCase="'batch-edit-assignments'"
            [assignments]="urStateService.assignments"
            [currentUser]="currentUser"
        ></batch-edit-assignments>
    </ng-container>
</div>
