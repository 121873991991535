import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DraasApiExecutionPlanReport, DraasApiFailoverReport, DraasApiReport } from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryReportType } from '../models/disaster-recovery-report';
import { DisasterRecoveryRtoSummaryReport } from '../models/disaster-recovery-rto-summary-report';
import { DisasterRecoveryExecutionPlanReport } from '../models/disaster-recovery-execution-plan-report';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryReportsService {
    constructor(protected http: HttpClient) {}

    private getEndpoint(clusterId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/reports`;
    }

    getReports(
        clusterId: string,
        reportType: DisasterRecoveryReportType,
        year: number,
        month: number,
        planId?: string,
        policyId?: string,
    ): Observable<DisasterRecoveryExecutionPlanReport[]> {
        const queryParams: string[] = [];
        queryParams.push(`reportType=${reportType}`);
        queryParams.push(`year=${year}`);
        queryParams.push(`month=${month}`);
        if (planId) {
            queryParams.push(`planIds=${planId}`);
        }
        if (policyId) {
            queryParams.push(`policyIds=${policyId}`);
        }
        const url = this.getEndpoint(clusterId) + `?${queryParams.join('&')}`;
        return this.http
            .get<DraasApiExecutionPlanReport[]>(url)
            .pipe(map(response => response.map(data => new DisasterRecoveryExecutionPlanReport(data))));
    }

    getRtoSummaryReport(clusterId: string, reportId: string): Observable<DisasterRecoveryRtoSummaryReport> {
        return this.getReport(clusterId, reportId).pipe(
            map(response => new DisasterRecoveryRtoSummaryReport(response as DraasApiFailoverReport)),
        );
    }

    getLatestRtoSummaryReport(
        clusterId: string,
        planId?: string,
        policyId?: string,
    ): Observable<DisasterRecoveryRtoSummaryReport> {
        return this.getLatestReport(clusterId, 'ONE_FAILOVER_EXECUTION', planId, policyId).pipe(
            map(response =>
                response ? new DisasterRecoveryRtoSummaryReport(response as DraasApiFailoverReport) : null,
            ),
        );
    }

    private getReport(clusterId: string, reportId: string): Observable<DraasApiReport> {
        const url = this.getEndpoint(clusterId) + `/${reportId}`;
        return this.http.get<DraasApiReport>(url).pipe();
    }

    private getLatestReport(
        clusterId: string,
        reportType: DisasterRecoveryReportType,
        planId?: string,
        policyId?: string,
    ): Observable<DraasApiReport> {
        const queryParams: string[] = [];
        queryParams.push(`reportType=${reportType}`);
        if (planId) {
            queryParams.push(`planIds=${planId}`);
        }
        if (policyId) {
            queryParams.push(`policyIds=${policyId}`);
        }
        const url = this.getEndpoint(clusterId) + `/last?${queryParams.join('&')}`;
        return this.http.get<DraasApiReport>(url);
    }
}
