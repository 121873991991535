<div *ngIf="isImpersonating; else notImpersonating">
    You do not have access to current user's authentication details, because you are switched to another user.
</div>
<ng-template #notImpersonating>
    <pureui-spinner *ngIf="loading" class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <ng-container *ngIf="!loading">
        <ng-container *ngIf="stepUpLockedOut; else notLockedOut">
            <div>
                Your account has been temporarily locked. There have been too many invalid verification code attempts
                during login. Please try again later or
                <span class="support-link" (click)="stepUpContactSupportToUnlock()"
                    >contact Pure Technical Services</span
                >
                to unlock your account.
            </div>
        </ng-container>
        <ng-template #notLockedOut>
            <ng-container *ngIf="totpFactor; else noTotpFactor">
                <div class="section-row">
                    <div class="section-content"><span class="bold-text">Two Factor Authentication</span></div>
                    <div class="section-content"
                        ><pureui-svg
                            class="enabled-status-icon"
                            svg="edge-service-check.svg"
                            width="12"
                            height="12"
                        ></pureui-svg>
                        Enabled</div
                    >
                </div>
                <div class="section-row">
                    <div class="section-content">
                        Lost access to your phone?<br />
                        <a class="support-link" (click)="stepUpContactSupportForLostPhone()"
                            >Contact Pure Technical Services</a
                        >
                    </div>
                    <div class="section-content">
                        <button class="btn btn-secondary" (click)="disableStepUp()">Disable</button>
                    </div>
                </div>
            </ng-container>
            <ng-template #noTotpFactor>
                <ng-container *ngIf="smsFactor; else noSmsFactor">
                    <div class="section-content col-xs-4 form-label">Mobile Phone</div>
                    <div class="section-content col-xs-7">{{
                        smsFactor.profile.phoneNumber | obfuscatePhoneNumber
                    }}</div>
                    <div class="section-content col-xs-4 red-text bold-text">Action Required:</div>
                    <div class="section-content col-xs-7 migration-message" *ngIf="smsFactorExpirationDays != null">
                        Your step-up authentication
                        <span class="bold-text"
                            >expires in {{ smsFactorExpirationDays }}
                            {{ smsFactorExpirationDays | pluralize: 'day' }}</span
                        >. Update your two-factor authentication soon.
                    </div>

                    <div class="section-row info-box">
                        <div class="section-content">
                            <pureui-svg svg="info-bubble-icon.svg" width="30"></pureui-svg>
                        </div>
                        <div class="section-content">
                            To get started, download and install your preferred authenticator app.
                            <a
                                href="https://support.purestorage.com/Pure1/Pure1_Manage/002_Getting_Support/Identity_Management_with_Step-Up_Authentication#Downloading_a_Supported_Authenticator_Application"
                                target="_blank"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                    <div class="section-row">
                        <div class="section-content">
                            Lost access to your phone?<br />
                            <a class="support-link" (click)="stepUpContactSupportForLostPhone()"
                                >Contact Pure Technical Services</a
                            >
                        </div>
                        <div class="section-content" [ngSwitch]="identityCenterFeatureEnabled$ | async">
                            <a
                                *ngSwitchCase="true"
                                [href]="'identity-center' | pure1AppLink: '/profile/account-security?mfaSetupOpen=true'"
                                angulartics2On="click"
                                angularticsAction="Pure Identity Center - Dialog - Launch"
                                angularticsCategory="Action"
                                target="_blank"
                            >
                                <hive-button
                                    icon="regular/arrow-up-right-from-square.svg"
                                    iconPosition="right"
                                    label="Start Setup"
                                />
                            </a>
                            <button (click)="openRegistrationModal()" class="btn btn-primary" *ngSwitchDefault>
                                Start Setup
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noSmsFactor>
                    <div class="section-row">
                        <div class="section-content"><span class="bold-text">Two Factor Authentication</span></div>
                        <div class="section-content section-content-right">
                            <span class="red-text"> Recommended</span><br />
                            <pureui-svg
                                class="disabled-status-icon"
                                svg="edge-service-status-minus.svg"
                                width="12"
                                height="12"
                            ></pureui-svg>
                            <span class="gray-text">Not Enabled</span>
                        </div>
                    </div>
                    <div class="section-row info-box">
                        <div class="section-content">
                            <pureui-svg svg="info-bubble-icon.svg" width="30"></pureui-svg>
                        </div>
                        <div class="section-content">
                            To get started, download and install your preferred authenticator app.
                            <a
                                href="https://support.purestorage.com/Pure1/Pure1_Manage/002_Getting_Support/Identity_Management_with_Step-Up_Authentication#Downloading_a_Supported_Authenticator_Application"
                                target="_blank"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                    <div class="section-row">
                        <div></div
                        ><!--Dummy to justify button to the right-->
                        <div class="section-content" [ngSwitch]="identityCenterFeatureEnabled$ | async">
                            <a
                                *ngSwitchCase="true"
                                [href]="'identity-center' | pure1AppLink: '/profile/account-security?mfaSetupOpen=true'"
                                angulartics2On="click"
                                angularticsAction="Pure Identity Center - Dialog - Launch"
                                angularticsCategory="Action"
                                target="_blank"
                            >
                                <hive-button
                                    icon="regular/arrow-up-right-from-square.svg"
                                    iconPosition="right"
                                    label="Start Setup"
                                />
                            </a>
                            <button (click)="openRegistrationModal()" class="btn btn-primary" *ngSwitchDefault>
                                Start Setup
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-template>
    </ng-container>
</ng-template>
