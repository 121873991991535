<div class="modal-header">
    <button
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Beta Registration - Close"
        angularticsCategory="Action"
        class="close modal-x-button pull-right"
        type="button"
    >
        <pureui-svg class="pstg-action-icon" height="12" svg="x-action.svg"></pureui-svg>
    </button>
    <div class="modal-title">Introducing Pure Identity and Access Management (IAM)</div>
</div>

<div class="modal-body">
    <div class="introducing-identity-center-dialog-content">
        <div class="introducing-identity-center-card">
            <img
                alt="Pure Identity and Access Management"
                class="introducing-identity-center-dialog-content-image"
                src="/images/introducing-identity-center-0.svg"
            />
            <p class="hive-paragraph-regular hive-paragraph-small">
                Pure Identity and Access Management (IAM) is a centralized portal for managing user identities and
                access across Pure Storage products. It ensures secure and efficient access control, giving users only
                the permissions they need. Register now for beta and see how Pure IAM can enhance your operations!
            </p>
        </div>
    </div>
</div>

<div class="modal-footer">
    <hive-checkbox
        [(ngModel)]="doNotShowAgain"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Do not show again"
        angularticsCategory="Action"
        class="checkbox"
    >
        Don't show this message any more
    </hive-checkbox>
    <a
        [external]="true"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Beta Registration - Learn More"
        angularticsCategory="Action"
        class="link"
        hive-link
        href="https://support.purestorage.com/bundle/m_pure1_manage_administration/page/Pure1/Pure1_Manage/topics/concept/c_Overview_IAM.html"
    >
        Learn More
    </a>
    <a
        (click)="close(true)"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Beta Registration - Register"
        angularticsCategory="Action"
        contactEmailField="entry.144640397"
        contactNameField="entry.467633826"
        prefillGoogleForm="https://docs.google.com/forms/d/e/1FAIpQLSf6Zv_-Q7XxuUGT81kzJ178FpYAPt2njJ3oNnl9u10BDKG3_Q/viewform"
        target="_blank"
    >
        <hive-button icon="regular/arrow-up-right-from-square.svg" iconPosition="right" label="Register for Beta" />
    </a>
</div>
