<form id="external-user-form" class="form-body" [formGroup]="assignmentForm">
    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <section id="external-user-details" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Overview</h1>
        </section>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="user-email">Email</label>
            </div>
            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showEmailInput">{{ newExternalUser.email }}</ng-container>
                <ng-template #showEmailInput>
                    <input
                        class="form-control"
                        id="user-email"
                        name="user-email"
                        type="email"
                        placeholder="Email Address *"
                        formControlName="email"
                    />
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-access-control" class="form-section">
        <section class="section-header">
            <h1 class="section-title">Access Control</h1>
            <div class="edit-profile hover-effect" *ngIf="isReadonly" (click)="beginEdit()">
                <pureui-svg class="edit-icon" svg="edit.svg" height="16"></pureui-svg>
            </div>
        </section>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label [ngClass]="{ required: !isReadonly }" for="external-user-role-assignment">Role</label>
            </div>
            <div class="col-xs-9 form-field"> Pure1 Viewer </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-view-assignment">View</label>
            </div>

            <div class="col-xs-9 form-field">
                <ng-container *ngIf="isReadonly; else showViewDropdown">{{
                    newExternalUser.view?.name || 'Unassigned (All Arrays)'
                }}</ng-container>
                <ng-template #showViewDropdown>
                    <view-dropdown
                        [views]="views"
                        [deferredAssignable]="deferredAssignable"
                        [addViewDisabled]="assignmentForm.controls.view.disabled"
                        [dropdownDisabled]="assignmentForm.controls.view.disabled"
                        [initialView]="$any(newExternalUser.view)"
                        (addViewSelected)="addView()"
                        (viewChanged)="selectView($event)"
                    >
                    </view-dropdown>
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-options" class="form-section" *ngIf="isEdit">
        <div class="profile-options">
            <div class="btn-toolbar">
                <span
                    class="btn profile-option-button delete-account hover-effect"
                    (click)="openModal(deleteUserModal)"
                    angulartics2On="click"
                    angularticsAction="UM - Delete user"
                    angularticsCategory="Action"
                >
                    Remove Account
                </span>
            </div>
        </div>
    </section>
</form>

<ng-template #deleteUserModal let-modal>
    <delete-assignments-modal [assignments]="[roleAssignment]" [modal]="modal"> </delete-assignments-modal>
</ng-template>

<ng-container *ngIf="!isReadonly">
    <div class="form-footer">
        <button
            class="btn btn-primary float-xs-right save-button"
            (click)="create()"
            [disabled]="('PURE1:write:users' | isNotAllowed) || !isValidForm()"
            angulartics2On="click"
            angularticsAction="UM - Create user"
            angularticsCategory="Action"
        >
            Save
        </button>
        <button
            class="btn btn-info float-xs-right cancel-button"
            (click)="cancel()"
            angulartics2On="click"
            angularticsAction="UM - Cancel create user"
            angularticsCategory="Action"
        >
            Cancel
        </button>
    </div>
</ng-container>
