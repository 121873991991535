import { Resource } from '../interfaces/resource';

// This class is used as type for both the arrays and filesystems
// associated with a directory.
export class DirectoryMember implements Resource {
    id: string;
    name: string;
    resourceType: string;

    // for arrays
    fqdn?: string;
    flashArrayId?: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.resourceType = json.resourceType;

        this.fqdn = json.fqdn || null;
        this.flashArrayId = json.flashArrayId || null;
    }
}
