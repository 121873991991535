import { Injectable, OnDestroy } from '@angular/core';
import { smartTimer } from '@pstg/smart-timer';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    AutocompleteKeyParams,
    AutocompleteKeyResponse,
    AutocompleteService,
    AutocompleteValueParams,
    AutocompleteValueResponse,
    FeatureFlagDxpService,
    IAutocompleteKey,
    ProgramType,
    Resource,
    View,
    ViewsService,
} from '@pure1/data';
import moment from 'moment';
import { forkJoin, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { FeatureNames } from '../model/FeatureNames';
import { ARRAY_NAME_KEY, CITY_KEY, COUNTRY_KEY, FQDN, MODEL_KEY, VERSION_KEY } from '../redux/actions';

export type GfbFilterMode = 'views' | 'metric_groups' | 'default';

const featureNameFieldMap: Map<FeatureNames, string[]> = new Map<FeatureNames, string[]>([
    [FeatureNames.HW_EOL, ['has_end_of_life_hardware', 'end_of_life_hardware']],
    [FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE, ['capacity_expandable']],
]);

@Injectable() // Not provided in root: only gets used as sandboxed instance
export class GfbAutocompleteService implements OnDestroy {
    private filterForSingleValueTags: GfbFilterMode = 'default';
    private viewsCache: Map<string, View>; // set per instance
    private orgId: number = null;
    private readonly filterModeKeys: Map<GfbFilterMode, Set<string>> = new Map([
        ['views', new Set([ARRAY_NAME_KEY, CITY_KEY, COUNTRY_KEY, MODEL_KEY, 'os', VERSION_KEY])],
        ['metric_groups', new Set([ARRAY_NAME_KEY, CITY_KEY, COUNTRY_KEY, FQDN, MODEL_KEY, 'os', VERSION_KEY])],
    ]);
    private programType: ProgramType = null;
    private viewListSubscription: Subscription;
    private enabledPolicyFields: Map<string, boolean> = new Map<string, boolean>();

    constructor(
        private autocomplete: AutocompleteService,
        private viewsService: ViewsService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {
        forkJoin({
            [FeatureNames.HW_EOL]: featureFlagDxpService.getFeatureFlag(FeatureNames.HW_EOL),
            [FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE]: featureFlagDxpService.getFeatureFlag(
                FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE,
            ),
        })
            .pipe(take(1))
            .subscribe(response => {
                const features: Map<FeatureNames, FeatureFlagStatus> = new Map<FeatureNames, FeatureFlagStatus>(
                    Object.entries(response) as [FeatureNames, FeatureFlagStatus][],
                );
                for (const featureName of features.keys()) {
                    for (const field of featureNameFieldMap.get(featureName)) {
                        this.enabledPolicyFields.set(field, features.get(featureName)?.enabled === true);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeFromViewList();
    }

    getKeys(params$: Observable<AutocompleteKeyParams>): Observable<AutocompleteKeyResponse> {
        // read from views cache when entity is 'views'
        const getViewKeyRes$: Observable<AutocompleteKeyResponse> = params$.pipe(
            filter(params => params.entity === 'views'),
            map(({ entity, match }) => {
                const keys: IAutocompleteKey[] = Array.from(this.viewsCache.values())
                    .filter(view => view.name.toLocaleLowerCase().includes(match.toLocaleLowerCase()))
                    .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
                    .map(view => ({ key: view.id, display_key: view.name, namespace: null }));
                return { entity, match, keys };
            }),
        );
        // for all other entities, pass through to autocomplete service
        const getOrgNonViewKeyRes$: Observable<AutocompleteKeyResponse> = this.autocomplete
            .getKeys(this.setGfbFilter(params$).pipe(filter(params => params.entity !== 'views')))
            .pipe(
                map((keyResponse: AutocompleteKeyResponse) => {
                    let { keys } = keyResponse;
                    const allowedKeys = this.filterModeKeys.get(this.filterForSingleValueTags);
                    if (allowedKeys && keyResponse.entity === 'arrays') {
                        keys = keys.filter(key => key.namespace !== null || allowedKeys.has(key.key));
                    }
                    keys = keys.filter(tagSummary =>
                        this.enabledPolicyFields.has(tagSummary.key)
                            ? this.enabledPolicyFields.get(tagSummary.key)
                            : true,
                    );
                    return { ...keyResponse, keys };
                }),
            );

        return merge(getViewKeyRes$, getOrgNonViewKeyRes$);
    }

    getValues(params$: Observable<AutocompleteValueParams>): Observable<AutocompleteValueResponse[]> {
        return this.autocomplete.getValues(this.setGfbFilter(params$));
    }

    getView(viewReference: Resource): View {
        return this.viewsCache.get(viewReference.id);
    }

    enableViewFiltering(orgId?: number, programType?: ProgramType): void {
        this.setOrgScope(orgId);
        this.setTagFilter('views');
        this.setProgramTypeFilter(programType);
        this.subscribeToViewList();
    }

    enableMetricGroups(orgId: number): void {
        this.setOrgScope(orgId);
        this.setTagFilter('metric_groups');
    }

    setOrgScope(orgId?: number): void {
        this.orgId = orgId ?? null;
    }

    setProgramTypeFilter(programType?: ProgramType): void {
        this.programType = programType ?? null;
    }

    setTagFilter(val: GfbFilterMode): void {
        this.filterForSingleValueTags = val;
    }

    private setGfbFilter<T = AutocompleteKeyParams | AutocompleteValueParams>(params$: Observable<T>): Observable<T> {
        const orgValue = this.orgId || null;
        const programTypeValue = this.programType || null;
        if (this.orgId || this.programType) {
            return params$.pipe(
                map(params => Object.assign(params, { orgId: orgValue, programType: programTypeValue })),
            );
        }
        return params$;
    }

    private subscribeToViewList(): void {
        this.unsubscribeFromViewList();
        this.viewListSubscription = smartTimer(0, moment.duration(30, 'seconds').asMilliseconds())
            .pipe(
                switchMap(() =>
                    this.viewsService.list().pipe(
                        take(1),
                        catchError(() => of(null)),
                    ),
                ),
            )
            .subscribe(dataPage => {
                if (dataPage != null) {
                    this.viewsCache = new Map(dataPage.response.map<[string, View]>(view => [view.id, view]));
                }
            });
    }

    private unsubscribeFromViewList(): void {
        if (this.viewListSubscription && !this.viewListSubscription.closed) {
            this.viewListSubscription.unsubscribe();
        }
    }
}
