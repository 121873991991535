<div class="modal-header">
    <button
        type="button"
        class="close modal-x-button pull-right"
        (click)="cancelModal()"
        angulartics2On="click"
        angularticsAction="Forecast - Simulate License Reserve Level Modal - Click X to close"
    >
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Simulate License Reserve Level </h3>
</div>

<div class="modal-body">
    <div class="license-info">
        <div>
            <span class="gray-text">Selected License: </span> {{ forecastedLicense ? forecastedLicense.name : '' }}
        </div>
        <div>
            <span class="gray-text">Service Tier: </span> {{ forecastedLicense ? forecastedLicense.licenseType : '' }}
        </div>
    </div>
    <div class="reserve-level-expansion">
        <div class="reserve-level-expansion-slider-label">I would like to reserve:</div>
        <div
            *ngIf="showSliderHelp()"
            class="reserve-level-expansion-slider-help"
            ngbTooltip="The slider below uses linear scale from 0 to 2 x of current reserve level and uses quadratic scale beyond that."
            placement="right"
        >
            <pureui-svg class="pstg-action-icon" svg="help-circle.svg" height="11"></pureui-svg>
        </div>
        <div class="reserve-level-expansion-container">
            <div class="pure1-common-slider reserve-level-expansion-slider">
                <ngx-slider
                    [value]="newAmountInTiB"
                    [options]="sliderOptions"
                    (valueChange)="newAmountInTiB = $event"
                    (userChangeEnd)="onNewAmountChange($event.value)"
                ></ngx-slider>
            </div>
            <div class="reserve-level-expansion-input">
                <input
                    type="text"
                    numbersOnly
                    [maxlength]="maxLength()"
                    class="form-control"
                    [ngModel]="newAmountInTiB"
                    (ngModelChange)="userInputNewAmount$.next($event)"
                />
                <span class="reserve-level-expansion-unit"> T </span>
                <span class="reserve-level-expansion-percentage">
                    <i class="fa fa-arrow-up text-green"></i>
                    <span>{{ getReserveLevelExpansionPercentage() }}</span>
                </span>
            </div>
        </div>
    </div>

    <div class="license-effective-usage-capacity-chart-container">
        <effective-used-license-forecast
            class="chart-body effective-used-capacity-chart"
            [forecastedLicense]="forecastedLicense"
            [timeRange]="timeRange"
            [simulationEucExpansionInTiB]="newAmountInTiB === reservedAmountInTiB ? 0 : newAmountInTiB"
            [hideSimulatedCapacity]="true"
        >
        </effective-used-license-forecast>
    </div>

    <div class="simulation-summary">
        <div class="simulation-summary-label"> Simulation Summary </div>
        <div class="simulation-summary-container">
            <div class="simulation-summary-item">
                <div class="simulation-summary-item-label gray-text">Reserved</div>
                <span class="gray-text">{{ reservedAmountInTiB }}T</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB">&#8594;</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB" class="simulated-value">{{ newAmountInTiB }}</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB">T</span>
            </div>

            <div class="simulation-summary-item">
                <div class="simulation-summary-item-label gray-text">Current Usage</div>
                <span class="gray-text">{{ getUsagePercentage(reservedAmountInTiB) }}</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB">&#8594;</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB" class="simulated-value">{{
                    getUsagePercentage(newAmountInTiB)
                }}</span>
            </div>

            <div class="simulation-summary-item">
                <div class="simulation-summary-item-label gray-text">Projected Time to On-Demand</div>
                <span class="gray-text">{{ forecastedLicense?.fullInDays | formatFullInDays }}</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB && simulatedOnDemand">&#8594;</span>
                <span *ngIf="newAmountInTiB > reservedAmountInTiB && simulatedOnDemand" class="simulated-value">{{
                    simulatedOnDemand | formatFullInDays
                }}</span>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer btn-toolbar">
    <label class="agreement" for="agreement" *ngIf="supportRequestQuote()">
        <input type="checkbox" id="agreement" name="agreement" [(ngModel)]="agreedOnTermsAndContracts" />I agree to the
        <a href="https://www.purestorage.com/content/dam/pdf/en/legal/pure-enduser-agreement.pdf" target="_blank"
            >End User Agreement.</a
        >
    </label>
    <forecast-license-click-to-quote
        *ngIf="forecastedLicense"
        analyticsPrefix="Forecast - license reserve level expansion modal"
        [disabled]="!agreedOnTermsAndContracts || newAmountInTiB === reservedAmountInTiB || isSaving"
        [customizedTooltip]="getRequestQuoteBtnTooltip()"
        [forecastedLicense]="forecastedLicense"
        [primaryButtonStyle]="true"
        [reservedAmountInTiB]="reservedAmountInTiB"
        [newAmountInTiB]="newAmountInTiB"
    >
    </forecast-license-click-to-quote>
    <button
        type="button"
        class="btn btn-info cancel-button"
        (click)="cancelModal()"
        angulartics2On="click"
        angularticsAction="Forecast - Simulate Reserve Level Modal - Click Cancel"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary apply-button save-button"
        (click)="save()"
        angulartics2On="click"
        angularticsAction="Forecast - Simulate Reserve Level Modal - Click Save"
        [disabled]="isSaving"
    >
        <pureui-spinner [pureuiShow]="isSaving"></pureui-spinner>
        Save
    </button>
</div>
