import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import {
    ExternalUser,
    ExternalUsersService,
    User,
    UsersService,
    CurrentUser,
    DataPage,
    RoleAssignment,
} from '@pure1/data';

import { UserRoleStateService } from '../services/user-role-state.service';
import { SpinnerService } from 'core/src/services/spinner.service';

export type UserType = 'regular' | 'external';

type CreateEditUserStatus = 'form' | 'successCreate' | 'error' | 'confirmDelete' | 'successResetPassword';

@Component({
    selector: 'create-edit-user',
    templateUrl: './create-edit-user.component.html',
    host: {
        class: 'base-create-edit',
    },
})
export class CreateEditUserComponent implements OnChanges {
    // note that when editing, we use usersEndpointUser (defined below) because this user object may be from a
    // different endpoint (/user rather than /users)
    @Input() readonly userAssignment: RoleAssignment;
    @Input() readonly isEdit: boolean;
    @Input() readonly currentUser: CurrentUser;
    @Input() readonly userType: UserType = 'regular';

    // Initialize to empty object so template doesn't explode
    user: User | ExternalUser = {} as User | ExternalUser;
    newExternalUser: ExternalUser;
    status: CreateEditUserStatus = 'form';

    constructor(
        public modalService: NgbModal,
        public urStateService: UserRoleStateService,
        private externalUsersService: ExternalUsersService,
        private usersService: UsersService,
        private spinnerService: SpinnerService,
    ) {}

    ngOnChanges(): void {
        if (this.isEdit) {
            this.urStateService
                .listWithCache(this.isExternalUser() ? this.externalUsersService : this.usersService)
                .pipe(this.spinnerService.spin('createEditUser'))
                .pipe(take(1))
                .subscribe((usersEndpointUsers: DataPage<User | ExternalUser>) => {
                    this.user = usersEndpointUsers.response.find(
                        endpointUser =>
                            endpointUser.email.toLocaleLowerCase() === this.userAssignment.id.toLocaleLowerCase(),
                    );
                    if (!this.user) {
                        console.warn('User not found in list of users');
                    }
                });
        }
    }

    returnToList(): void {
        this.urStateService.deferAssignable = false;
        this.urStateService.deferredAssignable = null;
        this.urStateService.previousDrawerState();
    }

    isSsoUser(): boolean {
        if (this.isCurrentUser(this.user)) {
            return this.currentUser.adUser;
        }
        return false;
    }

    isCurrentUser(user: User | ExternalUser): user is CurrentUser {
        return user.email === this.currentUser.email;
    }

    isExternalUser(): boolean {
        return this.userType === 'external';
    }

    openModal(modal: TemplateRef<NgbActiveModal>): void {
        this.modalService.open(modal);
    }
}
