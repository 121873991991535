import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
    addIdsToRequest,
    BaseBatchMetricsHistoryService,
    getUniqueKeysFromInputs,
    IGetBatchMetricsHistoryParameters,
    IGetBatchMetricsHistoryResult,
} from './base-batch-metrics-history.service';
import {
    MetricsEndpoint,
    ApiCallBatcherInputs,
    IMetricsHistoryOptions,
    IGetMetricsHistoryResult,
} from './metrics-history.service';
import { WINDOW } from '../../app/injection-tokens';

@Injectable({ providedIn: 'root' })
export class BatchDirectoryMetricsHistoryService extends BaseBatchMetricsHistoryService {
    protected apiEndpoint = MetricsEndpoint.directoriesV3;

    constructor(http: HttpClient, @Inject(WINDOW) window: Window) {
        super(http, window);
    }

    getMetricsHistoryTimeseries(
        applianceId: string,
        directoryId,
        options: IMetricsHistoryOptions,
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            applianceId: applianceId,
            directoryId: directoryId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.enqueueBatch(directoryId, apiBatcherInput);
    }

    protected apiBatcherGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.applianceId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    protected apiBatcherExecute(requests: ApiCallBatcherInputs[]): Observable<IGetBatchMetricsHistoryResult> {
        const { startTime, endTime, applianceId, maxPoints, aggregationMethod } = requests[0];
        const uniqueMetricList = getUniqueKeysFromInputs<ApiCallBatcherInputs, 'metricName'>(requests, 'metricName');
        const uniqueIdList = getUniqueKeysFromInputs<ApiCallBatcherInputs, 'directoryId'>(requests, 'directoryId');

        const addIdsToParameters = (idList: string[], params: IGetBatchMetricsHistoryParameters) =>
            addIdsToRequest('directory_ids', idList, params);

        const createCacheKeyFromId = (id: string) =>
            this.createCacheKey(uniqueMetricList, id, startTime, endTime, aggregationMethod);

        const params: IGetBatchMetricsHistoryParameters = {
            appliance_id: applianceId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
            metrics: uniqueMetricList.join(','),
        };

        return this.fetchTimeSeriesData(
            uniqueIdList,
            uniqueMetricList,
            params,
            addIdsToParameters,
            createCacheKeyFromId,
        );
    }
}
