import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '../ui/ui.module';

import { ApiKeysViewComponent } from './api-keys-view/api-keys-view.component';
import { CreateApiKeyModalComponent } from './create-api-key-modal/create-api-key-modal.component';
import { DeleteApiKeyModalComponent } from './delete-api-key-modal/delete-api-key-modal.component';
import { ApiKeysRoutingModule } from './api-keys-routing.module';
import { AuthorizerModule } from '@pure/authz-authorizer';

@NgModule({
    imports: [AuthorizerModule, CommonModule, FormsModule, NgbModule, UIModule, ApiKeysRoutingModule],
    declarations: [ApiKeysViewComponent, CreateApiKeyModalComponent, DeleteApiKeyModalComponent],
})
export class ApiKeyModule {}
