import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder } from '@angular/forms';
import { CachedCurrentUserService } from '@pure1/data';

import { AbstractUpgradePlannerComponent } from '../abstract-upgrade-planner/abstract-upgrade-planner.component';

@Component({
    selector: 'upgrade-planner-generic',
    templateUrl: 'upgrade-planner-generic.component.html',
})
export class UpgradePlannerGenericComponent extends AbstractUpgradePlannerComponent implements OnInit {
    fromDate: NgbDate | null = null;
    toDate: NgbDate | null = null;

    constructor(
        protected fb: UntypedFormBuilder,
        protected cachedCurrentUserService: CachedCurrentUserService,
        protected calendar: NgbCalendar,
    ) {
        super(fb, cachedCurrentUserService, calendar);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    isStart(date: NgbDate): boolean {
        return this.fromDate?.equals(date);
    }

    isEnd(date: NgbDate): boolean {
        return this.toDate?.equals(date);
    }

    isOnly(date: NgbDate): boolean {
        return this.fromDate && !this.toDate && this.fromDate.equals(date);
    }

    onDateSelection(date: NgbDate): void {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            const maxToDate = this.calendar.getNext(this.fromDate, 'd', 14);
            this.toDate = date.after(maxToDate) ? maxToDate : date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    continue(): void {
        this.onForward.emit({
            fromDate: this.ngbDateToMoment(this.fromDate),
            toDate: this.ngbDateToMoment(this.toDate),
            timezone: this.upgradeForm.controls.timezone.value,
            schedule: this.arrays,
        });
    }

    isHovered(date: NgbDate): boolean {
        return (
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            date.after(this.fromDate) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate): boolean {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate): boolean {
        return (
            date.equals(this.fromDate) ||
            (this.toDate && date.equals(this.toDate)) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }
}
