<div
    *ngIf="salesFlow.statusMessage === 'Quote in Progress'; else readOnlyMode"
    class="purchase-order-quote-container"
    (mouseover)="hoverState = true"
    (mouseleave)="hoverState = false"
>
    <div class="top-level-quote-info">
        <div class="row-main-item quote-file-container">
            <pureui-svg class="quote-prefix-icon" [svg]="getQuoteMainIcon()" height="24"></pureui-svg>
            <div class="quote-file-name" (click)="generateUrlAndOpenNewWindow(salesFlow.id, quote.id)">{{
                quote.name
            }}</div>
        </div>
        <div class="row-secondary-item"> {{ quote.netPrice | currency: 'USD' : 'symbol' : '1.0-0' }} </div>
        <div
            *ngIf="showQuoteRowActionButtons(); else showExpirationDate"
            class="row-secondary-item clickable-icon-container"
        >
            <ng-container *ngIf="showEnabledButtons(); else showDisabledButtons">
                <div
                    *ngIf="quote.partnerAcceptance !== 'Accepted'; else showUnAcceptButton"
                    class="outer-container-for-tooltip"
                    [ngbTooltip]="
                        salesFlowHasAnAcceptedQuote ? 'You must undo the acceptance of the other quote' : 'Accept Quote'
                    "
                    placement="top"
                    container="body"
                >
                    <div
                        class="clickable-icon accept-icon"
                        [ngClass]="{ 'icon-disabled': salesFlowHasAnAcceptedQuote }"
                    >
                        <pureui-svg
                            svg="quote-accept-icon.svg"
                            height="24"
                            (click)="quoteAcceptOrReject('Accepted')"
                        ></pureui-svg>
                    </div>
                </div>
                <ng-template #showUnAcceptButton>
                    <div
                        class="clickable-icon unaccept-icon"
                        [ngClass]="{ 'icon-disabled': hasPOfiles }"
                        ngbTooltip="Undo"
                        placement="top"
                        container="body"
                    >
                        <pureui-svg
                            svg="quote-unaccept-icon.svg"
                            height="24"
                            (click)="quoteAcceptOrReject('Unaccepted')"
                        ></pureui-svg>
                    </div>
                </ng-template>
                <div
                    class="clickable-icon negotiate-icon"
                    [ngClass]="{ 'icon-disabled': hasPOfiles || quote.partnerAcceptance === 'Accepted' }"
                    ngbTooltip="Request Changes"
                    placement="top"
                    container="body"
                >
                    <pureui-svg
                        svg="quote-negotiate-icon.svg"
                        height="24"
                        (click)="toggleQuoteExpansionForNegotiation()"
                    ></pureui-svg>
                </div>
                <div
                    class="clickable-icon attach-icon"
                    [ngClass]="{ 'icon-disabled': quote.partnerAcceptance !== 'Accepted' }"
                    ngbTooltip="Attach PO Files"
                    placement="top"
                    container="body"
                >
                    <pureui-svg
                        svg="quote-attach-icon.svg"
                        height="24"
                        (click)="toggleQuoteExpansionForAttachment()"
                    ></pureui-svg>
                </div>
            </ng-container>
            <ng-template #showDisabledButtons>
                <div
                    class="disabled-buttons-container"
                    ngbTooltip="A quote has already been accepted and PO files uploaded. These actions are no longer available."
                    placement="top"
                    container="body"
                >
                    <div class="clickable-icon icon-disabled">
                        <pureui-svg svg="quote-accept-icon.svg" height="24"></pureui-svg>
                    </div>
                    <div class="clickable-icon icon-disabled">
                        <pureui-svg svg="quote-negotiate-icon.svg" height="24"></pureui-svg>
                    </div>
                    <div class="clickable-icon icon-disabled">
                        <pureui-svg svg="quote-attach-icon.svg" height="24"></pureui-svg>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template #showExpirationDate>
            <div class="row-secondary-item">{{ $any(quote.expirationDate) | date: 'yyyy-MM-dd' : 'UTC' }}</div>
        </ng-template>
    </div>
    <div *ngIf="expandedForNegotiation" class="negotiation-input-container">
        <basic-comment-box
            descriptor="Requested Changes:"
            [startingText]="quote.partnerComment?.length > 0 ? quote.partnerComment : ''"
            (textChange)="onTextChange($event)"
        >
        </basic-comment-box>
        <div class="submit-status-message">{{ requestChangeSubmissionStatusMessage }}</div>
        <div class="button-row">
            <button
                class="btn btn-secondary cancel-button"
                [disabled]="doingWork"
                (click)="toggleQuoteExpansionForNegotiation()"
                >{{ getRequestChangeExitButtonLabel() }}</button
            >
            <button
                class="btn btn-primary confirm-button"
                [disabled]="isCommentSubmitDisabled()"
                (click)="quoteAcceptOrReject('Rejected')"
                >Submit</button
            >
        </div>
    </div>
    <div *ngIf="expandedForAttachment" class="attachment-input-container">
        <purchase-order-upload-form
            [quoteAccepted]="quote.partnerAcceptance === 'Accepted'"
            [salesFlow]="salesFlow"
            [quote]="quote"
            (cancelForm)="expandedForAttachment = false"
        >
        </purchase-order-upload-form>
    </div>
    <pureui-spinner
        class="quote-loading"
        [pureuiShow]="doingWork && !expandedForNegotiation && !expandedForAttachment"
    ></pureui-spinner>
</div>

<ng-template #readOnlyMode>
    <div class="purchase-order-quote-container">
        <div class="top-level-quote-info">
            <div class="row-main-item quote-file-container">
                <pureui-svg class="quote-prefix-icon" [svg]="getQuoteMainIcon()" height="24"></pureui-svg>
                <div class="quote-file-name" (click)="generateUrlAndOpenNewWindow(salesFlow.id, quote.id)">{{
                    quote.name
                }}</div>
            </div>
            <div class="row-secondary-item"> {{ quote.netPrice | currency: 'USD' : 'symbol' : '1.0-0' }} </div>
            <div class="row-secondary-item">{{ $any(quote.expirationDate) | date: 'yyyy-MM-dd' : 'UTC' }}</div>
            <pureui-svg
                class="expand-chevron"
                [ngClass]="{ 'rotate-one-eighty': !expandedForAttachment }"
                svg="chevron-up.svg"
                height="15"
                (click)="toggleQuoteExpansionForAttachment()"
            ></pureui-svg>
        </div>
        <div *ngIf="expandedForAttachment" class="attachment-input-container">
            <purchase-order-upload-form
                [quoteAccepted]="quote.partnerAcceptance === 'Accepted'"
                [salesFlow]="salesFlow"
                [quote]="quote"
                (cancelForm)="expandedForAttachment = false"
            >
            </purchase-order-upload-form>
        </div>
    </div>
</ng-template>
