import { Component, OnInit } from '@angular/core';
import { FeatureFlagDxpService, UnifiedArrayService } from '@pure1/data';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { FeatureNames } from '../../../model/FeatureNames';

@Component({
    selector: 'eol-warning-banner',
    templateUrl: 'eol-warning-banner.component.html',
})
export class EOLWarningBannerComponent implements OnInit {
    hasEOL = false;

    constructor(
        private unifiedArrayService: UnifiedArrayService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(
                switchMap(feature => {
                    if (!feature?.enabled) {
                        return of(null);
                    } else {
                        return this.unifiedArrayService.list({ fields: ['has_end_of_life_hardware'] });
                    }
                }),
                take(1),
            )
            .subscribe(data => {
                this.hasEOL = data?.response.some(array => array.has_end_of_life_hardware);
            });
    }

    getHwEolFilters(): string {
        return JSON.stringify([{ entity: 'arrays', key: 'has_end_of_life_hardware', value: 'true' }]);
    }
}
