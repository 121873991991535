import { DraasApiProtectionGroupExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryJobExecution } from './disaster-recovery-job-execution';

export class DisasterRecoveryProtectionGroupExecution extends DisasterRecoveryJobExecution {
    constructor(json: DraasApiProtectionGroupExecutionInfo) {
        super(json);
        this.clusterId = json.protection_group.cluster_id;
        this.name = json.protection_group.name;
        this.policy = json.protection_group.service_level_policy_name;
        this.hasPreConversion = json.protection_group.has_cloud_pre_conversion;
        this.sourceProviderId = json.protection_group.source_provider_id;
    }
}
