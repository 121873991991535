import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BadgeModule, ButtonComponent, ProgressBarComponent, SvgDirective, TooltipModule } from '@pure/hive';

import { StatelessSubmenuBarComponent } from './components/stateless-submenu-bar/stateless-submenu-bar.component';
import { AutocompleteDirective } from './directives/autocomplete.directive';
import { AutocompleteMatchComponent } from './components/autocomplete-match.component';
import { AutocompleteMenuComponent } from './directives/autocomplete-menu.component';

//components
import { AggregationOptionComponent } from './components/aggregation-option/aggregation-option.component';
import { AggregationViewNavComponent } from './components/aggregation-view-nav/aggregation-view-nav.component';
import { AlertIndicatorComponent } from './components/alert-indicator/alert-indicator.component';
import { ApplianceModelImageComponent } from './components/appliance-model-image/appliance-model-image.component';
import { ArrayAlertsPopoverComponent } from './components/array-alerts-popover/array-alerts-popover.component';
import { BannersComponent } from './components/banners/banners.component';
import { CalendarTimeRangeSelectComponent } from './components/calendar-time-range-select/calendar-time-range-select.component';
import { CapacityDialComponent } from './components/capacity-dial/capacity-dial.component';
import { CapacityDownLicensingIconComponent } from './components/capacity-down-licensing-icon/capacity-down-licensing-icon.component';
import { CapacityProgressBarComponent } from './components/capacity-progress-bar/capacity-progress-bar.component';
import { CategorizedSubmenuBarComponent } from './components/categorized-submenu-bar/categorized-submenu-bar.component';
import { ContractStatusIconComponent } from './components/contract-status-icon/contract-status-icon.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { CopyToClipboardHoverComponent } from './components/copy-to-clipboard-hover/copy-to-clipboard-hover.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { DrawerWithMenuComponent } from './components/drawer-with-menu/drawer-with-menu.component';
import { EntitySelectionDropdownComponent } from './components/entity-selection-dropdown.component';
import { EOLWarningBannerComponent } from './components/eol-warning-banner/eol-warning-banner.component';
import { EolHwPopoverCardComponent } from './components/eol-hw-popover-card/eol-hw-popover-card.component';
import { HorizontalResizeComponent } from './components/resize/horizontal-resize.component';
import { ImpersonatingBannerComponent } from './components/impersonating-banner/impersonating-banner.component';
import { InputPhoneComponent } from './components/input-phone.component';
import { LogOffLinkComponent } from './components/log-off-link.component';
import { ModelBezelComponent } from './components/proactive-recommendation-card/model-bezel/model-bezel.component';
import { NgxD3LiquidFillGaugeComponent } from './components/ngx-d3-liquid-fill-gauge.component';
import { OrgFilterSelectorComponent } from './components/org-filter-selector/org-filter-selector.component';
import { PaginationComponent } from './components/pagination.component';
import { PlacementRecommendationDegreeIconComponent } from './components/placement-recommendation-degree-icon/placement-recommendation-degree-icon.component';
import { PlacementWarningsPopoverComponent } from './components/placement-warnings-popover/placement-warnings-popover.component';
import { ProactiveRecommendationCardComponent } from './components/proactive-recommendation-card/proactive-recommendation-card.component';
import { PureAvatarComponent } from './components/pure-avatar/pure-avatar.component';
import { PureSvgComponent } from './components/pure-svg.component';
import { PureToastComponent } from './components/pure-toast/pure-toast.component';
import { PureInfoIconComponent } from './components/pure-info-icon.component';
import { PureRadialDialComponent } from './components/pure-radial-dial/pure-radial-dial.component';
import { PureuiReportingIconComponent } from './components/pureui-reporting-icon.component';
import { RequestConfirmedModalComponent } from './components/request-confirmed-modal/request-confirmed-modal.component';
import { RequestedStatusComponent } from './components/requested-status/requested-status.component';
import { SafemodeShieldIconComponent } from './components/safemode-shield-icon/safemode-shield-icon.component';
import { ScrollStickyTopComponent } from './components/scroll-sticky-top/scroll-sticky-top.component';
import { SlaStatusComponent } from './components/sla-status/sla-status.component';
import { SlaStatusIconComponent } from './components/sla-status-icon/sla-status-icon.component';
import { SpinnerComponent } from './components/spinner.component';
import { SubmenuBarComponent } from './components/submenu-bar/submenu-bar.component';
import { TimeRangePipe } from './components/time-range-selector/time-range.pipe';
import { TimeRangeSelectorComponent } from './components/time-range-selector/time-range-selector.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { PartnerInfoComponent } from './components/partner-info/partner-info.component';
import { ProtectionGaugeComponent } from './components/protection-gauge.component';
import { PureCarouselComponent } from './components/pure-carousel/pure-carousel.component';
import { RecommendationSparklineComponent } from './components/proactive-recommendation-card/recommendation-sparkline/recommendation-sparkline.component';
import { VerticalResizeComponent } from './components/resize/vertical-resize.component';
import { PerformanceTableComponent } from './components/performance-table/performance-table.component';
import { ViewFilterSelectorComponent } from './components/view-filter-selector/view-filter-selector.component';
import { ModifyPartnerModalComponent } from './components/partner-info/modify-partner-modal/modify-partner-modal.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';

//directives
import { ArrayHrefDirective } from './directives/array-href.directive';
import { ArrayNameTooltipDirective } from './directives/array-name-tooltip.directive';
import { BlurOnClickDirective } from './directives/blur-on-click.directive';
import { DebounceChangeDirective } from './directives/debounce-change.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DropdownHoverDirective } from './directives/dropdown-hover.directive';
import { FixDisabledTooltipDirective } from './directives/fix-disabled-tooltip.directive';
import { InputAutoresizeDirective } from './directives/input-autoresize.directive';
import { InputNumberOnlyDirective } from './directives/input-number-only.directive';
import { MapViewDirective } from './components/map-view/map-view.directive';
import { PureReadOnlyDirective } from './directives/pure-read-only.directive';
import { ShadowScrollDirective } from './directives/shadow-scroll.directive';
import { FadeOutScrollDirective } from './directives/fade-out-scroll.directive';
import { ValidateHrefDirective } from './directives/validate-href/validate-href.directive';
import { AuthzPermissionsDirective } from './directives/authz-permissions.directive';
import { NgSelectModule } from '@ng-select/ng-select';

//pipes
import { AlertIndicatorStringPipe } from './pipes/alert-indicator-string.pipe';
import { DayOfMonthPipe } from './pipes/day-of-month.pipe';
import { DefaultStringPipe } from './pipes/default-string.pipe';
import { DomainPipe } from './pipes/domain.pipe';
import { FromNowPipe } from './pipes/from-now.pipe';
import { FormatClusterUsagePipe } from './pipes/format-cluster-usage.pipe';
import { FormatDataReductionPipe } from './pipes/format-data-reduction.pipe';
import { FormatDurationPipe } from './pipes/format-duration.pipe';
import { FormatFullInDaysPipe } from './pipes/format-full-in-days.pipe';
import { FormatIOPSPipe } from './pipes/format-IOPS.pipe';
import { FormatLatencyPipe } from './pipes/format-latency.pipe';
import { FormatLoadPipe } from './pipes/format-load.pipe';
import { FormatPercentPipe } from './pipes/format-percent.pipe';
import { FormatReplicationLagPipe } from './pipes/format-replication-lag.pipe';
import { FormatReplicationLastUpdatedPipe } from './pipes/format-replication-last-updated.pipe';
import { FormatReplicationRecoveryPointPipe } from './pipes/format-replication-recovery-point.pipe';
import { FormatReplicationStatusPipe } from './pipes/format-replication-status.pipe';
import { FormatSizePipe } from './pipes/format-size.pipe';
import { FormatSizeTotalDigitsPipe } from './pipes/format-size-total-digits.pipe';
import { FormatSubscriptionDataPipe } from './pipes/format-subscription-data.pipe';
import { FormatTiBPipe } from './pipes/format-tib.pipe';
import { GetMetricGroupDrilldownParamsPipe } from './pipes/get-metric-group-drilldown-params.pipe';
import { HideKeyPipe } from './pipes/hide-key.pipe';
import { HourOfDayPipe } from './pipes/hour-of-day.pipe';
import { HumanizeGranularityPipe } from './pipes/humanize-granularity.pipe';
import { InvoiceReportTypePipe } from './pipes/invoice-report-type.pipe';
import { LicenseReservedUnitPipe } from './pipes/license-reserved-unit.pipe';
import { ListPipe } from './pipes/list.pipe';
import { MemberItemDisplayPipe } from './pipes/member-item-display.pipe';
import { MemberLocationDisplayPipe } from './pipes/member-location-display.pipe';
import { MemoizeFncPipe } from './pipes/memoize-fnc.pipe';
import { ParenthesizePipe } from './pipes/parenthesize.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { PodPromotionStatusPipe } from './pipes/pod-promotion-status.pipe';
import { PodPromotionSvgPipe } from './pipes/pod-promotion-svg.pipe';
import { PodStatusPipe } from './pipes/pod-status.pipe';
import { ProductShortNamePipe } from './pipes/product-short-name.pipe';
import { SnapshotStatusPipe } from './pipes/snapshot-status.pipe';
import { SubscriptionReportTypePipe } from './pipes/subscription-report-type.pipe';
import { SynchronizedChartTooltipsDirective } from './directives/synchronized-chart-tooltips.directive';
import { GroupByPipe } from './pipes/group-by.pipe';
import { FormatApproxDurationPipe } from './pipes/format-approx-duration.pipe';
import { FlashTypePipe } from './pipes/flash-type.pipe';
import { TabGroup } from './directives/tab-group.directive';
import { TabName } from './directives/tab-name.directive';
import { TrackByPropertyPipe } from './pipes/track-by-property.pipe';
import { TruncatePercentPipe } from './pipes/truncate-percent.pipe';
import { PureNumberedStepsComponent } from './components/pure-numbered-steps.component';
import { SubscriptionMetricPipe } from './pipes/subscription-metric.pipe';
import { RoleTypePipe } from './pipes/role-type.pipe';
import { BasicCommentBoxComponent } from './components/basic-comment-box/basic-comment-box.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { SidePanelTabDirective } from './components/side-panel/side-panel-tab/side-panel-tab.directive';
import { IncidentIndicatorComponent } from './components/incident-indicator/incident-indicator.component';
import { ObfuscatePhoneNumberPipe } from './pipes/obfuscate-phone-number.pipe';
import { FormatPlannerActionTooltipPipe } from './pipes/format-planner-action-tooltip.pipe';
import { FormatPreRatioPipe } from './pipes/format-pre-ratio.pipe';
import { LicenseUsageComponent } from './components/license-usage/license-usage.component';
import { HoneycombChartModule } from './honeycomb-chart/honeycomb-chart.module';
import { CollapsePanelComponent } from './components/collapse-panel/collapse-panel.component';
import { FormatBandwidthPerCapacityPipe } from './pipes/format-bandwidth-per-capacity.pipe';
import { Moment2DateNormalizePipe } from '../software-lifecycle/purity-upgrades/pipes/moment-date-normalize.pipe';
import { FormatJobExecutionDurationPipe } from './pipes/format-job-execution-duration.pipe';
import { ExpandableDropdownComponent } from './components/expandable-dropdown/expandable-dropdown.component';
import { FormatEnergyUsagePipe } from './pipes/format-energy-usage.pipe';
import { AlertIndicatorIconDataPipe } from './pipes/alert-indicator-icon-data.pipe';
import { ArrayHrefTooltipPipe } from './pipes/array-href-tooltip.pipe';
import { IsNaNPipe } from './pipes/is-NaN.pipe';
import { AsyncSpinnerPipe } from './pipes/async-spinner.pipe';
import { OnlyRouterOutletComponent } from './components/router-outlet.component';
import { RouterModule } from '@angular/router';
import { HighlightPipe } from './pipes/highlight.pipe';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { FeaturePolicyDirective } from '@pure1/data';
import { EverModernEligibilityComponent } from './components/ever-modern-eligibility/ever-modern-eligibility.component';

const components = [
    AggregationOptionComponent,
    AggregationViewNavComponent,
    AlertIndicatorComponent,
    AlertIndicatorStringPipe,
    AlertIndicatorIconDataPipe,
    ApplianceModelImageComponent,
    ArrayAlertsPopoverComponent,
    ArrayHrefDirective,
    ArrayHrefTooltipPipe,
    ArrayNameTooltipDirective,
    AutocompleteDirective,
    AutocompleteMatchComponent,
    AutocompleteMenuComponent,
    BannersComponent,
    BlurOnClickDirective,
    CalendarTimeRangeSelectComponent,
    CapacityDialComponent,
    CapacityDownLicensingIconComponent,
    CapacityProgressBarComponent,
    CategorizedSubmenuBarComponent,
    ContractStatusIconComponent,
    CopyToClipboardComponent,
    CopyToClipboardHoverComponent,
    CountdownTimerComponent,
    CollapsePanelComponent,
    DayOfMonthPipe,
    DebounceChangeDirective,
    DefaultStringPipe,
    ArrayHrefTooltipPipe,
    DomainPipe,
    DrawerComponent,
    DrawerWithMenuComponent,
    FormatApproxDurationPipe,
    DragAndDropDirective,
    DropdownHoverDirective,
    EntitySelectionDropdownComponent,
    EOLWarningBannerComponent,
    EverModernEligibilityComponent,
    ExpandableDropdownComponent,
    EolHwPopoverCardComponent,
    FadeOutScrollDirective,
    FormatBandwidthPerCapacityPipe,
    FormatClusterUsagePipe,
    FormatDataReductionPipe,
    FormatDurationPipe,
    FormatEnergyUsagePipe,
    FormatFullInDaysPipe,
    FormatIOPSPipe,
    FormatLatencyPipe,
    FormatLoadPipe,
    FormatPercentPipe,
    FormatReplicationLagPipe,
    FormatReplicationLastUpdatedPipe,
    FormatReplicationRecoveryPointPipe,
    FormatReplicationStatusPipe,
    FormatPlannerActionTooltipPipe,
    FormatSizePipe,
    FormatSizeTotalDigitsPipe,
    FormatSubscriptionDataPipe,
    FormatTiBPipe,
    FromNowPipe,
    GetMetricGroupDrilldownParamsPipe,
    GroupByPipe,
    HideKeyPipe,
    HighlightPipe,
    HorizontalResizeComponent,
    HourOfDayPipe,
    HumanizeGranularityPipe,
    ImpersonatingBannerComponent,
    IncidentIndicatorComponent,
    InputAutoresizeDirective,
    InputNumberOnlyDirective,
    InputPhoneComponent,
    InvoiceReportTypePipe,
    IsNaNPipe,
    LicenseReservedUnitPipe,
    LicenseUsageComponent,
    ListPipe,
    LogOffLinkComponent,
    MapViewDirective,
    MemberItemDisplayPipe,
    MemberLocationDisplayPipe,
    NgxD3LiquidFillGaugeComponent,
    ModelBezelComponent,
    ModifyPartnerModalComponent,
    ObfuscatePhoneNumberPipe,
    OrgFilterSelectorComponent,
    OnlyRouterOutletComponent,
    PaginationComponent,
    ParenthesizePipe,
    PerformanceTableComponent,
    PlacementRecommendationDegreeIconComponent,
    PlacementWarningsPopoverComponent,
    PluralizePipe,
    PodPromotionStatusPipe,
    PodPromotionSvgPipe,
    PodStatusPipe,
    ProductShortNamePipe,
    FormatPreRatioPipe,
    ProtectionGaugeComponent,
    ProactiveRecommendationCardComponent,
    PureRadialDialComponent,
    RequestConfirmedModalComponent,
    RequestedStatusComponent,
    FixDisabledTooltipDirective,
    PureReadOnlyDirective,
    PureAvatarComponent,
    PureCarouselComponent,
    BasicCommentBoxComponent,
    PartnerInfoComponent,
    PureInfoIconComponent,
    PureNumberedStepsComponent,
    PureSvgComponent,
    PureToastComponent,
    PureuiReportingIconComponent,
    RecommendationSparklineComponent,
    RoleTypePipe,
    SafemodeShieldIconComponent,
    ScrollStickyTopComponent,
    ShadowScrollDirective,
    SidePanelComponent,
    SidePanelTabDirective,
    SlaStatusComponent,
    SlaStatusIconComponent,
    SnapshotStatusPipe,
    SpinnerComponent,
    StatelessSubmenuBarComponent,
    SubmenuBarComponent,
    SubscriptionMetricPipe,
    SubscriptionReportTypePipe,
    TabGroup,
    TabName,
    TimeRangePipe,
    TimeRangeSelectorComponent,
    TrackByPropertyPipe,
    TruncatePercentPipe,
    FlashTypePipe,
    TooltipDirective,
    SynchronizedChartTooltipsDirective,
    ValidateHrefDirective,
    VerticalResizeComponent,
    ViewFilterSelectorComponent,
    MemoizeFncPipe,
    Moment2DateNormalizePipe,
    FormatJobExecutionDurationPipe,
    AuthzPermissionsDirective,
    AsyncSpinnerPipe,
    ProgressSpinnerComponent,
    FeaturePolicyDirective,
];

@NgModule({
    imports: [
        AuthorizerModule,
        Angulartics2Module,
        ButtonComponent,
        CommonModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        HoneycombChartModule,
        ProgressBarComponent,
        BadgeModule,
        RouterModule,
        SvgDirective,
        TooltipModule,
    ],
    declarations: components,
    exports: [...components, HoneycombChartModule],
})
export class UIModule {}
