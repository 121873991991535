import moment from 'moment';
import { Timeslot } from './support-upgrade/types';
import {
    CaseCategory,
    CaseSeverity,
    CaseSubCategory,
    FreeTime,
    ProductLine,
    SubCategoryOption,
    SupportCase,
} from './support.interface';

export const SUB_CATEGORY_GROUP = {
    FlashArray: ProductLine.FlashArray,
    FlashBlade: ProductLine.FlashBlade,
    Portworx: ProductLine.Portworx,
    Pure1: 'Pure1',
    EvergreenOne: 'Evergreen//One and //Flex',
    DisasterRecovery: 'Pure Protect //DRaaS',
    Others: 'Other',
};

const FLASH_ARRAY_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.FlashArray,
        displayName: 'Hardware',
        subCategory: CaseSubCategory.HARDWARE_HARDWARE,
        productLine: ProductLine.FlashArray,
    },
    {
        group: SUB_CATEGORY_GROUP.FlashArray,
        displayName: 'Software',
        subCategory: CaseSubCategory.HARDWARE_SOFTWARE,
        productLine: ProductLine.FlashArray,
    },
    {
        group: SUB_CATEGORY_GROUP.FlashArray,
        displayName: 'Environmental',
        subCategory: CaseSubCategory.ENVIRONMENTALS_NON_SPECIFIC,
        productLine: ProductLine.FlashArray,
    },
];

const FLASH_BLADE_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.FlashBlade,
        displayName: 'Hardware',
        subCategory: CaseSubCategory.HARDWARE_HARDWARE,
        productLine: ProductLine.FlashBlade,
    },
    {
        group: SUB_CATEGORY_GROUP.FlashBlade,
        displayName: 'Software',
        subCategory: CaseSubCategory.HARDWARE_SOFTWARE,
        productLine: ProductLine.FlashBlade,
    },
    {
        group: SUB_CATEGORY_GROUP.FlashBlade,
        displayName: 'Environmental',
        subCategory: CaseSubCategory.ENVIRONMENTALS_NON_SPECIFIC,
        productLine: ProductLine.FlashBlade,
    },
];

const PORTWORX_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.Portworx,
        displayName: 'Software',
        subCategory: CaseSubCategory.HARDWARE_SOFTWARE,
        productLine: ProductLine.Portworx,
    },
    {
        group: SUB_CATEGORY_GROUP.Portworx,
        displayName: 'Environmental',
        subCategory: CaseSubCategory.ENVIRONMENTALS_NON_SPECIFIC,
        productLine: ProductLine.Portworx,
    },
];

const PURE1_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.Pure1,
        displayName: 'Fleet',
        subCategory: CaseSubCategory.ADMINISTRATIVE_SUBSCRIPTION_ONBOARD_OFFBOARD,
        productLine: ProductLine.None,
    },
    {
        group: SUB_CATEGORY_GROUP.Pure1,
        displayName: 'User & Security',
        subCategory: CaseSubCategory.ADMINISTRATIVE_ACCOUNT_ADJUSTMENT,
        productLine: ProductLine.None,
    },
    {
        group: SUB_CATEGORY_GROUP.Pure1,
        displayName: 'Software',
        subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
        productLine: ProductLine.None,
    },
];

const EVERGREEN_ONE_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.EvergreenOne,
        displayName: 'Usage & Billing',
        subCategory: CaseSubCategory.USAGE_AND_BILLING,
        productLine: ProductLine.None,
    },
    {
        group: SUB_CATEGORY_GROUP.EvergreenOne,
        displayName: 'Renewal & Expansions',
        subCategory: CaseSubCategory.RENEWALS_AND_EXPANSIONS,
        productLine: ProductLine.None,
    },
    {
        group: SUB_CATEGORY_GROUP.EvergreenOne,
        displayName: 'License',
        subCategory: CaseSubCategory.LICENSE_MANAGEMENT,
        productLine: ProductLine.None,
    },
    {
        group: SUB_CATEGORY_GROUP.EvergreenOne,
        displayName: 'Hardware',
        subCategory: CaseSubCategory.HARDWARE_MANAGEMENT,
        productLine: ProductLine.None,
    },
    {
        group: SUB_CATEGORY_GROUP.EvergreenOne,
        displayName: 'SLA',
        subCategory: CaseSubCategory.SLA_MANAGEMENT_REQUEST_SLA_CREDITS,
        productLine: ProductLine.None,
    },
];

const DISASTER_RECOVERY_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.DisasterRecovery,
        displayName: 'Usage & Billing',
        subCategory: CaseSubCategory.ADMINISTRATIVE_ACCOUNT_ADJUSTMENT,
        productLine: ProductLine.DisasterRecovery,
    },
    {
        group: SUB_CATEGORY_GROUP.DisasterRecovery,
        displayName: 'Install & Config',
        subCategory: CaseSubCategory.ADMINISTRATIVE_ACCOUNT_ADJUSTMENT,
        productLine: ProductLine.DisasterRecovery,
    },
    {
        group: SUB_CATEGORY_GROUP.DisasterRecovery,
        displayName: 'Licence',
        subCategory: CaseSubCategory.SOFTWARE_REPLICATION,
        productLine: ProductLine.DisasterRecovery,
    },
    {
        group: SUB_CATEGORY_GROUP.DisasterRecovery,
        displayName: 'Backup',
        subCategory: CaseSubCategory.SOFTWARE_REPLICATION,
        productLine: ProductLine.DisasterRecovery,
    },
    {
        group: SUB_CATEGORY_GROUP.DisasterRecovery,
        displayName: 'Recovery',
        subCategory: CaseSubCategory.SOFTWARE_REPLICATION,
        productLine: ProductLine.DisasterRecovery,
    },
];

const OTHER_GROUP = [
    {
        group: SUB_CATEGORY_GROUP.Others,
        displayName: 'Other',
        subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
        productLine: ProductLine.Others,
    },
];

export const SUB_CATEGORY_OPTIONS: SubCategoryOption[] = [
    ...FLASH_ARRAY_GROUP,
    ...FLASH_BLADE_GROUP,
    ...PORTWORX_GROUP,
    ...PURE1_GROUP,
    ...EVERGREEN_ONE_GROUP,
    ...DISASTER_RECOVERY_GROUP,
    ...OTHER_GROUP,
];

type SubCategoryOptionsConfig = {
    evergreen?: boolean;
    flashArray?: boolean;
    flashBlade?: boolean;
    other?: boolean;
    portworx?: boolean;
    pure1?: boolean;
    draas?: boolean;
};

export const SUB_CATEGORY_OPTION_MAP = new Map<keyof SubCategoryOptionsConfig, SubCategoryOption[]>([
    ['evergreen', EVERGREEN_ONE_GROUP],
    ['flashArray', FLASH_ARRAY_GROUP],
    ['flashBlade', FLASH_BLADE_GROUP],
    ['other', OTHER_GROUP],
    ['portworx', PORTWORX_GROUP],
    ['pure1', PURE1_GROUP],
    ['draas', DISASTER_RECOVERY_GROUP],
]);

export function subCategoryOptionsFactory(config: SubCategoryOptionsConfig): SubCategoryOption[] {
    const options: SubCategoryOption[] = [];

    for (const [key, optionIncluded] of Object.entries(config)) {
        if (optionIncluded) {
            options.push(...SUB_CATEGORY_OPTION_MAP.get(key as any));
        }
    }

    return options;
}

export const getSubCategoryOption = (productLine: ProductLine, subCategory: CaseSubCategory): SubCategoryOption => {
    return SUB_CATEGORY_OPTIONS.find(item => item.productLine === productLine && item.subCategory === subCategory);
};

export const getCategoryForSubcategory = (subcategory: CaseSubCategory): CaseCategory => {
    switch (subcategory) {
        case CaseSubCategory.ADMINISTRATIVE_SUBSCRIPTION_ONBOARD_OFFBOARD:
        case CaseSubCategory.ADMINISTRATIVE_ACCOUNT_ADJUSTMENT:
        case CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY:
            return CaseCategory.ADMINISTRATIVE;

        case CaseSubCategory.ENVIRONMENTALS_MICROSOFT:
        case CaseSubCategory.ENVIRONMENTALS_VM_WARE_VIRTUALIZATION:
        case CaseSubCategory.ENVIRONMENTALS_NIX:
        case CaseSubCategory.ENVIRONMENTALS_CLOUD:
        case CaseSubCategory.ENVIRONMENTALS_DATABASE:
        case CaseSubCategory.ENVIRONMENTALS_CONTAINERS:
        case CaseSubCategory.ENVIRONMENTALS_FILE_SHARING:
        case CaseSubCategory.ENVIRONMENTALS_FC:
        case CaseSubCategory.ENVIRONMENTALS_ETHERNET:
            return CaseCategory.ENVIRONMENTALS;

        case CaseSubCategory.HARDWARE_SOFTWARE:
        case CaseSubCategory.HARDWARE_REPLICATION:
        case CaseSubCategory.HARDWARE_PLUGINS:
        case CaseSubCategory.HARDWARE_SPACE:
        case CaseSubCategory.HARDWARE_PERFORMANCE:
        case CaseSubCategory.HARDWARE_HARDWARE:
            return CaseCategory.HARDWARE;

        case CaseSubCategory.SOFTWARE_SOFTWARE:
        case CaseSubCategory.SOFTWARE_REPLICATION:
        case CaseSubCategory.SOFTWARE_PLUGINS:
        case CaseSubCategory.SOFTWARE_SPACE:
        case CaseSubCategory.SOFTWARE_PERFORMANCE:
        case CaseSubCategory.SOFTWARE_HARDWARE:
        case CaseSubCategory.SOFTWARE_EDGE_SERVICES:
            return CaseCategory.SOFTWARE;

        case CaseSubCategory.UPGRADE_SOFTWARE_UPGRADE:
        case CaseSubCategory.UPGRADE_HARDWARE_UPGRADE:
            return CaseCategory.UPGRADE;

        case CaseSubCategory.HARDWARE_MANAGEMENT:
        case CaseSubCategory.LICENSE_MANAGEMENT:
        case CaseSubCategory.RENEWALS_AND_EXPANSIONS:
        case CaseSubCategory.SLA_MANAGEMENT_REQUEST_SLA_CREDITS:
        case CaseSubCategory.USAGE_AND_BILLING:
            return CaseCategory.EVERGREENONEADMINISTRATION;

        case CaseSubCategory.DEPLOYMENT_ACTIVE_CLUSTER:
        case CaseSubCategory.DEPLOYMENT_SAFEMODE:
        case CaseSubCategory.DEPLOYMENT_VM_ANALYTICS:
        case CaseSubCategory.DEPLOYMENT_VVOLS:
        case CaseSubCategory.DEPLOYMENT_WFS:
        case CaseSubCategory.DEPLOYMENT_FILE_SERVICE:
        case CaseSubCategory.DEPLOYMENT_CLOUD_BLOCK_STORE:
        case CaseSubCategory.DEPLOYMENT_PURITY_MIGRATION:
        case CaseSubCategory.DEPLOYMENT_FIELD_WIPE:
        case CaseSubCategory.DEPLOYMENT_TUNABLE:
        case CaseSubCategory.DEPLOYMENT_HARDWARE_ORDER:
            return CaseCategory.DEPLOYMENT;

        default: {
            console.warn(`Unknown Case SubCategory of ${subcategory}, returning ENVIRONMENTALS instead`);
            return CaseCategory.ENVIRONMENTALS; // For non specific subcategory we should return environmental (discussed with support)
        }
    }
};

// Exported to unit test...
export function splitUpSlots(freetimes: FreeTime[], duration: moment.Duration, interval: moment.Duration): Timeslot[] {
    const result: Timeslot[] = [];
    for (let i = 0; i < freetimes.length; i++) {
        const freeTime = freetimes[i];
        const end = moment(freeTime.end).subtract(duration);

        for (const start = moment(freeTime.start); start.isSameOrBefore(end); start.add(interval)) {
            result.push({
                startTime: moment(start),
                duration: duration,
                capacity: freeTime.capacity,
            });
        }
    }
    return result;
}

export const REQUEST_SSO_CASE: Partial<SupportCase> = {
    subject: 'Request for Single Sign-On (SSO)',
    description: `We'd like to request single sign-on capability for our arrays.`,
    productLine: ProductLine.Others,
    subCategory: CaseSubCategory.ADMINISTRATIVE_GENERAL_INQUIRY,
    severity: CaseSeverity.MINOR,
};
