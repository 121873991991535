import { RouterModule, Routes } from '@angular/router';
import { UserGroupsViewComponent } from './user-groups-view/user-groups-view.component';
import { NgModule } from '@angular/core';
import { hasPermissionGuard } from '../app/guards/permission-guard';

const routes: Routes = [
    {
        path: '',
        component: UserGroupsViewComponent,
        title: 'Users',
        canActivate: [hasPermissionGuard('PURE1:read:users')],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoleManagementRoutingModule {}
