<div class="step-numbers">
    <div class="step-number" *ngFor="let step of steps; index as i; trackBy: stepTrackBy">
        <span class="step-number-description" [ngClass]="{ active: step === currentStep }">{{ i + 1 }}</span>
        <span style="font-weight: bold"> {{ step }} </span>
        <pureui-svg
            *ngIf="i != steps.length - 1"
            class="step-number-next"
            [svg]="'chevron-right.svg'"
            [height]="10"
        ></pureui-svg>
    </div>
</div>
