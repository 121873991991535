import moment from 'moment-timezone';
import { Angulartics2 } from 'angulartics2';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WizardComponent } from '@achimha/angular-archwizard';
import { catchError, map, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
    CachedCurrentUserService,
    Contact,
    ContactsService,
    CurrentUser,
    DataPage,
    DpaSafeMode,
    DpaSafeModeService,
    Product,
    UnifiedArray,
    UnifiedArrayService,
} from '@pure1/data';

import { Schedule, ScheduledArray } from '../../safemode-scheduler-common/types';
import { forString } from '../../../utils/comparator';
import { isArraySafeModeFullyEnabled, isUpgradeRequired } from '../../util';
import { SafeModeSchedulerService } from '../../services/safemode-scheduler.service';
import { DpaSafeModeUtils } from '../../../utils/dpa-safe-mode-utils';
import { Case, FreeTime, ProductLine } from '../../../support/support.interface';
import { SupportContactService } from '../../../support/services/support-contact.service';
import { splitUpSlots } from '../../../support/support.utils';

function getDayAsMoment(day: NgbDateStruct, timezone: string): moment.Moment {
    return moment.tz(
        {
            year: day.year,
            month: day.month - 1,
            day: day.day,
        },
        timezone,
    );
}

const TIMESLOT_INTERVAL = moment.duration(60, 'minutes');

@Component({
    selector: 'safemode-scheduler',
    templateUrl: 'safemode-scheduler.component.html',
})
export class SafeModeSchedulerComponent implements OnDestroy {
    @ViewChild(WizardComponent) wizard: WizardComponent;
    onCaseCreated = new Subject<void>();

    ProductLine = ProductLine;

    currentStepIndex = 0;
    allArrays: UnifiedArray[] = []; // list of all arrays available to schedule
    arraysFilteredByProduct: UnifiedArray[] = []; // available arrays after Product Line is selected
    arraysToEnable: UnifiedArray[] = [];
    initialArrays: string[] = [];
    isEnablement: boolean | null = true; // If null, we do not filter out based on the SafeMode status
    selectedProductLine: ProductLine;
    schedule: Schedule; // the selected schedule

    loading = false;
    submitting = false;
    submitted = false;
    shouldLoadTimeslots = false;
    createCaseDoneFlag = false;
    createCaseErrorFlag = false;
    timeslotsChecking = false;
    timeslotsConflictFlag = false;
    hasSNOWContact = false;
    isSNOWInitialized = false;

    caseId: string;
    userId = '';
    userEmail = '';

    // angulartics variables
    ANALYTICS_PREFIX = 'Assessment - SafeMode - Scheduler - ';
    startTime: number;

    freeTimeUpdates: {
        freeTimes: FreeTime[];
        conflictingArrays: ScheduledArray[];
    };

    constructor(
        private angulartics: Angulartics2,
        private cachedCurrentUserService: CachedCurrentUserService,
        private unifiedArrayService: UnifiedArrayService,
        private contactsService: ContactsService,
        private supportContactService: SupportContactService,
        private scheduleService: SafeModeSchedulerService,
        private activeModal: NgbActiveModal,
        private dpaSafeModeService: DpaSafeModeService,
        protected calendar: NgbCalendar,
    ) {}

    initialize(): void {
        this.loading = true;
        this.startTime = moment().valueOf();
        const arraysRequest$ = this.getArrays();
        const usersRequests$ = this.getUserInfo();
        const safeMode$ = this.dpaSafeModeService.list().pipe(
            take(1),
            catchError(error => {
                const errorMsg = error && (error.data?.message || error.statusText);
                console.error('Error updating safemode: ' + errorMsg);
                return of<DataPage<DpaSafeMode>>(null);
            }),
            map(value => value?.response),
        );

        forkJoin([usersRequests$, arraysRequest$, safeMode$]).subscribe(([_, __, safeMode]) => {
            this.allArrays = DpaSafeModeUtils.getArraysWithDpaSafeMode(this.allArrays, safeMode || []);

            // preselect the right product line (assuming all initial arrays are the same product)
            // use the initial array product line, otherwise use FA
            if (this.initialArrays.length) {
                const preselectedArray = this.allArrays.find(array => array.id === this.initialArrays[0]);
                if (preselectedArray) {
                    this.selectedProductLine =
                        preselectedArray.product === Product.FA ? ProductLine.FlashArray : ProductLine.FlashBlade;
                } else {
                    this.selectedProductLine = ProductLine.FlashBlade;
                }
            } else {
                this.selectedProductLine = ProductLine.FlashBlade;
            }
            // filter available arrays accordingly
            this.arraysFilteredByProduct = this.allArrays.filter(array => {
                const arrayProductLine = array.product === Product.FA ? ProductLine.FlashArray : ProductLine.FlashBlade;
                return arrayProductLine === this.selectedProductLine;
            });
            this.loading = false;
        }, console.error);

        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Dialog opened',
            properties: {
                category: 'Action',
            },
        });
    }

    ngOnDestroy(): void {
        this.angulartics.userTimings.next({
            timingCategory: 'Action',
            timingVar: this.ANALYTICS_PREFIX + 'Time spent in dialog',
            timingValue: moment().valueOf() - this.startTime,
        });

        if (!this.submitted) {
            this.angulartics.eventTrack.next({
                action: this.ANALYTICS_PREFIX + 'Cancelled',
                properties: {
                    category: 'Action',
                },
            });
        }
    }

    showStep(stepIndex: number): void {
        if (stepIndex == 1) {
            this.shouldLoadTimeslots = true;
        }
        if (stepIndex !== this.currentStepIndex) {
            this.currentStepIndex = stepIndex;
            this.wizard.goToStep(stepIndex);
        }
    }

    cancel(): void {
        this.activeModal.dismiss();
    }

    dismiss(): void {
        this.activeModal.dismiss();
    }

    selectProductLine(productLine: ProductLine): void {
        // store selected value
        this.selectedProductLine = productLine;
        // filter available arrays
        this.arraysFilteredByProduct = this.allArrays.filter(array => {
            const arrayProductLine = array.product === Product.FA ? ProductLine.FlashArray : ProductLine.FlashBlade;
            return arrayProductLine === this.selectedProductLine;
        });
    }

    setArrays(arrays: UnifiedArray[]): void {
        this.arraysToEnable = arrays;
        this.showStep(1);
    }

    setSchedule(schedule: Schedule): void {
        this.schedule = schedule;
        this.showStep(2);
    }

    doSchedule(description: string): void {
        this.timeslotsChecking = true;
        // check the timeslots to prevent overbooking
        const startTime = getDayAsMoment(this.calendar.getToday(), this.schedule.timezone);
        const endTime = getDayAsMoment(this.calendar.getToday(), this.schedule.timezone).add(6, 'months');
        this.scheduleService.getFreeTimes(startTime, endTime, this.selectedProductLine).subscribe(
            newFreeTimes => {
                const splitNewFreeTimes = splitUpSlots(newFreeTimes, moment.duration(1, 'hours'), TIMESLOT_INTERVAL);
                const scheduledArraysWithConflicts: ScheduledArray[] = [];

                // go through all arrays and check if their schedule fits into new timeslots
                this.schedule.arrays.forEach(scheduledArray => {
                    // array that has less than 4 number of hops and has timeslot (others cannot create conflicts because support will contact the customer anyway)
                    if (scheduledArray.timeslot) {
                        // find the slot where the upgrade shoudl start
                        const startSlotIndex = splitNewFreeTimes.findIndex(ts =>
                            ts.startTime.isSame(scheduledArray.timeslot.startTime),
                        );
                        if (startSlotIndex > -1) {
                            //does it fit into the capacity of the found slot?
                            if (splitNewFreeTimes[startSlotIndex].capacity > 0) {
                                // adjust capacity if
                                splitNewFreeTimes[startSlotIndex].capacity--;
                            } else {
                                // not enough capacity, mark as array with conflict
                                scheduledArraysWithConflicts.push(scheduledArray);
                            }
                        } else {
                            // if start slot was not found, push current array into array with conflicts
                            scheduledArraysWithConflicts.push(scheduledArray);
                        }
                    }
                });

                if (scheduledArraysWithConflicts.length > 0) {
                    this.timeslotsConflictFlag = true;
                    this.timeslotsChecking = false;
                    scheduledArraysWithConflicts.forEach(scheduledArray => {
                        scheduledArray.timeslot = null;
                    });
                    this.freeTimeUpdates = {
                        freeTimes: newFreeTimes,
                        conflictingArrays: scheduledArraysWithConflicts,
                    };
                } else {
                    this.timeslotsConflictFlag = false;
                    this.timeslotsChecking = false;
                    this.createCase(description);
                }
            },
            error => {
                console.error(error); // for datadog reporting reasons
                this.timeslotsChecking = false;
            },
        );
    }

    private createCase(description: string): void {
        if (!this.timeslotsConflictFlag) {
            this.submitting = true;
            this.angulartics.eventTrack.next({
                action: this.ANALYTICS_PREFIX + 'Case submitted',
                properties: {
                    category: 'Action',
                },
            });
            this.scheduleService
                .createBulkUpgradeSchedule(this.schedule, this.userId, description, this.selectedProductLine)
                .subscribe(
                    newCase => this.onCaseCreationSuccessCallback(newCase),
                    error => this.onCaseCreationErrorCallback(error),
                );
        }
    }

    private getUserInfo(): Observable<[CurrentUser, DataPage<Contact>]> {
        return forkJoin([
            this.cachedCurrentUserService.get().pipe(take(1)),
            this.contactsService.list().pipe(take(1)),
        ]).pipe(
            tap(([curUser, contacts]) => {
                this.userEmail = curUser.email;

                // Look up the current user's sfdc contact by email
                const contact = contacts.response.find(c => curUser.email.toLowerCase() === c.email.toLowerCase());
                if (contact?.id) {
                    this.userId = contact.id;
                } else {
                    throw new Error('Cannot get user ID for current user.');
                }

                this.supportContactService.getContactById(curUser.id).subscribe(
                    () => {
                        this.hasSNOWContact = true;
                        this.isSNOWInitialized = true;
                    },
                    error => {
                        this.isSNOWInitialized = true;
                        this.hasSNOWContact = false;
                    },
                );
            }),
        );
    }

    private getArrays(): Observable<DataPage<UnifiedArray>> {
        return this.unifiedArrayService.list().pipe(
            take(1),
            tap((array: DataPage<UnifiedArray>) => {
                this.allArrays = array.response
                    ? array.response
                          .filter(array => {
                              if (this.isEnablement) {
                                  return !isArraySafeModeFullyEnabled(array) && !isUpgradeRequired(array);
                              } else if (this.isEnablement === false) {
                                  return isArraySafeModeFullyEnabled(array);
                              } else {
                                  return !isUpgradeRequired(array);
                              }
                          })
                          .sort(forString((result: UnifiedArray) => result.name).asc)
                    : [];
                // filter available arrays
                // this.arraysFilteredByProduct = this.allArrays.filter(
                //     array => array.product === this.selectedProductLine
                // );
            }),
        );
    }

    private onCaseCreationSuccessCallback(newCase: Partial<Case>): void {
        if (newCase) {
            this.caseId = newCase.caseNumber;
        } else {
            console.warn(`no case number returned from bulk upgrade scheduler for ${this.userId}`);
        }
        this.submitting = false;
        this.submitted = true;
        this.createCaseDoneFlag = true;
        this.createCaseErrorFlag = false;
        this.onCaseCreated.next();
        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Case created',
            properties: {
                category: 'Action',
                label: `${this.schedule.arrays.length} child cases`,
            },
        });
    }

    private onCaseCreationErrorCallback(error: any): void {
        console.error(error); // for datadog reporting reasons
        this.submitting = false;
        this.submitted = true;
        this.createCaseDoneFlag = true;
        this.createCaseErrorFlag = true;
        this.angulartics.eventTrack.next({
            action: this.ANALYTICS_PREFIX + 'Case create failed',
            properties: {
                category: 'Action',
                label: `${this.schedule.arrays.length} child cases`,
            },
        });
    }
}
