import { Component, Input, OnInit } from '@angular/core';

import { Pill } from '../../gfb/pill';
import { DisplayKeyValueLookupService } from '../../services/display-key-value-lookup.service';
import { IStateFilter } from '../../redux/pure-redux.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'view-pill',
    templateUrl: './view-pill.component.html',
})
export class ViewPillComponent implements OnInit {
    @Input() readonly pill: Pill;

    displayKey: string;
    displayValues: string[] = [];
    displayValueLength = 0;

    constructor(private displayKeyValueLookup: DisplayKeyValueLookupService) {}

    ngOnInit(): void {
        const firstFilter: IStateFilter = this.pill.filters()[0];
        if (firstFilter.displayKey) {
            this.displayKey = firstFilter.displayKey;
        } else {
            this.displayKeyValueLookup
                .getDisplayKey(firstFilter.entity, firstFilter.namespace, firstFilter.key)
                .pipe(take(1))
                .subscribe((result: string) => {
                    this.displayKey = result;
                });
        }

        this.displayValueLength = 0;
        this.pill.filters().forEach((filter, index) => {
            if (filter.displayValue) {
                this.displayValues[index] = filter.displayValue;
            } else {
                this.displayKeyValueLookup
                    .getDisplayValue(filter.entity, filter.namespace, filter.key, filter.value)
                    .pipe(take(1))
                    .subscribe((result: string) => {
                        this.displayValues[index] = result;
                        this.displayValueLength += result.length;
                    });
            }
        });
    }
}
