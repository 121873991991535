<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<ng-container *ngIf="!renewalAcceptedRequestSucceeded; else showConfirmation">
    <div class="modal-header">
        <button type="button" class="close modal-x-button" (click)="activeModal.dismiss()">
            <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
        </button>
        <div class="modal-title"
            >Review or Edit Renewal for <b>{{ subscription.name }}</b></div
        >
    </div>
    <div class="modal-body">
        <div class="licenses-summary">
            <table class="renew-licenses-table">
                <thead class="renew-thead-default">
                    <tr>
                        <th>License Description</th>
                        <th>Service Level</th>
                        <th>Total Reserved</th>
                        <th>Current Usage</th>
                    </tr>
                </thead>
                <tbody class="renew-licenses">
                    <tr
                        class="renew-license"
                        *ngFor="let license of licenses; let i = index; trackBy: 'id' | trackByProperty"
                    >
                        <td>{{ license.name | defaultString }}</td>
                        <td>{{ license.licenseType | defaultString }}</td>
                        <td>{{
                            increaseReserved[i] | formatSubscriptionData: license.licenseType : 1 | defaultString
                        }}</td>
                        <td>{{
                            license.licenseUsage.current
                                | formatSubscriptionData: license.licenseType : 1
                                | defaultString
                        }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="renewal-info-section">
            <table class="renew-info-table">
                <thead class="renew-info-thead">
                    <tr>
                        <th>Renewal Term</th>
                        <th>Renewal Date</th>
                        <th>Partner AE</th>
                        <th>Partner Organization</th>
                        <th>Partner Contact</th>
                    </tr>
                </thead>
                <tbody class="renew-info-tbody">
                    <tr class="renew-info">
                        <td
                            >{{ increaseTermInMonths?.toString() | defaultString }} Months /
                            {{ renewalEndDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}</td
                        >
                        <td>{{ $any(subscription?.endDate) | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}</td>
                        <td>{{ displayedPartner?.contactName | defaultString }}</td>
                        <td>{{ displayedPartner?.accountName | defaultString }}</td>
                        <td>{{ displayedPartner?.contactEmail | defaultString }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <div class="modal-action-buttons-container">
            <button
                class="btn btn-secondary renew-action-button"
                (click)="activeModal.dismiss()"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Renewal Summary Modal - Click to cancel the renewal summary modal"
                >Cancel</button
            >
            <button
                class="btn btn-secondary renew-action-button"
                (click)="modifyRenewal()"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Renewal Summary Modal - Click to modify a renewal offer"
                >Modify</button
            >
            <button
                class="btn btn-primary renew-accept-button"
                pure-read-only
                [ngClass]="{ disabled: pendingQuote?.pure1Acknowledged || loading }"
                (click)="acceptRenewal()"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Renewal Summary Modal - Click to accept a renewal offer"
                >Accept</button
            >
        </div>
    </div>
</ng-container>
<ng-template #showConfirmation>
    <div class="confirmed-header">
        <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
            <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
        </button>
    </div>
    <div class="confirmed-body">
        <div class="icon">
            <pureui-svg class="check-mark-icon" svg="check_mark.svg" height="31"></pureui-svg>
            <pureui-svg class="confirmation-icon" svg="confirmation-icon.svg" height="80"></pureui-svg>
        </div>
        <label class="confirmed-message"
            >Thank you for renewing your subscription! <br />We’ll notify you once the renewal is processed. You can
            also track updates on the orders page.</label
        >
        <button class="btn btn-primary" (click)="activeModal.dismiss()">OK</button>
    </div>
</ng-template>
<ng-template #manageSubscriptionModal let-modal>
    <manage-subscription-modal
        [activeModal]="modal"
        [subscription]="subscription"
        [licenses]="licenses"
        [quoteBeingEdited]="pendingQuote"
    >
    </manage-subscription-modal>
</ng-template>
