import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AdditionalRole } from '../models/additional-role';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdditionalUserRolesService {
    private readonly endpoint = '/rest/v1/assignments/roles';

    constructor(private readonly http: HttpClient) {}

    list(): Observable<AdditionalRole[]> {
        return this.http.get<AdditionalRole[]>(this.endpoint).pipe(
            map((roles: AdditionalRole[]) =>
                roles.map(item => new AdditionalRole(item)).sort(AdditionalRole.compareByNameAlphabetically),
            ),
            catchError(err => {
                console.error(err);
                return of([]);
            }),
        );
    }
}
