<pureui-spinner class="p-loading" [pureuiShow]="'getUserGroupsList' | asyncSpinner"></pureui-spinner>
<div class="main-content-area">
    <div class="user-pane" [ngClass]="{ 'drawer-open': !urStateService.drawerClosed }">
        <banners></banners>
        <impersonating-banner permission="PURE1:read:users"></impersonating-banner>
        <div class="user-toolbar">
            <!-- gfb -->
            <gfb class="user-gfb" [entities]="['assignments']" [allowPinning]="false" [barId]="barId"> </gfb>
        </div>
        <div class="user-toolbar action-button-toolbar">
            <div class="toolbar-section grouped-buttons" ngbDropdown>
                <div class="flat-action-button quick-add-button" (click)="quickAdd()" ngbDropdownAnchor>
                    <pureui-svg class="pstg-action-icon add-user-icon" svg="create_add.svg" height="12"></pureui-svg>
                    <div *ngIf="isUsers(); else addGroupText" class="flat-action-button-text">Add User</div>
                    <ng-template #addGroupText>
                        <div class="flat-action-button-text">Add Group</div>
                    </ng-template>
                </div>
                <div class="dropdown-container">
                    <div class="flat-action-button additional-options-button" ngbDropdownToggle>
                        <pureui-svg class="chevron-icon" svg="chevron-up.svg" height="11"></pureui-svg>
                    </div>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <button
                            ngbDropdownItem
                            *ngIf="isUsers(); else addGroups"
                            angulartics2On="click"
                            angularticsAction="UM - Main view CTA - Add user"
                            angularticsCategory="Action"
                            (click)="onActionButtonClick(ADD_USER)"
                        >
                            Add User
                        </button>
                        <ng-template #addGroups>
                            <button
                                ngbDropdownItem
                                angulartics2On="click"
                                angularticsAction="UM - Main view CTA - Add group"
                                angularticsCategory="Action"
                                (click)="onActionButtonClick(ADD_GROUP)"
                            >
                                Add Group
                            </button>
                            <button
                                *ngIf="ngRolesAvailable"
                                ngbDropdownItem
                                angulartics2On="click"
                                angularticsAction="UM - Main view CTA - Add SSO user"
                                angularticsCategory="Action"
                                id="add_sso_user_option"
                                (click)="onActionButtonClick(ADD_SSO_USER)"
                            >
                                Add User
                            </button>
                        </ng-template>
                        <button
                            ngbDropdownItem
                            angulartics2On="click"
                            angularticsAction="UM - Main view CTA - Add external user"
                            angularticsCategory="Action"
                            (click)="onActionButtonClick(ADD_EXTERNAL_USER)"
                        >
                            Add External User
                        </button>
                    </div>
                </div>
            </div>
            <div class="toolbar-section flat-action-button" (click)="onActionButtonClick(VIEW_LIST)">
                <pureui-svg class="pstg-action-icon" svg="views-icon.svg" height="21"></pureui-svg>
                <div class="flat-action-button-text">Views</div>
            </div>
        </div>

        <div class="assignment-table-container">
            <performance-table
                [columnOptions]="columnOptions"
                [items]="filteredAssignments"
                [multiSelect]="multiSelectEnabled"
                [sort]="sort"
                [defaultSort]="defaultSort"
                [selection]="selectedAssignments"
                [tableOptions]="tableOptions"
                (selectionChange)="onSelectionChange($event)"
                (sortChange)="onSortChange($event)"
                (searchChange)="onTableSearchChange($event)"
            >
            </performance-table>
        </div>

        <feedback-button></feedback-button>
    </div>

    <drawer
        class="user-drawer"
        [drawerClosed]="urStateService.drawerClosed"
        [contentTemplate]="drawerTemplate"
        (closeDrawerClick)="onDrawerClose()"
    >
    </drawer>
</div>

<!-- ng-template for drawer -->
<ng-template #drawerTemplate>
    <user-role-drawer-pane [currentUser]="currentUser"></user-role-drawer-pane>
</ng-template>

<!-- ng-templates for the performance table -->
<ng-template #roleLink let-user="item">
    <show-all-roles [role]="user.role" [additionalRoles]="user.additionalRoles"></show-all-roles>
    <span *ngIf="user.assigneeType === 'EXTERNAL'" class="external-label"> - External</span>
</ng-template>

<ng-template #editViewLink let-user="item">
    <a
        *ngIf="user.viewName; else noLink"
        class="view-link"
        (click)="editView(user, $event)"
        angulartics2On="click"
        angularticsAction="UM - Edit view from user list"
        angularticsCategory="Action"
    >
        {{ getAssignableText(user) }}
    </a>
    <ng-template #noLink>
        <span class="assignment-text">{{ getAssignableText(user) }}</span>
    </ng-template>
</ng-template>

<ng-template #roleHeader>
    Roles
    <a
        href="https://support.purestorage.com/Pure1/Pure1_Manage/001_Overview/650%3A_Pure1_Manage_-_User_Account_Management"
        target="_blank"
    >
        <pureui-svg class="help-icon" svg="info-circle.svg" height="13"></pureui-svg>
    </a>
</ng-template>
