import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import moment from 'moment';

import {
    Release,
    UpgradeVersion,
    UpgradePath,
    AvailablePatch,
    PolicyVersionsModel,
} from '../purity-upgrades.interface';
import { UpgradePrechecksErrorService } from './error.service';
import { splitToChunks } from '../purity-upgrades.utils';

export interface UpgradeVersionRequiredInfo {
    applianceId: string;
    currentVersion: string;
    controllerModel: string;
    softwareName: string;
}

export interface UpgradePathRequiredInfo extends UpgradeVersionRequiredInfo {
    targetVersion: string;
}

@Injectable({ providedIn: 'root' })
export class ReleasesService {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 29000; // 29 seconds
    readonly endpoint = `/rest/cfwd/api/v1`;

    constructor(
        private http: HttpClient,
        private errorService: UpgradePrechecksErrorService,
    ) {}

    @Cacheable({
        maxAge: ReleasesService.cacheTTL,
        cacheBusterObserver: ReleasesService.cacheBuster$,
    })
    getAllReleases(): Observable<Release[]> {
        return this.http.get<Release[]>(this.endpoint + '/releases-v2').pipe(
            catchError(this.errorService.errorInterceptor),
            map(result => result.map(this.formatRelease)),
        );
    }

    @Cacheable({
        maxAge: ReleasesService.cacheTTL,
        cacheBusterObserver: ReleasesService.cacheBuster$,
    })
    getUpgradeVersions(
        appliances: UpgradeVersionRequiredInfo[],
    ): Observable<{ [key: string]: PolicyVersionsModel[] | null }> {
        return this.callChunked(appliances, chunk => this.getUpgradeVersionsChunk(chunk));
    }

    @Cacheable({
        maxAge: ReleasesService.cacheTTL,
        cacheBusterObserver: ReleasesService.cacheBuster$,
    })
    getPolicies(appliances: UpgradeVersionRequiredInfo[]): Observable<{ [key: string]: PolicyVersionsModel }> {
        const chunks = splitToChunks(appliances, 30);
        return forkJoin(chunks.map(chunk => this.getPoliciesChunk(chunk))).pipe(
            map(results => results.reduce((all, itm) => ({ ...all, ...itm }), {})),
        );
    }

    getPoliciesChunk(appliances: UpgradeVersionRequiredInfo[]): Observable<{ [key: string]: PolicyVersionsModel }> {
        const params = new HttpParams()
            .set('arrayIds', appliances.map(x => x.applianceId).join(','))
            .set('currentVersions', appliances.map(x => x.currentVersion).join(','))
            .set('controllerModels', appliances.map(x => x.controllerModel).join(','))
            .set('softwareNames', appliances.map(x => x.softwareName).join(','));
        return this.http
            .get<{ [key: string]: PolicyVersionsModel }>(this.endpoint + '/upgrade-versions-v2', { params })
            .pipe(catchError(this.errorService.errorInterceptor));
    }

    @Cacheable({
        maxAge: ReleasesService.cacheTTL,
        cacheBusterObserver: ReleasesService.cacheBuster$,
    })
    getUpgradePath(appliances: UpgradePathRequiredInfo[]): Observable<{ [key: string]: UpgradePath[] | null }> {
        const chunks = splitToChunks(appliances, 10);
        return forkJoin([...chunks.map(chunk => this.getUpgradePathChunk(chunk))]).pipe(
            map(results =>
                results.reduce((all, itm) => {
                    Object.keys(itm).forEach(key => {
                        if (all[key]) {
                            all[key].push(...itm[key]);
                        } else {
                            all[key] = itm[key];
                        }
                    });
                    return all;
                }, {}),
            ),
        );
    }

    bustCache(): void {
        ReleasesService.cacheBuster$.next();
    }

    private getUpgradeVersionsChunk(
        appliances: UpgradeVersionRequiredInfo[],
    ): Observable<{ [key: string]: UpgradeVersion[] | null }> {
        const params = new HttpParams()
            .set('arrayIds', appliances.map(x => x.applianceId).join(','))
            .set('currentVersions', appliances.map(x => x.currentVersion).join(','))
            .set('controllerModels', appliances.map(x => x.controllerModel).join(','))
            .set('softwareNames', appliances.map(x => x.softwareName).join(','))
            .set('includePolicies', false);
        return this.http
            .get<{ [key: string]: UpgradeVersion[] }>(this.endpoint + '/upgrade-versions-v2', { params })
            .pipe(
                map(upgrades =>
                    Object.keys(upgrades).reduce((acc, current) => {
                        // If the array upgrade version is only one object without the number property then there is an issue
                        // with determining available upgrade versions. In that case return null
                        if (
                            Array.isArray(upgrades[current]) &&
                            upgrades[current].length === 1 &&
                            !upgrades[current][0].number
                        ) {
                            return {
                                ...acc,
                                [current]: null,
                            };
                        }

                        return {
                            ...acc,
                            [current]: upgrades[current],
                        };
                    }, {}),
                ),
                catchError(this.errorService.errorInterceptor),
            );
    }

    private getUpgradePathChunk(
        appliances: UpgradePathRequiredInfo[],
    ): Observable<{ [key: string]: UpgradePath[] | null }> {
        const params = new HttpParams()
            .set('arrayIds', appliances.map(x => x.applianceId).join(','))
            .set('targetVersions', appliances.map(x => x.targetVersion).join(','))
            .set('currentVersions', appliances.map(x => x.currentVersion).join(','))
            .set('controllerModels', appliances.map(x => x.controllerModel).join(','))
            .set('softwareNames', appliances.map(x => x.softwareName).join(','))
            .set('includePolicies', true);

        return this.http.get<{ [key: string]: UpgradePath[] }>(this.endpoint + '/upgrade-paths', { params }).pipe(
            map(x =>
                Object.keys(x).reduce(
                    (acc, value) => ({
                        ...acc,
                        // Error upgrade paths have null arrayId and they have a message so filter them out
                        [value]: x[value].filter(path => path.arrayId && !path.message),
                    }),
                    {},
                ),
            ),
            catchError(this.errorService.errorInterceptor),
        );
    }

    private callChunked<T>(values: T[], func: Function): Observable<any> {
        const chunks = splitToChunks(values, 40);

        return forkJoin(chunks.map(chunk => func(chunk))).pipe(
            map(results => results.reduce((all, itm) => ({ ...all, ...itm }), {})),
        );
    }

    private formatRelease = (release: Release): Release => {
        return {
            ...release,
            createTime: release.createTime ? moment(release.createTime) : null,
            daTime: release.daTime ? moment(release.daTime) : null,
            eolTime: release.eolTime ? moment(release.eolTime) : null,
            gaTime: release.gaTime ? moment(release.gaTime) : null,
            lastUpdateTime: release.lastUpdateTime ? moment(release.lastUpdateTime) : null,
            releaseTime: release.releaseTime ? moment(release.releaseTime) : null,
        };
    };

    private formatAvailablePatchArray(patches: AvailablePatch[]): AvailablePatch {
        if (patches.length > 1) {
            // This should not happen, we should get only the latest patch
            throw new Error('Invalid patches count');
        } else if (patches.length === 0) {
            return null;
        }

        const patch = patches[0];

        // No array id means error message that BE cannot get patch (so no patch)
        if (!patch.arrayId) {
            return null;
        }

        return {
            ...patch,
            createTime: patch.createTime ? moment(patch.createTime) : null,
            resolvedCVEs: Array.isArray(patch.resolvedCVEs) ? patch.resolvedCVEs : [],
        };
    }
}
