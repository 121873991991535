import { Component, Input, OnChanges } from '@angular/core';
import { Group, RoleAssignment, ViewReference } from '@pure1/data';

import { UserRoleStateService } from '../services/user-role-state.service';

@Component({
    selector: 'create-edit-group',
    templateUrl: './create-edit-group.component.html',
    host: {
        class: 'base-create-edit',
    },
})
export class CreateEditGroupComponent implements OnChanges {
    @Input() readonly groupAssignment: RoleAssignment;

    group: Group;
    isEdit = false;
    isProcessing = false;

    constructor(public urStateService: UserRoleStateService) {}

    ngOnChanges(): void {
        this.isEdit = !!this.groupAssignment;
        // convert view from RoleAssignment format to Assignment format
        if (this.isEdit) {
            this.group = new Group(this.groupAssignment);
            if (this.groupAssignment.viewId) {
                this.group.view = new ViewReference({
                    id: this.groupAssignment.viewId,
                    name: this.groupAssignment.viewName,
                });
            }
        }
    }

    onGroupCreated(): void {
        this.returnToList();
    }

    returnToList(): void {
        this.urStateService.deferAssignable = false;
        this.urStateService.deferredAssignable = null;
        this.urStateService.previousDrawerState();
    }

    onCancelled(): void {
        this.returnToList();
    }
}
