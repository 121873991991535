<div class="content">
    <form [formGroup]="confirmForm">
        <div class="row">
            <div *ngIf="showSafeModeAutoOnColumn" class="col-xs-12 mb-2 info-toast-message">
                Upgrading to <span class="version">FA//Purity version 6.4.10</span
                ><span class="feature"> enables Auto-On SafeMode</span> to ensure your array has the recommended default
                protection against ransomware attacks.
                <a
                    href="https://support.purestorage.com/FlashArray/FlashArray_Security/SafeMode/Auto-On_SafeMode"
                    target="_blank"
                    >Learn more</a
                >
            </div>
        </div>
        <div class="row">
            <div class="col-xs-8">
                <div class="header left-header mb-1">
                    <div class="inline-column column-1 column-extra-padding">Appliances</div>
                    <div class="inline-column column-2">Upgrade To</div>
                    <div class="inline-column column-3">Appointment Time</div>
                    <div class="inline-column column-4" *ngIf="showSafeModeAutoOnColumn">Auto-on SafeMode</div>
                </div>
                <div class="selected-appliances-wrapper">
                    <ul class="selected-appliances-list">
                        <li
                            *ngFor="let scheduledArray of schedule?.schedule; let i = index; trackBy: trackByArrayId"
                            class="selected-appliances-list-item"
                        >
                            <div class="column-1">
                                <div *ngIf="!scheduledArray.secondaryArray" class="name">
                                    {{ getArrayName(scheduledArray.array) }}
                                </div>
                                <div *ngIf="scheduledArray.secondaryArray" class="name">
                                    <span class="replication-icon-wrapper">
                                        <pureui-svg svg="pod.svg" height="10" class="replication-icon"></pureui-svg>
                                    </span>
                                    <div class="clustered-array-name primary">
                                        <span class="connector"></span>
                                        {{ getArrayName(scheduledArray.array) }}
                                    </div>
                                    <div class="secondary">
                                        <span class="connector"></span>
                                        {{ getArrayName(scheduledArray.secondaryArray) }}
                                    </div>
                                </div>
                            </div>
                            <div class="column-2">
                                <span class="version"
                                    >{{ scheduledArray.array.currentVersion }}
                                    <pureui-svg class="link-arrow" svg="ssu-path-arrow.svg" height="11"></pureui-svg>
                                    {{ scheduledArray.targetVersion }}</span
                                >
                            </div>
                            <ng-container *ngIf="scheduledArray.numberOfHops <= maxHops; else hopsWarning">
                                <div
                                    class="column-3 top-aligned"
                                    [ngClass]="{ unavailable: scheduledArray.timeslot === null }"
                                >
                                    {{
                                        scheduledArray.timeslot
                                            ? getDateFromMoment(scheduledArray.timeslot.startTime)
                                            : 'Unavailable!'
                                    }},
                                    {{
                                        scheduledArray.timeslot
                                            ? getTimeAndDurationFromMoments(
                                                  scheduledArray.timeslot.startTime,
                                                  scheduledArray.timeslot.duration
                                              )
                                            : 'Unavailable!'
                                    }}
                                </div>
                                <div class="column-4 top-aligned" *ngIf="showSafeModeAutoOnColumn">
                                    <div *ngIf="safeModeAutoOnFlags.hasOwnProperty(scheduledArray.array.applianceId)">
                                        <input
                                            type="checkbox"
                                            [checked]="safeModeAutoOnFlags[scheduledArray.array.applianceId]"
                                            [value]="safeModeAutoOnFlags[scheduledArray.array.applianceId]"
                                            class="opt-out-checkbox"
                                            name="safemodeOptOut"
                                            (change)="toggleSafeModeAutoOn.emit(scheduledArray.array.applianceId)"
                                        />
                                        {{
                                            safeModeAutoOnFlags[scheduledArray.array.applianceId]
                                                ? 'Enabled'
                                                : 'Disabled'
                                        }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #hopsWarning>
                                <div class="column-2 top-aligned" colspan="2">
                                    Upgrade of this array to version {{ scheduledArray.targetVersion }} will be
                                    scheduled directly with our Support.
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div class="float-xs-right mt-1">
                        <div>Time zone: {{ getTimezonePrettyName(schedule?.timezone) }}</div>
                    </div>
                    <div
                        class="timeslot-checking-spinner"
                        [ngClass]="{ 'timeslot-checking-spinner-visible': timeslotsChecking }"
                    >
                        <pureui-spinner [pureuiShow]="true"></pureui-spinner>
                        <span class="pl-1">Checking timeslots...</span>
                    </div>
                </div>
            </div>
            <div class="col-xs-4">
                <div class="row">
                    <div class="col-xs-12 mb-1 header">Comments</div>
                </div>
                <div class="row">
                    <div class="col-xs-12 mb-1">
                        <textarea class="form-control comment-text-area" type="text" formControlName="comment">
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 mb-1">
                        <label class="form-check-label" for="best-practices">
                            <span
                                [ngbTooltip]="!userHasClickedOnLink ? clickOnLinkTooltip : null"
                                container="body"
                                placement="right"
                                class="accept-checkbox"
                            >
                                <input
                                    id="best-practices"
                                    class="form-check-input"
                                    type="checkbox"
                                    formControlName="bestPracticesCheck"
                                />
                            </span>
                            I have read the
                            <a
                                *ngIf="itContainsFAAppliances()"
                                id="fa-best-practices-link"
                                href="https://support.purestorage.com/FlashArray/PurityFA/PurityFA_Upgrades/03_Upgrades:_Support_Process/Purity_Upgrade:_Pre-Upgrade_Host-Side_Issues_and_Caveats"
                                target="_blank"
                                (click)="enableCheckbox()"
                            >
                                FA upgrade best practices
                            </a>
                            <br *ngIf="itContainsFAAppliances() && itContainsFBAppliances()" />
                            <a
                                *ngIf="itContainsFBAppliances()"
                                id="fb-best-practices-link"
                                href="https://support.purestorage.com/bundle/m_flashblade_release/page/FlashBlade/FlashBlade_Release/FlashBlade_Release_Information/topics/concept/c_purityfb_upgrade_best_practices.html"
                                target="_blank"
                                (click)="enableCheckbox()"
                            >
                                FB upgrade best practices
                            </a>
                            <span class="info-icon"
                                ><pure-info-icon
                                    tooltipContent="Please review the checklist in our best practice guide to learn of your todo’s before the upgrade starts"
                                ></pure-info-icon
                            ></span>
                        </label>
                    </div>
                    <div class="row">
                        <div class="col-xs-12" *ngIf="timeslotsConflictFlag">
                            <div class="alert alert-warning" role="alert">
                                Unfortunately, some of the requested time slots are no longer available. Please select
                                the “Back” button to re-select a requested time for those appliances.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="showSafeModeAutoOnWarning()" class="row">
        <div class="opt-out-warning">
            <input type="checkbox" id="safeModeOptOutConfirm" [(ngModel)]="confirmSafeModeOptOut" />
            <span
                >I understand that disabling Auto-On SafeMode means operating with a less secure configuration. This
                decision will be logged for auditing purposes.</span
            >
        </div>
    </div>
</div>
<div class="row bottom">
    <div class="col-xs-12">
        <div class="btn-toolbar clearfix">
            <button
                type="button"
                id="continue-button"
                class="btn btn-primary float-xs-right margin"
                (click)="continue()"
                [disabled]="submitDisabled"
            >
                Request Upgrade
            </button>
            <button type="button" class="btn btn-primary float-xs-right margin btn-custom" (click)="onBack.emit()">
                &lt; Back
            </button>
            <button type="button" class="btn btn-info float-xs-right margin btn-custom" (click)="onCancel.emit()">
                Cancel
            </button>
        </div>
    </div>
</div>
