<ng-container *ngIf="salesFlow.statusMessage === 'Quote in Progress'; else readOnlyModeForUpload">
    <div class="confirmation-message" *ngIf="quoteAccepted">
        You've accepted this quote. Please attach a purchase order and validate the total amount by entering it in the
        box below.
    </div>

    <section class="attachment-section">
        <label class="amount-total-label" for="amount-total-field"
            >Enter total amount matching the quote and your purchase order (numbers only)</label
        >
        <div class="purchase-order-info">
            <input id="amount-total-field" type="number" class="form-control" [(ngModel)]="totalAmount" />
            <label for="upload-file-input" class="upload-file btn btn-primary">
                <input
                    id="upload-file-input"
                    type="file"
                    accept=".pdf"
                    multiple
                    (change)="onFileChange($event)"
                    [disabled]="newFilesUploadDone?.length > 0"
                />
                Select PO Files
            </label>
        </div>
    </section>

    <section class="attachment-list">
        <div
            class="new-attachment attachment"
            *ngFor="let file of newFiles; let i = index; trackBy: 'name' | trackByProperty"
        >
            <div class="left-attachment-details">
                <span class="file-name">{{ file.name }}</span>
                <span class="file-size">({{ file.size | formatSize: 0 }})</span>
            </div>
            <div class="right-attachment-details">
                <ng-container *ngIf="newFilesUploadDone.length < 1; else showUploadStatus">
                    <span
                        *ngIf="newFilesUploadStatus[i] && newFilesUploadStatus[i].startsWith(ERROR_PREFIX)"
                        class="file-modified-date"
                        >{{ newFilesUploadStatus[i] }}</span
                    >
                    <span
                        *ngIf="!(newFilesUploadStatus[i] && newFilesUploadStatus[i].startsWith(ERROR_PREFIX))"
                        class="file-modified-date"
                        >{{ file.lastModified | date: 'yyyy-MM-dd HH:mm' }}</span
                    >
                    <pureui-svg
                        class="delete-attachment-button"
                        svg="x-action.svg"
                        [height]="10"
                        (click)="deleteAttachment(file, i)"
                    ></pureui-svg>
                </ng-container>
                <ng-template #showUploadStatus>
                    <span class="file-modified-date upload-status">{{ newFilesUploadStatus[i] }}</span>
                    <pureui-spinner
                        class="delete-attachment-button"
                        [pureuiShow]="
                            !newFilesUploadDone[i] &&
                            newFilesUploadStatus[i] &&
                            !newFilesUploadStatus[i].endsWith(FILE_TOO_LARGE)
                        "
                    ></pureui-spinner>
                </ng-template>
            </div>
        </div>
        <div
            class="existing-attachment attachment"
            *ngFor="let poFile of salesFlow.purchaseOrders; trackBy: 'pure1Id' | trackByProperty"
        >
            <div class="left-attachment-details" (click)="openNewWindowForPOFileDownload(poFile)">
                <span class="file-name">{{ poFile.fileName }}</span>
                <span class="file-size">({{ poFile.contentLength | formatSize: 0 }})</span>
            </div>
            <div class="right-attachment-details">
                <span class="file-modified-date">{{ $any(poFile).createdDate | date: 'yyyy-MM-dd HH:mm' }}</span>
            </div>
        </div>
    </section>

    <basic-comment-box descriptor="Add comment (optional):" (textChange)="onTextChange($event)"> </basic-comment-box>

    <div class="message-to-user">{{ selectMainMessageToUser() }}</div>

    <section class="form-actions">
        <button class="btn btn-info cancel-button" (click)="cancelClicked()">{{
            newFilesUploadDone?.length > 0 ? 'Close' : 'Cancel'
        }}</button>
        <button class="btn btn-primary submit-button" (click)="submitAttachment()" [disabled]="isSubmitDisabled()"
            >Attach File(s)</button
        >
    </section>
</ng-container>
<ng-template #readOnlyModeForUpload>
    <section class="attachment-list">
        <div
            class="existing-attachment attachment"
            *ngFor="let poFile of salesFlow.purchaseOrders; trackBy: 'pure1Id' | trackByProperty"
        >
            <div class="left-attachment-details" (click)="openNewWindowForPOFileDownload(poFile)">
                <span class="file-name">{{ poFile.fileName }}</span>
                <span class="file-size">({{ poFile.contentLength | formatSize: 0 }})</span>
            </div>
            <div class="right-attachment-details">
                <span class="file-modified-date">{{ $any(poFile).createdDate | date: 'yyyy-MM-dd HH:mm' }}</span>
            </div>
        </div>
    </section>
</ng-template>
