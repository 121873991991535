import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    ArrayContractStatus,
    FeatureFlagDxpService,
    ResourceStatus,
    ServiceCatalogQuote,
    UnifiedArray,
} from '@pure1/data';
import { take } from 'rxjs/operators';
import { FArray, FeatureNames, IArrayHealth } from '../../model/model';
import { ArraysManager } from '../../services/arrays-manager.service';

@Component({
    selector: 'array-expanded-card',
    templateUrl: 'array-expanded-card.component.html',
})
export class ArrayExpandedCardComponent implements OnInit {
    @Input() readonly array: UnifiedArray & FArray;
    @Input() readonly arrayHealth: IArrayHealth;
    @Input() readonly alerts: IAlert[];
    @Input() readonly isCardFlipped: boolean;
    @Input() readonly allOrders: ServiceCatalogQuote[];
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Output() readonly onFlipCard = new EventEmitter<{ event: UIEvent }>();
    @Output() readonly onChangeExpanded = new EventEmitter<{ isExpanded: boolean }>();
    @ViewChild('scrollToElem', { static: true }) readonly scrollToElem: ElementRef<HTMLElement>;

    readonly ArrayContractStatusExpired = ArrayContractStatus.EXPIRED;
    readonly ResourceStatus = ResourceStatus;
    readonly CAPACITY_PANE_ID = 0;
    readonly HEALTH_PANE_ID = 1;
    readonly PERFORMANCE_PANE_ID = 2;
    readonly isPaneExpanded: boolean[] = [false, false, false];

    hoverState = false;
    selectedArrayExistingQuote: ServiceCatalogQuote;
    isCardShowArrayInfo: boolean;
    hwEOLEnabled = false;
    hasCardEverFlipped = false;

    constructor(
        private arraysManager: ArraysManager,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        // Make sure the parent starts with the correct expanded state
        this.onChangeExpanded.emit({ isExpanded: this.hasExpandedPane() });
        this.selectedArrayExistingQuote = this.arraysManager.getSupportContractRenewalQuote(this.array, this.allOrders);
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.hwEOLEnabled = feature?.enabled === true;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array || changes.allOrders) {
            this.isCardShowArrayInfo = this.arraysManager.isCardShowArrayInfo(this.array);
            this.selectedArrayExistingQuote = this.arraysManager.getSupportContractRenewalQuote(
                this.array,
                this.allOrders,
            );
        }

        if (changes.isCardFlipped) {
            // Effectively lazy-load the card back only once it is actually visible
            this.hasCardEverFlipped ||= this.isCardFlipped;
        }
    }

    flipCard(event: UIEvent): void {
        if (
            this.showWarningCardForOutOfSupportAppliance &&
            this.array.contract_status === this.ArrayContractStatusExpired
        ) {
            return;
        }
        this.onFlipCard.emit({ event });
    }

    togglePane(paneId: number): void {
        const prevIsExpanded = this.hasExpandedPane();

        if (this.isPaneExpanded[paneId]) {
            this.isPaneExpanded[paneId] = false;
        } else {
            this.isPaneExpanded.fill(false);
            this.isPaneExpanded[paneId] = true;
        }

        const isExpanded = this.hasExpandedPane();

        // If the expanded state changes, the page layout may change and result in the card going out of view.
        // So make sure it is scrolled into view after changing state.
        if (prevIsExpanded !== isExpanded) {
            this.onChangeExpanded.emit({ isExpanded });
            this.scrollToElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    hasEolComponents(): boolean {
        return this.hwEOLEnabled && this.array.has_end_of_life_hardware;
    }

    private hasExpandedPane(): boolean {
        return this.isPaneExpanded.some(x => x === true);
    }
}
