<div class="ur-container">
    <div class="drawer-title" *ngIf="isEdit; else newGroupTitle"> {{ user.email }}</div>
    <ng-template #newGroupTitle>
        <div class="drawer-title">Create New User</div>
    </ng-template>

    <div id="form" class="ur-body form">
        <div class="profile">
            <sso-user-form
                [assignment]="user"
                [roleAssignment]="assignment"
                [isEdit]="isEdit"
                [deferredAssignable]="urStateService.deferredAssignable"
                [currentUser]="currentUser"
                [isCurrentUser]="currentUser.email === user?.email"
            />
        </div>
    </div>
</div>
