import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { CurrentUser } from '../models/current-user';
import { IRestResponse } from '../interfaces/collection';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class CurrentUserService extends GenericService<CurrentUser> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: CurrentUser,
            endpoint: '/rest/v1/user',
        });
    }
}

@Injectable({ providedIn: 'root' })
export class CurrentUserBusinessRolesService {
    private userBusinessRoles$: Observable<string[]>;
    constructor(protected http: HttpClient) {}

    getBusinessRoles(): Observable<String[]> {
        if (!this.userBusinessRoles$) {
            this.userBusinessRoles$ = this.http.get<IRestResponse<string>>('/rest/v1/user/business-roles').pipe(
                take(1),
                map(result => result.items),
                catchError(err => {
                    console.error('getBusinessRoles() failed', err);
                    return of<string[]>([]);
                }),
                shareReplay(1), // Cache the result - don't refetch on every subscribe,
            );
        }
        return this.userBusinessRoles$.pipe(map(roles => _.cloneDeep(roles)));
    }
}
