<div class="secondary-left-nav-inner">
    <div class="secondary-nav-collapser" (click)="clickSecondaryNavCollapser()"></div>

    <header style="border-bottom-color: transparent">
        <div id="cases-search" class="input-with-icon before after-sm">
            <label class="sr-only" for="support-cases-search">Find by name</label>
            <pureui-svg class="pstg-action-icon" [svg]="'search.svg'" [height]="14"></pureui-svg>
            <input
                type="search"
                class="form-control"
                id="support-cases-search"
                placeholder="Find by name"
                (input)="search($any($event.target).value)"
                [(ngModel)]="viewState.searchText"
                (blur)="onSearchTextBlur()"
            />
            <pureui-svg
                [hidden]="viewState.searchText.length === 0"
                (click)="clearSearchText()"
                class="pstg-action-icon"
                [svg]="'x-action.svg'"
                [height]="14"
            ></pureui-svg>
        </div>
    </header>

    <div id="support-nav-main">
        <support-tab-nav
            [cases]="cases"
            [closedCases]="closedCases"
            [filteredOpenCases]="filteredOpenCases"
            [filteredClosedCases]="filteredClosedCases"
            [searchText]="viewState.searchText"
            [(selectedTab)]="selectedTab"
        ></support-tab-nav>
        <div [hidden]="selectedTab$.value !== 'open'">
            <div *ngIf="cases === undefined" class="cases-loading">
                <pureui-spinner [pureuiShow]="true"></pureui-spinner>
            </div>
            <div *ngIf="cases !== undefined && filteredOpenCases.length === 0 && viewState.searchText.length === 0">
                <div class="no-cases-message no-open-cases"
                    >No open support cases for your arrays. Pure Storage gives you your time back.</div
                >
            </div>

            <div *ngIf="cases !== undefined && filteredOpenCases.length === 0 && viewState.searchText.length !== 0">
                <div class="no-cases-message no-search-results-for-open-cases"
                    >No open support cases found matching your query.</div
                >
            </div>

            <pure-support-nav-cases-list
                [cases]="filteredOpenCases"
                [selectedCaseId]="selectedCaseId"
                class="open-cases-list"
                style="position: relative"
            >
            </pure-support-nav-cases-list>
        </div>

        <div [hidden]="selectedTab$.value !== 'closed'">
            <div *ngIf="closedCases === undefined" class="cases-loading">
                <pureui-spinner [pureuiShow]="true"></pureui-spinner>
            </div>
            <div
                *ngIf="
                    closedCases !== undefined && filteredClosedCases.length === 0 && viewState.searchText.length === 0
                "
            >
                <div class="no-cases-message no-closed-cases">No closed support cases for your arrays.</div>
            </div>

            <div
                *ngIf="
                    closedCases !== undefined && filteredClosedCases.length === 0 && viewState.searchText.length !== 0
                "
            >
                <div class="no-cases-message no-search-results-for-closed-cases"
                    >No closed support cases found matching your query.</div
                >
            </div>

            <pure-support-nav-cases-list
                [cases]="filteredClosedCases"
                [selectedCaseId]="selectedCaseId"
                [anyCases]="cases !== undefined"
                class="closed-cases-list"
                style="position: relative"
            >
            </pure-support-nav-cases-list>
        </div>
    </div>

    <div id="nav-create-case">
        <ul class="list-group no-disabled-background" *ngIf="isPopupOpen">
            <li
                (click)="openCreateSupportCaseModal(createEditSupportCaseModal)"
                class="list-group-item list-group-item-action"
                id="wizard-support-case-button"
            >
                Open Support Case
            </li>
            <li
                *isAllowed="'PURE1:view:upgrade_purity_software'"
                (click)="openUpgradeScheduler(upgradeSchedulerModal)"
                class="list-group-item list-group-item-action"
                id="wizard-upgrade-button"
            >
                Schedule Assisted Upgrade
            </li>
        </ul>
        <button
            class="btn btn-primary btn-block"
            [disabled]="!hasSNOWContact"
            [ngbTooltip]="hasSNOWContact === false ? NO_SNOW_CONTACT_MSG : null"
            (click)="clickButton()"
            id="request-service-button"
        >
            Request Service&nbsp;&nbsp;
            <i class="fa" [class.fa-caret-up]="!isPopupOpen" [class.fa-caret-down]="isPopupOpen"></i>
        </button>
        <div *ngIf="exportManager.overlayVisibilityChange$ | async" class="export-status-bar-page-spacer"> </div>
    </div>
</div>

<ng-template #upgradeSchedulerModal let-modal>
    <upgrade-scheduler [activeModal]="modal" (onCaseCreated)="onCaseCreated($event)"> </upgrade-scheduler>
</ng-template>

<ng-template #createEditSupportCaseModal let-modal>
    <create-edit-support-case [activeModal]="modal" [caseTemplate]="selectedSupportCaseTemplate">
    </create-edit-support-case>
</ng-template>
