import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StepUpError, StepUpFactor, StepUpVerifyResponse } from '@pure1/data';

@Component({
    selector: 'step-up-registration-modal',
    templateUrl: 'step-up-registration-modal.component.html',
})
export class StepUpRegistrationModalComponent {
    @Input() readonly activeFactor: StepUpFactor;

    stepUpVerifyResponse: StepUpVerifyResponse;
    activatedTotpFactor = false;

    constructor(private activeModal: NgbActiveModal) {}

    verifySuccess(stepUpVerifyResponse: StepUpVerifyResponse): void {
        this.stepUpVerifyResponse = stepUpVerifyResponse;
    }

    verifyFailure(stepUpError: StepUpError): void {
        this.activeModal.dismiss(stepUpError);
    }

    activateSuccess(): void {
        this.activatedTotpFactor = true;
    }

    close(): void {
        this.activeModal.close();
    }

    cancel(): void {
        this.activeModal.dismiss({ error: 'cancelled', errorDescription: 'cancelled' });
    }
}
