import stableStringify from 'json-stable-stringify';
import moment from 'moment';
import { Observable, ReplaySubject, of } from 'rxjs';
import { flatMap, filter, map, defaultIfEmpty, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AutocompleteService, IAutocompleteKey, AutocompleteValueResponse } from '@pure1/data';
import { smartTimer } from '@pstg/smart-timer';

interface IKeyCacheKey {
    entity: string;
    namespace: string;
    key: string;
}

@Injectable({ providedIn: 'root' })
export class DisplayKeyValueLookupService {
    private keyCache = new Map<string, Observable<IAutocompleteKey[]>>();
    private valueCache = new Map<string, Observable<AutocompleteValueResponse[]>>();
    private readonly valueCacheTTL = moment.duration(5, 'minutes').asMilliseconds();
    private readonly keyCacheTTL = moment.duration(60, 'minutes').asMilliseconds();

    constructor(private autocomplete: AutocompleteService) {
        smartTimer(this.keyCacheTTL, this.keyCacheTTL, this.keyCacheTTL).subscribe((): void => {
            this.keyCache = new Map<string, Observable<IAutocompleteKey[]>>();
        });
        smartTimer(this.valueCacheTTL, this.valueCacheTTL, this.valueCacheTTL).subscribe((): void => {
            this.valueCache = new Map<string, Observable<AutocompleteValueResponse[]>>();
        });
    }

    getDisplayKey(entity: string, namespace: string, key: string): Observable<string> {
        return this.getKeysWithCache(entity).pipe(
            flatMap(keys => keys),
            filter(entityKey => entityKey.key === key && entityKey.namespace === namespace),
            map(entityKey => entityKey.display_key),
            defaultIfEmpty(key), // default value for display key is just the key itself
            take(1),
        );
    }

    getDisplayValue(entity: string, namespace: string, key: string, value: string): Observable<string> {
        return this.getValuesWithCache(entity, key, namespace).pipe(
            flatMap(values => values),
            filter(
                entityValue =>
                    entityValue.key === key && entityValue.namespace === namespace && entityValue.value === value,
            ),
            map(entityValue => entityValue.display_value),
            defaultIfEmpty(value), // default value for display value is just the value itself
            take(1),
        );
    }

    private getKeysWithCache(entity: string): Observable<IAutocompleteKey[]> {
        if (!this.keyCache.has(entity)) {
            const subject = new ReplaySubject<IAutocompleteKey[]>(1);
            this.keyCache.set(entity, subject);
            this.autocomplete
                .getKeys(of({ entity, match: '' }))
                .pipe(
                    take(1),
                    map((result: { entity: string; keys: IAutocompleteKey[] }) => {
                        return result.keys;
                    }),
                )
                .subscribe(keys => {
                    subject.next(keys);
                    subject.complete(); // we need to complete so that first() knows to choose default if nothing matches
                });
            return subject;
        }
        return this.keyCache.get(entity);
    }

    private getValuesWithCache(
        entity: string,
        key: string,
        namespace: string,
    ): Observable<AutocompleteValueResponse[]> {
        const valueKey: IKeyCacheKey = { entity, key, namespace };
        const serializedValueKey = stableStringify(valueKey);
        if (!this.valueCache.has(serializedValueKey)) {
            const subject = new ReplaySubject<AutocompleteValueResponse[]>(1);
            this.valueCache.set(serializedValueKey, subject);
            this.autocomplete
                .getValues(of({ entity, key, namespace, match: '' }))
                .pipe(take(1))
                .subscribe(values => {
                    subject.next(values);
                    subject.complete(); // we need to complete so that first() knows to choose default if nothing matches
                });
            return subject;
        }
        return this.valueCache.get(serializedValueKey);
    }
}
