<hive-content-dialog
    #pure4sightOnboardingModalDialog
    size="medium"
    [showCloseButton]="true"
    (contentDialogClose)="dismissModalDialog()"
    heading="Welcome to AI CoPilot™"
>
    <!-- use a specific class selector since this will be created in the DOM outside of the component -->
    <div class="pure4sight-onboarding-modal-dialog-content">
        <svg hive-svg="ai-onboarding-background.svg" class="dialog-background-img" [width]="644" [height]="330"></svg>

        <div class="dialog-description-container">
            <div class="dialog-description-icon">
                <svg hive-svg="ai-sparkles.svg" [width]="24" [height]="24"></svg>
            </div>
            <span class="dialog-description-text hive-paragraph-small hive-paragraph-regular">
                AI CoPilot™ is your cutting-edge intelligence that accelerates tasks, optimizes storage, and enhances
                security. Streamline your workflow and boost productivity with smart, intuitive solutions. Unlock your
                full potential with Pure Intelligence from bottom right corner.
            </span>
        </div>
    </div>

    <!-- use a specific class selector since this will be created in the DOM outside of the component -->
    <div class="pure4sight-onboarding-modal-dialog-actions-container" slot="actions-right">
        <hive-button emphasis="secondary" label="Maybe Later" (click)="dismissModalDialog()"> </hive-button>
        <hive-button emphasis="primary" label="Try AI CoPilot™" (click)="ackOnboardingModal()"> </hive-button>
    </div>
</hive-content-dialog>
