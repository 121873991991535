<pureui-svg class="pstg-action-icon" [svg]="summaryInfo.svg" [height]="16"></pureui-svg>
<div class="text">
    <div>{{ summaryInfo.title }}</div>
    <div class="total">{{ summaryInfo.value | defaultString }}</div>
</div>
<pureui-svg
    *ngIf="summaryInfo.tooltip"
    class="info-icon"
    svg="info-circle.svg"
    height="13"
    hiveTooltip
    [hiveTooltipDescription]="summaryInfo.tooltip"
    container="body"
    placement="top"
>
</pureui-svg>
