import { Injectable } from '@angular/core';
import {
    Observable,
    ReplaySubject,
    catchError,
    defer,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    tap,
    throwError,
} from 'rxjs';

/**
 * SpinnerService provides a way to manage "Loading..." status for some async operations (usually BE requests).
 * Using SpinnerService in conjuction with AsyncSpinnerPipe, there's no need to manually handle "loading" field in components.
 * Can be used as a standalone service.
 * Example:
 * `
 *      In template: <pureui-spinner [pureuiShow]="'usersRequest' | asyncSpinner" />
 *      In component: this.usersService.get().pipe(this.spinnerService.spin('usersRequest')).subscribe(...);
 * `
 * In the provided example, we see that `usersRequest` in template, and `usersRequest` in spin method have the same string.
 * This helps to identify which request should spin which spinner, because we might have multiple spinners and multiple observables.
 */
@Injectable({ providedIn: 'root' })
export class SpinnerService {
    // Spinners should be identified by name, so that we won't spin some other spinners by accident.
    private readonly spinnerUpdates$ = new ReplaySubject<{ name: string; active: boolean }>();

    // For each spinner identified by `name` (string), we want to count how many times it was started.
    // This is done for a cases, when we're running multiple requests with the same spinner name.
    // We want for spinner to be running, until all observables emitted at least once.
    private readonly spinnerMap = new Map<string, number>();

    readonly spinner$ = this.spinnerUpdates$.asObservable().pipe(shareReplay(1));

    /**
     * Subscribe to spinner updates by spinner's `name`
     * @param name - name of a spinner.
     * @returns Observable<boolean> - whether spinner with provided `name` is spinning or not.
     */
    getSpinnerUpdates(name: string): Observable<boolean> {
        return this.spinner$.pipe(
            filter(spinner => spinner.name === name),
            map(spinner => spinner.active),
            distinctUntilChanged(),
        );
    }

    /**
     * Sets up spinner, by introducing it for an observable.
     * Possible usage example: timer(1000).pipe(this.spinnerService.spin('getUser')).subscribe();
     * In this example, spinner by name 'getUser' will start as soon as we've subscribed to the provided observable.
     * Spinner will end after 1000 ms, when observable emits a value, or fails.
     * @param name - name of a spinner.
     * @returns
     */
    spin<T>(name: string): (obs$: Observable<T>) => Observable<T> {
        let spinnerDecremented = false;
        const tryToDecrementSpinner = () => {
            if (!spinnerDecremented) {
                spinnerDecremented = true;
                this.decrementSpinner(name);
            }
        };
        return (obs$: Observable<T>): Observable<T> => {
            return defer(() => {
                this.incrementSpinner(name);
                return obs$.pipe(
                    // Trying to stop the spin ner whenever new value emits.
                    tap(tryToDecrementSpinner),
                    catchError(err => {
                        tryToDecrementSpinner();
                        return throwError(() => err);
                    }),
                );
            });
        };
    }

    /**
     * Increments amount of spinners by provided `name`.
     * @param name - name of a spinner.
     */
    protected incrementSpinner(name: string): void {
        if (!this.spinnerMap.has(name)) {
            this.spinnerMap.set(name, 0);
        }
        const count = this.spinnerMap.get(name);
        if (count === 0) {
            this.spinnerUpdates$.next({
                name,
                active: true,
            });
        }
        this.spinnerMap.set(name, count + 1);
    }

    /**
     * Decrement amount of spinners by provided `name`.
     * @param name - name of a spinner.
     */
    protected decrementSpinner(name: string): void {
        if (this.spinnerMap.has(name)) {
            const count = this.spinnerMap.get(name);
            if (count === 1) {
                this.spinnerUpdates$.next({
                    name,
                    active: false,
                });
                this.spinnerMap.delete(name);
            } else {
                this.spinnerMap.set(name, count - 1);
            }
        }
    }
}
