import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Resource } from '@pure1/data';
import { pick } from 'lodash';
import { VersionedArray } from '../../types';
import { PureArray } from '../../../../model/PureArray';
import { SupportUpgradeService } from '../../services/support-upgrade.service';
import { debounceTime, ignoreElements, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { UpgradeCaseDraftService } from '../../services/upgrade-case-draft.service';
import { ProductLine, UpgradeScheduleDraft } from '../../../support.interface';
import {
    CduAppliance,
    Policy,
    PolicyVersionsModel,
    Release,
    UpgradePath,
    UpgradePoliciesModel,
    UpgradeVersion,
} from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.interface';
import {
    compareReleases,
    getAllApplianceVersions,
    getArrayUniqueName,
    getReleaseText,
    getUpgradePathRequiredInfoFromAppliance,
    hadValidContractStatus,
    isApplianceFlashArray,
} from '../../../../software-lifecycle/purity-upgrades/purity-upgrades.utils';
import { ToastService, ToastType } from '../../../../services/toast.service';
import { ExpandableDropdownItem } from '../../../../ui/components/expandable-dropdown/expandable-dropdown.component';
import { ReleasesService } from '../../../../software-lifecycle/purity-upgrades/services/releases.service';

interface ArrayVersionsHops {
    [version: string]: number;
}

interface UpgradePathByTargetVersion {
    [version: string]: UpgradePath;
}

interface ArrayVersionError {
    arrayId: string;
    errorMessage: string;
}

interface TableFilter {
    name: string;
    model: string;
    version: string;
}

type SortableColumns = 'hostname' | 'model' | 'version';

enum DisabledInfo {
    OUT_OF_SUPPORT = 'Support expired',
    NO_AVAILABLE_VERSIONS = 'No available versions found',
    EXISTING_CASE = 'An upgrade case is already scheduled for this array. Please see case',
}

@Component({
    selector: 'array-selector',
    templateUrl: 'array-selector.component.html',
})
export class ArraySelectorComponent implements OnChanges, OnInit {
    @Input() readonly arrayThreshold: number;
    @Input() readonly arrays: PureArray[] = [];
    @Input() readonly appliances: CduAppliance[] = [];
    @Input() readonly loading: boolean;
    @Input() readonly preselectedApplianceIds: string[] = [];
    @Input() readonly preselectedCommonVersion: string;
    @Input() readonly hasSNOWContact: boolean;
    @Input() readonly isSNOWInitialized: boolean;
    @Input() readonly replicationTargets: { [arrayId: string]: CduAppliance };
    @Input() readonly releasesMap: { [key: string]: Release } = {};
    @Input() readonly versionPolicies: { [key: string]: PolicyVersionsModel } = {};
    @Input() readonly hasDuplicateApplianceNames: boolean;

    @Output() readonly onCancel = new EventEmitter();
    @Output() readonly onForward = new EventEmitter<VersionedArray[]>();
    private readonly destroy$: Subject<void> = new Subject<void>();

    sortColumnName: SortableColumns = 'hostname';
    isSortingAscending = true;

    nameFilter = '';
    modelFilter = '';
    currentVersionFilter = '';
    filter$ = new BehaviorSubject<TableFilter>({
        name: '',
        version: '',
        model: '',
    });

    filteredSortedArrays: CduAppliance[] = [];

    // used for a quick array search by arrayId
    arraysMap = new Map<string, PureArray>();

    // selected arrays map tracks which arrays's checkboxes are checked
    // do not manipulate this directly, use selectArray and unselectArray instead
    selectedArraysMap: { [arrayId: string]: boolean } = {};

    // array of selected arrays
    // do not manipulate this directly, use selectArray and unselectArray instead
    selectedArrays: Resource[] = [];

    // versions map tracks which version is selected (version dropdown value) for each array
    selectedVersions: { [arrayId: string]: string } = {};

    // this tracks number of hops for each array to certain version
    arrayToVersionHops: { [arrayId: string]: ArrayVersionsHops } = {};

    upgradePathsMap: { [arrayId: string]: UpgradePathByTargetVersion } = {};

    // list of available versions for the 'same for all' dropdown
    // it will always be the common set of versions among all the selected arrays
    sameForAllAvailableVersions: UpgradeVersion[] = [];

    // Tracker any loadings going on on certain arrays
    arrayLoadingCounter: { [arrayId: string]: number } = {};

    // disabled arrays map tells you which arrays can't be selected for upgrade, either because
    // of the 'same for all' selected version or because of the 'out of support' flag
    // each array is mapped to a text containing the reason for disability
    disabledArrays: { [arrayId: string]: string } = {};

    // this array contains version error which can happen either when loading available versions or number of hops
    arrayVersionErrors: ArrayVersionError[] = [];
    // map of version errors for quick access from template
    arrayVersionErrorsMap: { [arrayId: string]: ArrayVersionError } = {};

    disableForward = true;

    apliancesMap = new Map<string, CduAppliance>();
    commonVersionsDropdownItems: ExpandableDropdownItem[] = [];
    upgradePolicies: UpgradePoliciesModel[] = [
        { code: Policy.BALANCED, displayName: 'Balanced' },
        { code: Policy.ESSENTIAL, displayName: 'Essential' },
        { code: Policy.PROACTIVE, displayName: 'Proactive' },
    ];

    someReplicatedAppliances = false;

    simplifiedArrayFields = ['applianceId', 'hostname', 'currentVersion', 'domain'];

    constructor(
        private upgradeService: SupportUpgradeService,
        private draftService: UpgradeCaseDraftService,
        private toastService: ToastService,
        private releasesService: ReleasesService,
    ) {}

    ngOnInit(): void {
        this.filter$.pipe(debounceTime(250)).subscribe(value => {
            this.updateFilteredArrays(value);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.loading) {
            return;
        }
        // disable arrays out of support
        if (changes.arrays || changes.loading) {
            this.arraysMap.clear();
            this.arrayLoadingCounter = {};
            this.arrayVersionErrors = [];
            this.arrayVersionErrorsMap = {};

            this.currentVersionFilter = '';
            this.modelFilter = '';
            this.arrays.forEach(arr => {
                this.arraysMap.set(arr.arrayId, arr);
                this.arrayToVersionHops[arr.arrayId] = {};
                this.upgradePathsMap[arr.arrayId] = {};
                if (arr.isOutOfSupport()) {
                    this.disabledArrays[arr.arrayId] =
                        DisabledInfo.OUT_OF_SUPPORT + ' on ' + arr.contract_expiration?.format('YYYY-MM-DD');
                    this.unselectArray(arr.arrayId);
                }

                this.arrayLoadingCounter[arr.arrayId] = 0;
            });

            this.filteredSortedArrays = [...this.appliances];

            if (this.arrays.length > 0 && !this.preselectedApplianceIds.length) {
                this.parseDraft();
            }

            const supportedArrayIds = this.arrays.filter(x => !x.isOutOfSupport()).map(x => x.arrayId);
            this.upgradeService.getExistingUpgradeParentCases(supportedArrayIds).subscribe(existingCases => {
                Object.keys(existingCases).forEach(key => {
                    if (existingCases[key]) {
                        this.disabledArrays[key] = DisabledInfo.EXISTING_CASE + ` ${existingCases[key].caseNumber}.`;
                        this.unselectArray(key);
                    }
                });
            });

            this.updateFilteredArrays({
                name: '',
                model: '',
                version: '',
            });

            if (this.preselectedApplianceIds && this.arrays.length > 0) {
                this.preselectedApplianceIds.forEach(preselectedId => {
                    this.selectedVersions[preselectedId] = this.preselectedCommonVersion;
                    if (this.preselectedCommonVersion && this.apliancesMap.has(preselectedId)) {
                        this.ensureUpgradeHopsAreLoaded(preselectedId, this.preselectedCommonVersion);
                    }
                    if (
                        !this.isApplianceReplicated(preselectedId) ||
                        (this.isReplicationAppliancePrimary(preselectedId) &&
                            this.isReplicatedApplianceNextToOther(preselectedId))
                    ) {
                        this.toggleArraySelection(preselectedId);
                    }
                });
            }

            if (this.nameFilter) {
                this.updateFilter();
            }
        }

        if (changes.appliances || changes.versionPolicies) {
            this.appliances.forEach(appliance => {
                this.selectedArraysMap[appliance.applianceId] = !hadValidContractStatus(appliance)
                    ? false
                    : this.selectedArraysMap[appliance.applianceId];
                this.apliancesMap.set(appliance.applianceId, appliance);
                const versions = getAllApplianceVersions(this.versionPolicies, appliance.applianceId);
                const availableVersions = (versions || [])
                    .map(x => this.releasesMap[x])
                    .filter(x => x)
                    .sort(compareReleases);
                // if there is another error then just skip this as the array would be disabled anyway
                if (
                    this.disabledArrays[appliance.applianceId] &&
                    this.disabledArrays[appliance.applianceId] !== DisabledInfo.NO_AVAILABLE_VERSIONS
                ) {
                    return;
                }
                // if there are no available upgrade versions, disable this array and unselect it
                if (!availableVersions || availableVersions.length === 0) {
                    this.disabledArrays[appliance.applianceId] = DisabledInfo.NO_AVAILABLE_VERSIONS;
                    this.unselectArray(appliance.applianceId);
                } else {
                    if (this.disabledArrays[appliance.applianceId] === DisabledInfo.NO_AVAILABLE_VERSIONS) {
                        delete this.disabledArrays[appliance.applianceId];
                    }
                }

                this.commonVersionsDropdownItems[appliance.applianceId] =
                    availableVersions.map(v => {
                        const versionOutOfPolicy = Object.keys(this.versionPolicies).includes(appliance.applianceId)
                            ? this.versionPolicies[appliance.applianceId].outOfPolicyVersions
                                  .map(x => x.version)
                                  .includes(v.versionFull)
                            : true;
                        return {
                            value: v.versionFull,
                            displayText: getReleaseText(v),
                            hidden: versionOutOfPolicy,
                            icon: versionOutOfPolicy ? 'circle-exclamation.svg' : null,
                            iconTooltip: versionOutOfPolicy ? 'This version is out of Policy.' : null,
                        };
                    }) || [];
            });
        }
    }

    getSortClass(columnName: SortableColumns): string {
        if (this.someReplicatedAppliances || this.loading) {
            return null;
        }

        if (columnName === this.sortColumnName) {
            const sortClass = 'st-sort-' + (this.isSortingAscending ? 'ascent' : 'descent');
            return `manual-sort ${sortClass}`;
        }

        return 'manual-sort';
    }

    sortByColumn(columnName: SortableColumns): void {
        if (this.someReplicatedAppliances || this.loading) {
            return;
        }

        if (this.sortColumnName === columnName) {
            this.isSortingAscending = !this.isSortingAscending;
        } else {
            this.sortColumnName = columnName;
            this.isSortingAscending = true;
        }

        this.sortArrays();
    }

    sortArrays(): void {
        const sortDirection = this.isSortingAscending ? 1 : -1;
        this.filteredSortedArrays = this.filteredSortedArrays.sort(
            (a, b) => sortDirection * a[this.sortColumnName].localeCompare(b[this.sortColumnName]),
        );
    }

    updateFilter(): void {
        this.filter$.next({
            name: this.nameFilter,
            model: this.modelFilter,
            version: this.currentVersionFilter,
        });
    }

    updateFilteredArrays(filter: TableFilter): void {
        if (!this.appliances) {
            return;
        }
        this.filteredSortedArrays = this.appliances
            .filter(arr => {
                if (!filter.name) {
                    return true;
                }
                if (arr.hostname.toLowerCase().indexOf(filter.name.toLowerCase()) > -1) {
                    return true;
                } else if (this.replicationTargets[arr.applianceId]) {
                    return (
                        this.replicationTargets[arr.applianceId].hostname
                            .toLowerCase()
                            .indexOf(filter.name.toLowerCase()) > -1
                    );
                }
                return false;
            })
            .filter(arr => {
                if (!filter.version) {
                    return true;
                }
                if (arr.currentVersion?.indexOf(filter.version) > -1) {
                    return true;
                } else if (this.replicationTargets[arr.applianceId]) {
                    return this.replicationTargets[arr.applianceId].currentVersion?.indexOf(filter.version) > -1;
                }
                return false;
            })
            .filter(arr => {
                if (!filter.model) {
                    return true;
                }
                if (arr.hardwareModel?.indexOf(filter.model) > -1) {
                    return true;
                } else if (this.replicationTargets[arr.applianceId]) {
                    return this.replicationTargets[arr.applianceId].hardwareModel?.indexOf(filter.model) > -1;
                }
                return false;
            });

        this.someReplicatedAppliances = this.isAnyApplianceReplicated();
    }

    // builds output data structure and emits onForward event
    onNext(): void {
        const resultData: VersionedArray[] = [];
        for (const arrayId in this.selectedArraysMap) {
            if (this.selectedArraysMap[arrayId]) {
                const targetVersion = this.selectedVersions[arrayId];
                const arrayObj = this.appliances.find(a => a.applianceId === arrayId);
                if (this.isApplianceReplicated(arrayId)) {
                    if (this.isReplicationAppliancePrimary(arrayId) && this.isReplicatedApplianceNextToOther(arrayId)) {
                        const replicatedArrayObj = this.getReplicationAppliance(arrayId);
                        resultData.push({
                            targetVersion,
                            array: pick(arrayObj, this.simplifiedArrayFields),
                            secondaryArray: pick(replicatedArrayObj, this.simplifiedArrayFields),
                            numberOfHops: this.arrayToVersionHops[arrayId][targetVersion],
                            productLine: isApplianceFlashArray(arrayObj)
                                ? ProductLine.FlashArray
                                : ProductLine.FlashBlade,
                            upgradeDurationSeconds: this.upgradePathsMap[arrayId][targetVersion].upgradeDurationSeconds,
                        });
                    } else {
                        continue;
                    }
                } else {
                    resultData.push({
                        targetVersion,
                        array: pick(arrayObj, this.simplifiedArrayFields),
                        numberOfHops: this.arrayToVersionHops[arrayId][targetVersion],
                        productLine: isApplianceFlashArray(arrayObj) ? ProductLine.FlashArray : ProductLine.FlashBlade,
                        upgradeDurationSeconds: this.upgradePathsMap[arrayId][targetVersion].upgradeDurationSeconds,
                    });
                }
            }
        }
        this.onForward.emit(resultData);
    }

    // Updates the arrayToVersionHops map (retrieves amount of hops required from api).
    // Returns an observable that completes when all the async ops are finished.
    ensureUpgradeHopsAreLoaded(arrayId: string, version: string): Observable<void> {
        if (!this.arrayToVersionHops[arrayId]) {
            this.arrayToVersionHops[arrayId] = {};
        }

        this.updateDisableForward();

        if (this.arrayToVersionHops[arrayId][version]) {
            return of();
        }

        const releaseCompareResult = compareReleases(
            this.releasesMap[this.arraysMap.get(arrayId).version],
            this.releasesMap[version],
        );
        if (releaseCompareResult < 1) {
            // current version is not lower than target version
            if (releaseCompareResult === 0) {
                this.setArrayError(arrayId, 'Array already is on selected version');
            } else {
                this.setArrayError(arrayId, 'Array already is on higher version than the selected version');
            }

            this.updateDisableForward();
            return of();
        }

        this.arrayLoadingCounter[arrayId]++;
        const dataRequest$ = this.releasesService
            .getUpgradePath([
                { ...getUpgradePathRequiredInfoFromAppliance(this.apliancesMap.get(arrayId)), targetVersion: version },
            ])
            .pipe(take(1));
        dataRequest$.subscribe({
            next: upgradePath => {
                this.arrayToVersionHops[arrayId][version] = upgradePath[arrayId][0]?.upgradeVersions?.length - 1 || 0;
                this.upgradePathsMap[arrayId][version] = upgradePath[arrayId][0];

                this.arrayLoadingCounter[arrayId]--;
                this.updateDisableForward();
            },
            error: (response: HttpErrorResponse) => {
                this.arrayLoadingCounter[arrayId]--;
                let errorMessage;
                if (response.error.message) {
                    const error = JSON.parse(response.error.message);
                    errorMessage = error.errors[0].message;
                } else {
                    errorMessage = 'Cannot get information about upgrading to this version, please contact support.';
                }
                this.setArrayError(arrayId, errorMessage);
                this.updateDisableForward();
            },
        });
        return dataRequest$.pipe(ignoreElements());
    }

    selectArray(arrayId: string): void {
        if (!this.selectedArrays.find(resource => resource.id === arrayId)) {
            const array = this.arrays.find(arr => arr.arrayId === arrayId);

            // when selecting arrays based on draft it could happen that selected array is no longer available
            if (array) {
                this.selectedArraysMap[arrayId] = true;
                this.selectedArrays = [
                    ...this.selectedArrays,
                    {
                        id: arrayId,
                        name: array.hostname,
                    },
                ];

                if (this.hasSsuAvailable(array.version)) {
                    this.toastService.add(
                        ToastType.error,
                        'Check out the Self-Service Upgrade option for selected appliance.',
                    );
                }
            }
        }

        this.removeArrayError(arrayId);
    }

    unselectArray(arrayId: string): void {
        this.selectedArraysMap[arrayId] = false;
        this.selectedArrays = this.selectedArrays.filter(arr => arr.id !== arrayId);

        this.removeArrayError(arrayId);
    }

    updateSelectedArrays(updatedResources: Resource[]): void {
        const itemsToUnselect: Resource[] = [];
        this.selectedArrays.forEach(resource => {
            if (!updatedResources.find(updatedResource => updatedResource.id === resource.id)) {
                itemsToUnselect.push(resource);
            }
        });

        itemsToUnselect.forEach(resource => {
            this.unselectArray(resource.id);
        });
    }

    hasSsuAvailable(version: string): boolean {
        return this.releasesMap[version]?.emsSupported;
    }

    onVersionSelectedForArray(arrayId: string, version: string): void {
        this.selectedVersions[arrayId] = version;

        this.ensureUpgradeHopsAreLoaded(arrayId, version);

        const replicationTarget = this.replicationTargets[arrayId];
        if (replicationTarget) {
            this.selectedVersions[replicationTarget.applianceId] = version;
            this.ensureUpgradeHopsAreLoaded(replicationTarget.applianceId, version);
        }
        this.updateDraft();
    }

    /**
     * Marks array as selected/unselected by updating the selectedArraysMap. Triggers a bunch of async side effects.
     * Returns an observable that completes when all triggered side effects are finished.
     * As a side effect of the _first_ selection change, this method will retrieve the available upgrade versions for
     * the array and store the list in the availableVersions map. Also, if the 'same for all' option is active, the
     * number of hops for the selected array will be fetched. Subsequent selects/unselects will not trigger this action.
     * If there are no available versions for the array, it will be disabled for future selection in disabledArrays map.
     * Another side effect of selecting/unselecting an array is the update of the list of versions that are common to
     * all selected arrays. (This list is used in the 'same for all' dropdown.)
     * After every selection/unselection of an array, a round of checks is performed to determine whether the user is
     * allowed to move to the next wizard step (disabledForward flag).
     * @param arrayId
     */
    toggleArraySelection(arrayId: string, ignoreReplication = false): Observable<void> {
        const pendingAsyncOps: Observable<any>[] = []; // when all ops are completed, we consider all side effects done
        if (this.selectedArraysMap[arrayId]) {
            this.unselectArray(arrayId);
            if (!ignoreReplication && this.replicationTargets[arrayId]) {
                this.unselectArray(this.replicationTargets[arrayId].applianceId);
            }
        } else {
            this.selectArray(arrayId);
            if (!ignoreReplication && this.replicationTargets[arrayId]) {
                this.selectArray(this.replicationTargets[arrayId].applianceId);
            }
        }

        // If array is selected and same version for all is selected then load hops for it
        this.updateDisableForward();

        // if array was unselected, then remove it from the draft
        if (!this.selectedArraysMap[arrayId]) {
            this.updateDraft();
        }

        return forkJoin(pendingAsyncOps).pipe(ignoreElements());
    }

    setArrayError(arrayId: string, errorMessage: string): void {
        // remove potential old error
        this.removeArrayError(arrayId);

        const error = {
            arrayId,
            errorMessage,
        };
        this.arrayVersionErrorsMap = {
            ...this.arrayVersionErrorsMap,
            [arrayId]: error,
        };
        this.arrayVersionErrors = [...this.arrayVersionErrors, error];
    }

    removeArrayError(arrayId: string): void {
        const error = this.arrayVersionErrorsMap[arrayId];
        if (error) {
            const { [arrayId]: _, ...restOfErrors } = this.arrayVersionErrorsMap;
            this.arrayVersionErrorsMap = restOfErrors;
            this.arrayVersionErrors = this.arrayVersionErrors.filter(e => e !== error);
        }
    }

    updateDisableForward(): void {
        if (!this.hasSNOWContact) {
            this.disableForward = true;
            return;
        }

        const selectedArrays = this.arrays.filter(({ arrayId }) => this.selectedArraysMap[arrayId]);
        if (selectedArrays.length === 0) {
            this.disableForward = true;
            return;
        }

        // Cannot go forward when there are selected arrays with version errors
        if (this.arrayVersionErrors.filter(error => this.selectedArraysMap[error.arrayId]).length > 0) {
            this.disableForward = true;
            return;
        }

        // Cannot go forward when there are selected arrays without selected version or without hops loaded
        if (
            selectedArrays.some(({ arrayId }) => {
                const version = this.selectedVersions[arrayId];
                if (!version) {
                    return true;
                }
                const hops = this.arrayToVersionHops[arrayId][version];
                return !hops;
            })
        ) {
            this.disableForward = true;
            return;
        }

        // For now, we cannot go forward if there are more than 6 selected arrays,
        // (this is just temporary until we figure out some 7 and more details)
        if (selectedArrays.length > this.arrayThreshold) {
            this.disableForward = true;
            return;
        }

        this.disableForward = false;
    }

    trackByArrayId(index: number, item: ArrayVersionError): string {
        return item.arrayId;
    }

    getPolicyName(policy: string): string {
        return this.upgradePolicies.find(p => p.code === policy)?.displayName;
    }

    isReplicationAppliancePrimary(applianceId: string): boolean {
        const appliance = this.getApplianceById(applianceId);
        if (!appliance) {
            return null;
        }

        return (
            this.isApplianceReplicated(applianceId) &&
            this.isApplianceAfter(this.getReplicationAppliance(appliance.applianceId), appliance)
        );
    }

    isReplicatedApplianceNextToOther(applianceId: string): boolean {
        const appliance = this.getApplianceById(applianceId);
        const replicatedAppliance = this.getReplicationAppliance(applianceId);
        if (replicatedAppliance) {
            const applianceIds = this.appliances.map(a => a.applianceId);
            return (
                Math.abs(
                    applianceIds.indexOf(appliance.applianceId) - applianceIds.indexOf(replicatedAppliance.applianceId),
                ) === 1
            );
        }

        return false;
    }

    getRowClass(applianceId: string): string[] {
        if (this.isApplianceReplicated(applianceId)) {
            return ['replicated-row', this.isReplicationAppliancePrimary(applianceId) ? 'primary' : 'secondary'];
        } else {
            return [];
        }
    }

    isAnyApplianceReplicated(): boolean {
        return this.filteredSortedArrays.some(a => this.isApplianceReplicated(a.applianceId));
    }

    isApplianceReplicated(applianceId: string): boolean {
        const appliance = this.getApplianceById(applianceId);
        if (!appliance) {
            return false;
        }

        const replicatedAppliance = this.getReplicationAppliance(appliance.applianceId);

        if (replicatedAppliance) {
            const replicatedCheck = this.getReplicationAppliance(replicatedAppliance.applianceId);
            if (replicatedCheck) {
                if (replicatedCheck.applianceId === applianceId) {
                    return true;
                }
            }
        }

        return false;
    }

    getReplicationAppliance(applianceId: string): CduAppliance {
        const appliance = this.getApplianceById(applianceId);
        if (!appliance) {
            return null;
        }

        return this.replicationTargets[appliance.applianceId] || null;
    }

    isApplianceAfter(appliance1: CduAppliance, appliance2: CduAppliance): boolean {
        const applianceIds = this.filteredSortedArrays.map(a => a.applianceId);
        return applianceIds.indexOf(appliance1?.applianceId) > applianceIds.indexOf(appliance2?.applianceId);
    }

    isReplicationApplianceSecondary(applianceId: string): boolean {
        const appliance = this.getApplianceById(applianceId);
        if (!appliance) {
            return null;
        }

        return (
            this.isApplianceReplicated(applianceId) &&
            this.isApplianceAfter(appliance, this.getReplicationAppliance(applianceId))
        );
    }

    getApplianceById(applianceId: string): CduAppliance {
        return this.appliances.find(a => a.applianceId === applianceId);
    }

    /**
     * Retrieves the draft schedule from draft service and parses it into the form inputs.
     * NOTE: assumes that the list of available arrays is already setup.
     */
    private parseDraft(): void {
        const draft: Partial<UpgradeScheduleDraft> = this.draftService.getDraft();
        if (draft.schedule && draft.schedule.length > 0) {
            draft.schedule.forEach(({ arrayId, targetPurityVersion }) => {
                // select array
                this.toggleArraySelection(arrayId, true).subscribe({
                    complete: () => {
                        // set version for array (we don't call onVersionSelectedForArray to avoid re-writing the draft
                        this.selectedVersions[arrayId] = targetPurityVersion;
                        this.ensureUpgradeHopsAreLoaded(arrayId, targetPurityVersion);
                    },
                });
            });
        }
    }

    /**
     * Builds a list of UpgradeScheduleItem and saves it as draft.
     */
    private updateDraft(): void {
        const schedule: { arrayId: string; targetPurityVersion: string }[] = [];
        for (const arrayId in this.selectedArraysMap) {
            if (this.selectedArraysMap[arrayId]) {
                schedule.push({
                    arrayId,
                    targetPurityVersion: this.selectedVersions[arrayId],
                });
            }
        }
        this.draftService.setDraftField('schedule', schedule);
    }

    getArrayName = (array: CduAppliance): string => getArrayUniqueName(array, this.hasDuplicateApplianceNames);

    isApplianceFlashArray = (array: CduAppliance): boolean => isApplianceFlashArray(array);
}
