import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { SSOUser } from '../models/sso-user';

@Injectable({ providedIn: 'root' })
export class SSOUsersService extends GenericService<SSOUser> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SSOUser,
            endpoint: '/rest/v1/sso-users/additional-roles',
            create: true,
            update: true,
            delete: false,
        });
    }
}
