import { cloneDeep } from 'lodash';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { markArray, textSearch, truncate } from '../../utils/helpers';
import { WINDOW } from '../../app/injection-tokens';
import { BehaviorSubject, Subject, defer } from 'rxjs';
import { CachedCurrentUserService } from '@pure1/data';
import { SupportCaseOrchestratorService } from '../services/support-case-orchestrator.service';
import { StorageService } from '../../services/storage.service';
import { ExportManagerService } from '../../export/services/export-manager.service';
import { SupportContactService } from '../services/support-contact.service';
import { CreatedUpgradeCaseResult, SupportCase } from '../support.interface';
import { CaseManager } from '../services/case-data.service';
import { ampli } from 'core/src/ampli';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { REQUEST_SSO_CASE } from '../support.utils';

interface ISupportNavViewState {
    searchText: string;
}

type SupportCaseState = 'open' | 'closed';

const persistKey = 'support_nav_view';

@Component({
    selector: 'support-nav',
    templateUrl: 'support-nav.component.html',
})
export class SupportNavComponent implements OnInit, OnChanges, OnDestroy {
    readonly ampli = ampli;

    @ViewChild('createEditSupportCaseModal') readonly createEditSupportCaseModal: TemplateRef<NgbActiveModal>;

    @Input() cases: SupportCase[];
    @Input() closedCases: SupportCase[];
    @Input() selectedCaseId: string;
    @Output() toggleSecondaryNavCollapsed = new EventEmitter<void>();
    @Output() newCaseCreated = new EventEmitter<string>();

    destroy$ = new Subject<void>();
    filter$ = new BehaviorSubject<string>('');
    selectedTab$ = new BehaviorSubject<SupportCaseState>('open');
    isPopupOpen = false;
    closedCasesLoading = false;
    filteredOpenCases: SupportCase[];
    filteredClosedCases: SupportCase[];
    lastSearchTextOnBlur = '';
    viewState: ISupportNavViewState;
    hasSNOWContact: boolean;
    selectedSupportCaseTemplate: Partial<SupportCase> = null;

    readonly NO_SNOW_CONTACT_MSG = 'Could not find a support contact to request a service';

    private isFirstLoadCompleted: boolean;

    constructor(
        private supportCaseOrchestrator: SupportCaseOrchestratorService,
        @Inject(WINDOW) private window: Window,
        private storageService: StorageService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private supportContactService: SupportContactService,
        public exportManager: ExportManagerService,
        public caseManager: CaseManager,
        public modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        if (this.selectedCaseId) {
            this.viewState = {
                searchText: '',
            };
            this.storageService.local.set(persistKey, this.viewState);
        } else {
            this.viewState = {
                searchText: '',
                ...(this.storageService.local.get(persistKey) || {}),
            };
        }
        this.cachedCurrentUserService
            .get()
            .pipe(
                switchMap(user => this.supportContactService.getContactById(user.id)),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => (this.hasSNOWContact = true),
                error: () => (this.hasSNOWContact = false),
            });

        this.updateSearchedCases(this.viewState.searchText);
        this.isFirstLoadCompleted = typeof this.cases !== 'undefined' && typeof this.closedCases !== 'undefined';
        if (this.isFirstLoadCompleted && this.selectedCaseId) {
            this.onFirstLoadCompleted();
        }
        if (this.cases) {
            this.supportCaseOrchestrator.numOfOpenCases = this.cases.length;
        }

        if (this.window.pure1.requestSsoCase) {
            this.window.pure1.requestSsoCase = false;
            this.openCreateSupportCaseModal(this.createEditSupportCaseModal, REQUEST_SSO_CASE);
        }

        this.selectedTab$
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.destroy$),
                filter(tab => tab === 'closed'),
                switchMap(() => this.fetchClosedCases()),
            )
            .subscribe();

        this.filter$.pipe(debounceTime(250), distinctUntilChanged()).subscribe(value => {
            this.updateSearchedCases(value);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.closeCreateCasePopup();
    }

    ngOnChanges(changesObj: SimpleChanges): void {
        const casesChanged = changesObj.cases && !changesObj.cases.isFirstChange();
        const closedCasesChanged = changesObj.closedCases && !changesObj.closedCases.isFirstChange();

        // If any of the lists has changed - update filtered lists
        if (casesChanged || closedCasesChanged) {
            this.updateSearchedCases(this.viewState.searchText);
            if (changesObj.cases) {
                this.supportCaseOrchestrator.numOfOpenCases = this.cases.length;
            }
        }

        if (this.isFirstLoadCompleted) {
            // Detects if the selected case has changed status (it goes closed or reopened) and then switches
            // to the proper tab
            if (changesObj.cases && this.selectedCaseAppeared(changesObj.cases)) {
                this.selectedTab$.next('open');
            }
            if (changesObj.closedCases && this.selectedCaseAppeared(changesObj.closedCases)) {
                this.selectedTab$.next('closed');
            }
        }

        // When cases changed during initialization, but selectedCase is not there - fetch closed cases
        if (casesChanged && this.selectedCaseId && !this.isFirstLoadCompleted) {
            if (!changesObj.cases.currentValue.some((x: SupportCase) => x.id === this.selectedCaseId)) {
                this.fetchClosedCases().subscribe();
            } else {
                this.isFirstLoadCompleted = true;
            }
        }

        // When closedCases changed during initialization and selectedCase is there - switch to "Closed Cases" tab
        if (closedCasesChanged && !this.isFirstLoadCompleted) {
            if (changesObj.closedCases.currentValue.some((x: SupportCase) => x.id === this.selectedCaseId)) {
                this.isFirstLoadCompleted = true;
                this.selectedTab$.next('closed');
            }
        }
    }

    search = (value: string): void => {
        this.filter$.next(value);
    };

    openCreateSupportCaseModal(modal: TemplateRef<NgbActiveModal>, template: Partial<SupportCase> = null): void {
        this.ampli.supportOpenSupportCaseClicked();
        this.isPopupOpen = false;
        this.selectedSupportCaseTemplate = template;
        this.modalService.open(modal);
    }

    openUpgradeScheduler(modal: TemplateRef<NgbActiveModal>): void {
        this.ampli.supportUpgradePuritySoftwareClicked();
        this.isPopupOpen = false;
        this.modalService.open(modal, { backdrop: 'static', size: 'lg' });
    }

    onCaseCreated = (caseResult: CreatedUpgradeCaseResult): void => {
        this.newCaseCreated.next(caseResult.internalCaseId);
    };

    clickButton(): void {
        this.ampli.supportRequestServiceClicked();
        if (this.isPopupOpen) {
            this.closeCreateCasePopup();
        } else {
            this.openCreateCasePopup();
        }
    }

    /**
     * Handles when the search input loses focus
     */
    onSearchTextBlur(): void {
        // Log event to GA. Using the blur event gives us more useful information than every time we query the server
        // since it better represents a 'unique search' by the user.

        // Make sure we don't send an event for the same value multiple times in a row (eg. if the user clicks the input
        // then clicks away before changing a value, or tabbing through it).
        if (this.viewState.searchText !== this.lastSearchTextOnBlur) {
            this.lastSearchTextOnBlur = this.viewState.searchText;

            // Save the search
            this.storageService.local.set(persistKey, this.viewState);

            // Don't log an empty search
            if (this.viewState.searchText) {
                this.ampli.supportFilterChanged({
                    value: this.viewState.searchText.length,
                });
            }
        }
    }

    clearSearchText(): void {
        this.viewState.searchText = '';
        this.updateSearchedCases(this.viewState.searchText);
    }

    clickSecondaryNavCollapser(): void {
        this.toggleSecondaryNavCollapsed.emit();
    }

    get selectedTab(): SupportCaseState {
        return this.selectedTab$.value;
    }
    set selectedTab(tab: SupportCaseState) {
        this.selectedTab$.next(tab);
    }

    private openCreateCasePopup = (): void => {
        this.isPopupOpen = true;
        this.window.document.addEventListener('click', this.documentClickHandler);
    };

    private closeCreateCasePopup = (): void => {
        this.isPopupOpen = false;
        this.window.document.removeEventListener('click', this.documentClickHandler);
    };

    private onFirstLoadCompleted(): void {
        if (this.closedCases.some((supportCase: SupportCase) => supportCase.id === this.selectedCaseId)) {
            this.selectedTab$.next('closed');
        } else {
            this.selectedTab$.next('open');
        }
    }

    private updateSearchedCases(searchString: string): void {
        this.filteredOpenCases = this.filterCasesByText(this.cases, searchString);
        this.filteredClosedCases = this.filterCasesByText(this.closedCases, searchString);
    }

    private filterCasesByText(cases: SupportCase[], text: string): SupportCase[] {
        if (cases === undefined) {
            return undefined;
        }

        text = text.toLowerCase();
        let filteredCases = cloneDeep(cases);

        // Data structure where the keys are the name of the fields where search is performed and value === false if the
        // field should be present even if it is not a match.
        const searchFields: { [key: string]: boolean } = {
            'array.hostname': false,
            'array.model': true,
            'array.version': true,
            caseNumber: false,
            subject: false,
            description: true,
        };

        if (text === '') {
            filteredCases = filteredCases.map((curCase: SupportCase): SupportCase => {
                curCase.subject = truncate(curCase.subject);
                return curCase;
            });
        } else {
            filteredCases = textSearch(filteredCases, Object.keys(searchFields), text);
        }
        filteredCases = markArray(filteredCases, searchFields, text, {
            classAttr: 'cases-nav-needle',
            encodeHtmlEntities: true,
        });

        return filteredCases;
    }

    private fetchClosedCases() {
        return defer(() => {
            this.closedCasesLoading = true;

            return this.caseManager.fetchClosedCases().pipe(tap(() => (this.closedCasesLoading = false)));
        });
    }

    private selectedCaseAppeared(changeObj: any): boolean {
        return (
            changeObj.currentValue.some((supportCase: SupportCase) => supportCase.id === this.selectedCaseId) &&
            !changeObj.previousValue.some((supportCase: SupportCase) => supportCase.id === this.selectedCaseId)
        );
    }

    private documentClickHandler = (event: any): void => {
        // Close list iff it's open & there is a click outside the targeted window
        if (event.target.closest('#nav-create-case')) {
            return;
        }
        if (this.isPopupOpen) {
            this.closeCreateCasePopup();
        }
    };
}
