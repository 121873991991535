import { UserRoleID } from '@pure1/data';
import { isLegacyRole } from './is-legacy-role';
import { UserRoleItem } from '../user-roles-select/user-role-item';

export const shouldDisplayRole = (item: UserRoleItem, selectedRoles: UserRoleID[]): boolean => {
    if (isLegacyRole(item.id)) {
        const selectedLegacyRoles = selectedRoles.filter(roleId => isLegacyRole(roleId));

        return selectedLegacyRoles.length === 0 || selectedRoles.some(roleId => roleId === item.id);
    }

    if (item.disabled) {
        return true;
    }

    const someLegacyRoleSelected = selectedRoles.some(roleId => isLegacyRole(roleId));

    return someLegacyRoleSelected;
};
