import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { Assignment } from '../user-role-management.interface';
import {
    AssigneeType,
    Collection,
    ExternalUsersService,
    GroupsService,
    QueryParams,
    RoleAssignment,
    RoleAssignmentService,
    UsersService,
    View,
    ViewsService,
} from '@pure1/data';
import { UserRoleStateService } from '../services/user-role-state.service';

const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again.';

@Component({
    selector: 'delete-view-modal',
    templateUrl: 'delete-view-modal.component.html',
})
export class DeleteViewModalComponent implements OnInit {
    @Input() readonly view: View;
    @Input() readonly modal: NgbActiveModal;

    @Output() readonly viewDeleted = new EventEmitter<void>();

    assignments: RoleAssignment[];
    loading = false;
    errorMessage = '';
    tableVisible = true;

    constructor(
        private viewsService: ViewsService,
        private roleAssignmentService: RoleAssignmentService,
        private externalUsersService: ExternalUsersService,
        private groupsService: GroupsService,
        private usersService: UsersService,
        public urStateService: UserRoleStateService,
    ) {}

    ngOnInit(): void {
        this.getAssignments();
    }

    toggleTableVisibility(): void {
        this.tableVisible = !this.tableVisible;
    }

    deleteView(): void {
        const id = this.view.id;
        this.loading = true;
        this.errorMessage = '';

        const deleteAssignments$ = this.removeAssignments();

        deleteAssignments$
            .pipe(
                concatMap(() => this.urStateService.deleteWithCache(this.viewsService, [id])),
                take(1),
            )
            .subscribe(
                () => {},
                error => {
                    this.loading = false;
                    const errorMsg = error.data?.errors?.[0].message || '';
                    if (errorMsg === 'Forbidden') {
                        // the backend returns a very unhelpful error message
                        this.errorMessage = GENERIC_ERROR_MESSAGE;
                    }
                },
                () => {
                    this.loading = false;
                    this.viewDeleted.emit();
                    this.modal.close();
                },
            );
    }

    isUsers(): boolean {
        return this.urStateService.currentMainPageState === 'users';
    }

    private removeAssignments(): Observable<void[]> {
        if (this.assignments?.length > 0) {
            const deleteList = this.assignments.map(assignment => {
                return this.urStateService
                    .deleteWithCache<Assignment>(
                        this.getAssignmentService(assignment),
                        this.getAssignmentParams(assignment),
                    )
                    .pipe(take(1));
            });

            return forkJoin(deleteList).pipe(
                // Reset selection, since we could possibly have deleted something that was currently selected.
                // We can definitely do something smarter here (check if delete assignments are selected),
                // but I'm reluctant to introduce extra logic just for this one edge case.
                tap(() => this.urStateService.updateSelectionFromDrawer([])),
            );
        } else {
            return of(null);
        }
    }

    private getAssignmentService(assignment: RoleAssignment): Collection<Assignment> {
        switch (assignment.assigneeType) {
            case AssigneeType.USER:
                return this.usersService;
            case AssigneeType.EXTERNAL:
                return this.externalUsersService;
            case AssigneeType.GROUP:
                return this.groupsService;
            default:
                console.warn('Unexpected assignee type', assignment.assigneeType);
        }

        return null;
    }

    private getAssignmentParams(assignment: RoleAssignment): string[] | QueryParams {
        switch (assignment.assigneeType) {
            case AssigneeType.USER:
            case AssigneeType.SSO_USER:
                return { email: assignment.id };
            case AssigneeType.EXTERNAL:
            case AssigneeType.GROUP:
                return [assignment.name];
            default:
                console.warn('Unexpected assignee type', assignment.assigneeType);
        }

        return null;
    }

    private getAssignments(): void {
        this.loading = true;
        this.urStateService
            .listWithCache(this.roleAssignmentService)
            .pipe(take(1))
            .subscribe(data => {
                this.loading = false;
                this.assignments = data.response
                    .filter(assignment => assignment.viewId && assignment.viewId === this.view.id)
                    .sort((a, b) => a.name.localeCompare(b.name));
            });
    }
}
