import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { ReportConfiguration } from '../models/report-configuration';

@Injectable({ providedIn: 'root' })
export class ReportConfigurationService extends GenericService<ReportConfiguration> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ReportConfiguration,
            endpoint: '/rest/v1/reports/configurations',
            create: true,
            update: true,
            delete: true,
        });
    }
}
