import { Component, Input } from '@angular/core';
import { CurrentUser } from '@pure1/data';

import { UserRoleStateService } from '../services/user-role-state.service';

@Component({
    selector: 'user-role-drawer-pane',
    templateUrl: 'user-role-drawer-pane.component.html',
})
export class UserRoleDrawerPaneComponent {
    @Input() currentUser: CurrentUser;

    constructor(public urStateService: UserRoleStateService) {}
}
