<div
    class="dismiss-button"
    (click)="cancel()"
    angulartics2On="click"
    angularticsAction="Step Up - Pre-Enroll Prompt Modal - close modal"
>
    &#x2715;
    <!-- "✕" -->
</div>
<pureui-svg svg="totp-qr.svg" height="106"></pureui-svg>
<div class="title">Before You Begin</div>
<div class="text">
    A mobile device capable of scanning a QR code is required.<br />
    Download and install your
    <a
        href="https://support.purestorage.com/Pure1/Pure1_Manage/002_Getting_Support/Identity_Management_with_Step-Up_Authentication#Downloading_a_Supported_Authenticator_Application"
        target="_blank"
        >preferred authenticator app</a
    >, then click Continue.
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="cancel()"> Cancel </button>
    <button type="button" class="btn btn-primary" (click)="continue()"> Continue </button>
</div>
