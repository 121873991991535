import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DraasApiLastVMUpdateTime } from '@pure/paas-api-gateway-client-ts';
import { GenericService } from './generic.service';
import { DisasterRecoveryVirtualMachine } from '../models/disaster-recovery-virtual-machine';
import { ListParams } from '../interfaces/list-params';
import { isArray } from 'lodash';
import { DraasApiConfig } from './disaster-recovery-constants';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryVirtualMachinesService extends GenericService<DisasterRecoveryVirtualMachine> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: DisasterRecoveryVirtualMachine,
            endpoint: `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/{clusterId}/vms`,
            list: true,
            create: false,
            update: false,
            delete: false,
        });
    }

    private getEndpoint(clusterId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/vms`;
    }

    override getListRequest(params: ListParams<DisasterRecoveryVirtualMachine>): { url: string } {
        const queryParams: string[] = [];
        const clusterId: string = params.filter.clusterId as string;

        queryParams.push(`page_number=${Math.floor(params.pageStart / params.pageSize)}`);
        queryParams.push(`page_size=${params.pageSize}`);

        if (params.filter || params.defaultFilter) {
            const filter = { ...params.filter, ...params.defaultFilter };
            Object.keys(filter)
                .filter(k => k != null && k !== 'clusterId')
                .forEach(k => queryParams.push(`${k}=${encodeURIComponent(filter[k] as string)}`));
        }

        if (params.sort) {
            if (isArray(params.sort)) {
                params.sort.forEach(s => queryParams.push(`sort=${s.key},${s.order}`));
            } else {
                queryParams.push(`sort=${params.sort.key},${params.sort.order}`);
            }
        }
        if (!queryParams.find(p => p.indexOf('sort=name') >= 0)) {
            queryParams.push(`sort=name,ASC`); // Append name sort if not already contained to stabilize sorts by host and datacenter
        }

        const url = this.getEndpoint(clusterId) + (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');
        return { url };
    }

    getLastVMUpdateTime(clusterId: string): Observable<Date | null> {
        const url = `${this.getEndpoint(clusterId)}/last-vm-update-time`;
        return this.http
            .get<DraasApiLastVMUpdateTime>(url)
            .pipe(map(response => (response.last_update_time ? new Date(response.last_update_time) : null)));
    }
}
