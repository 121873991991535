import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { NgRedux } from '../../redux/ng-redux.service';
import { of, Subject, Observable } from 'rxjs';
import { catchError, take, takeUntil, map } from 'rxjs/operators';
import { ServiceCatalogQuoteServiceV4 } from '@pure1/data';

import { PanelTabName, IState } from '../../redux/pure-redux.service';
import { removePanelSelection, addPanelSelection } from '../../redux/actions';
import { AppService } from '../../app/app.service';

@Component({
    selector: 'page-side-panel',
    templateUrl: 'page-side-panel.component.html',
})
export class PageSidePanelComponent implements OnInit, OnDestroy {
    @Input() readonly stateName: String;

    readonly PanelTabName = PanelTabName;
    readonly analyticsPrefix = 'evergreen_activity-tracker_';

    selectedTabName: PanelTabName;
    loading = false;

    // Orders Page Side Panel related
    ordersCount$: Observable<string>;

    private unsubscribeFromUrlService: Function;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private appService: AppService,
        private cdr: ChangeDetectorRef,
        private ngRedux: NgRedux<IState>,
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV4,
    ) {}

    ngOnInit(): void {
        // Since modules are lazy loaded, ngRedux's storage is not configured yet at time of calling
        // ngRedux.select() lets us subscribe to it so we can perform our actions after it has been set up
        this.ngRedux
            .select()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.handleRedux());

        // Orders query to populate actionable orders pill.
        this.ordersCount$ = this.serviceCatalogQuoteService.list().pipe(
            map(result => {
                const salesFlows = result.response.filter(
                    salesFlow => salesFlow.statusMessage === 'Quote in Progress' && salesFlow.quotes?.length > 0,
                );
                return salesFlows.length > 0 ? salesFlows.length.toString() : null;
            }),
            take(1),
            catchError(err => {
                console.error('serviceCatalogQuoteService.list() failed in page-side-panel', err);
                return of<string>(null);
            }),
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.unsubscribeFromUrlService?.();
    }

    handleRedux(): void {
        const existingSelection = this.ngRedux.getState().panel?.selection;

        if (!existingSelection) {
            this.selectedTabName = null;
            return;
        }

        // If we get an invalid tab name, we'll delete it
        if (!Object.values(PanelTabName).includes(existingSelection)) {
            this.selectedTabName = null;
            this.ngRedux.dispatch(removePanelSelection());
            return;
        }

        if (existingSelection !== this.selectedTabName) {
            const previousSelection = this.selectedTabName;
            this.selectedTabName = existingSelection;
            this.appService.pageSidePanelState.next({ isCollapsed: false });

            // handleRedux is called for the first time after ngRedux is set up, which happens sometime after ngOnInit
            // Because of that, we will likely change the selection in the middle of a lifecycle
            // So, we manually detectChanges this one time to prevent the ExpressionChanged error
            if (previousSelection === undefined) {
                this.cdr.detectChanges();
            }
        }
    }

    onPanelClose(): void {
        this.ngRedux.dispatch(removePanelSelection());
        this.appService.pageSidePanelState.next({ isCollapsed: true });
    }

    onPanelOpen(tabName: PanelTabName): void {
        this.ngRedux.dispatch(addPanelSelection(tabName));
        this.appService.pageSidePanelState.next({ isCollapsed: false });
    }
}
